import { Component, Input, OnInit } from '@angular/core';
import { CommonUtils } from '../../services/CommonUtils.service';
import { DashboardUtilsService } from '../../services/dashboard-utils.service';

@Component({
  selector: 'app-item-info',
  templateUrl: './item-info.component.html',
  styleUrls: ['./item-info.component.scss']
})
export class ItemInfoComponent implements OnInit {
  
  @Input() selectedDashId: any;
  @Input() leadInfo: any;
  @Input() users: any;
  orgUsersJson: any;
  @Input() dashName: any;
  itemTerminology: any={};
  @Input() isConnection: any
  @Input() connectionType: any

  constructor(private commonUtils: CommonUtils, private dashboardUtils: DashboardUtilsService) { }

  async ngOnInit() {
    this.orgUsersJson = await this.dashboardUtils.getOrgUsers();
    this.itemTerminology=this.dashboardUtils.getDashboardTerminoloy(this.selectedDashId)
  }

}
