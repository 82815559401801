import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit ,ViewChild} from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { CommonUtils } from 'app/services/CommonUtils.service';
import { DashboardUtilsService } from 'app/services/dashboard-utils.service';
import { HttpTransferService } from 'app/services/httpTransfer.service';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'button-cell',
  changeDetection:ChangeDetectionStrategy.OnPush,
  templateUrl: './button-cell.component.html',
  styleUrls: ['./button-cell.component.scss']
})
export class ButtonCellComponent implements OnInit  {
  @ViewChild('selectBox') selectBox: NgSelectComponent;
  params:any={};
  editable: boolean = false
  itemInfo:any={}
  permissibleValues: any;
  buttonDisplayText:any;

  constructor(private cdr:ChangeDetectorRef,private commonUtils: CommonUtils,private dashboardUtils:DashboardUtilsService,private httpTransfer: HttpTransferService,){
  }

  async ngOnInit() {
    this.onUpdate(this.params)
  }

  onUpdate(params: any){
    this.itemInfo=params?.item || {}
    this.editable=params?.editable
    this.buttonDisplayText=params?.columnParams?.customField?.display_key || 'click here'
    this.cdr.detectChanges()
  }

  clickMe(){
    this.params.value = {event: 'clicked'};
    this.params?.gridTableRef?.changedCellValue({value:this.params.value,customFieldType:true,params:this.params});
    this.onUpdate(this.params) 
  }

}
