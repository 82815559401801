
<!-- rating field -->
<div class="cell-box">
  <div style="overflow:hidden">
    <ngb-rating style="font-size: 22px; align-items: center; height: 30px;color: #3571de !important;" 
    [readonly]="!editable"
    [(rate)]="ratingValue" [max]="5" 
    [resettable]="true && editable && !params?.columnParams?.customField?.mandatory"
    (rateChange)="editable && updateRating()">
    </ngb-rating>
  </div>
  
</div>
