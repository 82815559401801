<div class="cell-box"  (click)="editable && enableEditor=true">
  <div class="cell-box-inner" #errorPopover="bs-popover" placement="bottom" container="body" [popover]="errorPopoverTemp" [outsideClick]="true" triggers="manual" [ngClass]="{'red-border': ipField?.invalid && (ipField?.dirty || ipField?.touched),'enable-editor-box': enableEditor }">
    <div class="left-box">
        <input placeholder="000.0.0.0" pattern="^(?!.*\.$)((?!0\d)(1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$" 
          (blur)="!ipField?.invalid && updateIpAddress(ipAddress,$event.target.value)"  
          [required]="params?.columnParams?.customField?.mandatory"
          (input)="ipField?.invalid && (ipField?.dirty || ipField?.touched) ? errorPopover?.show() : errorPopover?.hide()"
          (keydown.enter)="$event.stopPropagation();!ipField?.invalid && updateIpAddress(ipAddress,$event.target.value)"
          [readonly]="params?.columnParams?.customField.settings != 'Manual' || !editable"
          type="string" class="cell-input-box"
          [(ngModel)]="ipAddress" name="input" #ipField="ngModel" />
        <div class="cell-value-box">{{ipAddress}}</div>  
    </div>
    <div class="right-box" *ngIf="params?.columnParams?.customField.settings == 'Manual' && editable && !enableEditor" >
      <span (click)="editable && pickIp()" [ngbTooltip]="'Pick Ip'" placement="top" container="body" [tooltipClass]="'tooltipPopup'" triggers="hover">
        <img class="py-0" src="assets/1x/ip-type.svg" alt="ip">
      </span>
    </div>
  </div>
</div> 
<ng-template #errorPopoverTemp>
  <div class="text-danger">
    <b>
      <div *ngIf="ipField?.errors?.required">
        <span class="validationText ls-025x">{{'itemattributes.ip' | translate}} {{'editcustom.Required' | translate}}. </span>
      </div>
      <div *ngIf="ipField?.errors?.pattern">
          <span class="validationText ls-025x">{{'editcustom.enterCorrectIP' | translate}}</span>
      </div>
    </b>
  </div>
  </ng-template>