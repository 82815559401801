import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CommonUtils } from 'app/services/CommonUtils.service';
@Component({
  selector: 'tag-cell',
  templateUrl: './tag-cell.component.html',
  styleUrls: ['./tag-cell.component.scss']
})
export class TagCellComponent implements OnInit  {
  @Input() params : any={}; 
  itemInfo:any={}
  tagsMap:any={}
  showCreateTag:boolean=false;
  
  constructor(private cdr:ChangeDetectorRef,private commonUtils : CommonUtils){
    // setInterval(() => {if(this.checkUpdate)this.cdr.markForCheck();},0);
  }

  ngOnInit(): void {
    this.onUpdate()
  }

  onUpdate(params: any={}){
    this.itemInfo=this.params?.item || {}
    this.tagsMap =this.params?.columnParams[this.itemInfo?.dashboard_id]?.tagMap || {}
    this.cdr.detectChanges()
  }
  OnTagUpdate(tag,action){
    let tags=[]
    if(action=="remove"){
      tags=(this.params?.value || []).filter(tagId => tagId!= tag._id)
    }else{
      tags = this.params?.value?.length ? [...this.params?.value,tag?._id] : [tag?._id]
    }
    this.params.value = tags
    this.params?.gridTableRef?.changedCellValue({value:{[this.params?.field]:this.params.value},params:this.params});
    this.onUpdate()

  }
}
