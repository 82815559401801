<div>
    <ng-container *ngIf="!connectDashboard">
        <ng-container *ngFor="let connectedBoard of searchConnectedList;let index=index">
          <div role="button" class="con-boards hoverEffect d-flex" style="justify-content: left;align-items: stretch;width:315px;">
            <span class="ml-2 mt-2"><img class="icon-library" src="assets/1x/smIcons/Board.svg" style="height: 16px; width: 16px;"></span>
            <div *ngIf="!connectedBoard?.folder_name" class="graphOption d-inline-flex ml-2">{{connectedBoard.dashboard_name | truncate:30}}</div>
            <span class="d-flex mr-2 ml-auto mt-2 displayCrossIcon" (click)="removeFromConnectedBoard(connectedBoard.dashboard_id,connectedBoard,index)"><img style="width:0.7rem" src="../../../../assets/1x/cross-grey.svg"></span>
          </div>
        </ng-container>
        <div class="d-flex justify-content-center pt-2">
          <div class="widgeticons commonBtn-36" style="width:135px">
            <button class=" commonBtnBox-Child-1" (click)="connectDashboardClick(true)">{{'graph.connectDashboard' | translate}}</button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="connectDashboard">
        <div style="width: 315px;">
          <div class="d-flex w-100 align-items-center">
            <div style="width:15%;color:#1f1f1f;cursor: pointer;"><img src="../../../assets/img/icons/backArrow.svg" (click)="connectDashboard=false"/></div>
            <div class="width:70% search">
              <app-dashboard-icon style="left:90% !important" class=" cursorPointer" [size]="16"  [name]="'search'" [iconJson]="commonIcons"></app-dashboard-icon>
              <input class="w-100" placeholder="Enter Input" id="searchInput" type="search" (input)="filterDashboard(searchText)" [(ngModel)]="searchText">
            </div>
          </div>
          <div class="scrollusers" style="margin-top:10px;">
            <ng-container *ngIf="searchDashboardsList?.length>0;else noBoards">
              <ng-container *ngFor="let board of searchDashboardsList;let i=index">
                <div class="user" (click)="onDashboardConnect(board.dashboard_id,i,board)">
                  <div class="box">
                    <span class="f-m-b boardName">{{board?.dashboard_name}}</span>
                    <span *ngIf="board?.folder_name">({{board.folder_name}})</span>
                </div>
                </div>
              </ng-container>
            </ng-container>
            <ng-template #noBoards>
              <p class="emptyBoard">{{'userView.noDashFound' | translate}}</p>
            </ng-template>
          </div>
        </div>
        <div class="d-flex justify-content-center pt-2">
          <div class="commonBtn-36" [ngClass]="{'widgeticons':searchDashboardsList?.length>0,'widgeticonsDisable':searchDashboardsList?.length==0}" style="width:135px">
            <button class=" commonBtnBox-Child-1" (click)="connectDashboardClick(false)">{{'graph.connectDashboard' | translate}}</button>
          </div>
          </div>
      </ng-container>
</div>