import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { ICellRendererParams } from 'ag-grid-community';
import { CommonUtils } from 'app/services/CommonUtils.service';
import { DashboardUtilsService } from 'app/services/dashboard-utils.service';

@Component({
  selector: 'numeric-cell',
  changeDetection:ChangeDetectionStrategy.OnPush,
  templateUrl: './numeric-cell.component.html',
  styleUrls: ['./numeric-cell.component.scss']
})
export class NumericCellComponent implements OnInit  {
  params:any={};
  isCreatedBy: boolean = false
  editable: boolean = false
  orgUserJson:any={}
  itemInfo:any={}
  value:any;
  labelInfo:any={}
  enableEditor:boolean=false
  @ViewChild('inputBox') inputBox: ElementRef;
  show_as_duration:string='';
  showValidation:boolean=false;
  itemData:any={}
  errorMessage:string='';
  @ViewChild('errorPopover') errorPopover : any


  constructor(private cdr:ChangeDetectorRef,private commonUtils: CommonUtils,private dashboardUtils:DashboardUtilsService){}

  async ngOnInit() {
    this.onUpdate(this.params)
  }

  onUpdate(params: any){
    this.itemInfo=this.params?.item || {}
    this.editable=this.params?.editable
    this.itemData=this.params?.columnParams?.customField
    this.value=this.params.value
    params?.globalParams?.customFields.forEach(element => {
      if(element.key==params.field){
        this.labelInfo={'align':element.align,'unit':element.label}
        this.show_as_duration=element?.number_display?.display_type
      }
    });
    this.cdr.detectChanges()
  }
  onEditorEnable(){
    this.enableEditor=true;
    setTimeout(() => {this.inputBox?.nativeElement?.focus()},5);
  }

  onValueInput(value){
    this.enableEditor=false
    if(this.editable){
      this.params.value=value
      this.params?.gridTableRef?.changedCellValue({value:value,customFieldType:true,params:this.params});
      this.onUpdate(this.params) 
    }
  }

  validateInput(event:KeyboardEvent,events,item) {
    const minValue = item?.min_number;
    const maxValue = item?.max_number;
    const maxDecimalPlaces = item?.number_of_decimal;
    // const errorMessage = document.getElementById(`errorMessage`);
    const inputElement = events.target as HTMLTextAreaElement;
    const value = inputElement.value;
    this.errorMessage= '';
    const numberValue = parseFloat(value);
    if (numberValue < minValue) {
      this.showValidation=true;
      this.errorMessage = `Minimum value is ${minValue}.`;
      return;
    }
    if (numberValue > maxValue) {
      this.showValidation=true;
      this.errorMessage = `Maximum value is ${maxValue}.`;
      return;
    }
    const decimalPlaces = (value.split('.')[1] || '').length;
    if (maxDecimalPlaces>0 && decimalPlaces && decimalPlaces > maxDecimalPlaces) {
      this.showValidation=true;
      this.errorMessage = `Maximum number of decimals are ${maxDecimalPlaces}.`;
      event.preventDefault()
      return;
    }
  }
  
  onkeyPressForNumber(event:KeyboardEvent,item){
    this.errorPopover?.hide()
    // const errorMessage = document.getElementById(`errorMessage`);
    this.errorMessage = '';
    this.showValidation=false;
  }

}
