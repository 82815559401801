import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BasicUtils } from 'app/services/basicUtils.service';
import { CommonUtils } from 'app/services/CommonUtils.service';
import { DashboardUtilsService } from 'app/services/dashboard-utils.service';
import { ConstantService } from 'Enums/Constant.service';
import { MediaIcons } from 'Enums/media-icons';
import { MediaSmIcons } from 'Enums/media-sm-icons';
import { AttributesIcons } from 'Enums/attributes-icons';

@Component({
  selector: 'media-cell',
  templateUrl: './media-cell.component.html',
  styleUrls: ['./media-cell.component.scss']
})
export class MediaCellComponent implements OnInit  {
  @ViewChild("imageEditorModal") imageEditorRef: TemplateRef<any>;
  params:any={};
  editable: boolean = false
  itemInfo:any={}
  mediaList:any=[]
  mediaType:any;
  mediaIcons = MediaIcons;
  MediaSmIcons = MediaSmIcons;
  editImageFiles:any={}
  customFieldType: any={};
  previewInfo:any={
    media:[],
    index:0
  }
  isAnyModalOpen:boolean=false
  isLoaderShow:boolean=false
  renameValue:any=''
  popoverRef:any;
  dashBoardSprintArr:any=[];
  orgUsersJson:any={}
  attributesIcons = AttributesIcons;

  constructor(private cdr:ChangeDetectorRef,private commonUtils:CommonUtils,public constantService:ConstantService,private modal:NgbModal,private basicUtils:BasicUtils,private dashboardUtils:DashboardUtilsService){}




  async ngOnInit() {
    this.orgUsersJson = await this.dashboardUtils.getOrgUsers()
    this.customFieldType = this.constantService.getCustomTypes();
    this.onUpdate(this.params)
  }

  onUpdate(params: any={}){
    this.itemInfo=this.params?.item || {}
    this.editable=this.params?.editable
    this.mediaType=this.params?.columnParams?.customField?.type
    this.mediaList=[];
    let mediaData=[]
    if(this.params?.value && this.params?.value?.length)mediaData=this.params?.value
    else if(this.params.value)mediaData=[this.params.value];
    mediaData?.forEach(element=>{
      let fileObj={}
      fileObj['file_path'] = element?.file_path;
      fileObj['file_name'] = element?.file_name;
      fileObj['extension'] =  element?.extension?element.extension:fileObj['file_path']?.split(".").pop();
      fileObj['image_download_url'] =  element?.file_url;
      fileObj['type']=element?.type?element.type:null
      this.mediaList.push(fileObj);
    });
    this.dashBoardSprintArr=(this.params?.columnParams[this.itemInfo.dashboard_id]?.sprints || []).find(sprint=>sprint._id==this.params.value)
    this.cdr.detectChanges()
  }
  onUploadFile(files,isUploadWithEdit=true){
    if(files.length>0){
      if((this.mediaType==this.customFieldType.IMAGEFIELD || this.mediaType==this.customFieldType.MULTIIMAGE) && isUploadWithEdit){
        this.openImageEditor(files,this.mediaType)
      }
      else{
        this.isLoaderShow=true;
        this.commonUtils.onUploadFile(files, 'LEAD',null, this.fileUpload.bind(this));
      }
    }
  }
  fileUpload(fileArr,type?){
    let mediaData= this.params?.value?.length ? [...this.params?.value] : []
    fileArr.forEach(fileObj => {
      let fileJson = {
        file_name:fileObj['file_name']?fileObj['file_name']:fileObj['name'],
        file_path:fileObj['path']?fileObj['path']:fileObj['file_path'],
        image_download_url: fileObj['image_download_url'],
        extension:fileObj.extension?fileObj.extension:fileObj['file_path'].split(".").pop(),
      };
      let json={
        file_path:fileObj['path']?fileObj['path']:fileObj['file_path'],
        file_name:fileObj['file_name'],
        file_url:fileObj['image_download_url'],
        extension:fileObj.extension?fileObj.extension:fileObj['file_path'].split(".").pop(),
      }
      if(type && type!=='from_computer'){
        fileJson['type']=fileObj?.type;
        json['type']=type;
        fileJson['size_in_bytes']=fileObj?.size_bytes?fileObj?.size_bytes:0
        json['size_in_bytes']=fileObj?.size_bytes?fileObj?.size_bytes:0
      }
      // for single fields
      if(this.isSingleMediaType()){
        this.mediaList=[fileJson]
        mediaData=[json]
      }else{
        // on edit image 
        if(this.editImageFiles?.filePreviouspath){
          let index=this.mediaList?.findIndex(file=>file.file_path===this.editImageFiles.filePreviouspath)
          if(index>-1){
            this.mediaList[index]=fileJson
            mediaData[index]=json
          }
        }else{
          this.mediaList.push(fileJson)
          mediaData.push(json)
        }
      }
    })
    this.editImageFiles={}
    this.params.value=mediaData;
    this.updateField()
    this.isLoaderShow=false;
  }

  onRemoveMedia(index){
    this.mediaList.splice(index,1)
    if(this.params?.value?.length)this.params.value.splice(index,1)
    else this.params.value=null 
    this.updateField()
  }
  isSingleMediaType(){
    return this.mediaType == this.customFieldType.FILEUPLOAD || this.mediaType == this.customFieldType.IMAGEFIELD || this.mediaType == this.customFieldType.VIDEO || this.mediaType == this.customFieldType.AUDIO
  }

  onRenameMedia(index){
    if(this.renameValue?.length<3){return}
    if(this.params.value.length && this.mediaList[index]?.name!=this.renameValue){
      this.mediaList[index].name=this.renameValue
      this.params.value[index].file_name=this.renameValue
      this.popoverRef?.close()
      this.updateField()
    }
  }
  updateField(){
    let value=null
    if(this.mediaList?.length>0){
      value=this.isSingleMediaType() ? this.mediaList[0] : [...this.mediaList]
    }
    this.params?.gridTableRef?.changedCellValue({value:value,customFieldType:true,params:this.params});
    this.onUpdate()
  }

  openImageEditor(file,type=this.customFieldType.IMAGEFIELD){
    this.isAnyModalOpen=true
    this.editImageFiles={
      fileData : file,
      filePreviouspath: file[0]?.file_path || null,
      type:type,
      itemData:{dashId:this.itemInfo?.dashboard_id,leadId:this.itemInfo?.dashboard_id,customKey:this.params.field,lead:this.itemInfo}
    }
    let modal = this.modal.open(this.imageEditorRef, { size: 'lg' , windowClass: 'file preview-modal cell-modal'}) 
    modal.result.then(() => {},
    ()=>{
      this.isAnyModalOpen=false
    })
    this.basicUtils.storeModlRef(modal)
  }

  downloadMedia(file){
    this.commonUtils.downloadImage(file?.image_download_url,file?.name,file?.extension);
  }

  openModalForPreview(i,template) {
    this.isAnyModalOpen=true
    this.previewInfo.media = []
    this.previewInfo.index = i
    this.mediaList.forEach(e=>{
        let obj={}
        obj['type'] = this.mediaType
        obj['name'] =  e?.file_name + '.' + e?.extension
        obj['url']  =  e?.image_download_url
        obj['path'] =  e?.file_path 
        this.previewInfo.media.push(obj)
    })
    let modal = this.modal.open(template, { size: 'lg' , windowClass: 'file preview-modal cell-modal'}) 
    modal.result.then(() => {},
    ()=>{
      this.isAnyModalOpen=false
    })
    this.basicUtils.storeModlRef(modal)
  }

    uploadUrlLink(fileArr){
      console.log("filearray",fileArr)
      if(fileArr.type==='link' || fileArr.type==='google_drive' || fileArr.type==='one_drive'){
       this.fileUpload(fileArr?.files,fileArr.type)
      }
      if(fileArr.type==='from_computer'){
        this.onUploadFile(fileArr?.files)
      }
    }

    log(file){
      console.log("file",file)
    }

}