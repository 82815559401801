<div>
    <div class="d-flex justify-content-between graphOption-header hoverEffect"
        (click)="openGraphSettings('Chart');">
        <div class="pl-3">
            <app-dashboard-icon [size]="25" [name]="'pie'" [iconJson]="chartIcons"></app-dashboard-icon>
        </div>
        <div role="button" class="col-8 mr-1 ml-1">Chart</div>
    </div>
    <div class="d-flex justify-content-between graphOption-header hoverEffect"
        (click)="openGraphSettings('Number');">
        <div class="pl-3">
            <app-dashboard-icon [size]="25" [name]="'number'" [iconJson]="chartIcons"></app-dashboard-icon>
        </div>
        <div role="button" class=" col-8 mr-1 ml-1">Number</div>
    </div>
    <div class="d-flex justify-content-between graphOption-header hoverEffect"
        (click)="openGraphSettings('Grid');">
        <div class="ml-3">
            <app-dashboard-icon [size]="25" [name]="'overview'" [iconJson]="viewsIcons"></app-dashboard-icon>
        </div>
        <div role="button" class=" col-8 mr-1 ml-2">Data Widget</div>
    </div>
    <div class="d-flex hoverEffect" *ngIf="appsDetails?.showAppView" (click)="$event?.stopPropogation()">
        <multi-level-dropdown class="w-100" [viewType]="'WIDGET'" [isDropLeft]="true"
            [appConnectionJson]="appsDetails?.appConnectionJson"
            (selectedAppInstance)="selectedAppInstance.emit($event)"></multi-level-dropdown>
    </div>
</div>