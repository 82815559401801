<div class="modal-header border-bottom templateHeader" *ngIf="!autoReply && !itemCall">
  <div class="templateHeaderInput">
    <i class="fa fa-long-arrow-left" aria-hidden="true" (click)="closeModal.emit()"></i>
    <input type="text" class="form-control templateNameInput" placeholder="Template Name" [ngModelOptions]="{standalone: true}" [(ngModel)]="templateName" [disabled]="(!isAdmin && !personalTemplates) || isReadOnly" [ngClass]="{'templateNameInputText' : templateName.length }">
  </div>
  <button type="button" class="close" aria-label="Close" (click)="closeModal.emit()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>


 <div class = "whatsappEditorbox" *ngIf="!whatsappMedia.length">
  <div id="summernotewhatsapp" style="height:calc(100% - 40px);"></div>
 
  <div *ngIf="autoReply">
    <div *ngIf="emptyAutoReply" class="text text-danger">
      <p>{{'whatsapp.enterAutoReply' | translate}}</p>
    </div>
    <div class="mt-3">
      <h6>{{'whatsapp.autoReplyTime' | translate}}</h6>
      <div class="whatsappSettingRadios">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="exampleRadios1"  [(ngModel)]="whatsappSettingRule" [ngModelOptions]="{standalone: true}" id="exampleRadios4" value="ON_ITEM_CREATION" [disabled]="!isAdmin">
            <label class="form-check-label" for="exampleRadios4">
              {{'whatsapp.autoReplyMessage.newItemMessage' | translate}}                      
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="exampleRadios2" [(ngModel)]="whatsappSettingRule" [ngModelOptions]="{standalone: true}" id="exampleRadios4" value="ALL_TIME" [disabled]="!isAdmin">
            <label class="form-check-label" for="exampleRadios4">
              {{'whatsapp.autoReplyMessage.newMessageMessage' | translate}}                     
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="exampleRadios3"  [(ngModel)]="whatsappSettingRule" [ngModelOptions]="{standalone: true}" id="exampleRadios4" value="MESSAGE_RECEIVED_AFTER_TIME" [disabled]="!isAdmin">
            <label class="form-check-label" for="exampleRadios4">
              {{'whatsapp.autoReplyMessage.lastTimeMessage' | translate}}                  
            </label><br/>
            <div class="whatsAppsettingDaysHR">
                <input type="number" class="whatsapptime" min="0" [readonly]="whatsappSettingRule!='MESSAGE_RECEIVED_AFTER_TIME'" [(ngModel)]="autoreplyTime" [ngModelOptions]="{standalone: true}" [disabled]="!isAdmin"><label> &nbsp; {{'process.hours'|translate}}</label>
            </div>
          </div>              
        </div>
    </div>
    <div class="mt-3 autoReplyOptions" *ngIf="!itemCall">
      <button type="button" *ngIf="isAdmin" class="btn btn-secondary rounded " (click)="disableAutoreply()" > {{'common.disbale' | translate}}  </button>
      <button type="button" *ngIf="isAdmin && !autoReplyEnabled" class="btn btn-danger rounded " (click)="saveAutoReplySetting()" > {{'common.enableAndSave' | translate}}  </button>
      <button type="button" *ngIf="isAdmin && autoReplyEnabled" class="btn btn-danger rounded " (click)="saveAutoReplySetting()" > {{'common.update' | translate}}  </button>
    </div>
  </div>
  <div class="editorFooter" *ngIf="!autoReply && !itemCall" style="height: 50px;">
     <div style="display:flex">
    <button *ngIf="isCreatingTemplate && !isSaving" type="button" class="btn btn-danger rounded " (click)="saveTemplate()" [disabled]="(!isAdmin && !personalTemplates) || (!templateName.length) || isReadOnly">{{'common.create' | translate}}</button>
    <button *ngIf="isCreatingTemplate && isSaving" type="button" class="btn btn-danger rounded-pill px-4" [disabled]="true">
      <div class="spinner-border" role="status" style="width: 15px;height: 15px;"></div> {{'common.saving' | translate}}</button>

    <button *ngIf="!isCreatingTemplate && !isSaving" type="button" class="btn btn-danger rounded " (click)="saveTemplate()" [disabled]="(!isAdmin && !personalTemplates) || (!templateName.length || isReadOnly)">{{'common.save' | translate}}</button>
    <button *ngIf="!isCreatingTemplate && isSaving" type="button" class="btn btn-danger rounded-pill px-4" [disabled]="true">
      <div class="spinner-border" role="status" style="width: 15px;height: 15px;"></div> {{'common.saving' | translate}}</button>

     <div style="padding: 10px;align-items: center;display: flex;"><img src="../../../assets/1x/attachMedia.svg" (click)="whatsappFile.value='';whatsappFile.click()"  placement="top" ngbTooltip="Attachment" tooltipClass="tooltipPopup" container="body"></div>
    <input #whatsappFile type="file" class="inputFile" name="commentFile" (change)="onUploadFile($event)" multiple [disabled]="(!isAdmin && !personalTemplates) || isReadOnly">
     </div>
    <div>
      <button class="columnsBtn" [ngbPopover]="customColumns" #popColumns="ngbPopover" container="body" [disabled]="(!isAdmin && !personalTemplates) || isReadOnly">{{ '\{\{ \}\}'}} {{'common.COLUMNS'|translate}}</button>
    </div>
  </div>
 </div> 

 <!--Media-->

 <div class = " row whatsappEditorbox" style="height:calc(100% - 50px);" *ngIf="whatsappMedia.length">
  <div class="col-9" style="height:calc(100% + 50px);">
  <div id="summernotewhatsapp" style="height:calc(100% - 40px);"></div>
 
  <div *ngIf="autoReply">   
    <div>
      <div *ngIf="emptyAutoReply" class="text text-danger">
        <p>{{'whatsapp.enterAutoReply' | translate}} </p>
      </div>
      <h6>{{'whatsapp.autoReplyTime' | translate}}</h6>
      <div class="whatsappSettingRadios">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="exampleRadios1"  [(ngModel)]="whatsappSettingRule" [ngModelOptions]="{standalone: true}" id="exampleRadios4" value="ON_ITEM_CREATION">
            <label class="form-check-label" for="exampleRadios4">
              {{'whatsapp.autoReplyMessage.newItemMessage' | translate}}                      
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="exampleRadios2" [(ngModel)]="whatsappSettingRule" [ngModelOptions]="{standalone: true}" id="exampleRadios4" value="ALL_TIME">
            <label class="form-check-label" for="exampleRadios4">
              {{'whatsapp.autoReplyMessage.newMessageMessage' | translate}}                     
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="exampleRadios3"  [(ngModel)]="whatsappSettingRule" [ngModelOptions]="{standalone: true}" id="exampleRadios4" value="MESSAGE_RECEIVED_AFTER_TIME">
            <label class="form-check-label" for="exampleRadios4">
              {{'whatsapp.autoReplyMessage.lastTimeMessage' | translate}}                  
            </label><br/>
            <div class="whatsAppsettingDaysHR">
                <input type="number" class="whatsapptime" min="0" [readonly]="whatsappSettingRule!='CREATE_AFTER_PERIOD'" [(ngModel)]="autoreplyTime" [ngModelOptions]="{standalone: true}"><label> &nbsp; {{'process.hours'|translate}} </label>
            </div>
          </div>              
        </div>
    </div>
    <div class="mt-3" *ngIf="!itemCall">
      <button *ngIf="!isSaving" type="button" class="btn btn-danger rounded " (click)="isSaving=true;saveAutoReplySetting()" > {{'common.save' | translate}}    </button>
      <button *ngIf="isSaving" type="button" class="btn btn-danger rounded-pill px-4" [disabled]="true">
        <div class="spinner-border" role="status" style="width: 15px;height: 15px;"></div> {{'common.saving' | translate}}</button>
    </div>
  </div>
  <div class="editorFooter" *ngIf="!autoReply && !itemCall " style="height: 50px;">
     <div style="display:flex">
    <button *ngIf="isCreatingTemplate && !isSaving " type="button" class="btn btn-danger rounded " (click)="isSaving=true;saveTemplate()" [disabled]="(!isAdmin && !personalTemplates) || (!templateName.length )">{{'common.create' | translate}}</button>
    <button *ngIf="isCreatingTemplate && isSaving" type="button" class="btn btn-danger rounded-pill px-4" [disabled]="true">
      <div class="spinner-border" role="status" style="width: 15px;height: 15px;"></div> {{'common.creating' | translate}}</button>

    <button *ngIf="!isCreatingTemplate && !isSaving" type="button" class="btn btn-danger rounded " (click)="isSaving=true;saveTemplate()" [disabled]="(!isAdmin && !personalTemplates) || (!templateName.length )" >{{'common.save' | translate}}</button>
    <button *ngIf="!isCreatingTemplate && isSaving" type="button" class="btn btn-danger rounded-pill px-4" [disabled]="true">
      <div class="spinner-border" role="status" style="width: 15px;height: 15px;"></div> {{'common.saving' | translate}}</button>

     <div style="padding: 10px;align-items: center;display: flex;"><img src="../../../assets/1x/attachMedia.svg" (click)="whatsappFile.value='';whatsappFile.click()" placement="top" ngbTooltip="Attachment" tooltipClass="tooltipPopup" container="body"></div>
    <input #whatsappFile type="file" class="inputFile" name="commentFile" (change)="onUploadFile($event)" [disabled]="!isAdmin && !personalTemplates" multiple>
     </div>
    <div>
      <button class="columnsBtn" [ngbPopover]="customColumns" #popColumns="ngbPopover" container="body" [disabled]="!isAdmin && !personalTemplates">{{ '\{\{ \}\}'}} Columns</button>
    </div>
  </div>
</div>
<div class="col-3 medicFileBox" >
  <div class="mdaBlock mt-2 mb-1 f-l-r-r">
    <ng-container *ngFor="let media of whatsappMedia;let i= index">
        <div class="mediaDiv" *ngIf="media.type=='image'"><img  style="width:100px;height:100px;object-fit: contain;" [src]="media?.path"><span class="dltin"><img (click)="removeFile(i)" src="../../../assets/1x/image-delete-ico.png" class="deleteico"></span>
            <span class="fileNameU" 
            style="position:absolute; left: 0; bottom: -22px; max-width: 98px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
            {{media?.file_name}}</span>
        </div>
        <div class="mediaDiv" *ngIf="media.type=='video'"> <video style="width:100px;height:100px;" [src]="media?.path"></video><span class="dltin"><img (click)="removeFile(i)" src="../../../assets/1x/image-delete-ico.png" class="deleteico"></span>
            <span class="fileNameU" 
            style="position:absolute; left: 0; bottom: -22px; max-width: 98px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
            {{media?.file_name}}</span>
        </div>
        <div class="mediaDiv" *ngIf="media.type!='video' && media.type!='image'"><app-dashboard-icon [name]="(mediaIcons.hasOwnProperty(media?.file_name?.split('.').pop())) ? media?.file_name?.split('.').pop() :'txt'" [iconJson]="mediaIcons" size="95"></app-dashboard-icon> 
          <span class="dltin"><img (click)="removeFile(i)" src="../../../assets/1x/image-delete-ico.png" class="deleteico"></span>
            <span class="fileNameU" 
            style="position:absolute; left: 0; bottom: -22px; max-width: 98px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
            {{media?.file_name}}</span>
        </div>                           
    </ng-container> 
</div>
</div>
 </div> 


 <ng-template #customColumns>
  <div style="width:300px;height:200px;overflow-y: auto;">
      <label>{{'whatsapp.autoPopulateFields'|translate}}</label>
      <div style="display: flex;width: 100%;column-gap: 10px;row-gap:10px;flex-direction: col;flex-wrap: wrap;margin-bottom:5px;margin-top:10px;padding-bottom: 10px;border-bottom: 1px solid #d9d9d9;">
        <span class="predefineBox" (click)="getextravalue('bucketName')">{{'editcustom.bucket'|translate}}</span>
        <span class="predefineBox" (click)="getextravalue('priority')">{{'editcustom.priority'|translate}}</span>
        <span class="predefineBox" (click)="getextravalue('phase')">{{'setting.phase'|translate}}</span>
        <span class="predefineBox" (click)="getextravalue('assignee')">{{'editcustom.assignee'|translate}}</span>
        <span class="predefineBox" (click)="getextravalue('summary')">{{'editcustom.summary'|translate}}</span>
        <span class="predefineBox" (click)="getextravalue('startDate')">{{'date.startDate'|translate}}</span>
        <span class="predefineBox" (click)="getextravalue('endDate')">{{'date.endDate'|translate}}</span>
    </div>            
      <div style="display: flex;width: 100%;column-gap: 10px;row-gap:10px;flex-direction: col;flex-wrap: wrap;margin-top:10px;">
        <ng-container *ngFor="let item of arrOfCustomField">
          <span class="predefineBox" *ngIf="item.type!='Board' && item.type!='Mirror' && item.type!='Textarea' && item.type!='MultiSelect' && item.type!='MultiUser' && item.type!='SingleImage' && item.type!='MultipleImage' && item.type!='SingleFile'
          && item.type!='MultipleFile' && item.type!='SingleVideo' && item.type!='MultipleVideo' && item.type!='Checkbox' && item.type!='CheckList' && item.type!='Button'" (click)="getcustomvalue(item)">{{item?.display_key}}</span>
          </ng-container>
        </div> 
  </div>
</ng-template>