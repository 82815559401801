import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { CommonUtils } from 'app/services/CommonUtils.service';
import { DashboardUtilsService } from 'app/services/dashboard-utils.service';
import { ConstantService } from 'Enums/Constant.service';

@Component({
  selector: 'textfield-cell',
  changeDetection:ChangeDetectionStrategy.OnPush,
  templateUrl: './textfield-cell.component.html',
  styleUrls: ['./textfield-cell.component.scss']
})
export class TextfieldCellComponent implements OnInit  {
  params:any={};
  itemInfo:any={}
  summerNoteconfig:any={}
  columnData:any={}
  textContent:any=''
  enableEditor:any=false
  customType:any=null;
  viewType:string='';
  itemData:any
  textAssistOptions:any={}
  @ViewChild('editableRef') editableDiv: ElementRef;

  constructor(private cdr:ChangeDetectorRef,private constant: ConstantService,private commonUtils:CommonUtils){}

  async ngOnInit() {
    this.summerNoteconfig = this.constant.getSummerNoteConfig();
    this.onUpdate(this.params)
  }
  onUpdate(params: any){
    this.itemInfo=this.params?.item || {}
    this.columnData=this.params?.columnData
    this.customType=this.params?.columnParams?.customField?.type || null
    this.itemData=this.params?.columnParams?.customField
    this.textContent=this.params.value || '';
    this.viewType=this.params?.columnData?.columnParams?.viewType
    this.textAssistOptions=this.params?.editable ? (this.columnData.type=='Textarea' ? {'suggestions':[],'aiSuggestions':[],'editorOptions':[]} : {'suggestions':[],'aiSuggestions':[]}) :{'chatSuggestions':[],'aiSuggestions':[]}
    this.cdr.detectChanges()
  }
  updateTextfield(getHtml=false){
    this.enableEditor=false
    if(getHtml)this.textContent=this.editableDiv?.nativeElement?.innerHTML;
    if(this.params.value==this.textContent || this.params.field=='title' && !this.textContent?.length){
      return 
    }
    this.params.value=this.textContent
    let json = this.params.field=='title' ? {[this.params.field]:this.textContent} : this.textContent
    this.params?.gridTableRef?.changedCellValue({value:json,params:this.params,customFieldType:this.params.columnParams?.isCustomField || false});
    this.onUpdate(this.params)
  }
  onEnableInputBox(event){
    const selection = event ? window.getSelection() : null;
    if (selection && selection?.toString()?.length > 0) {
    }else{
      this.enableEditor=true
      setTimeout(() => {this.editableDiv?.nativeElement?.focus()},5);
    }
    
  }
  openItem(){
    if(this.params?.field=='title'){
      this.params?.globalParams?.gridViewRef?.openRightItemModal(this.params?.item)
    }
  }

  handledTextAssist(data){
    let valueKey=data.action=='Subtask' || data.action=='Create' ? 'title' : this.params.field
    let valJson={value:{[valueKey]:data.text},params:this.params,customFieldType:this.params.columnParams?.isCustomField || false,actionType:data?.action,refItem:this.params?.item}
    if(valueKey=='title')this.params?.gridTableRef?.changedCellValue(valJson);
  }

  isShowTooltip(textData){
    const element = textData?.nativeElement
    if(element) {
      return element.scrollWidth > element.clientWidth;
    }
    return false;
  }

  onKeyUp(event: KeyboardEvent,item) {
    let bool=this.commonUtils.onKeyBoardPress(event,item)
    if(bool)event.preventDefault()
  }
}
