<div class="cell-box mobile-cell" (click)="(!countrySelectionRef?.value?.country_code || countrySelectionRef?.fieldType==countrySelectionRef?.customFieldType?.COUNTRY) && countrySelectionRef?.countryPopover?.open()">
   <app-include-country-selection #countrySelectionRef style="width: 100%;" [selectedLeadObj]="itemInfo" [dashId]="dashId" [fieldType]="params?.columnParams?.customField?.type" [isGrid]="true" [editable]="params?.editable" [countryJson]="params?.globalParams?.currencyData || []" [currenValue]="params?.value" (onValueUpdate)="onValueInput($event)" (manageNumbers)="managePopover.open()"></app-include-country-selection>
</div>

<span [ngbPopover]="manage" [autoClose]="false" trigger="manual" popoverClass="modifiedPopover" container="body" #managePopover="ngbPopover">
</span>
<ng-template #manage>
   <div style="width: 25rem;">
      <app-multiple-mobile-handle [gridView]="true" [leadObj]="itemInfo" [dashId]="dashId" [editable]="params?.editable" [countryJson]="params?.globalParams?.currencyData || []" [currentValue]="params?.value" (onValueUpdate)="onValueInput($event)" (close)="managePopover.close()"></app-multiple-mobile-handle>
   </div>
</ng-template>