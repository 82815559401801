<div class="cell-box cursorPointer board-cell" [placement]="['bottom','bottom-right','bottom-left','auto']" container="body"  popoverClass="gridtableCell boardCellPopover" #boradPopover="ngbPopover" [ngbPopover]="itemInfo?._id && boardTemplate" [autoClose]="'outside'" triggers="manual" (click)="boradPopover?.open()">
    <div class="cell-box-inner" [class.w-100]="connectedLeadIds.length">
      <div class="left-box d-flex align-items-center"> 
        <div class="cell-input-box">
            <div *ngIf="connectedLeadIds.length" class="additem-btn"> 
                <i class="fas fa-plus" style="padding:4px"></i>
            </div> 
            <ng-container *ngIf="connectedLeadIds.length;else selction">
                <ng-container *ngFor="let itemId of connectedLeadIds | slice:0:countToShow">
                    <div *ngIf="mirrorColumnsJson[itemId]" (click)="$event?.stopPropagation();openItem(mirrorColumnsJson[itemId])" class="w-100 title-info">
                        <span class="text-box">
                            {{mirrorColumnsJson[itemId]?.title}}  
                        </span>
                        <span class="remove-item" (click)="$event?.stopPropagation();removeItemFromBoard(mirrorColumnsJson[itemId])"><i class="fa fa-times crossIcon"></i></span>
                    </div>
                </ng-container>
            </ng-container>
            <ng-template #selction><span class="text-box text-center" [style.opacity]="connectedLeadIds.length ? 1 : 0.5"><i class="fas fa-plus"></i> {{'board.addItems' | translate}}</span></ng-template>
        </div>
      </div>
      <div *ngIf="(connectedLeadIds?.length-countToShow)>0" class="right-box">
        <span class="valueCount d-flex" *ngIf="connectedLeadIds?.length>1">{{"+"+(connectedLeadIds?.length-countToShow)}}</span>
      </div>   
    </div> 
</div>

<ng-template #boardTemplate>
  <div class="connected-board-parent"> 
    <div class="boardEditorPopup">
     <div class="mx-0 px-2 header-wrapper">
        <div class="p-1 mt-2 board-name">{{dashboardName | titlecase}}</div> 
        <ng-container *ngIf="params?.editable">
             <div class="mx-0 g-0" style="display:flex; justify-content: end; align-items: center;">
                <div>
                    <div class="board-header d-flex justify-content-end">
                        <div (click)="openItem(params?.item,false,{type:' ',item:{key:params?.field},connectedBoardId:dashboardId,connectedItemsIds:connectedLeadIds});boradPopover?.close()" class="addCreateConnectBtn">
                            <span class="iconBox"><i class="fa fa-search"></i></span>
                            <span *ngIf="item?.table_view !== false">{{'editcustom.searchLeads' | translate :{ term: 'Items' | terminology: item?.itemTerminology?.plural : 'Items' } }}</span>
                        </div>
                    </div>
                </div>
             </div>
        </ng-container>
     </div>
     <div class="connected-board-list-grid px-2"> 
        <app-item-list-grid   #itemGrid       
        [dashId]="params.columnParams.customField?.connected_board_id" 
        [showBoardName]="false" 

        [connectedKey]="params?.field"
        [isCurrentBoardReadonly]="!params?.editable"
        [customFieldsOfMainBoard]="params?.columnParams[params?.item?.dashboard_id]?.customFiled || []"
        [currentLead]="params?.item"
        [boardConnectedItemIds]="connectedLeadIds"
        (itemRemove)="updateTableItem('Remove',connectedLeadIds,$event)" 
        (itemCreate)="updateTableItem('Add',connectedLeadIds,$event?.item,$event?.index)" 
        (itemUpdate)="updateTableItem('Update',connectedLeadIds,$event)"
        (onItemOpen)="openItem($event);boradPopover?.close()"
        (closePopover)="boradPopover?.close()"
        (onExpendTable)="params.globalParams.gridViewRef.itemPreviewData=[$event];boradPopover?.close()"
        [fieldInfo]="{fieldName:params?.columnParams?.customField?.display_key,fieldType:params?.columnParams?.customField?.type}"
        class="w-100"
        ></app-item-list-grid>
     </div>
    </div>
 </div>
</ng-template>
<ng-template #addcustomlead let-modal>
    <app-create-lead-modal [dashId]="dashboardId" (emitCreatedItemId)="setItemToBoard({_id:$event})" (createLeadModalCloseEvent)="modal.dismiss();" style="height: 100%;">
    </app-create-lead-modal>
</ng-template>