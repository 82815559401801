
<!-- Numeric field -->
<!--handle for fire-fox,chrome,unit,unitallignment-->
<div class="cell-box" (click)="editable && onEditorEnable()">
    <div *ngIf="show_as_duration!=='DURATION'" class="cell-box-inner" #errorPopover="bs-popover" placement="bottom" container="body" [popover]="errorPopoverTemp" [outsideClick]="true" triggers="manual" [ngClass]="{'red-border': ((!(value < 0 || value > 0 || value == 0 || value == null) || numberdfield?.invalid) && (numberdfield?.dirty || numberdfield?.touched)) || showValidation,'enable-editor-box': enableEditor,'emptyNumberField': (!value && value!==0 || value==='') && !enableEditor }">
      <span *ngIf="labelInfo?.unit && labelInfo.align=='left'" class="unitBox">{{labelInfo.unit}}</span>
      <div class="left-box">
        <input #inputBox  (input)="onkeyPressForNumber($event,itemData);validateInput($event,$event,itemData);((!(value < 0 || value > 0 || value == 0 || value == null) || numberdfield?.invalid) && (numberdfield?.dirty || numberdfield?.touched)) || showValidation ? errorPopover?.show() : errorPopover?.hide()"  class="cell-input-box"
        (blur)="(value < 0 || value > 0 || value == 0 || value == null) && onValueInput(value)"
        (keydown.enter)="(value < 0 || value > 0 || value == 0 || value == null) && onValueInput(value)"
        placeholder="{{'placeholders.addYourTextHere' | translate}}" [(ngModel)]="value" name="input_{{ itemInfo.key }}" #numberdfield="ngModel"  [disabled]="!editable" />
        <div class="cell-value-box">
          {{value | numberTransform:itemData?.number_display?.number_of_decimal_digits:itemData?.number_display?.display_type}}
        </div>
      </div>
      <span *ngIf="labelInfo?.unit && labelInfo.align=='right'" class="unitBox px-1">{{labelInfo.unit}}</span>  
    </div>

    <div *ngIf="show_as_duration==='DURATION'" class="cell-box-inner" #errorPopover="bs-popover" placement="bottom" container="body" [popover]="errorPopoverTemp" [outsideClick]="true" triggers="manual" [ngClass]="{'red-border': (!(value < 0 || value > 0 || value == 0 || value == null)),'enable-editor-box': enableEditor,'emptyNumberField': (!value && value!==0 || value==='') && !enableEditor }">
      <app-number-as-duration [value]="value" name="input_{{ itemInfo.key }}" [readonly]="!editable" (valueUpdated)="value=$event;onValueInput(value)"></app-number-as-duration>

    </div>
        <!-- <p id="errorMessage" style="color:red;"></p> -->
</div>
<ng-template #errorPopoverTemp>
  <ng-contaner *ngIf="!showValidation">
    <div class="text-danger">
      <b>{{'itemattributes.number' | translate}} {{'editcustom.Required' | translate}}</b>
    </div>
  </ng-contaner>
  <ng-container *ngIf="showValidation">
    <div class="text-danger">
      <b>{{errorMessage}}</b>
    </div>
  </ng-container>
</ng-template>

