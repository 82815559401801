<div class="leadUpdateModalComponent" #itemViewport>
        <!----Moadal Header---->
        <div class="item-modal-header" *ngIf="!itemConfig?.hideHeader">
            <ng-container >
                <app-lead-modal-header #itemHeader
                    leadId={{leadId}} dashId={{dashId}} [leadObj]="leadObj"
                    [tableInfo]="tableInfo"
                    [hideClosebtn]="hideClosebtn"
                    [showMinimizedIcon] ="showMinimizedIcon"
                    [currentItemView]="currentItemViewInfo?.viewType"
                    [itemStateData]="itemStateData"
                    (onitemViewTypeChange)="onitemViewTypeChange?.emit({viewType:$event,infoWidth:currentItemViewInfo?.infoWidth})"
                    (onItemChange)="onItemChange?.emit($event)"
                    [dashBoardSprintArr]="dashBoardSprintArr" (modalClose)="emitCloseLeadModal.emit($event)"
                    (showSubtaskParentModal)="openRightPopup({type:$event})"
                    [isPublicLead]="publicLead"
                    [showHiddenFields]="showHiddenFields"
                    [itemConfig]="itemConfig" (minimizeRight)="minimizeRight.emit($event)">
                </app-lead-modal-header>  
            </ng-container>
        </div>
        
        <!-----Start : Main Body Conent ------->
        <div class="modal-body lead-modal-height" [class.fullHeight]="itemConfig?.hideHeader">
            <div *ngIf="leadId" class="info-wrapper">
                <div class="info-wrapper-inner" #itemInfoViewport>
                    <div *ngIf="currentItemViewInfo?.viewType!='RIGHT'" class="item-left-info-wrapper" [style.width]="currentItemViewInfo?.infoWidth+currentItemViewInfo?.widthUnit">
                        <ng-container *ngIf="leadObj && !itemConfig?.showLoader">
                            <ng-container *ngTemplateOutlet="infoTemp"></ng-container>
                            <div class="resizer-handle"  (mousedown)="startResize($event)">
                                <div class="resizer-handle-inner"></div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="item-right-info-wrapper">
                        <div class="col-12 w-100 p-0">
                            <ng-container *ngIf="leadObj && currentActiveTab == 'detailsTab'">
                                <ng-container *ngTemplateOutlet="infoTemp"></ng-container>
                            </ng-container>
                            <ng-container *ngIf="leadId && currentActiveTab == 'activityTab'">
                                <app-timeline [selectedActivity]="itemStateData[itemStateData?.length-1]?.mentionView ? 'INTERNAL' : 'ALL'" [dashId]="dashId"[leadInfo]="leadObj ? leadObj : {_id:leadId}" (openSideFilterModal)="openRightPopup($event)" [viewType]="'ItemActivityView'" class="w-100 h-100"   (onItemOpen)="updateItemState($event,true)"></app-timeline>
                            </ng-container>
                            <ng-container *ngIf="currentActiveTab == 'notesTab'">
                                <app-notes-view [selectedDashId]="dashId" [users]="dasUsers" [leadId] = "leadId" [activeEdit] = false [itemCall] = true></app-notes-view>
                            </ng-container>
                            <ng-container *ngIf="currentActiveTab == 'mediaTab'">
                                <app-attachment [dashId]="dashId" [leadId]="leadId"></app-attachment>
                            </ng-container>
                            <ng-container *ngIf="currentActiveTab == 'appsTab' && selectedApp">
                                <app-view [viewUrl]="selectedApp?.view_url" [selectedDashId]="selectedDashId"></app-view>
                            </ng-container>
                            <ng-container *ngIf="currentActiveTab == 'boardTab'" >
                                <app-non-embedded-view-option [leadObj]="leadObj" [dashboardUserRole]="dashboardUserRole" 
                                [tabIndex]="0" [nonEmbeddedListArray]="nonEmbeddedListArray" (updateTheCountValue)="updateTheCount($event)" [selectedBoardList]="selectedBoardList" [dashBoardCustArr]="dashBoardCustArr" [dashId]="dashId" [leadId]="leadId" [dashboardInfo]="dashboardInfo" [publicLead]="publicLead" (onItemOpen)="updateItemState($event)" (showConnectedBoardFilter)="openRightPopup($event)"></app-non-embedded-view-option>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="side-tab h-100" *ngIf="leadObj?.item_type!=='ACTIVITY_COMMENT'">
                    <div class="tab-options f-l-r">
                        <div *ngIf="currentItemViewInfo?.viewType=='RIGHT'" class="tab-btn" [class.active-tab]="currentActiveTab =='detailsTab'" (click)="leadObj && currentActiveTab ='detailsTab'">
                            <span class="icon-box"><ng-container *ngTemplateOutlet="detailsSvg"></ng-container></span>
                            <span class="side-tab-btn-name f-xs-l">{{'item.details' | translate}}</span>
                        </div>
                        <div class="tab-btn" [class.active-tab]="currentActiveTab =='activityTab'" (click)="leadObj && currentActiveTab = 'activityTab'">
                            <span class="icon-box"><ng-container *ngTemplateOutlet="activitySvg"></ng-container></span>
                            <span class="side-tab-btn-name f-xs-l">{{'item.activity' | translate}}</span>
                        </div>
                        <div class="tab-btn" [class.active-tab]="currentActiveTab =='notesTab'" (click)="leadObj && currentActiveTab = 'notesTab'">
                            <span class="icon-box"><ng-container *ngTemplateOutlet="notesSvg"></ng-container></span>
                            <span class="side-tab-btn-name f-xs-l">{{'item.notes' | translate}}</span>
                        </div>
                        <div class="tab-btn" [class.active-tab]="currentActiveTab =='mediaTab'" (click)="leadObj && currentActiveTab = 'mediaTab'">
                            <span class="icon-box"><ng-container *ngTemplateOutlet="mediaSvg"></ng-container></span>
                            <span class="side-tab-btn-name f-xs-l">{{'item.media' | translate}}</span>
                        </div>
                        <div *ngIf="nonEmbeddedListArray?.length>0" [class.active-tab]="currentActiveTab =='boardTab'" (click)="currentActiveTab = 'boardTab'" class="tab-btn" [ngbTooltip]="'Non Embedded Board'" placement="auto" container="body"  tooltipClass="tooltipPopup" role="button">
                            <span class="icon-box">
                                <app-dashboard-icon [name]="'Board'" [iconJson]="attributesIcons" [size]="20" class="labelimg"></app-dashboard-icon>
                                <span style="position: relative;top: -25%;left: -4%;color: #3269CC;" *ngIf="countOfLeadsHere>0">{{countOfLeadsHere}}</span>
                            </span>
                            <span class="side-tab-btn-name f-xs-l">Board</span>
                        </div>
                        <div class="tab-btn" *ngIf="appsDetails?.showAppView">
                            <span class="h-24 d-flex align-items-center"><app-dashboard-icon [isSvg]="true" [color]="'#353E4E'" [size]="12" [name]="'apps'" [iconJson]="itemIcons"></app-dashboard-icon></span>
                            <span class="w-100 side-tab-btn-name f-xs-l"><multi-level-dropdown class="dropdownapp" [isDropLeft]="true" [viewType]="'ITEM_VIEW'" [appConnectionJson]="appsDetails?.appConnectionJson" (selectedAppInstance)="selectedAppInstance($event)"></multi-level-dropdown></span> 
                        </div>
                    </div>
                    <div class="tab-apps w-100  f-l-r" *ngIf="connectedExternalApps?.length && appsDetails?.appConnectionJson">
                        <ng-conatiner *ngFor="let apps of connectedExternalApps">
                              <div class="tab-btn appBtn cursorPointer" (click)="changeAppFeatur(apps)" [ngClass]="{'activeApp' :selectedApp?._id == apps?._id}">
                                <span class="h-24 d-flex align-items-center"><ngx-avatars class="mr-1 avatarClass" [name]="apps.name" [src]="appsDetails?.appConnectionJson[apps?.externalapp_id]?.appInfo?.info?.icon?.image_download_url" [bgColor]="appsDetails?.appConnectionJson[apps?.externalapp_id]?.appInfo?.info?.color" size="24" initialsSize="2" ></ngx-avatars></span>
                                <div class="side-tab-btn-name appFeatureName">{{apps?.name | truncate: 10}}</div>
                              </div>
                        </ng-conatiner>
                    </div>
                    <ng-container *ngIf="(currentItemViewInfo?.viewType=='RIGHT' || itemConfig.showSideOptions)&& !publicLead">
                        <ng-container *ngTemplateOutlet="subSideBarOptionTemp"></ng-container>
                    </ng-container>
                </div>    
            </div>
            <div *ngIf="tableInfo" class="info-wrapper table-info-wrapper">
                <app-item-list-grid                 
                [dashId]="tableInfo?.boardId" 
                [showBoardName]="false" 
                [subtaskFilter]="tableInfo?.subtaskFilter"
                [showHiddenFields]="showHiddenFields.ishidden"
                [connectedKey]="tableInfo?.connectedKey"
                [isCurrentBoardReadonly]="tableInfo?.isCurrentBoardReadonly"
                [customFieldsOfMainBoard]="tableInfo?.customFieldsOfMainBoard"
                [currentLead]="tableInfo?.item"
                [boardConnectedItemIds]="tableInfo?.boardConnectedItemIds"
                [isItemGridExpendView]="true"
                (onItemOpen)="updateItemState($event)"
                (itemRemove)="updateTableItem('Remove',tableInfo?.boardConnectedItemIds,$event)" 
                (itemCreate)="updateTableItem('Add',tableInfo?.boardConnectedItemIds,$event?.item,$event?.index)" 
                (itemUpdate)="updateTableItem('Update',tableInfo?.boardConnectedItemIds,$event)"
                class="w-100"
                ></app-item-list-grid>
            </div>
        </div>
        <!-----End : Main Body Conent------->

        <!-- subtask popup -->
        <ng-container *ngIf="showSubtaskFilter && leadObj">
            <div class="side-subtask-filter" [class.w-100]="currentItemViewInfo?.viewType=='RIGHT'">
                <app-subtask-connectboard-filter [currentLead]="leadObj" [dashId]="dashId" [activityInfo]="filterPopupInfo?.activityObj" [isPublicLead]="publicLead"  (showSubtaskFilter)="showSubtaskFilter=$event" (selectedListItem)="dependencyCall($event)" [connectedBoardIds]="filterPopupInfo?.connectedBoardId" [connectedBoardIdsList]="filterPopupInfo?.connectedItemsIds" [connectedBoardValuekey]="filterPopupInfo?.connectedBoardkey" [callType]="filterPopupInfo?.callType"></app-subtask-connectboard-filter>
            </div>
        </ng-container>
        <ng-container *ngIf="createNewDependency">
            <div class="side-subtask-filter" [class.w-100]="currentItemViewInfo?.viewType=='RIGHT'">
                <div class="dependency-create-option h-100">
                        <div class="h-100" style="padding:5px"> 
                            <app-create-lead-modal [dashId]="dashId"
                            [bucketSelect]="leadObj?.bucket_id"
                            [isTemplateCall]="false"
                            [creatingFromlist]="true"
                            [addItem]="true"
                            [callFromDependency]="true"
                            (createLeadModalCloseEvent)="createNewDependency = false"
                            (newItemCreated)="createNewDependency=false; addDependsOnItem($event)"
                            style="height: 100%;">
                            </app-create-lead-modal>
                        <!-- <ng-template *ngTemplateOutlet="infoTemp;"></ng-template> -->
                        </div>
                </div>
            </div>
        </ng-container>
</div> 

<!-- detals template (edit custom) -->
<ng-template #infoTemp>
    <div class="info-details-wrapper" keyboardMonitor>
        <app-edit-custom 
        style="width: 100%;"
        [dashId] = "dashId" [addTitile] = "true" [editTitle] = "leadObj?.title"
        [bucketId]="leadObj?.bucket_id" [leadId] = "leadId"
        [dashboardUserRole]="dashboardUserRole || []" 
        [bucketArray]="bucketArray || []"
        [is_deleted] = "is_deleted"
        [tabIndex]="tabIndex"
        [leadObj]="leadObj"
        [addItem]="createNewDependency?true:false"
        [publicLeadCustomFieldData]="publicLeadCustomFieldData"
        [isPublicLead]="publicLead"
        [showHiddenFields]="showHiddenFields"
        [showEmptyField]="showHiddenFields?.showEmptyFields"
        [itemConfig]="itemConfig"
        [dashBoardSprintArr]="dashBoardSprintArr" 
        [tagsCorrespondingToDash]="tagsCorrespondingToDash" 
        (onItemOpen)="updateItemState($event,true)"
        (showConnectedBoardFilter)="openRightPopup($event)"
        [isRecordingInprogress]="isRecordingInprogress">
        </app-edit-custom>
        <!------Dependency, subtask and duplicate item component-->
        <ng-container *ngIf="leadObj?.item_type!=='ACTIVITY_COMMENT'">
            <app-full-item-dependency-sub-task-check-list
            [bucketArray]="bucketArray" 
            style="padding-top: 15px; display: flex; flex-direction: column; row-gap: 15px;width: 100%;"
            [dashboardUserRole]="dashboardUserRole" 
            [tabIndex]="tabIndex"
            [currentLead]="leadObj"
            [dashId]="dashId"
            (createNewDependency)="createNewDependency=$event"
            (showSubtaskParentModal)="openRightPopup({type:$event})"
            [isPublicLead]="publicLead"
            (onItemOpen)="updateItemState($event)">
            </app-full-item-dependency-sub-task-check-list>
        </ng-container>
    </div>
</ng-template>

<ng-template #subSideBarOptionTemp>
    <div class="editional-tab-options f-l-r">
        <!-- start public icon  -->
        <span class="option-btn" [class.activeOption]="publicPopover?.isOpen()" [ngbPopover]="leadObj && !isReadOnly && itemPublicTemplate"  [placement]="['right-bottom','auto']" [autoClose]="'outside'" 
          #publicPopover="ngbPopover">
          <img [src]="leadObj?.item_type==='ITEM_PUBLIC' ? '../../../assets/common-use-icon-20dp/public-item-FE2030.svg' : '../../../assets/common-use-icon-20dp/private-item-6d6e6f.svg'" alt="public item">
        </span>
        <ng-template  #itemPublicTemplate>
          <div style="width: 265px;">
            <input (click)= "changeItemType(leadObj)" type="checkbox" [checked]="leadObj?.item_type==='ITEM_PUBLIC'">
            <span><strong> {{'item.markAsPublic' | translate}} </strong>  </span>
            <p>{{'item.publicItemCheckInfo' | translate :{ term: 'Items' | terminology: itemTerminology?.plural : 'Items' } }}</p>
          </div>
        </ng-template>
        <!-- end : public icon -->
        <!-- Start SubItem  -->
        <span class="option-btn" placement="left"  [ngbTooltip]="'Sub Task'" tooltipClass="tooltipPopup" (click)="leadObj && scrollTO('sub-task'+leadId)">
              <span class="subItm ICO"><app-dashboard-icon [size]="16"  [name]="'SubTask'"></app-dashboard-icon></span>
              {{leadObj?.child_lead_id?.length}}
        </span>
        <!-- End subItem -->
        <!-- Start dependency  -->
        <span class="option-btn" placement="left"  [ngbTooltip]="'Dependency'" tooltipClass="tooltipPopup" (click)="leadObj && scrollTO('dependency'+leadId)">
            <span *ngIf="leadObj?.dependencies?.depends_on?.length>0">{{leadObj?.dependencies?.depends_on?.length}}</span>
            <span><app-dashboard-icon [size]="16"  [name]="'Dependency'"></app-dashboard-icon></span>
            <span *ngIf="leadObj?.dependencies?.dependents?.length>0">{{leadObj?.dependencies?.dependents?.length}}</span>
        </span>
        <!-- End : dependency -->
        <!-- Start Collaborator -->
        <span class="option-btn" [class.activeOption]="collaboratorPopover?.isOpen()"  [placement]="['left-bottom','auto']" containerClass="shadow" [ngbPopover]="leadObj && !isReadOnly && leadUsersTemplate" #collaboratorPopover="ngbPopover" container="body" [autoClose]="'outside'">
          <span placement="left" [ngbTooltip]="'Collaborator'" class="ICO" tooltipClass="tooltipPopup">
              <app-dashboard-icon [size]="16"  [name]="'Collaborator'"></app-dashboard-icon>
          </span>
        </span>   
        <ng-template #leadUsersTemplate>
          <app-lead-colloborators [leadObj]="leadObj" dashId={{dashId}} (UpdateCount)="leadPopover.close()"></app-lead-colloborators>
        </ng-template> 
        <!-- End : Collaborator -->
        <!-- Start item options -->
        <ng-container *ngIf="itemConfig?.showSideOptions">
            <span class="option-btn" [class.activeOption]="optionsPopover?.isOpen()"  [placement]="['left-bottom','auto']" containerClass="shadow" [ngbPopover]="leadObj && leadOptionTemplate" #optionsPopover="ngbPopover" container="body" [autoClose]="true">
                <span><img src="../../../assets/common-use-icon-20dp/threeDots-icon.svg" ></span>
            </span>
            <ng-template #leadOptionTemplate>
                <app-lead-option-template 
                [isOpenedFullView]="true"
                [showHiddenFields]="showHiddenFields"
                [dashboardUserRole]="dashboardUserRole"
                [isReadOnly]="isReadOnly"
                [leadInfo]="leadObj" 
                [selectedDashId]="dashId"
                [dashBoardSprint]="dashBoardSprintArr"
                (showSubtaskParentModal)="openRightPopup({type:$event})">
              </app-lead-option-template>
          </ng-template>
        </ng-container>
         <!-- End : item options -->

      </div>
</ng-template>

<ng-template #detailsSvg>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3387_159783)">
        <path d="M3.33398 12.5H16.6673" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M3.33398 4.16732C3.33398 3.9463 3.42178 3.73434 3.57806 3.57806C3.73434 3.42178 3.9463 3.33398 4.16732 3.33398H7.50065C7.72166 3.33398 7.93363 3.42178 8.08991 3.57806C8.24619 3.73434 8.33398 3.9463 8.33398 4.16732V7.50065C8.33398 7.72166 8.24619 7.93363 8.08991 8.08991C7.93363 8.24619 7.72166 8.33398 7.50065 8.33398H4.16732C3.9463 8.33398 3.73434 8.24619 3.57806 8.08991C3.42178 7.93363 3.33398 7.72166 3.33398 7.50065V4.16732Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M3.33398 16.666H13.334" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_3387_159783">
        <rect width="20" height="20" fill="white"/>
        </clipPath>
        </defs>
        </svg>
</ng-template>

<ng-template #mediaSvg>
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3387_159797)">
        <path d="M12.9473 6.75391H12.9571" stroke="white" stroke-width="1.27083" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2.94727 5.08398C2.94727 4.42094 3.21066 3.78506 3.6795 3.31622C4.14834 2.84738 4.78422 2.58398 5.44727 2.58398H15.4473C16.1103 2.58398 16.7462 2.84738 17.215 3.31622C17.6839 3.78506 17.9473 4.42094 17.9473 5.08398V15.084C17.9473 15.747 17.6839 16.3829 17.215 16.8518C16.7462 17.3206 16.1103 17.584 15.4473 17.584H5.44727C4.78422 17.584 4.14834 17.3206 3.6795 16.8518C3.21066 16.3829 2.94727 15.747 2.94727 15.084V5.08398Z" stroke="white" stroke-width="1.27083" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2.94727 13.4221L7.11393 9.25539C7.88727 8.51122 8.8406 8.51122 9.61393 9.25539L13.7806 13.4221" stroke="white" stroke-width="1.27083" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.1152 11.7489L12.9486 10.9155C13.7219 10.1714 14.6752 10.1714 15.4486 10.9155L17.9486 13.4155" stroke="white" stroke-width="1.27083" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_3387_159797">
        <rect width="20" height="20" fill="white" transform="translate(0.447266 0.0859375)"/>
        </clipPath>
        </defs>
    </svg> 
</ng-template>

<ng-template #activitySvg>
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3387_159788)">
        <path d="M2.54297 10.0807H5.82166L8.28067 16.7474L11.5594 3.41406L14.0184 10.0807H17.2971" stroke="white" stroke-width="1.27083" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_3387_159788">
        <rect width="19.6721" height="20" fill="white" transform="translate(0.0839844 0.0859375)"/>
        </clipPath>
        </defs>
    </svg>
</ng-template>

<ng-template #notesSvg>
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3387_159791)">
        <path d="M4.88477 4.25098C4.88477 3.80887 5.0604 3.38486 5.37302 3.07224C5.68564 2.75961 6.10965 2.58398 6.55177 2.58398H14.8848C15.3269 2.58398 15.7509 2.75961 16.0635 3.07224C16.3761 3.38486 16.5518 3.80887 16.5518 4.25098V15.917C16.5518 16.1359 16.5086 16.3527 16.4249 16.5549C16.3411 16.7572 16.2183 16.9409 16.0635 17.0957C15.9087 17.2505 15.7249 17.3733 15.5227 17.4571C15.3204 17.5409 15.1037 17.584 14.8848 17.584H6.55177C6.33285 17.584 6.11608 17.5409 5.91383 17.4571C5.71158 17.3733 5.52781 17.2505 5.37302 17.0957C5.21822 16.9409 5.09543 16.7572 5.01166 16.5549C4.92788 16.3527 4.88477 16.1359 4.88477 15.917V4.25098Z" stroke="white" stroke-width="1.27083" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.21875 5.91406H13.2188" stroke="white" stroke-width="1.27083" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.21875 9.25391H13.2188" stroke="white" stroke-width="1.27083" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.21875 12.584H11.5517" stroke="white" stroke-width="1.27083" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_3387_159791">
        <rect width="20" height="20" fill="white" transform="translate(0.71875 0.0859375)"/>
        </clipPath>
        </defs>
    </svg>
</ng-template>



