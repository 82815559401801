import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild ,} from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { CommonUtils } from 'app/services/CommonUtils.service';
import { DashboardUtilsService } from 'app/services/dashboard-utils.service';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'email-cell',
  changeDetection:ChangeDetectionStrategy.OnPush,
  templateUrl: './email-cell.component.html',
  styleUrls: ['./email-cell.component.scss']
})
export class EmailCellComponent implements OnInit  {
  params:any={};
  editable: boolean = false
  itemInfo:any={}
  value:any;
  validateToEmail:boolean=true;
  enableEditor:boolean=false;
  itemValues:any;
  editing = false;
  @ViewChild('inputBox') inputBox: ElementRef;
  addMoreEmail:boolean=false

  constructor(private commonUtils: CommonUtils,private dashboardUtils:DashboardUtilsService,private cdr:ChangeDetectorRef,private clipboard: Clipboard){
  }

  async ngOnInit() {
    this.onUpdate()
  }
  onEditorEnable(){
    console.log("params",this.itemValues,this.params,this.value)
    this.enableEditor=true;
    this.editing=true
    console.log(this.params)
    setTimeout(() => {this.inputBox?.nativeElement?.focus()},5);
  }

  onUpdate(params: any={}){
    this.itemInfo=this.params?.item || {}
    this.itemValues=this.params?.columnParams?.customField || {}
    this.editable=this.params?.editable
    this.value=this.params?.value;
    this.itemValues['valueData']=[null,undefined].includes(this.value)?'':this.value || null;
    console.log(this.itemValues.multiEmailInput, "To be printed")
    this.cdr.detectChanges()
  }

  async onValueInput(){
    this.addMoreEmail=false
    this.enableEditor=false
    if(this.itemValues?.type=='Email'){
      if(this.validateToEmail && this.editable && this.params.value!=this.value){this.updateValueChanges() }
    }
    else{
      if(this.editable){
        this.updateValueChanges()
      }
    }
  }

  updateValueChanges(){
    this.params?.gridTableRef?.changedCellValue({value:this.value,customFieldType:true,params:this.params});
    this.params.value=this.value
    this.onUpdate() 
  }
  

  ngOnDestroy() {
  }

  public copyToClipboard(value: any): void {
    this.clipboard.copy(value);
  }

  checkEmptyEmail(value){
    this.validateToEmail=this.value.trim().length? this.commonUtils.ValidateEmail(this.value.trim()) : true;
  }

  reAssignValues(){
    this.cdr.detectChanges()
    this.onUpdate()
    console.log("params here after click",this.params,this.itemValues)
  }
}
