<div class="itemInfoBox">
    <div class="row mx-0">
        <div class="col-md-12 text-left itemInfo" *ngIf="isConnection">Connection Info</div>
        <div class="col-md-12 text-left itemInfo" *ngIf="!isConnection">{{'editcustom.itemInfo' | translate :{ term: 'Item' | terminology: itemTerminology?.singular : 'Item' } }}</div>
    </div>
    <div class="row mx-0 mt-1">
        <div class="col-5 itemInfoText" *ngIf="!isConnection">{{'template.boardName' | translate}}</div>
        <div class="col-7 infoValueText" *ngIf="!isConnection">: {{dashName}}</div>
        <div class="col-5 itemInfoText" *ngIf="isConnection">Name</div>
        <div class="col-7 infoValueText" *ngIf="isConnection">: {{dashName}}</div>

        <div class="col-5 itemInfoText" *ngIf="isConnection">Type</div>
        <div class="col-7 infoValueText" *ngIf="isConnection">: {{connectionType == "PRIVATE" ? "Private" : "Shared"}}</div>

        <div class="col-5 itemInfoText">{{'drive.createdDate' | translate}}</div>
        <div class="col-7 infoValueText" *ngIf="!isConnection">: {{leadInfo.createdate | date}}</div>
        <div class="col-7 infoValueText" *ngIf="isConnection">: {{leadInfo.create_date | date}}</div>

        <div class="col-5 itemInfoText">{{'drive.createdBy' | translate}}</div>
        <div class="col-7 infoValueText" *ngIf="orgUsersJson">: {{orgUsersJson[leadInfo.created_by].name}}</div>
        <div class="col-5 itemInfoText">{{'drive.lastModified' | translate}}</div>
        <div class="col-7 infoValueText">: {{leadInfo.update_date | date}}</div>
    </div>
</div>