<div class="cell-box sprint-cell" role="button" #sprintPopover="ngbPopover" [placement]="['bottom','bottom-right','bottom-left','auto']" container="body" [ngbPopover]="params?.editable && sprintTemplate" [autoClose]="'outside'" popoverClass="gridtableCell">
    <div class="sprint-text">
      {{selectedSprint?.name}}
    </div>
</div>
<ng-template #sprintTemplate>
    <app-lead-sprint (click)="$event.stopPropagation();" 
    [dashId]="itemInfo?.dashboard_id" 
    [bulkSelectionFlag]="false"
    [dashBoardSprint]="params?.globalParams?.boardData[this.itemInfo.dashboard_id]?.SPRINT || []"
    [selectedPhase]="params?.value"
    (updateLead)="onSprintUpdate($event);sprintPopover.close()"
    (closePopover)="sprintPopover.close()">
  </app-lead-sprint>
  </ng-template>

