import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild,  SimpleChanges, } from '@angular/core';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { NgxSummernoteModule } from 'ngx-summernote';
import { ConstantService } from '../../../../Enums/Constant.service';
import { CommonUtils } from '../../../services/CommonUtils.service';
import { DashboardUtilsService } from '../../../services/dashboard-utils.service';
import { HttpTransferService } from '../../../services/httpTransfer.service';
import { CustomStorageService } from '../../../services/custom-storage.service';
import { MediaIcons } from '../../../../Enums/media-icons';
import {v4 as uuidv4} from 'uuid';
import { MqttmessagingService } from '../../../services/mqttmessaging.service';
import { AttributesIcons, AttributesLxIcons, commonIcons } from "Enums/attributes-icons";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BasicUtils } from 'app/services/basicUtils.service';
declare var $: any;
@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit {
  @ViewChild('popEmail1') popEmail1 : NgbPopover
  @ViewChild('popEmail2') popEmail2 : NgbPopover
  @ViewChild('popEmail3') popEmail3 : NgbPopover
  @ViewChild("replyAllPop") replyAllPop: NgbPopover;
  @ViewChild("popEmailReplySingleEmail") popEmailReplySingleEmail: NgbPopover;
  @ViewChild("popEmailReplyAllSingleEmail") popEmailReplyAllSingleEmail: NgbPopover;
  @ViewChild("popEmailReplyAllSingleEmail2") popEmailReplyAllSingleEmail2: NgbPopover;
  emailSetCc: any;
  emailSetTo: any;
  @Input() expandEmail:boolean=false;
  @Input() dashId:any;
  @Input() leadInfo: any={};
  @Input() sprintArr:any={};
  @Input() isEmailExpandedView: boolean = false;
  @Output() emitSelectedEmail =  new EventEmitter();
  @Output() emitSelectedEmailThread =  new EventEmitter();
  @Output() emitIsThreadView =  new EventEmitter();
  @Output() connectedLead = new EventEmitter<any>();
  @Input() contactBoardId : any[]=[];
  @Input() comment: any = null;
  @Input() communicationCall: boolean = false;
  @Output() emitDataForThread = new EventEmitter();
  @Input() immediate: boolean = false;
  @Input() fromChatUserEmailConnections:any;
  copyleadInfoForcomment: any={};
  emailArr: any[]=[];
  orgUsersJson: any;
  summerNoteconfig: {
    placeholder: string;
    tabsize: number;
    height: string;
    uploadImagePath: string;
    toolbar: (string | string[])[][];
    fontNames: string[];
  };
  from:any;
  fromEmailObj:any = {};
  allEmailComment:any={}
  allComments:any[]=[]
  allThreadIds:any[]=[]
  userEmailConnections: any[]=[];
  userEmailsConnectedWithAliases: any[]=[];
  editorText: any;
  isAllEmails: boolean = true
  selectedEmail:any=undefined
  isReplyAll:boolean = false;
  usersEmailsAllowedToReply:any[] = []
  defaultSignatures:any = null
  signatureSeparator = ''
  userInfo: any 
  isThreadView = true 
  isEmailCommentsLoaded = false
  filePreviewModal: any;
  currentIndex = -1;
  mediaIcons = MediaIcons;
  emailEditorEmbeddedAttachments: {} = {}
  signatureIndex: number = 1
  signatureId: string = null
  selectedEmailObj: any = {}

  mqttSubscription: any;
  activityTypeKey: string;
  addActivityType: string;
  typeKey: string;
  commentObjectType: string;
  detailsKey: string;
  additionalAttributesKey: string;
  popOverClose:NgbPopover| null=null;
  popOverCloseReplayAll:NgbPopover| null=null;
  enableNewEmail:boolean=false;
  selectedEmailThreadViewDisplay:any[]=[];
  lxAttributesIcons = AttributesLxIcons
  attributesIcons = AttributesIcons;
  emailSignatureForEditor: any;
  isFullScreen:boolean = false;
  isReadOnly: boolean = false;
  threadArr: any[]=[];
  constructor(private modalService: NgbModal,
    private dashboardUtils : DashboardUtilsService,
    private mqttMessagingService: MqttmessagingService,
    private constantService : ConstantService,
    private httpTransfer : HttpTransferService,
    private basicUtils:BasicUtils,
    private customStorageService: CustomStorageService,
    public commonUtils : CommonUtils,
    private sanitizer: DomSanitizer) { 
      this.activityTypeKey = this.constantService.getActivityTypeKey();
      this.addActivityType = this.constantService.getAddActivityType();
      this.typeKey = this.constantService.getObjectTypeKey();
      this.additionalAttributesKey = this.constantService.getAdditionalAttributesKey();
      this.commentObjectType = this.constantService.getCommentObjectType();
      this.detailsKey = this.constantService.getDetailskEY();
      this.listenForMqttMessages();
    }


  async ngOnInit(){
    this.getUserMailConnections()
    if(!this.leadInfo) return;
    this.getComment()
    this.userInfo = await this.commonUtils.getUserJsonFromUserId(this.customStorageService.getItem('id'))
    if(!this.communicationCall) this.getSignatures()
    this.signatureId = uuidv4()
    this.copyleadInfoForcomment=JSON.parse(JSON.stringify(this.leadInfo))
    this.commonUtils.isDashboardReadOnly(this.dashId, []).then((res : any) => { 
      this.isReadOnly = res;
    });
  }

  ngAfterViewInit() {
    setTimeout(()=>{
      if(!this.leadInfo) return this.ngAfterViewInit();
      const element = document.getElementById('unreadmsg_'+this.leadInfo._id);
      element?.scrollIntoView({behavior: "smooth",block: "start",inline: "nearest"})
    },1040)
  }

  getSignatures(){
    let signatureIds = []
    if(this.userInfo?.new_email_signature) signatureIds?.push(this.userInfo?.new_email_signature)
    if(this.userInfo?.reply_forward_email_signature) signatureIds?.push(this.userInfo?.reply_forward_email_signature)
    if(signatureIds?.length)
      this.httpTransfer.queryEmailSignatures({signature_id: signatureIds}).subscribe((res : any)=>{
        if(res && res.result?.email_signature_info?.length){
          let signatures = res.result?.email_signature_info
          this.defaultSignatures = {
            defaultNewEmailSignature: signatures?.find(sign => this.userInfo?.new_email_signature == sign?._id),
            defaultReplyEmailSignature: signatures?.find(sign => this.userInfo?.reply_forward_email_signature == sign?._id)
          }
        }
      })
  }

  listenForMqttMessages() {
    this.mqttSubscription = this.mqttMessagingService.getMqttMessage().subscribe(message => {
      if(message) {
        this.updatesFromWss(message)
      }
    });
  }

  updatesFromWss(messagees) {
    let message = JSON.parse(messagees);
    if (this.activityTypeKey in message) {
      if (message[this.activityTypeKey] == this.addActivityType) {
        if (message[this.typeKey] == this.commentObjectType) {
          if(message[this.additionalAttributesKey][this.detailsKey]['lead_id'] == this.leadInfo['_id']
            && message[this.additionalAttributesKey]['dashboard_id'] == this.dashId
            && message[this.additionalAttributesKey][this.detailsKey]['type'] == 'EMAIL'){
            this.handleCommentTypeOperation(message);
          }
        }
      }
    }
    if (message[ConstantService.constant.OBJECTTYPEKEY] == "LEAD" && this.contactBoardId.includes(message?.additional_attributes?.dashboard_id)){
      this.connectedLead.emit(message?.additional_attributes?.connected_leads)
    }
  }

  handleCommentTypeOperation(message) {
    let commentDetails = message[this.additionalAttributesKey][this.detailsKey];
    if(this.allComments?.some(comment => comment?._id == commentDetails?._id)) return
    this.allThreadIds = [...new Set(this.allThreadIds)]
    let commentObj:any = this.commonUtils.getCommentObject(commentDetails);
    commentObj['mentions'] = message[this.additionalAttributesKey][this.detailsKey]?.mentions || []
    commentObj['type'] = message[this.additionalAttributesKey][this.detailsKey]?.type
    commentObj['lead_id'] = message[this.additionalAttributesKey][this.detailsKey]['lead_id']
    commentObj['email_object'] = commentDetails?.email_object
    this.commonUtils.formatCommentObject(commentObj)
    this.allComments.unshift(commentObj)
    let threadId = commentObj?.email_object?.smtp_thread_id
    if(this.allThreadIds?.includes(threadId)){
      commentObj.smtp_thread_count=1+(this.allEmailComment[threadId][0]?.smtp_thread_count || 0)
      this.allEmailComment[threadId]?.unshift(commentObj)
      if(!this.communicationCall){
         this.setSelectedThreadEmail(this.allEmailComment[this.allThreadIds[0]])
      }
    }
    // else{
    //   this.allEmailComment[threadId] = [commentObj]
    //   this.allThreadIds.unshift(threadId)
    // }
  }

  

  initializeSummerNote(value?) {
    this.isFullScreen = false
    if(!this.from)
    this.from = this.fromEmailObj?.id
    value = this.commonUtils.getSelectedEmailBody(value,this.selectedEmail,this.defaultSignatures,this.signatureId,this.signatureSeparator)
    if(value?.length){
      this.emailSignatureForEditor = value
    }
  }



 async getUserMailConnections(){
    const data = {
      user_id: [localStorage.getItem('id')]
    }
    let userListForReply
    if(!this.communicationCall){
      userListForReply=await this.httpTransfer.getEmailConnections({}).toPromise()
    }
    let res=this.communicationCall?this.fromChatUserEmailConnections:userListForReply
      if(res !== undefined && res) { 
        this.userEmailConnections = res.result.email_connections?.filter(connection => !connection?.is_disabled && !connection?.is_deleted)
        
        this.userEmailsConnectedWithAliases = []
        this.userEmailConnections?.forEach(connection => {
          if(connection?.email_aliases?.length){
            connection?.email_aliases?.forEach((alias, index) => {
              this.userEmailsConnectedWithAliases.push({
                ...connection, 
                  email_address: alias?.sendAsEmail,
                  value: alias?.isPrimary ? alias?.sendAsEmail : `Send email as: ${alias?.sendAsEmail}`,
                  isDefault: alias?.isDefault,
                  isPrimary: alias?.isPrimary != undefined ? alias?.isPrimary : false,
                  name: alias?.displayName,
                  id: `${connection?.id}${index}` 
              })
            })
          }else{
            this.userEmailsConnectedWithAliases.push({
              ...connection,
              value: connection?.email_address, 
                isPrimary: true, 
                id: `${connection?._id}_0` 
            })
          }
        }) 

        this.usersEmailsAllowedToReply = this.userEmailsConnectedWithAliases
        this.fromEmailObj = this.usersEmailsAllowedToReply[0]
        this.from = this.fromEmailObj?.id
      }
  }

 async getComment(){
    if(!this.communicationCall){
      var inputJson = {dashboard_id: [this.dashId],};
      let res = await this.httpTransfer.getCommentCorrespondingLead({dashboard_id: [this.dashId],lead_id: [this.leadInfo._id]}).toPromise()
      let threadArr = res.result?.commentsInfo.filter(c=>c?.type=='THREAD') || []
      inputJson['thread_id'] = threadArr?.map(item=>item?._id)
      this.httpTransfer
        .getCommentCorrespondingLead(inputJson)
        .subscribe((res : any) => {
          this.isEmailCommentsLoaded = true
          this.allComments = res.result["commentsInfo"];
          this.allThreadIds = this.allComments?.map(comment => comment?.email_object?.smtp_thread_id)
          this.allThreadIds = [...new Set(this.allThreadIds)]
          this.allComments.forEach(comment=>{
            this.commonUtils.formatCommentObject(comment)
          })
  
          this.allEmailComment = this.allComments.reduce(function (r, a) {
            r[a.email_object?.smtp_thread_id] = r[a.email_object?.smtp_thread_id] || [];
            r[a.email_object?.smtp_thread_id].push(a);
            return r;
          }, Object.create(null));
          this.emitIsThreadView.emit(this.isThreadView && this.allEmailComment)
          if(res?.result["commentsInfo"] && !this.isReadOnly){
            this.enableNewEmail=true
          }
        })
    }
    else{
      this.isEmailCommentsLoaded = true
      this.allComments=[]
      this.allComments.push(this.comment)
      this.allComments.forEach(comment=>{
        this.commonUtils.formatCommentObject(comment)
      })
      this.allEmailComment = this.allComments.reduce(function (r, a) {
        r[a.email_object?.smtp_thread_id] = r[a.email_object?.smtp_thread_id] || [];
        r[a.email_object?.smtp_thread_id].push(a);
        return r;
      }, Object.create(null));
      this.allThreadIds = [this.allComments[0]?.email_object?.smtp_thread_id]
      this.emitIsThreadView.emit(this.isThreadView && this.allEmailComment)
      if(!this.isReadOnly)
      this.enableNewEmail=true
      if(this.immediate) {
        this.getSignatures()
        this.setSelectedEmail(this.allEmailComment[this.allThreadIds[0]][0])
        this.setSelectedThreadEmail(this.allEmailComment[this.allThreadIds[0]])}
    }
  }


  setSelectedEmail(email, isAllEmails=false){ 
    this.selectedEmail = email?.email_object
    this.selectedEmail.receivedTime = email?.receivedTime
    this.selectedEmail.createdTime = email?.createdTime
    let backquoteForReply = `<br><br>
    <backquote class="gmail_quote" style="margin:0px 0px 0px 0.8ex;border-left:1px solid rgb(204,204,204);padding-left:1ex;">
      <div dir="ltr" class="gmail_attr">
      On ${email?.receivedTime ? email?.receivedTime?.date : email?.createdTime?.date} at ${email?.receivedTime ? email?.receivedTime?.time : email?.createdTime?.time} &lt;<a href="mailto:${this.selectedEmail?.from?.address}">${this.selectedEmail?.from?.address}</a>&gt; wrote:<br>       
      </div>`
    if(this.selectedEmail?.body?.html) { 
      this.selectedEmail.body.backQuoteHtmlReply = `${backquoteForReply} ${this.selectedEmail?.body?.html} </backquote>`
    }if(this.selectedEmail?.body?.text)  
      this.selectedEmail.body.backQuoteTextReply = `${backquoteForReply} ${this.selectedEmail?.body?.text} </backquote>`
    
    if(!this.communicationCall){
       this.isAllEmails = this.isEmailExpandedView ? true : isAllEmails
    }
    let toEmailAddresses = this.selectedEmail?.to?.map(item => item.address)
    let allowedEmailsToReply = [
      this.selectedEmail?.from?.address,
      ...toEmailAddresses,
      ...this.selectedEmail?.cc?.map(item => item.address), 
    ]
    // this.usersEmailsAllowedToReply = []
    
    let allowedEmails = this.userEmailsConnectedWithAliases?.filter(email => allowedEmailsToReply?.includes(email.email_address))
    // allowedEmails?.forEach(item => {
    //   this.usersEmailsAllowedToReply = [...this.usersEmailsAllowedToReply, ...this.userEmailsConnectedWithAliases?.filter(connection => connection?.id == item?.id) ]
    // })
    this.fromEmailObj = allowedEmails?.find(item => toEmailAddresses?.includes(item?.email_address))
    if(!this.fromEmailObj)
      this.fromEmailObj = this.usersEmailsAllowedToReply[0]
    this.from = this.fromEmailObj?.id
    this.selectedEmail['addresses'] = {
      to: this.selectedEmail?.to?.map(email => email.address)?.join(', '),
      bcc: this.selectedEmail?.bcc?.map(email => email.address)?.join(', '),
      cc: this.selectedEmail?.cc?.map(email => email.address)?.join(', '),
      from: this.selectedEmail?.from?.address,
      date: this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(this.selectedEmail?.received_time))
    }
    this.selectedEmail.attachments = this.selectedEmail?.originalAttachments
    this.selectedEmail?.attachments?.forEach(file => {
      file.extension = file?.file_name?.split('.').pop()
      file.name = file?.file_name
      file.type = file.type == 'IMAGE' ? 'MultipleImage' : (file.type == 'VIDEO' ? 'MultipleVideo' : 'MultipleFile')
    })
    
    this.selectedEmailObj = {
      selectedEmail:this.selectedEmail,
      defaultSignatures: this.defaultSignatures,
      usersEmailsAllowedToReply: this.usersEmailsAllowedToReply,
      userEmailsConnectedWithAliases: this.userEmailsConnectedWithAliases,
      from: this.from
    }
    // this.emitSelectedEmail.emit(this.selectedEmailObj)
  }

  changeEmail(event){
    if(event)
      this.fromEmailObj = this.userEmailsConnectedWithAliases?.find(item => item.id == this.from)
  }

  connectEmail(source){
    let url = (source == 'GOOGLE') ?this.constantService.getConnectEmailGoogleUrl() : this.constantService.getConnectEmailMircosoftUrl();
    window.location.replace(url);
  }

  closeAllPopups(){
    console.log("close")
    this.popOverClose?.close()
    this.popOverCloseReplayAll?.close()
    this.popEmail1?.close();
    this.popEmail2?.close();
    this.popEmail3?.close();
    this.replyAllPop?.close();
    this.popEmailReplySingleEmail?.close()
    this.popEmailReplyAllSingleEmail?.close()
    this.popEmailReplyAllSingleEmail2?.close()
  }

  openPreviewPopup(index: number, template: TemplateRef<any>) {
    this.filePreviewModal = this.modalService.open(template, { size: 'lg' , windowClass: 'file preview-modal'}) 
    this.currentIndex = index;
    this.basicUtils.storeModlRef(this.filePreviewModal)
  }

  goBackToAllEmailView(){
    this.isAllEmails=true
    if(!this.isEmailExpandedView)
      this.selectedEmail=null;
    this.emitSelectedEmail.emit(null)
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.isEmailExpandedView?.currentValue){
      this.isAllEmails = true
      this.emitSelectedEmail.emit({
        selectedEmail:this.selectedEmail,
        defaultSignatures: this.defaultSignatures,
        usersEmailsAllowedToReply: this.usersEmailsAllowedToReply,
        userEmailsConnectedWithAliases: this.userEmailsConnectedWithAliases,
        from: this.from
      })
      // this.emitSelectedEmailThread.emit(null)
    }else if(!changes.isEmailExpandedView?.currentValue && this.isAllEmails){
      this.selectedEmail = null
      this.emitSelectedEmail.emit(null)
      // this.emitSelectedEmailThread.emit(this.selectedEmailThreadViewDisplay)
    }
    if(changes.leadInfo){this.ngOnInit()}
    console.log(this.selectedEmailThreadViewDisplay,"check emitter")
  }

  setSelectedThreadEmail(emailContent){
    this.selectedEmailThreadViewDisplay=[]
    this.selectedEmailThreadViewDisplay.push(emailContent)
    // this.emitSelectedEmailThread.emit(this.selectedEmailThreadViewDisplay)
    // this.emitSelectedEmail.emit(null)
    // this.selectedEmailThreadViewDisplay.emit(...emailContent)
    this.emitDataForThread.emit({
      selectedEmailThreadViewDisplay: this.selectedEmailThreadViewDisplay,
      userEmailsConnectedWithAliases: this.userEmailsConnectedWithAliases,
      selectedEmailObj: this.selectedEmailObj,
      leadInfo: this.leadInfo,
      sprintArr: this.sprintArr
    })
    console.log('emitted data');
  }

  onFullview(){
    this.isFullScreen = !this.isFullScreen
  }

  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  ngOnDestroy() {
    this.mqttSubscription?.unsubscribe();
  }
}