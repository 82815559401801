<div class="d-flex flex-row justify-content-center align-items-center cell-box" role="button" #priorityPopOver="bs-popover" placement="bottom" container="body" [popover]="params?.editable && priorityTemplate" [outsideClick]="true" containerClass="gridtableCell">
    <span width="20px" class="priority-item d-flex justify-content-center align-items-center" [style.background]="priorityJson[params.value]?.color">
        <span>{{priorityJson[params.value]?.label}}</span>
    </span>  
</div>
<ng-template #priorityTemplate>
    <app-lead-priority (click)="$event.stopPropagation()"
        (updateLead)="updatePriority($event);priorityPopOver?.hide()" [bulkSelectionFlag]="false">
    </app-lead-priority>
</ng-template>
