import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CommonUtils } from '../../../services/CommonUtils.service';
import { DashboardUtilsService } from '../../../services/dashboard-utils.service';
import { AttributesIcons, commonIcons } from 'Enums/attributes-icons';

@Component({
  selector: 'app-user-selected',
  templateUrl: './user-selected.component.html',
  styleUrls: ['./user-selected.component.scss']
})
export class UserSelectedComponent implements OnInit {
  @ViewChild('container', { static: false }) container!: ElementRef;
  @Input() dashIdSelected: string;
  @Input() removeFromSelected: any;
  @Input() userList:any[]
  @Input() formCall:boolean=false;
  @Input() customCall:boolean=false;
  @Input() addedUserIds:any[] = []
  @Input() isGridView:boolean = false;
  @Input() isReadOnly:boolean = false;
  @Input() isMultipleAllowed:boolean = true;
  @Input() noteUsersCall:boolean=false;

  @Output("selectedUserList") selectedUserList: EventEmitter<any> = new EventEmitter();
  @Output("removeSelectedUsers") removeSelectedUsers: EventEmitter<any> = new EventEmitter();
  @Output() removedUserId: EventEmitter<string> = new EventEmitter();
  dashboardUser: any[]=[];
  showList: boolean=false;
  showOrg: boolean=false;
  orgUsersJson: any[]=[];
  selectedUser: any[]=[];
  invalidSearch:boolean=false;
  allowUserToInvite:boolean=false;
  searchEmail:string="";
  inviteMessage:boolean=false;
  searchOrgUser: any[];
  searchDashUser: any[];
  selectedIndex:any=0

  commonIcons = commonIcons
  AttributesIcons = AttributesIcons

  @ViewChild('searchInput') searchInput!: ElementRef<HTMLInputElement>;
  constructor(private commonutils :CommonUtils, private dashboardUtils: DashboardUtilsService) {}

  ngOnInit(): void {
    this.getDashUser()
    if(this.customCall || this.noteUsersCall){this.showList = false}
    else{this.showList = true}
  }

  ngAfterViewInit(){
    this.searchInput.nativeElement.focus();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.removeFromSelected!=undefined){
      this.removeUser()
    }
    if(this.userList?.length){
      setTimeout(() => {
        this.removeFromList()
      }, 500);
   }
  }
  
  listView(){
    this.showList=true;
  }

  hideList(){
    this.showList=false;
  }

  showAll(){
    this.showOrg= !this.showOrg;
  }
  
  async getDashUser(){
    let dashDataJson = await this.dashboardUtils.getDashboardUsers([this.dashIdSelected])
    this.dashboardUser=dashDataJson[this.dashIdSelected]?.DASHBOARD_USER || []
    if(!this.dashboardUser?.length)this.showOrg=true
    this.dashboardUser=[...this.dashboardUser]
    const orgUserJson = await this.dashboardUtils.getOrgUsers(); 
    this.orgUsersJson = Object.values(orgUserJson)
    this.orgUsersJson =  this.orgUsersJson.filter(user => !user.is_bot);

    this.searchOrgUser = [...this.orgUsersJson]
    if(this.isReadOnly){
      this.dashboardUser = this.dashboardUser.filter(user => this.addedUserIds.includes(user._id))
      this.orgUsersJson = this.orgUsersJson.filter(user => this.addedUserIds.includes(user._id))
    }else{
      if(this.addedUserIds?.length){
        this.dashboardUser?.sort((user1, user2) => this.addedUserIds.indexOf(user2._id) - this.addedUserIds.indexOf(user1._id))
        this.orgUsersJson?.sort((user1, user2) => this.addedUserIds.indexOf(user2._id) - this.addedUserIds.indexOf(user1._id))
      }       
      this.addKeyForDashUser()
    }
  }

  addKeyForDashUser(){
    this.dashboardUser.forEach(selUser => {      
      let delIndex = this.orgUsersJson.findIndex(user => user._id === selUser._id);
      if (delIndex > -1) {        
        this.orgUsersJson[delIndex]['isDashUser'] = true;
      }
    });   
    this.searchDashUser = [...this.dashboardUser]
  }
  
  addDashUser(item){
    item['isDashUser'] = true;
    if(!this.isGridView){
      for(let i=0;i<this.dashboardUser.length;i++){
        if(item._id == this.dashboardUser[i]._id){
          this.dashboardUser.splice(i,1)
        }
      }
      for(let i=0;i<this.orgUsersJson.length;i++){
        if(item._id == this.orgUsersJson[i]._id){
          this.orgUsersJson.splice(i,1)
        }
      }
    }else{
      if(!this.isMultipleAllowed){
        this.addedUserIds = [item._id]
      }else
        this.addedUserIds.push(item._id)
    }
    this.selectedUserList.emit(item)
  }

  addAllUser(item){
    for(let i=0;i<this.dashboardUser.length;i++){
      if(item._id == this.dashboardUser[i]._id){
        this.dashboardUser.splice(i,1)    
      }
    }
    for(let i=0;i<this.orgUsersJson.length;i++){
      if(item._id == this.orgUsersJson[i]._id){
        this.orgUsersJson.splice(i,1)
      }
    }
    item['isDashUser'] = item.isDashUser ? true : false;;
    this.selectedUserList.emit(item)
  }

  removeUser(){
    if(this.removeFromSelected.isDashUser){
     this.dashboardUser.push(this.removeFromSelected)
     this.orgUsersJson.push(this.removeFromSelected)
     this.dashboardUser =  this.dashboardUser.sort((a, b) => a.name > b.name ? 1 : -1)    
    }
    else{
      this.orgUsersJson.push(this.removeFromSelected)
    }
    this.orgUsersJson =  this.orgUsersJson.sort((a, b) => a.name > b.name ? 1 : -1)    
  }
  
  async removeFromList(){
    let dashDataJson = await this.dashboardUtils.getDashboardUsers([this.dashIdSelected])
    this.dashboardUser=dashDataJson[this.dashIdSelected]?.DASHBOARD_USER || []
    this.dashboardUser=[...this.dashboardUser]
    const orgUserJson = await this.dashboardUtils.getOrgUsers(); 
    this.orgUsersJson = Object.values(orgUserJson)
    this.addKeyForDashUser()
    this.userList.forEach(item=>{
         for(let m=0;m<this.orgUsersJson.length;m++){
           if(item._id == this.orgUsersJson[m]._id){
             this.orgUsersJson.splice(m,1)
           }
         }
         for(let t=0;t<this.dashboardUser.length;t++){
          if(item._id == this.dashboardUser[t]._id){
            this.dashboardUser.splice(t,1)
          }
        }
        this.orgUsersJson =  this.orgUsersJson.sort((a, b) => a.name > b.name ? 1 : -1)    
        this.dashboardUser =  this.dashboardUser.sort((a, b) => a.name > b.name ? 1 : -1)    
        this.orgUsersJson=[...this.orgUsersJson]
        this.dashboardUser=[...this.dashboardUser]
        
    })
  }

  filterUser(val) {
    if(this.noteUsersCall) {
    this.showOrg = true;
    }
    if(this.userList?.length){
      this.searchOrgUser = this.searchOrgUser.filter(user => !this.userList.some(searchuser => user._id === searchuser._id))
      this.searchDashUser = this.searchDashUser.filter(user => !this.userList.some(searchuser => user._id === searchuser._id))  
    }
    if(this.showOrg){
    val = val?.toString()?.toLowerCase();
    this.orgUsersJson =  this.searchOrgUser.filter(
      (user) => user?.name?.toLowerCase()?.includes(val) || user?.email?.toLowerCase()?.includes(val) 
    );
  }
    if(!this.showOrg){
      let search=this.dashboardUser
      val = val?.toLowerCase();
    this.dashboardUser =  this.searchDashUser.filter(
      (user) => user?.name?.toLowerCase()?.includes(val)|| user?.email?.toLowerCase()?.includes(val)
    );
    }
    if(val == ""){
      this.invalidSearch=false;
      this.allowUserToInvite=false;
      this.searchEmail=val;
      this.removeFromList()
     }
     else{
      this.searchUser(val);
     }
    
  }

  removeUserFromList(item){
    if(!this.dashboardUser.find(user => user._id == item._id))
      this.dashboardUser.push(item)
    this.emitRemovedUser(item._id)
  }

  removeAllUserFromList(item){
    if(!this.orgUsersJson.find(user => user._id == item._id))
      this.orgUsersJson.push(item)  
    this.emitRemovedUser(item._id)
  }  

  emitRemovedUser(id){
    if(!this.addedUserIds.includes(id)) this.addedUserIds.splice(this.addedUserIds.findIndex(userId => id == userId), 1)
    this.removedUserId.emit(id)
  }
  inviteUserAsExternalCollaborator(externalCollabEmailId){
    this.addDashUser({_id:externalCollabEmailId,isDashUser:false})
    this.inviteMessage=true;
    this.allowUserToInvite=false;
    setTimeout(() => {
      this.inviteMessage=false;
    }, 3000);
  }
  searchUser(val){
    val = val?.toString()?.toLowerCase();
    this.orgUsersJson =  this.searchOrgUser.filter(
      (user) => user?.name?.toLowerCase()?.includes(val) || user?.email?.toLowerCase()?.includes(val) 
    );
    if((!this.orgUsersJson || this.orgUsersJson.length===0) &&  !this.commonutils.ValidateEmail(val)){
      this.invalidSearch=true;
    }else{
      this.invalidSearch=false;
    }
    if(this.orgUsersJson.length ===0 && !this.invalidSearch){
      this.allowUserToInvite=true;
      this.searchEmail=val;
    }else{
      this.allowUserToInvite=false;
    }
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    let list=[]
    switch (event.key) {
      case 'Tab':
        event.preventDefault()
        if(this.selectedIndex==-1){
          this.selectedIndex=0
          this.commonutils.ensureVisible(this.container,this.selectedIndex);
        }else{
          this.selectedIndex=-1
        }
        break;
      case 'ArrowUp':
        if (this.selectedIndex >=0) {
          this.selectedIndex--;
          this.commonutils.ensureVisible(this.container,this.selectedIndex);
        }
        break;
      case 'ArrowDown':
        list=this.showOrg ? this.orgUsersJson : this.dashboardUser
        if (this.selectedIndex < list.length - 1) {
          this.selectedIndex++;
          this.commonutils.ensureVisible(this.container,this.selectedIndex+1);
        }
        break;
      case 'Enter':
        list=this.showOrg ? this.orgUsersJson : this.dashboardUser
        if(this.selectedIndex==-1)this.showAll()
        else if(this.selectedIndex<list.length && !this.isReadOnly){
          if(!this.addedUserIds?.includes(list[this.selectedIndex]._id)){
            this.addDashUser(list[this.selectedIndex]);
          }
          else{
            this.removeAllUserFromList(list[this.selectedIndex]);
          }
        }
        break;
    }
  }
  handleRemoveUser(item){
    this.removeSelectedUsers.emit(item);
  }
}
