

<!-- ISSUE WITH TEXT-OVERFLOW: ELLIPSIS -->
<div  class="cell-box cursorPointer position-relative" #mediaPopover="ngbPopover" [placement]="['bottom','bottom-right','bottom-left','auto']" container="body" [ngbPopover]="mediaPopoverTemp" popoverClass="gridtableCell" [autoClose]="'outside'" >   
  <div class="cell-box-inner" [ngClass]="{'enable-editor-box': isLoaderShow && !mediaPopover.isOpen()}">
    <div class="left-box">
      <ng-container *ngFor="let media of mediaList | slice:0:1;">
          <span class="mediaInfo">
            <img *ngIf="mediaType=='MultipleImage' || mediaType=='SingleImage'" src="/assets/1x/jpeg-icon.svg" class="mr-1">
            <img *ngIf="mediaType=='MultipleFile' || mediaType=='SingleFile'" src="/assets/1x/upload-icon.svg" class="mr-1">
            <img *ngIf="mediaType=='MultipleVideo' || mediaType=='SingleVideo'" src="/assets/1x/VideoIcon.svg" class="mr-1">
            <img *ngIf="mediaType=='MultipleAudio' || mediaType=='SingleAudio'" src="/assets/1x/audio.svg" class="mr-1"style="width: 16px;height:16px;">
            <span class="mr-1 text-box">{{media?.file_name}}</span>
          </span>
      </ng-container>
      <ng-container *ngIf="!mediaList?.length">
        <div class="cell-input-box" style="opacity: 0.5;">
          {{'common.upload' | translate}}
        </div>
      </ng-container>
    </div>
    <div class="right-box" [class.expanField]="mediaList?.length>1 || isLoaderShow && !mediaPopover.isOpen()">
      <span class="valueCount"  *ngIf="mediaList?.length>1">{{"+"+(mediaList?.length-1)}}</span>
    </div> 
  </div>
  <div class="uploadButton">
    <ng-container *ngIf="!isLoaderShow;else loader">
      <div *ngIf="editable"
      [ngbTooltip]="('common.upload' | translate)" placement="top" [tooltipClass]="'tooltipPopup'" triggers="hover" 
       (click)="$event.stopPropagation()">
        <ng-container *ngTemplateOutlet="uploadBtn"></ng-container>
      </div>
      <div *ngIf="!editable" class="right-upload-btn expandIcon">
        <svg viewBox="0 0 20 20" fill="blue" width="16" height="16" aria-hidden="true" aria-label="Open" class="icon_component icon_component--no-focus-style"><path d="M12.5148 2.49976C12.1212 2.49976 11.8022 2.82125 11.8022 3.21784 11.8022 3.61443 12.1212 3.93593 12.5148 3.93593H14.6466L10.7369 8.15315C10.4682 8.44295 10.4836 8.89735 10.7711 9.16809 11.0587 9.43883 11.5096 9.42339 11.7783 9.1336L15.5746 5.03862V7.28699C15.5746 7.68358 15.8936 8.00507 16.2872 8.00507 16.6807 8.00507 16.9997 7.68358 16.9997 7.28699V3.23812C17.0052 3.03946 16.9293 2.83946 16.7739 2.69313 16.6366 2.56389 16.4621 2.49986 16.288 2.49976L12.5148 2.49976zM9.26311 11.8464C9.53177 11.5566 9.51644 11.1022 9.22888 10.8314 8.94131 10.5607 8.4904 10.5761 8.22174 10.8659L4.42513 14.9612V12.7124C4.42513 12.3158 4.1061 11.9943 3.71256 11.9943 3.31903 11.9943 3 12.3158 3 12.7124V16.7815C3 16.8649 3.01412 16.945 3.04007 17.0195 3.07689 17.1255 3.13902 17.2244 3.22611 17.3064 3.36743 17.4394 3.54818 17.5034 3.72738 17.4996H7.48496C7.8785 17.4996 8.19752 17.1781 8.19752 16.7815 8.19752 16.3849 7.8785 16.0634 7.48496 16.0634H5.35358L9.26311 11.8464z"></path></svg>
      </div>
    </ng-container>
    <ng-template #loader>
      <span class="upload-loader right-loader" [ngbTooltip]="('common.updating' | translate)" placement="top" [tooltipClass]="'tooltipPopup'" triggers="hover">
        <span class="spinner-border spinner-border-sm m-0" role="status" aria-hidden="true"></span>
      </span>
    </ng-template>
  </div>
</div>

<ng-template #mediaPopoverTemp>
  <div class="media-info">
    <div *ngIf="editable" class="update-btn-wrapper">
      <ng-container *ngIf="!isLoaderShow">
        <!-- <ng-container *ngTemplateOutlet="uploadBtn"></ng-container> -->
      </ng-container>
      <ng-container *ngIf="isLoaderShow">
        <span class="upload-loader" id="loader" type="button" disabled>
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          {{'common.updating' | translate}}  
        </span>
      </ng-container>
    </div>
    <div class="media-wrapper-box">
      <ng-container *ngIf="mediaList.length>0;else emptyData"><ng-template *ngTemplateOutlet="mediaTemplateRef"></ng-template></ng-container>
      <ng-template #emptyData>
        <diV class="empty-media">{{'media.noFilesMessage' | translate}}  </diV>
      </ng-template>
    </div>
  </div>
</ng-template>

<!-- upload btn template with media type check -->

<ng-template #uploadBtn>
    <ng-container *ngIf="mediaType=='MultipleImage'">
      <!-- <input style="display: none;" #mediaFile type="file"  name="mediaFile" accept="image/*" multiple (change)="onUploadFile($event.target.files)" /> -->
      <span class="addCreateConnectBtn" (click)="uploadPopover.isOpen()? uploadPopover.close(): uploadPopover.open();">
        <span class="iconBox"><i class="fa fa-cloud-upload" aria-hidden="true" id="userIcon"></i></span>
        <span class="upload-text">{{ (isSingleMediaType() && mediaList.length>0 ? 'automation.change' : 'common.upload') | translate}}</span>
      </span> 
      <app-drive-attachment #uploadPopover 
      [leadInfo]="leadObj" [dashId]="dashId" [sprintArr]="dashBoardSprintArr" 
      [orgUsersJson]="orgUsersJson" [singleSelection]="false" [typeOfDoc]="'IMAGE'"  
      (filesJson)="uploadUrlLink($event);uploadPopover.close()">
      </app-drive-attachment>
    </ng-container>
    <ng-container *ngIf="mediaType=='MultipleFile'">
      <!-- <input style="display: none;" #mediaFile type="file"  name="mediaFile" accept="application/pdf, .xls,.xlsx,.doc,.docx ,application/zip,.rar,.7zip,application/vnd.oasis.opendocument.text" multiple  (change)="onUploadFile($event.target.files)" /> -->
      <span class="addCreateConnectBtn" (click)="uploadPopover.isOpen()? uploadPopover.close(): uploadPopover.open();">
        <span class="iconBox"><i class="fa fa-cloud-upload" aria-hidden="true" id="userIcon"></i></span>
        <span class="upload-text">{{ (isSingleMediaType() && mediaList.length>0 ? 'automation.change' : 'common.upload') | translate}}</span>
      </span> 
      <app-drive-attachment #uploadPopover 
      [leadInfo]="leadObj" [dashId]="dashId" [sprintArr]="dashBoardSprintArr" 
      [orgUsersJson]="orgUsersJson" [singleSelection]="false" [typeOfDoc]="'FILE'"  
      (filesJson)="uploadUrlLink($event);uploadPopover.close()">
      </app-drive-attachment>
    </ng-container>
    <ng-container *ngIf="mediaType=='MultipleVideo'">
      <!-- <input style="display: none;" #mediaFile type="file"  name="mediaFile" accept="video/*" multiple (change)="onUploadFile($event.target.files)"/> -->
      <span class="addCreateConnectBtn" (click)="uploadPopover.isOpen()? uploadPopover.close(): uploadPopover.open();">
        <span class="iconBox"><i class="fa fa-cloud-upload" aria-hidden="true" id="userIcon"></i></span>
        <span class="upload-text">{{ (isSingleMediaType() && mediaList.length>0 ? 'automation.change' : 'common.upload') | translate}}</span>
      </span> 
      <app-drive-attachment #uploadPopover 
      [leadInfo]="leadObj" [dashId]="dashId" [sprintArr]="dashBoardSprintArr" 
      [orgUsersJson]="orgUsersJson" [singleSelection]="false" [typeOfDoc]="'VIDEO'"  
      (filesJson)="uploadUrlLink($event);uploadPopover.close()">
      </app-drive-attachment>
    </ng-container>
    <ng-container *ngIf="mediaType=='MultipleAudio'">
      <!-- <input style="display: none;" #mediaFile type="file"  name="mediaFile" accept="audio/*" multiple   (change)="onUploadFile($event.target.files)"/> -->
      <span class="addCreateConnectBtn" (click)="uploadPopover.isOpen()? uploadPopover.close(): uploadPopover.open();">
        <span class="iconBox"><i class="fa fa-cloud-upload" aria-hidden="true" id="userIcon"></i></span>
        <span class="upload-text">{{ (isSingleMediaType() && mediaList.length>0 ? 'automation.change' : 'common.upload') | translate}}</span>
      </span> 
      <app-drive-attachment #uploadPopover 
      [leadInfo]="leadObj" [dashId]="dashId" [sprintArr]="dashBoardSprintArr" 
      [orgUsersJson]="orgUsersJson" [singleSelection]="false" [typeOfDoc]="'AUDIO'"  
      (filesJson)="uploadUrlLink($event);uploadPopover.close()">
      </app-drive-attachment>
    </ng-container>
    <ng-container *ngIf="mediaType=='SingleImage'">
      <!-- <input style="display: none;" #mediaFile type="file"  name="mediaFile" accept="image/*" single (change)="onUploadFile($event.target.files)" /> -->
      <span class="addCreateConnectBtn" (click)="uploadPopover.isOpen()? uploadPopover.close(): uploadPopover.open();">
        <span class="iconBox"><i class="fa fa-cloud-upload" ara-hidden="true" id="userIcon"></i></span>
        <span class="upload-text">{{ (isSingleMediaType() && mediaList.length>0 ? 'automation.change' : 'common.upload') | translate}}</span>
      </span> 
      <app-drive-attachment #uploadPopover 
      [leadInfo]="leadObj" [dashId]="dashId" [sprintArr]="dashBoardSprintArr" 
      [orgUsersJson]="orgUsersJson" [singleSelection]="true" [typeOfDoc]="'IMAGE'"  
      (filesJson)="uploadUrlLink($event);uploadPopover.close()">
      </app-drive-attachment>
    </ng-container>
    <ng-container *ngIf="mediaType=='SingleFile'">
      <!-- <input style="display: none;" #mediaFile type="file"  name="mediaFile" accept="application/pdf, .xls,.xlsx,.doc,.docx ,application/zip,.rar,.7zip,application/vnd.oasis.opendocument.text" single  (change)="onUploadFile($event.target.files)"/> -->
      <span class="addCreateConnectBtn" (click)="uploadPopover.isOpen()? uploadPopover.close(): uploadPopover.open();">
        <span class="iconBox"><i class="fa fa-cloud-upload" aria-hidden="true" id="userIcon"></i></span>
        <span class="upload-text">{{ (isSingleMediaType() && mediaList.length>0 ? 'automation.change' : 'common.upload') | translate}}</span>
      </span> 
      <app-drive-attachment #uploadPopover 
      [leadInfo]="leadObj" [dashId]="dashId" [sprintArr]="dashBoardSprintArr" 
      [orgUsersJson]="orgUsersJson" [singleSelection]="true" [typeOfDoc]="'FILE'"  
      (filesJson)="uploadUrlLink($event);uploadPopover.close()">
      </app-drive-attachment>
    </ng-container>
    <ng-container *ngIf="mediaType=='SingleVideo'">
      <!-- <input style="display: none;" #mediaFile type="file"  name="mediaFile" accept="video/*" single (change)="onUploadFile($event.target.files)"/> -->
      <span class="addCreateConnectBtn" (click)="uploadPopover.isOpen()? uploadPopover.close(): uploadPopover.open();">
        <span class="iconBox"><i class="fa fa-cloud-upload" aria-hidden="true" id="userIcon"></i></span>
        <span class="upload-text">{{ (isSingleMediaType() && mediaList.length>0 ? 'automation.change' : 'common.upload') | translate}}</span>
      </span> 
      <app-drive-attachment #uploadPopover 
      [leadInfo]="leadObj" [dashId]="dashId" [sprintArr]="dashBoardSprintArr" 
      [orgUsersJson]="orgUsersJson" [singleSelection]="true" [typeOfDoc]="'VIDEO'"  
      (filesJson)="uploadUrlLink($event);uploadPopover.close()">
      </app-drive-attachment>
    </ng-container>
    <ng-container *ngIf="mediaType=='SingleAudio'">
      <!-- <input style="display: none;" #mediaFile type="file"  name="mediaFile" accept="audio/*" single (change)="onUploadFile($event.target.files)"/> -->
      <span class="addCreateConnectBtn" (click)="uploadPopover.isOpen()? uploadPopover.close(): uploadPopover.open();">
        <span class="iconBox"><i class="fa fa-cloud-upload" aria-hidden="true" id="userIcon"></i></span>
        <span class="upload-text">{{ (isSingleMediaType() && mediaList.length>0 ? 'automation.change' : 'common.upload') | translate}}</span>
      </span> 
      <app-drive-attachment #uploadPopover 
      [leadInfo]="leadObj" [dashId]="dashId" [sprintArr]="dashBoardSprintArr" 
      [orgUsersJson]="orgUsersJson" [singleSelection]="true" [typeOfDoc]="'AUDIO'"  
      (filesJson)="uploadUrlLink($event);uploadPopover.close()">
      </app-drive-attachment>
    </ng-container>
</ng-template>

<!-- render media   -->
<ng-template #mediaTemplateRef>
  <ng-container *ngFor="let file of mediaList let i = index">
    <ng-container>
      <div class="showImageBox" *ngIf="file.extension" [ngbPopover]="file?.file_name?.length > 5 && !popoverRef?.isOpen() && file?.file_name + '.' + ((file?.type!=='link' && file?.type!=='one_drive' && file?.type!=='google_drive')?file?.extension || '':'')" placement="top" container="body" triggers="hover">
        <div class="show-image m-0" #deletePopupRef="ngbPopover" [ngbPopover]="deletePopup" placement="bottom auto" triggers="manual" [autoClose]="'outside'">
            <ng-container  *ngIf="mediaType=='MultipleImage' || mediaType=='SingleImage' || ['SingleFile','MultipleFile'].includes(mediaType) && constantService?.getMediaTypes()?.IMAGE?.includes(file.extension);else checkAudio">
              <app-dashboard-icon *ngIf="file?.type==='google_drive'" style="margin-top: -3px !important;" [name]="'googleDrive'" [iconJson]="attributesIcons" [size]="17" class="labelimg"></app-dashboard-icon>
              <app-dashboard-icon *ngIf="file?.type==='one_drive'" style="margin-top: -3px !important;" [name]="'oneDrive'" [iconJson]="attributesIcons" [size]="17" class="labelimg"></app-dashboard-icon>
              <app-dashboard-icon *ngIf="file?.type==='link' || file?.type==='one_drive' || file?.type==='google_drive'" [name]="file.extension" [iconJson]="mediaIcons" size="100"></app-dashboard-icon>
              <img *ngIf="file?.type!=='link' && file?.type!=='one_drive' && file?.type!=='google_drive'" class="media-box" [src]="file.image_download_url" />
            </ng-container>
            <ng-template #checkAudio>
              <ng-container *ngIf="mediaType==='MultipleAudio' || mediaType==='SingleAudio' ||  ['SingleFile','MultipleFile'].includes(mediaType) && constantService?.getMediaTypes()?.AUDIO?.includes(file.extension);else checkVideo">
                <app-dashboard-icon *ngIf="file?.type==='google_drive'" style="margin-top: -3px !important;" [name]="'googleDrive'" [iconJson]="attributesIcons" [size]="17" class="labelimg"></app-dashboard-icon>
                <app-dashboard-icon *ngIf="file?.type==='one_drive'" style="margin-top: -3px !important;" [name]="'oneDrive'" [iconJson]="attributesIcons" [size]="17" class="labelimg"></app-dashboard-icon>
                <app-dashboard-icon *ngIf="file?.type==='link' || file?.type==='one_drive' || file?.type==='google_drive'" [name]="file.extension" [iconJson]="mediaIcons" size="100"></app-dashboard-icon>
                <app-dashboard-icon *ngIf="file?.type!=='link' && file?.type!=='one_drive' && file?.type!=='google_drive'" [name]="'audio'" [iconJson]="mediaIcons" size="100"></app-dashboard-icon> 
              </ng-container>
              <ng-template #checkVideo>
                <ng-container *ngIf="mediaType==='MultipleVideo' || mediaType==='SingleVideo' || ['SingleFile','MultipleFile'].includes(mediaType) && constantService?.getMediaTypes()?.VIDEO?.includes(file.extension); else otherFiles">
                  <app-dashboard-icon *ngIf="file?.type==='google_drive'" style="margin-top: -3px !important;" [name]="'googleDrive'" [iconJson]="attributesIcons" [size]="17" class="labelimg"></app-dashboard-icon>
                  <app-dashboard-icon *ngIf="file?.type==='one_drive'" style="margin-top: -3px !important;" [name]="'oneDrive'" [iconJson]="attributesIcons" [size]="17" class="labelimg"></app-dashboard-icon>
                  <video #myVideo class="media-box">
                    <source [src]="file.image_download_url" type="video/mp4" />
                  </video>
                </ng-container>
                <ng-template #otherFiles>
                  <app-dashboard-icon *ngIf="file?.type==='google_drive'" style="margin-top: -3px !important;" [name]="'googleDrive'" [iconJson]="attributesIcons" [size]="17" class="labelimg"></app-dashboard-icon>
                  <app-dashboard-icon *ngIf="file?.type==='one_drive'" style="margin-top: -3px !important;" [name]="'oneDrive'" [iconJson]="attributesIcons" [size]="17" class="labelimg"></app-dashboard-icon>
                  <app-dashboard-icon *ngIf="file?.type!=='link'" [name]="file.extension" [iconJson]="mediaIcons" size="100"></app-dashboard-icon>
                  <app-dashboard-icon *ngIf="file?.type==='link'" [name]="'url'" [iconJson]="mediaIcons" size="100"></app-dashboard-icon> 
                </ng-template>
              </ng-template>
            </ng-template>
            <div class="middle">
              <a (click)="downloadMedia(file)">
                <span class="media-options">
                  <img src="../../../../assets/1x/doc-whitedownload.svg" />
                </span>
              </a>
              <span  class="media-options" (click)="openModalForPreview(i,videoPlayerModalNested)">
                <img src="../../../../assets/1x/view-btn.png" />
              </span>
              <span *ngIf="editable && constantService?.getMediaTypes()?.IMAGE?.includes(file.extension)" class="media-options" (click)="openImageEditor([file])">
                <img  src="../../../../assets/1x/editCircleWhiteIcon.svg" />
              </span>
              <span *ngIf="editable" class="media-options" (click)="deletePopupRef?.open();popoverRef=deletePopupRef">
                <img src="../../../../assets/1x/delete-btn.png" />
              </span>
            </div>
          </div>
          <span class="d-flex justify-content-between fileNameEdit" #renamePopupRef="ngbPopover" [ngbPopover]="renamePopup" placement="bottom auto" triggers="manual" [autoClose]="'outside'">
            <span class="fileName w-100"> {{file.file_name | truncate: 5}}.{{file?.extension}}</span>
            <span *ngIf="editable" class="iconBox" (click)="renamePopupRef?.open();renameValue=file.file_name;popoverRef=renamePopupRef">
              <i class="fas fa-pencil-alt p-0"></i>
            </span>   
          </span> 
      </div>
      <!-- delete media popover template  -->
      <ng-template #deletePopup>
        <div>{{'common.deleteConfirmation' | translate}}</div>
        <div class="dtn-field" >
          <span class="deletebtn" class="btn btn-danger rounded px-2" (click)="onRemoveMedia(i)">{{'common.delete' | translate}}</span>
          <span class="cancelbtn" class="btn rounded cancelbtn px-2" (click)="popoverRef?.close()">{{'common.cancel' | translate}}</span>
        </div>
      </ng-template>
      <!-- rename media popover template  -->
      <ng-template #renamePopup>
        <div class="rename-field">
          <div class="d-flex">
            <div class="edit-input"><input type="text" [(ngModel)]="renameValue"  maxlength="256" minlength="3" (keydown.enter)="onRenameMedia(i)" /></div>
            <div class="remane-btn" (click)="onRenameMedia(i)">
              {{'common.rename' | translate}} 
            </div>
          </div>
          <span class="text-danger" style="font-size: 10px;" *ngIf="renameValue?.length<3"> {{'drive.fileNameMin' | translate}}</span>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>
</ng-template>

<!-- image ediot -->
<ng-template #imageEditorModal let-modal>
  <div class="previewBodyModal" style="height: 100%;max-width: 100%;position: relative;">
    <app-image-editor [imageFiles]="editImageFiles?.fileData" [fileType]="editImageFiles?.type" [itemData]="editImageFiles?.itemData" (closeModal)="modal.dismiss()" (editImage)="onUploadFile($event,false)" ></app-image-editor>
  </div>
</ng-template>

<!-- preview media -->
<ng-template #videoPlayerModalNested let-modal>
  <div class="previewBodyModal" style="height: 100%;max-width: 100%;position: relative;">
    <i class="fa fa-close bg-white previewCross" (click)="modal.dismiss()"></i>
    <app-file-preview [mediaArr]="previewInfo.media" [index]="previewInfo.index" [type]="'ITEM'" [dashId]="itemInfo?.dashboard_id" [typeId]="itemInfo?._id"></app-file-preview>
  </div>
</ng-template>

