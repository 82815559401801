<div class="commonBtn-30" [ngClass]="{themeChild : !headerCall}" placement="auto" popoverClass="modifiedPopover" [ngbPopover]="boardCreatePopover" #popboardCreate="ngbPopover" [autoClose]="'outside'">
    <span class="commonBtnBox-Child-1">
      <span class="iconBox-20"> 
        <app-dashboard-icon class="appInnerIconBox" [size]="20" [name]="'addCircleWhiteOutline'"  [iconJson]="commonIcons"></app-dashboard-icon>
      </span>
      <span class="f-l-r newBtnText" [ngClass]="{themeChild : !headerCall}">New</span>
      <ng-template #boardCreatePopover>
        <div class="boardPopup">
          <div class="w-100 cursor-pointer newBoardIcon backgroundHover" (click)="selectDashboardPopupText = 'BOARD';popboardCreate?.close();openNewDashboardModel(createDashboardContent)"><span><app-dashboard-icon [size]="14"  [name]="'BoardFolder'" [iconJson]="attributesIcons" style="display: flex;align-items: center;"></app-dashboard-icon></span>Board</div>
          <div class="w-100 cursor-pointer newBoardIcon backgroundHover" (click)="selectDashboardPopupText = 'BOARD_GRAPH';popboardCreate?.close();openNewDashboardModel(createDashboardContent)"><span><app-dashboard-icon [size]="14"  [name]="'Graph'" [iconJson]="attributesIcons" style="display: flex;align-items: center;"></app-dashboard-icon></span>Dashboard</div>
          <div class="w-100 cursor-pointer newBoardIcon backgroundHover" (click)="selectDashboardPopupText = 'DRIVE';popboardCreate?.close();openNewDashboardModel(createDashboardContent)"><span><app-dashboard-icon [size]="14"  [name]="'Drive'" [iconJson]="attributesIcons" style="display: flex;align-items: center;"></app-dashboard-icon></span>Drive</div>
          <div class="w-100 cursor-pointer newBoardIcon backgroundHover" (click)="selectDashboardPopupText = 'DOCUMENT';popboardCreate?.close();openNewDashboardModel(createDashboardContent)"><span><app-dashboard-icon [size]="14"  [name]="'Notes'" [iconJson]="attributesIcons" style="display: flex;align-items: center;"></app-dashboard-icon></span>Document</div>
          <div class="w-100 cursor-pointer newBoardIcon backgroundHover" (click)="selectDashboardPopupText = 'FOLDER';popboardCreate?.close();openNewDashboardModel(createDashboardContent)"><span><app-dashboard-icon [size]="14"  [name]="'Graph'" [iconJson]="attributesIcons" style="display: flex;align-items: center;"></app-dashboard-icon></span>Folder</div>
        </div> 
        <div class="boardPopup borderTop">
            <div class="w-100 cursor-pointer newBoardIcon backgroundHover" (click)="popboardCreate?.close();openTemplateModal(templateModal)">Import Template</div>
            <ng-container *ngIf="appsDetails?.showAppView">
              <multi-level-dropdown [viewType]="viewType" [isDropLeft]="true" [appConnectionJson]="appsDetails?.appConnectionJson" (selectedAppInstance)="selectedAppInstance.emit($event)"></multi-level-dropdown>
             </ng-container>
           </div> 
      </ng-template>
    </span>
  </div>


  <!-- new das popover start-->
  <ng-template #createDashboardContent let-modal>
    <div class="d-flex justify-content-between align-items-center">
     <div class="modal-title f-xxxl-bb" style="line-height:40px;" id="modal-basic-title">{{ 'common.create' | translate}} {{createBoardType}}</div>
     <div class="d-flex align-items-center" style="gap:8px;">
     <!----Change Board-->
     <div class="commonBtn-30 changeBoardheaderRightghostBtn" *ngIf="selectedBoardForCreaion && selectDashboardPopupText!=='FOLDER' && headerCall">
       <span class="commonBtnBox" placement="auto" popoverClass="modifiedPopover" [ngbPopover]="boardChange" #popboardChange="ngbPopover" [autoClose]="'outside'">
         <span class="f-l-r">{{selectedBoardForCreaion?.dashboard_name}}</span>
       </span>
         <ng-template #boardChange>
             <div class="optionDetailBoxBody boardBody">
                <ng-container *ngFor="let board of boardList">
                            <div class="optionDetailBox bodyRow cursorPointer" (click)="selectedBoardForCreaion=board;popboardChange?.close();">{{board?.dashboard_name}}</div>
                </ng-container>
             </div>
         </ng-template>
     </div>
     <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()"><span aria-hidden="true">&times;</span></button> 
   </div>
   </div>
   <app-dashboard-create [dashboardFolderId]="dashboardFolderId ? dashboardFolderId : selectedBoardForCreaion?.dashboard_id" [editDashId]="null" [selectDashboardPopupText]="selectDashboardPopupText" [createNewDashTemplateRef]="createNewDashTemplateRef"></app-dashboard-create>
</ng-template>


 <!--import Template-->
 <ng-template #templateModal let-modal>
    <div id="templateHomeComonent" class="h-100"></div>
</ng-template>