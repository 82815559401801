<!-- New Dashboard Modal -->
<div class="w-100" *ngIf="editDashId === null" [class]="selectedThemeColor">
    <!-- Dashboard icon start-->
    <div class="row" *ngIf="showDash">
      <div class="col-6 col-md-2 d-flex align-items-center justify-content-center">
        <!-- <img src="../../../assets/1x/newdash-arrow.svg"> -->
      </div>
      <div class="col-6 col-md-8 py-5 pl-0 text-center">
        <div class="blank-dashboard" role="button" (click)="showHideDasPopup('BOARD')"><img
            src="../../../assets/1x/blank-dashboard-ico.svg" width="45"><br>
            <strong>{{'createBoard.blankBoard' | translate}}</strong>
            {{'createBoard.startFromScratch' | translate}}</div>
        <div class="blank-dashboard use-template" role="button" (click)="showHideDasPopup('CHOOSE_TEMPLATE')"><img
            src="../../../assets/1x/use-template-ico.svg"><br>
            <strong>{{'template.useTemplate' | translate}}</strong>
            {{'createBoard.chooseFromLibrary' | translate}}</div>
        <div class="blank-dashboard dashboard-graph" role="button" (click)="showHideDasPopup('BOARD_GRAPH')"><img
          src="../../../assets/1x/use-template-ico.svg"><br>
          <strong>{{'template.dashboardGraph' | translate}}</strong>
          {{'template.createGraph' | translate}}
        </div>
      </div>
    </div>
    <!-- dashboard icon end -->
  
    <!-- das name start for blank dash -->
    <div *ngIf="selectDashboardPopupText === 'BOARD'" class="w-100 h-100 createBodyBox">
      <form [formGroup]="newDashboardForm" class="w-100 form-data-create-board formBoard">
        <div class="nameBoard">
          <label for="dashName" class="input-label f-l-r-r">{{'createBoard.boardName' | translate}}</label>
          <input type="text" id="dashName" placeholder="{{'createBoard.enterBoardName' | translate}}" class="creatinpt" (input)="capitalizeFirstLetter($event?.target?.value)" formControlName="name" #dashNameInput>
          <div *ngIf="newDashboardForm.get('name').invalid && newDashboardForm.get('name').dirty" class="f-s-rtext text-danger">
            <div *ngIf="newDashboardForm.get('name').errors?.required else newMinLength">
              {{'common.nameValidation.reuire' | translate}}
            </div>
            <ng-template #newMinLength>
              <div *ngIf="newDashboardForm.get('name').errors?.minlength else newMaxLength">
                {{'common.nameValidation.minChar' | translate}}
              </div>
            </ng-template>
            <ng-template #newMaxLength>
              <div *ngIf="newDashboardForm.get('name').errors?.maxlength else newWhitespace">
                {{'common.nameValidation.max100Char' | translate}}
              </div>
            </ng-template>
          </div>  
        </div>

        <div class="nameBoard">
          <label for="enable" class="input-label f-l-r-r">{{'createBoard.privacy' | translate}}</label>
          <div class="w-100">
            <div>
              <input type="radio" formControlName="privacy" name="privacy" id="private" value="PRIVATE">
              <label for="private" class="ml-2" >{{'createBoard.privateVisible' | translate}}</label>
            </div>
            <div>
              <input type="radio" formControlName="privacy" name="privacy" id="internal" value="INTERNAL">
              <label for="internal" class="ml-2" >{{'createBoard.internalAnyone' | translate}}</label>
            </div>
          </div>
        </div>

        <div class="nameBoard">
          <label for="enable" class="input-label f-l-r-r">{{'createBoard.enableViews' | translate}}</label>
          <div class="w-100 d-flex align-items-center">
            <div class="d-flex align-items-center" style="gap:8px;width:120px">
              <input type="checkbox" formControlName="kanban">
              <label for="board" class="mb-0">{{'viewType.kanban' | translate}}</label>
            </div>
            <div class="d-flex align-items-center" style="gap:8px;width:120px">
              <input type="checkbox" formControlName="gantt">
              <label for="gantt" class="mb-0">{{'viewType.gantt' | translate}}</label>
            </div>
            <div class="d-flex align-items-center" style="gap:8px;width:120px">
              <input type="checkbox" formControlName="grid">
              <label for="grid" class="mb-0">{{'viewType.grid' | translate}}</label>
            </div>
          </div>
          <div *ngIf="!isViewSelected" class="text text-danger">
            {{'createBoard.boardViewSelected' | translate}}
          </div>
        </div>

        <div class="nameBoard">
          <label for="enable" class="input-label f-l-r-r">{{'setting.terminology' | translate}}</label>
          <div class="d-flex w-100">
            <div class="terminologyOptions">
              <ng-container *ngFor="let data of terminologyOptions">
                  <div *ngIf="data!='Manual'" style="width:120px;">
                      <div class="form-check pl-0 terminateBox">
                          <input type="radio" [(ngModel)]="itemTerminology" [ngModelOptions]="{standalone: true}" [value]="data" name="exampleRadios" >
                          <label class="form-check-label" for="exampleRadios2">
                              {{data}}
                          </label>
                      </div>
                    </div>
                    <div *ngIf="data=='Manual'" style="width:120px;">
                      <div class="form-check pl-0 d-flex">
                          <input class="mr-2" type="radio" [(ngModel)]="itemTerminology" [ngModelOptions]="{standalone: true}" [value]="data" name="exampleRadios"  (ngModelChange)="customTerminology()">
                          <input class="inputManual" placeholder="Custom Name" type="text" #terminology [(ngModel)]="manualTerminology" [ngModelOptions]="{standalone: true}"  [disabled]="itemTerminology!='Manual'">
                      </div>
                      <div *ngIf="itemTerminology=='Manual' && !terminology.value.trim().length">
                          <span class="text-danger">*{{'setting.enterTerminology' | translate}}</span>
                      </div>            
                    </div>
              </ng-container>
          </div>
          </div>
        </div>
      
        <div class="boardFooter" style="justify-content: space-between;">
           <div class="dashType">
            <ng-select style="height:35px;width:200px;border:none !important;" [multiple]="false" [closeOnSelect]="true"  [items]="availableBoards"
            [clearable]="false" [(ngModel)]="boardTypeselected" [ngModelOptions]="{standalone:true}" class="form-control p-0" id="exampleFormControlSelect1" role="button" >
           </ng-select>
           </div>
           <div style="display: flex;gap:8px;">
            <button class="createdashbtn" style="background-color: #fff;border:1px solid #353E4E;color:#353E4E" (click)="createNewDashTemplateRef.dismiss()">Cancel</button>
            <button class="createdashbtn themeChild" [disabled]="newDashboardForm.get('name').errors" (click)="createNewDashboard();createNewDashTemplateRef.dismiss();">{{'createBoard.createBoard' | translate}}</button>  
           </div>
           </div>

      </form>
    </div>
    <!-- das name end -->

    <!-- dashboard graph start -->
    <div  class="w-100 h-100 createBodyBox" *ngIf="selectDashboardPopupText === 'BOARD_GRAPH' || selectDashboardPopupText ==='DOCUMENT'">
        <form [formGroup]="newDashboardForm" class="w-100 form-data-create-board formBoard">
          <div class="nameBoard">
            <label for="dashName" class="input-label f-l-r-r">{{'createBoard.boardName' | translate}}</label>
            <input type="text" id="dashName" placeholder="{{'createBoard.enterBoardName' | translate}}" (input)="capitalizeFirstLetter($event?.target?.value)" class="creatinpt" formControlName="name" #dashNameInput>
            <div *ngIf="newDashboardForm.get('name').invalid && newDashboardForm.get('name').dirty" class="f-s-rtext text-danger">
              <div *ngIf="newDashboardForm.get('name').errors?.required else newMinLength">
                {{'common.nameValidation.reuire' | translate}}
              </div>
              <ng-template #newMinLength>
                <div *ngIf="newDashboardForm.get('name').errors?.minlength else newMaxLength">
                  {{'common.nameValidation.minChar' | translate}}
                </div>
              </ng-template>
              <ng-template #newMaxLength>
                <div *ngIf="newDashboardForm.get('name').errors?.maxlength else newWhitespace">
                  {{'common.nameValidation.max100Char' | translate}}
                </div>
              </ng-template>
            </div> 
          </div> 

          <div class="nameBoard">
            <label for="enable" class="input-label f-l-r-r">{{'createBoard.privacy' | translate}}</label>
            <div class="w-100">
              <div>
                <input type="radio" formControlName="privacy" name="privacy" id="private" value="PRIVATE">
                <label for="private" class="ml-2" >{{'createBoard.privateVisible' | translate}}</label>
              </div>
              <div>
                <input type="radio" formControlName="privacy" name="privacy" id="internal" value="INTERNAL">
                <label for="internal" class="ml-2" >{{'createBoard.internalAnyone' | translate}}</label>
              </div>
            </div>
          </div>

          <div class="boardFooter">
            <button class="createdashbtn" style="background-color: #fff;border:1px solid #353E4E;color:#353E4E" (click)="createNewDashTemplateRef.dismiss()">Cancel</button>
            <button class="createdashbtn themeChild" (click)="createNewGraphDashboard();createNewDashTemplateRef.dismiss();">{{'createBoard.createGraph' | translate}}</button>
          </div>
        </form>
    </div>
    <!-- dashboard graph end -->

       <!-- dashboard list start -->
    <div  class="w-100 h-100 createBodyBox" *ngIf="['DRIVE','BOARD_LIST'].includes(selectDashboardPopupText)">
      <form [formGroup]="newDashboardForm" class="w-100 form-data-create-board formBoard">
        <div class="nameBoard">
          <label for="dashName" class="input-label">{{'createBoard.boardName' | translate}}</label>
          <input type="text" id="dashName" placeholder="{{'createBoard.enterBoardName' | translate}}" (input)="capitalizeFirstLetter($event?.target?.value)" class="creatinpt" formControlName="name" (keyup.enter)="createNewListDashboard(selectDashboardPopupText);" #dashNameInput>
          <div *ngIf="newDashboardForm.get('name').invalid && newDashboardForm.get('name').dirty" class="f-s-rtext text-danger">
            <div *ngIf="newDashboardForm.get('name').errors?.required else newMinLength">
              {{'common.nameValidation.reuire' | translate}}
            </div>
            <ng-template #newMinLength>
              <div *ngIf="newDashboardForm.get('name').errors?.minlength else newMaxLength">
                {{'common.nameValidation.minChar' | translate}}
              </div>
            </ng-template>
            <ng-template #newMaxLength>
              <div *ngIf="newDashboardForm.get('name').errors?.maxlength else newWhitespace">
                {{'common.nameValidation.max100Char' | translate}}
              </div>
            </ng-template>
          </div> 
        </div> 

        <div class="boardFooter">
          <button class="createdashbtn" style="background-color: #fff;border:1px solid #353E4E;color:#353E4E" (click)="createNewDashTemplateRef.dismiss()">Cancel</button>
          <button class="createdashbtn themeChild" (click)="createNewListDashboard(selectDashboardPopupText);createNewDashTemplateRef.dismiss();">{{selectDashboardPopupText=='BOARD_LIST'? 'Create List' : 'Create Drive'}}</button>
        </div>
      </form>
  </div>
  <!-- dashboard graph end -->
  
    <!-- choose template start -->
    <div class="choose-template row mx-0 h-100 w-100" *ngIf="selectDashboardPopupText === 'CHOOSE_TEMPLATE'">
       
      <app-templates-home [isCreateBoard]="true" (closePopup)="closeModalAfterCreateDB($event)" class="h-100 w-100 createBoardFromTemplate">
        <div (click)="goToPreviousDashPopup()" role="button" class="pl-2">
            <img src="../../../assets/1x/newdash-arrow.svg">
        </div>
      </app-templates-home>
    </div>

    <div class="choose-template row mx-0 h-100 w-100" *ngIf="selectDashboardPopupText == 'FOLDER'">
      <form [formGroup]="newDashboardForm" class="w-100 form-data-create-board formBoard">
        <div class="nameBoard">
          <label for="dashName" class="input-label f-l-r-r">{{'createBoard.boardName' | translate}}</label>
          <input type="text" id="dashName" placeholder="{{'createBoard.enterBoardName' | translate}}" (input)="capitalizeFirstLetter($event?.target?.value)" class="creatinpt" formControlName="name" #dashNameInput>
        </div> 
        <div class="boardFooter">
          <button class="createdashbtn" style="background-color: #fff;border:1px solid #353E4E;color:#353E4E" (click)="createNewDashTemplateRef.dismiss()">Cancel</button>
          <button class="createdashbtn themeChild" (click)="createFolderCall();createNewDashTemplateRef.dismiss()">{{'createBoard.createFolder' | translate}}</button>
        </div>
      </form>  
    </div>
  </div>
  <!-- Edit dashboard start -->
  <div class="w-100" *ngIf="editDashId !== null && loadEditBoard" [class]="selectedThemeColor">
      <form [formGroup]="editDashboardForm" class="w-100 form-data-create-board formBoard">
        <div class="nameBoard">
          <label   class="input-label mb-0">{{'createBoard.boardName' | translate}} </label>
          <input type="text" class="editinpt" formControlName="editDashName">
          
          <div *ngIf="editDashboardForm.get('editDashName').invalid && (editDashboardForm.get('editDashName').dirty || editDashboardForm.get('editDashName').touched) || editDashboardForm.get('editDashName').errors?.whitespace" class="text text-danger">
            <div *ngIf="editDashboardForm.get('editDashName').errors?.required else minLength">
              {{'common.nameValidation.reuire' | translate}}
            </div>
            <ng-template #minLength>
              <div *ngIf="editDashboardForm.get('editDashName').errors?.minlength else maxLength">
                {{'common.nameValidation.minChar' | translate}}
              </div>
            </ng-template>
            <ng-template #maxLength>
              <div *ngIf="editDashboardForm.get('editDashName').errors?.maxlength; else whiteSpace">
                {{'common.nameValidation.max100Char' | translate}}.
              </div>
            </ng-template>  
          </div>
        </div>
        <div class="nameBoard">
          <label   class="input-label mb-0">{{'common.description' | translate}} </label>
          <textarea name="description" id="" class="editinpt" cols="120" rows="3" formControlName="editDashDescription" placeholder="{{'common.description' | translate}}"></textarea>
        </div>
        <div class="nameBoard" *ngIf="dashboardType != 'BOARD_GRAPH' && dashboardType != 'BOARD_LIST' && dashboardType != 'DOCUMENT'">
          <label for="enable" class="input-label">{{'createBoard.privacy' | translate}}</label>
          <div class="w-100">
            <div>
              <input type="radio" formControlName="privacy" name="privacy" id="private" value="PRIVATE">
              <label for="private" class="ml-2" >{{'createBoard.privateVisible' | translate}}</label>
            </div>
            <div>
              <input type="radio" formControlName="privacy" name="privacy" id="internal" value="INTERNAL">
              <label for="internal" class="ml-2" >{{'createBoard.internalAnyone' | translate}}</label>
            </div>
          </div>
            <div *ngIf="!isViewSelected && dashboardType != 'BOARD_GRAPH' && dashboardType != 'BOARD_LIST'" class="text text-danger mr-2">
          {{'createBoard.boardViewSelected' | translate}}
        </div>
        </div>
        <div class="nameBoard">
          <div class="dashType">
                <div>
                  <ng-container *ngIf="dashboardType != 'BOARD_GRAPH' && dashboardType != 'BOARD_LIST' && dashboardType != 'DOCUMENT'">
                    <ng-select style="height:35px;width:200px;border:none !important;"  dropdownPosition="top" [multiple]="false" [closeOnSelect]="true"  [items]="availableBoards"
                    [clearable]="false" [(ngModel)]="boardTypeselected" [ngModelOptions]="{standalone:true}" class="form-control p-0" id="exampleFormControlSelect1" role="button" >
                   </ng-select>
                  </ng-container>
                </div>
                <div style="display: flex;gap:8px;">
                  <button class="disabledBtn themeChild" *ngIf="editDashboardForm.get('editDashName').errors">{{'common.update' | translate}}</button>
                  <button class="createdashbtn themeChild" *ngIf="!editDashboardForm.get('editDashName').errors" (click)="updateDashboard()">{{'common.update' | translate}}</button>
                </div>
            </div>
        </div>
      </form>
  </div>

 