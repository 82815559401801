<div class="allEmails" *ngIf="isAllEmails; else emailFullview">
   <ng-container *ngIf="!communicationCall && isEmailCommentsLoaded">
       <div class="switchThreadView mt-1" (click)="isThreadView = !isThreadView;emitIsThreadView.emit( isThreadView && allEmailComment)">
      <div *ngIf="isThreadView" class="mb-2">{{'email.threadEmailReplies' | translate}}</div>
      <div *ngIf="!isThreadView">{{'email.showAllEmailThreads' | translate}}</div>
   </div>
   </ng-container>
   <div class="emailTop" [ngClass]="{'emailBodyHeightFullView':!communicationCall}">
      <div [ngClass]="{'notCommunicationView':!communicationCall}">
         <div *ngIf="!isEmailCommentsLoaded">
            <app-shimmer-loader [type]="'CardField'" [count]="1" [columnCount]="1"></app-shimmer-loader>
            <!-- <span style="text-align: center;">Loading......</span> -->
         </div>
         <ng-container *ngIf="isThreadView; else allEmailView">
            <ng-container *ngFor="let threadId of allThreadIds">
               <!-- <ng-container *ngIf="thread?.value?.length"> -->
                  <div class=" row emailContent flexAlign"  [ngClass]="{'emailContentChatView':communicationCall,'emailFullViewOption':!communicationCall}">
                     <div *ngIf="allEmailComment[threadId][0]?.email_object?.from?.name" style="margin-top: 10px;padding-left: 8px;" class="col-2 avatarBox" role="button"><ngx-avatars class="mr-1" [name]="allEmailComment[threadId][0]?.email_object?.from?.name" size="40" initialsSize="2" ></ngx-avatars></div>
                     <div *ngIf="!allEmailComment[threadId][0]?.email_object?.from?.name" style="margin-top: 10px;padding-left: 8px;" class="col-2 avatarBox" role="button"><ngx-avatars class="mr-1" [name]="'Unknown'" size="40" initialsSize="2" ></ngx-avatars></div>
                     <div class="col-10 chatBoxRightContent p-0" style="margin-left: -8px;">
                        <div class="emailBody" (click)="setSelectedEmail(allEmailComment[threadId][0]);setSelectedThreadEmail(allEmailComment[threadId]);" [ngClass]="isEmailExpandedView && (selectedEmail?._id == allEmailComment[threadId][0]?.email_obj_id || selectedEmail?._id == allEmailComment[threadId][0]?.email_object?._id) ? 'emailSelected' : 'emailNotSelected'">
                           <div class="mb-2">
                              <div class="emailTitleRow mb-1">
                                 <div class="subjectContainer">
                                    <span *ngIf="allEmailComment[threadId][0]?.email_object?.subject" class="emailContentTitle">{{allEmailComment[threadId][0]?.email_object?.subject}}</span>
                                    <span *ngIf="!allEmailComment[threadId][0]?.email_object?.subject" class="emailContentTitle">{{'email.noSubject' | translate}}</span>
                                 </div>
                                 <span class="receivedDate" *ngIf="allEmailComment[threadId][0]?.email_object?.received_time; else createdTime">
                                    {{allEmailComment[threadId][0]?.receivedTime?.date}} {{allEmailComment[threadId][0]?.receivedTime?.time}}
                                 </span>
                                 <ng-template #createdTime>
                                    <span class="receivedDate">
                                       {{allEmailComment[threadId][0]?.createdTime?.date}} {{allEmailComment[threadId][0]?.createdTime?.time}}
                                    </span>
                                 </ng-template>
                              </div>                        
                              <div class="to-address-block">
                                 <span class="to-address">from: {{allEmailComment[threadId][0]?.email_object.from?.address}}</span>
                                  <img class="cursorPointer" [ngbPopover]="toFromEmailDropDown"  #dropDownEmail="ngbPopover" container="body" placement="bottom" [outsideClick]="'outside'" aria-hidden="true" popoverClass="toFromEmailDropDown" triggers="manual" [autoClose]="'outside'" (click)="dropDownEmail.open();$event.stopPropagation();" class="right-3dots ml-3 cursorPointer" src="../../../assets/1x/arrow-dropdown.svg">
                                  <ng-template #toFromEmailDropDown>
                                   <app-email-dropdown-template [lastMessage]="allEmailComment[threadId][0]"></app-email-dropdown-template>
                                   </ng-template>
                             </div>
                              <!-- <p class="emailContentFrom mb-1" *ngIf="allEmailComment[threadId][0]?.email_object.toAddresses?.length">To: {{allEmailComment[threadId][0]?.email_object.toAddresses}}</p>
                              <p class="emailContentFrom mb-1" *ngIf="allEmailComment[threadId][0]?.email_object.ccAddresses?.length">CC: {{allEmailComment[threadId][0]?.email_object.ccAddresses}}</p> -->
                              <div style="height: 3ch; overflow: hidden;" *ngIf="allEmailComment[threadId][0]?.email_object?.body?.html"
                              [innerHtml]="allEmailComment[threadId][0]?.email_object?.body?.html.replaceAll('<br>', '').replaceAll('<p>', '').replaceAll('</p>', '').replaceAll('\n','')">
                              </div>
                              <div style="height: 3ch; overflow: hidden;" *ngIf="!allEmailComment[threadId][0]?.email_object?.body?.html"
                              [innerHtml]="allEmailComment[threadId][0]?.email_object?.body?.text.replaceAll('<br>', '').replaceAll('<p>', '').replaceAll('</p>', '').replaceAll('\n','')">
                              </div>
                              <div class="chatLeftBottom">
                                 <div class="msgBox">
                                    <span>
                                       <app-dashboard-icon [size]="18"  [name]="'SettingEmail'" [iconJson]="attributesIcons"></app-dashboard-icon>
                                    </span>
                                    <span class="ml-2">Email</span>
                                 </div>
                                 <div >
                                    <span *ngIf="allEmailComment[threadId][0]?.status == 'FAILED' "><app-dashboard-icon  [size]="22" [name]="'Alert'" [iconJson]="lxAttributesIcons"></app-dashboard-icon></span>
                                    <!-- <span class="ml-1 threadCapsule" *ngIf="allEmailComment[threadId]?.length>1">{{allEmailComment[threadId]?.length}} Threads</span> -->
                                    <span class="ml-1 threadCapsule" *ngIf="allEmailComment[threadId][0]?.smtp_thread_count >1">{{allEmailComment[threadId][0]?.smtp_thread_count}} Threads</span>
                                    <span class="ml-1 threadCapsule" *ngIf="!allEmailComment[threadId][0]?.smtp_thread_count && allEmailComment[threadId]?.length>1">{{allEmailComment[threadId]?.length}} Threads</span>
                                    <!-- <span class="ml-1 threadCapsule" *ngIf="allEmailComment[threadId]?.length>1">+ {{allEmailComment[threadId]?.length}}</span> -->
                                 </div>
                              </div>
                           </div> 
                        </div>
                     </div>
                  </div>
               <!-- </ng-container> -->
            </ng-container>
         </ng-container>
      </div>
   </div>
   <ng-template #allEmailView>
      <ng-container *ngFor="let comment of allComments">
        <ng-container *ngIf="copyleadInfoForcomment?.unreadCommentDetails?.email?.unreadCount>0 && item?.create_date>copyleadInfoForcomment?.readCommentDetails?.EMAIL?.last_read_time">
         <div class="commentBox unreadMsg mt-2" [id]="'unreadmsg_'+leadInfo?._id" ><span class="m-1">{{copyleadInfoForcomment?.unreadCommentDetails?.email?.unreadCount+" "+("comment.unreadMessages" | translate)}}</span></div>
       </ng-container>
         <div class="emailContent singleEmailContent" [ngClass]="isEmailExpandedView && (selectedEmail?._id == comment?.email_obj_id || selectedEmail?._id == comment?.email_object?._id) ? 'emailSelected' : 'emailNotSelected'">
            <div class="single-email emailBody" (click)="$event.stopPropagation(); setSelectedEmail(comment, false)">
               <div class="mb-2">
                  <div class="emailTitleRow mb-1">
                     <span *ngIf="comment?.email_object?.subject" class="emailContentTitle" style=" margin-left: 31px;"> {{comment?.email_object?.subject | truncate:40}}</span>
                     <span *ngIf="!comment?.email_object?.subject" class="emailContentTitle" style=" margin-left: 31px;">{{'email.noSubject' | translate}}</span>
                     <div class="reply-and-date">
                        <span class="mr-2 cursorPointer"
                           *ngIf=" usersEmailsAllowedToReply?.length"
                           placement="auto"
                           [ngbPopover]="newEmail" 
                           #popEmailReplySingleEmail="ngbPopover" 
                           container="body" 
                           [autoClose]="'outside'" [ngbTooltip]="'email.reply' | translate" tooltipClass="tooltipPopup"
                           (click)="isFullScreen=false;$event.stopPropagation();popOverClose=popEmailReplySingleEmail;  setSelectedEmail(comment, true); isReplyAll=false; initializeSummerNote(selectedEmail?.body?.html ? selectedEmail?.body?.backQuoteHtmlReply : selectedEmail?.body?.backQuoteTextReply );"><i class="fa fa-reply" aria-hidden="true"></i></span>
                        <span class="mr-2">{{comment?.receivedTime?.date || comment?.createdTime?.date}}</span>
                        <span class="cursorPointer" [ngbPopover]="newEmail" #popEmailReplyAllSingleEmail2="ngbPopover" container="body" [autoClose]="'outside'" [outsideClick]="'outside'"placement="auto" ></span>
                        <span *ngIf="usersEmailsAllowedToReply?.length" class="cursorPointer" class="mr-2">
                           <img 
                           [ngbPopover]="replyAllPopover2"  
                           #popEmailReplyAllSingleEmail="ngbPopover"
                           container="body" placement="bottom" 
                           [outsideClick]="'outside'" aria-hidden="true" 
                           popoverClass="replyAllPopover"
                           triggers="manual"
                           [autoClose]="'outside'"
                           (click)="$event.stopPropagation(); popEmailReplyAllSingleEmail.open(); setSelectedEmail(comment, true);popOverClose=popEmailReplyAllSingleEmail2;"
                           class="right-3dots ml-1 cursorPointer" src="../../../assets/1x/vertdots.svg">
                           <ng-template #replyAllPopover2>
                              <span class="cursorPointer" (click)="$event.stopPropagation();popEmailReplyAllSingleEmail.close();popEmailReplyAllSingleEmail2.open();popOverCloseReplayAll=popEmailReplyAllSingleEmail;isReplyAll=true;initializeSummerNote(selectedEmail?.body?.html ? selectedEmail?.body?.backQuoteHtmlReply : selectedEmail?.body?.backQuoteTextReply )"><i class="fa fa-reply-all mr-2" aria-hidden="true"></i>{{'email.replyAll' | translate}}</span>
                           </ng-template>
                        </span>
                     </div>
                  </div>
                  <div class="d-flex flex-row address-block">
                     <div class="mr-1 pr-1 mt-1">
                        <ngx-avatars class="mr-1" [name]="comment?.email_object.from?.address" [src]="undefined" size="25" initialsSize="2" ></ngx-avatars>
                     </div>
                     <div class="d-flex flex-column">
                        <span class="from-address">{{comment?.email_object.from?.address}}</span>
                        <div class="to-address-block">
                           <span class="to-address">To: {{comment?.email_object?.to[0]?.address}}</span>
                           <!-- <img class="cursorPointer" src="../../../assets/1x/arrow-dropdown.svg"> -->
                        </div>
                     </div>
                  </div>
                  <div style="height: 3ch;overflow: hidden;margin-left: 31px;" *ngIf="comment?.email_object?.body?.html" [innerHtml]="comment?.email_object?.body?.html?.replaceAll('<br>', '').replaceAll('<p>', '').replaceAll('</p>', '').replaceAll('\n','') | truncate:50"></div>
                     <div style="height: 3ch;overflow: hidden;" *ngIf="!comment?.email_object?.body?.html" [innerHtml]="comment?.email_object?.body?.text?.replaceAll('<br>', '').replaceAll('<p>', '').replaceAll('</p>', '').replaceAll('\n','') | truncate:50"></div>
               </div>  
            </div>
         </div>
      </ng-container>
   </ng-template>
   <div *ngIf="enableNewEmail && !communicationCall && !isReadOnly && !expandEmail" class="emailBottom">
      <div *ngIf="enableNewEmail" class="newEmail" placement="auto" [ngbPopover]="userEmailConnections?.length ? newEmail : connectEmailPopover" #popEmail1="ngbPopover" container="body" [autoClose]="'outside'" (click)="$event.stopPropagation();selectedEmail=null;initializeSummerNote();">
         <span><img src="../../../assets/1x/send.svg"> {{'email.newEmail' | translate}}</span>
      </div>
   </div>
   <div *ngIf="!enableNewEmail && !communicationCall && isReadOnly && !expandEmail" class="emailBottom">
      <div *ngIf="!enableNewEmail && isReadOnly" class="newEmail" style="background-color:grey;">
         <span><img src="../../../assets/1x/send.svg"> {{'email.newEmail' | translate}}</span>
      </div>
   </div>
</div>
<!-- disablePopover="!usersEmailsAllowedToReply?.length" -->

<ng-template #emailFullview>
   <ng-container *ngIf="!isEmailExpandedView && isThreadView">
      <app-expand-thread-view [selectedEmailThreadViewDisplay]="selectedEmailThreadViewDisplay" [communicationCall]="communicationCall" [userEmailsConnectedWithAliases]="userEmailsConnectedWithAliases" [selectedEmailObj]="selectedEmailObj" [dashId]="dashId" [leadInfo]="leadInfo" [showBackButton]="true" (backToAllEmails)="isAllEmails=true;" [sprintArr]="sprintArr"></app-expand-thread-view>
   </ng-container>
   <ng-container *ngIf="!isEmailExpandedView && !isThreadView">
      <app-expanded-email-view 
      [isFullyExpanded]="false"
      [selectedEmailObj]="selectedEmailObj" 
      [dashId]="dashId" 
      [sprintArr]="sprintArr" 
      (backToAllEmails)="goBackToAllEmailView(); isReplyAll=false"
      [leadInfo]="leadInfo">
      </app-expanded-email-view> 
   </ng-container> 
</ng-template>

<!-- CONNECT EMAIL -->
<ng-template #connectEmailPopover>
   <div class="connect-email d-flex flex-column align-items-center">
      <div class="connect-email-header">{{'email.connectEmail' | translate}}</div>
      <div class="w-100">
         <app-connect-emails [imageSize]="'50px'"></app-connect-emails>
     </div>
   </div>
</ng-template>

<!--Email Modal-->
<ng-template #newEmail>
   <div class="emailPopover" (click)="$event.stopPropagation()" [style.width.px]="isFullScreen ? 1050 : 600">
      <div class="emailHeader">         
         <div style="width:40%;">
            <span class="modal-title">
               <ng-container *ngIf="selectedEmail;else newMail">
                  <ng-container *ngIf="isReplyAll">{{'email.replyAll' | translate}}</ng-container>  
                  <ng-container *ngIf="!isReplyAll">{{'email.reply' | translate}}</ng-container>  
               </ng-container>
               <ng-template #newMail>{{'email.newEmail' | translate}}</ng-template>
            </span>
         </div>
         
         <div style="width:60%;display: flex;align-items: center;justify-content: flex-end;">
            <span style="width:5%;" (click)="onFullview()"><img src="../../../assets/1x/fullScreen.svg" style="height: 16px;width: 16px;"></span>
            <span>
               <ng-select style="width:250px;" 
               (change)="changeEmail($event)"
               [items]="userEmailsConnectedWithAliases" 
               bindLabel="value" 
               bindValue="id" 
               [(ngModel)]="from" 
               [clearable]="false" 
               placeholder="Select From">
                  <ng-template ng-option-tmp let-item="item" let-index="index">
                     <span>{{item?.value}}</span>
                  </ng-template>
               </ng-select>
            </span>
            <!-- <img src="/assets/1x/flip.svg" style="margin-right:5px;height:12px;"> -->
            <i class="fa fa-close cursorPointer ml-2" (click)="closeAllPopups()"></i>
         </div>
   </div>
   <div class="emailPopBody" (click)="$event.stopPropagation()">
      <app-send-email 
      [dashId]="dashId" 
      [sprintArr]="sprintArr" 
      [isReplyAll]="isReplyAll" 
      [replyingEmail]="selectedEmail" 
      [leadInfo]="leadInfo" 
      [from]="fromEmailObj"  
      [emailSignatureForEditor]="emailSignatureForEditor"
      (closeModal)="closeAllPopups();from='';"
      [isFullScreen]="isFullScreen"
      (isFullScreenEmit)="isFullScreen=true"
      [embeddedImageAttachments]="emailEditorEmbeddedAttachments"></app-send-email>
   </div>
 </div>
</ng-template>

<ng-template #videoPlayerModalNested let-modal>
   <div class="previewBodyModal" style="height: 100%;max-width: 100%;position: relative;">
     <i class="fa fa-close bg-white previewCross" role="button" (click)="modal.dismiss()"></i>
     <app-file-preview [mediaArr]="selectedEmail?.attachments" [index]="currentIndex" [type]="'ITEM'"></app-file-preview>
   </div>
</ng-template>
