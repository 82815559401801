import {ApplicationRef, ChangeDetectorRef, Compiler, Component, Injector, Input, OnInit, SimpleChange, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { AttributesIcons, commonIcons } from '../../../../Enums/attributes-icons';
import { Role } from '../../../../Enums/Role';
import { MessageService } from '../../../message.service';
import { CommonUtils } from '../../../services/CommonUtils.service';
import { CustomStorageService } from '../../../services/custom-storage.service';
import { DashboardUtilsService } from '../../../services/dashboard-utils.service';
import { HttpTransferService } from '../../../services/httpTransfer.service';
import { ToasterService } from 'app/services/toaster.service';

@Component({
  selector: 'app-board-header-right-section',
  templateUrl: './board-header-right-section.component.html',
  styleUrls: ['./board-header-right-section.component.scss']
})
export class BoardHeaderRightSectionComponent implements OnInit {
  @Input() dashSelect:any;
  @Input() workfolderJson:any[]=[];
  @Input() showProfile: boolean = true;
  @Input() showNotification: boolean = true;
  @Input() boardType: string;
  @Input() profileCall:boolean=false;
  @Input() homeCall:boolean=false;
  @ViewChild('noAccesInOrgModel') noAccesInOrgModel: TemplateRef<any>;
  loginUserId:string=''
  attributesIcons = AttributesIcons;
  totalUnreadNotifications: any=0;
  userListData: any={};
  orgData: any;
  selectedOrganization: any;
  role: string;
  enumRole: { SUPER_ADMIN: string; ADMIN: string; SALES: string; DASHBOARDADMIN: string; };
  loggedinUserEmail: string;
  orgUserSubscription : any
  pushNotificationSub:any
  loginUserName: string;
  commonIcons = commonIcons;
  currentDashInfo: any;
  

  constructor(private modalService: NgbModal,
    private commonUtils: CommonUtils,
    private messageService: MessageService,
    private dashboardUtils: DashboardUtilsService,
    private customStorageService: CustomStorageService,
    private httpTransfer: HttpTransferService,
    private router: Router,
    private cookieService: CookieService,
    private cdRef: ChangeDetectorRef,
    private compiler: Compiler, private injector: Injector,
    private app: ApplicationRef,
    private toasterService : ToasterService
    ) {
      this.loginUserId = this.customStorageService.getItem("id");
      this.role = this.customStorageService.getItem("role");
      this.loginUserName=this.customStorageService.getItem("name")
      this.orgUserSubscription= this.dashboardUtils.getUpdatedOrgUserJson().subscribe((res)=>{
        this.userListData=res
        this.role = this.customStorageService.getItem("role");
        this.loginUserName=this.customStorageService.getItem("name")
        // trigers no org access modal
        if(Object.keys(this.userListData).length>0 && this.userListData[this.loginUserId] && this.userListData[this.loginUserId]?.is_deleted){
          this.getOrgList();      
          this.modalService.open(this.noAccesInOrgModel, { size: 'sm' ,backdrop: 'static', })  
        }
      })
     }

  async ngOnInit() {
    console.log(this.workfolderJson)
    this.enumRole = Role;
    this.loggedinUserEmail = this.customStorageService.getItem('email');
    if(this.showNotification)this.getUnreadNotificationCount();
    this.pushNotificationSub=this.messageService.getPushNotificationChangeEvent().subscribe(
      ()=>{this.totalUnreadNotifications++}
    );
    const dashboardInfo = await this.dashboardUtils.getDashboardInformation([this.dashSelect])
    this.currentDashInfo = dashboardInfo[this.dashSelect]?.DASHBOARD_INFO || {}
    this.getOrgList()
  }

  async ngOnChanges(changes:SimpleChange) { 
    if(changes['dashSelect'] && this.dashSelect) {
      const dashboardInfo = await this.dashboardUtils.getDashboardInformation([this.dashSelect])
      this.currentDashInfo = dashboardInfo[this.dashSelect]?.DASHBOARD_INFO || {}  
    }
  }

updateNotificationCount(updateObj){
  console.log(updateObj)
  let count = updateObj.count;
  this.totalUnreadNotifications = count;
}

getUnreadNotificationCount() {
  var inputJson = { 
    status: ['unread'],
    pagination_details :{
      page_no	:1,
      page_size:1
    }
  };
  this.httpTransfer.getNotification(this.loginUserId, inputJson).subscribe((res) => {
    if (res.status == 200) {
      var notificationRes = res.result.pagination_details;
      this.totalUnreadNotifications = notificationRes?.total_records || 0;
      this.cdRef.detectChanges();
    }
  });
}


async getOrgList() { 
  this.orgData=await this.dashboardUtils.getUserOrganigations();
  if(this.orgData.length>0){
    this.customStorageService.setItem("allOrganisationIds",JSON.stringify(this.orgData))
    let selctedOrgIndex=this.orgData.findIndex(org=>org.organization_id==this.customStorageService.getItem('organisationid')) 
    if(selctedOrgIndex>-1){
      this.selectedOrganization = this.orgData[selctedOrgIndex]
    }else{
      this.switchOrg(this.orgData[0]?.organization_id,false)
    }  
  } 
}

async switchOrg(orgId: string,refresh=true) {    
  this.dashboardUtils.clearDashboardJson()
  this.customStorageService.setItem('organisationid', orgId);  // set org id required
  this.customStorageService.setItem('token', 'temp_token'); // set dummy token required
  this.customStorageService.removeItem('organisationData'); // clear org data 
  let refreshTokenResult:any = await this.httpTransfer.callRefreshToken(this.httpTransfer.hostUrl + this.httpTransfer.refreshTokenApi).toPromise();
  this.httpTransfer.setRefreshTokanOrSwichOrgDataToLocalStorage(refreshTokenResult);
  if(refresh) this.router.navigate(['/']).then(() => {window.location.reload();}); 
}

logout() {
/*   document.getElementById("nav-bar").classList.add("collapsed-nav")
  document.getElementById("containt-page").classList.add("collapsed-containt-page")
  document.getElementById("nav-bar").classList.remove("expanded-nav")
  document.getElementById("containt-page").classList.remove("expanded-containt-page") */
  var data = {};
  data["client_id"] = this.customStorageService.getItem("clientId");
  this.httpTransfer.userLogOut(data).subscribe((res : any) => {
    if (res.status == 200) {
      this.commonUtils.setFavIconAndTitle()
      this.cookieService.delete('is_LoggedIn','/','pronnel.com');
      this.customStorageService.clear();
      this.router.navigate(["/login"]);
    }
  });
}

redirectToHome(){
  window.open("home/welcome", "_self");
}
ngOnDestroy(){
  if(this.orgUserSubscription)this.orgUserSubscription?.unsubscribe();
  this.pushNotificationSub?.unsubscribe()
}

storeLastUrlbeforeProfile(){
  sessionStorage.setItem("previousUrl", this.router.url.toString());
}



}
