<div class="multislctoptions f-s-r" [ngStyle]="multiSelectElement | gradient" #multiSelectElement>
  <div class="selectedItems f-l-b">
    <div class="w-100 d-flex align-items-center" *ngIf="!multiSelectGroupArray?.length">
      <div class="multiSelecteCount f-xxxxl-xl">{{bulkLeadInfo.length}}</div>
      <span style="width:70px;" class="d-flex align-items-center">{{'multiSelect.selectedItem' | translate:{ term: 'Items' | terminology: itemTerminology?.plural : 'Items' } }}</span>
    </div>
    <span *ngIf="multiSelectGroupArray?.length">{{'dashboard.subHeader.multiSelect' | translate}}</span>
  </div>
  <div class="centrePanelMultiSelect">
    <div class="multiBox" *ngIf="dashInfo?.board_type=='CONTACT'" placement="top" container="body" broadCastPop="ngbPopover" [autoClose]="'outside'" [ngbPopover]="broadCast" (click)="filterObjectForMultiselect('broadCast')">
      <!-- <img src="assets/1x/assign.png"> -->
      <span><ng-container *ngTemplateOutlet="iconTemp;context:{type:('Brodcast')}"></ng-container></span>
      <span class="multiSelectOption f-s-m">{{'multiSelect.Broadcast' | translate}}</span>
      <ng-template #broadCast>
        <ng-container *ngIf="!broadCastChannel">
          <div class="optionDetailBoxBody broadChannel">
            <div class="broadChannelHead f-xl-m justify-content-between"><span>Select Channel</span><span class="cursorPointer" (click)="broadCastPop?.close()"><app-dashboard-icon class="iconBox-20" [size]="20" [name]="'Cross'"></app-dashboard-icon></span></div>
            <div class="broadChannelBody">
              <ng-container *ngFor="let channel of dashboardChannels">
                  <div class="w-100 d-flex align-items-center channelDiv cursor-pointer" (click)="broadCastChannel=channel">
                    <span><ng-container *ngTemplateOutlet="iconTemp;context:{type:(channel?.type)}"></ng-container></span>
                    <span class="f-l-r">{{channel?.name}}</span>
                  </div>
              </ng-container>
            </div>
           </div>
        </ng-container>  
           <ng-container *ngIf="broadCastChannel">
            <div class="broadChannelEditor">
                 <div class="chatEditorWindow"><app-editor-columns class="editorCallingDiv" [broadCastChannel]="broadCastChannel" [selectedLeadObj]="bulkLeadInfo[0]" [leadInfo]="bulkLeadInfo" [objFilterForMultiselect]="objFilterForMultiselect" [multiSelect]="true" [dashId]="selectedDashId" [type]="'CHANNEL'" [tempType]="'CHANNEL'" [columnsJson]="columnsJson" [columnIndex]="columnIndex" [fileEvent]="[]" [mediaArr]="[]" (close)="broadCastPop?.close();"></app-editor-columns></div>
            </div>
           </ng-container>
      </ng-template>
    </div>
    <div *ngIf="!itemAttributesObj?.assigned_to?.hidden" [ngClass]="{'highlightSelectedField' : selectedField=='Assign'}" class="multiBox" placement="top" container="body" 
      #userAssign="ngbPopover" [autoClose]="'outside'" [ngbPopover]="assignUserBulkTemplate" (click)="filterObjectForMultiselect('Assign')">
      <!-- <img src="assets/1x/assign.png"> -->
      <span><ng-container *ngTemplateOutlet="iconTemp;context:{type:('User')}"></ng-container></span>
      <span class="multiSelectOption f-s-m">{{itemAttributesObj?.assigned_to?.label | attributesLabel: itemAttributesObj: 'assigned_to': 'multiSelect.assign' | translate}}</span>
      <ng-template #assignUserBulkTemplate>
          <ng-container>
            <app-lead-assign-user [dashId]="selectedDashId" [bulkSelectionFlag]="true"
              [leadInfo]="bulkLeadInfo"  [objFilterForMultiselect]="objFilterForMultiselect" (closePopover)="userAssign.close()" >
            </app-lead-assign-user>
          </ng-container>
      </ng-template>
    </div>
    <div *ngIf="!itemAttributesObj?.priority?.hidden" [ngClass]="{'highlightSelectedField' : selectedField=='Priority'}" class="multiBox" [ngbPopover]="priorityBulkPopOver" #priority="ngbPopover" placement="auto"
      container="body" [autoClose]="'outside'" (click)="filterObjectForMultiselect('Priority')">
        <!-- <img src="assets/1x/priority.png"> -->
        <span><ng-container *ngTemplateOutlet="iconTemp;context:{type:('Priority')}"></ng-container></span>
        <span class="multiSelectOption f-s-m">{{itemAttributesObj?.priority?.label | attributesLabel: itemAttributesObj: 'priority': 'multiSelect.choosePriority' | translate}}</span>
        <ng-template #priorityBulkPopOver>
            <ng-container>
              <app-lead-priority [dashId]="selectedDashId" [bulkSelectionFlag]="true" [leadInfo]="bulkLeadInfo" [objFilterForMultiselect]="objFilterForMultiselect" (closePopover)="priority.close()"></app-lead-priority>
            </ng-container>
        </ng-template>
    </div>
    <div *ngIf="!itemAttributesObj?.tag_id?.hidden" [ngClass]="{'highlightSelectedField' : selectedField=='Tags'}" class="multiBox" placement="auto" container="body" #tagPopover="ngbPopover" container="body"
      [autoClose]="'outside'" [ngbPopover]="tagPopInBulkTemplate" (click)="filterObjectForMultiselect('Tags')">
        <!-- <img src="assets/1x/tags.png"> -->
        <span><ng-container *ngTemplateOutlet="iconTemp;context:{type:('Tags')}"></ng-container></span>
        <span class="multiSelectOption f-s-m">{{itemAttributesObj?.tag_id?.label | attributesLabel: itemAttributesObj: 'tag_id': 'multiSelect.changeTag' | translate}}</span>         
        <ng-template #tagPopInBulkTemplate>
            <ng-container *ngIf="!showCreateTag">
              <app-lead-tags [dashboardTags]="tagsCorrespondingToDash" [dashId]="selectedDashId"
                [bulkSelectionFlag]="true" [leadInfo]="bulkLeadInfo" [objFilterForMultiselect]="objFilterForMultiselect" 
                (showCreateTag)="showCreateTag = $event">
              </app-lead-tags>
            </ng-container>
            <ng-container *ngIf="showCreateTag">
              <app-create-tag (click)="$event.stopPropagation()"
                  (updateDashboardTags)="tagsCorrespondingToDash = $event;showCreateTag=false"
                  [dashId]="selectedDashId"
                  (closeManageTag)="showCreateTag = $event"
                  (showConfirmationModal)="showConfirmationModalFlag = $event"
                  [confirmationAction]="confirmationAction">
              </app-create-tag>
          </ng-container>
        </ng-template>
    </div>
    <div *ngIf="sprintEnabed" class="multiBox" [ngClass]="{'highlightSelectedField' : selectedField=='Sprints'}" placement="top" [ngbPopover]="sprintTemplate"
    [autoClose]="'outside'" (click)="filterObjectForMultiselect('Sprints')" #phasePopover="ngbPopover">
        <!-- <img src="assets/1x/sprint.png"> -->
        <span><ng-container *ngTemplateOutlet="iconTemp;context:{type:('Sprints')}"></ng-container></span>
        <span class="multiSelectOption f-s-m">{{'multiSelect.choosePhase' | translate}}</span>
        <ng-template #sprintTemplate>
            <ng-container>
              <app-lead-sprint (click)="$event.stopPropagation();" [leadInfo]="bulkLeadInfo"
                [dashId]="selectedDashId" [bulkSelectionFlag]="true" [dashBoardSprint]="dashBoardSprint" [objFilterForMultiselect]="objFilterForMultiselect" (closePopover)="phasePopover.close()">
              </app-lead-sprint>
            </ng-container>
        </ng-template>
    </div>
    <div *ngIf="!itemAttributesObj?.bucket_id?.hidden" [ngClass]="{'highlightSelectedField' : selectedField=='Bucket'}" class="multiBox" placement="top" #bucketPopover="ngbPopover" [ngbPopover]="bucketTemplate"
    [autoClose]="'outside'" (click)="filterObjectForMultiselect('Bucket')">
        <!-- <img src="assets/1x/bucket.png"> -->
        <span><ng-container *ngTemplateOutlet="iconTemp;context:{type:('Bucket')}"></ng-container></span>
        <span class="multiSelectOption f-s-m"> {{itemAttributesObj?.bucket_id?.label | attributesLabel: itemAttributesObj: 'bucket_id': 'multiSelect.chooseBucket' | translate}}</span>
        <ng-template #bucketTemplate>
            <ng-container>
              <app-lead-bucket-change (click)="$event.stopPropagation();" [leadInfo]="bulkLeadInfo"
                [dashId]="selectedDashId" [bucketArray]="bucketArray"
                [bulkSelectionFlag]="true"
                (updateBuckets)="setCurrentAndPreviousBucket($event.leadId,$event.destination_bucket_id,$event.bucketId)"
                [objFilterForMultiselect]="objFilterForMultiselect" 
                (closePopover)="bucketPopover.close()"
                >
              </app-lead-bucket-change>
            </ng-container>
        </ng-template>
    </div>
    <div class="multiBox" [ngClass]="{'highlightSelectedField' : selectedField=='Comment'}" placement="top" [ngbPopover]="commentTemplate" #commentPopover="ngbPopover" [autoClose]="'outside'" (click)="filterObjectForMultiselect('Comment')">
      <span><ng-container *ngTemplateOutlet="iconTemp;context:{type:('Comment')}"></ng-container></span>  
      <span class="multiSelectOption f-s-m"> {{'multiSelect.addComment' | translate}}</span>
        <ng-template #commentTemplate>
          <div class="broadChannelEditor">
            <div class="chatEditorWindow"><app-editor-columns class="editorCallingDiv" [mentionEnabled]="true" [broadCastChannel]="broadCastChannel" [selectedLeadObj]="bulkLeadInfo[0]" [leadInfo]="bulkLeadInfo" [objFilterForMultiselect]="objFilterForMultiselect" [multiSelect]="true" [dashId]="selectedDashId" [type]="'CHANNEL'" [tempType]="'CHANNEL'" [columnsJson]="columnsJson" [columnIndex]="columnIndex" [fileEvent]="[]" [mediaArr]="[]" (close)="commentPopover?.close();"></app-editor-columns></div>
          </div>
        </ng-template>
    </div>
    <div *ngIf="!itemAttributesObj?.start_end_date?.hidden" [ngClass]="{'highlightSelectedField' : selectedField=='Date'}" class="multiBox" [ngbPopover]="datePopover" #popDate="ngbPopover" placement="auto" container="body" popoverClass="datePickerPopover"
       [autoClose]="'outside'" (click)="filterObjectForMultiselect('Date')">
        <!-- <img src="assets/1x/calender.png"> -->
        <span><ng-container *ngTemplateOutlet="iconTemp;context:{type:('Date')}"></ng-container></span>
        <span class="multiSelectOption f-s-m"> {{itemAttributesObj?.start_end_date?.label | attributesLabel: itemAttributesObj: 'start_end_date': 'multiSelect.chooseDate' | translate}}</span>
        <ng-template #datePopover>
            <app-lead-date-picker (click)="$event.stopPropagation()"
              [selectedStartDateObj]="selectedStartDate"
              [selectedEndDateObj]="selectedEndDate"
              [leadInfo]="bulkLeadInfo"
              [selectedDashId]="selectedDashId"
              (closeDateModalOnCancel)="popDate.close()" [objFilterForMultiselect]="objFilterForMultiselect" 
              (closePopover)="popDate.close()"></app-lead-date-picker>
        </ng-template>
    </div>
  
  <!-- code for Item attribute -->
    <div class="multiBox" [ngClass]="{'highlightSelectedField' : selectedField=='updateField'}" [ngbPopover]="itemAttributesPopover" #itemAttributePopover="ngbPopover" placement="auto" container="body"
       [autoClose]="'outside'" (click)="filterObjectForMultiselect('updateField')">
       <span><ng-container *ngTemplateOutlet="iconTemp;context:{type:('Updatefield')}"></ng-container></span>
       <span class="multiSelectOption f-s-m"> {{'multiSelect.updateField' | translate}}</span>
        <ng-template #itemAttributesPopover>
         <app-lead-item-attribute
         (click)="$event.stopPropagation()"
         [leadInfo]="bulkLeadInfo"
         [dashId]="selectedDashId" 
         [bulkSelectionFlag]="true" 
         [dashBoardSprint]="dashBoardSprint" 
         [objFilterForMultiselect]="objFilterForMultiselect" 
         [multiselectBucketArr]="multiselectBucketArr"
         (closePopover)="itemAttributePopover.close()"
         ></app-lead-item-attribute>
        </ng-template>
    </div>
  
    <div class="multiBox" [ngClass]="{'highlightSelectedField' : selectedField=='Delete'}" [ngbPopover]="deletePopOver" #delete="ngbPopover" placement="auto" container="body"
      #popOver="ngbPopover" [autoClose]="'outside'" (click)="filterObjectForMultiselect('Delete')"> 
      <span><ng-container *ngTemplateOutlet="iconTemp;context:{type:('deleteOutline')}"></ng-container></span>
      <span class="multiSelectOption f-s-m"> {{'common.delete' | translate}}</span>
    </div>
    <div class="multiBox" *ngIf="boardListDetail?.list_type=='STATIC'" [ngClass]="{'highlightSelectedField' : selectedField=='RemoveItem'}" [ngbPopover]="deletePopOver" #delete2="ngbPopover" placement="auto" container="body"
      #popOver="ngbPopover" [autoClose]="'outside'" (click)="filterObjectForMultiselect('RemoveItem'); removeFromList=true;"> 
      <span><ng-container *ngTemplateOutlet="iconTemp;context:{type:('deleteOutline')}"></ng-container></span>
      <span class="multiSelectOption f-s-m"> {{'common.removeFromList' | translate}}</span>
    </div>
  
    <div class="multiBox" [ngClass]="{'highlightSelectedField' : selectedField=='Merge Item'}" [ngStyle]="{'opacity': (multiSelectGroupArray?.length || bulkLeadInfo.length<2 || boardListDetail?.connected_board_id) ? '0.5': '1'}"  (click)="(!multiSelectGroupArray?.length && bulkLeadInfo.length>1) && filterObjectForMultiselect('Merge Item');(!multiSelectGroupArray?.length && bulkLeadInfo.length>1) && openMergeIemModal(mergeItemModal);">
      <span><ng-container *ngTemplateOutlet="iconTemp;context:{type:('MergeItems')}"></ng-container></span>
      <span class="multiSelectOption f-s-m"> Merge</span>
      <ng-template #mergeItemModal let-modal>
        <div class="mergeModalBody">
          <ng-container>
            <app-merge-Items style="height: 100%;"
            [leadInfo]="bulkLeadInfo"
            [dashId]="selectedDashId" 
            [bulkSelectionFlag]="true" 
            [objFilterForMultiselect]="objFilterForMultiselect" 
            [multiselectBucketArr]="multiselectBucketArr"
            (closePopover)="mergeItemModalRef.close()" (mergeCompleted)="closeBulkModeOperation()"></app-merge-Items>
          </ng-container>
        </div>
      </ng-template>
    </div>
  </div>
 
  <div class="multiBox" (click)="closeBulkModeOperation()">
    <span><ng-container *ngTemplateOutlet="iconTemp;context:{type:('Cross')}"></ng-container></span>
  </div>
  <ng-template #deletePopOver>
      <div *ngIf="!objFilterForMultiselect.is_deleted" style="display: flex;align-items: center;flex-direction: column;row-gap: 10px; width: 300px;">
        <div style="display: flex;align-items: center;justify-content: center;flex-direction: column;row-gap: 10px;">
          <h5 *ngIf="selectedField=='Delete'">{{'multiSelect.deleteConfirmMessage' | translate}}</h5>
          <h6 *ngIf="selectedField=='RemoveItem'">{{'multiSelect.removeItemConfirmMessage' | translate}}</h6>
          <span style="text-align: center;" *ngIf="selectedField=='Delete'">({{'multiSelect.deleteRestoreInfoMessage' | translate:{ term: 'Items' | terminology: itemTerminology?.plural : 'Items' } }})</span>
        </div>
        <div style="display: flex;column-gap: 10px;">
          <button class="btn btn-success" (click)='deleteItemInBulk(true);delete.close()'>{{'multiSelect.yes' | translate}}</button>
          <button class="btn btn-danger" (click)='delete.close()'>{{'multiSelect.no' | translate}}</button>
        </div>
      </div>
      <div class="cursorPointer" *ngIf="objFilterForMultiselect.is_deleted" >
        <div (click)="openDeleteItemModal(restoreConfirmationModel);delete.close()">
            {{'common.restore' | translate}}
        </div>
        <div class="cursorPointer" *ngIf="dashboardUserRole.includes('DASHBOARD_ADMIN')" (click)="openDeleteItemModal(deleteConfirmationModel);delete.close()">
          {{'leadOption.permanentDelete' | translate}}
        </div>
      </div>


      <!-- Restore delete Lead modal-->
      <ng-template #restoreConfirmationModel let-modal>
        <div class="modal-body text-center py-3">
          <h3>{{'common.confirmation' | translate}}</h3> 
          <img src="../../../assets/1x/info.png">
          {{'leadOption.restoreLeadConfirmation' | translate:{ term: 'Items' | terminology: itemTerminology?.plural : 'Items' } }}
          <button type="button" class="btn btn-danger rounded px-4 mr-3" (click)="deleteItemInBulk(false); modal.dismiss()">{{'common.restore' | translate}}</button>
          <button type="button" class="btn rounded cancelbtn px-4" (click)="modal.dismiss()">{{'common.cancel' | translate}}</button>
        </div>
      </ng-template>
      <!-- delete Lead modal-->
      <ng-template #deleteConfirmationModel let-modal>
        <div class="modal-body text-center py-3">
          <h3>{{'common.confirmation' | translate}}</h3> 
          <img src="../../../assets/1x/info.png">
          {{'leadOption.leadDeleteConfirmation' | translate:{ term: 'Items' | terminology: itemTerminology?.plural : 'Items' } }}
          <button type="button" class="btn btn-danger rounded px-4 mr-3" (click)="deleteItemInBulk(true,true); modal.dismiss()">{{'common.confirm' | translate}}</button>
          <button type="button" class="btn rounded cancelbtn px-4" (click)="modal.dismiss()">{{'common.cancel' | translate}}</button>
        </div>
      </ng-template>
  </ng-template>
</div>


<ng-template #iconTemp let-type="type">
  <ng-container *ngIf="type=='WHATSAPP'">
    <app-dashboard-icon class="iconBox-20" [size]="20" [name]="type" [iconJson]="activityIcons"></app-dashboard-icon>
  </ng-container>
  <ng-container *ngIf="type=='INSTAGRAM'">
    <app-dashboard-icon class="iconBox-20" [size]="20"  [name]="type" [iconJson]="activityIcons"></app-dashboard-icon>
  </ng-container>
  <ng-container *ngIf="type=='TELEGRAM'">
    <app-dashboard-icon class="iconBox-20" [size]="20" [name]="type" [iconJson]="activityIcons"></app-dashboard-icon>
  </ng-container>
  <ng-container *ngIf="type=='VIBER'">
    <app-dashboard-icon class="iconBox-20" [size]="20"  [name]="type" [iconJson]="activityIcons"></app-dashboard-icon>
  </ng-container>
  <ng-container *ngIf="type=='LINE'">
    <app-dashboard-icon class="iconBox-20" [size]="20"  [name]="type" [iconJson]="activityIcons"></app-dashboard-icon>
  </ng-container>
  <ng-container *ngIf="type=='FACEBOOK'">
    <app-dashboard-icon class="iconBox-20" [size]="20"  [name]="type" [iconJson]="activityIcons"></app-dashboard-icon>
  </ng-container>
  <ng-container *ngIf="type=='Priority' || type=='Tags' || type=='Sprints' || type=='Bucket' || type=='Date' || type=='Updatefield' || type=='MergeItems' || type=='Comment' || type=='Brodcast' || type=='Cross'">
    <app-dashboard-icon class="iconBox-20" [size]="20"  [name]="type" [isSvg]="true" [color]="'#fff'"></app-dashboard-icon>
  </ng-container>
  <ng-container *ngIf="type=='User' || type=='deleteOutline'">
    <app-dashboard-icon class="iconBox-20" [size]="20" [name]="type" [iconJson]="commonIcons" [isSvg]="true" [color]="'#fff'"></app-dashboard-icon>
  </ng-container>
</ng-template>