import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit ,} from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { CommonUtils } from 'app/services/CommonUtils.service';
import { DashboardUtilsService } from 'app/services/dashboard-utils.service';

@Component({
  selector: 'rating-cell',
  changeDetection:ChangeDetectionStrategy.OnPush,
  templateUrl: './rating-cell.component.html',
  styleUrls: ['./rating-cell.component.scss']
})
export class RatingCellComponent implements OnInit  {
  params:any={};
  editable: boolean = false
  itemInfo:any={}
  ratingValue:number[]=[];


  constructor(private cdr:ChangeDetectorRef){
  }

  async ngOnInit() {
    this.onUpdate()
  }

  onUpdate(params: any={}){
    this.itemInfo=this.params?.item || {}
    this.editable=this.params?.editable
    this.ratingValue=this.params?.value || 0
    this.cdr.detectChanges()
  }

  updateRating(){
    if(this.ratingValue!=this.params?.value){
      this.params?.gridTableRef?.changedCellValue({value:this.ratingValue,customFieldType:true,params:this.params});
      this.onUpdate(this.params)
    }
       
  }

}
