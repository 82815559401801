<div style="width:300px;height:250px;" *ngIf="!showTemplateContent">
    <div style="height: calc(100% - 50px);">
        <div class="templateRowTop">
            <input type="text" style="width:230px;border:none;" #searchbox placeholder="Search" (input)="filterItem(searchbox.value)"/>
            <span><i class="fa fa-search"></i></span>      
        </div>  
    <div *ngIf="whatsappTemplates.length>0 && !loader" style="height: calc(100% - 30px);overflow: auto;overflow-x: hidden" (click)="$event.stopPropagation()">    
    <div class="templateRow" style="margin-top: 11px;" *ngFor="let item of whatsappTemplates">
      <label [ngbTooltip]="item.template_name" placement="auto" container="body"  tooltipClass="tooltipPopup">{{item.template_name | truncate :45}}</label>
      <span style="cursor:pointer;" (click)="getMessageFromTemplate(item)">{{'whatsapp.use' | translate}}</span>
    </div> 
    </div>
    <ng-container *ngIf="loader">
        <div class="d-flex justify-content-center align-items-center my-2">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only"> {{'common.loading' | translate}}...</span>
          </div>
        </div>
      </ng-container>
    <div *ngIf="whatsappTemplates.length<1 && !loader" style="margin-top: 50px;">
        <span style="font-weight: bold;margin-left: 80px;">No templates available</span>
    </div>
    </div>
    <div class="w-100" *ngIf="showList">
        <button class="w-100 btn Sendbtn" style="height:30px;" type="button" [disabled]="isReadOnly" (click)="openChannelTemplateModal(channelTemplateModal)"> + {{'whatsapp.personalMessageTemplates' | translate}}</button>
    </div>
</div>

<div class="w-100 h-100" *ngIf="showTemplateContent">
  <div class="templatecontentBody">
    <ng-container *ngIf="!loader">
      <ng-container *ngFor="let tempText of whatsappTemplates;let i= index">
         <div class="w-100 messageBox cursorPointer" (click)="tempClick(i)" [ngClass]="{'selectedBox' : i === selectedIndex}" [attr.id]="'tempDiv_' + i" [innerHTML]="tempText?.htmlcontentMsg | safehtml "></div>
      </ng-container>
    </ng-container>
  </div>
  <ng-container *ngIf="loader">
    <div class="d-flex justify-content-center align-items-center my-2">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only"> {{'common.loading' | translate}}...</span>
      </div>
    </div>
  </ng-container>  
</div>


<!--Channels Templates-->
<ng-template #channelTemplateModal let-modal>
    <div style="height:100%;">
      <app-channel-template [personalTemplates]="false" [isTemplateViewCall]="true" [templateCount]="whatsappTemplates.length" [tempType]="emailTemplates ? 'EMAIL' : 'CHANNEL'" [dashId]="dashId" [type]="emailTemplates ? 'EMAIL' : 'CHANNEL'" (closeModal)="modal.dismiss();"></app-channel-template>
    </div>
</ng-template>