<div role="button" (click)="hideList()" class="position-relative w-100">
    <div *ngIf="!noteUsersCall" class="left">
      <P *ngIf="!showOrg && !noteUsersCall"><span class="selectOption"  (mouseenter)="selectedIndex=-1" [class.selected-field]="selectedIndex==-1" (click)="$event.stopPropagation();showAll()"><app-dashboard-icon class="iconBox-24" [size]="20" [name]="'MultiUser'" [iconJson]="AttributesIcons"></app-dashboard-icon>{{'userView.viewAllUsers' | translate}}</span></P>
      <P *ngIf="showOrg && !noteUsersCall" ><span  class="selectOption" (mouseenter)="selectedIndex=-1" [class.selected-field]="selectedIndex==-1" (click)="$event.stopPropagation();showAll()"><app-dashboard-icon class="iconBox-24" [size]="20" [name]="'Board'" [iconJson]="AttributesIcons"></app-dashboard-icon>{{'userView.viewBoardUsers' | translate}}</span></P>
    </div>
    <div *ngIf="noteUsersCall" class="d-flex">
      <p class="ml-2"></p>
    </div>    
      <div class="position-relative w-100">
        <div class="search usrSearchBox mx-0" (click)="$event.stopPropagation();listView()" [ngClass]="{'activeSearch' : formCall}"> 
          <app-dashboard-icon class="iconBox-24" [size]="20" [name]="'search'" [iconJson]="commonIcons"></app-dashboard-icon>
          <input type="search" #searchInput placeholder="{{'placeholders.searchUser' | translate}}" #input (input)="filterUser(input.value)">
        </div>
        <div #container  class="container" *ngIf="showList && !showOrg"> 
          <div class="udls mt-3">
            <span  *ngIf="allowUserToInvite" class="allowUserToInvite ">
              <span >{{'userView.inviteAsCollaborator' | translate}}</span>
              <span (click)="$event.stopPropagation();inviteUserAsExternalCollaborator(searchEmail);">+ {{'userView.invite' | translate}} {{searchEmail}} </span>
            </span>
            <span class="un text-success" *ngIf="inviteMessage">{{'userView.inviteSuccess' | translate}}</span>
            <span *ngIf="invalidSearch" class="un text-danger">{{'userView.incorrectUsernameEmail' | translate}}</span>
          </div>
        <ng-container *ngFor="let item of dashboardUser;let index=index">
          <div class="row" (click)="$event.stopPropagation()" (mouseenter)="selectedIndex=index" [class.selecteduser]="selectedIndex==index" [class.selected-field]="selectedIndex==index">
            <div class="column1" >
              <ngx-avatars class="mr-1" [name]="item.name" [src]="item.image_url" size="30" initialsSize="2" ></ngx-avatars>
              <div class="udls">
                <span class="un">{{item.name}}</span>
                <span class="ue">{{item.email}}</span>
              </div>
            </div>
            <div *ngIf="!isReadOnly" class="column2" (click)="$event.stopPropagation()">
              <span (click)="$event.stopPropagation()"><app-dashboard-icon class="iconBox-24" [size]="20" [name]="'Board'" [iconJson]="AttributesIcons"></app-dashboard-icon></span>             
              <span *ngIf="!addedUserIds?.includes(item._id); else removeUser" style="cursor: pointer;" (click)="$event.stopPropagation();addDashUser(item)"><app-dashboard-icon  class="iconBox-24" [size]="20" [name]="'plusOutline'" [iconJson]="commonIcons"></app-dashboard-icon></span>
              <ng-template #removeUser>
                <img style="cursor: pointer;"  src="../../../assets/1x/cross-icon.svg" role="button" (click)="$event.stopPropagation();removeUserFromList(item)">
              </ng-template>
            </div>
          </div>
        </ng-container>
        </div>
        <div class="container" #container  *ngIf="showList && showOrg">
          <div class="udls mt-3">
            <span  *ngIf="allowUserToInvite" class="allowUserToInvite ">
              <span >{{'userView.inviteAsCollaborator' | translate}}</span>
              <span (click)="$event.stopPropagation();inviteUserAsExternalCollaborator(searchEmail);">+ {{'userView.invite' | translate}} {{searchEmail}} </span>
            </span>
            <span class="un text-success" *ngIf="inviteMessage">{{'userView.inviteSuccess' | translate}}</span>
            <span *ngIf="invalidSearch" class="un text-danger">{{'userView.incorrectUsernameEmail' | translate}}</span>
          </div>
          <div  *ngFor="let item of orgUsersJson;let index=index">
          <div class="row" *ngIf="!item.is_deleted && item.email!=='bot@pronnel.com'" (mouseenter)="selectedIndex=index" [class.selecteduser]="selectedIndex==index" [class.selected-field]="selectedIndex==index">
            <div class="column1" (click)="$event.stopPropagation()">
              <ngx-avatars class="mr-1" [name]="item.name" [src]="item.image_url" size="30" initialsSize="2" ></ngx-avatars>
              <div class="udls">
                <span class="un">{{item.name}}</span>
                <span class="ue">{{item.email}}</span>
              </div>
            </div>
            <div  *ngIf="!isReadOnly" class="column2" (click)="$event.stopPropagation()">
              <span (click)="$event.stopPropagation()" *ngIf="item?.isDashUser"><app-dashboard-icon class="iconBox-24" [size]="20" [name]="'Board'" [iconJson]="AttributesIcons"></app-dashboard-icon></span>
              <span (click)="$event.stopPropagation()" *ngIf="!item?.isDashUser"><app-dashboard-icon class="iconBox-24" [size]="20" [name]="'MultiUser'" [iconJson]="AttributesIcons"></app-dashboard-icon></span> 
              <span *ngIf="!addedUserIds?.includes(item._id); else removeAllUser" style="cursor: pointer;" (click)="$event.stopPropagation();addAllUser(item)" ><app-dashboard-icon  class="iconBox-24" [size]="20" [name]="'plusOutline'" [iconJson]="commonIcons"></app-dashboard-icon></span>
              <ng-template #removeAllUser>
                <img style="cursor: pointer;"  src="../../../assets/1x/cross-icon.svg" (click)="$event.stopPropagation();removeAllUserFromList(item)">
              </ng-template>
            </div>
          </div>
        </div> 
       </div>
      </div>

      <!-- SELECTED USERS -->
      <div class="select" *ngIf="userList?.length && !isGridView">
        <div class="rows w-100" *ngFor="let item of userList">
          <div class="userNameBox col-8">
            <ngx-avatars class="mr-1" [name]="item.name" [src]="item.image_url" size="30" initialsSize="2"></ngx-avatars>
            <span>{{item.name | truncate: 15}}</span>
          </div>
          <div class="rightIconBox col-4" role="button">
            <span><img *ngIf="item?.isDashUser" src="../../../assets/1x/boards-folders.svg"></span>
            <span><img *ngIf="!item?.isDashUser" src="../../../assets/1x/user.svg"></span>
            <img src="../../../assets/1x/cross-icon.svg" (click)="handleRemoveUser(item);validation()">
          </div>
        </div>
      </div>
  </div>

  
  