import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonHeaderComponent } from './common-header/common-header.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { CreateTagComponent } from './create-tag/create-tag.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReusableModule } from '../../reusable.module/reusable.module'; 
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxPaginationModule } from 'ngx-pagination'; 
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; 
import { ShimmerLoaderComponent } from './shimmer-loader/shimmer-loader.component';
import { DashboardIconComponent } from './icons-component/dashboard-icon/dashboard-icon.component';
import { UserInfoCardComponent } from './user-info-card/user-info-card.component';
import { RouterModule } from '@angular/router'; 
import { environment } from '../../../environments/environment';
import { AngularFireMessagingModule } from "@angular/fire/compat/messaging";
import { AngularFireDatabaseModule } from "@angular/fire/compat/database";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFireModule } from "@angular/fire/compat";
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FilePreviewComponent } from './file-preview/file-preview.component';
import { ScreenRecordingComponent } from './screen-recording/screen-recording.component';
import { GroupCreateComponent } from './group-create/group-create.component';
import { SafehtmlPipe } from '../../safehtml.pipe';
import { LinkyModule } from 'ngx-linky';
import { ItemInfoComponent } from '../../reusable/item-info/item-info.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { MinutesPipePipe } from '../../minutes-pipe.pipe';
import { ImageModalComponent } from '../../reusable/imageModal/components/imageModal.component';
import { ImageEditorComponent } from './image-editor/image-editor.component';
// import { ImageDrawingModule } from 'ngx-image-drawing';
import { ImageCropperModule } from 'ngx-image-cropper';
import { WhatsappPreApproveMessagesComponent } from '../whatsapp-pre-approve-messages/whatsapp-pre-approve-messages.component';
import { TranslateModule } from '@ngx-translate/core';
import { WhatsappEditorComponent } from '../dashboard-settings-reusables/whatsapp-editor/whatsapp-editor/whatsapp-editor.component';
import { EmailComponent } from './email/email.component';
import { UserListComponent } from './user-list/user-list.component';
import { NgxSummernoteModule } from 'ngx-summernote';
import { SendEmailComponent } from './send-email/send-email.component';
import { AudioRecordingComponent } from './audio-recording/audio-recording.component';
import { ExpandedEmailViewComponent } from './expanded-email-view/expanded-email-view.component';
import { BoardHeaderRightSectionComponent } from '../dashboard-reusables/board-header-right-section/board-header-right-section.component';
import { AvatarModule } from 'ngx-avatars';
import { ContentEditableInputNoteComponent } from '../dashboard-reusables/content-editable-input-note/content-editable-input-note.component';
import { AutomationColumnsComponent } from '../dashboard-reusables/automation-columns/automation-columns.component';
import { LocationItemSettingsComponent } from '../lead-reusables/location-item-settings/location-item-settings.component';
import { EmailSelectionComponent } from '../lead-reusables/email-selection/email-selection.component';
import { NumberAsDurationComponent } from '../lead-reusables/number-as-duration/number-as-duration.component'; 
import { ClickOutSideDirective } from '../../click-out-side.directive';
import { ConnectEmailsComponent } from './connect-emails/connect-emails.component';
import { NumberOperationComponent } from '../dashboard-reusables/number-operation/number-operation.component';
import { ImapSmtpConnectionComponent } from './imap-smtp-connection/imap-smtp-connection.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ChatMessagesComponent } from '../chat-message/chat-messages.component';
import { ExpandThreadViewComponent } from './expand-thread-view/expand-thread-view.component';
import { EmailDropdownTemplateComponent } from './email-dropdown-template/email-dropdown-template.component';
import { GridTableComponent } from './grid-table/grid-table.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ChannelTemplatePickerComponent } from '../channel-template-picker/channel-template-picker.component';
import { GoogleMapComponent } from '../google-map/google-map.component';
import { FacebookLoginComponent } from '../Facebook-login/facebook-login.component';
import { LeadColumnPickerComponent } from '../lead-columns-picker/lead-columns-picker.component';
import { ChannelTemplateComponent } from '../dashboard-reusables/dashboard-channel-templates/channel-template.component';
import { EditorColumnsComponent } from '../editor-columns/editor-columns.component';
import { ColumnsChipComponent } from '../columns-chip/columns-chip.component';
import { FunctionPopoverComponent } from '../dashboard-reusables/columns-functions/function-popover.component';
import { FunctionEditorComponent } from '../dashboard-reusables/function-editor/function-editor.component';
import { TemplatePickerComponent } from '../template-picker/template-picker.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { DpDatePickerModule } from 'ng2-date-picker';
import { DateCalculatePipe } from 'app/date-calculate.pipe';
import { FormulaCalculationComponent } from '../formula-calculation/formula-calculation.component';
import {KeyboardMonitorDirective } from 'app/keyboardMonitor.directive';
import { MultilevelDropdownComponent } from '../multilevel-dropdown/multi-level-dropdown.component';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { DriveAttachmentComponent } from '../lead-reusables/drive-attachmnet-selection/drive-attachment.component';
import { DeleteConfirmationComponent } from '../delete-confirmation/delete-confirmation.component';
import { TextAssistDirective } from './text-suggestion/text-assist.directive';
import { TextAssistOptionsComponent } from './text-suggestion/text-assist-options/text-assist-options.component';
import { AiAssistComponent } from './text-suggestion/ai-assist/ai-assist.component';
import { GradientPipe } from 'app/gradient.pipe';
import { TemplatesModule } from 'app/components/templates/templates.module';
import { BoardIconComponent } from '../dashboard-reusables/dashboard-icon/board-icon.component';
import { OverflowCheckDirective } from 'app/tooltipOverFlow.directive';
import { TwoLineEllipsisDirective } from 'app/twoline-css-truncate.directive';
import { MinutesToHoursDirective } from 'app/duration-control.directive';
import { CreateDashboardModule } from 'app/create-dashboard/create-dashboard.module';
import { BoardCreationBtnComponent } from './board-creation/board-creationbtn.component';
import { ProcessReusableModule } from '../process-reusables/process-module';
import { ConnectBoardComponent } from './connectBoard/connect-board.component';
import { MultiTypeComponent } from 'app/grid-view/multi-type/multi-type-cell.component';

@NgModule({
  declarations: [
    CommonHeaderComponent,
    NotificationsComponent,
    ConfirmationModalComponent,
    CreateTagComponent,
    ShimmerLoaderComponent,
    DashboardIconComponent,
    UserInfoCardComponent, 
    FilePreviewComponent,
    ScreenRecordingComponent,
    GroupCreateComponent,
    SafehtmlPipe,  
    DateCalculatePipe,
    MinutesPipePipe,
    ItemInfoComponent,
    ImageModalComponent,
    ImageEditorComponent,
    WhatsappPreApproveMessagesComponent,
    WhatsappEditorComponent,
    EmailComponent,
    UserListComponent,
    SendEmailComponent,
    AudioRecordingComponent,
    ExpandedEmailViewComponent,
    BoardHeaderRightSectionComponent,
    ContentEditableInputNoteComponent,
    AutomationColumnsComponent,
    LocationItemSettingsComponent,
    EmailSelectionComponent,
    NumberAsDurationComponent,
    ClickOutSideDirective,
    TextAssistDirective,
    KeyboardMonitorDirective,
    OverflowCheckDirective,
    ConnectEmailsComponent,
    NumberOperationComponent,
    ImapSmtpConnectionComponent,
    ChatMessagesComponent,
    ExpandThreadViewComponent,
    EmailDropdownTemplateComponent,
    GridTableComponent,
    ChannelTemplatePickerComponent,
    GoogleMapComponent,
    FacebookLoginComponent,
    LeadColumnPickerComponent,
    ChannelTemplateComponent,
    EditorColumnsComponent,
    ColumnsChipComponent,
    FunctionPopoverComponent,
    FunctionEditorComponent,
    DatePickerComponent,
    FormulaCalculationComponent,
    TemplatePickerComponent,
    DatePickerComponent,
    MultilevelDropdownComponent,
    ColorPickerComponent,
    DriveAttachmentComponent,
    DeleteConfirmationComponent,
    GradientPipe,
    BoardIconComponent,
    TextAssistOptionsComponent,
    AiAssistComponent,
    TwoLineEllipsisDirective,
    MinutesToHoursDirective,
    BoardCreationBtnComponent,
    ConnectBoardComponent,
    MultiTypeComponent

  ],
  exports: [
    CommonHeaderComponent,
    TextAssistDirective,
    NotificationsComponent,
    ConfirmationModalComponent,
    CreateTagComponent,
    ShimmerLoaderComponent,
    DashboardIconComponent,
    UserInfoCardComponent, 
    FilePreviewComponent,
    ScreenRecordingComponent,
    GroupCreateComponent,
    SafehtmlPipe,  
    DateCalculatePipe,
    ItemInfoComponent,
    MinutesPipePipe,
    ImageModalComponent,
    AvatarModule,
    ImageEditorComponent,
    WhatsappPreApproveMessagesComponent,
    WhatsappEditorComponent,
    EmailComponent,
    UserListComponent,
    SendEmailComponent,
    AudioRecordingComponent,
    ExpandedEmailViewComponent,
    BoardHeaderRightSectionComponent,
    ContentEditableInputNoteComponent,
    AutomationColumnsComponent,
    LocationItemSettingsComponent,
    EmailSelectionComponent,
    NumberAsDurationComponent,
    ClickOutSideDirective,
    KeyboardMonitorDirective,
    OverflowCheckDirective,
    ConnectEmailsComponent,
    NumberOperationComponent,
    ImapSmtpConnectionComponent,
    TooltipModule,
    ChatMessagesComponent,
    ExpandThreadViewComponent,
    GridTableComponent,
    PopoverModule,
    ChannelTemplatePickerComponent,
    GoogleMapComponent,
    FacebookLoginComponent,
    LeadColumnPickerComponent,
    ChannelTemplateComponent,
    EditorColumnsComponent,
    ColumnsChipComponent,
    FunctionPopoverComponent,
    FunctionEditorComponent,
    DatePickerComponent,
    FormulaCalculationComponent,
    TemplatePickerComponent,
    DatePickerComponent,
    MultilevelDropdownComponent,
    ColorPickerComponent,
    DriveAttachmentComponent,
    DeleteConfirmationComponent,
    GradientPipe,
    BoardIconComponent,
    TwoLineEllipsisDirective,
    MinutesToHoursDirective,
    BoardCreationBtnComponent,
    ConnectBoardComponent,
    MultiTypeComponent
  ],

  imports: [
    FormsModule,
    ReusableModule,
    PopoverModule.forRoot(),
    NgSelectModule,
    NgbModule, 
    InfiniteScrollModule,
    NgxPaginationModule, 
    AvatarModule,
    RouterModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    DpDatePickerModule,
    NgxSkeletonLoaderModule,
    ReactiveFormsModule,
    LinkyModule,
    NgxDocViewerModule,
    NgxSummernoteModule,
    // ImageDrawingModule,
    ImageCropperModule,
    TranslateModule,
    CommonModule,
    TooltipModule,
    DragDropModule,
    ScrollingModule,
    CreateDashboardModule,
  ]
})
export class SharedComponentsModule {

}
