import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstantService } from '../../../../Enums/Constant.service';
import { CommonUtils } from '../../../services/CommonUtils.service';
import { DashboardUtilsService } from '../../../services/dashboard-utils.service';
import { HttpTransferService } from '../../../services/httpTransfer.service';
import { ToasterService } from '../../../services/toaster.service';
import { MediaSmIcons } from 'Enums/media-sm-icons'; 
import { AttributesIcons, AttributesLxIcons, commonIcons } from '../../../../Enums/attributes-icons';

@Component({
  selector: 'app-merge-Items',
  templateUrl: './merge-Items.component.html',
  styleUrls: ['./merge-Items.component.scss']
})
export class MergeItemComponent implements OnInit {
  @Output() closePopover = new EventEmitter<any>();
  @Output() mergeCompleted = new EventEmitter<any>();

  @Input() leadInfo:any[];
  attributesIcons = AttributesIcons;
  lxAttributesIcons = AttributesLxIcons;
  leadIdArr:any[]=[]
  leadIdJson:any={}
  titleFieldOptions:any[]=[]
  fieldsArr:any[]=[]
  @Input() dashId:number;
  @Input() bulkSelectionFlag:boolean;
  @Input() objFilterForMultiselect: any; 

  MediaSmIcons = MediaSmIcons;

  flippedView:boolean=false;
  bucketArr:any[]=[]
  bucketMap:any={}
  tagArr:any[]=[]
  tagMap:any={}
  sprintArr:any[]=[]
  sprintMap:any={}
  priorityMap:any={}
  orgUsersJson:any

  //we are strong only 1st Item info in this json
  connectedItemIdJson:any={}
  connectedItemIdArr:any[]=[]

  connectedBoardIdArr:any[]=[]
  
  mergeToLeadId:any
  mergeToLeadSeqId:any
  requireConfirmation:boolean=false;
  leadMergeInput:any
  errMessage:any
  merging:boolean=false;
  mergeConfirmModalRef:any

  commonIcons = commonIcons


  constructor(
    private ngbModalService: NgbModal,
    private httpTransfer : HttpTransferService,
    public commonUtils: CommonUtils,
    public dashboardUtils: DashboardUtilsService,
    private toaster : ToasterService,
    private constantService: ConstantService


  ) { }

  async ngOnInit(){

    this.leadInfo.forEach(lead=>{
      if(lead?._id){
        this.leadIdJson[lead?._id]=lead
      }
    })
    if(this.leadInfo && this.leadInfo.length>3){
      this.flippedView=true
    }
    this.leadIdArr=Object.keys(this.leadIdJson) 
    this.fieldsArr=await this.makeArrOfFields()
  }


  async makeArrOfFields(){

    this.orgUsersJson = await this.dashboardUtils.getOrgUsers();
    for (let key in this.orgUsersJson) {
      if (this.orgUsersJson.hasOwnProperty(key) && this.orgUsersJson[key]['is_bot']) {
          delete this.orgUsersJson[key];
          break; 
      }
  }
    let dashboardJson = await this.dashboardUtils.getDashboardCustomForms(this.dashId)
    let custmFields=JSON.parse(JSON.stringify(dashboardJson[this.dashId]['CUSTOM_FORM'] || []))
    this.bucketArr=JSON.parse(JSON.stringify(dashboardJson[this.dashId]['BUCKET'] || []))
    this.tagArr=JSON.parse(JSON.stringify(dashboardJson[this.dashId]['TAG'] || []))
    this.sprintArr=JSON.parse(JSON.stringify(dashboardJson[this.dashId]['SPRINT'] || []))
    this.constantService.constant.Priority.forEach(ele=>{
      this.priorityMap[ele.id.toString()]=ele
    })

    if(this.bucketArr.length){
      this.bucketArr.forEach(bucket=>{
        this.bucketMap[bucket._id]=bucket
      })
    }

    

    let arr:any[]=[
      {display_key:"Fields", key:"seq_id",type:"seq_id",isCustomField:false,mandatory:true,multiple:false,value:this.getDefaultValue('seq_id',false,false) || ""},
      {display_key:"Title", key:"title",type:"Text",isCustomField:false,mandatory:true,multiple:false,value:this.getDefaultValue('title',false,false) || "" },
      {display_key:"Priority", key:"priority",type:"Priority",isCustomField:false,mandatory:true,multiple:false,value:this.getDefaultValue('priority',false,false) || ""},
      {display_key:"Bucket", key:"bucket_id",type:"Bucket",isCustomField:false,mandatory:true,multiple:false,value:this.getDefaultValue('bucket_id',false,false) || "" },
      {display_key:"Assignee", key:"assigned_to",type:"SingleUser",isCustomField:false,mandatory:false,multiple:false,value:this.getDefaultValue('assigned_to',false,false) || "" },
      {display_key:"Collaborators", key:"lead_colloborators_users",type:"MultiUser",isCustomField:false,mandatory:false,multiple:true,value:this.getDefaultValue('lead_colloborators_users',false,true) || {} },

    ]

    if(this.sprintArr.length){
      arr.push({display_key:"Phase", key:"sprint_id",type:"Sprints",isCustomField:false,mandatory:false,multiple:false,value:this.getDefaultValue('sprint_id',false,false) || "" } )
      this.sprintArr.forEach(sprint=>{
        this.sprintMap[sprint._id]=sprint
      })
    }
    if(this.tagArr.length){
      arr.push({display_key:"Tags", key:"tag_id",type:"Tags",isCustomField:false,mandatory:false,multiple:false,value:this.getDefaultValue('tag_id',false,false) || ''})
      this.tagArr.forEach(tag=>{
        this.tagMap[tag._id]=tag
      })
    }
 
    for (var i = 0; i < custmFields.length; ++i) {

      if((!['Mirror','Button','IPAddress'].includes(custmFields[i].type)) || (custmFields[i].type==='IPAddress' && custmFields[i]?.settings==="Manual")){
        let obj={
          display_key:custmFields[i].display_key, 
          key:custmFields[i].key,
          type:custmFields[i].type,
          isCustomField:true,
          mandatory:custmFields[i].mandatory
        }

        if(custmFields[i].type=='Board'){
          this.connectedBoardIdArr.push(custmFields[i].connected_board_id)
          this.connectedItemIdArr=this.makeConnectedItemArr(custmFields[i].key)
        }

        obj['multiple']= ["Checkbox","MultiSelect",'MultiUrl','MultiUser','MultipleImage','MultipleFile','MultipleVideo','MultipleAudio','CheckList','TimeTracker'].includes(custmFields[i].type) ? true : false
        obj['value']=  this.getDefaultValue(custmFields[i].key,true,obj['multiple']) || (obj['multiple'] ? {} : '')
        arr.push(obj)    
      }
    }
    this.connectedItemIdJson=await this.gettingConnectedItemsInfo()
    this.getSeqId(arr)
    return arr
  }


  makeConnectedItemArr(fieldKey){
    this.leadInfo.forEach(item=>{
      if(item.custom_fields[fieldKey] && item.custom_fields[fieldKey].length){
        this.connectedItemIdArr.push(item.custom_fields[fieldKey][0])
      }      
    })
    return this.connectedItemIdArr
  }

  async gettingConnectedItemsInfo(){
    var inputJson = {
      dashboard_id:this.connectedBoardIdArr,
      lead_id:this.connectedItemIdArr
    }
    
    let res : any=await this.httpTransfer.getLeadQuery(inputJson).toPromise();
    if(res.status==200){
      if(res.result.leadResponse.length){
        res.result.leadResponse.forEach(ele=>{
          this.connectedItemIdJson[ele._id]=ele
        })
      }
    }
    return this.connectedItemIdJson
  }


  getDefaultValue(field,iscustomField,multiple){
    let value:any=false
    for(let i=0;i<this.leadInfo.length;i++){
      let id=this.leadInfo[i]._id
      if(!iscustomField && this.leadInfo[i][field]){
        if(multiple && this.leadInfo[i][field].length>0){
          value= { [id] : true }
          break
        }
        else if(!multiple){
          value= id
          break
        }
      }
      else if(iscustomField && this.leadInfo[i].custom_fields.hasOwnProperty(field) && this.leadInfo[i].custom_fields[field]!=null && this.leadInfo[i].custom_fields[field]!=undefined){
        if(multiple && this.leadInfo[i].custom_fields[field].length>0){
          value= { [id] : true }
          break
        }
        else if(!multiple){
          value= id
          break
        }
      }
    }
    return value
  }



  mergeItem(){
    let input={}
    let custmFields={}
    for(let i=0;i<this.fieldsArr.length;i++){
      let field=this.fieldsArr[i]
      if(field.key=='seq_id'){
        this.mergeToLeadId=field.value
      }
      else{
        let val=this.getSelectedFieldValue(field)
          if(field.isCustomField){
            custmFields[field.key]=val
          }
          else{
            input[field.key]=val ? val : field.type=='Tags' && !val ? [] :field.type === 'Priority' && val === 0 
            ? parseInt(val) : null
          }
          
      }
    }
    input['custom_fields']=custmFields
    input['merge_item_ids']=this.leadIdArr.filter(ele=> ele!=this.mergeToLeadId)
    input=this.addCurrentAssigneeInCollaboatorsArr(input)
    this.leadMergeInput=input
    this.updateItem(input,this.mergeToLeadId)
  }

  addCurrentAssigneeInCollaboatorsArr(input){
    if(input['lead_colloborators_users']){
      if(this.leadIdJson[this.mergeToLeadId]['assigned_to'] && !input['lead_colloborators_users'].includes(this.leadIdJson[this.mergeToLeadId]['assigned_to'])){
        input['lead_colloborators_users'].push(this.leadIdJson[this.mergeToLeadId]['assigned_to'])
      }
    }
    return input
  }


  getSelectedFieldValue(field){
    let value:any=field.multiple && field.type!=='TimeTracker' ? [] : null
    if(!field.multiple && field.value){
      if(['SingleImage','SingleFile','SingleVideo','SingleAudio'].includes(field.type)){
        let currentField=  this.leadIdJson[field.value].custom_fields[field.key]
        if(currentField){
          value={}
          value['file_name']=currentField?.file_name,
          value['file_path']=currentField?.file_path,
          value['image_download_url']=currentField?.file_url
        }
      }
      else{
        value= field.isCustomField ? this.leadIdJson[field.value].custom_fields[field.key] : (field.key=='priority' ?  parseInt(this.leadIdJson[field.value][field.key]) : this.leadIdJson[field.value][field.key])        
      }
    }
    else if(field.multiple && Object.values(field.value).includes(true) ){
      let selectedIds = Object.keys(field.value).filter(key => field.value[key]);
      if(selectedIds.length){
        if(field.type==='TimeTracker'){
          value=this.getMultipleSelectedValueForTimeTracker(selectedIds,field)
        }else{
          let valueArr=[]
          selectedIds.forEach(id=>{
           valueArr=this.getMultipleSelectedValue(id,field,valueArr)
          })
          value=valueArr
        }
      }
    }
    return value
  }

  
  getMultipleSelectedValue(id,field,valueArr){
    let currentField= field.isCustomField ? this.leadIdJson[id].custom_fields[field.key] : this.leadIdJson[id][field.key]

    if(['MultipleImage','MultipleFile','MultipleVideo','MultipleAudio'].includes(field.type)){
      valueArr=this.getMultipleSelectedValueForMultiMedia(id,field,valueArr)
    }
    else if(field.type==='CheckList'){
      valueArr=this.getMultipleSelectedValueForChecklist(id,field,valueArr)
    }
    else{
      if(currentField && currentField.length>0){
        currentField.forEach(item=>{
          if(!valueArr.includes(item)){
            valueArr.push(item)
          }
        })
      }  
    }

    return valueArr
  }

  getMultipleSelectedValueForMultiMedia(id,field,valueArr){
    let pathArr=[]
    let currentField= field.isCustomField ? this.leadIdJson[id].custom_fields[field.key]: this.leadIdJson[id][field.key]
    if(currentField && currentField.length>0){
      currentField.forEach(item=>{
        if(!pathArr.includes(item?.file_path)){
          let obj={
            file_name:item?.file_name,
            file_path:item?.file_path,
            image_download_url:item?.file_url
          }
          pathArr.push(item?.file_path)
          valueArr.push(obj)
        }
      })
    }  
    return valueArr
  }


  getMultipleSelectedValueForChecklist(id,field,valueArr){
    let checklistArr=[]
    let currentField= field.isCustomField ? this.leadIdJson[id].custom_fields[field.key]: this.leadIdJson[id][field.key]
    if(currentField && currentField.length>0){
      currentField.forEach(item=>{
        if(!checklistArr.includes(item?.summary)){
          checklistArr.push(item?.summary)
          valueArr.push(item)
        }
      })
    }  
    return valueArr
  }

  getMultipleSelectedValueForTimeTracker(selectedIds,field,){
    let merge_timetracker_items=[]
    
    selectedIds.forEach(id=>{
      merge_timetracker_items.push(id)
     })
     let value={
      'merge_timetracker_items':merge_timetracker_items
     }
    return merge_timetracker_items.length ? value : null
  }

  changeInSelection(key){
    if(key=='seq_id'){
      this.getSeqId(this.fieldsArr)
    }

  }


  getSeqId(arr){
    let field=arr[0]
    if(field?.key=='seq_id'){
      this.mergeToLeadSeqId=this.leadIdJson[field.value]['seq_id']
    }
  }

  updateItem(data,leadId,confirmation?){
  this.merging=true
  this.httpTransfer.updateLeadCorrespondingToLeadId(data, this.dashId, leadId,confirmation,false) .subscribe((res : any) => {
          if (res.status === 200) {  
            this.mergeConfirmModalRef?.close()  
            this.closePopover.emit()
            this.mergeCompleted.emit()
            this.merging=false
            console.log("Succedfully Merged")      
            // this.updateLeadTitle.emit({'leadId':leadId,'bucketId':this.bucketId});
          }
        },
        (err:any)=>{
          console.log(err)
          if(err.status=500 && err.message && err.message.includes('Can not delete because lead used in::')){
            const separatorIndex = err.message.split('Can not delete because lead used in::');
            this.errMessage= separatorIndex[1];
            this.requireConfirmation=true
            this.merging=false
          }
          else{
            this.merging=false
            this.mergeConfirmModalRef?.close()  
            this.toaster.Errors(err.message)
          }
        });
      
  }


  mergeConfirmation(){
    this.updateItem(this.leadMergeInput,this.mergeToLeadId,true)

  }

  openMergeConfirmIemModal(modalContent) {
    this.requireConfirmation=false
    this.mergeConfirmModalRef=this.ngbModalService.open(modalContent, { size : 'sm', windowClass: 'mergeConfirm',  backdrop : 'static', keyboard : false});
  }
}