<div class="d-flex flex-row justify-content-center align-items-center cell-box" role="button" #bucketPopover="ngbPopover" [placement]="['bottom','bottom-right','bottom-left','auto']" container="body" [ngbPopover]="params?.editable && bucketTemplate" [autoClose]="'outside'" popoverClass="gridtableCell">
    <div class="bucketName">
      {{selectedBucketInfo?.name}}
    </div>
</div>
<ng-template #bucketTemplate>
    <app-lead-bucket-change (click)="$event.stopPropagation();" [dashId]="itemInfo?.dashboard_id"
        [bucketArray]="allowBucketArr" [bulkSelectionFlag]="false"
        (updateLead)="onBucketUpdate($event);bucketPopover?.close()">
    </app-lead-bucket-change>
  </ng-template>

