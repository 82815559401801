<div class="cell-box tag-cell" role="button"  #tagPopover="ngbPopover" [placement]="['bottom','bottom-right','bottom-left','auto']" container="body" (click)="showCreateTag=false" [ngbPopover]="params?.editable && tagTemplate" [autoClose]="'outside'" popoverClass="gridtableCell" >
    <div class="tagField d-flex">
      <div *ngIf="!params?.value?.length">
        <span class="iconBox"><img src="../../../../../assets/common-use-icon-20dp/tag-3571de.svg"></span>
      </div>
      <ng-container *ngIf="params?.value?.length">
        <ng-container *ngFor="let tagId of params?.value">
            <div *ngIf="tagsMap[tagId]" class="cardtag"  container="body" placement="auto" [ngbTooltip]="tagsMap[tagId]?.name.length>7 && tagsMap[tagId]?.name" tooltipClass="tooltipPopup" >
                <div class="tagText">{{tagsMap[tagId]?.name}}</div>
            </div>
        </ng-container>
      </ng-container>
    </div>
</div>
<ng-template #tagTemplate>
  <div *ngIf="!showCreateTag">
    <app-lead-tags 
      (click)="$event.stopPropagation()" 
      (showCreateTag)="showCreateTag=$event"
      [isGrid]="true"
      [dashboardTagsJson]="params?.columnParams[this.itemInfo?.dashboard_id]?.tags || []" 
      [leadInfo]="[itemInfo]"
      [dashId]="itemInfo?.dashboard_id" 
      (removedTag)="OnTagUpdate($event,'remove')"
      (addedTag)="OnTagUpdate($event,'add')"
      [bulkSelectionFlag]="false">
    </app-lead-tags>
  </div>
  <ng-container *ngIf="showCreateTag">
    <app-create-tag 
      (updateDashboardTags)="showCreateTag=false" 
      [dashId]="itemInfo?.dashboard_id"
      (closeManageTag)="showCreateTag=false">
    </app-create-tag>
  </ng-container>
  <!-- (isTagModalUpdated)="showCreateTag=false" -->
  </ng-template>

