<div class="cell-box date-cell-box" role="button" #datePopOver="ngbPopover"  [placement]="['bottom','bottom-right','bottom-left','auto']" container="body" [ngbPopover]="editable && datePopover" [autoClose]="'outside'" popoverClass="gridtableCell datePickerPopover" >
    <div class="d-flex" style="max-width: 100%;">
        <span class="mr-1 calender-icon" *ngIf="!date?.customDate && !date?.end_date?.time && !date?.start_date?.time || date?.customDate && !date?.customDate?.time" ><img src="../../../assets/common-use-icon-20dp/calendar-6d6e6f.svg"></span>
        <!-- start and end date start -->
        <div *ngIf="params?.field=='start_end_date'" class="text-box d-flex">
            <ng-container *ngIf="date?.start_date?.time || date?.end_date?.time">
                {{date?.start_date?.time ? (date?.start_date | dateCalcuate:"date") : 'Start date'}}
                <ng-container *ngIf="date?.start_date?.is_time_added">
                    {{','+date?.start_date | dateCalcuate:"time"}}
                </ng-container>
                <span class="mx-1">-</span>
                {{date?.end_date?.time ? (date?.end_date | dateCalcuate:"date") : 'End date'}}
                <ng-container *ngIf="date?.end_date?.is_time_added">
                    {{','+date?.end_date | dateCalcuate:"time"}}
                </ng-container>
            </ng-container>
        </div>
        <ng-container *ngIf="editable && ((params?.field=='start_end_date' && (date?.end_date?.time || date?.start_date?.time)) || (params?.field!='start_end_date' && date?.customDate?.time))">
            <div class="remove-date" (click)="$event?.stopPropagation();params?.field=='start_end_date' ? changeStartEndDates({}) : onCustomDateChange(null);">
                <i class="fa fa-times crossIcon"></i>
            </div>
        </ng-container>
        <!-- single date start here -->
        <ng-container *ngIf="date?.customDate" class>
            <div *ngIf="!params?.columnParams?.customField?.is_end_date"  class="text-box" >{{date?.customDate | dateCalcuate:"dateTime"}}</div>
            <ng-container *ngIf="params?.columnParams?.customField?.is_end_date">
                <div *ngIf="date?.customDate?.from"  class="text-box" >{{date?.customDate?.from?.time | date:'dd MMM yyyy'}}</div>
                <span  *ngIf="date?.customDate?.to" class="pl-1 pr-1"> - </span>
                <div *ngIf="date?.customDate?.to"  class="text-box" >{{date?.customDate?.to?.time | date:'dd MMM yyyy'}}</div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="editable && ((params?.field=='start_end_date' && (date?.end_date?.time || date?.start_date?.time)) || (params?.field!='start_end_date' && (date?.customDate?.time || (date?.customDate?.from || date?.customDate?.to))))">
            <div class="remove-date" (click)="$event?.stopPropagation();params?.field=='start_end_date' ? changeStartEndDates({}) : onCustomDateChange(null);">
                <i class="fa fa-times crossIcon"></i>
            </div>
        </ng-container>
    </div>  
</div>
<ng-template #datePopover>
    <ng-container *ngIf="date?.customDate">
        <date-picker 
            [startEndDatePicker]="params?.columnParams?.customField?.is_end_date?true:false"
            [dateConfig]="itemData?.dateValidationJson?.dateConfig"
            [enableTimeEditor]="!itemData?.dateValidationJson?.disable_time"
            [dateRange]="params?.columnParams?.customField?.is_end_date?{'from':date?.customDate?.from?date?.customDate?.from:date?.customDate?.originalValue,'to':date?.customDate?.to?date?.customDate?.to:date?.customDate?.originalValue}:{'from':date?.customDate?.originalValue}"
            [focusDate]="date?.customDate?.originalValue"
            (onDateSave)="onCustomDateChange($event);datePopOver?.close()"
            (onDateClear)="onCustomDateChange(null)">
        </date-picker>
    </ng-container>
    <ng-container *ngIf="params?.field=='start_end_date'">
        <app-lead-date-picker (click)="$event.stopPropagation()" [selectedStartDateObj]="date?.start_date"   [selectedEndDateObj]="date?.end_date"  [selectedDashId]="itemInfo?.dashboard_id"  (closeDateModalOnCancel)="datePopOver?.close()" (setDateTime)="changeStartEndDates($event);datePopOver?.close()"></app-lead-date-picker>
    </ng-container>
</ng-template> 


 
                   
