<div [class]="selectedThemeColor">
    <div class="modal-sm">
        <div class="modal-header delHeader">
          <div class="head">
            <h4 class="modal-title">{{'common.areYouSure' | translate}}</h4>
          </div>
        </div>
        <div class="modal-body delBody">
          <img src="../../../assets/img/icons/delete.svg" />
          <br />
          <label>{{"common.DELETE_CONFIRMATION"|translate}} {{warningTitle | truncate:20}}</label>
        </div>
        <div class="modal-footer">
          <button class="btn prevbtn themeChild" (click)="closeEmit.emit()">{{'common.discard' | translate}}</button>
          <button class="btn prevbtn themeChild" (click)="deleteEmit.emit()">{{'common.confirm' | translate}}</button>
        </div>
      </div>
</div>