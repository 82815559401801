import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonUtils } from '../../services/CommonUtils.service';
import { DashboardUtilsService } from '../../services/dashboard-utils.service';
import { ConstantService } from '../../../Enums/Constant.service';
import { AttributesIcons, AttributesLxIcons } from '../../../Enums/attributes-icons';
import * as moment from 'moment';
import { HttpTransferService } from '../../services/httpTransfer.service';
import { AutomationUtilsService } from 'app/services/automation-utils.service';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-advance-filter',
  templateUrl: './advance-filter.component.html',
  styleUrls: ['./advance-filter.component.scss']
})
export class AdvanceFilterComponent implements OnInit {

  @Input() selectedDashId: string;
  @Input() localFilterDataObj: any;
  @Input() advancedFilterArr: any
  @Input() advancedFilterData: any
  @Input() level: number=0 
  @Input() automationCall:boolean=false;
  @Input() triggerType:any;
  @Input() catchHookReqJson:any;
  @Input() appSampleReqJson: any;
  @Input() isTriggeredNonItemField:boolean=false;
  @Input() filterColumnJson: any;
  @Input() columnIndex: number;
  @Input() actionobj:any;
  @Input() triggerItemcustomFields: any;
  @Input() arrayOfCustomFiled:any;
  @ViewChild('popColumnsForChat') public popColumnsForChat: NgbPopover;
  maxlevel:number=2
  attributesIcons = AttributesIcons;
  lxAttributesIcons = AttributesLxIcons;
  booleanFieldsArr=[{value:true,key:'True'},{value:false,key:'False'}]
  itemTypeArr=[{value:'ITEM_PUBLIC',key:'Public Item'},{value:'ITEM_INTERNAL',key:'Board Item'}]
  dateOptionsForSingleDate=[{display_key:'Custom Date',key:'CUSTOM'},{display_key:'Evaluated Date',key:'EVAL'}]
  dateOptionsForRangeDate=[{display_key:'Custom Date',key:'CUSTOM'},{display_key:'Fiscal Date',key:'EVAL'}]
  relativeDateTemplates:any
  fiscalDateTemplates:any

  directFiscaldateTempList:any[]=[]
  @Output() updateAdvanceFilter = new EventEmitter()
  @Output() deleteThisGroup = new EventEmitter()
  @Output() advanceColumnFilter = new EventEmitter()




  //temp Variable
  currentDateKey: string;



  constructor(
    private dashboardUtils: DashboardUtilsService,
    public commonUtils: CommonUtils,
    public constantService: ConstantService,
    private httpTransferService:HttpTransferService,
    private automationUtils : AutomationUtilsService
  ) {
   }

  ngOnInit() {
    let dateOptionJsonArr=Object.values(this.automationUtils.getListForActionOnDateType('Date',this.arrayOfCustomFiled,this.isTriggeredNonItemField,true))
    dateOptionJsonArr=dateOptionJsonArr.filter(e=>e.isCalculation==false && e.key!="manual")
    this.relativeDateTemplates=this.commonUtils.getRelativeDateTemplates()
    let jsonOfrelativeDateTemplates=Object.values( this.relativeDateTemplates)
    for(let i=0;i<jsonOfrelativeDateTemplates.length;i++) {
      this.dateOptionsForSingleDate.push({display_key:jsonOfrelativeDateTemplates[i]['display_key'],key:jsonOfrelativeDateTemplates[i]['key']})
    }
    this.fiscalDateTemplates=this.commonUtils.getFiscalDateTemplates()

    for (let temp in this.fiscalDateTemplates) {
      if (this.fiscalDateTemplates.hasOwnProperty(temp) && this.fiscalDateTemplates[temp]?.type==='DIRECT') {
        this.directFiscaldateTempList.push(temp)
      }
    }


    let jsonOfFiscalDateTemplates=Object.values( this.fiscalDateTemplates)
    for(let i=0;i<jsonOfFiscalDateTemplates.length;i++) {
      this.dateOptionsForRangeDate.push({display_key:jsonOfFiscalDateTemplates[i]['display_key'],key:jsonOfFiscalDateTemplates[i]['key']})
    }
    if(this.automationCall){
      dateOptionJsonArr.forEach(date=>{
        this.dateOptionsForSingleDate.push({display_key: date.display_key, key: date.uniquekey })
      })
    }
    setTimeout(()=>{
      console.log(this.advancedFilterData)
      this.checkForColumnsInadvancefilter()
    },200)
  }

  makeValueData(filter){
    if(filter?.type=='FILTER'){
    }

  }

  addMoreFilter(){
    let obj={'key':null,'operator':null,'value':null,'type':'FILTER','filterOperator': this.advancedFilterArr[0]?.filterOperator ? this.advancedFilterArr[0]?.filterOperator : 'and','optionSelected':null}
    this.advancedFilterArr.push(obj)
    console.log(this.advancedFilterArr)
    this.updateAdvanceFilter.emit()
  }

  addMoreFilterGroup(){
    let obj={'key':null,'operator':null,'value':null,'type':'FILTER','filterOperator':'and'}
    let filterArr=[obj]
    let groupobj={'type':'GROUP','filterArr':filterArr,'filterOperator':'and'}
    this.advancedFilterArr.push(groupobj)
    this.updateAdvanceFilter.emit()

  }

  makeDataAccordingOp(filter){
    let field=this.advancedFilterData?.jsonlistForAdvanceFilter[filter.key]
    if(filter.operator=='RANGE' && field?.type!='Date' && field?.type!='DateTime'){
      filter.value={from:null,to:null}
    }
    if(['Board','MultipleBoard'].includes(field?.type)){
      if(!this.advancedFilterData?.connectedBoardData[field.connected_board_id]){
        this.httpTransferService.getLeadQuery({'dashboard_id':[field.connected_board_id]}).subscribe(res =>{
          this.advancedFilterData.connectedBoardData[field.connected_board_id] = res.result.leadResponse
        })
      }
    }
    else if(field?.type=='lead'){
      if(!this.advancedFilterData?.dashBoardLeadList.length){
        let ids=this.advancedFilterData['multipleBoardIds'] || [this.selectedDashId]
        ids=>ids.filter(id=>id)
        this.httpTransferService.getLeadQuery({'dashboard_id':ids}).subscribe(res =>{
          this.advancedFilterData.dashBoardLeadList = res.result.leadResponse
        })
      }
    }
  }

  removeFilter(i){
    this.advancedFilterArr.splice(i,1)
    this.updateAdvanceFilter.emit()
  }

  makeDateObj(filter){

    if(filter.optionSelected=='CUSTOM' && filter.operator=='RANGE'){
      let dateObj={
        from:filter.value && filter.value.from ? filter.value.from :null,to:filter.value &&  filter.value.to ? filter.value.to :null
      }
      filter.value={ type:'CUSTOM' ,date: dateObj}
    }
    else if(filter.optionSelected=='CUSTOM' && filter.operator!='RANGE'){
      filter.value={ type:'CUSTOM' ,date:filter.value && filter.value.date ? filter.value.date : null,time:filter?.value?.time}
      filter.dateObj= { date: moment(filter?.value.date)?.format('DD/MM/YYYY'), is_time_added: false, time: filter.value.date }

    }
    
    else if(filter.optionSelected=='EVAL' && filter.operator!='RANGE'){

      if(!filter.value ||( filter.value.type=='EVAL' && !filter.value.eval_json)){
        filter['evaldateObj']={
          "relative_date": {
              "day": 0,
              "month": 0,
              "year": 0
          },
          "day": {
              "value": "{{relative_day}}"
          },
          "month": {
              "value": "{{relative_month}}"
          },
          "year": {
              "value": "{{relative_year}}"
          },
          "weekday_adjust": {
              "value": 0
          }
       }
      }
      else if(filter.value && filter.value.type=='EVAL'){
        filter['evaldateObj']=filter.value.eval_json
      }
     this.evalDateUpdated(filter)
    }
    else if(filter.optionSelected=='EVAL' && filter.operator=='RANGE'){
      if(!filter.value ||( filter.value.type=='EVAL' && !filter.value.eval_json)){
        filter['fiscaldateObj']={
          "cyclic": true,
          "fiscal_year": {
              "start_day": 1,
              "start_month": 1
          },
          "filter_cycle": {
              "month": 1
          },
          "eval_period": {
              "relative_period": 0
          }
        }
      }
      else if(filter.value && filter.value.type=='EVAL'){
        filter['fiscaldateObj']=filter.value.eval_json
      }
      this.fiscalDateUpadted(filter)

    }
    else if(filter.operator!='RANGE' && filter.optionSelected){
      if(this.relativeDateTemplates[filter.optionSelected]){
        filter['evaldateObj']=this.relativeDateTemplates[filter.optionSelected]['value']
        this.evalDateUpdated(filter)
      }
      else {
        let datearr=this.dateOptionsForSingleDate.filter(ele=>ele.key==filter.optionSelected)
        filter['eval_date_string']= datearr[0].display_key
        filter['value']=filter.optionSelected
        console.log(filter,this.dateOptionsForSingleDate)
      }
    } 
    else if(filter.operator=='RANGE' && filter.optionSelected){
      if(this.directFiscaldateTempList.includes(filter.optionSelected)){
        filter['fiscaldateObj']=this.fiscalDateTemplates[filter.optionSelected]['value']
        this.fiscalDateUpadted(filter)
      }
      else{
        filter['fiscaldateObj']=JSON.parse(JSON.stringify(this.fiscalDateTemplates[filter.optionSelected]['value']))
        this.fiscalDateCalcUpdate(filter)
      }
    }
  }

  evalDateUpdated(filter,event?){
    if(event){
      filter.evaldateObj=event
      filter.optionSelected='EVAL'
    }
    let res = this.commonUtils.calculateEvalDate(filter.evaldateObj)
    let evalDate = res.evaluated_date
    if(filter.optionSelected=='EVAL'){
      filter['eval_date_string']= this.commonUtils.getDateText(evalDate)
      filter.value= {
        'type': filter.optionSelected,
        'eval_json': filter.evaldateObj     
       }
     }
     else{
      filter['eval_date_string']=filter.optionSelected ? this.relativeDateTemplates[filter.optionSelected].display_key :''
      filter.value= {
        'type': 'EVAL_TEMP',
        'eval_json': filter.evaldateObj,
        'eval_temp':   filter.optionSelected
       }
     }
     this.updateAdvanceFilter.emit()

  }

  fiscalDateUpadted(filter,event?){
    if(event){
      filter.fiscaldateObj=event
      filter.optionSelected='EVAL'
    }
    let res = this.commonUtils.calcFiscalPeriod(filter.fiscaldateObj)
    let fiscalDate = new Date(res.from)

    if(filter.optionSelected=='EVAL'){
      filter['fiscal_date_string']= this.commonUtils.getDateText(fiscalDate)
      filter.value= {
        'type': filter.optionSelected,
        'eval_json': filter.fiscaldateObj     
       }
     }
     else{
      filter['fiscal_date_string']=filter.optionSelected ? this.fiscalDateTemplates[filter.optionSelected].display_key :''
      filter.value= {
        'type': 'EVAL_TEMP',
        'eval_json': filter.fiscaldateObj,
        'eval_temp':   filter.optionSelected
       }
     }
     this.updateAdvanceFilter.emit()

  }

  fiscalDateCalcUpdate(filter,event?,keyFor?){
    if(event && keyFor){
      filter.fiscaldateObj.date[keyFor]=event
    }
    console.log(filter.fiscaldateObj)
    let fromRes=this.commonUtils.calculateEvalDate(filter.fiscaldateObj?.date?.from)
    let fromDate = fromRes.evaluated_date
    let toRes=this.commonUtils.calculateEvalDate(filter.fiscaldateObj?.date?.to)
    let toDate = toRes.evaluated_date
    filter['eval_date_string']={
      from:this.commonUtils.getDateText(fromDate),
      to:this.commonUtils.getDateText(toDate),
    }
    filter.value= filter.fiscaldateObj,
     this.updateAdvanceFilter.emit()
  }

  checkForColumnsInadvancefilter(){
    console.log("call for check")
    this.advancedFilterArr.forEach(p=>{
      if(p?.value && typeof p?.value === "string" && p?.value?.includes('{{')){
        p.enableColumns = true  
      }
      else{
        p.enableColumns = false
      }

    })
  }
  

  isNumber(value: any): boolean {
    return !isNaN(value) && typeof value === 'number';
  }

  checkForMobileNumberCustom(filter){
    filter.value = filter?.custom!='customMobile' ? filter?.custom : null
  }

  removeItem(item, clear) {
    clear(item);
  }
}
