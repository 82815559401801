import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonUtils } from 'app/services/CommonUtils.service';
import { time, timeStamp } from 'console';
declare var $: any;
@Component({
  selector: 'date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnInit {


  @Input() startEndDatePicker=false
  @Input() dateRange:any={}
  @Input() focusDate:any=null
  @Input() dateConfig:any=null
  @Input() enableTimeEditor:any=true
  @Input() showHeader:any=true
  @Input() showClearOrSaveBtn:any=true
  @Input() activeDateField:any='from'
  @Input() keyboardAccess:any=true
  @Input() isIncludeFullDayTime:any=false;
  @Input() setSameWhileEmpty:any=false;
  @Input() showAllDay:boolean=false;
  @Input() type:string=''
  @Output()  onDateSave = new EventEmitter<any>();
  @Output()  onDateClear = new EventEmitter<any>();
  @Output()  onDateSelect = new EventEmitter<any>();
  @Output()  openPopUp = new EventEmitter<any>();
  @Output()  onMonthChange = new EventEmitter<any>();
  timeSuggestionPopupRef: any;
  @ViewChild('calenderViewport', { static: false }) calenderViewport: ElementRef;
  dates:any=[]
  currentDate:any=[]
  currentFocusMonthYear:any={}
  weeks:any=['Sun','Mon','Tue','Wed','Thu','Fri','Sat']
  months:any=['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November','December']
  selectedDateRange:any={}
  timeSuggestion:any=[];
  calenderView:any="Day"
  yearsRange:any=[]
  selectedYear:any=null
  selectionIndex:any=0
  portionIndex:any=2;
  disabledDates:any=[];
  constructor(
    private commonUtils:CommonUtils
  ) {}

  ngOnInit(): void {
    console.log("date picker ",this.dateRange,this.focusDate,this.dateConfig,this.activeDateField)
    this.currentDate=new Date()
    if(!this.focusDate){
      this.goToCurrentDate()
    }else{
      let focusDate=this.getConvertDate(this.focusDate)
      if(focusDate?.date!='Invalid Date' && Object.keys(this.focusDate || {})?.length>0){
        this.currentFocusMonthYear={month:focusDate?.date?.getMonth(),year:focusDate?.date?.getFullYear()}
        this.daysHandle(this.currentFocusMonthYear?.month,this.currentFocusMonthYear.year)
      }else{
        this.goToCurrentDate()
      }
    }
    if(!this.dateRange?.hasOwnProperty(this.activeDateField)){
      let key=Object.keys(this.dateRange || {})
      if(key.length>0)this.activeDateField=key[0];
    }
    if(this.dateRange?.from){
      this.setDateFromRange('from')
    }
    if(this.dateRange?.to){
      this.setDateFromRange('to')
    }
    if(this.keyboardAccess){
      document.addEventListener('keydown',this.onKeyDown)
    }
    
  }

  calculateWorkAndOffDateAccurance(fromDate,toDate){
    let weeks=['SUN','MON','TUE','WED','THU','FRI','SAT']
    let finalData=[]
    let dateObj=new Date(fromDate)
    dateObj.setHours(0,0,0,0);
    while(dateObj<=toDate){
      let dateInfo={date:dateObj,type:'OFF'}
      if(this.dateConfig?.exception?.work_days){
        this.dateConfig.exception?.work_days?.forEach(data=>{
          if(data?.day === weeks[dateObj.getDay()])dateInfo={date:dateObj,type:'WORK'}
        })
      }
      if(this.dateConfig?.exception?.weekly_exception){
        const firstDayOfMonth = new Date(dateObj.getFullYear(), dateObj.getMonth(), 1).getDay();
        let weekNumberOfDate= Math.ceil((dateObj.getDate() + firstDayOfMonth) / 7);
        this.dateConfig.exception.weekly_exception.forEach(data=>{
          if(data?.day === weeks[dateObj.getDate()] && data.week_number==weekNumberOfDate)dateInfo={date:dateObj,type:data.type}
        })
      }
      if(this.dateConfig?.exception?.monthly_exception){
        this.dateConfig.exception.monthly_exception.forEach(data=>{
          if(data?.day == dateObj.getDate())dateInfo={date:dateObj,type:data.type}
        })
      }
      if(this.dateConfig?.exception?.overrides){
        this.dateConfig.exception.overrides.forEach(data=>{
          let dateSince1900=data?.date ? this.commonUtils.calculateTimestampSince1900(data?.date) : null
          if(dateSince1900 && dateObj?.getTime()==dateSince1900)dateInfo={date:dateObj,type:data.type}
        })
      }
      if(dateInfo)finalData.push(dateInfo)
      dateObj=new Date(dateObj)
      dateObj.setDate(dateObj.getDate()+1)
    }
    this.disabledDates=finalData.filter(data=>data.type=='OFF')
  }

  setDateFromRange(key){
    let dateObj=this.getConvertDate(this.dateRange[key])
    if(dateObj?.date!='Invalid Date'){
      this.selectedDateRange[key]={
        'date':dateObj?.date?.getDate(),
        'month':dateObj?.date?.getMonth(),
        'year':dateObj?.date?.getFullYear(),
        'isTimeAdded':dateObj?.time,
        'timeSearchTxt':dateObj?.time?.timeString,
        'dateStamp':this.dateRange[key]
      }
    }
  }

  daysHandle(currentMonth,currentYear){
    this.calenderView='Day'
    this.currentFocusMonthYear.month=currentMonth;
    this.currentFocusMonthYear.year=currentYear
    
    let fastDayOfMonth=new Date(currentYear,currentMonth,1).getDay()
    let lastDateOfMonth=new Date(currentYear,currentMonth+1,0).getDate()
    let lastDayOfMonth=new Date(currentYear,currentMonth,lastDateOfMonth).getDay()
    let lastDateOfLastMonth=new Date(currentYear,currentMonth,0).getDate()
    this.dates=[]
    for(let i=fastDayOfMonth-1;i>=0;i--){
      this.dates.push({day:(lastDateOfLastMonth-i),monthType:'PreviousMonth'})
    }
    for(let i=1;i<=lastDateOfMonth;i++){
      this.dates.push({day:i,monthType:'CurrentMonth'})
    }
    for(let i=lastDayOfMonth;i<6;i++){
      this.dates.push({day:(i-lastDayOfMonth+1),monthType:'NextMonth'})
    }

    if(this.dateConfig?.exception){
      let previousMonthStartDate=new Date(currentYear,currentMonth,1,0,0,0,0);
      previousMonthStartDate.setMonth(previousMonthStartDate.getMonth()-1)
      let nextMonthStartDate=new Date(currentYear,currentMonth,1,0,0,0,0)
      nextMonthStartDate.setMonth(nextMonthStartDate.getMonth()+2)
      this.calculateWorkAndOffDateAccurance(previousMonthStartDate,nextMonthStartDate)
    }

  }


  changePreviousOrNextMonth(chnageIndex){
    let newMonth=this.currentFocusMonthYear.month+chnageIndex
    if(newMonth<0){
      this.currentFocusMonthYear.month=this.months.length-1
      this.currentFocusMonthYear.year=this.currentFocusMonthYear.year-1
    }else if(this.months.length<=newMonth){
      this.currentFocusMonthYear.month=0
      this.currentFocusMonthYear.year=this.currentFocusMonthYear.year+1
    }else{
      this.currentFocusMonthYear.month=newMonth
    }
    this.daysHandle(this.currentFocusMonthYear?.month,this.currentFocusMonthYear.year)
    this.onMonthChange?.emit({event:this.currentFocusMonthYear,index:chnageIndex})
  }

  isDateBetweenInRange(date){
    if(this.selectedDateRange?.from?.date && this.selectedDateRange?.to?.date){
      return this.isDateLessThan(this.selectedDateRange?.from?.dateStamp,date) && !this.isDateLessThan(this.selectedDateRange?.to?.dateStamp,date)
    }
    return false
  }

  goToCurrentDate(){
    this.currentDate=new Date()
    this.currentFocusMonthYear={month:this.currentDate?.getMonth(),year:this.currentDate?.getFullYear()}
    this.daysHandle(this.currentFocusMonthYear?.month,this.currentFocusMonthYear.year)
  }
  onSelect(date,event?){
    if(date?.monthType=='PreviousMonth')this.changePreviousOrNextMonth(-1)
    if(date?.monthType=='NextMonth')this.changePreviousOrNextMonth(1)
    this.selectedDateRange[this.activeDateField]={
      'date':date.day,
      'month':this.currentFocusMonthYear?.month,
      'year':this.currentFocusMonthYear?.year
    }
    let obj=this.getConvertedDate();
    if(this.startEndDatePicker){
      // check from is not greater then to date field
      if(obj['from']?.time>obj['to']?.time){
        this.activeDateField=this.activeDateField=='from' ? 'to' : 'from'
        this.selectedDateRange[this.activeDateField]=this.setSameWhileEmpty ? {'date':date.day,'month':this.currentFocusMonthYear?.month,'year':this.currentFocusMonthYear?.year} : {}
        obj=this.getConvertedDate();
      }
    }
    Object.keys(obj)?.forEach(key=>this.selectedDateRange[key]['dateStamp']=obj[key])
    this.onDateSelect?.emit(this.startEndDatePicker ? obj : obj[this.activeDateField])
    this.openPopUp?.emit(event)
  }

  onSave(){
    let obj=this.getConvertedDate();
    this.onDateSave?.emit(this.startEndDatePicker ? obj : obj[this.activeDateField])
  }

  isDateDisabled(date){
    let block=false
    if(this.dateConfig?.exception){
      let monthYear =this.getMonthYearFromDate(date)
      let checkDateExist=this.disabledDates?.find(data=>data?.date?.getFullYear()==monthYear?.year && data?.date?.getMonth() ==monthYear?.month && data?.date?.getDate()==date.day)
      block=checkDateExist ? true : false
    }
    return this.isMaxOrMaxDateValidation(date) || block
  }

  isMaxOrMaxDateValidation(date){
    if(this.dateConfig?.maxDate || this.dateConfig?.minDate){
      if(this.dateConfig?.maxDate && this.isDateLessThan(this.dateConfig?.maxDate,date) || this.dateConfig?.minDate && !this.isDateLessThan(this.dateConfig?.minDate,date,true)){
        return true
      }
    }
    return false
  }

  isDateLessThan(dateObj,date,isConsiderSame=false){
    let monthYear =this.getMonthYearFromDate(date)
    let dateInstance=new Date(monthYear?.year,monthYear?.month,date?.day,0,0,0)
    let convertedDate=this.getConvertDate(dateObj)
    if(convertedDate?.date!='Invalid Date' && dateInstance){
      return isConsiderSame ? convertedDate?.date?.getTime()<=dateInstance?.getTime(): convertedDate?.date?.getTime()<dateInstance?.getTime()
    }else{
      return false
    }
  }

  isSelectedDate(date,key){
    let monthYear =this.getMonthYearFromDate(date)
    return this.selectedDateRange[key]?.date && this.selectedDateRange[key]?.year==monthYear?.year && this.selectedDateRange[key]?.month==monthYear?.month && this.selectedDateRange[key]?.date==date?.day
  }
  getMonthYearFromDate(date){
    let monthCalcuate=this.currentFocusMonthYear?.month+(date?.monthType=='PreviousMonth' ? -1 : (date?.monthType=='NextMonth' ? 1 : 0))
    let year=this.currentFocusMonthYear?.year+(monthCalcuate<0 ? -1 : (monthCalcuate>11 ? 1 : 0))
    return {year:year,month:monthCalcuate}
  }


  getConvertedDate(){
    let obj={}
    Object.keys(this.selectedDateRange || {}).forEach(key=>{
      let dateObj={date: null,time: null,is_time_added: false}
      if(this.selectedDateRange[key]){
        let defaultTime=key=='to' || this.isIncludeFullDayTime ? {hh:23,mm:59,ss:59,ms:999} : {hh:0,mm:0,ss:0,ms:0}
        let dateWithoutTime=new Date(this.selectedDateRange[key]?.year,this.selectedDateRange[key]?.month,this.selectedDateRange[key]?.date,defaultTime?.hh,defaultTime?.mm,defaultTime?.ss,defaultTime?.ms)
        dateObj['time']=dateWithoutTime?.getTime()
        dateObj['date']=this.commonUtils.calculateDaysSince1900(dateObj['time'])
        dateObj['is_time_added']=false
        // handle date with time
        if(this.enableTimeEditor && this.selectedDateRange[key]?.isTimeAdded){
          let time ={ hours:0 ,minutes:0,seconds:0}
          let timeObj=this.selectedDateRange[key]?.isTimeAdded?.timeObj
          time.hours =(timeObj?.meridiem=='PM' && timeObj?.hours!==12) ? timeObj?.hours+12 : timeObj?.hours
          time.minutes=timeObj?.minutes || 0

          let dateWithTime=new Date(this.selectedDateRange[key]?.year,this.selectedDateRange[key]?.month,this.selectedDateRange[key]?.date,time?.hours,time?.minutes,time?.seconds)
          dateObj['is_time_added']=true
          dateObj['time']=dateWithTime?.getTime()
          dateObj['day_time']=dateWithTime?.getTime()-dateWithoutTime?.getTime()
        }
      }
      obj[key]=dateObj
    })
    return obj
  }

  onClear(){
    delete this.selectedDateRange[this.activeDateField]
    let obj={[this.activeDateField]:{date: null,time: null,is_time_added: false,day_time:null}}
    this.onDateClear?.emit(obj)
  }

  removeDate(key){
    this.selectedDateRange[key]={}
    if(this.showAllDay)
     {
      let tempkey = (key=='from') ? 'to' : 'from'
      this.selectedDateRange[tempkey]={}
     }
  }

  getConvertDate(dateObj){
    let date=null;
    let time=null
    if(dateObj?.time || dateObj?.date){
      if(!dateObj?.is_time_added && dateObj?.date && !isNaN(dateObj?.date)){
        let timeFrom1900=this.commonUtils.calculateTimestampSince1900(dateObj?.date)
        date=new Date(timeFrom1900)
      }else{
        date=new Date(dateObj?.time) 
        let hrs = date.getHours()
        let amOrpm= hrs>=12 ? 'PM' : 'AM'
        hrs=(hrs%12) || 12;
        time={
          timeString:((hrs==0 ? 12 : hrs)+':'+(date.getMinutes()<10 ? '0'+ date.getMinutes() : date.getMinutes())+' '+amOrpm),
          timeObj:{hours:hrs,minutes:date.getMinutes(),meridiem:amOrpm}
        }
      }
    }
    return {date:date || 'Invalid Date',time:time}
  }



  generateTimeSuggestion(input?){
    this.portionIndex=4;
    this.selectionIndex=0
    let checkHoursOrMinutesValidation=false
    let timeComponents = input?.split(':');
    this.selectionIndex==0
    if(input){;
      const [hours2, minutes2, ampm2] = input.split(/:|\s+/);
      checkHoursOrMinutesValidation= hours2<=12 && hours2>0 && (!minutes2 || minutes2 && minutes2<=59 && minutes2>=0)
      this.timeSuggestion=this.getTimeGeneratedList(input,checkHoursOrMinutesValidation,15)
      if(this.timeSuggestion?.length==0){
        this.timeSuggestion=this.getTimeGeneratedList(input,checkHoursOrMinutesValidation,1)
      }else if(timeComponents?.length>1){
        this.timeSuggestion=this.getTimeGeneratedList(input,checkHoursOrMinutesValidation,5)
      }
      if(this.timeSuggestion?.length<=0){
        this.timeSuggestion=this.getTimeGeneratedList(input,false,15)
      }
    }else{
      this.timeSuggestion=this.getTimeGeneratedList(null,false)
    }
  }

  getTimeGeneratedList(input?,checkHoursOrMinutesValidation?,minutesGap=15){
    let minutes = 0
    let meridiem='PM'
    let hours = 12
    let dummyTimeSuggestion=[]
    let i=1
    while(i<=24) {
      let obj={
        timeString:((hours==0 ? 12 : hours)+':'+(minutes<10 ? '0'+ minutes : minutes)+' '+meridiem),
        timeObj:{hours:hours,minutes:minutes,meridiem:meridiem}
      }
      if(!input || !checkHoursOrMinutesValidation || input && obj?.timeString && obj?.timeString?.toLowerCase()?.includes(input?.toLowerCase()))dummyTimeSuggestion.push(obj)
      minutes += minutesGap;
      if (minutes >= 60) {
          minutes = 0;
          hours = (hours + 1) % 12;
          i++
          if (hours === 0) {
              meridiem = meridiem === 'AM' ? 'PM' : 'AM';
          }
      }
    }
    return dummyTimeSuggestion
  }

  onTimeSet(time,key,index){
    let dummyTimaArr= this.getTimeGeneratedList(null,false)
    if(this.showAllDay){
      if(key=='from'){
        this.selectedDateRange[key].isTimeAdded=time
        this.selectedDateRange[key].timeSearchTxt=time.timeString
        if(!this.selectedDateRange['to']?.isTimeAdded || this.selectedDateRange['to']?.isTimeAdded==null){
        let int = dummyTimaArr.findIndex(t=>t?.timeString == time.timeString)
        if(dummyTimaArr?.length-1 == int){int = 0}
        if(int>=0){
          this.selectedDateRange['to'].isTimeAdded= dummyTimaArr[int+4]
          this.selectedDateRange['to'].timeSearchTxt=dummyTimaArr[int+4]?.timeString  
        }
        }
        }
        else if(key=='to'){
          this.selectedDateRange[key].isTimeAdded=time
          this.selectedDateRange[key].timeSearchTxt=time.timeString
          if(!this.selectedDateRange['from'] || this.selectedDateRange['from']?.isTimeAdded==null){
            let int = dummyTimaArr.findIndex(t=>t?.timeString == time.timeString)
            if(int>=0){
              this.selectedDateRange['from'].isTimeAdded= int>3 ? dummyTimaArr[int-1] : dummyTimaArr[dummyTimaArr?.length -4 + int]
              this.selectedDateRange['from'].timeSearchTxt=int>3 ? dummyTimaArr[int-1]?.timeString  : dummyTimaArr[dummyTimaArr?.length -4 + int]?.timeString
            }
          }
        }
    }
    else{
      this.selectedDateRange[key].isTimeAdded=time
      this.selectedDateRange[key].timeSearchTxt=time.timeString
  
    }
  }

  // strat year handling
  yearList(startYear){
    this.calenderView='Year'
    this.yearsRange=[]
    let year=startYear
    for(let i=0;i<35;i++){
      this.yearsRange.push(year)
      year=year+1
    }
  }

  // end year handling

  // start keyboard monitoring

  onKeyDown = (event: any): void => {
    switch (event.key) {
      case 'ArrowLeft':
        if(!this.timeSuggestionPopupRef?.isOpen()){
          if(this.portionIndex==2){
            this.selectionIndex--;
            if(this.calenderView=='Day'){
              if(this.selectionIndex<0 || this.dates[this.selectionIndex]?.monthType=='PreviousMonth'){
                this.changePreviousOrNextMonth(-1)
                this.selectionIndex=this.dates?.length-1
              }
            }else if(this.calenderView=='Month'){
              if(this.selectionIndex<0){
                this.selectionIndex=this.months?.length-1
              }
            }else if(this.calenderView=='Year'){
              if(this.selectionIndex<0){
                this.yearList(this.yearsRange[0]-35)
                this.selectionIndex=this.yearsRange?.length-1
              }
            }
          }else if(this.portionIndex==3){
            this.selectionIndex++
            if(this.selectionIndex>1)this.selectionIndex=0
          }else if(this.portionIndex==1){
            this.selectionIndex--;
            if(this.selectionIndex<0){
              if(this.calenderView=='Year' || this.calenderView=='Month'){
               this.selectionIndex=1
              }else{
                this.selectionIndex=4
              }
            }
          }else if(this.portionIndex==0 && this.startEndDatePicker){
            this.selectionIndex--;
            if(this.selectionIndex<0){
              this.selectionIndex=1
            }
          }
        }
        break;
      case 'ArrowRight':
        if(!this.timeSuggestionPopupRef?.isOpen()){
          if(this.portionIndex==2){
            this.selectionIndex++;
            if(this.calenderView=='Day'){
              if(this.selectionIndex>=this.dates?.length || this.selectionIndex<this.dates?.length && this.dates[this.selectionIndex]?.monthType=='NextMonth'){
                this.changePreviousOrNextMonth(1)
                this.selectionIndex=0
              }
            }else if(this.calenderView=='Month'){
              if(this.selectionIndex>=this.months?.length){
                this.selectionIndex=0
              }
            }else if(this.calenderView=='Year'){
              if(this.selectionIndex>=this.yearsRange?.length){
                this.yearList(this.yearsRange[this.yearsRange?.length-1])
                this.selectionIndex=0
              }
            }
          }else if(this.portionIndex==3){
            this.selectionIndex--
            if(this.selectionIndex<0)this.selectionIndex=1
          }else if(this.portionIndex==1){
            this.selectionIndex++;
            if(this.selectionIndex>3 || (this.calenderView=='Year' || this.calenderView=='Month') && this.selectionIndex>1){
              this.selectionIndex=0
            }
          }else if(this.portionIndex==0 && this.startEndDatePicker){
            this.selectionIndex++;
            if(this.selectionIndex>1){
              this.selectionIndex=0
            }
          }
        }
        break; 
      case 'ArrowUp':
        if(this.timeSuggestionPopupRef?.isOpen()){
          this.portionIndex=4
          this.selectionIndex--
          if(this.selectionIndex<0)this.selectionIndex=0
          let focusElement=this.calenderViewport?.nativeElement?.querySelector('.key-active')
          if(focusElement){
            focusElement?.scrollIntoView({behavior:'smooth', block: "center",inline: "center"})
          }
        }else{ 
          if(this.portionIndex!=2)this.portionIndex--
          if(this.portionIndex<0)this.portionIndex=3
        }
        break;
      case 'ArrowDown':
        if(this.timeSuggestionPopupRef?.isOpen()){
          this.portionIndex=4
          this.selectionIndex++
          if(this.selectionIndex>=this.timeSuggestion?.length)this.selectionIndex=0
          let focusElement=this.calenderViewport?.nativeElement?.querySelector('.key-active')
          if(focusElement){
            focusElement?.scrollIntoView({behavior:'smooth', block: "center",inline: "center"})
          }
        }else{
          if(this.portionIndex!=2)this.portionIndex++
          if(this.portionIndex>3)this.portionIndex=0
        }
        break;  
      case 'Tab':
        event?.preventDefault()
        if(!this.timeSuggestionPopupRef?.isOpen()){
          this.portionIndex++;
          this.selectionIndex=0
          if(this.portionIndex>=4)this.portionIndex=0
        }
        break;   
      case 'Enter':
        let focusElement=this.calenderViewport?.nativeElement?.querySelector('.key-active')
        if(focusElement){
          focusElement?.click()
        }
        break;
    }
  }

  setTimeForAllDay(){
    console.log(this.selectedDateRange)
    this.enableTimeEditor = !this.dateConfig.allDay
  }

  // end keyboard monitoring

  ngOnDestroy(){
    document.removeEventListener('keydown',this.onKeyDown)
  }

  changeMonthYear(index){
    this.onMonthChange?.emit({event:this.currentFocusMonthYear,index:index})
  }

  isColorAddedForWorkSchedule(date){
    if (this.dateConfig?.exception?.workScheduleDate?.length > 0) {
      let monthYear = this.getMonthYearFromDate(date)
      let index = this.dateConfig?.exception?.workScheduleDate?.findIndex(data => {
        const curDate = new Date(data?.date)
        return curDate?.getFullYear() == monthYear?.year && curDate?.getMonth() == monthYear?.month && curDate?.getDate() == date.day
      })
      if (index < 0) {
        return ''
      }
      return this.dateConfig?.exception?.workScheduleDate[index]?.color
    }
    else{return ''}
  }



}
