import { Component, Input, OnInit,Output, EventEmitter, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { ConstantService } from '../../../Enums/Constant.service';
import { CommonUtils } from '../../services/CommonUtils.service';
import { CustomStorageService } from '../../services/custom-storage.service';
import { DashboardUtilsService } from '../../services/dashboard-utils.service';
import { AutomationUtilsService } from '../../services/automation-utils.service';
import { HttpTransferService } from '../../services/httpTransfer.service';
import { isNumeric } from 'jquery';
import { KeyboardMonitorService } from 'app/services/keyboardMonitor.service';
import { AttributesIcons, commonIcons } from 'Enums/attributes-icons';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {
  @Output() getInstance = new EventEmitter();
  @Input() settingCall:boolean=false;
  @Input() selectedDashId: string='';
  @Input() multipleBoardIds: any;
  @Input() enableMultipleBoardFilter: boolean=false;
  @Input() curentDashId: string='';
  @Input() showFilter = false;
  @Input() localFilterDataObj: any;
  @Input() withoutTagsFilter: boolean=false;
  @Input() nonFinalBucketsFilter: boolean=false;
  @Input() arrayOfCustomFiled: any[];
  @Input() automationCall : boolean= false;
  @Input() filteredAndCreate : boolean= false;
  originalautomationCall : boolean= false;
  @Input() connectedDashboardArr : any[]=[];
  @Input() actionobj:any
  @Input() showfilterForBoard:boolean= false;
  @Input() currentDashId:any;
  @Input() subtaskFilter:boolean=false
  @Output() resetFilterEmit = new EventEmitter()
  @Output() applyFilterEmit = new EventEmitter();
  @Output() columnFilterEmit = new EventEmitter();
  @Output() appliedFilterCountEmit = new EventEmitter();
  @Output() saveFilterEmit = new EventEmitter();
  @Output() closeFilterEmit = new EventEmitter();
  @Output() validateFilterEmit = new EventEmitter();
  @Output() nonFinalBucketsFilterEmit = new EventEmitter();
  @Output() withoutTagsFilterEmit = new EventEmitter();
  @Output() connectedDashboardEmit = new EventEmitter();
  @Input() bulkSelectionMode:boolean= false;
  @Input() bottomBarFilter:boolean=false;
  @Input() appTemplateCall: boolean = false;
  @Input() templateVariableArr: any[]=[];
  @Input() isListPopupopen = false;

  connectedDashboardJson: any = {};
  bucketArray: any[];
  dashBoardSprint: any[]=[];
  @Input() quickFilterName: any;
  showQuickFilterNameField: boolean;
  itemAttributesObj: any;
  orgUsersJson: {};
  orgFiltersUsersArr: any[] = [];
  orgFiltersSingelUsersArr: any[] = [];
  orgFiltersMultiUsersArr: any[] = [];
  orgFiltersUsersForAssigneeArr: any[] = [];
  orgFiltersUsersForCollArr: any[] = [];
  dashBoardSprintForFilter: any[] = [];
  originalDashBoardSprintForFilter: any[] = [];
  dashboardFormsArr: any[]
  currentDateKey: string;
  loggedInUserId: string;
  filterDropdownBucketList: any[] = [];
  filterCopyOfCustomFields: any[] = [];
  priorityArray: { id: number; value: string; checked: boolean; image: string; color: string; }[];
  dashboardJsonSub: any;
  tagsCorrespondingToDash: any[]=[];
  isReadOnly: boolean;
  openInTemplateOrInternalBoard: boolean;
  @Input() sortByOptionsViewList=[]
  sortOrder = [
    {option:"Ascending", value:"ASC"},
    {option:"Descending", value:"DSC"},
  ]
  sortByOptions = [
    {option:"Priority",value:"priority"},
    {option:"Assignee",value:"assigned_to"},
    {option:"Title",value:"title"},
    {option:"Bucket",value:"bucket_id"},
    {option:"Create time",value:"create_date"},
    {option:"Last update",value:"last_updated"},
    {option:"None",value:"index"},
    {value: 'start_date', option: 'Start date'},
    {value: 'end_date', option: 'End date'}
  ]
  sortingCustomFields: any[];
  orgFiltersUsersArrWithoutBotUser: any[] = [];
  orgFiltersUsersArrForCreator: unknown[];
  orgFiltersUsersArrForAssignedBy: unknown[];
  @Input() triggerItemcustomFields: any;
  @Input() dashboardGraphCall
  concatIndexForColumns: any;
  customFieldsForTargetItem: any[];
  customFieldsForTriggerItem: any;
  @Input() isTriggeredNonItemField:boolean=false
  connectedBoardData:any={}
  dashBoardLeadList:any[]=[]
  numberOptions:any[]
  currencyOptions:any[]
  ratingOptions:any[]
  dateOptions:any[]
  tagsCorrespondingToDashFilter: any[];
  storedCursorPosition: Range;
  @Input() filterColumnJson: any;
  @Input() columnIndex: number;
  countryListOption:any;
  itemTerminology:any={}
  // advancedFilterEnable:boolean=false
  advancedFilterArr=[]
  advancedFilterData:any={}
  advancedFilterhasError:boolean=true;
  @Input() triggerType:any;
  @Input() catchHookReqJson:any;
  @Input() appSampleReqJson: any;
  catchHookjsonMap: { display_key: string; value: any ; type :any ; key :any,mappingKey:any,name:any, _id:any }[] = [];
  dashboardUserRole:any;
  mobileOptions: any[]=[];
  popoverRef:any
  @ViewChild('container', { static: false }) container!: ElementRef;
  @Input() onlyAdvanceFilter:boolean=false;

  commonIcons = commonIcons
  attributesIcons = AttributesIcons

  constructor(
    private dashboardUtils: DashboardUtilsService,
    public commonUtils: CommonUtils,
    private customStorageService: CustomStorageService,
    public constantService: ConstantService,
    public automationUtils : AutomationUtilsService,
    private httpTransferService:HttpTransferService,
    private keyboardMonitor:KeyboardMonitorService
  ) {
    this.priorityArray = []
    let priorityArray = this.constantService.getPriorityArray();
    this.priorityArray = JSON.parse(JSON.stringify(priorityArray))   
  }

  ngOnDestroy() {
    this.dashboardJsonSub?.unsubscribe();
    this.keyboardMonitor.removeKeyboardMonitoring(this.container)
  }

  ngAfterViewInit(){
    this.keyboardMonitor.addKeyboardMonitoring(this.container)
  }

  async ngOnInit() {  
    this.getInstance.emit(this)
    if(this.originalautomationCall && !this.localFilterDataObj.advanced_enable || this.onlyAdvanceFilter){
      this.localFilterDataObj.advanced_enable=true
    }  
    if(!this.multipleBoardIds)this.multipleBoardIds=this.connectedDashboardArr?.length>0 && this.enableMultipleBoardFilter ? this.connectedDashboardArr.map(board=>board?.dashboard_id) : [this.selectedDashId];
    this.multipleBoardIds=this.multipleBoardIds?.filter(id=>id);
    if(this.multipleBoardIds.length)await this.dashboardUtils.getAndSyncAllDashboardData(this.multipleBoardIds,false)
    console.log('filteredAndCreate',this.filteredAndCreate)
    console.log("localfilter",this.localFilterDataObj)
    this.originalautomationCall=this.automationCall
  
    console.log("2222@@@@",this.triggerType,this.isTriggeredNonItemField)
    console.log(this.filterColumnJson,this.columnIndex)
    if(!this.appTemplateCall && this.multipleBoardIds?.length){
      this.itemTerminology=this.dashboardUtils.getDashboardTerminoloy(this.selectedDashId)
      this.getForms()
    }
    if(this.isTriggeredNonItemField && this.triggerType!='CATCHHOOK_RESTHOOK'){
      this.automationCall=false
    }
    if(this.localFilterDataObj?.lead_id?.length){
      let inputJson={
        "dashboard_id" : [this.selectedDashId],
        "lead_id" : []
      }
      this.localFilterDataObj?.lead_id.forEach(m=>{
        if(!m.includes("{{")){
          inputJson["lead_id"].push(m)
        }
      })
      if(inputJson["lead_id"]?.length){
        this.httpTransferService.getLeadQuery(inputJson).subscribe((res : any) =>{
          this.dashBoardLeadList = res.result.leadResponse
        })
      }
        if(this.automationCall){
          let boardType = this.triggerItemcustomFields.filter(i=>i.type == 'Board' || i.type == 'MultipleBoard')
          boardType.forEach(p=>{
            console.log(p)
            if(p.connected_board_id == this.selectedDashId)
            {
              this.dashBoardLeadList.unshift({ title: 'Triggered Item '+p.display_key, _id:`{{triggeredItem.custom_fields.${p.key}}}` , email: '' })
            }
          })
          if(this.selectedDashId == this.currentDashId){
            this.dashBoardLeadList.unshift({ title: 'Triggered Item (Current Item)', _id:`{{triggeredItem._id}}` , email: '' })
          }
        }
    }

    if(!this.localFilterDataObj['custom_column'] || Object.keys(this.localFilterDataObj['custom_column']).length==0) await this.addCustomColumns();
    if(!this.appTemplateCall){
      await this.getConnectedBoardsIds();
    }
    this.dateOptions=[{name: "Custom Date", _id: 'customDate'}  ]
    this.numberOptions=[{name: "Custom Input", _id: 'customNumber'} ]
    this.ratingOptions=[{name: "Custom Input", _id: 'customRating'} ]
    this.currencyOptions=[{name: "Custom Input", _id: 'customAmount'} ]
    this.mobileOptions=[{name: "Custom Input", _id: 'customMobile'} ]
    let dateOptionJsonArr=Object.values(this.automationUtils.getListForActionOnDateType('Date',this.arrayOfCustomFiled,this.isTriggeredNonItemField,true))
    dateOptionJsonArr=dateOptionJsonArr.filter(e=>e.isCalculation==false && e.key!="manual")
    if(!this.appTemplateCall){
      this.dashboardJsonSub = this.dashboardUtils.getUpdatedDashboardJson().subscribe((res : any) => {
        res=JSON.parse(JSON.stringify(res))
        this.dashboardUserRole = res[this.selectedDashId]?.DASHBOARD_INFO?.role || [];
        this.tagsCorrespondingToDash = this.commonUtils.getMultiBoardDataList(res,this.multipleBoardIds,'TAG','_id')
        this.bucketArray = this.commonUtils.getMultiBoardDataList(res,this.multipleBoardIds,'BUCKET','_id')
        let sprints=this.commonUtils.getMultiBoardDataList(res,this.multipleBoardIds,'SPRINT','_id')
        this.setSprintData(sprints);
        this.getFilterDropdownBucketsList(this.bucketArray);
        this.setFilterVariables()
        if(this.automationCall && !this.isTriggeredNonItemField){
          if(this.curentDashId===this.selectedDashId){
            let indexForTag = this.tagsCorrespondingToDash.findIndex(data=>data.name == "Triggered Item Tags")
            if(indexForTag==-1){
              this.tagsCorrespondingToDash.unshift({name:"Triggered Item Tags",tag_id:"{{triggeredItem.tag_id}}"})
            }
          }
          this.priorityArray=[]
          let priorityArray = this.constantService.getPriorityArray();
          if(this.filteredAndCreate){
            priorityArray = [...[{id: -2,value: "Filtered Item Priority",checked: false,image: "./../assets/img/icons/urgent.png",color: '#FF544C'}],...priorityArray]
          }
          this.priorityArray = [...[{id: -1,value: "Triggered Item Priority",checked: false,image: "./../assets/img/icons/urgent.png",color: '#FF544C'}],...priorityArray]
          if(this.localFilterDataObj['priority']?.length){
            let index=this.localFilterDataObj['priority'].indexOf('{{triggeredItem.priority}}')
            if(index>-1){
              this.localFilterDataObj['priority'][index]=-1
            }
            let targetindex=this.localFilterDataObj['priority'].indexOf('{{targetItem.priority}}')
            if(targetindex>-1){
              this.localFilterDataObj['priority'][targetindex]=-2
            }
          }
         
        }
        if(this.selectedDashId!=null){
          this.commonUtils.isDashboardReadOnly(this.selectedDashId).then((res : any) => { 
            this.isReadOnly = res;
          });
          this.commonUtils.isDashboardTemplate(this.selectedDashId).then((res : any) => { 
              this.openInTemplateOrInternalBoard=res
          });
        }
        let mirrorBoardColumn=Object.keys(this.localFilterDataObj['custom_column'])
        let mirrorboardList=this.arrayOfCustomFiled?.filter(e=>e.type=='Board' || e.type=='MultipleBoard')
        console.log(mirrorBoardColumn)
        console.log(mirrorboardList)
        if(mirrorboardList.length){
          for(let j=0;j<mirrorboardList.length;j++){
            if(mirrorBoardColumn?.includes(mirrorboardList[j].key)){
              this.callListOfData(mirrorboardList[j].connected_board_id)
            }
          }
      }
      });
    }
    
    this.addCustomFieldSortingParams();
    this.checkForNullValues();
    this.loggedInUserId = this.customStorageService.getItem('id')
    // this.arrayOfCustomFiled = this.arrayOfCustomFiled.filter(field => !field?.hidden)
    this.filterCopyOfCustomFields = [...this.arrayOfCustomFiled];
    this.getFilterDropdownBucketsList(this.bucketArray);
    this.orgUsersJson = await this.dashboardUtils.getOrgUsers();
    this.orgFiltersUsersArr = Object.values(this.orgUsersJson);
    this.orgFiltersSingelUsersArr = Object.values(this.orgUsersJson);
    this.orgFiltersMultiUsersArr = Object.values(this.orgUsersJson);
    this.orgFiltersUsersArrForAssignedBy = Object.values(this.orgUsersJson);
    this.orgFiltersUsersArrForCreator = Object.values(this.orgUsersJson);
    this.orgFiltersUsersArrWithoutBotUser = this.orgFiltersUsersArr.filter(user => !user.is_bot);
    this.orgFiltersUsersForAssigneeArr = [{ name: 'Without Assignee', _id: 'isNull', email: '' },...this.orgFiltersUsersArrWithoutBotUser];
    this.orgFiltersUsersForCollArr = [{ name: 'Without Collaborator', _id: 'isNull', email: '' },...this.orgFiltersUsersArrWithoutBotUser];
    this.tagsCorrespondingToDashFilter = [{name: 'Without Tags', tag_id: 'isNull'},...this.tagsCorrespondingToDash];
    if(!this.appTemplateCall){
      let existingCustomFields = await this.dashboardUtils.getDashboardExistingCustomFormIfPresent(this.selectedDashId);
      if (existingCustomFields?.length) this.itemAttributesObj = this.commonUtils.handleExistingItemAttributes(existingCustomFields)
    }
    if(this.automationCall && !this.isTriggeredNonItemField){
      this.filterUserTypeForAutomation()
      let singleUserVal=[{ name: 'Triggered Item Assignee', _id:'{{triggeredItem.assigned_to}}' , email: '' },
                        {name:"Triggered Item Assigned By",_id:'{{triggeredItem.assigned_by_user}}' , email: ''},
                        {name:"Triggered Item Created By",_id:'{{triggeredItem.created_by}}' , email: ''}]

      if(this.filteredAndCreate){
        singleUserVal=[...singleUserVal,{ name: 'Filtered Item Assignee', _id:'{{targetItem.assigned_to}}' , email: '' },
        {name:"Filtered Item Assigned By",_id:'{{targetItem.assigned_by_user}}' , email: ''},
        {name:"Filtered Item Created By",_id:'{{targetItem.created_by}}' , email: ''}]
        this.orgFiltersUsersForCollArr.unshift({ name: 'Filtered Item Collaborator', _id: '{{targetItem.lead_colloborators_users}}', email: '' });    

      }
      let SingleUserArrays=[this.orgFiltersUsersForAssigneeArr,this.orgFiltersUsersArrForAssignedBy,this.orgFiltersUsersArrForCreator,this.orgFiltersSingelUsersArr,this.orgFiltersMultiUsersArr]
      SingleUserArrays.forEach(array => {
        array.unshift(...singleUserVal);
      });
      this.orgFiltersUsersForCollArr.unshift({ name: 'Triggered Item Collaborator', _id: '{{triggeredItem.lead_colloborators_users}}', email: '' });    

      dateOptionJsonArr.forEach(date=>{
        this.dateOptions.push({name: date.display_key, _id: date.uniquekey })
      })
    }

    if(this.originalautomationCall){
      this.addTemplateInputVariables()
    }
    this.countryListOption=this.constantService.constant.CurrencyList
    if(!this.automationCall && !this.isTriggeredNonItemField){
      this.orgFiltersUsersForAssigneeArr.unshift({ name: 'Logged In User', _id:'{{EVAL_LOGGED_IN_USER}}' , email: '' });
      this.orgFiltersUsersArrForAssignedBy.unshift({name:"Logged In User",_id:'{{EVAL_LOGGED_IN_USER}}' , email: ''})
      this.orgFiltersUsersArrForCreator.unshift({name:"Logged In User",_id:'{{EVAL_LOGGED_IN_USER}}' , email: ''})
      this.orgFiltersUsersForCollArr.unshift({ name: 'Logged In User', _id: '{{EVAL_LOGGED_IN_USER}}', email: '' });    
      this.orgFiltersSingelUsersArr.unshift({ name: 'Logged In User', _id:'{{EVAL_LOGGED_IN_USER}}' , email: '' });
      this.orgFiltersMultiUsersArr.unshift({ name: 'Logged In User', _id:'{{EVAL_LOGGED_IN_USER}}' , email: '' });
    }
    if(this.quickFilterName?.length>0)this.showQuickFilterNameField=true
    if(this.triggerType=='CATCHHOOK_RESTHOOK' && this.catchHookReqJson){
      this.catchHookjsonMap = this.automationUtils.getCatchHookReqJson(this.catchHookReqJson?.request_body,this.catchHookjsonMap)
      console.log(this.numberOptions)
      this.numberOptions = [...this.numberOptions,...this.catchHookjsonMap]
      this.ratingOptions = [...this.ratingOptions,...this.catchHookjsonMap]
      this.currencyOptions = [...this.currencyOptions,...this.catchHookjsonMap]
      this.mobileOptions = [...this.mobileOptions,...this.catchHookjsonMap]
    }
    if(('and' in this.localFilterDataObj) || ('or' in this.localFilterDataObj)){
      let operator= this.localFilterDataObj.and ? 'and' : 'or'
      let arr= this.localFilterDataObj?.and ? this.localFilterDataObj?.and : this.localFilterDataObj?.or
      let tempvar= arr.find(element=>element['key']=='parent_id')
      if(!tempvar){
        await this.makeDataForAdvanceFilter(true)
        this.advancedFilterArr = await this.makeUIForAdavnaceFilter(arr,operator)
      }
      else if(tempvar){
        let newJson=arr.find(element=>('and' in element || 'or' in element))
        if(newJson){
          await this.makeDataForAdvanceFilter(true)
          this.advancedFilterArr = await this.makeUIForAdavnaceFilter(newJson?.and ? newJson?.and : newJson?.or,operator)
        }
      }
    }
  if(this.originalautomationCall && !this.localFilterDataObj.advanced_enable || this.onlyAdvanceFilter){
    this.localFilterDataObj.advanced_enable=true
    await this.makeDataForAdvanceFilter(true)
  }
 if(!this.sortByOptionsViewList?.length){
  this.sortByOptionsViewList = this.dashboardUtils.getSortByOptions(this.arrayOfCustomFiled)
 }  
}

  getForms(){
    let inputJson={
      'dashboard_id' : this.multipleBoardIds,
    }
    this.httpTransferService.getAllFormCorrespondingToDashBoard(inputJson).subscribe((res) => {      
        this.dashboardFormsArr=res.result.form_response
     
    })

  }

  async getConnectedBoardsIds() {
      let connectedBoardIds = [];
      for(let i=0; i<this.arrayOfCustomFiled?.length; i++) {
       if(['Mirror'].includes(this.arrayOfCustomFiled[i].type)) {
          if(this.arrayOfCustomFiled[i]?.mirror_dashboard_id) {
            connectedBoardIds.push(this.arrayOfCustomFiled[i]?.mirror_dashboard_id);
          }
        }
      }
      connectedBoardIds = [... new Set(connectedBoardIds)];
      this.connectedDashboardJson = await this.dashboardUtils.getDashboardCustomForms(connectedBoardIds);
  }

  setFilterVariables() {
    if('bucket_type' in this.localFilterDataObj && this.localFilterDataObj?.bucket_type[0] === "NON_FINAL") {
      this.nonFinalBucketsFilter = true;
    } else {
      this.nonFinalBucketsFilter = false;
      delete this.localFilterDataObj['bucket_type']
    }
    if('tag_id' in this.localFilterDataObj && this.localFilterDataObj?.tag_id.length === 1 && this.localFilterDataObj?.tag_id[0] === null) {
      this.withoutTagsFilter = true;
    } else {
      this.withoutTagsFilter = false;
    }
  }

  getLeadsofBoard(){
      this.httpTransferService.getLeadQuery({'dashboard_id':[this.selectedDashId]}).subscribe((res : any) =>{
        this.dashBoardLeadList = res.result.leadResponse
        if(this.automationCall){
          let boardType = this.triggerItemcustomFields.filter(i=>i.type == 'Board' || i.type == 'MultipleBoard')
          boardType.forEach(p=>{
            console.log(p)
            if(p.connected_board_id == this.selectedDashId)
            {
              this.dashBoardLeadList.unshift({ title: 'Triggered Item '+p.display_key, _id:`{{triggeredItem.custom_fields.${p.key}}}` , email: '' })
            }
          })
          if(this.selectedDashId == this.currentDashId){
            this.dashBoardLeadList.unshift({ title: 'Triggered Item (Current Item)', _id:`{{triggeredItem._id}}` , email: '' })
          }
        }
      })
    
  }

  async addCustomColumns() {
    this.localFilterDataObj['custom_column'] = {}
    console.log(this.arrayOfCustomFiled,'this.arrayOfCustomFiled');
    for(let i=0; i<this.arrayOfCustomFiled.length; i++) {
      if(['Checkbox','Dropdown','Radiobutton','MultiSelect'].includes(this.arrayOfCustomFiled[i].type)) {
        this.localFilterDataObj['custom_column'][this.arrayOfCustomFiled[i].key] = [];
      } else if(['Numeric','Date','Rating','Percentage','Currency','Country'].includes(this.arrayOfCustomFiled[i].type)) {
        this.localFilterDataObj['custom_column'][this.arrayOfCustomFiled[i].key] = {from:null,to:null};
      } else if(['Text','Email','Url','Textarea','Location'].includes(this.arrayOfCustomFiled[i].type)) {
        this.localFilterDataObj['custom_column'][this.arrayOfCustomFiled[i].key] = '';
      }
      else if(['Mirror'].includes(this.arrayOfCustomFiled[i].type)) {
        if(['Checkbox','Dropdown','Radiobutton','MultiSelect'].includes(this.arrayOfCustomFiled[i].mirror_column_type)) {
          this.localFilterDataObj['custom_column'][this.arrayOfCustomFiled[i].key] = [];
          // let dashboardJson = await this.dashboardUtils.getDashboardCustomForms(this.arrayOfCustomFiled[i].mirror_dashboard_id);
        } else if(['Numeric','Date','Rating','Percentage','Currency','Country'].includes(this.arrayOfCustomFiled[i].mirror_column_type)) {
          this.localFilterDataObj['custom_column'][this.arrayOfCustomFiled[i].key] = {from:null,to:null};
        } else if(['Text','Email','Url','Textarea','Location'].includes(this.arrayOfCustomFiled[i].mirror_column_type)) {
          this.localFilterDataObj['custom_column'][this.arrayOfCustomFiled[i].key] = '';
        }
      }
    }
  }

  checkForNullValues() {
    if('assigned_to' in this.localFilterDataObj && this.localFilterDataObj?.assigned_to.length) {
      const index = this.localFilterDataObj.assigned_to.indexOf(null);
      if(index > -1) this.localFilterDataObj.assigned_to[index] = 'isNull';
    }
    if('lead_colloborators_users' in this.localFilterDataObj && this.localFilterDataObj?.lead_colloborators_users.length) {
      const index = this.localFilterDataObj.lead_colloborators_users.indexOf(null);
      if(index > -1) this.localFilterDataObj.lead_colloborators_users[index] = 'isNull';
    }
    if('tag_id' in this.localFilterDataObj && this.localFilterDataObj?.tag_id?.length>0) {
      const index  = this.localFilterDataObj?.tag_id.indexOf(null);
      if(index > -1) this.localFilterDataObj.tag_id[index] = 'isNull'
    }
    if('sprint_id' in this.localFilterDataObj && this.localFilterDataObj?.sprint_id?.length>0) {
      const index  = this.localFilterDataObj?.sprint_id.indexOf(null);
      if(index > -1) this.localFilterDataObj.sprint_id[index] = 'isNull'
    }
  }

  filterUserTypeForAutomation(){
    console.log(this.connectedBoardData)
    this.triggerItemcustomFields?.forEach(async (customFields)=>{
      if(customFields.type=='Date' || customFields.mirror_column_type=="Date"){
        customFields?.mirror_column_type=="Date" ? 
          this.dateOptions.push({name: `${customFields.display_key} (First Value)`, _id: `{{triggeredItem.custom_fields.${customFields.key}}}` })
        : this.dateOptions.push({name: customFields.display_key, _id: `{{triggeredItem.custom_fields.${customFields.key}}}` })
      }
      if(['Numeric','Rating'].includes(customFields.type) || ['Numeric','Rating','Radiobutton','Dropdown'].includes(customFields.mirror_column_type) 
       || (['Radiobutton','Dropdown'].includes(customFields?.type) && customFields?.is_numeric)){
        if(['Numeric','Rating'].includes(customFields?.mirror_column_type)){
          this.numberOptions.push({name: `${customFields.display_key} (First Value)`, _id: `{{triggeredItem.custom_fields.${customFields.key}}}` }),
          this.ratingOptions.push({name: `${customFields.display_key} (First Value)`, _id: `{{triggeredItem.custom_fields.${customFields.key}}}` })  
        } 
        else if(['Radiobutton','Dropdown'].includes(customFields?.mirror_column_type)){
          let dashboardJson = await this.dashboardUtils.getDashboardCustomForms(customFields?.mirror_dashboard_id);
          let customforMirror = JSON.parse(JSON.stringify(dashboardJson[customFields?.mirror_dashboard_id]['CUSTOM_FORM']));
          let num = customforMirror.filter(y=>y.key === customFields?.mirror_column_key && y?.is_numeric)
          if(num?.length){
            this.numberOptions.push({name: `${customFields.display_key} (First Value)`, _id: `{{triggeredItem.custom_fields.${customFields.key}}}` }),
            this.ratingOptions.push({name: `${customFields.display_key} (First Value)`, _id: `{{triggeredItem.custom_fields.${customFields.key}}}` })   
          }
        }
        else{
          this.numberOptions.push({name: customFields.display_key, _id: `{{triggeredItem.custom_fields.${customFields.key}}}` })
          this.ratingOptions.push({name: customFields.display_key, _id: `{{triggeredItem.custom_fields.${customFields.key}}}` })
        } 
      }
      if(customFields.type=='Currency' || customFields.mirror_column_type=="Currency"){
        customFields?.mirror_column_type=="Currency" ?
        this.currencyOptions.push({name: `${customFields.display_key} (First Value)`, _id: `{{triggeredItem.custom_fields.${customFields.key}}}` })
       : this.currencyOptions.push({name: customFields.display_key, _id: `{{triggeredItem.custom_fields.${customFields.key}}}` })
      }
     /*  if(customFields?.type=='Rating' || customFields.mirror_column_type=="Rating" ||
      customFields.type=='Numeric' || customFields.mirror_column_type=="Numeric"){
        customFields?.mirror_column_type=="Rating" ?
        this.ratingOptions.push({name: `${customFields.display_key} (First Value)`, _id: `{{triggeredItem.custom_fields.${customFields.key}}}` })
       : this.ratingOptions.push({name: customFields.display_key, _id: `{{triggeredItem.custom_fields.${customFields.key}}}` })
      } */
      if(customFields.type=="SingleUser" || customFields.mirror_column_type=="SingleUser"){
        this.orgFiltersUsersForAssigneeArr.unshift({ name: 'Triggered Item '+customFields.display_key, _id:`{{triggeredItem.custom_fields.${customFields.key}}}` , email: '' });
        this.orgFiltersUsersArrForAssignedBy.unshift({name:"Triggered Item "+customFields.display_key,_id:`{{triggeredItem.custom_fields.${customFields.key}}}` , email: ''})
        this.orgFiltersUsersArrForCreator.unshift({name:"Triggered Item "+customFields.display_key,_id:`{{triggeredItem.custom_fields.${customFields.key}}}` , email: ''})
        this.orgFiltersUsersForCollArr.unshift({ name: 'Triggered Item '+customFields.display_key, _id: `{{triggeredItem.custom_fields.${customFields.key}}}`, email: '' }); 
        this.orgFiltersSingelUsersArr.unshift({ name: 'Triggered Item '+customFields.display_key, _id: `{{triggeredItem.custom_fields.${customFields.key}}}`, email: '' }); 
        this.orgFiltersMultiUsersArr.unshift({ name: 'Triggered Item '+customFields.display_key, _id: `{{triggeredItem.custom_fields.${customFields.key}}}`, email: '' });         
      }
      if(customFields.type=="MultiUser" || customFields.mirror_column_type=="MultiUser"){
        this.orgFiltersUsersForCollArr.unshift({ name: 'Triggered Item '+customFields.display_key, _id: `{{triggeredItem.custom_fields.${customFields.key}}}`, email: '' });  
        this.orgFiltersMultiUsersArr.unshift({ name: 'Triggered Item '+customFields.display_key, _id: `{{triggeredItem.custom_fields.${customFields.key}}}`, email: '' }); 
      }
      if(['Text','Mobile','Numeric'].includes(customFields.type) || ['Text','Mobile','Numeric'].includes(customFields.mirror_column_type)){
        if(['Text','Mobile','Numeric'].includes(customFields.mirror_column_type)) {
          this.mobileOptions.push({name: `${customFields.display_key} (First Value)`, _id: `{{triggeredItem.custom_fields.${customFields.key}}}` })
        }
        else{
          this.mobileOptions.push({name: customFields.display_key, _id: customFields.type=='Mobile' ? `{{triggeredItem.custom_fields.${customFields.key}.mobile_number}}` : `{{triggeredItem.custom_fields.${customFields.key}}}`})
        }
      }
    })


  }

  addTemplateInputVariables(){
    if(this.appTemplateCall && this.templateVariableArr.length>0){
      let columnTypeArr = this.templateVariableArr?.filter(ele => ele.var_type == 'VALUE' )
      let valueTemplateVarArr = this.automationUtils.createCustomFieldArrFromtempateVariables(columnTypeArr)
      valueTemplateVarArr?.forEach(async (varInput)=>{
        if(varInput.type=='Date' || varInput.type=='DateTime'){
          this.dateOptions.push({name: varInput.display_key, _id: varInput.key })
        }
        if(varInput.type=="SingleUser"){
          let obj={name:varInput.display_key,_id:varInput.key, email: ''}
          this.orgFiltersUsersForAssigneeArr.unshift(obj);
          this.orgFiltersUsersArrForAssignedBy.unshift(obj)
          this.orgFiltersUsersArrForCreator.unshift(obj)
          this.orgFiltersUsersForCollArr.unshift(obj); 
          this.orgFiltersSingelUsersArr.unshift(obj); 
          this.orgFiltersMultiUsersArr.unshift(obj);         
        }
        if(['Numeric','Rating'].includes(varInput.type)){
          this.numberOptions.push({name: varInput.display_key, _id: varInput.key })
          this.ratingOptions.push({name: varInput.display_key, _id: varInput.key })
        }
      })
    }

  }

  checkForNullUserIds() {
    if(this.localFilterDataObj['assigned_to'].includes(null)) {
      const index = this.localFilterDataObj.assigned_to.findOf(null);
      this.localFilterDataObj.assigned_to[index] = { name: 'Without Assignee', _id: null, email: '' }
    }
  }

  triggerItemPriority(){
    // if(this.localFilterDataObj['priority']==-1){
    //   this.localFilterDataObj['priority'] = "{{triggeredItem.priority}}"
    // }

    let index=this.localFilterDataObj['priority'].indexOf(-1)
    if(index>-1){
      this.localFilterDataObj['priority'][index]="{{triggeredItem.priority}}"
    }
    let tagregtindex=this.localFilterDataObj['priority'].indexOf(-2)
    if(tagregtindex>-1){
      this.localFilterDataObj['priority'][tagregtindex]="{{targetItem.priority}}"
    }
  }
  scrollToElementById(id) {
    setTimeout(()=>{
      $('.filterMiddleContent')?.focus()
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    },100)
  }

  addCustomFieldSortingParams() {
    this.sortByOptionsViewList=this.sortByOptionsViewList.filter(item=>!item.hidden)
  }

  onIsdeletedFiltersChanges() {
    if (this.localFilterDataObj['is_deleted'].length > 0) {
      this.localFilterDataObj['is_deleted'] = [];
    } else {
      this.localFilterDataObj['is_deleted'] = [true];
    }
  }

  resetDateFilter(type) {
    this.localFilterDataObj[type].from = null;
    this.localFilterDataObj[type].to = null;
  }

  setWithoutTags() {
    if (this.withoutTagsFilter) {
      this.localFilterDataObj['tag_id'] = [null];
    } else {
      this.localFilterDataObj['tag_id'] = [];
    }
    this.withoutTagsFilterEmit.emit(this.withoutTagsFilter)
  }
  setNonFinalBuckets() {
    if (this.nonFinalBucketsFilter) {
      this.localFilterDataObj['bucket_type'] = ["NON_FINAL"];
    } else {
      this.localFilterDataObj.bucket_id = [];
      delete this.localFilterDataObj['bucket_type'];
    }
    this.nonFinalBucketsFilterEmit.emit(this.nonFinalBucketsFilter)
  }

  onOtherFiltersChanges(event, type) {
    if(type === 'mentions' ) {
      if(this.localFilterDataObj[type]['status'].length === 0){
         this.localFilterDataObj[type]['status'] = ["UNREAD","READ"];
      }else{
        this.localFilterDataObj[type]['status']=[];
      }
    } else {
      if(Array.isArray(this.localFilterDataObj[type])) {
        if(this.localFilterDataObj[type].length>0) {
          this.localFilterDataObj[type] = [];
        } else {
          this.localFilterDataObj[type] = [event.target.value];
        }
      } else {
        this.localFilterDataObj[type] = event.target.value;
      }
    }
  }

  setSprintData(sprintData) {
    this.dashBoardSprint = sprintData;
    this.dashBoardSprintForFilter = [...this.dashBoardSprint];
    this.dashBoardSprintForFilter.unshift({ _id: 'isNull', name: 'No phase' });
    if(this.automationCall && !this.isTriggeredNonItemField){
      if(this.curentDashId===this.selectedDashId){
        if(this.filteredAndCreate){
          this.dashBoardSprintForFilter.unshift({ _id: "targetItem.sprint_id", name: 'Filtered Item phase' });
        }
        this.dashBoardSprintForFilter.unshift({ _id: "triggeredItem.sprint_id", name: 'Triggered Item phase' });
      }
    }
    this.originalDashBoardSprintForFilter=JSON.parse(JSON.stringify(this.dashBoardSprintForFilter))
    // this.dashBoardSprintForFilter.unshift({ _id: "{{EVAL_IN_PROGRESS_SPRINT}}", name: 'Inprogress Phase' })    
  }

  onSortingChange(option) {
    if(option.value === 'index') this.localFilterDataObj.sort_params[0].order = 'ASC'
  } 

  getFilterDropdownBucketsList(bucketArray) {
    this.filterDropdownBucketList = [];
    bucketArray?.forEach((element) => {
      if (!element.isDeleted) {
        this.filterDropdownBucketList.push(element);
      //  this.localFilterDataObj.bucket_id = [];
      }
    });
    if(this.automationCall && !this.isTriggeredNonItemField){
      if(this.curentDashId===this.selectedDashId){
        if(this.filteredAndCreate){
          this.filterDropdownBucketList.unshift({name:"Filtered Item Bucket",_id:"{{targetItem.bucket_id}}"})
        }
        this.filterDropdownBucketList.unshift({name:"Triggered Item Bucket",_id:"{{triggeredItem.bucket_id}}"})
      }
    }
  }

  resetFilter(flag?) {
    this.appliedFilterCountEmit.emit(0)
    this.resetFilterEmit.emit(flag)
  }
  async applyFilter() {
    if(this.localFilterDataObj?.advanced_enable){
      if(this.dashboardGraphCall || this.bottomBarFilter){
        this.localFilterDataObj = this.commonUtils.cleanFilterDataObject(this.localFilterDataObj,this.arrayOfCustomFiled);
        delete this.localFilterDataObj.and;
      }
      else if(this.originalautomationCall){
        this.localFilterDataObj = this.commonUtils.resetFilterDataObj(this.localFilterDataObj, this.arrayOfCustomFiled);
        this.localFilterDataObj = this.commonUtils.cleanFilterDataObject(this.localFilterDataObj,this.arrayOfCustomFiled);
      }
      else{
        this.resetFilter(true)
      }
      this.localFilterDataObj.advanced_enable=true
      await this.advancedFilterUpdated()
      if(this.originalautomationCall){
        if(this.showfilterForBoard){
          this.actionobj.connetedBoardlocalFilterDataObj=this.localFilterDataObj
        }
        else{
          this.actionobj.localFilterDataObj=this.localFilterDataObj
        }
      }
    }
    else{// This else will be executed if we are not using Advanced Filter
        //In case of and if there is hierarchy then we don't have to delete hierarchy. But in case of or we will blindly delete or
      let andOperator=this.localFilterDataObj?.and;
      let orOperator=this.localFilterDataObj?.or;
      if(andOperator && andOperator?.length>0 ){
        let hierarchyOperation=andOperator.find(element=>element['key']=='parent_id')
        if(hierarchyOperation){
          this.localFilterDataObj['and'] = [hierarchyOperation]
        }
        else{
          delete this.localFilterDataObj['and']
        }
      }
      if(orOperator && orOperator?.length>0 ){
        delete this.localFilterDataObj['or']
      }
    }
  
    const count =  this.commonUtils.countAppliedFilter(this.localFilterDataObj);
    this.appliedFilterCountEmit.emit(count)
    this.applyFilterEmit.emit()
    let obj = {
      filterColumnJson : this.filterColumnJson,
      columnIndex : this.columnIndex
    }
    this.columnFilterEmit.emit(obj)
  }
  async onSaveFilter() {
    if(this.localFilterDataObj?.advanced_enable){
      this.localFilterDataObj = this.commonUtils.resetFilterDataObj(this.localFilterDataObj, this.arrayOfCustomFiled);
      this.localFilterDataObj = this.commonUtils.cleanFilterDataObject(this.localFilterDataObj,this.arrayOfCustomFiled);
      this.localFilterDataObj.advanced_enable=true
      await this.advancedFilterUpdated()
    }
    else{// This else will be executed if we are not using Advanced Filter
        //In case of and if there is hierarchy then we don't have to delete hierarchy. But in case of or we will blindly delete or
      let andOperator=this.localFilterDataObj?.and;
      let orOperator=this.localFilterDataObj?.or;
      if(andOperator && andOperator?.length>0 ){
        let hierarchyOperation=andOperator.find(element=>element['key']=='parent_id')
        if(hierarchyOperation){
          this.localFilterDataObj['and'] = [hierarchyOperation]
        }
        else{
          delete this.localFilterDataObj['and']
        }
      }
      if(orOperator && orOperator?.length>0 ){
        delete this.localFilterDataObj['or']
      }
    }
    this.saveFilterEmit.emit(this.quickFilterName)
  }
  closeFilter() {
    this.showFilter = false;
    this.showQuickFilterNameField = false;
    this.closeFilterEmit.emit(true);
  }
  columnsCall(customField){
    if(customField?.type!='Text' && customField.type!='Textarea')
   {

    if(customField?.type=='MultiUrl'){
      this.customFieldsForTargetItem = this.arrayOfCustomFiled.filter(e=>e.type == 'Url' || e.type == 'MultiUrl')
      this.customFieldsForTriggerItem = this.triggerItemcustomFields.filter(e=>e.type == 'Url' || e.type == 'MultiUrl') 
    }
    else{
      this.customFieldsForTargetItem = this.arrayOfCustomFiled.filter(e=>e.type == customField?.type)
      this.customFieldsForTriggerItem = this.triggerItemcustomFields.filter(e=>e.type == customField?.type) 
    }
   }
   else{
    this.customFieldsForTargetItem = this.arrayOfCustomFiled
    this.customFieldsForTriggerItem = this.triggerItemcustomFields
   }
  }
  cursorPosition(event) {
    this.concatIndexForColumns = event.target.selectionStart;
  }
  getcustomvalue(value,customField){
   if(customField?.type!='Text' && customField.type!='Textarea'){
    this.localFilterDataObj.custom_column[customField?.key] = `{{${value}}}`
   }
   else{
    let str = this.localFilterDataObj.custom_column[customField?.key] ? this.localFilterDataObj.custom_column[customField?.key] : ""
    this.localFilterDataObj.custom_column[customField?.key] = this.localFilterDataObj.custom_column[customField?.key]?.length && this.concatIndexForColumns ? str.slice(0, this.concatIndexForColumns) + `{{${value}}}` + str.slice(this.concatIndexForColumns) : str +""+`{{${value}}}`
  }

  }

  onFilterDashboardSelect(event) {
    this.connectedDashboardEmit.emit(event)
  }

  isTypeNumber(obj) {
    if (isNumeric(obj)) {
      return true
    }
    return false
  }

  callListOfData(dashId){
    if(!this.connectedBoardData[dashId])
    this.httpTransferService.getLeadQuery({'dashboard_id':[dashId]}).subscribe((res : any) =>{
      this.connectedBoardData[dashId] = res.result.leadResponse
      if(this.automationCall){
        let boardType= this.triggerItemcustomFields.filter(m=>m.type=='Board' || m.type=='MultipleBoard')
        boardType.forEach(p=>{
          console.log(p)
          if(p.connected_board_id == dashId)
          {
            this.connectedBoardData[dashId].unshift({ title: 'Triggered Item '+p.display_key, _id:`{{triggeredItem.custom_fields.${p.key}}}` , email: '' })
          }
        })
        if(dashId == this.currentDashId){
          this.connectedBoardData[dashId].unshift({ title: 'Triggered Item (Current Item)', _id:`{{triggeredItem._id}}` , email: '' })
        }
      }
    })
  }
  getSearchMirrorLead(term: string, item: any){
    term  = term.toLowerCase()
    //while saerching remove #and search next sequence and cannot use incluse for number
    if(term.slice(0,1) == '#' && term.length >1){
      return item.seq_id == term.substring(1, term.length)
    }else{
      return item.title.toLowerCase().includes(term) || item.seq_id == term
    }
  }


  async makeDataForAdvanceFilter(event){
    if(this.localFilterDataObj?.advanced_enable && event){
      let jsonlistForAdvanceFilter
      if(!this.appTemplateCall){
        let dashboardJson = await this.dashboardUtils.getDashboardCustomForms(this.selectedDashId)
        let havePhase=dashboardJson && dashboardJson[this.selectedDashId] && dashboardJson[this.selectedDashId]['SPRINT']?.length ? true : false
        jsonlistForAdvanceFilter= this.commonUtils.getNonCustomFields(havePhase)
        if(this.originalautomationCall){
          let dashInfo=dashboardJson[this.selectedDashId]?.DASHBOARD_INFO || {}
          if(dashInfo?.type=="ACTIVITY_TYPE"){
            if(dashInfo?.board_type=='TASK_ACTIVITY'){
              // jsonlistForAdvanceFilter=jsonlistForAdvanceFilter.filter(ele => ele.key == 'bucket_id')
              let filteredObject = Object.keys(jsonlistForAdvanceFilter)
                .filter(key => key === 'bucket_id')
                .reduce((obj, key) => {
                    obj[key] = jsonlistForAdvanceFilter[key];
                    return obj;
                }, {});
              jsonlistForAdvanceFilter==filteredObject
            }
            else{
              jsonlistForAdvanceFilter={}
            }
          }
        }
        if(this.multipleBoardIds?.length>0)jsonlistForAdvanceFilter['dashboard_id']={display_key:"Board",key:"dashboard_id",type:"MainBoard",referer_type:"MainBoard"}
        jsonlistForAdvanceFilter=await this.commonUtils.makeCustomArrForAdvancedFilter(this.arrayOfCustomFiled,jsonlistForAdvanceFilter)
      }
      else{
        jsonlistForAdvanceFilter= this.commonUtils.getNonCustomFields()
        // if(this.connectedDashboardArr.length>0)jsonlistForAdvanceFilter['dashboard_id']={display_key:"Board",key:"dashboard_id",type:"MainBoard",referer_type:"MainBoard"},
        jsonlistForAdvanceFilter=await this.commonUtils.makeCustomArrForAdvancedFilter(this.arrayOfCustomFiled,jsonlistForAdvanceFilter)
      }
      let booleanTypeJson=this.commonUtils.getBooleanTypeFields()
      let newjsonlistForAdvanceFilter= Object.assign({}, jsonlistForAdvanceFilter, booleanTypeJson);
      let listForAdvanceFilter=Object.values(newjsonlistForAdvanceFilter)  
      let operatorOptionForAdvance = this.commonUtils.getOperatorForFilterField()
      if(!this.advancedFilterArr.length){
        let obj={'key':null,'operator':null,'value':null,'type':'FILTER','filterOperator':'and','optionSelected':null}
        this.advancedFilterArr.push(obj)
      }
      this.advancedFilterData['operatorOptionForAdvance']=operatorOptionForAdvance
      this.advancedFilterData['listForAdvanceFilter']=listForAdvanceFilter
      this.advancedFilterData['jsonlistForAdvanceFilter']=newjsonlistForAdvanceFilter

      this.advancedFilterData['orgFiltersUsersForAssigneeArr']=this.orgFiltersUsersForAssigneeArr
      this.advancedFilterData['orgFiltersUsersArrForAssignedBy']=this.orgFiltersUsersArrForAssignedBy
      this.advancedFilterData['orgFiltersUsersArrForCreator']=this.orgFiltersUsersArrForCreator
      this.advancedFilterData['orgFiltersUsersForCollArr']=this.orgFiltersUsersForCollArr
      this.advancedFilterData['countryListOption']=this.countryListOption
      this.advancedFilterData['tagsCorrespondingToDashFilter']= this.tagsCorrespondingToDashFilter
      this.advancedFilterData['dashBoardSprintForFilter']=this.dashBoardSprintForFilter
      this.advancedFilterData['originalDashBoardSprintForFilter']=this.originalDashBoardSprintForFilter
      this.advancedFilterData['priorityArray']=this.priorityArray
      this.advancedFilterData['bucketArray']=this.bucketArray
      let activityBoardData=this.localFilterDataObj.include_activities ? this.connectedDashboardArr?.reduce((list,data)=>{ return [...list,...(data?.connected_activity_types?.reduce((dataList,board)=>{return this.connectedDashboardJson[board?.board_id]?.DASHBOARD_INFO ? [...dataList,this.connectedDashboardJson[board?.board_id]?.DASHBOARD_INFO] : dataList},[]) || [])]},[]) : []
      this.advancedFilterData['mainBoardList']=[...this.connectedDashboardArr,...activityBoardData]
      this.advancedFilterData['orgFiltersMultiUsersArr']=this.orgFiltersMultiUsersArr
      this.advancedFilterData['orgFiltersUsersArr']=this.orgFiltersUsersArr
      this.advancedFilterData['orgFiltersSingelUsersArr']=this.orgFiltersSingelUsersArr
      this.advancedFilterData['connectedDashboardJson']=this.connectedDashboardJson
      this.advancedFilterData['connectedBoardData']=this.connectedBoardData
      this.advancedFilterData['dashBoardLeadList']=this.dashBoardLeadList 
      this.advancedFilterData['multipleBoardIds']=this.multipleBoardIds
      let currencyArr = await this.dashboardUtils.getCurrencyJson();
      let numberOptions = []
      let currencyOptions = []
      let mobileCode = []
      if(this.automationCall && this.triggerType!='CATCHHOOK_RESTHOOK'){
        this.triggerItemcustomFields.forEach(async(customFields)=>{
          if(customFields.type=='Currency' || customFields.mirror_column_type=="Currency"){
            if(customFields?.mirror_column_type=="Currency") {
              currencyOptions.unshift({name: `${customFields.display_key} (First Value)`, code: `{{triggeredItem.custom_fields.${customFields.key}}}` })
              numberOptions.unshift({name: `${customFields.display_key} (First Value)`, _id: `{{triggeredItem.custom_fields.${customFields.key}.amount}}` })
            }
            else{
              currencyOptions.unshift({name: customFields.display_key, code: `{{triggeredItem.custom_fields.${customFields.key}}}` })
               numberOptions.unshift({name: customFields.display_key, _id: `{{triggeredItem.custom_fields.${customFields.key}.amount}}` })
            }
          }
          else if(customFields.type=='Mobile' || customFields.mirror_column_type=="Mobile"){
            if(customFields?.mirror_column_type=="Mobile") {
              mobileCode.unshift({name: `${customFields.display_key} (First Value)`, code: `{{triggeredItem.custom_fields.${customFields.key}}}`, dial_code:`${customFields.display_key} (First Value)` })
            }
            else{
              mobileCode.unshift({name: customFields.display_key, code: `{{triggeredItem.custom_fields.${customFields.key}.country_code}}` , dial_code:customFields.display_key })
            }
          }
        })
      }
      this.advancedFilterData['currencyArr'] = [...currencyArr,...currencyOptions]
      this.advancedFilterData['numberOption'] = [...this.numberOptions,...numberOptions]
      this.advancedFilterData['mobileNumber'] = this.mobileOptions
      this.advancedFilterData['mobilecode'] = [...mobileCode,...currencyArr]
      if(this.triggerType=='CATCHHOOK_RESTHOOK' && this.catchHookReqJson){
        this.catchHookjsonMap = this.automationUtils.getCatchHookReqJson(this.catchHookReqJson?.request_body,this.catchHookjsonMap)
        this.advancedFilterData['currencyArr'] = [...this.advancedFilterData['currencyArr'],...this.catchHookjsonMap]
        this.advancedFilterData['mobileNumber'] = [...this.advancedFilterData['mobileNumber'],...this.catchHookjsonMap]
        this.advancedFilterData['mobilecode'] = [...this.advancedFilterData['mobilecode'],...this.catchHookjsonMap]
      }
    else if(!this.localFilterDataObj?.advanced_enable && event){
      this.resetFilter()
    }
  }
}


   async advancedFilterUpdated(){
    const advanceFilterJson =await  this.makeAdvancedFilterJson(this.advancedFilterArr);
    if(this.localFilterDataObj.and){
      let obj={}
      obj[this.advancedFilterArr[0].filterOperator]=advanceFilterJson
      this.localFilterDataObj?.and?.push(obj)
    }
    else{
      this.localFilterDataObj[this.advancedFilterArr[0].filterOperator]=advanceFilterJson
    }
  }


  async makeAdvancedFilterJson(advancedFilterArr){
    let tempArr=[]
     await Promise.all(advancedFilterArr.map(async (e) => {
      if(e.type=='GROUP'){
        const obj={} ;
        const tempGroupArr = await this.makeAdvancedFilterJson(e.filterArr);
        obj[e.filterArr[0].filterOperator]=tempGroupArr
        tempArr.push(obj)
      }
      else if(e.type=='FILTER'){
        const obj={}  
        obj['key']=e?.key
        obj['operator']=e?.operator
        if(e?.operator!='IS_EMPTY' && e?.operator!='IS_NOT_EMPTY' ){
          let matchingobj=this.advancedFilterData.jsonlistForAdvanceFilter[e.key]
          
          if(matchingobj.type=='Date' || matchingobj.type=='DateTime' || matchingobj?.mirror_column_type=="Date" || matchingobj?.mirror_column_type=="DateTime"){
            if(!e.value.type && (typeof e?.value)!='string'){
              obj['value']={
                date:{
                  from:{'eval_json':e.value?.date?.from,type:'EVAL'},
                  to:{'eval_json':e.value?.date?.to,type:'EVAL'}
                },
                type:'EVAL'
              }
            }
            else if(!e.value.type && (typeof e?.value)=='string'){
              obj['value']={
                "value_eval":{
                  "value_from": "EVALUATE",
                  // "operation_type": "COMPUTEFUNCTION",
                  "value_data": e?.value
                }
              }
            }
            else if(e.value.type!='CUSTOM'){
              obj['value']=e?.value
            }
            else if(e.value.type=='CUSTOM'){
             if(e?.operator=='RANGE'){
              let dateObj={
                from : e.value.date && e.value.date.from ? e.value.date.from : null,
                to : e.value.date && e.value.date.to ? e.value.date.to : null
              }
              obj['value']={
                type:e.value.type,
                date:dateObj
              }
             }
             else{
              obj['value']={
                type:e.value.type,
                date:e.value.date,
                time:e.value.time
              }
             }
            }
          }
          else if(matchingobj.type=='Sprint' || matchingobj.type=='Tags' || matchingobj.type=='SingleUser' || matchingobj.type=='MultiUser'){
            if(e?.operator=='EQUALS_TO' || e?.operator=='NOT_EQUALS_TO'){
              obj['value']=e?.value=='isNull' ? null : e?.value
            }
            else if(e?.operator=='IS_ONE_OF' || e?.operator=='IS_NOT_ONE_OF'){
              const index  = e?.value.indexOf('isNull');
              if(index > -1) e.value[index] = null  
              obj['value']= e?.value
          
            }
          }
          else{            
            obj['value']=e?.value
          }
        }
        tempArr.push(obj)
      }
  }))
    return tempArr
  }

  validateAdvancedFilter(){
    if(this.advancedFilterArr.length){
      this.advancedFilterhasError=this.recursiveValidatedAdvancedFilter(this.advancedFilterArr)
      this.validateFilterEmit.emit(this.advancedFilterhasError)
    }
    else{
      this.advancedFilterhasError=true
      this.validateFilterEmit.emit(this.advancedFilterhasError)
    }
  }

  recursiveValidatedAdvancedFilter(filterArr){
    for(let i=0;i<filterArr.length;i++){
      let ele=filterArr[i]
      if(ele?.type=='GROUP'){
        let error=this.recursiveValidatedAdvancedFilter(ele?.filterArr)
        if(error){
          return true
        }
      }
      else if(ele?.type=='FILTER'){
        if(!ele?.key ) {
          return true
        }  
        if(ele?.key){
          let matchingobj=this.advancedFilterData.jsonlistForAdvanceFilter[ele.key]
          if(ele?.value==="") ele.value=null
          if(matchingobj.type!='Date' && matchingobj.type!='DateTime' && matchingobj?.mirror_column_type!="Date" && matchingobj?.mirror_column_type!="DateTime"){
            if (!ele?.operator || ele?.key=='' || ele?.operator=='' || 
            (((ele?.operator=='EQUALS_TO' || ele?.operator=='NOT_EQUALS_TO' || ele?.operator=='IS_ONE_OF' 
            || ele?.operator=='IS_NOT_ONE_OF' ||  ele?.operator=='CONTAINS' || ele?.operator=='DOES_NOT_CONTAIN' 
            || ele?.operator=='IS_GREATER_THAN' || ele?.operator=='IS_LESS_THAN' 
            || ele?.operator=='IS_GREATER_THAN_OR_EQUALS_TO' || ele?.operator=='IS_LESS_THAN_OR_EQUALS_TO') && ((ele?.value==null || ele?.value=="") && ele?.value!=0 ))
            || (ele?.operator=='RANGE' && ( ((!ele?.value.from || ele?.value.from=='') && ele?.value.from!=0) || ((!ele?.value.to || ele?.value.to=='') && ele?.value.to!=0)) )) 
            ){
              return true
            }
          }
          else if(matchingobj.type=='Date' || matchingobj.type=='DateTime' || matchingobj?.mirror_column_type=="Date" || matchingobj?.mirror_column_type=="DateTime"){
            if(ele?.operator!='RANGE' && ele?.operator!='IS_EMPTY' && ele?.operator!='IS_NOT_EMPTY' ){
              if((!ele?.value || !ele?.optionSelected || (ele?.value && ele?.value.type=='CUSTOM' && !ele?.value.date) || ((typeof ele?.value)!='string' && ele?.value.type!='CUSTOM' && !ele?.value?.eval_json) )){
                return true
              }

            }
            else if(ele?.operator=='RANGE'){
              if((!ele?.value || !ele?.optionSelected || 
                (ele?.value && ele?.value.type=='CUSTOM' && (!ele?.value.date || (ele?.value.date && 
                  (((!ele?.value.date.from || ele?.value.date.from=='') && ele?.value.date.from!=0) || ((!ele?.value.date.to || ele?.value.date.to=='') && ele?.value.date.to!=0))))) || (ele?.value && ele?.value.type && ele?.value.type!='CUSTOM' && !ele?.value?.eval_json) )){
                return true
              }
            }
          }

        }
      }
    }
    return false
  }

  async makeUIForAdavnaceFilter(advancedFilterArr,filterOperator){
    let tempArr=[]
    await Promise.all(advancedFilterArr?.map(async (e)=>{
      if((e?.and && e?.and?.length) || (e?.or && e?.or?.length)){
        let arr=e?.and ? e?.and : e?.or
        let obj={}
        let groupfilterOperator= e?.and ? 'and' : 'or'
        let tempFilterArr= await this.makeUIForAdavnaceFilter(arr,groupfilterOperator)
        obj['type'] = 'GROUP'
        obj['filterArr']=tempFilterArr
        obj['filterOperator']=filterOperator
        tempArr.push(obj)
      }
      else{
        console.log('filter')
        let obj={}
        obj['type'] = 'FILTER'
        obj['key']= e?.key
        obj['operator']=e?.operator
        obj['value']=e?.value 
        obj['filterOperator']=filterOperator
        obj['optionSelected']=null
        let field=this.advancedFilterData?.jsonlistForAdvanceFilter[e.key]
        if(['Board','MultipleBoard'].includes(field?.type) && (e.operator=='IS_ONE_OF' || e.operator=='IS_NOT_ONE_OF')){
          if(!this.advancedFilterData?.connectedBoardData[field.connected_board_id]){
            if(e?.value?.length){
              this.httpTransferService.getLeadQuery({'dashboard_id':[field.connected_board_id]}).subscribe(res =>{
                this.advancedFilterData.connectedBoardData[field.connected_board_id] = res.result.leadResponse
              })
            }
          }
        }
        if(field?.type=='lead' && (e.operator=='IS_ONE_OF' || e.operator=='IS_NOT_ONE_OF')){
          if(!this.advancedFilterData?.dashBoardLeadList.length ){
            if(e?.value?.length){
              this.httpTransferService.getLeadQuery({'dashboard_id':[this.selectedDashId]}).subscribe(res =>{
                this.advancedFilterData.dashBoardLeadList  = res.result.leadResponse
              })
            }
          }
        }
        else if((field?.type=='Date' || field?.type=='DateTime' || field?.mirror_column_type=="Date" || field?.mirror_column_type=="DateTime") && !(e.operator=='IS_EMPTY' || e.operator=='IS_NOT_EMPTY')){
          if(e?.value && e?.value.eval_json && e?.value.eval_temp){
            obj['optionSelected']=e?.value.eval_temp
          }
          else{
            obj['optionSelected']= e?.value && e?.value.eval_json ? 'EVAL' : 'CUSTOM'
          }

          if(e.value?.date?.from?.eval_json && e.value?.date?.to?.eval_json){
            obj['optionSelected']= 'EVAL_TEMP' 
          }

          if(obj['optionSelected']!='CUSTOM'){
            if( obj['operator']=='RANGE'){
              if(  obj['optionSelected']== 'EVAL_TEMP' ){
                obj['fiscaldateObj']= {
                  date:{
                    from:e.value?.date?.from?.eval_json,
                    to:e.value?.date?.to?.eval_json
                  }
                }
                let fromRes=this.commonUtils.calculateEvalDate(e.value?.date?.from?.eval_json)
                let fromDate = fromRes.evaluated_date
                let toRes=this.commonUtils.calculateEvalDate(e.value?.date?.to?.eval_json)
                let toDate = toRes.evaluated_date
                obj['eval_date_string']={
                  from:this.commonUtils.getDateText(fromDate),
                  to:this.commonUtils.getDateText(toDate),
                }

              }
              else{
                obj['fiscaldateObj']= e?.value.eval_json
                let res = this.commonUtils.calcFiscalPeriod(e?.value.eval_json)
                let fiscalDate = new Date(res.from)
                // obj['fiscal_date_string']= this.commonUtils.getDateText(fiscalDate)
                if(e?.value.type=='EVAL'){
                  obj['fiscal_date_string']= this.commonUtils.getDateText(fiscalDate)
                }
                else{
                  let fiscalDateTemplates=this.commonUtils.getFiscalDateTemplates()
                  obj['fiscal_date_string']= fiscalDateTemplates[e?.value.eval_temp].display_key
                }
              }

            }
            else{
              obj['evaldateObj']= e?.value.eval_json
              let res = this.commonUtils.calculateEvalDate(e?.value.eval_json)
              let evalDate = res.evaluated_date
              if(e?.value.type=='EVAL'){
                obj['eval_date_string']= this.commonUtils.getDateText(evalDate)
              }
              else{
                let relativeDateTemplates=this.commonUtils.getRelativeDateTemplates()
                obj['eval_date_string']= relativeDateTemplates[e?.value.eval_temp].display_key
              }
            }
          }
          else if(obj['optionSelected']=='CUSTOM'){
            if(e?.operator=='RANGE'){
              let dateObj={
                from : e.value.date && e.value.date.from ? e.value.date.from : null,
                to : e.value.date && e.value.date.to ? e.value.date.to : null
              }
              obj['value']={
                type:e.value.type,
                date:dateObj
              }
             }
             else{
              if(!e?.value?.value_eval){
                obj['value']={
                  type:e.value.type,
                  date:e.value.date,
                  time:e.value.time
                }
              }  
              else if(this.automationCall && e?.value.value_eval && e?.value?.value_eval?.value_from=='EVALUATE'){
                let dateOptionJsonArr=Object.values(this.automationUtils.getListForActionOnDateType('Date',this.arrayOfCustomFiled,this.isTriggeredNonItemField,true))
                let datearr=dateOptionJsonArr.filter(ele=>ele.uniquekey==e?.value?.value_eval?.value_data)
                obj['eval_date_string']= datearr[0]?.display_key
                obj['value']=e?.value?.value_eval?.value_data
                obj['optionSelected']=e?.value?.value_eval?.value_data
              }            
             }
          }

        }
        else if(field?.type=='Sprint' || field?.type=='Tags' || field?.type=='SingleUser' || field?.type=='MultiUser'){
          if(e?.operator=='EQUALS_TO' || e?.operator=='NOT_EQUALS_TO'){
            obj['value']=e?.value==null ? 'isNull' : e?.value
          }
          else if(e?.operator=='IS_ONE_OF' || e?.operator=='IS_NOT_ONE_OF'){
            const index  = e?.value.indexOf(null);
            if(index > -1) e.value[index] = 'isNull'             
          }
        }
        else if(field?.type=='MobileNumber'){
          if(this.advancedFilterData['mobileNumber'].findIndex(m=>m._id == e?.value) == -1){
            obj['custom'] = 'customMobile'
          }
          else{
            obj['custom'] = e?.value
          }
        }
        tempArr.push(obj)
      }
    }))
    return tempArr
}

removeItem(item, clear) {
  // Use the clear function provided by ng-select to remove the item
  clear(item);
}



}
