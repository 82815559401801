import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, KeyValueDiffers, OnInit, QueryList, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { CommonUtils } from 'app/services/CommonUtils.service';
import { DashboardUtilsService } from 'app/services/dashboard-utils.service';
import { HttpTransferService } from 'app/services/httpTransfer.service';
import { PopoverDirective } from 'ngx-bootstrap/popover';

@Component({
  selector: 'mirror-cell',
  changeDetection:ChangeDetectionStrategy.OnPush,
  templateUrl: './mirror-cell.component.html',
  styleUrls: ['./mirror-cell.component.scss']
})
export class MirrorCellComponent implements OnInit  {
  params:any={};
  editable: boolean = false
  itemInfo:any={}
  mirrorColumnsJson:any={}
  mirrorItemIds: any=[];
  expendGrid:boolean=false
  @ViewChild('mirrorPopover') mirrorPopover: PopoverDirective;
  @ViewChild("cellRender",{ read: ViewContainerRef }) cellRender;
  differ:any
  connectedBoardReadOnly: any;

  constructor(private cdr:ChangeDetectorRef,private httpTransfer: HttpTransferService,private differs: KeyValueDiffers, private dashboardUtils: DashboardUtilsService,private commonUtils: CommonUtils){
    this.differ = this.differs.find({}).create();
  }

  async ngOnInit() {
    this.onUpdate()
  }

  ngDoCheck(){
    const changes = this.differ.diff(this.mirrorColumnsJson);
    if (changes) {
      this.onUpdate()
    }
  }

  onUpdate(params?){
    this.itemInfo=this.params?.item || {}
    this.editable=this.params?.editable
    this.mirrorColumnsJson=this.params?.globalParams?.mirrorColumnsJson
    this.mirrorItemIds=this.params?.value?.mirror_item_id || []
    this.cdr.detectChanges()
    if(this.mirrorItemIds?.length>0){
      this.commonUtils.isDashboardReadOnly(this.params.columnParams.customField?.connected_board_id).then(res=>{
      this.connectedBoardReadOnly=res
      setTimeout(() => {this.createDynamicComponent();this.cdr.detectChanges()},10);
    })
    }
    
    
  }
  async createDynamicComponent(){
    this.cellRender?.clear()
    let type=this.params?.columnParams?.customField?.connected_board_column_type
    let field=this.params?.columnParams?.customField?.connected_board_column_key
    let conmectedBoardId=this.params?.columnParams?.customField?.connected_board_id
    let cellRender=this.params?.globalParams?.gridViewRef?.cellComponentsConfig[type]?.cellRenderer
    if(cellRender && this.cellRender){
      let nonCustomKey=['title','start_date','end_date','priority','sprint_id','bucket_id','assigned_to','tag_id','create_date','last_updated','created_by']
      let nonEditableFields=['create_date','last_updated','created_by']
      let dummyColumnParams=JSON.parse(JSON.stringify(this.params?.columnParams)) 
      dummyColumnParams.isCustomField=!nonCustomKey?.includes(field)
      if(dummyColumnParams.isCustomField){
        let dashboadJson=await this.dashboardUtils.getAndSyncAllDashboardData([conmectedBoardId],false,['CUSTOM_FORM'])
        let customData=dashboadJson[conmectedBoardId]?.CUSTOM_FORM?.find(data=>data.key==field)
        if(customData)dummyColumnParams.customField=customData
      }
      let componentRef=this.cellRender.createComponent(cellRender);
      let mirrorData=this.params?.globalParams?.mirrorColumnsJson[this.mirrorItemIds[0]]
      let value= dummyColumnParams.isCustomField ? (mirrorData?.custom_fields ? mirrorData?.custom_fields[field] : null) : mirrorData[field]
      componentRef.instance["params"]={'value' : value ,isMirrorColumn:true,'item':mirrorData,'field':field,'columnData':this.params?.columnParams?.customField, 'editable':(!this.connectedBoardReadOnly && !nonEditableFields?.includes(field)),gridTableRef:this.params.gridTableRef ,globalParams:this.params.globalParams,columnParams:dummyColumnParams}
    }
    this.cdr.detectChanges()
  }

  updateTableItem(type,connectedItemIds,leadData,insertIdIndex?){
    if(!connectedItemIds)connectedItemIds=[]
    if(type=='Remove'){
      let index =connectedItemIds.findIndex(el => el === leadData._id);
      if(index >=0)connectedItemIds.splice(index,1);
    }else if(type=='Update')
      connectedItemIds=leadData
    else if(type=='Add'){
      if(insertIdIndex>-1 && insertIdIndex<=connectedItemIds?.length){
        connectedItemIds.splice(insertIdIndex,0,leadData._id)
      } 
      else if(!connectedItemIds?.includes(leadData._id)){
        connectedItemIds.push(leadData._id);
      }
    }
    if(!connectedItemIds?.length)connectedItemIds=null
    let key=this.params?.columnParams.customField?.connected_board_key
    this.httpTransfer.updateLeadCorrespondingToLeadId({custom_fields:{[key]:connectedItemIds}},this.params?.item?.dashboard_id,this.params?.item?._id).subscribe((res:any)=>{
      if (res?.status === 200) {}
    })
  }
  focusInput(tableViewport){
    setTimeout(() => {tableViewport?.querySelector('#createrow_null_title')?.focus()},10); 
  }

  openItem(item,connectedBoradInfo){
    this.params?.globalParams?.gridViewRef?.openRightItemModal(item,true,connectedBoradInfo)
  }

}

