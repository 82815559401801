// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  //  hostUrl : 'https://staging.pronnel.com/pronnel/', 
 hostUrl : 'https://dev1api.pronnel.com/',
// hostUrl : 'http://10.0.0.72:13012',
  oauthBaseUrl : document.baseURI,
  //  hostUrl:'https://developerapi.pronnel.com/',
   // hostUrl : 'http://10.0.0.88:13013/', 
 //   hostUrl : 'http://10.0.0.78:13013/',
  // hostUrl : 'http://prachim.athmin.com:9595/', 
  chathostUrl: 'https://dev2.athmin.com/chatapplication/',
  leadUrlText: document.baseURI + 'board/',
  webSocketUrl : 'wss://dev.pronnel.com/ws',
  formHostUrl: document.baseURI,
  publicFormsUrl: 'https://forms.pronnel.com/',
  notesWebSocketProviderUrl : 'wss://dev1.pronnel.com/websocketserver',
  publicLeadUrlText: document.baseURI + 'shareditem/',
  fbAppID:"362269573030914",
  instaConfigId:"240614582345155",
  fbConfigId:"3140181312958359",
  whatsappConfigId:"1489114754998004",
  // hostUrl :'http://vinnoba.com:8070/',
  firebase:{
    apiKey: "AIzaSyAfS8XTs7IgM47Ai12tjGykX3p50dnvZZY",
    authDomain: "pronnelprod.firebaseapp.com",

    projectId: "pronnelprod",
    storageBucket: "pronnelprod.appspot.com",
    messagingSenderId: "486990179517",
    appId: "1:486990179517:web:cac0fd8f72f0534caa1bd4",
    measurementId: "G-WRELVZHCXS"
  },
  Google_Recaptcha_SITE_KEYs:{
    V3RECAPTCHA:'6LcuIkgpAAAAAHLcxlz9t0Bw-4Gg7rojbc-cMbzS',
    V2CHECKBOX:'6Lc0IkgpAAAAAAnciaEoi1I_mpkituMZA8lvvkoB',
    V2INVISIBLE:'6LcVVU0pAAAAAIBlPYfd7IpZRAPKubrSlsrt4AJ7'
  }
};
  
