import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit ,ViewChild} from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { CommonUtils } from 'app/services/CommonUtils.service';
import { DashboardUtilsService } from 'app/services/dashboard-utils.service';
import { HttpTransferService } from 'app/services/httpTransfer.service';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'checklist-cell',
  changeDetection:ChangeDetectionStrategy.OnPush,
  templateUrl: './checklist-cell.component.html',
  styleUrls: ['./checklist-cell.component.scss']
})
export class ChecklistCellComponent implements OnInit  {
  @ViewChild('selectBox') selectBox: NgSelectComponent;
  params:any={};
  editable: boolean = false
  itemInfo:any={}
  checkListArray:any={}
  selectedValue: any;


  constructor(private cdr:ChangeDetectorRef,private commonUtils: CommonUtils,private dashboardUtils:DashboardUtilsService,private httpTransfer: HttpTransferService,){
  }

  async ngOnInit() {
    this.onUpdate(this.params)
  }


  onUpdate(params: any={}){
    this.itemInfo=this.params?.item || {}
    this.editable=this.params?.editable
    this.selectedValue  = this.params?.value || []
    if(typeof(this.selectedValue)=='string'){
      this.selectedValue=[this.selectedValue]
    }
    this.checkListArray["checklistInput"]=this.selectedValue
    this.checkListArray["key"]=this.params?.columnData?.columnKey
    this.cdr.detectChanges()
  } 
}
