<div class="cell-box"  [placement]="['bottom','bottom-right','bottom-left','auto']" container="body" #multiUrlPopOver="ngbPopover"  popoverClass="gridtableCell" [ngbPopover]="multiUrlTemplate" [autoClose]="'outside'">
    <div class="cell-box-inner url-cell" >
        <div class="left-box">
          <div class="cell-input-box">
            <span class="text-select" [style.opacity]="urlValues.length ? 1 : 0.5">{{urlValues.length ? urlValues[0] : 'Add Url'}}</span>
          </div>
          <div *ngIf="urlValues.length" class="cell-value-box">{{urlValues[0]}}</div>
        </div>
        <div class="right-box" [class.expanField]="urlValues?.length>1">
            <span class="valueCount d-flex" *ngIf="urlValues?.length>1">{{"+"+(urlValues?.length-1)}}</span>
            <span class="expandIcon" *ngIf="urlValues?.length<=1">
                <svg viewBox="0 0 20 20" fill="blue" width="16" height="16" aria-hidden="true" aria-label="Open" class="icon_component icon_component--no-focus-style"><path d="M12.5148 2.49976C12.1212 2.49976 11.8022 2.82125 11.8022 3.21784 11.8022 3.61443 12.1212 3.93593 12.5148 3.93593H14.6466L10.7369 8.15315C10.4682 8.44295 10.4836 8.89735 10.7711 9.16809 11.0587 9.43883 11.5096 9.42339 11.7783 9.1336L15.5746 5.03862V7.28699C15.5746 7.68358 15.8936 8.00507 16.2872 8.00507 16.6807 8.00507 16.9997 7.68358 16.9997 7.28699V3.23812C17.0052 3.03946 16.9293 2.83946 16.7739 2.69313 16.6366 2.56389 16.4621 2.49986 16.288 2.49976L12.5148 2.49976zM9.26311 11.8464C9.53177 11.5566 9.51644 11.1022 9.22888 10.8314 8.94131 10.5607 8.4904 10.5761 8.22174 10.8659L4.42513 14.9612V12.7124C4.42513 12.3158 4.1061 11.9943 3.71256 11.9943 3.31903 11.9943 3 12.3158 3 12.7124V16.7815C3 16.8649 3.01412 16.945 3.04007 17.0195 3.07689 17.1255 3.13902 17.2244 3.22611 17.3064 3.36743 17.4394 3.54818 17.5034 3.72738 17.4996H7.48496C7.8785 17.4996 8.19752 17.1781 8.19752 16.7815 8.19752 16.3849 7.8785 16.0634 7.48496 16.0634H5.35358L9.26311 11.8464z"></path></svg>
            </span>
        </div> 
    </div>
</div>  

<ng-template #multiUrlTemplate>
    <div class="url-containe-wrapper">
        <div *ngIf="editable && (urlValues.length==0 || params?.columnParams?.customField?.type != 'Url')" class="add-url">
            <div>Enter {{('editcustom.new' | translate)+' '+('itemattributes.Url' | translate)}} </div>
            <div class="url-field-box">
                <div class="url-left-field">
                    <input placeholder="{{'placeholders.enterYourLinkHere' | translate}}" type="text" (blur)="editable && addnewUrl()" (keydown.enter)="editable && addnewUrl()" class="url-input-box" [(ngModel)]="newUrl" [disabled]="urlValues?.length>0 && params?.columnParams?.customField?.type == 'Url'" /> 
                </div>  
            </div>
        </div>
        <div class="url-container">
            <div *ngIf="!urlValues?.length" class="empty-url">No Url Found</div>
            <ng-container *ngFor="let entry of urlValues; let i = index;trackBy:trackByFn">
                <div class="url-field-box">
                    <div class="url-left-field">
                        <input *ngIf="editedUrl.index==i" [id]="'url_#'+i" placeholder="{{'placeholders.enterYourLinkHere' | translate}}" type="text" (blur)="onEditUrl(i)" (keydown.enter)="onEditUrl(i)" [(ngModel)]="editedUrl.value" class="url-input-box" /> 
                        <ng-container *ngIf="editedUrl.index!=i"><a href="{{entry}}" target="_blank">{{entry}}</a></ng-container>
                    </div>
                    <div *ngIf="editable"  class="url-right-field"[style.display] ="editedUrl?.index==i ? 'none' : ''">
                        <span class="edit-box" (click)="editedUrl.index=i;editedUrl.value=entry;focusInput('url_#'+i)"><i class="fa fa-pencil"></i></span>
                        <span class="edit-box" (click)="onRemoveUrl(i)"><i class="fa fa-times"></i></span>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-template>