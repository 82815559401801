<!-- dashboard cardView -->
    <div class="elements h-100" *ngIf="showLoader" [ngClass]="{'collapseHeadSet' : isDashCollapse}">
        <app-shimmer-loader *ngIf="viewType=='BoardView' || viewType=='GraphView'" [type]="'Kanban'" [count]="10" [columnCount]="6"></app-shimmer-loader>
        <app-shimmer-loader *ngIf="viewType=='CalenderView' || viewType=='SplitView' || viewType=='bottomBarView'" class="w-100" [count]="8" [height]="100" [type]="'CardField'" [rowGap]="0" [header]="false"></app-shimmer-loader>
    </div>
    <ng-container *ngIf="(funnelarr.length>0 && arrayOfGroupField.length>0  || viewType!=='BoardView')&& !showLoader">
        <div cdkDropListGroup #container2 class="elements board-card-view" cdkScrollable [class.split-board-view]="viewType=='SplitView' || viewType=='CalenderView' || viewType=='bottomBarView'"  >
            <ng-container *ngFor="let groupInfo of funnelarr;let i=index">
            <div *ngIf="groupInfo?.isDefaultGroup || groupInfo?.isIncludeInFilter || groupInfo?.leadContents?.length>0 || (multipleBoardIds.length==1 && selectedGroupBy?.type!='Country' && selectedGroupBy?.type!='User' || selectedGroupBy?.group_by=='priority')" class="col-12 col-md-6 col-lg-4 col-xl-3  beforesearch" [attr.id]="'group_'+groupInfo?.group_id" [class.notAllowToDrop]="isDragging && getdragLeadinfoForCalendar && groupInfo?.group_data?.boardIds && !groupInfo?.group_data?.boardIds?.includes(getdragLeadinfoForCalendar?.dashboard_id)">
                <div [attr.id]="'dropPera'+groupInfo?.group_id" class="dropPera d-none">
                    <h4>{{'board.dropHereToMoveThiscolumn' | translate}} </h4>
                    <h6>{{'board.thisBoardSortedBy' | translate}} {{sortBy}}</h6>
                </div>
                <div [attr.id]="'groupDropPera'+ groupInfo?.group_id" class="dropPera d-none">
                    <h4>{{'board.notAllowedToMoveInBucket' | translate}}</h4>
                </div>
                <div class="card" [ngStyle]="{
                    'border-color': groupInfo.is_destination ? '#41A668' : '',
                    'border-color': (groupInfo?.group_data?.color && selectedGroupBy?.group_by === 'bucket') ? groupInfo.group_data.color : ''}">
                    <!------Bucket haeader-->
                    <div *ngIf="viewType=='BoardView' || viewType=='GraphView'" class="cardhead f-xl-m position-relative bucketHead w-100"
                        [draggable]="selectedGroupBy?.group_by=='bucket'" (dragover)="allowDrop($event)" (dragstart)="dragbuck(i)"
                        [cdkDragDisabled]="selectedGroupBy?.group_by!='bucket' || viewType=='SplitView'"
                        (drop)="onDropbuck(i)">
                        <div *ngIf="viewType=='BoardView' && !isReadOnly" class="form-group multiSelectGroupBox"  [ngClass]="{'multiFlex' : multiSelectGroupArray.includes(groupInfo?.group_id)}" (click)="$event.stopPropagation()">
                            <input type="checkbox" [checked]="multiSelectGroupArray.includes(groupInfo?.group_id)" (click)="selectAllLeadsOfBoard(groupInfo)" [id]="'group_checkbox_'+groupInfo?.group_id">
                                <label for="{{'group_checkbox_'+groupInfo.group_id}}"> </label>
                        </div>
                        <div class="d-flex flex-row w-75 align-items-center" [ngClass]="{'w-75':selectedGroupBy?.group_by!='phase','width65':selectedGroupBy?.group_by=='phase'}">
                            <img *ngIf="groupInfo.is_destination" class="mr-1" src="../../assets/1x/check-icon-41A668.svg" alt="">
                            <span [popoverClass]="selectedGroupBy?.group_by=='user' ? 'userInfoCardPopover' : ''" triggers="hover" placement="auto" container="body" 
                            [ngbPopover]="(selectedGroupBy?.group_by=='phase' || selectedGroupBy?.group_by=='user' || groupInfo?.name?.length>22) && groupInfo?.group_id && groupInfoPopover" class="groupName">
                                {{groupInfo.name}}
                            </span>
                            <span [ngClass]="{'finalBucketLeads' : groupInfo.is_destination}" class="badge f-l-b totalBucketLeads">{{groupInfo.total_records}}</span>
                            <!-- popover start -->
                            <ng-template #groupInfoPopover>
                                <ng-container  *ngIf="orgUsersJson[groupInfo?.group_id]; else phaseInfo">
                                        <app-user-info-card [user]="orgUsersJson[groupInfo?.group_id]"></app-user-info-card>
                                </ng-container>
                                <ng-template #phaseInfo>    
                                    <ng-container *ngIf="selectedGroupBy?.group_by=='phase'; else fullNameInfo">
                                        <app-dashboard-sprint [dashBoardSprint]="[groupInfo?.group_data]" [phasePopover]="true"></app-dashboard-sprint>
                                    </ng-container>
                                    <ng-template #fullNameInfo>
                                        <div style="max-width:340px">{{groupInfo?.name}}</div>
                                    </ng-template>
                                </ng-template>    
                            </ng-template>
                            <!-- popover end -->
                        </div>
                        <div  class="d-inline-flex text-right align-items-center">
                            <span *ngIf="selectedGroupBy?.group_by=='phase' && groupInfo?.group_data?._id" aria-hidden="true" placement="auto" #popPhaseOptions="ngbPopover"
                            [ngbPopover]="editPhaseTemplate" container="body" [autoClose]="'outside'" (click)="selectedPhase(groupInfo?.group_data)">
                                <span *ngIf="groupInfo?.group_data?.current_status=='STARTED'" class="phasechip inprogressChip"
                                ngbTooltip="In Progress" tooltipClass="tooltipPopup" triggers="hover" placement="auto">
                                    <app-dashboard-icon name="InprogressPhase" [size]="20" [iconJson]="attributesIcons"></app-dashboard-icon>
                                </span>
                                <span *ngIf="groupInfo?.group_data?.current_status=='STOPPED'" class="phasechip completedChip"
                                ngbTooltip="Completed" tooltipClass="tooltipPopup" triggers="hover" placement="auto">
                                    <app-dashboard-icon name="CompletedPhase" [size]="20" [iconJson]="attributesIcons"></app-dashboard-icon>
                                </span>
                                <span *ngIf="groupInfo?.group_data?.current_status!='STARTED' && groupInfo?.group_data?.current_status!='STOPPED'" class="phasechip startPhaseChip"
                                ngbTooltip="Start" tooltipClass="tooltipPopup" triggers="hover" placement="auto">
                                    <app-dashboard-icon name="StartPhase" [size]="20" [iconJson]="attributesIcons"></app-dashboard-icon>
                                </span>

                                <ng-template #editPhaseTemplate>
                                    <div class="editPhasePop">
                                      <div class="editPhaseHeader">
                                        Phase Detail
                                      </div>
                                      <div class="phaseEditArea">
                                        <div class="editContent">
                                          <span>Name</span>
                                          <span>          
                                            <input type="text" class="editInputBox" [(ngModel)]="phaseName" #editphaseName  placeholder="{{'phase.enterPhaseName' | translate}}" (ngModelChange)="checkPhaseDetailsValid()" [disabled]="!checkUserHaveEditAccessInDashboard() || (groupInfo?.group_data?.current_status=='STARTED' || groupInfo?.group_data?.current_status=='STOPPED')">
                                          </span>
                                          <span class="text-danger f-xs-l" *ngIf="editphaseName.value.trim().length > 100">
                                            *{{'navSidebar.phaseNameMaxChar' | translate}}
                                          </span>
                                          <span class="text-danger f-xs-l" *ngIf="editphaseName.value.trim().length < 3">
                                            *{{'navSidebar.phaseNameMinChar' | translate}}                
                                          </span>
                                        </div>
                                        <div class="editContent">
                                          <span>Start date</span>
                                          <span> 
                                            <span class="editDateBox" [ngbPopover]="checkUserHaveEditAccessInDashboard() && (groupInfo?.group_data?.current_status!='STARTED' && groupInfo?.group_data?.current_status!='STOPPED') && customDatePopoverFrom" #startDatePopup="ngbPopover" popoverClass="datePickerPopover" placement="auto" [autoClose]="'outside'" (click)="$event.stopPropagation();currentDateKey='from'">
                                              <span *ngIf="phaseStartDate">{{{date : phaseStartDate} | dateCalcuate:"dateTime"}}</span> 
                                              <span *ngIf="!phaseStartDate" class="datePlaceHolderText">DD/MM/YYYY</span>
                                            </span>         
                                          </span>
                                          <ng-template #customDatePopoverFrom>
                                            <date-picker [dateRange]="{'from':{date:phaseStartDate}}" [dateConfig]="{'maxDate':{date:phaseEndDate}}" [focusDate]="{date:phaseStartDate}" [showHeader]="false" [showClearOrSaveBtn]="false" (onDateSelect)="phaseStartDate = $event?.date;checkPhaseDetailsValid();startDatePopup?.close()"></date-picker>
                                        </ng-template>
                                        </div>
                                        <div class="editContent">
                                          <span>End date</span>
                                          <span>   
                                            <span class="editDateBox" [ngbPopover]="checkUserHaveEditAccessInDashboard() && (groupInfo?.group_data?.current_status!='STARTED' && groupInfo?.group_data?.current_status!='STOPPED') && customDatePopoverTo" placement="auto" popoverClass="datePickerPopover" [autoClose]="'outside'" #endDatePopup="ngbPopover" (click)="$event.stopPropagation();currentDateKey='to'">
                                              <span *ngIf="phaseEndDate">{{{date:phaseEndDate} | dateCalcuate:"dateTime"}}</span> 
                                              <span *ngIf="!phaseEndDate" class="datePlaceHolderText">DD/MM/YYYY</span>
                                            </span>         
                                          </span>
                                          <ng-template #customDatePopoverTo>
                                            <date-picker [dateRange]="{'to':{date:phaseEndDate}}" [dateConfig]="{'minDate':{date:phaseStartDate}}" [focusDate]="{date:phaseEndDate}" [showHeader]="false" [showClearOrSaveBtn]="false" (onDateSelect)="phaseEndDate = $event?.date;checkPhaseDetailsValid();endDatePopup?.close()"></date-picker>
                                        </ng-template>
                                        </div>
                                        <span class="text-danger f-xs-l" *ngIf="phaseStartDate && phaseEndDate && (phaseEndDate < phaseStartDate)">
                                          *{{'navSidebar.endDategreater' | translate}}
                                        </span>
                                        
                                        <div *ngIf="phaseData.current_status=='STOPPED' || phaseData.current_status=='STARTED'" class="actualDateArea">
                                          <span>Actual Dates</span>
                                          <span class="actualdatesVal">
                                            <span style="width: 50%;">Start: {{phaseData?.actual_start_date | date:'dd/MM/yyyy'}}</span>
                                            <span style="width: 50%;">End: {{phaseData?.actual_end_date | date:'dd/MM/yyyy'}}</span>
                                          </span>
                                        </div>
                                        <div class="editphaseBottom">
                                          <span class="btncss updateBtn" *ngIf="checkUserHaveEditAccessInDashboard() && isInValidDetails && groupInfo?.group_data?.current_status!='STARTED' && groupInfo?.group_data?.current_status!='STOPPED'" (click)="startPhase();popPhaseOptions.close()">Start Phase</span>
                                          <span class="btncss updateBtnDisabled" *ngIf="checkUserHaveEditAccessInDashboard() && !isInValidDetails && groupInfo?.group_data?.current_status!='STARTED' && groupInfo?.group_data?.current_status!='STOPPED'">Start Phase</span>

                                          <span class="btncss updateBtn" *ngIf="checkUserHaveEditAccessInDashboard() && groupInfo?.group_data?.current_status=='STARTED'" (click)="openTransferItems(transferModal,phaseData);popPhaseOptions.close()">Complete</span>
                                          <span class="btncss updateBtn" *ngIf="!checkUserHaveEditAccessInDashboard() || groupInfo?.group_data?.current_status=='STOPPED'" (click)="popPhaseOptions.close()">Close</span>

                                        </div>
                            
                            
                                      </div>
                                    </div>
                                  </ng-template>


                                  <ng-template #transferModal let-modal>
                                    <div class="modal-md">
                                      <div class="modalheader">
                                        <p class="transferHead text-truncate">
                                           {{sprintName}}
                                        </p>
                                      </div>
                                     <app-sprint-transfer [dashId]="selectedDashId" [sprintId]="transferFromPhase" 
                                     [completedItemsCount]="completedItemsCount" [incompleteItemsCount]="incompleteItemsCount" 
                                     [transferPhaseList]="transferPhaseList" (closeModal)="modal.dismiss()"></app-sprint-transfer>
                                    </div>
                                  </ng-template>

                            </span>

                            <!---Add bucket Button-->
                            <ng-container *ngIf="!isReadOnly && (createLeadBucketIds?.includes(groupInfo?.group_id) || selectedGroupBy?.group_by!='bucket')">
                                <ng-container *ngIf="selectedGroupBy?.group_by!=='dashboard_id' && (multipleBoardIds.length>1 || multipleBoardIds?.length==0 && viewType?.includes('GraphView'));else other">
                                    <span  class="addbtn f-m-b p-0 text-center d-flex cursorPointer d-flex" style="column-gap: 2px;padding-right: 2px;" [ngClass]="{'finalBucketAddButton' : groupInfo.is_destination}">
                                        <span style="column-gap: 2px" class="d-flex" (click)="openAddLead(groupInfo); container.scrollTop = 0"  [ngbTooltip]="('helpingText.addItem' | translate)" placement="auto" triggers="hover" tooltipClass="tooltipPopup">
                                            <app-dashboard-icon class="iconBox-16" [size]="16" [name]="dashboardData[selectedDashId]?.DASHBOARD_INFO?.icon" [iconJson]="attributesIcons"></app-dashboard-icon>
                                            <span>{{'bucket.add' | translate}}</span>
                                        </span>
                                        <span style="padding: 2px;border-left: 1px solid #3571de80;cursor: pointer;" [ngClass]="{'finalBucketAddButton' : groupInfo.is_destination}" #boardSelectPopoverRef="ngbPopover" container="body" [autoClose]="'outside'" [ngbPopover]="boardIconsSelect" [placement]="['bottom-right','bottom','top-right','auto']" popoverClass="modifiedPopover">
                                            <i class="fa fa-chevron-down" aria-hidden="true"></i> 
                                        </span>
                                        <ng-template #boardIconsSelect>
                                            <div style="max-height: 160px;overflow: auto;width: 130px;" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="getDasahboarData()" [scrollWindow]="false">
                                              <ng-container *ngFor="let id of (multipleBoardIds.length ? multipleBoardIds : boardDataListInfo.dashboardList)">
                                                <span *ngIf="dashboardData[id]?.DASHBOARD_INFO" class="board-icon-btn" [class.disabled-board-icon-btn]="!dashboardData[id]?.DASHBOARD_INFO?.role || dashboardData[id]?.DASHBOARD_INFO?.role?.includes('READ_ONLY')" (click)="selectedDashId=id;boardSelectPopoverRef?.close();clickQuickLeadGrouptId=''">
                                                  <span><app-dashboard-icon [size]="15" [name]="dashboardData[id]?.DASHBOARD_INFO?.icon" [iconJson]="attributesIcons"></app-dashboard-icon></span>
                                                  <span style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{dashboardData[id]?.DASHBOARD_INFO?.dashboard_name}}</span>
                                                </span>
                                              </ng-container>
                                              <div *ngIf="boardDataListInfo?.ScrollLoaderr" class="allChat-wrapper-footer d-flex justify-content-center align-items-center p-2">
                                                <div *ngFor="let item of [].constructor(3)" class="spinner-grow text-dark" role="status" style="width: 10px;height:10px;">
                                                  <span class="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                            </div>
                                          </ng-template>
                                    </span>
                                </ng-container>
                                <ng-template #other>
                                    <span  class="addbtn f-m-b cursorPointer iconBox-30" role="button" (click)="openAddLead(groupInfo); container.scrollTop = 0" [ngClass]="{'finalBucketAddButton' : groupInfo.is_destination}"
                                    [ngbTooltip]="('helpingText.addItem' | translate)" placement="auto" triggers="hover" tooltipClass="tooltipPopup">
                                        <!-- <img *ngIf="!groupInfo.is_destination" src="../../assets/1x/addButton-3571de.svg" class="mx-0"> 
                                        <img *ngIf="groupInfo.is_destination" src="../../assets/1x/addButton-41A668.svg" class="mx-0">  -->
                                        <!-- <span>{{'bucket.add' | translate}}</span> -->
                                        <app-dashboard-icon class="appInnerIconBox" [name]="'addOutline'"  [size]="20" [iconJson]="commonIcons"></app-dashboard-icon>
                                    </span>
                                </ng-template>
                            </ng-container>
                            <spam  *ngIf="selectedGroupBy?.group_by=='bucket'" class="iconBox-24 dotsVerticalOption" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" role="button" aria-expanded="false">
                                <app-dashboard-icon class="appInnerIconBox" [name]="'dotsVertical'"  [size]="20" [iconJson]="commonIcons"></app-dashboard-icon>
                            </spam>
                            <div  class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                <div class="dropdown-item f-m-r" *ngIf="!groupInfo?.bucketFollowed"
                                    (click)="followBucket(groupInfo,true)">{{'bucket.follow' | translate}}  
                                </div>
                                <div class="dropdown-item f-m-r" *ngIf="groupInfo?.bucketFollowed"
                                    (click)="followBucket(groupInfo,false)">{{'bucket.unfollow' | translate}}  
                                </div>
                            </div>
                        </div>
                    </div>  
                    <!------Bucket Items list body-->
                    <div class="funnel_Lead_Container" [ngClass]="{'shorted':bulkSelectionMode && bulkLeadInfo.length>0,'collapseHead' : isDashCollapse}" infiniteScroll [infiniteScrollDistance]="2"
                        [infiniteScrollThrottle]="50" [cdkDropListAutoScrollStep]="15" 
                        (scrolled)="gettingLeadDataForIndividualGroupOnScroll(groupInfo)" [scrollWindow]="false"
                        cdkDropList [cdkDropListData]="groupInfo?.leadContents" 
                        (cdkDropListDropped)="onDrop($event,groupInfo)" #container
                        [attr.id]="'group_'+groupInfo?.group_id">
                        <!-- formula div start here -->
                        <ng-container *ngIf="groupInfo?.formulaContents && groupInfo?.formulaContents?.length>0 && (viewType=='BoardView' || viewType=='GraphView')">
                                <div class="formulaCard"  >
                                    <div class="formulaCardInfo f-s-r">
                                        <ng-container *ngFor="let formulaInfo of groupInfo?.formulaContents | slice:0:2;let i = index">
                                            <div class="py-1 row mx-0 d-flex" style="align-items: center; justify-content: center;" >
                                                <div class="pl-2" style="width:calc(100% - 30px)">{{(formulaInfo?.displayKey)+" "+formulaInfo?.customFieldDisplayKey+" : "+formulaInfo?.convertedValue}}</div>
                                                <div class="cursorPointer removeFormulaIcon" (click)="removeAppliedFormula(formulaInfo?.customFieldKey,formulaInfo?.formulaKey)">&times;</div>
                                            </div>
                                        </ng-container>
                                        <div *ngIf="groupInfo?.formulaContents?.length>2" class="py-1 row mx-0 pr-2" style="justify-content: end;">
                                            <div class="pl-2 cursorPointer" [ngbPopover]="formulasInfoPopover" #formulasPopover="ngbPopover" placement="auto" container="body" [autoClose]="'outside'" popoverClass="formulasPopover" > {{'common.viewAll' | translate}} </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- formula popover start here -->
                                <ng-template #formulasInfoPopover>
                                        <div class="py-1 row mx-0 f-s-r mb-1">
                                            <div class="pl-2" style="width:70%">{{'bucket.appliedFormulas' | translate}} </div>
                                            <div style="width:30%" class="cursorPointer" (click)="removeAppliedFormula(null,null)">{{'bucket.removeAll' | translate}}</div>
                                        </div>
                                        <div class="formulaCardInfo f-s-r">
                                            <ng-container *ngFor="let formulaInfo of groupInfo?.formulaContents;let i = index">
                                                <div class="py-1 row mx-0" style="display: flex; align-items: center; justify-content: center;">
                                                    <div class="pl-2" style="width:calc(100% - 30px);">{{(formulaInfo?.displayKey)+" "+formulaInfo?.customFieldDisplayKey+" : "+formulaInfo?.convertedValue}}</div>
                                                    <div  class="cursorPointer removeFormulaIcon" (click)="removeAppliedFormula(formulaInfo?.customFieldKey,formulaInfo?.formulaKey)">&times;</div>
                                                </div>
                                            </ng-container>
                                        </div>
                                </ng-template>
                        </ng-container>
                        <!-- formula popover end here -->
                        <!-- formula div end here -->
                        <ng-container *ngIf="groupInfo?.group_id===clickQuickLeadGrouptId">
                            <div (keyup.esc)="closeQuickAddLead()"
                                (keyup.enter)="createLeadFromTitle(groupInfo?.group_id,j,$event)"
                                (keydown.tab)="$event.preventDefault()"
                                (click)="setQuickLeadFalse()">
                                <textarea [(ngModel)]="creationtext" (keydown.enter)="false"
                                    [ngModelOptions]="{standalone: true}" autosize class="emptytextarea"
                                    [minRows]="3" id="myTextInput" (focusout)="creationtext?.trim().length ? createLeadFromTitle(groupInfo?.group_id,j,$event) : closeQuickAddLead()">
                                </textarea>
                            </div>
                        </ng-container>
                        <ng-container *ngFor="let leadInfo of groupInfo.leadContents;let j=index">
                            <ng-container *ngIf="leadInfo && leadInfo?.iscreated">
                                <div class="element cardcnr shadow-sm" [class.selected-item]="leadInfo._id==currentLeadId" [cdkDragBoundary]="viewType=='GraphView' ? '.board-card-view' : null"
                                    cdkDrag (cdkDragStarted)="dragfun(groupInfo,leadInfo)" [cdkDragDisabled]="isReadOnly || leadInfo?.stopDrag || leadInfo?.isShowEditor || viewType=='SplitView' || viewType==='bottomBarView'"
                                    (cdkDragEnded)="onDragRelease()" cdkDragPreviewContainer="parent">
                                    <!-------When Parent Lead Present------>
                                    <div *ngIf="leadInfo?.parent_id && parentChildLeads[leadInfo?.parent_id]" class="previousleadcnr lprior " (click)="clickedLead(parentChildLeads[leadInfo?.parent_id], groupInfo);$event.stopPropagation()" [ngStyle]="{'border-top-color': !itemAttributesObj?.priority?.hidden ? constantService?.priority_colors[parentChildLeads[leadInfo?.parent_id]?.priority] : '#eee' }"> 
                                        <span class="d-flex align-items-center parentDeatilsBox">
                                            <span class="parentTagCapsule">Parent</span>
                                            <div class="d-flex align-items-center">
                                                <span class="f-s-m" (click)="onNavigateToEditLeadPage(parentChildLeads[leadInfo?.parent_id]);$event.stopPropagation()">{{parentChildLeads[leadInfo?.parent_id]?.seq_id_text}}</span>
                                                <span *ngIf="!itemAttributesObj?.bucket_id?.hidden" class="f-s-r">- {{parentChildLeads[leadInfo?.parent_id]?.bucket_name}}</span>
                                            </div>
                                        </span>
                                        <span *ngIf="!itemAttributesObj?.assigned_to?.hidden" class="userprof f-m-b" (click)="$event.stopPropagation()" placement="auto"  #assigneePopOver="ngbPopover" container="body"  [ngbPopover]="parentChildLeads[leadInfo?.parent_id]?.editable && assignUserTemplateParent" [autoClose]="'outside'">
                                            <span class="userprof"  [ngbPopover]="parentChildLeads[leadInfo?.parent_id]?.assigned_to && userInfoCard" popoverClass="userInfoCardPopover" triggers="hover" placement="auto" container="body">
                                                <span *ngIf="!parentChildLeads[leadInfo?.parent_id]?.assigned_to" class="iconBox-30 hoverBg"><img src="../../assets/common-use-icon-20dp/add-user-6d6e6f.svg" alt="add-assignee" class="iconBox-20"></span>
                                                <ngx-avatars *ngIf="parentChildLeads[leadInfo?.parent_id]?.assigned_to"  [name]="orgUsersJson[parentChildLeads[leadInfo?.parent_id]?.assigned_to]?.name || 'Assign'" [src]="orgUsersJson[parentChildLeads[leadInfo?.parent_id]?.assigned_to]?.image_url" size="20" initialsSize="2" ></ngx-avatars>
                                            </span>
                                            <ng-template #assignUserTemplateParent>
                                                <app-lead-assign-user  [assignedUser]="orgUsersJson[parentChildLeads[leadInfo?.parent_id]?.assigned_to]" [dashId]="leadInfo?.dashboard_id" [leadInfo]="[parentChildLeads[leadInfo?.parent_id]]" [bulkSelectionFlag]="false" (close)="assigneePopOver.close()" ></app-lead-assign-user>
                                            </ng-template>
                                        </span>
                                        <ng-template #userInfoCard>
                                            <app-user-info-card [user]="orgUsersJson[parentChildLeads[leadInfo?.parent_id]?.assigned_to]"></app-user-info-card>
                                        </ng-template>
                                    </div>
                                    <div class="position-relative topBorder" #selectedLead 
                                    [ngStyle]="{'border-top': !(leadInfo?.parent_id && parentChildLeads[leadInfo?.parent_id])? 'none': ''}"
                                        (click)="clickedLead(leadInfo,groupInfo, null,j)"
                                        >
                                        <div *ngIf="viewType=='BoardView'  && !isReadOnly" class="form-group multiSelectCheckBox"  [ngClass]="{'multiFlex' : multiSelectedLeadIds?.length}"
                                            (click)="$event.stopPropagation()">
                                            <input type="checkbox" [checked]="multiSelectedLeadIds.includes(leadInfo._id)" (click)="leadIntoBulkSelection(leadInfo,groupInfo)"
                                                [id]="leadInfo._id" [disabled]="!multiSelectGroupArray.includes(groupInfo?.group_id) && multiSelectGroupArray.length">
                                            <input type="checkbox" [checked]="multiSelectedLeadIds.includes(leadInfo._id)" (click)="leadIntoBulkSelection(leadInfo,groupInfo)"
                                                [id]="leadInfo._id">
                                            <label for="{{leadInfo._id}}"> </label>
                                        </div>
                                        <div class="cardsprint">
                                            <!------Conatin priority, lead id, Phase, Star----->
                                            <span class="cardSprintHeadLeft">
                                                <span *ngIf="!itemAttributesObj?.priority?.hidden" class="priority" [ngbPopover]="leadInfo.editable && priorityTemplate"
                                                #popPriorityTemplate="ngbPopover" placement="right" container="body"
                                                [autoClose]="'outside'" (click)="$event.stopPropagation()"
                                                [style.background]="constantService?.priority_colors[leadInfo.priority]">
                                                <span class="f-s-m">{{priorityJson[leadInfo.priority]?.name}}</span>
                                                <ng-template #priorityTemplate>
                                                    <span id="boardViewPopupsId">
                                                        <app-lead-priority (click)="$event.stopPropagation()"
                                                            [leadInfo]="[leadInfo]" [dashId]="leadInfo?.dashboard_id" (closePopover)="popPriorityTemplate?.close()"
                                                            [bulkSelectionFlag]="false">
                                                        </app-lead-priority>
                                                    </span>
                                                </ng-template>
                                                </span>
                                                <span class="sprintno d-flex">
                                                   <div class="d-flex align-items-center">
                                                        <span *ngIf="multipleBoardIds?.length>1 && dashboardData[leadInfo?.dashboard_id]?.DASHBOARD_INFO" container="body" placement="auto" ngbTooltip="{{dashboardData[leadInfo?.dashboard_id]?.DASHBOARD_INFO?.dashboard_name}}" tooltipClass="tooltipPopup">
                                                            <app-dashboard-icon class="iconBox-16" [size]="16" [name]="dashboardData[leadInfo?.dashboard_id]?.DASHBOARD_INFO?.icon" [iconJson]="attributesIcons"></app-dashboard-icon>
                                                        </span>
                                                        <span class="cursorPointer f-m-sb" (click)="onNavigateToEditLeadPage(leadInfo);$event.stopPropagation()">{{leadInfo?.seq_id_text}}</span>
                                                        <img class="cursorPointer"  *ngIf="!isLeadInTemplate" [ngbPopover]="itemInfoTemplate"
                                                            #popItemInfoTemplate="ngbPopover" placement="right" container="body"
                                                            [autoClose]="'outside'" (click)="$event.stopPropagation()"
                                                            [src]="groupInfo?.is_destination ? '../../../assets/common-use-icon-20dp/info-6d6e6f.svg' : '../../../assets/common-use-icon-20dp/info-6d6e6f.svg'">
                                                   </div>
                                                    <ng-template #itemInfoTemplate>
                                                        <div id="boardViewPopupsId">
                                                            <app-item-info [selectedDashId]="leadInfo?.dashboard_id" [leadInfo]="leadInfo" [users]="users" [dashName]="dashboardData[leadInfo?.dashboard_id]?.DASHBOARD_INFO?.dashboard_name"></app-item-info>
                                                        </div>
                                                    </ng-template>
                                                    <!-----When Phase Enabled-->
                                                    <ng-container *ngIf="dashboardData[leadInfo?.dashboard_id]?.SPRINT?.length>0 && dashboardData[leadInfo?.dashboard_id]?.DASHBOARD_INFO?.sprint_enabled">
                                                        <a class="f-xs-l cursorPointer" #leadSprintPopover="ngbPopover" role="button" placement="auto" container="body" [ngbPopover]="leadInfo.editable && sprintTemplate" (click)="$event.stopPropagation();" [autoClose]="'outside'">
                                                            <ng-container *ngIf="!leadInfo?.sprint_id">{{'board.noPhase' | translate}}</ng-container>
                                                            <ng-container *ngIf="leadInfo?.sprint_id">
                                                                <ng-container *ngFor="let sprintData of dashboardData[leadInfo?.dashboard_id]?.SPRINT"><span *ngIf="sprintData._id == leadInfo.sprint_id"  [ngbTooltip]="sprintData?.name?.length>10 && sprintData?.name" triggers="hover" placement="auto" tooltipClass="tooltipPopup">{{sprintData.name | truncate : 10}}</span></ng-container>
                                                            </ng-container>
                                                        </a>
                                                        <ng-template #sprintTemplate>
                                                            <ng-container>
                                                                <app-lead-sprint (click)="$event.stopPropagation();"
                                                                    [leadInfo]="[leadInfo]" [dashId]="leadInfo?.dashboard_id"
                                                                    [bulkSelectionFlag]="false"
                                                                    [dashBoardSprint]="dashboardData[leadInfo?.dashboard_id]?.SPRINT || []"
                                                                    [selectedPhase]="leadInfo.sprint_id"
                                                                    (closePopover)="leadSprintPopover.close()"
                                                                    >
                                                                </app-lead-sprint>
                                                            </ng-container>
                                                        </ng-template>
                                                    </ng-container>
                                                </span>
                                                
                                            </span>
                                            <!------Conatin priority, lead id, Phase, Star End Here----->
                                            <ng-template #leadDependencyTemplate>
                                                <div class="container">
                                                    <app-lead-dependency leadId={{leadInfo._id}}
                                                        dashId={{leadInfo?.dashboard_id}} [dashUsers]="dashUsersArr"
                                                        dashInitial={{selectedDashNameInitial}}>
                                                    </app-lead-dependency>
                                                </div>
                                            </ng-template>
                                            <span *ngIf="leadInfo.leadTotalCheckList" (click)="$event.stopPropagation()"
                                                class="boardchecklist mr-0" placement="auto"
                                                #leadCheckListPopover="ngbPopover" container="body"
                                                [ngbPopover]="leadInfo.editable && leadCheckListTemplate" [autoClose]="'outside'">
                                                <!-- <img  src="/assets/1x/checklist-icon.svg"> -->
                                                <app-dashboard-icon [size]="17"  [name]="'CheckList'"></app-dashboard-icon>
                                                {{leadInfo.leadCompletedCheckItem}}/{{leadInfo.leadTotalCheckList}}
                                            </span>
                                            <ng-template #leadCheckListTemplate>
                                                <div class="container" (click)="$event.stopPropagation()">
                                                    <app-check-list [leadId]="leadInfo._id" [dashId]="leadInfo?.dashboard_id">
                                                     </app-check-list>
                                                </div>
                                            </ng-template>
                                            <!--- car header right side-->
                                            <span class="vertdt cardHeaderRight f-s-r" *ngIf="viewType=='BoardView' || viewType=='GraphView'">
                                                <span class="star">
                                                    <img *ngIf="leadInfo.isStarred"
                                                        (click)="leadInfo.editable && updateStarLead(leadInfo,false);$event.stopPropagation()"
                                                        src="../../../assets/1x/star.svg">
                                                    <ng-container
                                                            *ngIf="(viewType=='BoardView' || viewType=='GraphView') && (leadInfo.dependsOnCount>0 || leadInfo.dependentsCount>0)">              
                                                            <span class="d-flex align-items-center dependencyStatus">
                                                                {{leadInfo.dependsOnCount}} 
                                                                <span (click)="getDependenciesForLead(leadInfo._id,groupInfo);$event.stopPropagation()"
                                                                #leadDependencyPopover="ngbPopover"
                                                                [ngbPopover]="leadInfo.editable && leadDependencyTemplate" [autoClose]="'outside'" placement="auto" container="body">
                                                                    <app-dashboard-icon [size]="16"  [name]="'Dependency'"></app-dashboard-icon>
                                                                </span>
                                                                {{leadInfo.dependentsCount}}
                                                            </span>
                                                    </ng-container>
                                                </span>
                                                <ng-container *ngIf='!leadInfo.is_aging_time'>
                                                    <span class="time f-s-r">
                                                        <img src="../../../assets/common-use-icon-20dp/time-6d6e6f.svg" class="iconBox-16">{{leadInfo.diff | daysago: 'true'}}</span>
                                                </ng-container>
                                                <ng-template #leadTimelineTemplate>
                                                    <div class="timelinePopover">
                                                        <app-timeline dashId={{leadInfo?.dashboard_id}} item_id={{leadInfo._id}}
                                                            [height]="25" dashboard_initial={{selectedDashNameInitial}}
                                                            [isHeaderFilterRequired]="false"
                                                            [isHeaderQuickFilterRequired]="false"
                                                            [isHeaderRequired]="false"
                                                            [isRightSideFilterRequired]="false"></app-timeline>
                                                    </div>
                                                </ng-template>
                                                <ng-container *ngIf='leadInfo.is_aging_time'>
                                                    <span class="time liveCounter againgtxtLeft" id="live-Counter" *ngIf="leadInfo.aging_time_in_seconds>0"><img
                                                            src="../../../assets/common-use-icon-20dp/deadline-bom-6d6e6f.svg" class="iconBox-16 m-0">{{leadInfo.aging_time_in_seconds | minutesPipe}} Left</span>
                                                    <span class="time againgtxtExceed" id="live-Counter" *ngIf="leadInfo.aging_time_in_seconds<0"><img
                                                            src="../../../assets/common-use-icon-20dp/deadline-bom-active-fe2030.svg" class="iconBox-16 m-0">{{leadInfo.aging_time_in_seconds | minutesPipe}} Over-due</span>
                                                </ng-container>
                                                <!------These Are the options coming on hover, contains tag, date assignee and three dots-->
                                                <ng-container>
                                                    <div class="cdrRightSideIptions align-items-center gap8" [ngClass]="{'d-flex': popoverRef?.popover?.isOpen() && popoverRef?.leadId==leadInfo?._id, 'hideNoValueIcon' : !leadTagPopOverInner?.isOpen() && !datePopOverInner?.isOpen() && !commentPopOverInner?.isOpen() && !assigneePopOverInner?.isOpen() && (!popoverRef || popoverRef && (!popoverRef?.popover?.isOpen() || popoverRef?.popover?.isOpen() && popoverRef?.leadId!=leadInfo?._id))}" >
                                                        <ng-container *ngIf="((!leadInfo.assigned_to || itemAttributesObj?.assigned_to?.hidden)  && (!(leadInfo.totalCommentCount-leadInfo.totalReadCommentCount>0) && !commentPopoverLeadIds.includes(leadInfo._id) || commentPopoverLeadIds.includes(leadInfo._id+'emptyCount'))  && !leadInfo?.child_lead_id?.length) ||  (!leadInfo.tag_id?.length && !itemAttributesObj?.tag_id?.hidden) || (!(leadInfo?.start_date?.time || leadInfo?.end_date?.time) && !itemAttributesObj?.start_end_date?.hidden)">
                                                            <!-- <div style="width:80%;display: flex" class="cardbtmcnr"> -->
                                                            <div [style]="itemAttributesObj?.tag_id?.hidden || leadInfo.tag_id?.length ? 'display:none;' : '' " class="iconBox-24">
                                                                <span [ngbTooltip]="!isPopOverOpen?('helpingText.Tag' | translate):''" tooltipClass="tooltipPopup" triggers="hover" placement="auto" class="appInnerIconBox">
                                                                    <img class="iconBox-20" [src]="groupInfo.is_destination?'../../../assets/common-use-icon-20dp/tag-6d6e6f.svg':'../../../assets/common-use-icon-20dp/tag-6d6e6f.svg'" #leadTagPopOverInner="ngbPopover" placement="auto" container="body" [autoClose]="'outside'" [ngbPopover]="leadInfo.editable && tagPopTemplate" (click)="showCreateTag=false;$event.stopPropagation();isPopOverOpen=!isPopOverOpen;getPopoverRef(leadTagPopOverInner,leadInfo._id)" (hidden)="isPopOverOpen=false">
                                                                </span>
                                                            </div>
                                                            <div [style]="itemAttributesObj?.start_end_date?.hidden || (leadInfo?.start_date?.time || leadInfo?.end_date?.time) ? 'display:none;' : ''">
                                                                <div class="d-flex iconBox-24" [ngbPopover]="leadInfo.editable && datePopover" placement="auto" container="body" #datePopOverInner="ngbPopover" [autoClose]="'outside'"(click)="$event.stopPropagation();getPopoverRef(datePopOverInner,leadInfo._id)" popoverClass="datePickerPopover">
                                                                    <span class="appInnerIconBox" [ngbTooltip]="('helpingText.startEndDate' | translate)" tooltipClass="tooltipPopup" triggers="hover" placement="auto">
                                                                        <img class="iconBox-20" (click)="startDateClicked(leadInfo)" src="../../../assets/common-use-icon-20dp/calendar-6d6e6f.svg">
                                                                    </span>  
                                                                </div>
                                                                
                                                            </div> 
                                                            <!-- <div [style]="!((!leadInfo.assigned_to && !itemAttributesObj?.assigned_to?.hidden || itemAttributesObj?.assigned_to?.hidden)  && (!(leadInfo.totalCommentCount-leadInfo.totalReadCommentCount>0) && !commentPopoverLeadIds.includes(leadInfo._id) || commentPopoverLeadIds.includes(leadInfo._id+'emptyCount')) && !leadInfo?.child_lead_id?.length) ? 'display:none;' : '' "
                                                            [ngbTooltip]="('helpingText.Comment' | translate)"  tooltipClass="tooltipPopup" triggers="hover" placement="auto" class="iconBox-24">
                                                                <img class="iconBox-20" src="../../../assets/common-use-icon-20dp/comment-6d6e6f.svg" placement="auto" container="body" [ngbPopover]="leadInfo.editable && leadCommentTemplate" #commentPopOverInner="ngbPopover" [autoClose]="'outside'" (click)="getLeadCopy(leadInfo);$event.stopPropagation()" (hidden)="commentPopoverLeadIds=[]" (shown)="commentPopoverLeadIds=[leadInfo._id+'emptyCount']">           
                                                            </div> -->
                                                            <div *ngIf="!leadInfo.assigned_to && !itemAttributesObj?.assigned_to?.hidden"
                                                            [ngbTooltip]="('helpingText.assignee' | translate)" tooltipClass="tooltipPopup" triggers="hover" placement="auto" (click)="$event.stopPropagation()" class="iconBox-24">
                                                                <span class="appInnerIconBox" style="display:flex" placement="auto"  #assigneePopOverInner="ngbPopover" [autoClose]="'outside'" [ngbPopover]="leadInfo.editable && assignUserTemplate" (click)="getPopoverRef(assigneePopOverInner,leadInfo._id);$event.stopPropagation()" container="body">
                                                                    <span *ngIf="!leadInfo.assigned_to" class="iconBox-20"><img src="../../assets/common-use-icon-20dp/add-user-6d6e6f.svg" class="appInnerIconBox"></span>
                                                                </span>
                                                            </div> 
                                                            <ng-template #assignUserTemplate>
                                                                <app-lead-assign-user   [assignedUser]="orgUsersJson[leadInfo.assigned_to]" [dashId]="leadInfo?.dashboard_id" [leadInfo]="[leadInfo]"  [bulkSelectionFlag]="false"  (close)="popoverRef?.popover?.close()"></app-lead-assign-user>
                                                            </ng-template>
                                                        </ng-container> 
                                                        <span  [ngbPopover]="!isLeadInTemplate && !isInternalBoardReadOnly && leadOptionTemplate"
                                                                #optionsPopover="ngbPopover" placement="auto" container="body" (click)="$event.stopPropagation();getPopoverRef(optionsPopover,leadInfo._id)"
                                                                [autoClose]="true" class="leadCardOptions iconBox-24" [ngStyle]="{'display': optionsPopover?.isOpen() ? 'flex' : ''}">
                                                            <span class="appInnerIconBox" >
                                                                <app-dashboard-icon [size]="20" class="appInnerIcons" [name]="'dotsVertical'" [iconJson]="commonIcons"></app-dashboard-icon>
                                                            </span>
                                                        </span>
                                                        <ng-template #leadOptionTemplate>
                                                            <div id="boardViewPopupsId" >
                                                                <div>
                                                                <app-lead-option-template 
                                                                [isReadOnly]="!leadInfo.editable"
                                                                [bucketInfo]="groupInfo"
                                                                [dashboardUserRole]="dashboardUserRole"
                                                                [leadInfo]="leadInfo" 
                                                                [selectedDashId]="leadInfo?.dashboard_id"
                                                                [dashBoardSprint]="dashboardData[leadInfo?.dashboard_id]?.SPRINT || []"
                                                                (showSubtaskParentModal)="clickedLead(leadInfo,groupInfo, null,j,$event)">
                                                                </app-lead-option-template>
                                                                </div>
                                                            </div>
                                                        </ng-template>
                                                    </div>
                                                </ng-container>
                                                <!------These Are the options coming on hover ENDS HERE-->
                                            </span>
                                        </div>
                                        <div class="carddetails lead-title" *ngIf="!leadInfo?.isShowEditor">
                                            <p class="lead-title-txt ls-05x mb-0 f-l-r">{{leadInfo.title}} 
                                                <span *ngIf="leadInfo.editable" (click)="$event.stopPropagation();openTitleEditor(leadInfo,$event)" class="editTitle  kanbamTitleEditIcon">
                                                    <img  src="../../../assets/1x/editBlackIcon.svg" /></span>
                                            </p>
                                        </div>
                                        <div class="carddetails lead-title" *ngIf="leadInfo?.isShowEditor" (click)="$event.stopPropagation()">
                                            <textarea [(ngModel)]="leadInfo.title" autosize class="emptytextarea"
                                            [id]="'editTextarea_'+leadInfo._id"
                                            [readonly]="!leadInfo.editable" [minRows]="1"
                                            [ngModelOptions]="{standalone: true}"
                                             #title ="ngModel"
                                             (click)="$event.stopPropagation()"
                                             (blur)="updateLeadInfo(leadInfo)"
                                             >
                                            </textarea>
                                        </div>

                                        <!----Display tags, Bucket, Assignee, Date and columns value from here-->
                                        <ng-container *ngIf="viewType=='BoardView' || viewType=='GraphView'">
                                            <!-- tags if value -->
                                            <div class="cardbtmcnr f-s-r"  *ngIf="!itemAttributesObj?.tag_id?.hidden && leadInfo.tag_id?.length>0">
                                                <span class="iconBox-24" style="margin-left: -2px;"   placement="auto"  #leadTagPopOver="ngbPopover" [autoClose]="'outside'" container="body" [ngbPopover]="leadInfo.editable && tagPopTemplate" (click)="showCreateTag=false;$event.stopPropagation();getPopoverRef(leadTagPopOver,leadInfo._id)">
                                                    <img class="iconBox-20" [src]="groupInfo.is_destination?'../../../assets/common-use-icon-20dp/tag-3571de.svg':'../../../assets/common-use-icon-20dp/tag-3571de.svg'" >
                                                </span>
                                                <div *ngIf="leadInfo.tag_id?.length>0" class="tagListBox">
                                                    <ng-container *ngFor="let tagId of leadInfo.tag_id">
                                                        <div *ngIf="dashboardData[leadInfo?.dashboard_id]?.TAG_MAP && dashboardData[leadInfo?.dashboard_id]?.TAG_MAP[tagId]" class="cardtag" [placement]="['top','top-left','top-right','auto']" triggers="hover" [ngbTooltip]="dashboardData[leadInfo?.dashboard_id]?.TAG_MAP[tagId]?.name?.length>7 && dashboardData[leadInfo?.dashboard_id]?.TAG_MAP[tagId]?.name"  tooltipClass="tooltipPopup" >
                                                            <div class="tagText">{{dashboardData[leadInfo?.dashboard_id]?.TAG_MAP[tagId]?.name}}</div>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <!-- dates(start and end) if value -->
                                            <div class="cardbtmcnr f-s-r"  *ngIf="!itemAttributesObj?.start_end_date?.hidden && (leadInfo?.start_date?.time || leadInfo?.end_date?.time)">
                                                <div class="d-flex align-items-center" (click)="$event.stopPropagation()">
                                                    <div class="d-flex iconBox-24" style="margin-left: -2px;" [ngbPopover]="leadInfo.editable && datePopover"  placement="auto" container="body" #datePopOver="ngbPopover" popoverClass="datePickerPopover" [autoClose]="'outside'" (click)="popoverRef=datePopOver">
                                                        <img class="iconBox-20" (click)="startDateClicked(leadInfo)"  src="../../../assets/common-use-icon-20dp/calendar-3571de.svg">
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center dateGroupBox">
                                                    <div *ngIf="leadInfo?.start_date?.dateLabel" class="startDateTime">
                                                        <span *ngIf="leadInfo?.start_date?.dateLabel && !leadInfo?.end_date?.dateLabel">Starts</span>
                                                        <div class="d-flex" ><span>{{leadInfo?.start_date | dateCalcuate:"date"}}</span><span class="startTime" *ngIf="leadInfo.start_date.is_time_added">{{','+(leadInfo.start_date | dateCalcuate:"time")}}</span></div>
                                                    </div>
                                                    <span *ngIf="leadInfo?.start_date?.dateLabel && leadInfo?.end_date?.dateLabel">-</span>
                                                    <div *ngIf="leadInfo?.end_date?.dateLabel" class="endDateTime" (click)="$event.stopPropagation()">
                                                        <span *ngIf="!leadInfo?.start_date?.dateLabel && leadInfo?.end_date?.dateLabel">Ends</span>
                                                        <div class="d-flex" ><span>{{leadInfo?.end_date | dateCalcuate:"date"}}</span><span class="endTime" *ngIf="leadInfo.end_date.is_time_added">{{','+(leadInfo.end_date | dateCalcuate:"time")}}</span></div>
                                                    </div>
                                                </div>
                                                <ng-template #datePopover>
                                                    <div id="boardViewPopupsId">
                                                        <app-lead-date-picker (click)="$event.stopPropagation()" [selectedStartDateObj]="leadInfo?.start_date"   [selectedEndDateObj]="leadInfo?.end_date"  [selectedDashId]="leadInfo?.dashboard_id"  [leadInfo]="[leadInfo]"   (closeDateModalOnCancel)="datePopOver?.close()"></app-lead-date-picker>
                                                    </div>
                                                </ng-template>  
                                            </div>
                                            <!-- comment subtask and assignee if value -->
                                            <div class="cardbtmcnr  commentSubTaskBoxAssignee f-s-r" *ngIf="(!itemAttributesObj?.bucket_id?.hidden || leadInfo?.totalCommentCount-leadInfo?.totalReadCommentCount)>0 || commentPopoverLeadIds.includes(leadInfo._id) || leadInfo?.child_lead_id?.length > 0 || (leadInfo.assigned_to && !itemAttributesObj?.assigned_to?.hidden)">
                                                <div class="commentSubTaskBox flex-grow-1" *ngIf="!itemAttributesObj?.bucket_id?.hidden || (itemAttributesObj?.bucket_id?.hidden && !itemAttributesObj?.assigned_to?.hidden && leadInfo.assigned_to)">
                                                    <!-- <div class="d-flex align-items-center" style="margin-left: -2px;">
                                                        <span class="iconBox-30"  placement="auto" [ngbPopover]="leadInfo.editable && leadCommentTemplate" #commentPopOver="ngbPopover" container="body" [autoClose]="'outside'" (click)="getLeadCopy(leadInfo);$event.stopPropagation()" (hidden)="commentPopoverLeadIds=[]" (shown)="commentPopoverLeadIds=[leadInfo._id]"><img src="../../../assets/common-use-icon-20dp/comment-3571de.svg" class="iconBox-20"></span>
                                                        <span *ngIf="(leadInfo?.totalCommentCount-leadInfo?.totalReadCommentCount)>0"> {{leadInfo.totalCommentCount-leadInfo.totalReadCommentCount}}
                                                            <span *ngIf="leadInfo.tagged_count && leadInfo.tagged_count != 0">@{{leadInfo.tagged_count}}</span>
                                                        </span>               
                                                    </div> -->
                                                    <span class="d-flex align-items-center gap3" *ngIf="!itemAttributesObj?.bucket_id?.hidden"  (click)="$event.stopPropagation()" #bucketPopoverTempRef="ngbPopover" [autoClose]="'outside'" [ngbPopover]="!isReadOnly && bucketPopoverTemp">
                                                        <span class="iconBox-24" style="margin-left: -2px;"><app-dashboard-icon [size]="20" class="appInnerIconBox"  [name]="'bucketOutline'" [iconJson]="commonIcons"></app-dashboard-icon></span>
                                                        <span class="f-s-r">{{leadInfo.bucket_name}}</span>
                                                    </span>
                                                    <ng-template #bucketPopoverTemp>
                                                        <app-lead-bucket-change (click)="$event.stopPropagation();"
                                                            [dashId]="leadInfo?.dashboard_id"
                                                            [leadInfo]="[leadInfo]"
                                                            [bucketArray]="bucketArray" [bulkSelectionFlag]="false"
                                                            (updateLead)="leadInfo['bucket_name']=$event?.bucket_name;bucketPopoverTempRef?.close()">
                                                        </app-lead-bucket-change>
                                                      </ng-template>
                                                    
                                                      <div  *ngIf="!itemAttributesObj?.assigned_to?.hidden && leadInfo.assigned_to && itemAttributesObj?.bucket_id?.hidden " class="d-flex flex-grow-1" (click)="$event.stopPropagation()">
                                                        <div [ngbPopover]="!assigneePopOver?.isOpen() && leadInfo.assigned_to && userInfoCard" popoverClass="userInfoCardPopover" triggers="hover" placement="auto" container="body" class="d-flex w-100">
                                                            <span class="d-flex w-100 align-items-center gap3" placement="auto" #assigneePopOver="ngbPopover" [autoClose]="'outside'" [ngbPopover]="leadInfo.editable && assignUserTemplate" (click)="getPopoverRef(assigneePopOver,leadInfo._id);$event.stopPropagation()" container="body">
                                                                <!-- <span *ngIf="!leadInfo.assigned_to" class="appInnerIconBox"> <img src="../../assets/common-use-icon-20dp/add-user-6d6e6f.svg" class="iconBox-24"></span> -->
                                                                <ngx-avatars [name]="orgUsersJson[leadInfo.assigned_to]?.name ? orgUsersJson[leadInfo.assigned_to]?.name : 'Assign'" [src]="orgUsersJson[leadInfo.assigned_to]?.image_url" size="24" initialsSize="2" sizeRatio="2.2" ></ngx-avatars>
                                                                <span class="f-s-r flex-grow-1">{{orgUsersJson[leadInfo.assigned_to]?.name}}</span>
                                                            </span>
                                                        </div>
                                                        <ng-template #assignUserTemplate>
                                                                <app-lead-assign-user   [assignedUser]="orgUsersJson[leadInfo.assigned_to]" [dashId]="leadInfo?.dashboard_id" [leadInfo]="[leadInfo]"  [bulkSelectionFlag]="false"  (close)="assigneePopOver.close()"></app-lead-assign-user>
                                                        </ng-template>
                                                    </div> 
                                                </div>
                                                <!----Assignee and sub task box-->
                                                <div class="d-flex align-items-center gap16 jusstify-content-end" *ngIf="leadInfo?.child_lead_id?.length > 0 || (leadInfo.assigned_to && !itemAttributesObj?.assigned_to?.hidden)">
                                                    <div class="d-flex" *ngIf="leadInfo?.child_lead_id?.length > 0" (click)="$event.stopPropagation()" [ngbTooltip]="(leadInfo?.closed_subitem_id ? leadInfo?.closed_subitem_id?.length : '0') + ' of ' +leadInfo.child_lead_id.length +' sub item closed'" triggers="hover" placement="auto" tooltipClass="tooltipPopup">
                                                        <div class="d-flex align-items-center" (click)="getChildrenOfSelectedLead(leadInfo, leadInfo._id);" [ngStyle]="{'margin': ((itemAttributesObj?.assigned_to?.hidden || !leadInfo.assigned_to) && itemAttributesObj?.bucket_id?.hidden) ? '-2px' : '0'}">
                                                            <span class="iconBox-24" >
                                                                <img class="iconBox-20" src="assets/new-icon-6d6e6f-20dp/subtask-6d6e6f.svg" alt="sub Itemm img"> </span>
                                                            <span>{{leadInfo.child_lead_id.length}}</span>
                                                        </div>
                                                    </div> 
                                                    <div  *ngIf="!itemAttributesObj?.assigned_to?.hidden && leadInfo.assigned_to && !itemAttributesObj?.bucket_id?.hidden" class="assignusr iconBox-24" (click)="$event.stopPropagation()">
                                                        <div [ngbPopover]="!assigneePopOver?.isOpen() && leadInfo.assigned_to && userInfoCard" popoverClass="userInfoCardPopover" triggers="hover" placement="auto" container="body" class="appInnerIconBox">
                                                            <span class="appInnerIconBox" placement="auto" #assigneePopOver="ngbPopover" [autoClose]="'outside'" [ngbPopover]="leadInfo.editable && assignUserTemplate" (click)="getPopoverRef(assigneePopOver,leadInfo._id);$event.stopPropagation()" container="body">
                                                                <!-- <span *ngIf="!leadInfo.assigned_to" class="appInnerIconBox"> <img src="../../assets/common-use-icon-20dp/add-user-6d6e6f.svg" class="iconBox-24"></span> -->
                                                                <ngx-avatars [name]="orgUsersJson[leadInfo.assigned_to]?.name ? orgUsersJson[leadInfo.assigned_to]?.name : 'Assign'" [src]="orgUsersJson[leadInfo.assigned_to]?.image_url" size="24" initialsSize="2" sizeRatio="2.2" ></ngx-avatars>
                                                            </span>
                                                        </div>
                                                        <ng-template #assignUserTemplate>
                                                                <app-lead-assign-user   [assignedUser]="orgUsersJson[leadInfo.assigned_to]" [dashId]="leadInfo?.dashboard_id" [leadInfo]="[leadInfo]"  [bulkSelectionFlag]="false"  (close)="assigneePopOver.close()"></app-lead-assign-user>
                                                        </ng-template>
                                                    </div> 
                                                </div>
                                            </div>
                                            <!-----Columns Value-->
                                            <ng-container *ngIf="selectedCustFieldKeys?.length">
                                                    <ng-container *ngFor="let customItem of selectedCustFieldKeys">
                                                        <ng-container *ngTemplateOutlet="columnTemp;context:{data:(customItem)}"></ng-container>
                                                    </ng-container>
                                            </ng-container>
                                        </ng-container>
                                        <!----Display tags, Bucket, Assignee, Date and columns value ENDS here-->

                                        <!-----Popover codes-->
                                        <ng-template #leadCommentTemplate>
                                            <div class="commentPopver" style="height:300px;">
                                                <app-chat-messages [dashId]="leadInfo?.dashboard_id"  [selectedLeadObj]="commentLead" [currentActiveTab]="'commentTab'" [disableAttachColumns]="true"></app-chat-messages>
                                            </div>
                                        </ng-template>                  
                                        <ng-template #userInfoCard>
                                            <ng-container>
                                                <app-user-info-card
                                                    [user]="orgUsersJson[leadInfo?.assigned_to]">
                                                </app-user-info-card>
                                            </ng-container>
                                        </ng-template>
                                        <ng-template #tagPopTemplate>
                                            <ng-container *ngIf="!showCreateTag">
                                                <app-lead-tags (click)="$event.stopPropagation()"
                                                    [dashboardTagsJson]="dashboardData[leadInfo?.dashboard_id]?.TAG_MAP || []"
                                                    [leadInfo]="[leadInfo]" [dashId]="leadInfo?.dashboard_id"
                                                    [bulkSelectionFlag]="false"
                                                    (showCreateTag)="showCreateTag = $event">
                                                </app-lead-tags>
                                            </ng-container>
                                            <ng-container *ngIf="showCreateTag">
                                                <app-create-tag (click)="$event.stopPropagation()"
                                                    (updateDashboardTags)="showCreateTag=false"
                                                    [dashId]="leadInfo?.dashboard_id"
                                                    (closeManageTag)="showCreateTag = $event"
                                                    (showConfirmationModal)="showConfirmationModalFlag = $event"
                                                    [confirmationAction]="confirmationAction">
                                                </app-create-tag>
                                            </ng-container>
                                        </ng-template>
                                        <ng-template #datePopover>
                                            <div id="boardViewPopupsId">
                                                <app-lead-date-picker (click)="$event.stopPropagation()" [selectedStartDateObj]="leadInfo?.start_date"   [selectedEndDateObj]="leadInfo?.end_date"  [selectedDashId]="leadInfo?.dashboard_id"  [leadInfo]="[leadInfo]"   (closeDateModalOnCancel)="popoverRef?.popover?.close()"></app-lead-date-picker>
                                            </div>
                                        </ng-template> 
                                    </div>

                                    <ng-container *ngIf="leadInfo.showChildren" class="cursorDefault" (click)="$event.stopPropagation()">
                                        <ng-container *ngFor="let childLeadId of leadInfo.child_lead_id">
                                            <div *ngIf="parentChildLeads[childLeadId] && !parentChildLeads[childLeadId]?.is_deleted" class="subItemBorder row mx-1 mt-2" (click)="clickedLead(parentChildLeads[childLeadId], groupInfo);$event.stopPropagation()" [style.border-left-color]="constantService?.priority_colors[parentChildLeads[childLeadId]?.priority]">
                                                <div class="col-12 px-2" style="display: flex; justify-content: space-between;">
                                                    <span class="lIdbuckNameStyle">
                                                        <span (click)="onNavigateToEditLeadPage(parentChildLeads[childLeadId]);$event.stopPropagation()">{{parentChildLeads[childLeadId]?.seq_id_text}}</span>
                                                        <span class="bluetxt"> - {{parentChildLeads[childLeadId]?.bucket_name}} </span>
                                                    </span>
                                                    <span class="userprof f-m-b" (click)="$event.stopPropagation()" placement="auto" container="body" #assigneePopOver="ngbPopover" container="body" [ngbPopover]="parentChildLeads[childLeadId]?.editable && assignUserTemplateChild" [autoClose]="'outside'">
                                                        <span class="userprof"  [ngbPopover]="parentChildLeads[childLeadId].assigned_to && userInfoCard" popoverClass="userInfoCardPopover" triggers="hover" placement="auto"  container="body">
                                                            <span *ngIf="!parentChildLeads[childLeadId].assigned_to" class="iconBox-30 hoverBg"><img src="../../assets/common-use-icon-20dp/add-user-6d6e6f.svg" alt="add-asginee" class="iconBox-24"></span>
                                                            <ngx-avatars class="mr-1" *ngIf="parentChildLeads[childLeadId].assigned_to" [name]="orgUsersJson[parentChildLeads[childLeadId]?.assigned_to]?.name || 'Assign'" [src]="orgUsersJson[parentChildLeads[childLeadId].assigned_to]?.image_url" size="24" initialsSize="2" ></ngx-avatars>
                                                        </span>
                                                    </span>
                                                    <ng-template #assignUserTemplateChild>
                                                        <app-lead-assign-user [assignedUser]="orgUsersJson[parentChildLeads[childLeadId].assigned_to]" [dashId]="leadInfo?.dashboard_id" [leadInfo]="[parentChildLeads[childLeadId]]" [bulkSelectionFlag]="false" (close)="assigneePopOver.close()"></app-lead-assign-user>
                                                    </ng-template>
                                                    <ng-template #userInfoCard>
                                                        <app-user-info-card [user]="orgUsersJson[parentChildLeads[childLeadId].assigned_to]"></app-user-info-card>
                                                    </ng-template>
                                                </div>
                                                <div class="col-12 px-2">
                                                    {{parentChildLeads[childLeadId]?.title | truncate : 70}}
                                                </div>
                                            </div>

                                        </ng-container>
                                    </ng-container>
                                    
                                </div>
                            </ng-container>

                            <!--Column Template-->
                            <ng-template #columnTemp let-data="data">
                               <ng-container *ngIf="data?.type!=='Bucket' && leadInfo?.custom_fields[data?.key]">
                                <div class="w-100 d-flex align-items-center gap6 cardColumnBox"  (click)="$event.stopPropagation()">
                                    <span class="iconBox-24" style="margin-left: -6px;"><app-dashboard-icon [name]="data?.mirror_column_type ? data?.mirror_column_type : data?.type" [iconJson]="attributesIcons"></app-dashboard-icon></span>
                                    <span style="flex:1;"><app-multi-type class="BoardViewCellCall" [selectedDashId]="selectedDashId" [currencyArr]="currencyArr" [dashboardUserRole]="dashboardUserRole" [mirrorColumnsJson]="mirrorColumnsJson" [params]="{'cellType':data?.type,'cellValue':leadInfo.custom_fields[data?.key],'cellKey':data?.key,'editable':true,'customFieldData':data,'leadInfo':leadInfo,'isBoardView':true}" (onItemOpen)="openItemModal($event)"></app-multi-type></span>
                                </div>
                               </ng-container>
                             <!--    <ng-container *ngFor="let field of leadInfo.custom_fields | keyvalue">
                                    <ng-container *ngIf="data.key == field.key">
                                                <ng-container *ngIf="data?.type=='Text'">
                                                    <div class="w-100 d-flex align-items-center gap6 cardColumnBox"  (click)="$event.stopPropagation()">
                                                        <span class="iconBox-24" style="margin-left: -6px;"><app-dashboard-icon [name]="data.type" [iconJson]="attributesIcons"></app-dashboard-icon></span>
                                                        <span [innerHtml]="field.value | safehtml | truncate: 20" class="boardTxtArea  d-flex align-items-center" [ngbPopover]="customFiledColumn"> </span>    
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="data?.type=='Numeric'">
                                                    <div class="w-100 d-flex align-items-center gap6 cardColumnBox"  (click)="$event.stopPropagation()">
                                                    <span class="iconBox-24" style="margin-left: -6px;"><app-dashboard-icon [name]="data.type" [iconJson]="attributesIcons"></app-dashboard-icon></span>
                                                    <span class="boardTxtArea  d-flex align-items-center">{{field.value}} </span>
                                                    </div>
                                                </ng-container> 
                                    </ng-container>
                                </ng-container>    -->  
                            </ng-template>
                        </ng-container>
                        <div *ngIf="groupInfo?.enableScrollerLoader" class="d-flex text-center justify-content-center p-2" style="background-color: white;position: sticky;bottom: 0px;">
                            <app-shimmer-loader  [type]="'Spinner'" [circleWidth]="30" [color]="commonUtils.getCurrentThemeColor()"></app-shimmer-loader>
                        </div>
                        <!-- show empty icon -->
                        <ng-container *ngIf="!groupInfo.leadContents?.length && (viewType=='CalenderView' || viewType=='SplitView' || viewType=='bottomBarView')">
                            <div class="no-data-found">
                                <img src="../../assets/img/icons/empty.svg" />
                                <span class="f-xl-m mt-1">{{'editcustom.noRecordFound' | translate}}</span> 
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            </ng-container>
            <ng-container *ngIf="viewType=='SplitView'">
                <ng-container *ngTemplateOutlet="itemViewPannel"></ng-container>
            </ng-container>
            <div class="showMobileViewMsg">
                <p>For better user experience open in wide screen.</p>
            </div>
            <div *ngIf="isSidebarOpen" class="lastboarcol"></div>
        </div>
    </ng-container>
<!-- preview modal -->
  <ng-template #videoPlayerModalNested let-modal>
    <div class="previewBodyModal" style="height: 100%;max-width: 100%;position: relative;">
      <i class="fa fa-close bg-white previewCross" (click)="modal.dismiss()"></i>
      <app-file-preview [mediaArr]="mediaArr" [index]="indexForPreview" [type]="'ITEM'" [dashId]="selectedDashId" [typeId]="currentLeadId"></app-file-preview>
    </div>
  </ng-template>

  <!-- multi select -->
  <ng-container *ngIf="multiSelectedLeadIds?.length || multiSelectGroupArray?.length">
    <app-item-bulk-selection
    class="d-flex align-items-center justify-content-center"
    [dashUsers]="users"
    [bulkSelectionData]="bulkSelectionData"
    [dashId]="selectedDashId"
    [leadInfo]="bulkLeadInfo"
    [dashboardTags]="tagsCorrespondingToDash"
    [dashBoardSprint]="dashBoardSprint"
    [bucketArray]="bucketArray"
    [selectedStartDate]="selectedStartDate"
    [selectedEndDate]="selectedEndDate"
    (bulkOperationsCompleted)="bulkOperationsCompleted()"
    [isSelectedAll]="isSelectedAll"
    [filterDataObj]="filterDataObj"
    [multiSelectGroupArray]="multiSelectGroupArray"
    [excludeInMultiselectBucket]="excludeInMultiselectGroup"
    [funnelarr]="funnelarr"
    >
    </app-item-bulk-selection>
  </ng-container>

<div class="modal fade" id="dragNotAllowed" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog " role="document">

    <div class="modal-content">
      <div class="modal-header text-right mt-2 pb-0">
        <div data-dismiss="modal" aria-label="Close" aria-hidden="true" class="close"><span aria-hidden="true">×</span>
        </div>
      </div>

      <div class="modal-body text-center pb-5">
        <h4 class="modal-title pb-3">        {{'board.notAllowed' | translate}}</h4>
        <img src="../../../assets/1x/info.png"><br>
        <br>
        {{'board.youAreNotAllowedToMoveLeadInBucket' | translate}}
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="itemPreviewData?.length">
    <item-view [itemData]="itemPreviewData" [currentBoardId]="selectedDashId" [currentViewId]="viewType=='GraphView' ? null : currentViewId"  (closeItemView)="itemPreviewData=[]" (onItemChange)="$event?.changeType=='previousItem' && moveToPreviousLead();$event?.changeType=='nextItem' && moveToNextLead()" [class]="'item-side-view'"></item-view>
</ng-container>
<!-- item right container -->
<ng-template #itemViewPannel>
    <div class="item-container" style="flex: 1;background-color: white;overflow: auto;">
        <ng-container *ngIf="itemPannelPreviewData?.length">
            <item-view [itemData]="itemPannelPreviewData" [itemViewType]="'MANUAL'" (onItemChange)="$event?.changeType=='previousItem' && moveToPreviousLead();$event?.changeType=='nextItem' && moveToNextLead()" (onOtherItemOpen)="itemPreviewData=[$event]"></item-view>
        </ng-container>
        <ng-container *ngIf="!itemPannelPreviewData?.length">
            <div class="no-item-selected">
                <img src="../../assets/img/icons/empty.svg" />
                <span class="f-xl-m mt-1">No Item Selected</span> 
            </div>
        </ng-container>
    </div>
</ng-template>

<ng-template #addcustomleadsplus let-modal>
    <app-create-lead-modal [dashId]="selectedDashId"  (createLeadModalCloseEvent)="modal.dismiss();" [bucketSelect]="selectedGroupBy?.group_by=='bucket' ? currentGroup?._id : null" style="height: 100%;"></app-create-lead-modal>
  </ng-template>



