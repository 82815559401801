import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit ,} from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { CommonUtils } from 'app/services/CommonUtils.service';
import { DashboardUtilsService } from 'app/services/dashboard-utils.service';
import { HttpTransferService } from 'app/services/httpTransfer.service';

@Component({
  selector: 'ipaddress-cell',
  changeDetection:ChangeDetectionStrategy.OnPush,
  templateUrl: './ipaddress-cell.component.html',
  styleUrls: ['./ipaddress-cell.component.scss']
})
export class ipaddressCellComponent implements OnInit  {
  params:any={};
  editable: boolean = false
  itemInfo:any={}
  ipAddress: string;
  enableEditor:boolean=false;


  constructor(private cdr:ChangeDetectorRef,private httpTransfer: HttpTransferService,){
  }

  async ngOnInit() {
    this.onUpdate(this.params)
  }

  onUpdate(params: any){
    this.itemInfo=params?.item || {}
    this.editable=params?.editable
    this.ipAddress  = this.params?.value || null
    this.cdr.detectChanges()
  }

  updateIpAddress(ipAddress,event?){
    this.enableEditor=false
    if(!ipAddress?.length)ipAddress=null
    if(this.editable && this.params?.columnParams?.customField.settings == 'Manual' && this.params.value!=ipAddress){
      this.params.value = ipAddress?.length ? ipAddress : null
      this.params?.gridTableRef?.changedCellValue({value:this.params.value,customFieldType:true,params:this.params});
      this.onUpdate(this.params)
    }
   
  }

  pickIp(){
    this.httpTransfer.getIpAddress().subscribe((res:any)=>{
      this.updateIpAddress(res.ip)
    });
  }

}
