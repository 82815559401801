import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CommonUtils } from 'app/services/CommonUtils.service';
@Component({
  selector: 'sprint-cell',
  changeDetection:ChangeDetectionStrategy.OnPush,
  templateUrl: './sprint-cell.component.html',
  styleUrls: ['./sprint-cell.component.scss']
})
export class SprintCellComponent implements OnInit  {
  @Input() params : any={}; 
  itemInfo:any={}
  selectedSprint:any={}
  sprintArray:any={}
  
  constructor(private cdr:ChangeDetectorRef,private commonUtils : CommonUtils){}

  ngOnInit(): void {
    this.onUpdate()
  }

  onUpdate(params: any={}){
    this.itemInfo=this.params?.item || {}
    this.selectedSprint=(this.params?.globalParams?.boardData[this.itemInfo.dashboard_id]?.SPRINT || []).find(sprint=>sprint._id==this.params.value)
    this.cdr.detectChanges()
  }
  onSprintUpdate(value){
    if(this.params.value==value){return}
    this.params.value=value
    this.params?.gridTableRef?.changedCellValue({value:{[this.params?.field]:value},params:this.params});
    this.onUpdate()
  }


}
