import { Component, Renderer2, ElementRef, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { HttpTransferService } from 'app/services/httpTransfer.service';
import { environment } from 'environments/environment';
import { FacebookService, InitParams, LoginResponse } from 'ngx-facebook';

declare var FB: any;
declare var fbq: any;

@Component({
  selector: 'app-facebook-login',
  templateUrl: './facebook-login.component.html',
  styleUrls: ['./facebook-login.component.scss']
})
export class FacebookLoginComponent implements OnInit {
  @Input() type:any;
  @Input() dashId:any;
  @Input() itemCreationRule:any;
  @Input() timeInHrs:any;
  @Output() closeLogin : EventEmitter<any> = new EventEmitter();
  showLoader:boolean=false;
  constructor(private renderer: Renderer2, private el: ElementRef,public fb: FacebookService,public  httpTransfer : HttpTransferService) { 
    // Initialize the Facebook service with your App ID and Graph API version
  }

  ngOnInit(): void {
    this.loadFacebookSDK().then(() => {
      const initParams: InitParams = {
        appId: environment.fbAppID,
        xfbml: true,
        version: 'v18.0',
      };
      console.log(initParams)
      FB.init(initParams);
      console.log('Facebook SDK loaded and initialized');
    }).catch((error) => {
      console.error('Error loading Facebook SDK', error);
    });
    this.renderer.appendChild(this.el.nativeElement, this.loadSDK());
  }

  private loadFacebookSDK(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (window['FB']) {
        resolve(); // FB is already loaded
        return;
      }

      window['fbAsyncInit'] = () => {
        resolve(); // SDK loaded
      };

      const script = this.renderer.createElement('script');
      script.src = 'https://connect.facebook.net/en_US/sdk.js';
      script.onload = () => {
        if (window['FB']) {
          resolve();
        } else {
          reject(new Error('Failed to load Facebook SDK'));
        }
      };
      script.onerror = () => reject(new Error('Failed to load Facebook SDK'));

      this.renderer.appendChild(this.el.nativeElement, script);
    });
  }


  statusChangeCallback(response: any): void {
    console.log('statusChangeCallback');
    console.log(response);
    if (response.status === 'connected') {
      this.testAPI();
    } else {
      this.renderer.setProperty(document.getElementById('status'), 'innerHTML', 'Please log into this webpage.');
    }
  }

   checkLoginState(): void {
    FB.getLoginStatus((response: any) => {
      this.statusChangeCallback(response);
    });
  } 

  testAPI(): void {
    console.log('Welcome!  Fetching your information....');
    FB.api('/me', (response: any) => {
      console.log(response);
      console.log('Successful login for: ' + response.name);
      this.renderer.setProperty(document.getElementById('status'), 'innerHTML', 'Thanks for logging in, ' + response.name + '!');
    });
  }

  private loadSDK(): HTMLScriptElement {
    const script = this.renderer.createElement('script');
    script.src = 'https://connect.facebook.net/en_US/sdk.js';
    script.async = true;
    script.defer = true;
    script.crossOrigin = 'anonymous';

    return script;
  }

   loginWithChannel()
  {
    this.showLoader = true;
    if(this.type=='WHATSAPP'){this.launchWhatsAppSignup()}
    else{this.loginWithFacebookInstaWhatsapp()}
  }
  
  launchWhatsAppSignup() {
    // Launch Facebook login
    FB.login((response) => {
      console.log(response);
      if (response.authResponse) {
        const code = response.authResponse.code;
        this.createChannel(response.authResponse)
        // The returned code must be transmitted to your backend, 
  // which will perform a server-to-server call from there to our servers for an access token
      } else {
        this.showLoader = false;
        console.log('User cancelled login or did not fully authorize.');
      }
    }, {
      config_id: environment?.whatsappConfigId, //'3616196788623739', // configuration ID goes 
      response_type: 'code',
      override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
      extras: {
        setup: {
        }
      },
      auth_type: 'reauthenticate'
    });
  } 


  loginWithFacebookInstaWhatsapp(): void {
    FB.login((response) =>{
        console.log(response)
      if (response.authResponse) {
        // User is logged in
        console.log('Logged in with Facebook:', response);
        this.createChannel(response.authResponse)
      } else {
        // User canceled or did not grant permission
        this.showLoader = false;
        console.log('Facebook login canceled or failed');
      }
    },
    {
        auth_type: 'reauthenticate',
        config_id: this.type=='FACEBOOK' ? environment?.fbConfigId : this.type=="WHATSAPP" ? environment?.whatsappConfigId : environment?.instaConfigId, // configuration ID goes here
      });
  }

  createChannel(response){
    let params = {}
    params["is_enabled"]= true
    params["type"]= `${this.type}_EMBEDDED`//'`_EMBEDDED", 
    // params["create_item_settings"]= {
    //     "rule": "CREATE_ONLY_FIRST_TIME",
    // }
    params['create_item_settings']={
      "rule": this.itemCreationRule
    }
    if(this.itemCreationRule==='CREATE_AFTER_PERIOD'){
      params["create_item_settings"]["time_in_hours"]=this.timeInHrs  
    }
    if(this.type=='FACEBOOK' || this.type=="INSTAGRAM"){
    params["fb_user_token"]= response?.accessToken,  
    params["fb_user_id"]= response?.userID 
    }
    else if(this.type=="WHATSAPP"){
        params["code"]= response?.code
    }
    this.httpTransfer.createChannelQuery(this.dashId,params).subscribe(res=>{
        if(res.status=='201'){
        this.showLoader = false;
         this.closeLogin.emit()
        }
      })
  }
}
