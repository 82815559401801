<div  class="activity-wrapper" [class.activity-view]="viewType=='ActivityView'" [class.item-activity-view]="viewType=='ItemActivityView'">
  <div *ngIf="viewType!='ActivityHistoryView' && viewType!='bottomBarView'" #activityViewHeader class="activity-header">
    <ng-container *ngTemplateOutlet="activityHeader"></ng-container>
  </div>
  <div class="activity-body">
    <ng-container *ngIf="selectedActivity=='ALL' || selectedActivity=='BOTTOMBAR'">
      <ng-container *ngIf="!selectedActivityBoard && !selectedHistoryActivity">
        <ng-container *ngTemplateOutlet="allActivity"></ng-container>
      </ng-container>
      <ng-container *ngIf="selectedHistoryActivity">
        <app-timeline [dashId]="selectedHistoryActivity.activity_lead_obj?.dashboard_id" [leadInfo]="selectedHistoryActivity.activity_lead_obj" [viewType]="'ActivityHistoryView'" [historyInfo]="selectedHistoryActivity" (backFromHistory)="selectedHistoryActivity=null" class="w-100 h-100"></app-timeline>
      </ng-container>
      <ng-container *ngIf="selectedActivityBoard">
        <ng-container *ngTemplateOutlet="addNewActivity"></ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="selectedActivity=='INTERNAL'">
      <app-chat-messages  [dashId]="dashId" [selectedLeadObj]="leadInfo" [currentActiveTab]="'commentTab'" ></app-chat-messages>
    </ng-container>
    <ng-container *ngIf="selectedActivity=='EMAIL'">
      <app-email  [dashId]="dashId" [leadInfo]="leadInfo ? leadInfo : {_id:data?.current_lead_id}" [contactBoardId]="contactBoardId"></app-email>
    </ng-container>
    <ng-container *ngIf="selectedActivity=='CHANNELS' && selectedChannel">
      <app-communicate [dashId]="dashId" [selectedChannelObj]="selectedChannel" [chatMesssageGroupArr]="chatMesssageGroupArr" [leadInfo]="leadInfo" [chatCommentArr]="chatCommentArr"  [contactTypeBoardData]="contactTypeBoardData"></app-communicate>
    </ng-container>
  </div>
  <!-- subtask popup -->
  <ng-container *ngIf="filterPopupInfo?.showSubtaskFilter">
    <div class="side-task-filter">
      <app-subtask-connectboard-filter [currentLead]="filterPopupInfo.leadObj" [dashId]="filterPopupInfo.dashId" [activityInfo]="filterPopupInfo?.activityObj" [isPublicLead]="false"  (showSubtaskFilter)="filterPopupInfo={}" [connectedBoardIds]="filterPopupInfo?.connectedBoardId" [connectedBoardIdsList]="filterPopupInfo?.connectedItemsIds" [connectedBoardValuekey]="filterPopupInfo?.connectedBoardkey" [callType]="filterPopupInfo?.callType"></app-subtask-connectboard-filter>
    </div>
  </ng-container>
</div>

<ng-template #activityHeader>
  <div class="activity-tab-wrapper" [ngClass]="{'f-l-r' : viewType=='ActivityView','f-s-r': viewType=='ItemActivityView'}">
    <ng-container *ngTemplateOutlet="activityOptions;context:{options:headerOptions}"></ng-container>
    <ng-container *ngIf="viewType=='ItemActivityView' && moreHeaderOptions?.length>0">
      <div class="activity-tab" class="header-option add-new-btn" [ngbPopover]="otherActivity"  [placement]="['bottom-right','auto']" popoverClass="activity-popover">
        <div class="activity-tab-inner">
          <span><i class="fas fa-chevron-down p-0"></i></span>
        </div>
      </div>
      <ng-template #otherActivity>
        <div class="moreoptions-wrapper optionDetailBoxBody">
          <ng-container *ngTemplateOutlet="activityOptions;context:{options:moreHeaderOptions,isPopover:true}"></ng-container>
        </div>
      </ng-template>
    </ng-container>
  </div>
</ng-template>

<ng-template #activityOptions let-options="options" let-isPopover="isPopover">
  <ng-container *ngFor="let tab of options">
    <ng-container *ngIf="tab?.key!='ACTIVITY'">
      <div *ngIf="!tab?.dashboard_id" class="activity-tab" [ngClass]="{'active-tab': selectedActivity==tab?.key && !filterJson?.activityType || filterJson.activityType==tab?.key, 'optionDetailBox': isPopover}"  (click)="(leadInfo?.seq_id && changeTab(tab?.key,'type')) || (viewType==='ActivityView' && changeTab(tab?.key,'type'))">
        <div class="activity-tab-inner" [ngbPopover]="channelOptions" #channelOptionRef="ngbPopover"  [placement]="['bottom','auto']" triggers="manual">
          <ng-container class="d-flex" *ngIf="selectedActivity!=='CHANNELS' || tab?.key!=='CHANNELS'">
            <span><ng-container *ngTemplateOutlet="iconTemp;context:{type:(tab?.key || 'ACTIVITY')}"></ng-container></span>
            <span>{{tab?.name}}</span>
          </ng-container>
          <ng-container *ngIf="selectedActivity=='CHANNELS' && tab?.key=='CHANNELS' && selectedChannel"> 
              <div>
                <span style="position:relative"><ngx-avatars  [name]="selectedChannel.profile_pic ? selectedChannel.profile_pic : selectedChannel.chatUserName" size="20" initialsSize="2" textSizeRatio="2.2"></ngx-avatars></span>
              </div>
              <div class="h-100">
                <span>{{dashboardJsonChannel[selectedChannel?.channel_id]?.name | truncate:20}}</span>    
             <!--     <div class="d-flex">
                    <span><ng-container *ngTemplateOutlet="iconTemp;context:{type:(dashboardJsonChannel[selectedChannel.channel_id]?.type || 'ACTIVITY')}"></ng-container></span>
                    <small *ngIf="dashboardJsonChannel[selectedChannel?.channel_id]?.type=='WHATSAPP'">{{selectedChannel?.mobile}}</small>
                    <small *ngIf="dashboardJsonChannel[selectedChannel?.channel_id]?.type!='WHATSAPP'">{{dashboardJsonChannel[selectedChannel?.channel_id]?.name | truncate:20}}</small>    
                  </div> -->
              </div>
          </ng-container>
          <ng-container *ngIf="chatCommentArr?.length>1 && tab?.key=='CHANNELS'">
            <span (click)="$event?.stopPropagation();channelOptionRef?.open()"><i class="fas fa-chevron-down"></i></span>
          </ng-container>
        </div>
      </div>
      <div *ngIf="tab?.dashboard_id" class="activity-tab" [ngClass]="{'active-tab': selectedActivity==tab?.dashboard_id && !filterJson?.activityType || filterJson.activityType==tab?.dashboard_id, 'optionDetailBox': isPopover}"  (click)="leadInfo?.seq_id && changeTab(tab?.dashboard_id,'activity_type_board_id') || (viewType==='ActivityView' && changeTab(tab?.key,'type'))">
        <div class="activity-tab-inner">
          <span><ng-container *ngTemplateOutlet="iconTemp;context:{type:(tab?.board_type || 'ACTIVITY')}"></ng-container></span>
          <span>{{tab?.dashboard_name}}</span>
        </div>
      </div>  
    </ng-container>
    <ng-template #channelOptions>
      <div class="activity-option-wrapper" style="width:200px;row-gap: 10px;">
        <ng-container *ngFor="let option of chatMesssageGroupArr">
          <div class="channelDrop" [class.active-activity-option]="option?.chat_user_id===selectedChannel?.chat_user_id">
            <div>
              <ngx-avatars  [name]="option.profile_pic ? option.profile_pic : option.chatUserName" size="30" initialsSize="2" textSizeRatio="2.2"></ngx-avatars>
            </div>
            <div (click)="$event?.stopPropagation();changeChannel(option)">
               <div>{{dashboardJsonChannel[option?.channel_id]?.name | truncate:20}}</div>
               <div class="channelNameBox">
                <span><ng-container *ngTemplateOutlet="iconTemp;context:{type:(dashboardJsonChannel[option.channel_id]?.type || 'ACTIVITY')}"></ng-container></span>
                <span *ngIf="dashboardJsonChannel[option?.channel_id]?.type=='WHATSAPP'">{{option?.mobile}}</span>
                <span *ngIf="dashboardJsonChannel[option?.channel_id]?.type!='WHATSAPP'">{{dashboardJsonChannel[option?.channel_id]?.name | truncate:20}}</span>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-template>
  </ng-container>
</ng-template>

<ng-template #allActivity>
  <div class="chat-wrapper">
    <div  class="allChat-wrapper-header" *ngIf="viewType!='bottomBarView'">
      <div class="allChat-header-left">
        <ng-container *ngIf="viewType=='ActivityHistoryView'">
          <div class="header-option cursorPointer option-hover"  (click)="backFromHistory.emit(null)">
            <span><img  src="../../../assets/1x/newdash-arrow.svg " width="18px" height="18px"></span>
          </div>
          <div class="header-option">
            <span>History</span>
          </div>
          <div class="header-option">
            <span><i class="fas fa-chevron-right p-0"></i></span>
          </div>
          <div *ngIf="boardInfo[dashId]"  [class]="'board-info '+(chatConfig[boardInfo[dashId]?.DASHBOARD_INFO?.board_type]?.class || '')">
            <span><ng-container *ngTemplateOutlet="iconTemp;context:{type:(boardInfo[dashId]?.DASHBOARD_INFO?.board_type || 'ACTIVITY')}"></ng-container></span>
            <span>{{boardInfo[dashId]?.DASHBOARD_INFO?.dashboard_name}}</span>
          </div>
          <div class="header-option">
            <span class="text">By {{dashboardUsers[historyInfo?.created_by]?.name}}</span>
          </div>
        </ng-container>
      </div>
      <div *ngIf="viewType!='ActivityHistoryView'" class="allChat-header-right f-s-r">
        <div class="header-option" (click)="expendCollapse()">
          <span>{{ expandAll ? 'Expand All' : 'Collapsed All'}}</span>
          <span class="iconBox">
            <svg *ngIf="expandAll"  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1.5"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-down"><path d="M6 9l6 6l6 -6" /></svg>           
            <svg *ngIf="!expandAll" xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1.5"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-up"><path d="M6 15l6 -6l6 6" /></svg>
          </span>
        </div>
        <div class="header-option" [ngbPopover]="filterTemp"  [placement]="['bottom-right','auto']" #filterPopover="ngbPopover" popoverClass="filter-popover" [class.active-option]="filterPopover?.isOpen() || loaderConfig.filterCommentloader" autoClose="outside" (shown)="onPopoverShown()" (hidden)="onPopoverHidden()">
          <span>
            <ng-container *ngIf="!loaderConfig?.filterCommentloader">
              <app-dashboard-icon [size]="16"  [name]="'Filter'" ></app-dashboard-icon>
            </ng-container>
            <ng-container *ngIf="loaderConfig?.filterCommentloader">
              <div class="spinner-border" role="status" style="width: 15px;height: 15px;"><span class="sr-only"></span></div>
            </ng-container>
          </span>
          <span>{{ viewType=='ActivityView' ? 'Activity filter' : 'Filter' }}</span>
        </div>
        <div *ngIf="viewType=='ItemActivityView' && activityTypeBoardList?.length>0 && !isReadOnly && !['INTERNAL','EMAIL','EMAIL','ACTIVITY_LOGS']?.includes(filterJson?.activityType)" class="header-option add-new-btn commonBtn-30" [ngbPopover]="(filterJson?.activityType=='ALL' || !filterJson?.activityType) && activityOptionsTemp"  placement="bottom-right" #popoverRef="ngbPopover" popoverClass="activity-popover" (click)="filterJson?.activityType!='ALL' && filterJson?.activityType &&  openNewActivityPannel()">
          <div class="commonBtnBox-Child-1">
            <span class="f-l-r">Add</span>
            <span class="iconBox" *ngIf="!loaderConfig?.addNewPopoverloader && (filterJson?.activityType=='ALL' || !filterJson?.activityType)">
              <ng-container *ngIf="!popoverRef?.isOpen()"><svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1.5"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-down"><path d="M6 9l6 6l6 -6" /></svg></ng-container> 
              <ng-container *ngIf="popoverRef?.isOpen()"><svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1.5"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-up"><path d="M6 15l6 -6l6 6" /></svg></ng-container>
            </span>
          </div>
          <span *ngIf="loaderConfig?.addNewPopoverloader">
            <div class="spinner-border" role="status" style="width: 15px;height: 15px;"><span class="sr-only"></span></div>
          </span>
        </div>
      </div>
    </div>
    <div class="allChat-wrapper-body" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="getCommentsForLead(false,true)" [scrollWindow]="false">
      <ng-container *ngIf="!loaderConfig?.commentloader">
        <ng-container *ngFor="let list of activityDataList ">
          <ng-container *ngIf="list?.activityList?.length>0">
            <div  class="activity-card-wrapper-header">
              <div class="activity-card-wrapper-header-inner f-l-b" >
                <span style="width: 98%;white-space: nowrap;text-overflow: ellipsis;">{{list?.title}}</span>
                <span *ngIf="list.key=='upcoming'" class="cursorPointer py-1" (click)="list.collapsed=!list.collapsed">
                  <i *ngIf="list?.collapsed" class="fas fa-chevron-down p-0"></i>
                  <i *ngIf="!list?.collapsed" class="fas fa-chevron-up p-0"></i>
                </span>
              </div>
            </div>
            <div *ngIf="!list.collapsed" class="activity-card-wrapper-body">
              <ng-container *ngFor="let activity of list.activityList;let i=index">
                <ng-container *ngIf="!['THREAD','MESSAGE_GROUP'].includes(activity?.type)">
                  <ng-container *ngTemplateOutlet="cardView;context:{data:activity}"></ng-container>
                </ng-container>
                <ng-container *ngIf="['THREAD','MESSAGE_GROUP'].includes(activity?.type) && activity?.comments?.length">
                      <ng-container *ngTemplateOutlet="cardView;context:{data:activity?.comments[0]}"></ng-container>
               </ng-container> 
               </ng-container>
            </div>
            <div *ngIf="list?.enableViewMore" class="activity-card-wrapper-footer">
              <div class="d-flex align-items-center justify-content-end">
                <diV class="viewmore-btn" (click)="getCommentsForLead(false,true,true)">
                  <span *ngIf="loaderConfig.viewMoreLoader"><div class="spinner-border" role="status" style="width: 15px;height: 15px;"><span class="sr-only"></span></div></span>
                  <span>View More</span>
                </diV>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!loaderConfig?.commentloader && (!activityDataList?.length || activityDataList?.length==1 && activityDataList[0]?.key=='upcoming' && !activityDataList[0]?.activityList?.length)">
        <div  class="d-flex justify-content-center align-items-center w-100 h-100">
          <diV class="text-center">
            <img  src="../../../assets/img/icons/empty.svg" />
            <diV class="mt-1 text-align-center">{{'editcustom.noRecordFound' | translate}}</diV>
          </diV>
        </div>
      </ng-container>
      <ng-container *ngIf="loaderConfig?.commentloader">
        <app-shimmer-loader [type]="'CardField'" [animation]="'pulse'" [rowGap]="0" [columnGap]="4" [cardCount]="8" [header]="false" [count]="1" [width]="'100%'" [height]="110" [radius]="10"></app-shimmer-loader> 
      </ng-container>
    </div>
    <ng-container *ngIf="loaderConfig.scrollCommentloader">
      <div  class="allChat-wrapper-footer d-flex justify-content-center align-items-center">
        <ng-container *ngFor="let item of [].constructor(3)">
          <div class="spinner-grow text-dark" role="status" style="width: 15px;height: 15px;">
            <span class="sr-only">Loading...</span>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #activityOptionsTemp>
  <div class="activity-option-wrapper">
    <ng-container *ngFor="let board of activityTypeBoardList">
      <div class="activity-option" (click)="loaderCreateACtivityView(board)">
        <span><ng-container *ngTemplateOutlet="iconTemp;context:{type:(board?.board_type || 'ACTIVITY')}"></ng-container></span>
        <span>{{board?.dashboard_name}}</span>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #addNewActivity>
  <div class="chat-wrapper" style="background-color: white;">
    <div class="allChat-wrapper-header f-s-r">
      <div class="allChat-header-left">
        <div class="header-option">
          <span (click)="selectedActivityBoard=null" class="mr-1 cursorPointer"><img  src="../../../assets/1x/newdash-arrow.svg " width="18px" height="18px"></span>
          <span>Add New</span>
        </div>
        <div class="header-option">
          <span><i class="fas fa-chevron-right p-0"></i></span>
        </div>
        <div class="header-option">
          <span><ng-container *ngTemplateOutlet="iconTemp;context:{type:(board?.board_type || 'ACTIVITY')}"></ng-container></span>
          <span>{{selectedActivityBoard?.dashboard_name}}</span>
        </div>
      </div>
    </div>
    <div class="allChat-wrapper-body activity-fields">
      <ng-container *ngIf="!loaderConfig?.createActivityViewLoader">
        <app-edit-custom
          #editCustomRef
          [activityBoardId]="dashId"
          [dashId]="selectedActivityBoard.dashboard_id"
          [addTitile]="false"
          (isLeadCreated)="currentCreateActivityData['isValidated'] = $event"
          (showConnectedBoardFilter)="updateInnerConnected($event,null,editCustomRef)"
          (onEdit)="currentCreateActivityData['custom_fields'] = $event">
        </app-edit-custom>
      </ng-container>
      <ng-container *ngIf="loaderConfig?.createActivityViewLoader">
        <app-shimmer-loader
        [type]="'Avatar'" [lineWidth]="['80px','auto','auto']" [height]="10" [rowGap]="6" [count]="8" [columnGap]="10" [circleWidth]="75" [circleHeight]="15" [circleRadius]="8"
        [externalStyle]="{'padding':'4px 2px'}">
        </app-shimmer-loader>
      </ng-container>
    </div>
    <div class="allChat-wrapper-footer">
      <button (click)="createActivity()" class="create-btn" [class.create-btn-disabled]="!currentCreateActivityData['isValidated']">
        <ng-container *ngIf="!loaderConfig.createCommentloader;else loader">{{'common.create' | translate}}</ng-container> 
        <ng-template #loader>
          <div class="spinner-border mr-1" role="status" style="width: 15px;height: 15px;"><span class="sr-only"></span></div>
          {{'common.creating' | translate}}
        </ng-template> 
      </button>
    </div>
  </div>
</ng-template>

<ng-template #filterTemp>
<div class="filter-view">
  <div class="filter-view-body">
    <div *ngIf="(filterJson?.activityType=='ALL' || !filterJson?.activityType)" class="filter-option">
      <div class="f-xl-m">Activities</div>
      <div>
        <ng-select  [virtualScroll]="true" [items]="activityTypes" [multiple]="true" [closeOnSelect]="false" bindLabel="name"
        bindValue="key" [(ngModel)]="filterJson['type']" (clear)="filterJson['type']=['ALL']"  (ngModelChange)="checkForFilterSelected(filterJson['type'])">
        </ng-select>
      </div>
    </div>
    <div class="filter-option">
      <div class="f-xl-m">Action By User</div>
      <div>
        <ng-select  [virtualScroll]="true"  [items]="userList" [multiple]="true" bindLabel="name" [closeOnSelect]="true"
        bindValue="_id" placeholder="Select Schedule" [(ngModel)]="filterJson['created_by']">
          <ng-template ng-option-tmp let-item="item" let-index="index">
            <div class="d-flex align-items-center">
                <div class="d-flex">
                  <ngx-avatars class="mr-1" [name]="item?.name" [src]="item?.image_url" size="25" initialsSize="2" ></ngx-avatars>
                </div>  
                <div class="d-flex flex-column">
                  <span>{{item?.name}}</span>
                  <small class="my-0">{{item?.email}}</small>
                </div>
            </div>
          </ng-template>
        </ng-select>
      </div>
    </div>
    <div class="filter-option">
      <div class="f-xl-m">Date Range</div>
      <div class="date-box-wrapper">
        <div class="form-date date-box" [ngbPopover]="datePopoverFrom" #datePopoverFromRef="ngbPopover" popoverClass="datePickerPopover" placement="auto" autoClose="outside">
          {{filterJson?.activityTime?.from ? (filterJson?.activityTime?.from | dateCalcuate:"dateTime") : 'From' }}
          <span *ngIf="filterJson?.activityTime?.from" class="remove-date" (click)="$event?.stopPropagation();filterJson.activityTime.from=null">
            <img src="../../../../assets/common-use-icon-20dp/close.svg">
          </span>
        </div>
        <div class="to-date date-box" [ngbPopover]="datePopoverTo" #datePopoverToRef="ngbPopover" popoverClass="datePickerPopover" placement="auto" autoClose="outside">
          {{filterJson?.activityTime?.to ? (filterJson?.activityTime?.to | dateCalcuate:"dateTime") : 'to' }}
          <span *ngIf="filterJson?.activityTime?.to" class="remove-date" (click)="$event?.stopPropagation();filterJson.activityTime.to=null">
            <img src="../../../../assets/common-use-icon-20dp/close.svg">
          </span>
        </div> 
        <ng-template #datePopoverFrom>
          <date-picker 
          [dateRange]="{'from':filterJson?.activityTime?.from}"
          [dateConfig]="{'maxDate':filterJson?.activityTime?.to}"
          [showHeader]="false" [showClearOrSaveBtn]="false"
          [focusDate]="filterJson?.activityTime?.from"
          (onDateSelect)="filterJson.activityTime.from= $event;datePopoverFromRef?.close()">
          </date-picker>
        </ng-template>
        <ng-template #datePopoverTo>
          <date-picker 
          [dateRange]="{'to':filterJson?.activityTime?.to}"
          [showHeader]="false" [showClearOrSaveBtn]="false"
          [dateConfig]="{'minDate':filterJson?.activityTime?.from}"
          [focusDate]="filterJson?.activityTime?.to"
          (onDateSelect)="filterJson.activityTime.to= $event;datePopoverToRef?.close()">
          </date-picker>
        </ng-template> 
      </div>
    </div>
  </div>
  <div class="filter-view-footer">
    <div class="filter-option d-flex">
      <div class="cancel-btn" (click)="clearFilter();filterPopover?.close();onPopoverShown()">
        {{'common.clear' | translate}}
      </div>
      <div class="apply-btn" (click)="loaderConfig.filterCommentloader=true;getCommentsForLead(true);filterPopover?.close();onPopoverShown()">
        {{'dashboard.filter.applyFilter' | translate}}
      </div>
    </div>
  </div>
</div>
</ng-template>

<ng-template #activityLog let-data="data">
  <span class="readLessLastMsg f-l-r">
    <div *ngIf="(commentsIndex !== showMoreCommentsIndexValue && showMoreCommentsIndexValue !== null) || (!expandAllLeadComments && showMoreCommentsIndexValue === null)">
      <span *ngIf="data.comments.length == 1" class="mb-0 mr-2 leadSingleComment" [innerHTML]="data.comments[0].comments "></span> 
                            <span *ngIf="data.comments.length > 1" class="mb-0 mr-2 leadSingleComment" [innerHTML]="data.comment"></span>
      <span *ngIf="data.comments.length > 1 || fileTypeConstants.includes(data.comments[0]?.type)">
        <span class="readMoreLess " (click)="showMoreComments(commentsIndex)">Read more</span>
      </span>

      <span *ngIf="data.previous_board" style="color: #3BA4DA;font-size: 12px;" >
    click info to see previous board activity   
    <span style="margin-left: 5px;cursor: pointer;" (click)="$event.stopPropagation()"
      placement="auto" leadTimelinePopover="ngbPopover" container="body" [ngbPopover]=" leadTimelineTemplate" [autoClose]="'outside'">
              <app-dashboard-icon [size]="14"  [name]="'Info'" [iconJson]="attributesIcons"></app-dashboard-icon>
            </span>
  </span>
  <ng-template #leadTimelineTemplate>
    <div class="timelinePopover">
        <app-timeline dashId={{data.previous_board}} item_id={{item_id}}
                [height]="25" dashboard_initial={{selectedDashNameInitial}}
                              [isHeaderFilterRequired]="false"
                              [isHeaderQuickFilterRequired]="false"
                              [isHeaderRequired]="false"
                              [isRightSideFilterRequired]="false">
        </app-timeline>
      </div>
  </ng-template>
    </div>
    <ng-container *ngIf="commentsIndex === showMoreCommentsIndexValue || expandAllLeadComments">
      <ng-container *ngFor="let value of data.comments">
        <ng-container *ngIf='value.type == "IMAGE"'>
          <div class="mediaDiv">
            <span [innerHTML]="value.comments"></span> <br>
          </div>
        </ng-container>
        <ng-container *ngIf='value.type == "MULTIIMAGE"'>
          <div class="mediaDiv">
            <span>
              <span> {{value.comments}} </span> <br>
            </span>
            <div class="mr-2 mb-2 mt-1 imgOpenStyle" *ngFor='let url of value.url'>
              <img [src]="url" class="image" alt="image">
              <div class="imgSearchBtn"><i class="fa fa-arrows-alt"
                  (click)="openImageInPopup(imageContentModal, url)"></i></div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf='value.type == "MULTIVIDEO"'>
          <div class="mediaDiv">
            <span>
              <span> {{value.comments}} </span> <br>
            </span>
            <ng-container>
              <span>
                <span class="mr-2 mb-2 pt-1" *ngFor='let url of value.url'>
                  <video class="image w-25" controls>
                    <source [src]="url" type="video/mp4">
                  </video>
                </span>
              </span>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf='value.type == "MULTIAUDIO"'>
          <div class="mediaDiv">
            <span>
              <span> {{value.comments}} </span> <br>
            </span>
            <ng-container>
              <span>
                <span class="mr-2 mb-2 pt-1" *ngFor='let url of value.url'>
                  <audio class="audio w-70" controls>
                    <source [src]="url" type="audio/mp4">
                  </audio>
                </span>
              </span>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf='value.type == "MULTIPLEFILE"'>
          <div class="mediaDiv">
            <span>
              <span> {{value.comments}}</span> <br>
            </span>
            <ng-container>
              <span class="mr-2 my-2" *ngFor='let url of value.url' (click)="openFileModal(fileContentModal, url)">
                <app-dashboard-icon [name]="value.ext" [iconJson]="mediaIcons" size="98"></app-dashboard-icon>
                <!-- <img src="./../../../../assets/1x/doc.png" class="pdfIcon commentDoc"
                  (click)="openFileModal(fileContentModal, url)" /> -->
              </span>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf='value.type == "DATE"'>
          <div class="mediaDiv ">
            <span>
              <span> {{value.comments}} </span> <br>
            </span>
            <ng-container>
              <span class="mr-2 my-2" *ngFor='let url of value.url'>
                <img src="./../../../../assets/1x/doc.png" class="pdfIcon commentDoc"
                  (click)="openFileModal(fileContentModal, url)" />
              </span>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf='value.type == "Simple_Message" '>
          <div id="textComment" class="pb-2" [innerHTML]="value.comments "></div>
        </ng-container>
      </ng-container>
      <span class="readMoreLess" *ngIf="data.comments.length > 1 && !expandAllLeadComments"
        (click)="showLessComments()">Read Less
      </span>
    </ng-container>
  </span>
</ng-template>

<ng-template #iconTemp let-type="type">
  <ng-container *ngIf="type=='TASK_ACTIVITY'">
    <app-dashboard-icon [size]="20"  [isSvg]="true" [color]="chatConfig[type]?.color" [name]="'Task'" [iconJson]="activityIcons"></app-dashboard-icon>
  </ng-container>
  <ng-container *ngIf="type=='MEETING_ACTIVITY'">
    <app-dashboard-icon [size]="20" [isSvg]="true" [color]="chatConfig[type]?.color" [name]="'Meeting'" [iconJson]="activityIcons"></app-dashboard-icon>
  </ng-container>
  <ng-container *ngIf="type=='CALL_ACTIVITY'">
    <app-dashboard-icon [size]="20"  [isSvg]="true" [color]="chatConfig[type]?.color" [name]="'Call'" [iconJson]="activityIcons"></app-dashboard-icon>
  </ng-container>
  <ng-container *ngIf="type=='CUSTOM_ACTIVITY_TYPE' || type=='ACTIVITY' || type=='ALL'">
    <app-dashboard-icon [size]="20"  [isSvg]="true" [color]="chatConfig[type]?.color" [name]="'Custom'" [iconJson]="activityIcons"></app-dashboard-icon>
  </ng-container>
  <ng-container *ngIf="type=='MESSAGE_ACTIVITY'">
    <app-dashboard-icon [size]="20"  [isSvg]="true" [color]="chatConfig[type]?.color" [name]="'Message'" [iconJson]="activityIcons"></app-dashboard-icon>
  </ng-container>
  <ng-container *ngIf="type=='ACTIVITY_LOGS'">
    <app-dashboard-icon [size]="20"  [isSvg]="true" [color]="chatConfig[type]?.color" [name]="'Logs'" [iconJson]="activityIcons"></app-dashboard-icon>
  </ng-container>
  <ng-container *ngIf="type=='WHATSAPP'">
    <app-dashboard-icon [size]="16" [name]="type" [iconJson]="activityIcons"></app-dashboard-icon>
  </ng-container>
  <ng-container *ngIf="type=='INSTAGRAM'">
    <app-dashboard-icon [size]="20"  [name]="type" [iconJson]="activityIcons"></app-dashboard-icon>
  </ng-container>
  <ng-container *ngIf="type=='TELEGRAM'">
    <app-dashboard-icon [size]="20"  [isSvg]="true" [color]="chatConfig[type]?.color" [name]="type" [iconJson]="activityIcons"></app-dashboard-icon>
  </ng-container>
  <ng-container *ngIf="type=='VIBER'">
    <app-dashboard-icon [size]="20"  [name]="type" [iconJson]="activityIcons"></app-dashboard-icon>
  </ng-container>
  <ng-container *ngIf="type=='INTERNAL' || type=='CHANNELS'">
    <app-dashboard-icon [size]="20" [isSvg]="true" [color]="chatConfig[type]?.color" [name]="'CHANNELS'" [iconJson]="activityIcons"></app-dashboard-icon>
  </ng-container>
  <ng-container *ngIf="type=='EMAIL'">
    <app-dashboard-icon [size]="20"  [isSvg]="true" [color]="chatConfig[type]?.color" [name]="'Email'" [iconJson]="activityIcons"></app-dashboard-icon>
  </ng-container>
</ng-template>

<ng-template #activityEdititorTemp let-data="data" let-editPopoverRef="editPopoverRef" let="in">
  <!-- activity edit options -->
  <ng-container *ngIf="!data?.associatePannel && !data?.deleteConfirmation">
    <div class="edit-option-wrapper">
      <div  class="edit-option" (click)="openAssociatePannel(data)">
        <span></span>
        <span>Associate</span>
      </div>
      <div class="edit-option" *ngIf="['EXTERNAL_OUTBOUND','EXTERNAL_INBOUND']?.includes(data?.type)" (click)="editPopoverRef?.close();closeConversation(data)">
        <span></span>
        <span>Close Conversation</span>
      </div>
      <div class="edit-option" *ngIf="!['EXTERNAL_OUTBOUND','EXTERNAL_INBOUND','EMAIL']?.includes(data?.type)" (click)="editPopoverRef?.close();selectedHistoryActivity=data">
        <span></span>
        <span>History</span>
      </div>
      <div *ngIf="!isReadOnly && viewType=='ItemActivityView' && !['EXTERNAL_OUTBOUND','EXTERNAL_INBOUND','EMAIL']?.includes(data?.type)" class="edit-option" (click)="data.deleteConfirmation=true">
        <span></span>
        <span>Remove</span>
      </div>
    </div>
  </ng-container>
  <!-- activity associate edit pannel -->
  <ng-container *ngIf="data?.associatePannel">
    <div class="associate-edit-wrapper">
      <div class="associate-editor-header d-flex" style="justify-content:space-between">
        <div class="left-header-wrapper">
          <span (click)="data.associatePannel=false" class="edit-button header-field" style="width: 30px;"><img  src="../../../assets/1x/newdash-arrow.svg " width="16px" height="16px"></span>
          <span class="f-xl-m header-field">Associate</span>
        </div>
        <div class="righr-header-wrapper">
          <span *ngIf="!isReadOnly" style="color:#20a8d8" class="edit-button f-l-r header-field" [ngbPopover]="addNewAssociateTemp" [class.edit-active]="addNewAssociatePopoverRef?.isOpen()"  placement="bottom-right" #addNewAssociatePopoverRef="ngbPopover" popoverClass="activity-popover" autoClose="outside">
            Add New
          </span>
          <ng-template #addNewAssociateTemp>
            <div class="associate-addNew-option-wrapper">
              <div class="associate-addNew-option-header f-l-b">
                Select Board
              </div>
              <div class="edit-option-wrapper my-1 w-100">
                <ng-container  *ngFor="let boardInfo of connectedBoardInfoList">
                  <span *ngIf="boardInfo?.dashboard_name" class="edit-option" (click)="openConnectAssociateItemPanel(boardInfo,data);editPopoverRef?.close()">
                    {{boardInfo?.dashboard_name}}
                  </span>
                </ng-container>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
      <div class="associate-editor-body" >
        <ng-container *ngIf="data?.associateItemData && !data.loadAssociatePannel">
          <ng-container *ngFor="let board of data?.associateItemData | keyvalue">
            <div class="p-1" *ngIf="boardInfo[board?.key]?.DASHBOARD_INFO?.dashboard_name">
              <div class="associate-board-name text-field f-l-b">{{boardInfo[board?.key]?.DASHBOARD_INFO?.dashboard_name}}</div>
              <div class="associate-list">
                <ng-container *ngFor="let item of board?.value">
                  <div class="associate-item-data d-flex">
                    <input type="checkbox" *ngIf="data?.message_group_item_id !== item?._id" class="cursorPointer" [checked]="true" class="mr-1" (change)="updateAssociateItem(data,item,$event)"  [readonly]="isReadOnly || data?.lead_id?.length<2" [disabled]="isReadOnly || data?.lead_id?.length<2" />
                    <span class="text-field">{{item?.title}}</span>
                  </div>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="data.loadAssociatePannel">
          <app-shimmer-loader
          [type]="'Avatar'" [lineWidth]="['80%']" [height]="13" [rowGap]="6" [count]="10" [columnGap]="4" [circleWidth]="13"
          [externalStyle]="{'padding':'0px'}">
          </app-shimmer-loader>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <!-- remove confimation -->
  <ng-container *ngIf="data?.deleteConfirmation">
    <div class="edit-option-wrapper">
      <div class="p-2">
        {{'activity.activityDeleteConfirmation' | translate}}
      </div>
      <div class="justify-content-center d-flex align-items-center" style="column-gap: 20px;">
        <span class="btn btn-success" style="border-radius: 10px;" (click)="editPopoverRef?.close();deleteComment(data?._id,leadInfo?._id,dashId)">{{'multiSelect.yes' | translate}}</span>
        <span class="btn btn-danger" style="border-radius: 10px;" (click)="editPopoverRef?.close()" >{{'multiSelect.no' | translate}}</span> 
      </div>
    </div>
  </ng-container>
</ng-template>



<ng-template #imageContentModal let-modal>
  <div class="modal-header image-modal-header " >
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body image-modal-body" >
    <img [src]="imageUrlForModal" alt="Img" width="100%" height="100%" style="object-fit: contain">
  </div>
</ng-template>
<ng-template #fileContentModal let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="fileUrlForModal!=''">
    <ngx-doc-viewer [url]="fileUrlForModal" [viewer]="commonUtils.applyDocViewerBasedOnFileEXtension(fileUrlForModal)" style="width:100%;height:80vh;"></ngx-doc-viewer>
  </div>
</ng-template>


<ng-template #cardView let-data="data">
  <div class="chat-card" [class.collapse-card]="data?.collapse">
    <div class="chat-card-header">
      <div class="chat-card-header-left">
        <ng-container *ngIf="data?.type=='ACTIVITY'">
          <div [class]="'d-flex activity-type '+(chatConfig[boardInfo[data?.activity_lead_obj?.dashboard_id]?.DASHBOARD_INFO?.board_type]?.class || '')" >
            <span class="chat-activity-icon"><ng-container *ngTemplateOutlet="iconTemp;context:{type:(boardInfo[data?.activity_lead_obj?.dashboard_id]?.DASHBOARD_INFO?.board_type || 'ACTIVITY')}"></ng-container></span>
            <span class="text chat-activity-type-name" [style.color]="chatConfig[boardInfo[data?.activity_lead_obj?.dashboard_id]?.DASHBOARD_INFO?.board_type]?.color">{{boardInfo[data?.activity_lead_obj?.dashboard_id]?.DASHBOARD_INFO?.dashboard_name}}</span>
          </div>
        </ng-container>
        <ng-container *ngIf="data?.type!='ACTIVITY'">
          <div [class]="'d-flex activity-type '+(chatConfig[data?.type]?.class || 'activityColorView')">
            <ng-container *ngIf="['EXTERNAL_OUTBOUND','EXTERNAL_INBOUND']?.includes(data?.type);else otherActivity">
              <span class="chat-activity-icon"><ng-container *ngTemplateOutlet="iconTemp;context:{type:(chatConfig[data.type]?.iconsExist?.includes(dashboardJsonChannel[data?.channel_id]?.type) ? dashboardJsonChannel[data?.channel_id]?.type : 'CHANNELS')}"></ng-container></span>
              <span class="text chat-activity-type-name" [style.color]="chatConfig[data?.type]?.color">{{(dashboardJsonChannel[data?.channel_id]?.type || chatConfig[data?.type]?.name) | titlecase }}</span>
            </ng-container>
            <ng-template #otherActivity>
              <span class="chat-activity-icon"><ng-container *ngTemplateOutlet="iconTemp;context:{type:(data?.type || 'ACTIVITY')}"></ng-container></span>
              <span class="text chat-activity-type-name">{{chatConfig[data?.type]?.name}}</span>
            </ng-template>
          </div>
        </ng-container>
        <div class="d-flex chat-by-name">
          <span class="text f-s-r">By {{dashboardUsers[data?.created_by]?.name}}</span>
        </div>
      </div>
      <div class="chat-card-header-right">
        <div *ngIf="taskBucketInfo[data?.activity_lead_obj?.dashboard_id]  && boardInfo[data?.activity_lead_obj?.dashboard_id]?.DASHBOARD_INFO?.board_type=='TASK_ACTIVITY'" class="trigger-btn" (click)="changeMarkDone(data?.activity_lead_obj)">
          <span *ngIf="!taskBucketInfo[data?.activity_lead_obj?.dashboard_id][data?.activity_lead_obj?.bucket_id]?.is_destination" class="final-check"></span>
          <span *ngIf="taskBucketInfo[data?.activity_lead_obj?.dashboard_id][data?.activity_lead_obj?.bucket_id]?.is_destination" class="final-check-active">
            <i class="fas fa-check p-0"></i>
          </span>
          <span>Mark Done</span>
        </div>
        <div class="text activity-date" [class.d-none]="editPopoverRef?.isOpen() || updateDatePopover.isOpen()" >{{data?.activity_time | date:'dd/MM/yyyy hh:mm'}}</div>
        <div class="activity-options-wrapper" [class.d-flex]="editPopoverRef?.isOpen() || updateDatePopover.isOpen()">
          <span [class.d-none]="!checkBaseUrl || checkBaseUrl && data?.type!='ACTIVITY_LOGS'" class="activity-option" [ngbPopover]="!isReadOnly && startdatePopover" placement="auto"   #updateDatePopover="ngbPopover" [autoClose]="'outside'" container="body" triggers="manual" (click)="checkBaseUrl && data?.type=='ACTIVITY_LOGS' && updateDatePopover?.open()" popoverClass="activity-edit-option">
              <i class="fas fa-calendar"></i>
          </span>
          <ng-template #startdatePopover>
            <div (click)="$event.stopPropagation()">
              <date-picker (click)="$event.stopPropagation()"
              [dateRange]="{'from':data.activity_time}"
              [focusDate]="data.activity_time"
              [dateConfig]="{'maxDate':{time:maxDate?.getTime()}}"
              (onDateSave)="updateTimeLine($event,data,i);updateDatePopover.close()"
              (onDateClear)="data.activity_time = null;updateDatePopover.close()">
              </date-picker>
            </div>
          </ng-template> 
          <span *ngIf="['INTERNAL','EXTERNAL_OUTBOUND','EXTERNAL_INBOUND','EMAIL']?.includes(data?.type)" class="activity-option" (click)="data.expandChannel=!data?.expandChannel">
            <app-dashboard-icon [size]="20"  [name]="data.expandChannel ? 'commentCollapsed' : 'commentExpand'" [iconJson]="itemIcons"></app-dashboard-icon>
          </span>
          <span [class.d-none]="!['ACTIVITY','EXTERNAL_OUTBOUND','EXTERNAL_INBOUND','EMAIL']?.includes(data?.type)" class="activity-option" [ngbPopover]="editPopover"  [placement]="['bottom-right','auto']" #editPopoverRef="ngbPopover" popoverClass="activity-edit-option" [class.active-option]="editPopoverRef?.isOpen()" (click)="data.associatePannel=false;data.deleteConfirmation=false" autoClose="outside" container="body"><i class="fas fa-ellipsis-v p-0"></i></span>
          <span class="activity-option" (click)="data.collapse=!data?.collapse">
            <i *ngIf="!data?.collapse" class="fas fa-chevron-up"></i>
            <i *ngIf="data?.collapse" class="fas fa-chevron-down"></i>
          </span>
          <ng-template #editPopover>
            <ng-container *ngTemplateOutlet="activityEdititorTemp;context:{data:data,editPopoverRef:editPopoverRef}"></ng-container>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="chat-card-body" *ngIf="!data?.collapse">
      <ng-container *ngIf="data?.type=='ACTIVITY' && data?.activity_lead_obj">
        <div class="d-flex">
          <div class="w-100 activity-fields">
            <app-edit-custom 
            [activityBoardId]="dashId"
            [dashId] = "data?.activity_lead_obj?.dashboard_id" 
            [addTitile] = "false" 
            [editTitle] = "data?.activity_lead_obj?.title"
            [leadId] = "data?.activity_lead_obj?._id"
            [bucketArray]="bucketArray || []"
            [viewOnly]="viewType=='ActivityView'"
            (onUpdate)="leadInfo && updateActivity({activity_lead_obj:$event},dashId,leadInfo?._id,data?._id)"
            (onItemOpen)="onItemOpen.emit($event)"
            (showConnectedBoardFilter)="updateInnerConnected($event,data)"
            [leadObj]="data?.activity_lead_obj">
            </app-edit-custom>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="['INTERNAL','EXTERNAL_OUTBOUND','EXTERNAL_INBOUND']?.includes(data?.type)">
        <ng-container *ngIf="!data?.expandChannel">
          <!--message-->
          <span *ngIf="data?.message_data?.text && !data?.message_data?.attachments?.length" [innerHTML]="data?.message_data?.text | safehtml"></span>
          <!--button-->
          <div class="buttonContainer" *ngIf="data?.message_data?.buttons?.length">
            <span style="height:calc(100% - 32px)" *ngIf="data?.message_data?.buttons[0]?.image"><img class="btnImage" [src]="data?.message_data?.button[0]?.image?.file_url"></span>
            <button class="botButton" disabled="true">{{data?.message_data?.buttons[0].text}}</button>
          </div>
          <!--checkbox-->
          <div class="buttonContainer"*ngIf="data?.message_data?.checkboxes?.length" >
            <span style="height:calc(100% - 32px)" *ngIf="checkbox?.image"><img class="btnImage" [src]="data?.message_data?.checkboxes[0]?.image?.file_url"></span>
            <span class="botCheckbox"><input type ="checkbox" disabled="true"/>{{data?.message_data?.checkboxes[0]?.text}}</span>
          </div>
          <!--Attachments-->
          <div class="chatLeftMid" *ngIf="data?.message_data?.attachments?.length">
            <div class="d-flex align-items-center" *ngIf="data?.message_data?.attachments[0]?.file_type=='IMAGE'"><app-dashboard-icon class="d-flex align-items-center" [isSvg]="true" [size]="16" [name]="'SingleImage'" [iconJson]="lxAttributesIcons" [color]="'#666666'"></app-dashboard-icon><span>Image</span></div>
            <div class="d-flex align-items-center" *ngIf="data?.message_data?.attachments[0]?.file_type=='VIDEO'"><app-dashboard-icon class="d-flex align-items-center" [isSvg]="true" [size]="16" [name]="'SingleVideo'" [iconJson]="lxAttributesIcons" [color]="'#666666'"></app-dashboard-icon><span>Video</span></div>
            <div class="d-flex align-items-center" *ngIf="data?.message_data?.attachments[0]?.file_type!='IMAGE' && data?.message_data?.attachments[0]?.file_type!='VIDEO'"><app-dashboard-icon class="d-flex align-items-center" [isSvg]="true" [size]="16" [name]="'SingleFile'" [iconJson]="lxAttributesIcons" [color]="'#666666'"></app-dashboard-icon><span>Document</span></div>
          </div>
        </ng-container>
        <ng-container *ngIf="data?.expandChannel">
          <div class="activity-inner-comment">
            <ng-container *ngIf="data?.type=='INTERNAL'">
              <app-chat-messages  [dashId]="dashId" [selectedLeadObj]="leadInfo ? leadInfo : {_id:lead_id[0]}" [currentActiveTab]="'commentTab'"></app-chat-messages>
            </ng-container>
            <ng-container *ngIf="['EXTERNAL_OUTBOUND','EXTERNAL_INBOUND']?.includes(data?.type)  && data">
                <app-communicate [dashId]="dashId" [selectedChannelObj]="data" [leadInfo]="leadInfo" [chatMesssageGroupArr]="chatMesssageGroupArr" [chatCommentArr]="chatCommentArr"  [contactTypeBoardData]="contactTypeBoardData"></app-communicate>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="data?.type=='EMAIL'">
        <ng-container *ngIf="!data?.expandChannel">
          <div [innerHtml]="data?.email_object?.body?.html.replaceAll('<br>', '').replaceAll('<p>', '').replaceAll('</p>', '').replaceAll('\n','')"></div>
        </ng-container>
        <div *ngIf="data?.expandChannel" class="activity-inner-comment">
          <app-email  [expandEmail]="true" [dashId]="dashId" [leadInfo]="leadInfo ? leadInfo : {_id:lead_id[0]}" [contactBoardId]="contactBoardId"></app-email>
        </div>
      </ng-container>
      <ng-container *ngIf="data?.type=='ACTIVITY_LOGS' && data?.dummy_activity">
        <ng-container *ngTemplateOutlet="activityLog;context:{data:data?.dummy_activity}"></ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>

