import { ApplicationRef, Compiler, Component, ElementRef, HostListener, Injector, OnInit, Renderer2, TemplateRef, ViewChild, ViewContainerRef } from "@angular/core";
import { Router, NavigationEnd, NavigationStart, NavigationCancel, NavigationError } from "@angular/router";
import { ConstantService } from "Enums/Constant.service";
import { Subscription } from "rxjs/Subscription";
import { MessageService } from "./message.service";
import { BasicUtils } from "./services/basicUtils.service";
import { CustomStorageService } from "./services/custom-storage.service";
import { HttpTransferService } from "./services/httpTransfer.service";
import { MessagingService } from "./services/messaging.service";
import { NgbModal} from "@ng-bootstrap/ng-bootstrap";
import { CommonUtils } from "./services/CommonUtils.service";
export let browserRefresh = false;
declare var $: any;
@Component({
  // tslint:disable-next-line
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ['./app.component.css','app-theme.css']
})
export class AppComponent implements OnInit {
  isPageRefreshed: Subscription;
  mqttSub: Subscription;
  currentRoute: string;
  @ViewChild('lostInternetConnection') lostInternetConnection: TemplateRef<any>;
  @ViewChild('noAccesInItemModel') noAccesInItemModel: TemplateRef<any>;
  @ViewChild('pricingErrorModal') pricingErrorModal: TemplateRef<any>;
  showNavBar: boolean = false;
  navComponentRef: any;
  noAccesInItemModelRef=null
  isMqttKeyCall:boolean=true;
  showTheme:boolean=false;
  selectedColor :string;
  isDontShowBottomBar:boolean=false;
  showHeader:boolean=false;
  pricingPageSubscription:Subscription;
  isLoading:any=true;

  
  constructor(
    public router: Router,
    private messagingService: MessagingService,
    public customStorageService: CustomStorageService,
    private httpTransfer: HttpTransferService,
    private msgService: MessageService,
    private compiler: Compiler, private injector: Injector,
    private app: ApplicationRef,
    private basicUtils:BasicUtils,
    public constantService:ConstantService,
    private modalService: NgbModal,
    public commonUtils : CommonUtils
  ) {
    this.basicUtils.setSystemLanguage();
    if(!sessionStorage?.getItem('loaderIndex')){
      let random=Math.floor(Math.random() *4)+''
      sessionStorage?.setItem('loaderIndex',random)
    }
    console.log(sessionStorage?.getItem('loaderIndex'));
    this.isPageRefreshed = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
        if (browserRefresh) {
          this.customStorageService.removeItem("quickId");
        }
      }
    });
/* 
    this.msgService.getEditPageInfo().subscribe((res : any) => {
      this.showNavBar = res;
      if(!res) this.navComponentRef = undefined; 
    }); */
    this.msgService.getThemeColor().subscribe((res:any)=>{
      this.addOrChangeClassInBody(res,this.selectedColor)
      this.selectedColor = res
    });
    this.msgService.showModal$.subscribe(data => {
      if(data!=null){
        this.openPricingErrorModal(data)
      }
    });
  }
  
   async ngOnInit() {
    //crerate mqtt only logined user
    console.log(this.customStorageService.getItem('theme'))
    const theme = this.customStorageService.getItem('theme');
    this.selectedColor = (theme !== "undefined" && theme !== "null" && theme !== null && theme !== undefined) ? theme : 'theme-royalBlue';
    this.addOrChangeClassInBody(this.selectedColor) 
    document.documentElement.style.setProperty('--themeBackground', this.commonUtils.getCurrentThemeColor());
    document.documentElement.style.setProperty('--light-background-color', this.commonUtils.getCurrentThemeColor() + '1f');
    document.documentElement.style.setProperty('--light-background-color-400', this.commonUtils.getCurrentThemeColor() + '4f');
    if(this.customStorageService.getItem('id') && this.customStorageService.getItem('isAuthenticated')){
        // lazy load handleMqttKey(),
        import('./services/mqtt-connection.service').then((module) => {this.injector.get(module?.MqttConnectionService)?.createMqttConnection()});
    }
    let isFormUrl = false;
    let isGraphUrl = false;
    let isNoteUrl = false;
    let isDataTableUrl = false;
    this.router.events.subscribe((e:any) => {
      this.showNavBar = false;
      if(e instanceof NavigationEnd) {
        setTimeout(() => {this.isLoading=false},1000);
        const currentRoute = e['url'];
        if( currentRoute.includes('/oauth') || currentRoute.includes('/login') || currentRoute.includes('/signup') || currentRoute.includes('/register') || currentRoute.includes('/mobile')){
         this.showTheme = false;
        }
        else{
          this.showTheme = true
        }
        var matchPath=currentRoute.match("\/board\/([0-9,a-z]{24})\/([0-9]+)")
        isFormUrl = currentRoute.includes('/form');
        isGraphUrl = currentRoute.includes('/boardgraph');
        isNoteUrl = currentRoute.includes('/mobile/note');
        isDataTableUrl=currentRoute.includes('mobile/board');
        this.isDontShowBottomBar= (!isNoteUrl && !isDataTableUrl) ? ['/home/welcome'].includes(currentRoute) : isNoteUrl?isNoteUrl:isDataTableUrl
        console.log(this.isDontShowBottomBar)
        if(currentRoute.includes('/board') || currentRoute.includes('/home/welcome') || (currentRoute.includes('/note') && !currentRoute.includes('mobile/note'))){
          this.navComponentRef = null;
          if(document.getElementById('bottombar')){
            document.getElementById('bottombar').innerHTML = ''
            }
        setTimeout(()=>{
          this.renderBottomBar(currentRoute)
        },3000)
        }
        else{
          this.navComponentRef = null;
          if(document.getElementById('bottombar')){
            document.getElementById('bottombar').innerHTML = ''
            }
        }
        if(currentRoute == '/board') {
          this.getUserPreviousActivitys();
        }
        if(currentRoute == '/'){
          this.router.navigate(['/home/welcome']);
        }
        if(
          currentRoute.includes('/oauth') ||
          currentRoute.includes('/profile') ||
          currentRoute.includes('/login') ||
          currentRoute == '/signup' ||
          currentRoute == '/' ||
          currentRoute == '/change-password' ||
          currentRoute == '/404' ||
          currentRoute == '/500' ||
          currentRoute == '/register' ||
          isFormUrl || isNoteUrl || isDataTableUrl || (matchPath && matchPath?.length>0)
          ) {
            this.navComponentRef=null
          }
      }
      if (!(e instanceof NavigationEnd)) {
        return;
      }
      
      
   /*    if (!this.navComponentRef) {
       // let delay=(Math.floor(Math.random() * (80 - 51)) + 50)
        setTimeout(()=>{if(this.navComponentRef!='#' && !this.navComponentRef && this.showNavBar) this.navComponentRef='#';this.renderBottomBar();},5000)
      } 
 */
      if(e instanceof NavigationStart){
        this.navComponentRef = null;
        if(document.getElementById('bottombar')){
        document.getElementById('bottombar').innerHTML = ''
        }
        this.isLoading=true
      }
      window.scrollTo(0, 0);
    });
    this.messagingService.receiveMessage();
    let lostInternetConnectionRef=null
    window.addEventListener('online',  ()=>{
      console.log("window:onLine")
      lostInternetConnectionRef?.modal('hide');
      lostInternetConnectionRef=null;
    });
    window.addEventListener('offline', ()=>{
      console.log("window:offline")
      if(!lostInternetConnectionRef){
        lostInternetConnectionRef=$('#lostInternetConnection')
        lostInternetConnectionRef?.modal('show');
      }
    });

    // this.httpTransfer.showModal.subscribe((res : any) =>{
    //   if(res?.not_access_found){
    //     if(!this.noAccesInItemModelRef) this.noAccesInItemModelRef=this.modalService.open(this.noAccesInItemModel, { size: 'sm' ,backdrop: 'static', })
    //   }
    // })
  }



  @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler(
    event: KeyboardEvent
  ) {
    this.basicUtils.closeCurrentModal()
  }

 

  async renderBottomBar(currentRoute){
  //  this.pinnedEmit(this.isBottombarPinned)
     let node = document.getElementById('bottombar')
     if(node){
      const { BottomBarModule } = await import("./bottom-bar/bottom-bar.module");
      const moduleFactory = await this.compiler.compileModuleAsync(BottomBarModule);
      const moduleRef = moduleFactory.create(this.injector);
      const componentFactory = moduleRef.instance.getComponent();
      this.navComponentRef = componentFactory.create(this.injector, [], node);
      this.app.attachView(this.navComponentRef.hostView);
      this.navComponentRef.instance.currentRoute = currentRoute 
     }
     else{
      this.navComponentRef = null
     }
    
  }

  // if usser login in two browsers
  @HostListener('window:storage', ['$event'])
    onStorageChange(event:StorageEvent) {  
      if(event && this.router?.url!=='/login' && !this.router?.url?.includes('key=public') && (this.customStorageService.getItem('id')=='null' || this.customStorageService.getItem('id')==null)){
        this.customStorageService.setItem('RedirectionLink','')
        this.router.navigate(["/login"]);
      } 
      else if(event && event['key']=='id' && event['newValue']!=event['oldValue'] && this.customStorageService.getItem('id')!='null' && this.customStorageService.getItem('id')!=null){
        window.open('/login', "_self")
      }  
    }

  getUserPreviousActivitys() { 
      if(this.customStorageService.getItem("id")) {
              // get token if token not exit
        this.httpTransfer.getTokenIfNotExit()
        let userData = {};
        userData["client_id"] = this.customStorageService.getItem("clientId");
        userData["user_id"] = this.customStorageService.getItem("id");
        let redirectUrl=this.customStorageService.getItem('RedirectionLink')
        if(redirectUrl!=null && redirectUrl!=""){
            this.customStorageService.removeItem('RedirectionLink')
            window.open(redirectUrl, "_self");
        }
        else{
         // this.router.navigate(['/home/welcome']);
             this.httpTransfer.fetchUserLastActivityDashBoard(userData).subscribe(
                (res : any) => {
                    if (res.result.user_activity.length > 0 && res.result.user_activity[0].work_folder_id) {
                      let id = res.result.user_activity[0].dashboard_id ? res.result.user_activity[0].dashboard_id : res.result.user_activity[0].work_folder_id
                      this.router.navigate(['/board', id]);
                    } else {
                        this.router.navigate(['/home/welcome']);
                    }
                },
                (error) => {
                    this.router.navigate(['/home/welcome']);
                }
            ); 
          }
      } else {
        this.router.navigate(['/login']);
      }
  }

  ngOnDestroy(): void {
    this.isPageRefreshed.unsubscribe();
  }

  async openPricingErrorModal(data){
    this.showHeader=false;
    let modalRef=this.modalService.open(this.pricingErrorModal, { centered: true,size: 'lg' ,windowClass : 'createPricingModal'}); 
    let newNode = document.createElement('div');
    newNode.id = 'placeholder';
    newNode.classList.add('dashParentDiv');
    document.getElementById('createPricingComponent').appendChild(newNode);
    const { pricingModalPopUpModule } =  await import("../app/pricing-modal-pop-up/pricing-modal-pop-up.module");
    const moduleFactory = await this.compiler.compileModuleAsync(pricingModalPopUpModule);
    const moduleRef = moduleFactory.create(this.injector);
    const componentFactory = moduleRef.instance.getComponent();
    const ref = componentFactory.create(this.injector, [], newNode);
    this.app.attachView(ref.hostView); 
    this.pricingPageSubscription=ref.instance.openPricingPage.subscribe(ele=>{
      if(ele){
        this.msgService?.setCloseSettingSidebar(true)
        this.router.navigate(["/profile/admin/billing"]);
        modalRef?.close();
        this.msgService?.closeModal()
      }
    })
    ref.instance.createNewDashTemplateRef =modalRef
    ref.instance.data = data 
    const loadingIndicator = document.getElementById('loadingIndicator');
    if (loadingIndicator) {
      loadingIndicator.style.display = 'none';
    }
    this.showHeader=true
  }
  addOrChangeClassInBody(newThemeClass,oldThemeClass?){
    let body=document.body
    if(oldThemeClass)body.classList.remove(oldThemeClass);
    if(newThemeClass)body.classList.add(newThemeClass);
  }

}
