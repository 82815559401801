import { ChangeDetectorRef, Compiler, Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output, SimpleChange, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { ConstantService } from '../../../../Enums/Constant.service';
import { CommonUtils } from '../../../services/CommonUtils.service';
import { CustomStorageService } from '../../../services/custom-storage.service';
import { DashboardUtilsService } from '../../../services/dashboard-utils.service';
import { HttpTransferService } from '../../../services/httpTransfer.service';
import { MessageService } from 'app/message.service';
import { commonIcons } from 'Enums/attributes-icons';

@Component({
  selector: 'app-graph-connected-board-popup',
  templateUrl: './graph-connected-board-popup.html',
  styleUrls: ['./graph-connected-board-popup.scss']
})
export class GraphConnectedBoardPopupComponent implements OnInit {
  @Output() openGraphEmit=new EventEmitter<any>();
  @Input() currentDashboard:any;
  @Input() allDashboards:any;
  @Input() connectDashboard:boolean=false;
  @Input() searchConnectedList:any[]=[];
  @Input() viewGraph:boolean=false
  dashboardJson:any
  @Output() selectedAppInstance=new EventEmitter<any>();
  searchDashboardsList: any[] = [];
  searchText:any;
  commonIcons = commonIcons;
  isReadOnly:boolean=false

  constructor(private httpTransfer: HttpTransferService, private commonUtils: CommonUtils,
    public constantService: ConstantService,
    private dashboardUtils : DashboardUtilsService,
    private customStorageService : CustomStorageService,
    private messageService:MessageService
    ) {

   }

   ngAfterViewInit(){
   }

  async ngOnInit() {
    if(this.viewGraph){
      await this.getAllDashboards();
      await this.getDashboardsFoldersName();
    }
    else{
      this.connectDashboardClick(this.connectDashboard)
    }
  }

  ngOnChanges(changes: any) {
  }

  ngOnDestroy() {
  }

  onDashboardConnect(selectedDashboardId: string,i?,board?) {
    const orgId = this.customStorageService.getItem('organisationid');
    let connectedBordsId = this.currentDashboard?.connected_board_id || [];
    if(!this.currentDashboard?.connected_board_id.includes(selectedDashboardId)){
      connectedBordsId.push(selectedDashboardId)
      let json = { connected_board_id: connectedBordsId }
      this.httpTransfer.updateDashBoardCorrespondingToOrganisation(json, orgId, this.currentDashboard?.dashboard_id).subscribe(async(res : any) => {
        if (res !== undefined && res) {
          this.dashboardJson = await this.dashboardUtils.getAndSyncAllDashboardData(connectedBordsId);
          // this.connectDashboard = false;
          this.messageService.setDashboardRefresh(true);
          this.searchDashboardsList?.splice(i,1)
          this.searchConnectedList?.push(board);
          // this.connectDashboardClick(false)
          this.messageService.setNewConnectedBoardAdded(connectedBordsId)
          if(!this.currentDashboard?.connected_board_id?.length) {
            this.currentDashboard['connected_board_id'] = [selectedDashboardId]
          }
        }
      });
    }
  }

  removeFromConnectedBoard(boardId: string,board?,i?) {
    const orgId = this.customStorageService.getItem('organisationid');
    let connectedBordsId = this.currentDashboard?.connected_board_id || [];
    connectedBordsId = [...connectedBordsId];
    let index = connectedBordsId.indexOf(boardId);
    if(index>-1) {
      connectedBordsId.splice(index,1);
      let json = {
        connected_board_id: connectedBordsId,
      }
      this.httpTransfer.updateDashBoardCorrespondingToOrganisation(json, orgId, this.currentDashboard.dashboard_id).subscribe((res : any) => {
        if (res !== undefined && res) {
          this.connectDashboard = false;
          this.messageService.setDashboardRefresh(true);
          this.messageService.setNewConnectedBoardAdded(connectedBordsId);
          this.searchConnectedList?.splice(i,1)
          this.searchDashboardsList?.push(board)
          this.currentDashboard?.connected_board_id.splice(index,1);
        }
      });
    } 
  
  }

  connectDashboardClick(bool=true) {
    this.allDashboards = this.allDashboards.filter(dashboard => !this.currentDashboard?.connected_board_id?.includes(dashboard.dashboard_id));
    this.searchDashboardsList = [...this.allDashboards]
    this.searchDashboardsList=this.searchDashboardsList.filter(dash=> dash.type!="BOARD_GRAPH" && dash.type!="WORK_FOLDER")
    if(bool){this.searchText='';this.connectDashboard = true;}
  }

  filterDashboard(val) {
    if(val===''){
      this.searchDashboardsList=[...this.allDashboards]
      this.searchDashboardsList=this.searchDashboardsList.filter(dash=> dash.type!="BOARD_GRAPH" && dash.type!="WORK_FOLDER")
    }
    val = val?.toLowerCase();
    this.searchDashboardsList = this.searchDashboardsList.filter(
      (board) => board?.dashboard_name && board?.dashboard_name?.toLowerCase()?.indexOf(val) !== -1);
    console.log(this.searchDashboardsList)
  }

  async getAllDashboards() {
    let workFolderId=this.currentDashboard?.work_folder_id ? this.currentDashboard.work_folder_id : this.currentDashboard?.dashboard_id
    let inputJson = {
      "status": ["ACTIVE"],
      "access_type": ["PRIVATE", "INTERNAL"],
      "work_folder_id": [workFolderId],
      "include_work_folder": true
    }
    this.httpTransfer.getDashBoardForAdmin(inputJson).subscribe(async function (res) {
      const dashboards = res['result']['dashboards'] || [];
      this.allDashboards = dashboards.filter(e => !e.isDeleted && !e.is_archived && e?.type!=='BOARD_GRAPH');
      this.getDashboardsFoldersName();
    }.bind(this));
    if(this.currentDashboard?.role){
      if(this.currentDashboard?.role.includes('DASHBOARD_ADMIN' || 'ACCESS')){
        this.isReadOnly= false;
       }
       else{this.isReadOnly= true;}
    }
  }

  async getDashboardsFoldersName() {
      this.allDashboards = this.allDashboards.filter(dash => !dash.isDeleted && !dash.is_archived);
    let filterData = this.allDashboards?.filter(dashboard => this.currentDashboard?.connected_board_id?.includes(dashboard?.dashboard_id));
    this.searchConnectedList = [...filterData];
    if(this.viewGraph){
      this.connectDashboard=this.searchConnectedList?.length>0?false:true;
      this.connectDashboardClick(this.connectDashboard)
    }
  }

}
