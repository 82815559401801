import { ComponentFactoryResolver, NgModule } from '@angular/core'; 
import { CommonModule } from '@angular/common'; 
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule} from '@ng-bootstrap/ng-bootstrap'; 
import { DashboardCreateComponent } from './dashboard-create/dashboard-create.component';
import { SharedComponentsModule } from '../all-reusables/shared-components/shared.components.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  declarations: [DashboardCreateComponent],
  imports: [
    CommonModule, 
    ReactiveFormsModule,
    FormsModule,  
    NgbModule, 
    NgSelectModule,
    TranslateModule
  ],
  exports:[DashboardCreateComponent]
})
export class CreateDashboardModule { 
  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}
  getComponent() {
  return this.componentFactoryResolver.resolveComponentFactory(
    DashboardCreateComponent
  )
  }
}
