import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild ,} from '@angular/core';
import { DashboardUtilsService } from 'app/services/dashboard-utils.service';
import { ConstantService } from 'Enums/Constant.service';
import { FilterPipe } from 'app/filter.pipe';

@Component({
  selector: 'mobile-cell',
  changeDetection:ChangeDetectionStrategy.OnPush,
  templateUrl: './mobile-cell.component.html',
  styleUrls: ['./mobile-cell.component.scss']
})
export class MobileCellComponent implements OnInit  {
  params:any={};
  itemInfo:any={}
  dashId:any
  
  
  constructor(private dashboardUtils:DashboardUtilsService,private cdr:ChangeDetectorRef,private constantService :ConstantService){
  }

  async ngOnInit() {
    this.dashId=this.params?.item?.dashboard_id
    this.onUpdate();
  }

  onUpdate(params: any={}){
    this.itemInfo=this.params?.item || {}
    this.cdr.detectChanges();
  }

  onValueInput(value?){
    this.params?.gridTableRef?.changedCellValue({value:value,customFieldType:true,params:this.params});
    this.params.value=value; 
    this.onUpdate()
  }
}
