
<!-- tracker field -->
<div class="cell-box">
  <div class="tracker-cell">
        <app-time-tracking
          [view]="'GRID'"
          [itemKey]="params.columnData.columnKey"
          [timeSpent]="value"
          [leadObj]="itemInfo"
          [activeTrackerList]="activeTrackerList"
          [dashId]="itemInfo?.dashboard_id">
        </app-time-tracking>
    </div>
</div>
  

