export const AttributesIcons ={
    "Text": 'assets/new-icon-6d6e6f-20dp/text-field-6d6e6f.svg',
    "Textarea": 'assets/new-icon-6d6e6f-20dp/textArea-icon-6d6e6f.svg',
    "Numeric": 'assets/new-icon-6d6e6f-20dp/number-icon-6d6e6f.svg',
    "Email": 'assets/new-icon-6d6e6f-20dp/email-icon-6d6e6f.svg',
    "Mobile": 'assets/new-icon-6d6e6f-20dp/mobile-6d6e6f.svg',
    "Date": 'assets/new-icon-6d6e6f-20dp/date-outline.svg',
    "SingleVideo": 'assets/new-icon-6d6e6f-20dp/video-6d6e6f.svg',
    "SingleImage": 'assets/new-icon-6d6e6f-20dp/image-6d6e6f.svg',
    "SingleFile": 'assets/new-icon-6d6e6f-20dp/file-6d6e6f.svg',
    "Radiobutton": 'assets/new-icon-6d6e6f-20dp/radio-6d6e6f.svg',
    "Dropdown": 'assets/new-icon-6d6e6f-20dp/dropdown-6d6e6f.svg',
    "Rating": 'assets/new-icon-6d6e6f-20dp/rating-6d6e6f.svg',
    "Percentage": 'assets/1x/smIcons/Percentage.svg',
    "Url": 'assets/new-icon-6d6e6f-20dp/url-6d6e6f.svg',
    "MultiSelect": 'assets/new-icon-6d6e6f-20dp/multiselect-6d6e6f.svg',
    "IPAddress": 'assets/new-icon-6d6e6f-20dp/Ip-address-6d6e6f.svg',
    "SingleUser": 'assets/new-icon-6d6e6f-20dp/user-6d6e6f.svg',
    "MultiUser": 'assets/new-icon-6d6e6f-20dp/user-6d6e6f.svg',
    "MultipleImage": 'assets/new-icon-6d6e6f-20dp/image-6d6e6f.svg',
    "MultipleVideo": 'assets/new-icon-6d6e6f-20dp/video-6d6e6f.svg',
    "MultipleFile": 'assets/new-icon-6d6e6f-20dp/file-6d6e6f.svg',
    "Checkbox": 'assets/new-icon-6d6e6f-20dp/checkbox-filled-6d6e6f.svg',
    "Currency":'assets/new-icon-6d6e6f-20dp/currency-6d6e6f.svg',
    "Board": 'assets/new-icon-6d6e6f-20dp/board-6d6e6f.svg',
    "Button": 'assets/new-icon-6d6e6f-20dp/button-6d6e6f.svg',
    "SingleAudio":'assets/new-icon-6d6e6f-20dp/audio-6d6e6f.svg',
    "MultipleAudio": 'assets/new-icon-6d6e6f-20dp/audio-6d6e6f.svg',
    "TimeTracker":'assets/new-icon-6d6e6f-20dp/time-tracker-6d6e6f.svg',
    "Country":'assets/new-icon-6d6e6f-20dp/flag-outline-6d6e6f.svg',
    "Location":'assets/new-icon-6d6e6f-20dp/location-outline-6d6e6f.svg',
    "MultiUrl":'assets/new-icon-6d6e6f-20dp/url-6d6e6f.svg',

    "Mirror-Text": 'assets/1x/mirrorIcons/MirrorTextField.svg',
    "Mirror-Textarea": 'assets/1x/mirrorIcons/MirrorTextArea.svg',
    "Mirror-Numeric": 'assets/1x/mirrorIcons/MirrorNumeric.svg',
    "Mirror-Email": 'assets/1x/mirrorIcons/MirrorEmail.svg',
    "Mirror-Mobile": 'assets/1x/mirrorIcons/MirrorMobile.svg',
    "Mirror-Date": 'assets/1x/mirrorIcons/MirrorDateTime.svg',
    "Mirror-DateTime": 'assets/1x/mirrorIcons/MirrorDateTime.svg',
    "Mirror-SingleImage": 'assets/1x/mirrorIcons/MirrorSingleMultiImage.svg',
    "Mirror-MultipleImage": 'assets/1x/mirrorIcons/MirrorSingleMultiImage.svg',
    "Mirror-Percentage": 'assets/1x/mirrorIcons/MirrorPercentage.svg',
    "Mirror-MultiSelect": 'assets/1x/mirrorIcons/MirrorMultiselect.svg',
    "Mirror-SingleVideo": 'assets/1x/mirrorIcons/MirrorSingleVideo.svg',
    "Mirror-MultipleVideo": 'assets/1x/mirrorIcons/MirrorSingleVideo.svg',
    "Mirror-SingleFile": 'assets/1x/mirrorIcons/MirrorFile.svg',
    "Mirror-MultipleFile": 'assets/1x/mirrorIcons/MirrorFile.svg',
    "Mirror-Radiobutton": 'assets/1x/mirrorIcons/MirrorRadioButton.svg',
    "Mirror-Dropdown": 'assets/1x/mirrorIcons/MirrorDropDown.svg',
    "Mirror-Checkbox": 'assets/1x/mirrorIcons/MirrorCheckBox.svg',
    "Mirror-Currency": 'assets/1x/mirrorIcons/MirrorCurrency.svg',
   "Mirror-End-Date": 'assets/1x/mirrorIcons/MirrorEndDate.svg',
   "Mirror-Start-Date": 'assets/1x/mirrorIcons/MirrorStartDate.svg',
   "Mirror-Update-Date": 'assets/1x/mirrorIcons/MirrorUpdateDate.svg',
   "Mirror-Create-Date": 'assets/1x/mirrorIcons/MirrorCreateDate.svg',
   //  "Mirror-Text": 'assets/1x/mirror-end-date.svg',
   //  "Mirror-Text": 'assets/1x/mirror-update-date.svg',
    "Mirror-Sprint": 'assets/1x/mirrorIcons/MirrorSprint.svg',
    "Mirror-Tag": 'assets/1x/mirrorIcons/MirrorTag.svg',
    "Mirror-SingleUser": 'assets/1x/mirrorIcons/MirrorsingleMultiUser.svg',
    "Mirror-MultiUser": 'assets/1x/mirrorIcons/MirrorsingleMultiUser.svg',
    "Mirror-Bucket": 'assets/1x/mirrorIcons/MirrorBucket.svg',
    "Mirror-Priority": 'assets/1x/mirrorIcons/MirrorPriority.svg',
    "Mirror-Url": 'assets/1x/mirrorIcons/MirrorUrl.svg',
    "Mirror-IPAddress": 'assets/1x/mirrorIcons/MirrorIpAddress.svg',
    "Mirror-Rating": 'assets/1x/mirrorIcons/MirrorRating.svg',
    "Mirror-Board": 'assets/1x/mirrorIcons/MirrorBoard.svg',
    'Setting' : 'assets/common-use-icon-20dp/settings-outline-6d6e6f.svg',
    'Notification' : 'assets/1x/notification-outline.svg',
    'SubTask' : 'assets/new-icon-6d6e6f-20dp/subtask-6d6e6f.svg',
    'CheckList': '/assets/new-icon-6d6e6f-20dp/check-list-6d6e6f.svg',
    'Collaborator': '/assets/common-use-icon-20dp/collaborator-6d6e6f.svg',
    'Dependency': '/assets/1x/dependency.svg',
    'Filter' : 'assets/1x/filter-outline.svg',
    'groupBy' : 'assets/1x/groupby-outline.svg',
    'sortBy':'assets/1x/sortby-outline.svg',
    'hierarchy': 'assets/1x/hierarchy-outline.svg',
    'FilterFilled' : 'assets/common-use-icon-20dp/filter-filled-3571de.svg',
    "Columns" : 'assets/1x/column-outline.svg',
    "MultiSelectItem" : 'assets/1x/MultiselectItem.svg',
    "Import-Export" : 'assets/1x/Import-Export.svg',
    'Process' : 'assets/1x/sideSettingIcons/Process.svg',
    'SettingTags' :  'assets/1x/sideSettingIcons/Tags.svg',
    'QuickFilter':'assets/1x/sideSettingIcons/quick-filter.svg',
    'Forms' :  'assets/1x/sideSettingIcons/Forms.svg',
    'ItemAttributes' : 'assets/1x/sideSettingIcons/ItemAttributes.svg',
    'SettingEmail':'assets/1x/sideSettingIcons/Email.svg',
    'SettingWhatsapp':'assets/1x/sideSettingIcons/whatsapp.svg',
    'SettingChannels':'assets/1x/sideSettingIcons/channels.svg',
    'SettingAutomation':'assets/1x/sideSettingIcons/automation.svg',
    'SettingItemTerminology':'assets/1x/sideSettingIcons/ItemTerminology.svg',
    "title":'assets/new-icon-6d6e6f-20dp/text-field-6d6e6f.svg',
    "start_end_date":'assets/new-icon-6d6e6f-20dp/calendar-6d6e6f.svg',
    "sub_task":'assets/new-icon-6d6e6f-20dp/subtask-6d6e6f.svg',
    "priority":'assets/1x/Priority.svg',
    'Members' : 'assets/member-icon-30dp.svg',
    'TeamMembers' : 'assets/sidebar-header-icons-6d6e6f-20dp/users-group-6d6e6f.svg',
    'SettingPhase' : 'assets/1x/sideSettingIcons/Phase.svg',
    'ItemTemplate' : 'assets/1x/sideSettingIcons/ItemTemplate.svg',
    'ImportExport' : 'assets/1x/sideSettingIcons/ImportExport.svg',
    'Bucket' : 'assets/common-use-icon-20dp/bucket-6d6e6f.svg',
    'Assignee' : 'assets/common-use-icon-20dp/add-user-6d6e6f.svg',
    'Priority' : 'assets/1x/Priority.svg',
    'Tags' : "assets/common-use-icon-20dp/tag-6d6e6f.svg",
    'Sprints' : "assets/1x/phase-outline.svg",
    'createBoard' : "assets/1x/blank-dashboard-ico.svg",
    'Updatefield':'assets/common-use-icon-20dp/update-item-6d6e6f.svg',
    "Formulas" : 'assets/1x/formula-outline.svg',
    "Mirror-MultiUrl": 'assets/1x/mirrorIcons/MirrorUrl.svg',
    "Mirror-Location": 'assets/1x/mirrorIcons/MirrorLocation.svg',
    "Mirror-Country": 'assets/1x/mirrorIcons/MirrorCountry.svg',
    "Mirror-SingleAudio": 'assets/1x/mirrorIcons/MirrorAudio.svg',
    "Mirror-MultipleAudio": 'assets/1x/mirrorIcons/MirrorAudio.svg',
    "Mirror-CheckList": 'assets/1x/mirrorIcons/MirrorChecklist.svg',
    "Mirror-TimeTracker": 'assets/1x/mirrorIcons/MirrorTimeTracker.svg',
    "Automation":'assets/1x/automation-icon-3571de.svg',
    "Parent":'assets/1x/Parent-icon.svg',
    "duplicate":'assets/new-icon-6d6e6f-20dp/duplicate-6d6e6f.svg',
    "addIcon":'assets/1x/add-outline.svg',
    "copyIcon":"assets/1x/copy-outline.svg",
    'Info':"assets/common-use-icon-20dp/info-6d6e6f.svg",
    "Sprint": 'assets/1x/phase-outline.svg',
    "Tag": 'assets/common-use-icon-20dp/tag-6d6e6f.svg',
    'StartEndDate':'assets/common-use-icon-20dp/calendar-6d6e6f.svg',
    "DateTime": 'assets/new-icon-6d6e6f-20dp/calendar-6d6e6f.svg',
    "WHATSAPP":'assets/1x/WHATSAPP.svg',
    "INSTAGRAM":'assets/1x/INSTAGRAM.png',
    "FACEBOOK":'assets/1x/FACEBOOK.svg',
    "LINE":'assets/1x/LINE.svg',
    "NEW_FACEBOOK":'assets/1x/new-facebook.svg',
    "VIBER":'assets/1x/VIBER.svg',
    "LINKDIN":'assets/1x/linkedin-logo.png',
    "TELEGRAM":'assets/1x/TELEGRAM.svg',
    "TWITTER":'assets/1x/TWITTER.svg',
    "EDIT":"assets/common-use-icon-20dp/edit-6d6e6f.svg",
    "DELETE" : 'assets/common-use-icon-20dp/delete-FE2030.svg',
    "ADDCHANNEL" : 'assets/1x/addChannel.svg',
    "Back":"assets/1x/back-arrow-left.svg",
    "GOOGLE_BUSINESS_MESSAGES":"assets/1x/googleBusiness.svg",
    "Template" : 'assets/common-use-icon-20dp/templates-msg.svg',
    "LeftArrow" : 'assets/1x/angle-left-4d4d4d.svg',
    "RightArrow" : 'assets/1x/IconRightArrow.svg',
    'SettingComment':'assets/1x/sideSettingIcons/comment-icon-with-blue-bg.svg',
    'addItem':'assets/1x/item-card.svg',
    'templateBoard':'assets/sidebar-header-icons-6d6e6f-20dp/template-6d6e6f.svg',
    'SettingChatBot':'assets/1x/sideSettingIcons/Typebot.svg',
    'TYPEBOT':'assets/1x/TYPEBOT.svg',
    'CompletedPhase':'assets/1x/Completed-Phase.svg',
    'InprogressPhase':'assets/1x/InProgress-phase.svg',
    'StartPhase':'assets/1x/Start-phase.svg',
    'SettingActivityType':'assets/1x/sideSettingIcons/ActivityType.svg',
    'callLogsIcon':'assets/1x/callLogsIcon.svg',
    'webTrackingIcons':'assets/1x/webTrackingIcons.svg',
    'mettingLogsIcons':'assets/1x/meetingLogsIcons.svg',
    'callLogsIconWhite':'assets/1x/callLogsIconWhite.svg',
    'meetingLogsIconsWhite':'assets/1x/meetingLogsIconsWhite.svg',
    'associate':'assets/1x/associate.svg',
    'rename':'assets/1x/rename.svg',
    'taskActivity':'assets/1x/taskActivity.svg',
    'messageActivity':'assets/1x/messageActivity.svg',
    'Calendar':'/assets/common-use-icon-20dp/calendar.svg',
    'BoardFolder':'/assets/common-use-icon-20dp/board-folders-24x24.svg',
    'GoogleCalendar':'/assets/common-use-icon-20dp/googleCalendar.png',
    'OutlookCalendar':'/assets/common-use-icon-20dp/outlook.png',
    'googleDrive':'assets/1x/google-drive.svg',
    'oneDrive':'assets/1x/onedrive.svg',
    'attachment':'assets/1x/upload-attachment.svg',
    'template':'assets/1x/upload-pdf-template.svg',
    'url':'assets/1x/upload-url.svg',
    'sharedIcons':'assets/1x/sharedIcons.svg',
    'zoom-in':'assets/1x/zoom-in.svg',
    'zoom-out':'assets/1x/zoom-out.svg',
    'screenshot-capture':'assets/1x/screenshot-capture.svg',
    'downArrow':'assets/1x/down.svg',
    'threeDotHorizontal':'assets/common-use-icon-20dp/showMore.svg',
    'user':'assets/1x/userIcon.svg',
     'briefcase': 'assets/boards-random-icon-20dp/briefcase-6d6e6f.svg',
    'dart': 'assets/boards-random-icon-20dp/target-6d6e6f.svg',
    'flag1': 'assets/boards-random-icon-20dp/flag.svg',
    'idea1': 'assets/boards-random-icon-20dp/idea.svg',
    'material': 'assets/boards-random-icon-20dp/material.svg',
    'rectangle': 'assets/boards-random-icon-20dp/wifi.svg',
    'rocket1': 'assets/boards-random-icon-20dp/rocket.svg',
    'target': 'assets/boards-random-icon-20dp/target-6d6e6f.svg',
    'speaker': 'assets/boards-random-icon-20dp/speakerphone.svg',
    'report': 'assets/boards-random-icon-20dp/report.svg',
    'piechart': 'assets/boards-random-icon-20dp/chart.svg',
    'hr': 'assets/boards-random-icon-20dp/cast.svg',
    'connected': 'assets/boards-random-icon-20dp/dashboard.svg',
    'calendar': 'assets/boards-random-icon-20dp/calendar.svg',
    'bug': 'assets/boards-random-icon-20dp/bug.svg',
    'agile': 'assets/boards-random-icon-20dp/loader.svg',
    'folder':'assets/boards-random-icon-20dp/folder.svg',
    'drag':'assets/1x/drag.svg',
    'favourite': 'assets/1x/star.svg',
    'paint': 'assets/1x/paint.svg',
    'kanban':'assets/sidebar-header-icons-6d6e6f-20dp/kanban-6d6e6f.svg',
    'Gantt':'assets/sidebar-header-icons-6d6e6f-20dp/gant.svg',
    'Split':'assets/sidebar-header-icons-6d6e6f-20dp/split-view.svg',
    'Grid':'assets/sidebar-header-icons-6d6e6f-20dp/grid-view-6d6e6f.svg',
    'Notes':'assets/1x/notes-outline.svg',
    'Drive':'assets/sidebar-header-icons-6d6e6f-20dp/drive-6d6e6f.svg',
    'Communication':'assets/1x/Connect-new-icon.svg',
    'Chat':'assets/sidebar-header-icons-6d6e6f-20dp/Chat-icon.svg',
    'Activity':'assets/sidebar-header-icons-6d6e6f-20dp/activity-6d6e6f.svg',
    'Overview':'assets/sidebar-header-icons-6d6e6f-20dp/overview-6d6e6f.svg',
    'Apps':'assets/1x/apps-market.svg',
    'hide':"assets/1x/collapsed-comment.svg",
    'Subtask' : 'assets/new-icon-6d6e6f-20dp/subtask-6d6e6f.svg',
    'App' : 'assets/1x/sideSettingIcons/app.svg',
    'sharedUsers':'assets/1x/sharedUsers.svg',
     'threeDot':'assets/common-use-icon-20dp/threeDots-icon.svg',
    'Whatsapp':'assets/1x/WHATSAPP.svg',
    "MultipleBoard": 'assets/new-icon-6d6e6f-20dp/board-6d6e6f.svg',
    "Search" :"assets/common-use-icon-20dp/search.svg",
    "plus":"assets/add-white.svg",
    "down":"assets/dropdown-icon-white.svg",
    "search":"assets/search-white.svg",
    "MultipleEmail": 'assets/new-icon-6d6e6f-20dp/email-icon-6d6e6f.svg',
    "MultipleMobile": 'assets/new-icon-6d6e6f-20dp/mobile-6d6e6f.svg',
    "resetIcon": 'assets/1x/reset.svg',
    "Graph":"assets/common-use-icon-20dp/board-graph.svg",
    "List":"assets/1x/list-booard.svg",
    "workFolderIcon": 'assets/1x/work-folder.svg',
    "share":'assets/1x/Share.svg',
    "assigned_to":'assets/common-use-icon-20dp/add-user-6d6e6f.svg',
    "bucket_id":'assets/common-use-icon-20dp/bucket-6d6e6f.svg',
    "tag_id":'assets/common-use-icon-20dp/tag-6d6e6f.svg',
    "dependency":'/assets/1x/dependency.svg',
    'threeDots':'/assets/1x/vertdots.svg',
    'delete':'assets/1x/delete-outline.svg',
    'WEBSITE':'assets/1x/websiteUrl.svg',
    'backArrow':'assets/1x/newdash-arrow.svg',
    'SettingKnowledgeBase':'assets/1x/sideSettingIcons/ActivityType.svg',
    'WEB_PAGE':'assets/1x/wepPage.svg',
    'OWN_PAGE':'assets/1x/hostPage.svg',
    'UPLOAD_FILE':'assets/1x/uploadFile.svg',
    'chevronDown':'assets/1x/chevron-down.svg',
    'chevronRight':'assets/1x/chevron-right.svg',
    'aiAssist':'assets/1x/ai-assist-icon.svg',
    'aiAssistBlueBg': 'assets/1x/ai-assist-bluebg.svg',
    'msgChatBot':'assets/1x/message-chatbot.svg',
    'replaceWhite':'assets/1x/replace-icon.svg',
    'items':"assets/1x/item-outline.svg",
    'Internal_chat':"assets/1x/bottom-icons/Chats.svg",
    'Channel':'assets/1x/communication-outline.svg',
    'Pin':'assets/1x/pin.svg',
    'Unpin':'assets/1x/unpin-outline.svg',
    'messageOutline':'assets/1x/message-outline.svg',
    'pronnelAi':'assets/1x/bottom-icons/AI.svg',
    'MergeItems':'assets/common-use-icon-20dp/Merge-item.svg',
    'Delete':"assets/common-use-icon-20dp/delete-6d6e6f.svg",
    'Comment':'assets/common-use-icon-20dp/comment-outline.svg',
    'Brodcast':'assets/1x/Brodcast-outline.svg',
    "Expand" :"assets/img/icons/preview.svg",
    "Cross" : "assets/common-use-icon-20dp/close.svg",
    'attach':"assets/1x/attachMedia.svg",
    'ChatBot':"assets/1x/bottom-icons/chatbot.svg",
    'Lists' :  'assets/1x/sideSettingIcons/list-settings.svg',
   }

   export const ItemIcons ={
      activity:'assets/1x/activity.svg',
      media:'assets/1x/media.svg',
      note:'assets/1x/notes.svg',
      itemDetails:'assets/1x/item-details.svg',
      layout:'assets/1x/layouts.svg',
      folder:'assets/1x/folder.svg',
      hierarchy:'assets/1x/hierarchy.svg',
      commentCollapsed:"assets/1x/collapsed-comment.svg",
      commentExpand:"assets/1x/Expand-comment.svg",
      apps:'assets/1x/apps-market.svg'
   }
    
 export const AttributesLxIcons ={
    "Text": 'assets/new-icon-6d6e6f-20dp/text-field-6d6e6f.svg',
    "Textarea": 'assets/new-icon-6d6e6f-20dp/textArea-icon-6d6e6f.svg',
    "Numeric": 'assets/new-icon-6d6e6f-20dp/number-icon-6d6e6f.svg',
    "Email": 'assets/new-icon-6d6e6f-20dp/email-icon-6d6e6f.svg',
    "Mobile": 'assets/new-icon-6d6e6f-20dp/mobile-6d6e6f.svg',
    "Date": 'assets/new-icon-6d6e6f-20dp/calendar-6d6e6f.svg',
    "SingleVideo": 'assets/new-icon-6d6e6f-20dp/video-6d6e6f.svg',
    "SingleImage": 'assets/new-icon-6d6e6f-20dp/image-6d6e6f.svg',
    "SingleFile": 'assets/new-icon-6d6e6f-20dp/file-6d6e6f.svg',
    "Radiobutton": 'assets/new-icon-6d6e6f-20dp/radio-6d6e6f.svg',
    "Dropdown": 'assets/new-icon-6d6e6f-20dp/dropdown-6d6e6f.svg',
    "Rating": 'assets/new-icon-6d6e6f-20dp/rating-6d6e6f.svg',
    "Percentage": 'assets/1x/lxIcons/Percentage.svg',
    "Url": 'assets/new-icon-6d6e6f-20dp/url-6d6e6f.svg',
    "MultiSelect": 'assets/new-icon-6d6e6f-20dp/multiselect-6d6e6f.svg',
    "IPAddress": 'assets/new-icon-6d6e6f-20dp/Ip-address-6d6e6f.svg',
    "SingleUser": 'assets/new-icon-6d6e6f-20dp/user-6d6e6f.svg',
    "MultiUser": 'assets/new-icon-6d6e6f-20dp/user-6d6e6f.svg',
    "MultipleImage": 'assets/new-icon-6d6e6f-20dp/image-6d6e6f.svg',
    "MultipleVideo": 'assets/new-icon-6d6e6f-20dp/video-6d6e6f.svg',
    "MultipleFile": 'assets/new-icon-6d6e6f-20dp/file-6d6e6f.svg',
    "Checkbox": 'assets/new-icon-6d6e6f-20dp/checkbox-filled-6d6e6f.svg',
    "Currency":'assets/new-icon-6d6e6f-20dp/currency-6d6e6f.svg',
    "Board": 'assets/new-icon-6d6e6f-20dp/board-6d6e6f.svg',
    "Button": 'assets/new-icon-6d6e6f-20dp/button-6d6e6f.svg',
    'CheckList': '/assets/new-icon-6d6e6f-20dp/check-list-6d6e6f.svg',
    "SingleAudio":'assets/new-icon-6d6e6f-20dp/audio-6d6e6f.svg',
    "MultipleAudio": 'assets/new-icon-6d6e6f-20dp/audio-6d6e6f.svg',
    "TimeTracker":'assets/new-icon-6d6e6f-20dp/time-tracker-6d6e6f.svg',
    "Country":'assets/new-icon-6d6e6f-20dp/flag-outline-6d6e6f.svg',
    "Location":'assets/new-icon-6d6e6f-20dp/location-outline-6d6e6f.svg',
    "MultiUrl":'assets/new-icon-6d6e6f-20dp/url-6d6e6f.svg',
    'BUCKET' : 'assets/common-use-icon-20dp/bucket-6d6e6f.svg',
    'PRIORITY' : 'assets/1x/Priority.svg',
    'TAGS' : "assets/common-use-icon-20dp/tag-6d6e6f.svg",

    "Automation":'assets/1x/automation-icon-3571de.svg',
    "COMMENT":"assets/common-use-icon-20dp/comment-3571de.svg",
    "EMAIL" : "assets/new-icon-6d6e6f-20dp/email-icon-6d6e6f.svg",
    "WHATSAPP" : "assets/common-use-icon-20dp/brand-whatsapp.svg",
    "TIMETRACKER" : "assets/new-icon-6d6e6f-20dp/time-tracker-6d6e6f.svg",
    "WEBHOOK": "assets/common-use-icon-20dp/webhook.svg",
    "Instagram" : 'assets/common-use-icon-20dp/Instagram.png',
    "Whatsapp" : 'assets/common-use-icon-20dp/whatsapp.svg',
    "Messenger" : 'assets/common-use-icon-20dp/messenger.svg',
    "Reply" :"assets/common-use-icon-20dp/reply.svg",
    "CheckMarkForRead":"assets/1x/readCheck.svg",
    "CheckMarkForSent":"assets/1x/sentCheck.svg",
    "CheckMarkForDeliver":"assets/1x/delivercheck.svg",
    "Send" :"assets/common-use-icon-20dp/sendArrow.svg",
    "SendWhite" :"assets/common-use-icon-20dp/Send-White.svg",
    "Attachment" :"assets/common-use-icon-20dp/attachment.svg",
    "Columns" : "assets/1x/column-outline.svg",
    "Search" :"assets/common-use-icon-20dp/search.svg",
    "Cross" : "assets/common-use-icon-20dp/close.svg",
    "ReplyWhite" :"assets/common-use-icon-20dp/reply-white-icon.png",
    "Alert" :"assets/common-use-icon-20dp/alert.svg",
    "Expand" :"assets/img/icons/preview.svg",
    "Ban" :"assets/common-use-icon-20dp/ban.svg",
    'newNum': '/assets/common-use-icon-20dp/newNo.svg',
    'newText': '/assets/common-use-icon-20dp/newText.svg',
    'newDate': '/assets/common-use-icon-20dp/newDate.svg',
    'arrayBraces':'/assets/common-use-icon-20dp/arrayBrackets.svg',
    'redCross':'/assets/1x/Close-icon-red-16.svg',
    'ques':'/assets/1x/ques.svg',
    'checked':'/assets/1x/Check-20.svg',
    'exclamation':'/assets/1x/pending.svg',
    "MultipleBoard": 'assets/new-icon-6d6e6f-20dp/board-6d6e6f.svg',
    "MultipleEmail": 'assets/new-icon-6d6e6f-20dp/email-icon-6d6e6f.svg',
    "MultipleMobile": 'assets/new-icon-6d6e6f-20dp/mobile-6d6e6f.svg',
    "Comment":"assets/1x/comment.svg"

 }
 export const commonIcons={
   "Add": 'assets/common-use-icon-20dp/add-3571de.svg',
   "Delete" : 'assets/common-use-icon-20dp/delete-FE2030.svg',
   "Copy":'assets/1x/copy.svg',
   'Calendar':'/assets/common-use-icon-20dp/calendar.svg',
   "Tick" : 'assets/common-use-icon-20dp/tick.svg',
   "Cancel" : 'assets/common-use-icon-20dp/cancelbox.svg',
   "User":'assets/common-use-icon-20dp/Add_User.svg',
   'rename':'assets/1x/rename.svg',
   'dotsVertical':'assets/1x/dots-vertical.svg',
   'dotsHorizontal':'assets/1x/dots-horizontal.svg',
   'editOutline':'assets/1x/edit-pencil.svg',
   'shareOutline':'assets/1x/share-outline.svg',
   'deleteOutline':'assets/1x/delete-outline.svg',
   'copyOutline':'assets/1x/copy-outline.svg',
   'eyeVisibleOutline':'assets/1x/eye-visible.svg',
   'eyeHiddenOutline':'assets/1x/eye-hidden.svg',
   'addOutline':'assets/1x/add-Outline.svg',
   'downloadOutline':'assets/1x/download-outline.svg',
   'fileDownloadOutline':'assets/1x/file-download-outline.svg',
   'settingsOutline':'assets/1x/settings-outline.svg',
   'fullScreen':'assets/1x/fullScreen.svg',
   "Cross" : "assets/common-use-icon-20dp/close.svg",
   "Alert" :"assets/common-use-icon-20dp/alert.svg",
   'closeOutline':'assets/1x/close-outline.svg',
   'plusOutline':'assets/1x/plusOutline.svg',
   'search':'assets/1x/search.svg',
   'chevronDown':'assets/1x/chevronDown.svg',
   'chevronDownWhite':'assets/1x/chevronDownWhite.svg',
   'usersOutline':'assets/1x/users-outline.svg',
   'checkOutline':'assets/1x/check-Outline.svg',
   'userOutline':'assets/1x/user-Outline.svg',
   'logoutOutline':'assets/1x/logout-Outline.svg',
   'VisiblityOutline':'assets/1x/visibility-Outline.svg',
   'backOutline':'assets/1x/back-Outline.svg',
   'paintOutline':'assets/1x/paint-Outline.svg',
   
   'History':'assets/1x/history.png',
   'Maximize':'assets/1x/maximize-20.svg',
   "Minimize":'assets/1x/minimize.png',
   "Empty":'assets/img/icons/empty.svg',
   'screenshotOutline':'assets/1x/screenshot-outline.svg',
   'fullViewOutline':'assets/1x/full-view-outline.svg',
   'refreshOutline':'assets/1x/refresh-outline.svg',
   'auditLog':'assets/1x/audit-log-20.svg',
   'userRemove':'assets/1x/user-remove.svg',
   'dragOutline':'assets/1x/drag-outline.svg',
   'addCircleOutline':'assets/1x/add-square-outline.svg',
   'addCircleWhiteOutline':'assets/1x/add-square-white-outline.svg',
   'botOutline':'assets/1x/bot-outline.svg',
   'qrCodeOutline':'assets/1x/qr-code-outline.svg',
   'alertTriangleOutline':'assets/1x/alert-triangle-outline.svg',
   'bucketOutline':'assets/1x/bucket-outline.svg',
   'circleCheck':'assets/1x/circle-Check-Filled.svg',


   
}

export const ActivityIcons = {
   Task: 'assets/activity_icons/task.svg',
   Meeting: 'assets/activity_icons/meeting.svg',
   Call: 'assets/activity_icons/call.svg',
   Custom: 'assets/activity_icons/custom.svg',
   Message: 'assets/activity_icons/message.svg',
   Logs: 'assets/activity_icons/logs.svg',
   WHATSAPP: 'assets/1x/WHATSAPP.svg',
   INTAGRAM: 'assets/activity_icons/instagram.svg',
   CHANNELS: 'assets/activity_icons/channels.svg',
   Email: 'assets/activity_icons/email.svg',
   FACEBOOK:'assets/1x/FACEBOOK.svg',
   LINE:'assets/1x/LINE.svg',
   VIBER:'assets/1x/VIBER.svg',
   TELEGRAM:'assets/1x/TELEGRAM.svg', 
   INSTAGRAM:'assets/1x/INSTAGRAM.png',
}
export const NavBarIcons={
   "home" : "assets/1x/home-outline.svg",
   "youtube":"assets/youtube.svg",
   "blog":"assets/blog.svg",
   "tutorials":"assets/tutorials.svg",
    "playstore":"assets/playstore.svg",
    "appStore":"assets/appStore.svg",
    "workFolder":"assets/workFolder.svg",
    "favourite":"assets/favourite.svg",
    "interest":"assets/interest.svg",
    "webChat":"assets/webChat.svg"
    
}
export const viewsIcons ={
   "kanban" : "assets/1x/kanban-outline.svg",
   "split" : "assets/1x/splitview-outline.svg",
   "gantt" : "assets/1x/gantt-outline.svg",
   "grid" : "assets/1x/grid-outline.svg",
   "calendar" : "assets/1x/calendar-outline.svg",
   "notes" : "assets/1x/notes-outline.svg",
   "overview" : "assets/1x/overview-outline.svg",
   "activity" : "assets/1x/activity-outline.svg",
   "internalChat" : "assets/1x/internal-chat-outline.svg",
   "communication" : "assets/1x/communication-outline.svg",
   "dataView" : "assets/1x/data-view-outline.svg",
   "drive" : "assets/1x/drive-outline.svg",
}

export const automationIcons = {
   "timezone" : "assets/automation-icons/timezone.svg",
   "variables" : "assets/automation-icons/variables.svg",
   "exit" : "assets/automation-icons/exit.svg",
   "back" : "assets/automation-icons/back.svg",
   "clock" : "assets/automation-icons/clock.svg",
   "branch" : "assets/automation-icons/branch.svg",
   "duplicate" : "assets/automation-icons/duplicate.svg",
   "setting" : "assets/automation-icons/setting.svg",
   "split" : "assets/automation-icons/split.svg",
   "child" : "assets/automation-icons/child.svg",
   "save" : "assets/automation-icons/save.svg",
   "edit" : "assets/automation-icons/edit.svg",
   "cut" : "assets/automation-icons/cut.svg",
   "delete" : "assets/automation-icons/delete.svg"
}