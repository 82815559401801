import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { AttributesLxIcons, commonIcons } from "Enums/attributes-icons";
import { CommonUtils } from "app/services/CommonUtils.service";
import { HttpTransferService } from "app/services/httpTransfer.service";
import { ContentEditableInputNoteComponent } from "../dashboard-reusables/content-editable-input-note/content-editable-input-note.component";
import { ConstantService } from "Enums/Constant.service";
import { DashboardUtilsService } from "app/services/dashboard-utils.service";
import { log } from "console";
import { MediaIcons } from "Enums/media-icons";
import { NgbModal, NgbPopover } from "@ng-bootstrap/ng-bootstrap";
import { MqttmessagingService } from "app/services/mqttmessaging.service";
import { Clipboard } from "@angular/cdk/clipboard";
import * as he from 'he'; 
import { CustomStorageService } from "app/services/custom-storage.service";
import { MessageService } from "app/message.service";
import { BasicUtils } from "app/services/basicUtils.service";

@Component({
    selector: 'app-chat-messages',
    templateUrl: './chat-messages.component.html',
    styleUrls: ['./chat-messages.component.scss']
  })
  
  export class ChatMessagesComponent implements OnInit {
    @Input() dashId :string;
    @Input() selectedChatObj:any;
    @Input() selectedLeadObj:any;
    @Input() currentActiveTab:any;
    @Input() objFilterForMultiselect: any;
    @Input() bulkSelectionFlag: boolean = false;
    @Input() leadInfo:any[]=[]
    @Output() connectedLead = new EventEmitter<any>();
    @Output() sendEditorText : EventEmitter<any> = new EventEmitter();
    @Output() closePopover = new EventEmitter<any>();
    @Output() getChatMsg : EventEmitter<any> = new EventEmitter();
    @Output() restoreScrollPositionEmit : EventEmitter<any> = new EventEmitter();
    @Input() contactBoardId : any[]=[];
    @Input() disableAttachColumns:boolean= false;
    @Input() internalChat:boolean=false;
    rplyObj:any;
    @ViewChild(ContentEditableInputNoteComponent) editor!: ContentEditableInputNoteComponent;
    @ViewChild('optionsPopover') public optionsPopover : NgbPopover
    @Input() commentMediaArr:any[]=[];
    lxAttributesIcons = AttributesLxIcons
    @Input() selectedChatUserMsgarr: any[]=[];
    attachmentArr: any[]=[];
    uploadMediaArr:any[]=[];
    MediaType: { IMAGE: string[]; VIDEO: string[]; FILE: any[]; AUDIO: string[];};
    count: any=0;
    orgUsersJson: any;
    rplyEnable:boolean= false;
    mediaIcons = MediaIcons;
  mediaArr: any[]=[];
  commentId: any;
  previewIndex: number = 0;
  mqttSubscription: any;
  @Input() disableEditor: boolean = false;
  enableInfo:boolean=false;
  copyleadInfoForcomment: any;
  showScrollToBottom: boolean = false;
  @ViewChild('scrollToBottom', { static: false }) scrollToBottomRef: ElementRef;
  commentMentions: any[];
  commentMentionsCopy: any[];
  orgUserArr: any[]=[];
  loggedInUserId: string;
  commentCurrentPage = 1;
  commentTotal_Page: any;
  pageSizeForComments = 10;
  @Input() chatMsgcurrentPage: any;
  @Input() pageSizeForChatMsg: number;
  @Input() chatMsgTotal_Page: number;
  loading: boolean = false;
  @Input() restoreScrollPosition : any;
  @Input() internalChatbot:boolean=false;
  chatBotLoader: boolean=false;
  chatbotSendId: any;
  chatbotMsgCount: number=0;
  constructor(public httpTransfer : HttpTransferService,public modalServices : NgbModal,public commonUtils : CommonUtils, public constant : ConstantService,public dashboardUtils : DashboardUtilsService,private MqttmessagingService: MqttmessagingService,
    private clipboard : Clipboard, private customStorageService : CustomStorageService,private msgservice : MessageService,private basicUtils:BasicUtils, private cdr: ChangeDetectorRef){
    this.MediaType = this.constant.getMediaTypes()
    this.listenForMqttMessages();
  }

  listenForMqttMessages() {
    this.mqttSubscription = this.MqttmessagingService.getMqttMessage().subscribe(message => {
      if(message) {
        this.updatesFromWss(message)
      }
    });
  }

  updatesFromWss(messagees) {
    let message = JSON.parse(messagees);
    if (message[ConstantService.constant.OBJECTTYPEKEY] == "LEAD" && this.contactBoardId.includes(message?.additional_attributes?.dashboard_id)){
      this.connectedLead.emit(message?.additional_attributes?.connected_leads)
    }
    else if(message[ConstantService.constant.OBJECTTYPEKEY] == "COMMENT" && message[ConstantService.constant.ACTIVITYTYPEKEY] == 'ADD' && message?.additional_attributes?.details?.type == 'INTERNAL' && this.dashId == message?.additional_attributes?.details?.dashboard_id && message?.additional_attributes?.details?.lead_id == this.selectedLeadObj?._id){
      message.additional_attributes.details['createdTime']  = this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(message?.additional_attributes?.details?.create_date))['date'] ? this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(message?.additional_attributes?.details?.create_date))['date'] +" "+ this.commonUtils.getAgingTimeForHeatMap(message?.additional_attributes?.details?.create_date)['time'] : this.commonUtils.getAgingTimeForHeatMap(message?.additional_attributes?.details?.create_date)['time']
     let index = this.selectedChatUserMsgarr.findIndex(m=>m?._id == message?.additional_attributes?.details?._id)
     if (index === -1) {
      this.selectedChatUserMsgarr.push(message?.additional_attributes?.details);
      this.chatbotMsgCount = this.chatbotMsgCount +1
      if(this.internalChatbot && this.chatbotMsgCount==1){this.chatBotLoader = true}
      if(this.chatbotMsgCount>1){
        this.chatBotLoader = false;
        this.chatbotMsgCount = 0
      }
    }
       this.parsePostedComments()
    }
  }
  
  async ngOnInit() {
    console.log(this.selectedChatUserMsgarr)
    this.loggedInUserId = this.customStorageService.getItem('id')  
    this.orgUsersJson = await this.dashboardUtils.getOrgUsers(); 
    this.orgUserArr = Object.values(this.orgUsersJson);
    if(this.selectedLeadObj){
      this.selectedLeadObj = this.commonUtils.calcuateCommentCount(this.selectedLeadObj,this.selectedLeadObj)
    this.copyleadInfoForcomment=JSON.parse(JSON.stringify(this.selectedLeadObj))
    this.copyleadInfoForcomment['unreadCount'] = this.copyleadInfoForcomment?.totalCommentCount - this.copyleadInfoForcomment?.totalReadCommentCount
    this.copyleadInfoForcomment['lastReadTime'] = this.copyleadInfoForcomment?.readCommentDetails?.[`${this.selectedChatObj?.channel_id}_${this.selectedChatObj?.chat_user_id}`] ? this.copyleadInfoForcomment?.readCommentDetails?.[`${this.selectedChatObj?.channel_id}_${this.selectedChatObj?.chat_user_id}`]?.last_read_time :
    this.copyleadInfoForcomment?.readCommentDetails['INTERNAL']?.last_read_time;
    if(this.copyleadInfoForcomment['unreadCount']>0){
      setTimeout(()=>{
        const element = document.getElementById('unreadmsg_'+this.selectedLeadObj._id);
      if (element) {
     // Scroll to the element
       element.scrollIntoView({
       behavior: 'smooth',
       block: 'start', // or 'end', 'center', 'nearest'
       inline: 'nearest'
       });
       this.cdr?.detectChanges();
      } 
      },100)
    } 
  }
   if(this.currentActiveTab=='commentTab' && !this.bulkSelectionFlag){
    this.disableEditor = false;
   this.getCommentsForLead()
   }
  }

  ngOnChanges(changes: SimpleChanges) {
     if(changes['restoreScrollPosition']){
      this.restoreScrollPosition = changes['restoreScrollPosition']?.currentValue
       setTimeout(()=>{
        this.scrollToRply(this.restoreScrollPosition)
       },500)    
      }
    }

  getCommentsForLead(scrollId?) {
    var inputJson:any = {
      lead_id: [this.selectedLeadObj?._id],
      type : ["INTERNAL"],
       pagination_details:{
      page_number : this.commentCurrentPage,
      page_size : this.pageSizeForComments
      } 
    };
    if (this.dashId !== undefined) {
      inputJson.dashboard_id = [this.dashId];
    }
    const getComments = this.internalChat
        ? this.httpTransfer.getCommentCorrespondingChat(inputJson)
        : this.httpTransfer.getCommentCorrespondingLead(inputJson);
    getComments.subscribe((res : any) => {
        this.selectedChatUserMsgarr = this.selectedChatUserMsgarr?.length ? [...res.result?.commentsInfo.reverse(),...this.selectedChatUserMsgarr] : res.result?.commentsInfo.reverse();
        this.commentTotal_Page = res?.result?.pagination_details?.total_pages;
        console.log(this.selectedChatUserMsgarr)
        this.parsePostedComments()
       this.selectedChatUserMsgarr.forEach(m=>{
          m['createdTime']  = this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(m?.create_date))['date'] ? this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(m?.create_date))['date'] +" "+ this.commonUtils.getAgingTimeForHeatMap(m?.create_date)['time'] : this.commonUtils.getAgingTimeForHeatMap(m?.create_date)['time']
      });
      this.restoreScrollPosition = scrollId
      setTimeout(()=>{
        this.scrollToRply(scrollId)
      },500)
    })
  }
  
  removeMedia(){
    this.commentMediaArr=[]
  }

  sendChatMsg(event?, tempId?){
    if(this.currentActiveTab!='commentTab'){
    let commentObj = {
      //"chat_user_id": this.selectedChatObj?.chat_user_id,
      "channel_id": this.selectedChatObj?.channel_id,
      "type":"EXTERNAL_OUTBOUND",  // INTERNAL/EXTERNAL_INBOUND/EXTERNAL_OUTBOUND/
      "temporary_id" : tempId ? tempId : this.commonUtils.getRandomString(10),
      "send_to": [
        {
            "lead_id": this.selectedLeadObj?._id,
        }
    ]
    }
    if(this.selectedLeadObj?._id === this.selectedChatObj?.message_group_item_id){
      commentObj['send_to'][0]['chat_user_id'] = this.selectedChatObj?.chat_user_id
    }
    else{
      commentObj['send_to'][0]['group_id'] = Array.isArray(this.selectedChatObj?.message_group_id) 
      ? this.selectedChatObj?.message_group_id[0] 
      : this.selectedChatObj?.message_group_id;
    }
    if(event?.text){
      commentObj["message_data"]={'text': this.commonUtils.decodeEntitiesWithAttributes(event?.text)}
    }
    if(this.uploadMediaArr?.length){
      commentObj["message_data"] = {'attachments': this.uploadMediaArr}
    }
    if(event?.reply_comment_id || this.rplyObj?.reply_comment_id){
      commentObj['reply_comment_id'] = event?.reply_comment_id || this.rplyObj?.reply_comment_id
    }
    this.editor?.resetText()
    this.attachmentArr = []
    if(!this.uploadMediaArr?.length){
      let obj = JSON.parse(JSON.stringify(commentObj))
      obj["created_by"] = this.customStorageService.getItem("id")
      this.selectedChatUserMsgarr.push(obj)}
     this.httpTransfer
    .postMessageForChannel(commentObj).subscribe((res : any) => {
     if(res.status==200){
      this.rplyEnable = false;
      this.rplyObj = {}
      this.uploadMediaArr = [];
      this.editor.enableDisableEditor(true)
      this.editor.setFocusOnDiv()
     }
    },
    (err) => {
      this.selectedChatUserMsgarr.forEach(p=>{
        if(p?.temporary_id && p.temporary_id == commentObj?.temporary_id){
          p['status'] = "FAILED"
          this.uploadMediaArr = [];
        }
      })
      this.editor.enableDisableEditor(true)
    }
    )
  }
  else{
    this.sendInternalComment(event)
  }
  }


  sendInternalComment(event?){
    if (event?.text != "") {
      var commentObj = {};
      let commentParsedString;
      if(event && event?.text){
        commentParsedString = this.commonUtils.decodeEntitiesWithAttributes(event?.text)
      }
      this.copyleadInfoForcomment.notShowUnreadMsg=true
      var isUserMentionedFlag = false;
      let mentionsUsers=[]
      if(commentParsedString && commentParsedString!==''){
        this.orgUserArr.forEach(element => {
          if (commentParsedString.includes(element._id) && !mentionsUsers[element._id]) 
          {
              isUserMentionedFlag = true;
              let count= Object.keys(mentionsUsers).length;
              commentParsedString = commentParsedString.split("{{"+element._id+"}}@"+element.name).join("{{"+count+"}}");
              let obj={
                "type":"USER",
                "type_id": element._id
              }
              mentionsUsers[element._id]=obj  
          }
        });
        if(Object.keys(mentionsUsers).length>0){
          commentObj["mentions"]=Object.values(mentionsUsers);
        }
        if(commentObj["message_data"]){
          commentObj["message_data"]['text']= commentParsedString
        }else{
          commentObj["message_data"] = {'text' : commentParsedString};
        }
      }
        if(this.uploadMediaArr?.length){
          this.uploadMediaArr.forEach(m=>{
            let commentInput = '';
            if(m?.caption){
              commentInput = this.commonUtils.decodeEntitiesWithAttributes(m?.caption)
            }
            this.orgUserArr.forEach(element => {
              if (commentInput!=='' && commentInput.includes(element._id) && !mentionsUsers[element._id]) 
              {
                  isUserMentionedFlag = true;
                  let count= Object.keys(mentionsUsers).length;
                  m.caption = commentInput.split("{{"+element._id+"}}@"+element.name).join("{{"+count+"}}");
                  let obj={
                    "type":"USER",
                    "type_id": element._id
                  }
                  mentionsUsers[element._id]=obj  
              }
            });
          })
          if(Object.keys(mentionsUsers).length>0){
            commentObj["mentions"]=Object.values(mentionsUsers);
          }
          commentObj["message_data"] = {'attachments': this.uploadMediaArr}
        }
      if(this.bulkSelectionFlag && this.leadInfo?.length>1){
        let inputJson={}
        inputJson['filter_properties']=this.objFilterForMultiselect
        inputJson['update_json']=commentObj
        this.httpTransfer.updateMultipleDashboard(this.dashId,inputJson).subscribe((res : any)=>{
          if(res.status==200){
          this.editor?.resetText()
          this.uploadMediaArr = [];
          this.attachmentArr = [];
            this.closePopover.emit(true)       
          }
        })
      }
      else{
        const createCommpent = this.internalChat
        ? this.httpTransfer.postCommentCorrespondingChat(commentObj, this.selectedLeadObj?._id)
        : this.httpTransfer.postCommentCorrespondingLead(commentObj, this.dashId, this.selectedLeadObj?._id);
        createCommpent.subscribe((res : any) => {
         if(res.status==200){
          this.editor?.resetText()
          this.uploadMediaArr = [];
          this.attachmentArr = [];
          this.editor.enableDisableEditor(true)
          this.editor.setFocusOnDiv()
          this.closePopover.emit(true)       
         }
        },
        (err) => {
          this.editor?.resetText()
          this.uploadMediaArr = [];
          this.attachmentArr = [];
          this.editor.enableDisableEditor(true)
          this.closePopover.emit(true)       
        })  
      } 
    }
  }

  onUploadFile(event){
    console.log(event)
  }

  async sendWithattachement(mediaArr){
    this.commentMediaArr = []
    this.attachmentArr = mediaArr
    let temp_id = this.commonUtils.getRandomString(10)
    mediaArr.forEach(async (e,index)=>{
      e['temp_id'] = temp_id
      e.indexKey= index
        if(!e?.file_path && !e?.path){
          await this.commonUtils.onUploadFile([e?.eventFile],'LEAD',null,this.postFileCommentForLead.bind(this),e)
        }
        else{
          this.postFileCommentForLead([{'file_name': e?.file_name ? e?.file_name : e?.name,'file_path' : e?.file_path ? e?.file_path : e?.path,'media_type' : e?.fileType}],e)
        }
    })  
  }

   mediaColumns(event){
    console.log(event)
    let arr = []
    if(!this.commentMediaArr?.length && event?.media?.length){
      if(event?.media?.length){
        event.media.forEach(e=>{
         if(e?.file_name || e?.file_path){
          let obj = {}
          obj['image_download_url'] = e?.file_url ? e?.file_url : e?.url
          obj['file_path'] = e?.file_path
          obj['file_name'] = e?.file_name
          if(this.MediaType.IMAGE.includes(e?.file_path.split('.').pop())){
          obj['type'] = "image" +"/"+e.file_path.split('.').pop()
          }
          else if(this.MediaType.VIDEO.includes(e?.file_path.split('.').pop())){
            obj['type'] = "video" +"/"+e?.file_path.split('.').pop()
          }
          else if(this.MediaType.AUDIO.includes(e.file_path.split('.').pop())){
            obj['type'] = "audio" +"/"+e?.file_path.split('.').pop()
          }
          else{
            obj['type'] = "file" +"/"+e?.file_path.split('.').pop()
          }
          arr.push(obj)
         }
        })
      }
      this.commentMediaArr = [...this.commentMediaArr,...arr]
      if (this.commentMediaArr.length > 0 && this.commentMediaArr[0]) {
        this.commentMediaArr[0]['caption'] = event?.text;
      }
      console.log(this.commentMediaArr)
    }
  }

  postFileCommentForLead(fileArr,callbackParams){
    let commentObj = {
      "chat_user_id": this.selectedChatObj?.chat_user_id,
      "channel_id": this.selectedChatObj?.channel_id,
      "type":"EXTERNAL_OUTBOUND",  // INTERNAL/EXTERNAL_INBOUND/EXTERNAL_OUTBOUND/
       "message_data" :{'attachments' : []},
       'temporary_id' : callbackParams?.temp_id
    }
    this.count++;
   fileArr.forEach(e=>{
    let fileJson = {};
    fileJson['file_name'] = e['file_name']+"."+e['file_path']?.split('.').pop();
    //fileJson['name'] = e['file_name']+"."+e['file_path']?.split('.').pop();
    fileJson['file_path'] = e['file_path'];
    if(callbackParams?.caption?.trim()?.length>0){
      fileJson['caption'] = callbackParams?.caption;
    }
    fileJson['file_type'] = e['media_type'].toUpperCase()=='TEXT' ? 'DOCUMENT' : e['media_type'].toUpperCase()
    this.uploadMediaArr.push(fileJson)
    let tempObj = JSON.parse(JSON.stringify(fileJson))
    tempObj['url'] = callbackParams?.url
    commentObj['message_data']['attachments'].push(tempObj)
    let obj = JSON.parse(JSON.stringify(commentObj))
    obj["created_by"] = this.customStorageService.getItem("id")
    if(this.currentActiveTab!='commentTab'){this.selectedChatUserMsgarr?.push(obj)}
    if(this.count==this.attachmentArr?.length){
      this.count = 0;
      this.currentActiveTab!='commentTab' ?
      this.sendChatMsg(null,callbackParams?.temp_id) :
      this.sendInternalComment()
   }
   })
}

getReplyObj(item){
  this.rplyEnable = false;
  this.rplyObj = {}
  this.rplyObj = item
  this.rplyEnable = true;
}

scrollToRply(id){
  if(id && document.getElementById(id)){
  document.getElementById(id).scrollIntoView({
    behavior: "smooth",
    block: "start",
    inline: "nearest"
  });
  this.cdr?.detectChanges();
}

}

open(del,data,id?) {
  this.commentId = id
  this.previewIndex = 0;    
    this.mediaArr = []    
   let obj={}
   if(data?.file_type == 'IMAGE'){
    obj['type'] = 'MultipleImage'
  }
  if(data?.file_type == 'VIDEO'){
    obj['type'] = 'MultipleVideo'
  }
  if(data?.file_type == 'DOCUMENT' || data?.file_type == 'APPLICATION' || data?.file_type == 'FILE'){
    obj['type'] = 'MultipleFile'
  }
  obj['name'] =  data?.file_path
  obj['url']  =  data?.file_url ? data?.file_url : data?.file_path
  obj['path'] = data?.file_path
  obj['size_in_bytes'] = data?.size_in_bytes?this.commonUtils.bytes_to_size(data?.size_in_bytes):''
  this.mediaArr.push(obj)
  let modalRef = this.modalServices.open(del, { size: 'lg' , windowClass: 'file preview-modal'})
  this.basicUtils.storeModlRef(modalRef)
}

scrollToBottomOfElement() {
  if (this.scrollToBottomRef) {
    this.scrollToBottomRef.nativeElement.scrollTop = this.scrollToBottomRef.nativeElement.scrollHeight;
    this.showScrollToBottom = false;
  }
}

onScroll(event) {
  if (this.scrollToBottomRef) {
    const element = this.scrollToBottomRef.nativeElement;
    this.showScrollToBottom = element.scrollHeight - element.scrollTop !== element.clientHeight;
  }
  const container = event.target as HTMLElement;
  if(this.currentActiveTab!='commentTab'){
  // Check if the user is scrolling to the top
  if (container.scrollTop === 0 && !this.loading && this.selectedChatUserMsgarr?.length) {
    this.loading = true;
    // Simulate loading more messages (you should fetch data from your API)
    this.getChatMsg.emit(this.selectedChatUserMsgarr[0]?._id)
        if (this.chatMsgcurrentPage <= this.chatMsgTotal_Page) {
           // Scroll back to the previous position
       // container.scrollTop = container.scrollHeight / 2;
        } 
  }
  }
  if(this.currentActiveTab=='commentTab'){
    if (container.scrollTop === 0 && !this.loading) {
      this.loading = true;
      // Simulate loading more messages (you should fetch data from your API)
      this.commentCurrentPage = this.commentCurrentPage + 1;
      if (this.commentCurrentPage <= this.commentTotal_Page) {
        this.getCommentsForLead(this.selectedChatUserMsgarr[0]?._id);
      //  container.scrollTop = container.scrollHeight / 2;
      }
    }
  
  }
  this.loading = false

}


copytoClip(item){
  item = this.commonUtils.htmlToPlainText(item)
  this.clipboard.copy(item)
}

getTemplateMedia(event){
  console.log(event)
  let arr = []
  event?.attachments.forEach(m=>{
    let obj = {}
    obj['file_name'] = m?.file_name,
    obj['fileType'] = m?.file_type,
    obj['image_download_url'] = m?.url,
    obj['file_path'] = m?.file_path
    arr.push(obj)
  })
  this.commentMediaArr = arr
  this.msgservice.setChannelTemplateMessage(event?.message)
  //this.sendEditorText.emit(event?.message)
}

parsePostedComments() {
  for (var i = 0; i < this.selectedChatUserMsgarr.length; i++) {
   /*  if(this.selectedChatUserMsgarr[i].reply_comment_id) {
      this.setReplyCommentObj(this.commentsCorrespondingClickedLead[i])
    } */
    let commentText = (this.selectedChatUserMsgarr[i]["message_data"]?.attachments?.length && this.selectedChatUserMsgarr[i]["message_data"]?.attachments[0]?.caption ) ? this.selectedChatUserMsgarr[i]["message_data"]["attachments"][0]?.caption : this.selectedChatUserMsgarr[i]["message_data"]["text"]
    let wordArr=  commentText//?.replaceAll('<'," &lt;")
    if(wordArr){
      wordArr = wordArr.split(/({{|}})/g);
    }
    let mentionsList=this.selectedChatUserMsgarr[i]["mentions"]
    let updatedArr = [];
    let count = 0
    while (count < wordArr?.length) {
      let element = wordArr[count];
      if(element == "{{"){
        let firstWord = '';
        if(wordArr[count+1] != undefined){
          firstWord = wordArr[count+1]
        }
        let secondWord = '';
        if(wordArr[count+2] != undefined){
          secondWord = wordArr[count+2]
        }
        updatedArr.push(wordArr[count] + firstWord + secondWord);
        count = count + 3;
      }else{
        updatedArr.push(element);
        count++;
      }
    }
    let comment = "";
    updatedArr.forEach(element => {
      comment += " "+this.getSuitableDataForComments(element,mentionsList);
    });
    this.selectedChatUserMsgarr[i]["commentText"] = comment;
  }
}

getSuitableDataForComments(word,mentions?){
  let updatedWord = word
  if(mentions?.length==0 || !mentions) return updatedWord;
  if(mentions?.length>0){
      for(let i=0;i<mentions.length;i++){
        let mentionVal="{{"+i+"}}"
        if(updatedWord.includes(mentionVal)){
          let userName="<span class='mentionName'>@"+this.orgUsersJson[mentions[i]?.type_id]?.name+"</span>" || mentionVal
          updatedWord = updatedWord.replace(mentionVal,userName);
        }
      }
      return updatedWord;
  }
}

getMentionColor(){
  $('.mentionName').css('color','blue');
}

deleteComment(comment) {
  const deleteComment = this.internalChat
        ? this.httpTransfer.deleteCommentCorrespondingChat(comment._id, comment.lead_id,)
        : this.httpTransfer.deleteCommentCorrespondingLead(comment._id, comment.lead_id,this.dashId);
    deleteComment.subscribe((res : any) => {
    if(res.status === 200) {
      let index = this.selectedChatUserMsgarr.findIndex(index=>index._id===comment._id);
    if(index>-1) {
      this.selectedChatUserMsgarr.splice(index,1);
    }
    }
  });
}

ngOnDestroy() {
  this.mqttSubscription?.unsubscribe();
}

}
 

