import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { CommonUtils } from 'app/services/CommonUtils.service';

@Component({
  selector: "priority-cell",
  changeDetection:ChangeDetectionStrategy.OnPush,
  templateUrl: "./priority-cell.component.html",
  styleUrls: ["./priority-cell.component.scss"],
})
export class PriorityCellComponent implements OnInit 
{
  params: any={};
  public selectedPriorities: any[]= []; 
  mirrorColumnsJson: any[] 
  priorityJson:any={}
  itemInfo:any={}

  constructor(private commonUtils: CommonUtils,private cdr:ChangeDetectorRef) {}


  async ngOnInit() {
    this.priorityJson = this.commonUtils.priorityJson;
    this.onUpdate()
  }
  onUpdate(params: any={}){
    this.itemInfo=this.params?.item || {}
    this.cdr.detectChanges()
    
  }
  updatePriority(value){
    if(this.params.value==value){return}
    this.params.value=value
    this.params?.gridTableRef?.changedCellValue({value:{[this.params?.field]:value},params:this.params});
    this.onUpdate()
  }

}
