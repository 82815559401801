import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CommonUtils } from 'app/services/CommonUtils.service';
import { AttributesIcons } from 'Enums/attributes-icons';
import { Clipboard } from '@angular/cdk/clipboard';


@Component({
  selector: 'app-email-selection',
  templateUrl: './email-selection.component.html',
  styleUrls: ['./email-selection.component.scss']
})
export class EmailSelectionComponent implements OnInit {
  @Input() editable: boolean = false
  @Input() fieldType:string;
  @Input() currenValue:any;
  @Input() isGrid:boolean=false
  @Input() dashId:any
  @Input() item:any
  @Input() selectedLeadObj:any
  @Input() addMoreEmail:boolean=false
  @ViewChild('container') container: ElementRef;
  @Output() onValueUpdate: EventEmitter<any> = new EventEmitter();
  @Output() emitAddMore: EventEmitter<any> = new EventEmitter();

  attributesIcons = AttributesIcons;
  selectedIndex:any=0;
isReadOnly:boolean=false;
  removePopoverRef:any
  @Input() isAutomationCall:boolean=false

  constructor(private cdr:ChangeDetectorRef,private commonUtils:CommonUtils,private clipboard: Clipboard){
  }

  async ngOnInit() {
    this.onValueChange()
      let userRole=await this.commonUtils.isLeadOrDashboardReadOnlyOrViewOny(this.dashId,this.selectedLeadObj);
    if(userRole){
      if((userRole?.is_readOnly_user && userRole?.is_collabrator_user))
      {this.isReadOnly = false;}
      else{
        this.isReadOnly = userRole?.is_readOnly_user;
      }
    }
    
  }

  onValueChange(){
    this.item['enable']=[]
    this.item['validateToEmail']=[]
    this.item['enableNewButton']=true
    if(this.item?.type=='Email' && this.item['valueData']==null){this.item["valueData"]=''}
    else{this.item['multiEmailInput'] = Array.isArray( this.item?.valueData)? [...this.item.valueData]: this.item?.valueData?.length > 0 ?[this.item.valueData]:[''];}
    if(this.item?.type=='MultipleEmail' && !this.isGrid){
      this.addNewMultiEmail(this.item);
      this.multiEmailCall(this.item)
    }
    this.validateEmailUrl()
    this.cdr.detectChanges()  
    console.log(this.item)
  }

  
  ngOnChanges(changes: SimpleChanges){
    if(changes['currenValue']){
      this.onValueChange();
    }
    if(changes['addMoreEmail']){
      if(this.addMoreEmail){this.addNewMultiEmail(this.item,true);this.multiEmailCall(this.item)}
    }
  }

  async onValueInput(){
    let validaEmail=await this.item.validateToEmail.some(ele=>!ele)
    if(!validaEmail){
      if(this.item?.type=='MultipleEmail'){this.onValueUpdate.emit(this.item?.multiEmailInput);this.item.valueData=this.item?.multiEmailInput;;this.onValueChange()}
      else{if(this.item?.multiEmailInput?.length==0){this.item.multiEmailInput[0]=''}
        this.onValueUpdate.emit(this.item?.multiEmailInput[0]);this.item.valueData=this.item?.multiEmailInput;
        this.onValueChange()
      }
    }
  }
  

  ngOnDestroy() {
  }

  addNewMultiEmail(item,bool=false){
    if(bool){item.multiEmailInput.push('');
    let lengthValide=item.multiEmailInput.length
    item.enable[lengthValide]=true;
    item.validateToEmail[lengthValide]=false;
    item.adding=true
    this.onValueInput()
  }
  }
  
  removeEmail(item, index){
    if(item.multiEmailInput?.length > 1){
      item.enableNewButton=true
      item.multiEmailInput.splice(index, 1);
      item.validateToEmail.splice(index,1)
      this.removePopoverRef?.close()
      this.onValueInput();
      this.emitAddMore?.emit(true)
    }
  }

  trackByFn(index){
      return index;
  }

  async validateEmailUrl(){
    this.item.validateToEmail = []
    this.item?.multiEmailInput?.forEach((ele,i)=>{
      this.item.validateToEmail[i]=ele.trim().length? this.commonUtils.ValidateEmail(ele.trim()) : true;
    })
  }

  multiEmailCall(item){
    item.enableNewButton=true
    item?.multiEmailInput?.forEach(ele=>{
      if(ele.trim().length==0){
        item.enableNewButton=false;
      }
    })
  } 

  allEmailsPresent(){
    return this.item?.type==='MultipleEmail' && this.item.multiEmailInput?.every(val=>val?.length > 3)
  }

  makePrimary(i){
    let splicedEmail=this.item?.multiEmailInput[i]
    this.item?.multiEmailInput?.splice(i,1);
    this.item.multiEmailInput.unshift(splicedEmail);
    console.log(this.item?.multiEmailInput);
    this.onValueInput()
  }

  public copyToClipboard(value: any): void {
    this.clipboard?.copy(value);
  }

}
