import { Component, Input, OnInit, ViewChild, ElementRef, SimpleChange, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { CommonUtils } from '../../../services/CommonUtils.service';
import { HttpTransferService } from '../../../services/httpTransfer.service';
import { NgbModal, ModalDismissReasons,NgbModalRef, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Router,ActivatedRoute, ParamMap } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard'
import { ToasterService } from '../../../services/toaster.service';
import { environment } from '../../../../environments/environment';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import { MqttmessagingService } from '../../../services/mqttmessaging.service';
import { ConstantService } from '../../../../Enums/Constant.service';
import { DashboardUtilsService } from '../../../services/dashboard-utils.service';
import { BasicUtils } from 'app/services/basicUtils.service';
import { MessageService } from 'app/message.service';
import { commonIcons, AttributesIcons, NavBarIcons } from 'Enums/attributes-icons';

@Component({
  selector: 'app-notes-view',
  templateUrl: './notes-view.component.html',
  styleUrls: ['./notes-view.component.scss']
})
export class NotesViewComponent implements OnInit {
  @Output() minimizeRight = new EventEmitter<any>();
  @Input() documentType:boolean=false;
  @Input() noteLink : boolean = false;
  @Input() noteWebLink : boolean = false;
  @Input() selectedDashId: any;
  @Input() users: any[]=[];
  boardInfo: any[]=[];
  folderInfo: any[]=[];
  @Input() activeEdit:boolean=true;
  @Input() itemCall:boolean=false;
  @Input() bottomBarCall:boolean=false;
  @Input() leadId: string;
  @Input() personalCall:boolean=false;
  @Input() searchText:string;
  @Input() currentViewId:any;
  subject: Subject<any> = new Subject();
  @ViewChild('emailSetRef', { static: false }) emailSetRefElement: ElementRef;
  emailSet: any = "";
  selectedemail: any[] = [];
  notes: any[] = [];
  selectedNoteForEmail: any[] = [];
  notedetails: any[] = [];
  createdBy: string = '';
  lastUpdatedBy: string = '';
  intialsName: string = '';
  createDate: any;
  lastUpdatedDate: any;
  title: string = 'Title';
  noteData: any;
  noteSharedStatus: string = 'Private';
  historyUpdatedDate: any;
  notesHistoryArray: any[] = [];
  selectedDashUsersList: any[] = [];
  getDashItems: any[] = [];
  searchActive: boolean = false;
  showHistory: boolean = false;
  noteHistoryDetails: any[] = [];
  @Input() selectedNoteId: string = "";
  selectAllUser: boolean = false;
  allDashUsersIdArray: any[] = [];
  allSelectedItemsId: any[] = [];
  searchUserList: any[] = [];
  leadResponse: any[] = [];
  loginUser: any[] = [];
  selectedSharedUserId: any[] = [];
  noteKey: any;
  selectEmailToAll: boolean = false;
  selectEmailToDashUser: boolean = false;
  showUser:boolean=false;
  searchUser: any[] = [];
  searchItem: any[] = [];
  countItems: any;
  countNotes:any;
  sharedToAllDashboard: any;
  selectedNoteText: string;
  itemPreviewData:any=[]
  resultArrayFromOrgUserArray : any;
  suggestedUserJson: any;
  suggetedUserList: any;
  filteredSuggestedEmails:any;
  suggestedAndOrgUserList:any;
  isMentionPanelOpen: boolean=false;
  noteInitialized = false
  requestPending: boolean=true;
  urlNoteId: string;
  searchValue: string;
  mqttSubscription: any;
  dashboardJsonSub: any;
  orgUsersJson: {};
  displayConnectNotes:any[]=[];
  @ViewChild('pop') public popover :NgbPopover;
  itemTerminology: any={};
  isFullScreen = false;
  isShareUsersPopoverOpen = false;
  isShareDashboardPopoverOpen = false;
  isShareItemPopoverOpen = false;
  isShareCreateDashboardPopoverOpen = false;
  isShareCreateItemPopoverOpen = false;
  isConnectItemOpen:boolean = false;
  removeFromSelected: any;
  leadData: any;
  selectedDashIds: any;
  boardsOfFolder: any=[];
  @Input() collaboratorArr: any[] = [];
  selectedBoards: any[] = [];
  selectedItems: any[] = [];
  @Output() close = new EventEmitter<void>();
  @Input() opnedNotes: any;
  dashboardData: any={};
  isReadOnly: boolean=true;
  selectedNoteDashId: any;
  previewData:any={}
  hideToolbar:boolean=true;
  commonIcons = commonIcons;
  attributesIcons = AttributesIcons;
  navBarIcons = NavBarIcons;
  constructor(private httpTransfer: HttpTransferService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    public commonUtils: CommonUtils,
    private modalService: NgbModal,
    private clipboard: Clipboard,
    private toastservice: ToasterService,
    private route: ActivatedRoute,
    private MqttmessagingService: MqttmessagingService,
    public constantService : ConstantService,
    private basicUtils:BasicUtils,
    private dashboardUtils:DashboardUtilsService,
    private msgservice: MessageService,
    private toaster :ToasterService
  ) 
    {
      this.listenForMqttMessages()
    }
  ngOnChanges(changes:SimpleChange) { 
      this.subject.next(this.searchText);
  }

  async ngOnInit() {
      let inputJson = {"status":["ACTIVE"],"type":["BOARD", "BOARD_GRAPH"], "access_type": ["INTERNAL", "PRIVATE"],"get_my_boards": true,}
      if(!this.noteLink){
        this.itemTerminology=this.dashboardUtils.getDashboardTerminoloy(this.selectedDashId)
        let inputForFolder = {"status":["ACTIVE"],"type":["WORK_FOLDER"], "access_type": ["INTERNAL", "PRIVATE"],"get_my_boards": true}
        this.httpTransfer.getDashBoardForAdmin(inputForFolder).subscribe((result:any) => {
          this.folderInfo = result.result.dashboards;
        })
        this.httpTransfer.getDashBoardForAdmin(inputJson).subscribe((result:any) => {
          this.boardInfo = result.result.dashboards;
          this.boardInfo.forEach(board => {
            const matchingWorkFolder = this.folderInfo?.find(folder => folder.dashboard_id === board.work_folder_id);
            if (matchingWorkFolder) {
              board.workFolderName = matchingWorkFolder.dashboard_name;
            }
          });
        })
        this.dashboardJsonSub = this.dashboardUtils.getUpdatedDashboardJson().subscribe(res => {
          this.users = res[this.selectedDashId]?.DASHBOARD_USER || [];
        })
      }
      if(!this.personalCall){
      this.searchUserList = [...this.users];
      this.searchUserList.forEach((user,index) => {
        if (user._id == localStorage.getItem("id")) { 
          this.loginUser.push(user)
          this.searchUserList.splice(index, 1)
        }
      })
    }
      if(!this.itemCall && !this.bottomBarCall && !this.noteLink){
        this.route.queryParamMap.subscribe((params: ParamMap) => { 
          if(params.get('noteId')) {
            this.selectedNoteId = params.get('noteId');
            this.urlNoteId = params.get('noteId');
          } 
        });
      }
      //this.orgUsersJson= !this.noteLink ? await this.dashboardUtils.getOrgUsers() : {}; 
      this.orgUsersJson= await this.dashboardUtils.getOrgUsers(); 
      this.getAllDashBoardNotes(false);
      this.debounceApiCallWithDelay()
  
      this.resultArrayFromOrgUserArray = Object.keys(this.orgUsersJson).map(key => {
        const { _id, name, email } = this.orgUsersJson[key];
        return { _id, name, email };
    });
    if(this.leadId && this.selectedDashId){
      this.dashboardData=await this.dashboardUtils.getAndSyncAllDashboardData([this.selectedDashId])
      this.isReadOnly= this.commonUtils.calcuateReadOnly(this.dashboardData[this.selectedDashId]?.DASHBOARD_INFO)
    }
  // this.suggestedAndOrgUserList = this.resultArrayFromOrgUserArray.concat(this.suggetedUserList);  
  this.cdr?.detectChanges()
  }

  listenForMqttMessages() {
    this.mqttSubscription = this.MqttmessagingService.getMqttMessage().subscribe(message => {
      if (message) {
          this.updatesFromWss(message)
      }
    });
  }
  updatesFromWss(messagees) {    
    let message = JSON.parse(messagees);  
    if(message[ConstantService.constant.OBJECTTYPEKEY] == "DASHBOARD_USER"){
        this.updateDashUser(message)
    }
    if(message[ConstantService.constant.OBJECTTYPEKEY] == ConstantService.constant.LEADOBJECTTYPE){
      this.updateLeadArr(message)
    }
    this.cdr?.detectChanges()
    
  }
  updateDashUser(message){
   if(message.additional_attributes.details.length){
    this.searchUserList = []
    this.searchUser = []
    message.additional_attributes.details.forEach(item=>{
      if(item._id != localStorage.getItem("id")){
      this.searchUserList.push(item)
      this.searchUser.push(item)
      }
  /*   console.log(message.additional_attributes.details.filter(
      ({ _id: id1 }) => !this.searchUser.some(({ _id: id2 }) => id1 === id2)
    ))
    let tempArrForUpdateUser = message.additional_attributes.details.filter(
      ({ _id: id1 }) => !this.searchUser.some(({ _id: id2 }) => id1 === id2)
    )
    tempArrForUpdateUser.forEach(item=>{
      if(item._id != localStorage.getItem("id")){
      this.searchUser.push(item)
      }*/
    }) 
     
   }
  }
  updateLeadArr(message){
    console.log(this.leadResponse)
    if(message.activity_type == "ADD"){
      this.leadResponse.push(message.additional_attributes.details)
      this.searchItem.push(message.additional_attributes.details)
    }
    if(message.activity_type == "DELETE"){
      let index = this.leadResponse.findIndex(data=>data._id == message.additional_attributes.details._id)
      this.leadResponse.splice(index,1)
      this.searchItem.splice(index,1)
      console.log(this.leadResponse)
    }
  }
  getAllItems(searchText?) {
    if((searchText && searchText.length>=2) || searchText===''){
      let inputJson = {}
      inputJson["search_params"] = {"search_text":searchText};
      this.httpTransfer.getLeadQuery(inputJson, this.selectedDashId).subscribe((res : any) => {
        if (res.status == 200) {
          this.leadResponse = res.result.leadResponse;
          this.leadResponse = this.leadResponse.filter(({ _id: id1 }) => !this.getDashItems.some(({ _id: id2 }) => id1 === id2));
        }
        this.cdr?.detectChanges()
      })
    }
  }
  debounceApiCallWithDelay() {
    this.subject.pipe(debounceTime(1000)).subscribe((text) => {
      this.getAllDashBoardNotes(true,text)
      //inputJson["search_params"] = {"search_text":searchText};
    })
  }
  getAllDashBoardNotes(isUpdate: boolean, searchtext?) {
       let inputJson = {}
    if(this.itemCall){
      inputJson ={
      "item_id": [this.leadId],
      "sorting_details": {
        "sort_order": "DESC",     //ASC or DESC
        "sort_by": "last_update"
      },
     }
    } else if(this.bottomBarCall || this.noteLink || this.noteWebLink) {
      inputJson={
        "note_id": [this.selectedNoteId],
        "sorting_details": {
          "sort_order": "DESC",
          "sort_by": "last_update"
        }
      }
    }
     else{
      inputJson ={
        "dashboard_id": !this.personalCall ? [this.selectedDashId] : null,
        "sorting_details": {
          "sort_order": "DESC",     //ASC or DESC
          "sort_by": "last_update"
        },
     }
     if(searchtext!=""){
      inputJson["search_params"] = {"search_text": searchtext};
     }
    }
    this.allDashUsersIdArray = [localStorage.getItem("id")]
    this.httpTransfer.getNote(inputJson).subscribe((res : any) => {
      if (res.status == 200) {
        this.requestPending =false;
        this.notes = res.result.notes;
        this.countNotes= res.result.notes.length;
        this.notes.forEach((noteJson)=>{
          if(this.selectedNoteId === noteJson._id) {
            this.noteData=noteJson;
          }
          if(this.selectedNoteId === noteJson._id && !this.title && !this.bottomBarCall && !this.noteLink){
            this.title = noteJson.title;
          }
          if(this.selectedNoteId === noteJson._id && this.bottomBarCall && this.noteLink){
            this.title = noteJson.title;
            this.showEditor(noteJson, this.selectedNoteId);
          }
          if(this.selectedNoteId === noteJson._id && noteJson.dashboard_id.length !==this.selectedBoards){
          this.collaboratorArr = this.resultArrayFromOrgUserArray.filter(user => noteJson.user_id.includes(user._id));
          this.selectedBoards = this.boardInfo.filter(dash => noteJson.dashboard_id.includes(dash.dashboard_id));
          }
          if(this.selectedNoteId === noteJson._id && noteJson.item_id.length !==this.selectedItems){
            this.httpTransfer.getLeadQuery({"lead_id": noteJson.item_id}).subscribe((result:any) => {
              let leadData = result.result.leadResponse;
              this.selectedItems = leadData;
            })
          }
        })
        if(!isUpdate) this.iterateAllNotesAndSetSelected()
      }
      this.cdr?.detectChanges()
    });
  }


  updatedNoteText(updatedNoteText: string){
    this.notes?.forEach((noteJson)=>{
      if(this.selectedNoteId === noteJson._id){
        noteJson.note_text = updatedNoteText
      }
    })
    this.cdr?.detectChanges()
  }

  updateNotesList(isUpdate: boolean) {  
    if(this.notes[0]._id!=this.selectedNoteId){
      this.getAllDashBoardNotes(isUpdate)
    }
    this.searchUserList.forEach((user,index) => {
      if (user.id == localStorage.getItem("id")) { 
        this.searchUserList.splice(index, 1)
      }
    })
  }

  iterateAllNotesAndSetSelected(){
    let noteFound = false
    this.notes.forEach((noteJson)=>{
      if(this.selectedNoteId === noteJson._id){
        this.onSelect(noteJson, this.selectedNoteId)
        noteFound = true
      }
    })
    if(!noteFound){
      //If none of the notes is selected, then auto select first note
      if(this.notes !=null && this.notes.length>0){
        this.onSelect(this.notes[0], this.notes[0]._id)
      }
      //If there are no notes show blank note and once user starts writing on it. It will create new note
      else if(this.notes ==null || this.notes.length==0){
        this.onSelect(null, null)
      }
    }
  }
  onSelect(noteJson: any, selectedNoteId: any) {
    this.searchActive = false;
    this.noteData=null;
    this.selectedBoards=[];
    this.selectedItems=[];
    if(!this.personalCall){   
       this.searchUserList = [...this.users];
       
       this.searchUserList.forEach((user,index) => {
        if (user._id == localStorage.getItem("id")) { 
          this.searchUserList.splice(index, 1)
        }
      })
      
    }    
    this.showUser=false;
    this.selectedDashUsersList = [];
    this.getDashItems = [];
    this.noteInitialized = false
    this.getNoteKeyFromServerOrGenerateNew(noteJson)
    if(this.urlNoteId != selectedNoteId && !this.bottomBarCall && !this.noteLink){
    this.router.navigate([],{relativeTo: this.route,
      queryParams: {noteId:selectedNoteId},
      queryParamsHandling: 'merge'});
    }
    if(noteJson!=null){
      
      
      this.createDate = this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(noteJson.create_date))['date']
/*       this.commonUtils.getAgingTimeForHeatMap(noteJson.create_date)['date']
 */      this.lastUpdatedDate = this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(noteJson.last_update))['date']
      this.getOrganisationUsers(noteJson)
      this.noteData = noteJson;
          this.collaboratorArr = this.resultArrayFromOrgUserArray.filter(user => noteJson.user_id.includes(user._id));
          this.selectedBoards = this.boardInfo.filter(dash => noteJson.dashboard_id.includes(dash.dashboard_id));
          if(noteJson.item_id.length) {
          this.httpTransfer.getLeadQuery({"lead_id": noteJson.item_id}).subscribe((result:any) => {
            let leadData = result.result.leadResponse;
            this.selectedItems = leadData;
          })
          }
  if(!this.personalCall){
        this.getNoteSharedType(noteJson);
        //this.getNoteSharedStatus(noteJson)
        this.getNoteConnectedItem(noteJson)
      }
    }
    else{
      this.noteSharedStatus='Private';
      this.countItems=0;
      this.searchUserList = [...this.users]
      // if(!this.personalCall){
      // this.getAllItems()
      // }
    }
    this.cdr?.detectChanges()
  }
  showEditor(data,id){
     this.selectedNoteId = id
     this.activeEdit = true;
     this.onSelect(data,id)
     
    if(this.personalCall){
      this.selectedNoteId=id
    }
    this.cdr?.detectChanges()
  }
  hideEditor(){
    if(this.itemCall){
     this.activeEdit=false
     this.selectedNoteId=""
    }
    this.cdr?.detectChanges()
  }
  createdNote() {
    let inputJson = {}
    if(this.itemCall){
  inputJson={
    "title":"Untitled Note",
      "dashboard_id": [this.selectedDashId],
      "item_id": [ this.leadId ],
      "note_text": "[]",
      "user_id": [localStorage.getItem("id")],
  }
    }
    else{
      inputJson={
      "title":"Untitled Note",
      "dashboard_id": [this.selectedDashId],
      "note_text": "[]",
    }
    }
    if(this.personalCall){ delete inputJson['dashboard_id']}

    setTimeout(() => {
      this.httpTransfer.createNote(inputJson).subscribe((res : any) => {
        if (res.status == 200) {
          this.selectedNoteId = !this.itemCall ? res.result.note_id : ""
          this.getAllDashBoardNotes(false)
        }
        this.cdr?.detectChanges()
      })
    }, 100);
  }
  getNoteKeyFromServerOrGenerateNew(noteJson ) {

    setTimeout(() => {
      if(noteJson){
        this.selectedNoteId = !this.itemCall ? noteJson._id : this.selectedNoteId
        this.title = noteJson.title;
        this.selectedNoteText = noteJson.summary
        this.noteInitialized = true;
      }
      else{
        this.selectedNoteId = null
        this.title = null;
      }
      this.cdr?.detectChanges()
      
    },25)

    
  }
  getNoteSharedType(note) {
    this.sharedToAllDashboard = null
    this.selectAllUser = false
    this.countItems = note.item_id?.length || 0 
    if (note.shared_details?.users.length <= 1 && note.shared_details?.type == "SPECIFIC") {
      this.noteSharedStatus = 'Private'
    }
    else {
      this.noteSharedStatus = 'Shared'
      if (note.shared_details?.type == "DASHBOARD" && note.shared_details?.users.length == 0) {
        this.sharedToAllDashboard = !this.itemCall ? 'Dashboard' :'Board'
        this.selectAllUser = !this.selectAllUser
      }
      if (note.shared_details?.type == "SPECIFIC" && note.shared_details?.users.length > 1) {
        this.sharedToAllDashboard = note.shared_details.users.length - 1;
      }
    }
  }
  getOrganisationUsers(note) {
    // this.orgUsersJson= await this.dashboardUtils.getOrgUsers(); 
    this.getHistoryOfNotes(note)
    this.createdBy = this.orgUsersJson[note?.created_by]?.name || ''
    this.intialsName = this.createdBy.charAt(0)
    this.lastUpdatedBy = this.orgUsersJson[note?.last_update_by]?.name || ''
    this.notedetails=Object.values(this.orgUsersJson)
  }
  getNoteSharedStatus(note) {
    if (this.noteSharedStatus == 'Shared') {
      this.selectedDashUsersList = [];
      let ids = note.shared_details.users.filter(id => id != localStorage.getItem("id"));
      this.selectedDashUsersList = this.users.filter(user => ids.some(id => user._id === id));
      this.searchUserList = this.users.filter(({ _id: id1 }) => !this.selectedDashUsersList.some(({ _id: id2 }) => id1 === id2));
      this.searchUserList.forEach((user,index) => {
        if (user._id == localStorage.getItem("id")) { 
          this.searchUserList.splice(index, 1)
        }
      })
      this.searchUser = this.searchUserList;
    }
    else {
     this.searchUserList.forEach((user,index) => {
      if (user.id == localStorage.getItem("id")) { 
        this.searchUserList.splice(index, 1)
      }
    })
      this.searchUser = this.searchUserList
    }
  }
  getNoteConnectedItem(note) {  
    this.getDashItems=[]
    let inputJson = { lead_id : note.item_id }
    let count = note.item_id?.length || 0
    if(count>0){
      this.httpTransfer.getLeadQuery(inputJson, this.selectedDashId).subscribe((res : any) => {
        if (res.status == 200) {
          this.getDashItems = res.result.leadResponse;
          }
          this.cdr?.detectChanges()
      })    
    }
   
  }
  
  
  
  getHistoryOfNotes(note) {
    let inputHistory = {}
    if(!this.noteLink && !this.noteWebLink){
      if(this.bottomBarCall) {
        inputHistory = {
          "history_note_id": [note._id],
          "my_notes": true
        }
      }else{
      inputHistory = {
        "history_note_id": [note._id],
        "dashboard_id": [this.selectedDashId]
      }
     }
      this.httpTransfer.getNote(inputHistory).subscribe((res : any) => {
        if (res.status == 200) {
          this.notesHistoryArray = [];
          for (var i = 0; i < res.result.notes.length; ++i) {
            this.notesHistoryArray.push(this.getNotesHistoryDetails(res.result.notes[i]))
          }
        }
      }); 
    }   
  }
  getNotesHistoryDetails(historyDetails: any) {
    
    
    historyDetails['last_updated_name'] = this.orgUsersJson[historyDetails.last_update_by]?.name 
    historyDetails['last_update_date'] = this.commonUtils.getHumanReadableDateAndTimeFromDate(new Date(historyDetails.last_update))['date']
    historyDetails['last_update_time'] = this.commonUtils.getAgingTimeForHeatMap(historyDetails.last_update)['time']
    return historyDetails;

  }
  selectedSharedUserForNote(user: any) {
    if(this.searchUser.length!=this.searchUserList.length){
      this.searchUserList = [...this.searchUser]
    }
    this.selectedDashUsersList.push(user)
    for (var i = 0; i < this.selectedDashUsersList.length; ++i) {
      this.allDashUsersIdArray.push(this.selectedDashUsersList[i]._id)
    }
    let inputJson = {}
    if(!this.itemCall){
      inputJson = {
        "dashboard_id": this.selectedDashId,
        "shared_details": {
          type: "SPECIFIC",
          users: this.allDashUsersIdArray
        }
      }
    }
    else{
      inputJson = {
        "dashboard_id": this.selectedDashId,
        "item_id":[this.leadId],
        "shared_details": {
          type: "SPECIFIC",
          users: this.allDashUsersIdArray
        }
      }
    }
    this.updateNote(this.selectedNoteId, inputJson)
    this.allDashUsersIdArray = [localStorage.getItem("id")]
    for (var i = 0; i < this.searchUserList.length; ++i) {
      if (user._id == this.searchUserList[i]._id) {
        this.searchUserList.splice(i, 1)
      }
    }
    this.sharedToAllDashboard=this.selectedDashUsersList.length
    if(this.selectedDashUsersList.length){
     this.noteSharedStatus='Shared'
    }
    
    this.searchUser = [...this.searchUserList]
    
  }
  selectItemForNote(lead) {
    this.getDashItems.push(lead)
    for (var i = 0; i < this.getDashItems.length; ++i) {
      this.allSelectedItemsId.push(this.getDashItems[i]._id)
    }
    if(this.itemCall){
      this.allSelectedItemsId.push(this.leadId)
       }
    let inputJson = {
      "dashboard_id": this.selectedDashId,
      "item_id": this.allSelectedItemsId
    }
    this.updateNote(this.selectedNoteId, inputJson)
    this.allSelectedItemsId = []
    this.countItems=this.getDashItems.length
  }
  updateHistory(noteHistoryDetails) {
    let inputJson = {
      "title": noteHistoryDetails.title,
      "note_text": noteHistoryDetails.note_text,
    }
    this.updateNote(noteHistoryDetails.history_note_id, inputJson)
    this.getAllDashBoardNotes(false)
  }
  updateNote(noteId, inputJson) {
    this.httpTransfer.updateNote(noteId, inputJson).subscribe((res : any) => {
      if (res.status == 200) {
        this.getAllDashBoardNotes(true);
        this.showUser=false;
      }
    })
  }
  removeSharedUserOfNote(user: any) {
    this.searchUserList.push(user)
    this.searchUser = [...this.searchUserList]
    for (var i = 0; i < this.selectedDashUsersList.length; ++i) {
      if (user._id == this.selectedDashUsersList[i]._id) {
        this.selectedDashUsersList.splice(i, 1)
      }
    }

    for (var i = 0; i < this.selectedDashUsersList.length; ++i) {
      this.allDashUsersIdArray.push(this.selectedDashUsersList[i]._id)
    }
    let inputJson = {
        "dashboard_id": this.selectedDashId,
        "shared_details": {
          type: "SPECIFIC",
          users: this.allDashUsersIdArray
        }
    }
    if(this.itemCall){
      inputJson 
        ["item_id"]= [this.leadId]
    }
    this.sharedToAllDashboard=this.selectedDashUsersList.length
    this.updateNote(this.selectedNoteId, inputJson)
    if(!this.selectedDashUsersList.length){
      this.noteSharedStatus="Private"
    }
    this.allDashUsersIdArray = [localStorage.getItem("id")];
  }
  deleteSelectedItemForNotes(lead) {
    for (var i = 0; i < this.getDashItems.length; ++i) {
      if (lead._id == this.getDashItems[i]._id) {
        this.getDashItems.splice(i, 1)
      }
    }
    for (var i = 0; i < this.getDashItems.length; ++i) {
      this.allSelectedItemsId.push(this.getDashItems[i]._id)
    }
    let inputJson = {
      "dashboard_id": this.selectedDashId,
      "item_id": this.allSelectedItemsId
    }
    this.updateNote(this.selectedNoteId, inputJson)
    this.countItems=this.getDashItems.length
    this.allSelectedItemsId = [];
    if(this.itemCall){
        if(this.leadId == lead._id){
        this.getAllDashBoardNotes(true)
        this.hideEditor()
        }
    }
  }
  selectedSearch() {
    this.showUser=false;
    this.searchActive = !this.searchActive;
  }
  selectHistory(historyNote) {
    
    if(historyNote?.note_text){
    var converter = new QuillDeltaToHtmlConverter(JSON.parse(historyNote?.note_text),{})
    historyNote.note_text = converter.convert();
    }
    this.noteHistoryDetails = historyNote;
    this.showHistory = !this.showHistory;
  }
  removeNoteCard() {
    let inputJson = {}
    this.httpTransfer.removeNote(this.selectedNoteId, inputJson).subscribe((res : any) => {
      if (res.status == 200) {
        this.getAllDashBoardNotes(false)
      }
      if(this.bottomBarCall) {
      this.closeNote();
      }
    })
  }
  selectAllDashUser() {
    this.selectAllUser = !this.selectAllUser
    for (var i = 0; i < this.users.length; ++i) {
      this.allDashUsersIdArray.push(this.users[i].id)
    }
    let inputJson = {
      "dashboard_id": this.selectedDashId,
      "shared_details": {
        type: "DASHBOARD",
        users: []
      }
    }
    if(this.itemCall){
    inputJson['item_id']=[this.leadId]
    }
    if (this.selectAllUser) {
      this.updateNote(this.selectedNoteId, inputJson)
      this.noteSharedStatus='Shared'
      this.sharedToAllDashboard=!this.itemCall ? 'Dashboard' :'Board'
    }
  }
  makePrivateNote() {
    this.selectAllUser = !this.selectAllUser
    let inputJson = {
      "dashboard_id": this.selectedDashId,
      "shared_details": {
        type: "SPECIFIC",
        users: this.allDashUsersIdArray
      }
    }
    if(this.itemCall){
      inputJson['item_id']=[this.leadId]
      }
    this.updateNote(this.selectedNoteId, inputJson)
    this.noteSharedStatus='Private'
    this.searchUserList = this.searchUserList.concat(this.selectedDashUsersList)
    this.selectedDashUsersList = []
    this.getAllDashBoardNotes(true)
  }
  onEmailsSetKeydown() {
    this.isMentionPanelOpen = false;
    const found = this.notedetails.some(element => element.email == this.emailSet);
    const match = this.selectedemail.some(element => element.email == this.emailSet);
    
    if (!found && !match) {      
      if(!this.commonUtils.ValidateEmail(this.emailSet)){
        this.toastservice.Errors("Enter Correct Email")
      }
      if(this.commonUtils.ValidateEmail(this.emailSet)){
      if (this.emailSet == "" || this.emailSet == null) return;
      this.selectedemail.push({ email: this.emailSet, name: this.emailSet });
      this.emailSet = "";
      }
    }
    if (found && !match) {
      if(this.commonUtils.ValidateEmail(this.emailSet)){
      for (var i = 0; i < this.notedetails.length; ++i) {
        if (this.emailSet == this.notedetails[i].email) {
          this.selectedemail.push({ email: this.notedetails[i].email, name: this.notedetails[i].name });
          this.emailSet = "";
        }
      }
      this.selectedemail = this.selectedemail.filter(item => item.email !== "");
      this.suggestedAndOrgUserList = this.suggestedAndOrgUserList.filter(item1 => !this.selectedemail.some(item2 => item2.email === item1.email));
    }
    else{
        this.toastservice.Errors("Enter Correct Email")
    }
    }
    if (match) {
      this.toastservice.Errors("Already Added In List Please Enter other Email")
    }
  }
  removeSelectedEmail(index: any) {
    if (index >= 0 && index < this.selectedemail.length) {
      const foundObject = this.selectedemail[index];
      this.suggestedAndOrgUserList.push(foundObject);
    }
    this.selectedemail.splice(index, 1);

  }
  filterUser(val) {
    
    // val = val.toLowerCase();
    // this.searchUserList = this.searchUser.filter(
    //   (user) => user.name.toLowerCase().indexOf(val) !== -1 || !val
    // );
    val = val.toLowerCase();
    this.searchUserList = this.searchUser.filter(
      (user) => user.name.toLowerCase().indexOf(val) !== -1 || user.email.toLowerCase().indexOf(val) !== -1 || !val
    );
  }
  exitUser() {
    
    
    for (var i = 0; i < this.selectedDashUsersList.length; ++i) {
      this.selectedSharedUserId.push(this.selectedDashUsersList[i]._id)
    }
    let inputJson = {
      "dashboard_id": this.selectedDashId,
      "shared_details": {
        type: "SPECIFIC",
        users: this.selectedSharedUserId
      }
    }
    this.updateNote(this.selectedNoteId, inputJson)
    this.getAllDashBoardNotes(false)
  }
  sendEmailToAll(event) {
    
    for(let q=0;q<this.users.length;q++){
      for(let w=0;w<this.selectedemail.length;w++){
         if(this.selectedemail[w].email == this.users[q].email ){
             this.selectedemail.splice(w,1)
         }
      }
    }
    
    this.selectEmailToAll = true
    if (this.selectEmailToAll) {
      for (var i = 0; i < this.users.length; ++i) {
        this.selectedemail.push(this.users[i])
      }
    }
    /* else {
      this.selectedemail = []
    } */
    if(!this.selectEmailToAll){

    }
  }
  sendEmailForDashUser() {
    
    if(this.selectedemail.length == this.users.length){
      this.selectedemail=[]
     }
     if(this.selectedemail.length > this.users.length){
       for(let q=0;q<this.selectedemail.length;q++){
         for(let t=0;t<this.users.length;t++){
           if(this.selectedemail[q].email == this.users[t].email){
            this.selectedemail.splice(q,1)
           }
         }
       }
     }
    this.selectEmailToDashUser = true
    if (this.selectEmailToDashUser) {
      for (var i = 0; i < this.selectedDashUsersList.length; ++i) {
        this.selectedemail.push(this.selectedDashUsersList[i])
      }
    }
  }
  sendEmail(title) {
    let result = this.selectedemail.map(a => a.email);
    let inputJson = {
      "share_with_emails": result,
      "subject": title
    }    
    this.httpTransfer.noteEmail(this.selectedNoteId, inputJson).subscribe(res => {
      if (res.status == 200) {
        this.toaster.Success("Email sent Successfully");
      }
    })
  }
  getTitleText() {
    if (this.selectedNoteId) {
      let inputJson =
      {
        "title": this.title,
      }
      if(this.itemCall){
        inputJson["item_id"]=[this.leadId],
        inputJson["update_type"] = "APPEND"
      }
      this.updateNote(this.selectedNoteId, inputJson)
      //this.getAllDashBoardNotes(true)
    }
  }
  getCurrentUrl(data) {
    this.selectedNoteId = data._id
    //Generate your own url
    let url = environment.leadUrlText + this.selectedDashId + "?view=NOTES" + "&noteId="+this.selectedNoteId
    this.clipboard.copy(url)
  }

 
  onSearch(){
    if(!this.showUser) {
      this.leadResponse = []
      // this.getAllItems(this.searchValue)
    }
    this.showUser=true;
  }
  onHideSearch(val){
    this.searchValue = ""
    this.leadResponse = []
    this.showUser=false;
  }
  open(content,data) {
    if(data!=null){    this.selectedNoteId = data._id  
      this.title = data.title
    }
    this.modalService.open(content, { size: 'sm' })
  }
  async openEmail(email, data: any) {
    this.suggestedUserJson = await this.httpTransfer.getEmailSuggestions().toPromise();
  this.suggetedUserList = Object.keys(this.suggestedUserJson?.email_suggestions?.emails || {}).map(key => {
    const { _id, name, address } = this.suggestedUserJson?.email_suggestions?.emails[key];
    return { _id, name, email: address };
  });
    data=JSON.parse(JSON.stringify(data))
    var converter = new QuillDeltaToHtmlConverter(JSON.parse(data.note_text),{})
    data.title=this.title
    data.convertedData=converter.convert()
    this.selectedemail = []
    this.selectedNoteId = data._id;
    this.selectedNoteForEmail = data;
    this.modalService.open(email, { size: 'md' })
    const emailSet = new Set(this.resultArrayFromOrgUserArray.map(item => item.email));
    const filteredData2 = this.suggetedUserList.filter(item => !emailSet.has(item.email));
    this.suggestedAndOrgUserList = this.resultArrayFromOrgUserArray.concat(filteredData2);
    this.emailSet = "";
  }
  onAddedNote(id: string) {
    this.selectedNoteId = id
  }
  ngOnDestroy(){
    this.mqttSubscription?.unsubscribe();
    this.dashboardJsonSub?.unsubscribe();
    
    this.router.navigate([], {
      queryParams: {
        'noteId': null,
      },
      queryParamsHandling: 'merge'
    })
  }
  /* connect existing note*/

  openConnectNotes(modalContent){
    this.modalService.open(modalContent, { size: 'xl', windowClass: 'createConnectNotes'});
    this.connectNotes()
  }

  connectNotes(){
    let inputJson = {}
    inputJson ={
      "dashboard_id": [this.selectedDashId],
      "sorting_details": {
        "sort_order": "DESC",     //ASC or DESC
        "sort_by": "last_update"
      },
     }
    this.httpTransfer.getNote(inputJson).subscribe((res : any) => {
      if (res.status == 200) {
        this.displayConnectNotes=[]
        let connetNotes = res.result.notes;
        connetNotes.forEach((noteJson)=>{
          let flag=true;
          noteJson['item_id'].forEach((lead_Id)=>{
            if(lead_Id ===this.leadId){
              flag=false;
            }
          })
          if(flag){
            this.displayConnectNotes.push(noteJson)
          }
        })
      }
    });
  }

  connectExixtingNote(data){
    let inputJson = {
      "item_id":[...data.item_id,this.leadId],
      "update_type": "APPEND"
    }
    this.updateNote(data._id,inputJson)
    // this.displayConnectNotes=this.displayConnectNotes.filter(element=>{
    //     return element['_id'] != data._id
    // })
  }

  openConnectItem(item){
    //this.popover.close();
    this.itemPreviewData=[{itemId:item._id,boardId:item?.dashboard_id,item:item}]
  }

  colseMentionPanel(){{
    setTimeout(()=>{this.isMentionPanelOpen=false},200)
  }}

  openMentionPanel(){
    this.isMentionPanelOpen = false;
    if(this.emailSet.length > 0){
      this.isMentionPanelOpen = true;
      this.filteredSuggestedEmails = this.suggestedAndOrgUserList.filter(item => (item.email.toLowerCase().includes(this.emailSet) || item.name.toLowerCase().includes(this.emailSet)) && this.emailSet !== "");
    }
  }

  setSuggestedEmail(user_email: string){
    this.emailSet = user_email;
    this.onEmailsSetKeydown();
    // this.emailSet.nativeElement.focus();
  }

  openModal(modal){
    if(this.isFullScreen) return;
    this.isFullScreen=true
    const modalRef = this.modalService.open(modal, {size: 'xl', windowClass: 'fullScreenPopOverOpne',})

    modalRef.hidden.subscribe(()=>{
      this.closeModal(this.itemCall)
    });
  }

  closeModal(itemCall,modal?){
    this.isFullScreen = false;
    if(modal && !itemCall){this.modalService.dismissAll(modal)}
    else{this.hideEditor();
  }
  }

  openUsers() {
    this.isShareUsersPopoverOpen = !this.isShareUsersPopoverOpen;
  }
  openDashboard() {
    this.isShareDashboardPopoverOpen = !this.isShareDashboardPopoverOpen;
  }
  openItem() {
    this.isShareItemPopoverOpen = !this.isShareItemPopoverOpen;
  }
  openCreateDashboard() {
    this.isShareCreateDashboardPopoverOpen = !this.isShareCreateDashboardPopoverOpen;
  }
  openCreateItem() {
    this.isShareCreateItemPopoverOpen = !this.isShareCreateItemPopoverOpen;
  }

  closeUsersPopover(popover) {
    popover.close();
    this.isShareUsersPopoverOpen = false;
  }
  closeDashboardPopover(popover) {
    popover.close();
    this.isShareDashboardPopoverOpen = false;
  }
  closeItemPopover(popover) {
    popover.close();
    this.isShareItemPopoverOpen = false;
  }
  closeCreateDashboardPopover(popover) {
    popover.close();
    this.isShareCreateDashboardPopoverOpen = false;
  }
  closeCreateItemPopover(popover) {
    popover.close();
    this.isShareCreateItemPopoverOpen = false;
  }
  async itemCallForItemConnection(folder, board) {
    const dashboardIds = [];

    board.forEach(board => {
      if (board && board.value && board.value.dashboard_id) {
        dashboardIds.push(board.value.dashboard_id);
      }
    });
    this.selectedNoteDashId = board[0].value.dashboard_id;
    if(this.isConnectItemOpen){
      this.isConnectItemOpen = false;
      setTimeout(() => {this.isConnectItemOpen= true;}, 5);
    }else{
      this.isConnectItemOpen = true;
    }
  }

  onWorkFolderSelect(event: any) {
    const dashboardId = [event.dashboard_id];
    let inputJson = {"status":["ACTIVE"], "work_folder_id": dashboardId};
    this.httpTransfer.getDashBoardForAdmin(inputJson).subscribe((result:any) => {
      this.boardsOfFolder = result.result.dashboards;
    })
  }

  selectedUserList(user){
    if(user._id.includes('@')){
      user = {
        name:`External user(${user._id})`,
        email:user._id,
        isDashUser:false,
        is_external_user:true,
        _id:user._id
      }
    }
    this.collaboratorArr.push(user);
    let inputJson = {
     "user_id": [user._id],
     "update_type": "APPEND"
    }
    this.updateNote(this.selectedNoteId, inputJson)
  }

  removeFromSelectedList(item){
    this.removeFromSelected = item; 
    for(let x=0;x<this.collaboratorArr.length;x++){
      if(this.collaboratorArr[x]._id == item._id){
       this.collaboratorArr.splice(x,1)
      }
    }
    let inputJson = {
      "user_id": [item._id],
      "update_type": "REMOVE"
     }
     this.updateNote(this.selectedNoteId, inputJson)
  }
  connectNoteDashboards(selectedDashIds: any) {
    let inputJson = {
      "dashboard_id": selectedDashIds,
      "update_type": "APPEND"
     }
    this.updateNote(this.selectedNoteId, inputJson)
  }
  onDashboardConnectionRemove(dashId: any) {
    let inputJson = {
      "dashboard_id": [dashId],
      "update_type": "REMOVE"
     }
    this.updateNote(this.selectedNoteId, inputJson)
  }

  onItemConnectionRemove(itemId: any) {
    let inputJson = {
      "item_id": [itemId],
      "update_type": "REMOVE"
     }
    this.updateNote(this.selectedNoteId, inputJson)
  }
  closeNote() {
    this.close.emit();
  }
  
  minimizeNote() {
    if(this.bottomBarCall) {
      let obj = {
        'minimizeId': this.selectedNoteId,
        "update_type": "APPEND",
        'view':"NOTES"
      }
      this.minimizeRight.emit(obj)
    }else{
      const inputJson = {
        "bottom_bar_settings": {
        "minimized_note": [this.selectedNoteId],
        "update_type": "APPEND"
        }
      }
      this.httpTransfer.createUserSettings(inputJson).subscribe(res => {
        if (res) {
        this.msgservice.setSaveMinimizeResult({id: res.result.id});
        }
      });
    }
  }

  removefromMinimize() {
    let obj = {
      'minimizeId': this.selectedNoteId,
      "update_type": "REMOVE",
      'view':"NOTES"
    }
    this.minimizeRight.emit(obj)
  }

  copyNoteLink(noteData){
    let url = environment.formHostUrl + "mobile/note/"+ noteData?._id
    this.clipboard.copy(url)
  }
  copyWebNoteLink(noteData){
    let url = environment.formHostUrl + "note/"+ noteData?._id
    this.clipboard.copy(url)
  }

  async handledTextAssist(data){
    if(data.action=='Subtask' || data.action=='Create'){
      let res = await this.commonUtils.getDataFromFilterObjectToCreateLead({dashboard_id:[this.selectedDashId]},this.dashboardData[this.selectedDashId]?.CUSTOM_FORM,(this.dashboardData[this.selectedDashId]?.BUCKET || []),(this.dashboardData[this.selectedDashId]?.DASHBOARD_INFO?.role || []));
      let json=res[0] 
      json['title']=data.text
      if(data.action=='Subtask')json['parent_id']=this.leadId;
      this.httpTransfer.createLeadCorrespondingToDashBoard(json,this.selectedDashId).subscribe((res : any) => {
      });
    }
  }

  openModalForPreview(data, template) {
    let item=data.item;
      this.previewData=data;
      if(item && (data.data_type=='media' ||  data.data_type=='noteMedia')){
        let type=item?.file_type=='IMAGE'?'SingleImage':item?.file_type=='AUDIO'?'SingleAudio':'SingleVideo'
        this.previewData.previewMedia=[]
        let obj=data.data_type=='noteMedia' ? {'type':type,'name':item?.file_name,'url':item?.image_download_url,'path':item?.file_path ,'size_in_bytes':item?.size_in_bytes} : {'type':type,'name':item?.name,'url':item?.paths?.url,'path':item?.paths?.path ,'size_in_bytes':item?.size_in_bytes}
        this.previewData.previewMedia.push(obj)
        let modalRef = this.modalService?.open(template, { size: 'lg' , windowClass: 'file preview-modal'}) 
        this.basicUtils.storeModlRef(modalRef)
      }
      else  if(data.data_type=='note'){
        let modalRef = this.modalService?.open(template, { size: 'lg' , windowClass: 'fullScreenPopOverOpne'}) 
        this.basicUtils.storeModlRef(modalRef)
      }
      else if(data.data_type=='item' || data.data_type=='activity'){
        this.openConnectItem(item)
      }
    }

    backToHome() {
      this.router.navigate(['/home/welcome']);
    }
    openNoteInNewTab(noteData) {
      let url = environment.formHostUrl + "note/"+ noteData?._id;
      window.open(url, '_blank');
    }
}
