<div class="w-100" *ngIf="showLoader">
    <div class="d-flex align-items-center justify-content-between">
        <ng-container  *ngIf="!bottomCall">
            <div class="d-flex align-items-center boardHeader">
                <app-dashboard-icon class="iconBox-16" *ngIf="showAllBoards" [name]="'Back'" [size]="20" [iconJson]="attributesIcons" (click)="showAllBoards=false"></app-dashboard-icon>
                <span style="font-size: 16px;">Connect Boards</span>
             </div>
             <div class="d-flex align-items-center" *ngIf="!showAllBoards" (click)="showAllBoards=true;"><img class="mr-1" src="../../assets/1x/addButton-3571de.svg"> Connect Board</div>    
        </ng-container>
        <ng-container  *ngIf="bottomCall && showAllBoards">
            <div class="d-flex align-items-center boardHeader">
                <span style="font-size: 16px;">Connect Boards</span>
             </div>
        </ng-container>
    </div>

      <ng-container *ngIf="showLoader && showAllBoards && boardListArr?.length">
        <div class="sprintselect-cnr scrollarea">
            <ul *ngFor="let data of boardListArr">
              <div>
                <li class="d-flex align-items-center" style="gap:8px;">
                  <span class="d-flex align-items-center"><input style="width: 11px;height: 11px; border:1px solid #eaeaea; margin-right: 0.5rem; padding-top: 0.1rem;" type="checkbox" (change)="onDashboardCheckboxChange($event, data.dashboard_id)"></span>
                  <span class="iconBox-20 boardIconBox" [ngStyle]="{'background-color': data?.color || commonUtils.getCurrentThemeColor()}"><i [class]="data?.icon" class="faicon"></i></span><span class="noteName" container="body" placement="top" [ngbTooltip]="data.workFolderName" tooltipClass="tooltipPopup"> {{data.dashboard_name}} </span>
                </li>
              </div>
            </ul>
        </div>
        <div class="d-flex align-items-center justify-content-end" style="gap:8px;" *ngIf="noteCall">
            <button class="btn btn-md cancel-btn key-tab mr-2" (click)="showAllBoards=false;">Cancel</button>
            <button class="btn btn-md connect-btn key-tab" (click)="connectDashboards()">Connect</button>
        </div>    
      </ng-container>

      <ng-container *ngIf="showLoader && !showAllBoards && selectedDashboardIds?.length">
        <div class="sprintselect-cnr scrollarea">
            <ng-container   *ngFor="let data of selectedBoardList;let i =index">
                <ul>
                    <div class="d-flex align-items-center justify-content-between">
                      <li class="d-flex align-items-center" style="gap:8px;">
                        <span class="iconBox-20 boardIconBox" [ngStyle]="{'background-color': data?.color || commonUtils.getCurrentThemeColor()}"><i [class]="data?.icon" class="faicon"></i></span><span class="noteName" container="body" placement="top" [ngbTooltip]="data.workFolderName" tooltipClass="tooltipPopup"> {{data.dashboard_name}} </span>
                      </li>
                      <i *ngIf="data.dashboard_id!==dashId" class="mr-2 fa fa-close float-right" aria-hidden="true" (click)="removeSelectedBoard(data.dashboard_id,i)"></i>
                    </div>
                  </ul>
            </ng-container>
          </div>   
      </ng-container>
</div> 