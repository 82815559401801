<div  *ngFor="let filter of advancedFilterArr;let i= index" class="mainFilterBox adf-cotainer">

    <div *ngIf=" i > 0" style="width: 70px;display: flex; align-items: center;height: 50px;">
        <div  class="conditionType"><ng-select [items]="constantService.constant.andOr" bindLabel="display" bindValue="key" [clearable]="false" [(ngModel)]="advancedFilterArr[0].filterOperator" (ngModelChange)="updateAdvanceFilter.emit()" [disabled]="i>1"  [class.key-tab]="!(i>1)" tabindex="0"></ng-select></div>
    </div>


    <!-- for GROUP -->
    <div class="filterbox" *ngIf="filter.type=='GROUP'" [ngClass]="{'filterbox':i>0,'firstFilterBox':i==0}">

        <div class="filterGroupboxArea">
            <app-advance-filter class="w-100" [arrayOfCustomFiled]="arrayOfCustomFiled" [actionobj]="actionobj" [level]="level+1" [selectedDashId]="selectedDashId" [localFilterDataObj]="localFilterDataObj" [advancedFilterArr]="filter.filterArr" [triggerItemcustomFields]="triggerItemcustomFields" [advancedFilterData]="advancedFilterData" (updateAdvanceFilter)="updateAdvanceFilter.emit()"  (deleteThisGroup)="removeFilter(i)" (advanceColumnFilter)="filterColumnJson=$event.columnsJson;columnIndex=$event.columnIndex" [automationCall]="automationCall" [isTriggeredNonItemField]="isTriggeredNonItemField"  [catchHookReqJson]="catchHookReqJson" [appSampleReqJson]="appSampleReqJson"  [triggerType]="triggerType" [filterColumnJson]="filterColumnJson ? filterColumnJson : {}" [columnIndex] ="columnIndex ? columnIndex : 0"></app-advance-filter>
        </div>
       
    </div>

    <!-- for Single Filter -->
    <div class="filterbox" *ngIf="filter.type=='FILTER'" [ngClass]="{'filterbox':i>0,'firstFilterBox':i==0}">
        <div class="filterboxArea">

            <!-- for Filter Key -->
            <div style="width: 25%;">
                <!-- <ng-select [items]="advancedFilterData?.listForAdvanceFilter"  [(ngModel)]="filter.key" (ngModelChange)="filter.operator=null;updateAdvanceFilter.emit()" bindLabel="display_key" bindValue="key" [clearable]="false" style="width:100;" placeholder="Select fields"  ></ng-select> -->

                <ng-select  [virtualScroll]="true" [items]="advancedFilterData?.listForAdvanceFilter" [multiple]="false" bindLabel="display_key" [closeOnSelect]="true" [clearSearchOnAdd]="true" [clearable]="false"
                        bindValue="key" placeholder="Select fields" [(ngModel)]="filter.key" (ngModelChange)="filter.operator=null;updateAdvanceFilter.emit()" class="select-Field key-tab" tabindex=0 style="align-items: center !important;" >
                    <ng-template ng-option-tmp let-item="item" let-index="index">
                        <div (mouseenter)="infoPopRef?.open()" (mouseleave)="infoPopRef?.close()" style="position: relative;">
                            <div #infoPopRef="ngbPopover" [ngbPopover]="item?.boardIds && item?.boardIds?.length>0 && advancedFilterData?.connectedDashboardJson && info" placement="auto" triggers="manual" container=body>
                                <span *ngIf="item.referer_type!='Boolean' && item.referer_type!='Mirror' && item.referer_type!='ItemType' && item.referer_type!='ExternalCollaborator'"><app-dashboard-icon [size]="16" [name]="item.referer_type" [iconJson]="attributesIcons"></app-dashboard-icon></span>
                                <span> {{item.display_key + (item?.isActivityBoardField ? ' (Activity)' : '')}}</span><br>
                            </div>
                            <ng-template #info >
                                <ng-container *ngTemplateOutlet="infoTemp;context:{item:item}"></ng-container>
                            </ng-template>
                        </div>
                    </ng-template>
                </ng-select>
            </div>

            <!-- for Filter Operator -->
            <div style="width: 25%;" *ngIf="filter.key" style="align-items: center !important;" >
                <ng-select [items]="advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='Mirror' ? advancedFilterData?.operatorOptionForAdvance[advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.mirror_column_type] : advancedFilterData?.operatorOptionForAdvance[advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type]"  
                [(ngModel)]="filter.operator" (ngModelChange)="filter.value=null;filter.optionSelected=null;makeDataAccordingOp(filter);updateAdvanceFilter.emit()" [disabled]="!filter.key" bindLabel="display_key" bindValue="key" [clearable]="false" style="width:100%;" class="select-Operator key-tab" tabindex=0 placeholder="Select Operator"  ></ng-select>
            </div>

            <!-- for Filter Value -->
            <div style="width: 45%;">

                <!-- for Priority Field : all cases -->
                <div *ngIf="(advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='Priority' || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.mirror_column_type=='Priority' )
                && (filter.operator=='EQUALS_TO' || filter.operator=='NOT_EQUALS_TO' || filter.operator=='IS_ONE_OF' || filter.operator=='IS_NOT_ONE_OF')">
                    <ng-select  [virtualScroll]="true" [items]="advancedFilterData?.priorityArray" [multiple]="(filter.operator=='EQUALS_TO' || filter.operator=='NOT_EQUALS_TO') ? false : true" bindLabel="value" [closeOnSelect]="true" [clearSearchOnAdd]="true"
                        bindValue="id" placeholder="Select Priority" [(ngModel)]="filter.value" (ngModelChange)="updateAdvanceFilter.emit()" class="select-Operator key-tab" tabindex=0 style="width:100%;" placement="auto">
                        <ng-template ng-option-tmp let-item="item" let-index="index">
                        <div class="flt_user">
                            <span *ngIf="item.id==3" class="priorityLabel" [style.background]="constantService?.priority_colors[item.id]">U</span>
                            <span *ngIf="item.id==2" class="priorityLabel" [style.background]="constantService?.priority_colors[item.id]">H</span>
                            <span *ngIf="item.id==1" class="priorityLabel" [style.background]="constantService?.priority_colors[item.id]">M</span>
                            <span *ngIf="item.id==0" class="priorityLabel" [style.background]="constantService?.priority_colors[item.id]">L</span>
                            <span> {{item.value}}</span><br>
                        </div>
                        </ng-template>
                    </ng-select>
                </div>

                <!-- for Assignee, Assigned By, Created By, Updated By, collaborators , User Field : all cases -->
                <div *ngIf="(advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='SingleUser' || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='MultiUser' || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.mirror_column_type=='SingleUser' || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.mirror_column_type=='MultiUser')
                && (filter.operator=='EQUALS_TO' || filter.operator=='NOT_EQUALS_TO' || filter.operator=='IS_ONE_OF' || filter.operator=='IS_NOT_ONE_OF')">
                    <ng-select  [virtualScroll]="true" 
                    [items]="advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='Mirror' ?  advancedFilterData?.orgFiltersUsersArr :
                    filter.key=='assigned_to' ?  advancedFilterData?.orgFiltersUsersForAssigneeArr : 
                    filter.key=='lead_colloborators_users' ?  advancedFilterData?.orgFiltersUsersForCollArr : 
                    filter.key=='updated_by' ?  advancedFilterData?.orgFiltersUsersArrForAssignedBy :  
                    filter.key=='created_by' ?  advancedFilterData?.orgFiltersUsersArrForCreator :  
                    advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='MultiUser' ?  advancedFilterData?.orgFiltersMultiUsersArr :
                    advancedFilterData?.orgFiltersSingelUsersArr" 
                    [multiple]="(filter.operator=='EQUALS_TO' || filter.operator=='NOT_EQUALS_TO') ? false : true" bindLabel="name" [closeOnSelect]="true" [clearSearchOnAdd]="true" bindValue="_id" [searchFn]="commonUtils.onSearchCode"
                    [(ngModel)]="filter.value" (ngModelChange)="updateAdvanceFilter.emit()" placeholder="Select" class="key-tab select-Operator" tabindex=0 style="width:100%;"  placement="auto">
                      <ng-template ng-option-tmp let-item="item" let-index="index">
                      <div class="flt_user">
                          <div class="user">
                            <div class="align">
                              <ngx-avatars class="mr-1" [name]="item?.name" [src]="item?.image_url" size="25" initialsSize="2" ></ngx-avatars>
                            </div>  
                            <div class="box">
                              <span>{{item?.name}}</span>
                              <p class="my-0">{{item?.email}}</p>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </ng-select>
                </div>

                <!-- for Bucket Field : all cases -->
                <div *ngIf="(advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='Bucket' || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.mirror_column_type=='Bucket')
                && (filter.operator=='EQUALS_TO' || filter.operator=='NOT_EQUALS_TO' || filter.operator=='IS_ONE_OF' || filter.operator=='IS_NOT_ONE_OF')">
                    <ng-select  [virtualScroll]="true" [items]="advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='Mirror' ? advancedFilterData?.connectedDashboardJson[advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.mirror_dashboard_id]?.BUCKET : advancedFilterData?.bucketArray" 
                    [multiple]="(filter.operator=='EQUALS_TO' || filter.operator=='NOT_EQUALS_TO') ? false : true" [clearSearchOnAdd]="true" bindLabel="name" [closeOnSelect]="true"
                    bindValue="_id"  [(ngModel)]="filter.value" (ngModelChange)="updateAdvanceFilter.emit()" placeholder="Select Buckets" class="key-tab select-Operator" tabindex=0 style="width:100%;"  placement="auto">
                        <ng-template ng-option-tmp let-item="item" let-index="index">
                            <div style="display:flex" [ngbPopover]="item?.boardIds && item?.boardIds?.length>0 && advancedFilterData?.connectedDashboardJson && info" placement="auto" triggers="hover" container="body">
                                <div style="width:80%">
                                <span class="pull-left">{{item.name}}</span>
                                </div>
                                <div *ngIf="item.is_destination" class="text-center" style="width:15%;">
                                <i class="far fa-check-circle finalBucketIcon" style="color:#55b98a ; padding : 0px"></i>
                                <p  class="trxtGrayColor">{{'dashboard.filter.finalBucket' | translate}}</p>
                                </div> 
                            </div> 
                            <ng-template #info >
                                <ng-container *ngTemplateOutlet="infoTemp;context:{item:item}"></ng-container>
                            </ng-template>
                        </ng-template>
                    </ng-select>
                </div>

                <!-- for Phase Field : all cases -->
                <div *ngIf="(advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='Sprint' || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.mirror_column_type=='Sprint')
                && (filter.operator=='EQUALS_TO' || filter.operator=='NOT_EQUALS_TO' || filter.operator=='IS_ONE_OF' || filter.operator=='IS_NOT_ONE_OF')">
                    <ng-select  [virtualScroll]="true" [items]=" advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='Mirror' ? advancedFilterData?.connectedDashboardJson[advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.mirror_dashboard_id]?.SPRINT : filter.operator=='IS_ONE_OF' || filter.operator=='IS_NOT_ONE_OF' ? advancedFilterData?.dashBoardSprintForFilter : advancedFilterData?.originalDashBoardSprintForFilter  " [clearSearchOnAdd]="true" [multiple]="(filter.operator=='EQUALS_TO' || filter.operator=='NOT_EQUALS_TO') ? false : true" bindLabel="name" [closeOnSelect]="true"
                    bindValue="_id"  [(ngModel)]="filter.value" (ngModelChange)="updateAdvanceFilter.emit()" placeholder="Select phase" class="key-tab select-Operator" tabindex=0 style="width:100%;"  placement="auto">
                        <ng-template ng-option-tmp let-item="item" let-index="index">
                        <div style="display:flex" [ngbPopover]="item?.boardIds && item?.boardIds?.length>0 && advancedFilterData?.connectedDashboardJson && info" placement="auto" triggers="hover" container="body">
                            <div style="width:80%">
                            <span class="trimLongName">{{item.name}}</span><br>
                            <p class="trxtGrayColor"*ngIf="item.startDateFormat && item.endDateFormat">{{item.startDateFormat}} - {{item.endDateFormat}}</p>
                            <p class="trxtGrayColor" *ngIf="!item.startDateFormat && !item.endDateFormat"></p>
                            <p class="trxtGrayColor" *ngIf="item.startDateFormat && !item.endDateFormat">{{item.startDateFormat}}- {{'form.noEndDate' | translate}}</p>
                            <p class="trxtGrayColor" *ngIf="!item.startDateFormat && item.endDateFormat">{{'form.noStartDate' | translate}} -{{item.endDateFormat}}</p>
                            </div>
                            <div *ngIf="!item.is_archive" class="pull-right" style="width:20%;">
                            <i *ngIf="item.current_status=='STARTED'" class="dot"></i>
                            <i *ngIf="item.current_status=='STOPPED'" class="dot activedone"></i> 
                            <p class="trxtGrayColor" *ngIf="item.current_status=='STARTED' && !item.is_archive">In Progress</p>
                            <p class="trxtGrayColor" *ngIf="item.current_status=='STOPPED' && !item.is_archive">Completed</p>
                        </div>
                        <div *ngIf="item.is_archive" class="pull-right" style="width:20%;">
                            <img class="arcicon"src="../../../assets/img/icons/archive.svg"/>
                            </div>
                            <div *ngIf="item.is_hidden" class="pull-right" style="width:20%;">
                            <img src="../../../assets/img/icons/eye.svg" />
                            </div>
                        </div>
                        <ng-template #info >
                            <ng-container *ngTemplateOutlet="infoTemp;context:{item:item}"></ng-container>
                        </ng-template>
                        </ng-template>
                    </ng-select>
                </div>

                <!-- for Tag Field : all cases -->
                <div *ngIf="(advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='Tags' || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.mirror_column_type=='Tags')
                && (filter.operator=='EQUALS_TO' || filter.operator=='NOT_EQUALS_TO' || filter.operator=='IS_ONE_OF' || filter.operator=='IS_NOT_ONE_OF')">
                    <ng-select  [virtualScroll]="true"  [items]="advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='Mirror' ? advancedFilterData?.connectedDashboardJson[advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.mirror_dashboard_id]?.TAG :  advancedFilterData?.tagsCorrespondingToDashFilter" 
                    [clearSearchOnAdd]="true" [multiple]="(filter.operator=='EQUALS_TO' || filter.operator=='NOT_EQUALS_TO') ? false : true" bindLabel="name" [closeOnSelect]="true"
                    bindValue="tag_id" [(ngModel)]="filter.value" (ngModelChange)="updateAdvanceFilter.emit()" placeholder="Select Tags" class="key-tab select-Operator" tabindex=0 style="width:100%;"  placement="auto">
                        <ng-template ng-option-tmp let-item="item" let-index="index">
                            <div class="w-100 h-100" [ngbPopover]="item?.boardIds && item?.boardIds?.length>0 && advancedFilterData?.connectedDashboardJson && info" placement="auto" triggers="hover" container="body" class="trimLongName">{{item.name}}</div>
                            <ng-template #info >
                                <ng-container *ngTemplateOutlet="infoTemp;context:{item:item}"></ng-container>
                            </ng-template>
                        </ng-template>
                    </ng-select>
                </div>


                <div *ngIf="(advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='Date'|| advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='DateTime'  || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.mirror_column_type=='Date' || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.mirror_column_type=='DateTime')
                && (filter.operator=='EQUALS_TO' || filter.operator=='NOT_EQUALS_TO'|| filter.operator=='IS_GREATER_THAN' || filter.operator=='IS_LESS_THAN'
                || filter.operator=='IS_GREATER_THAN_OR_EQUALS_TO' || filter.operator=='IS_LESS_THAN_OR_EQUALS_TO')">

                    <!-- date option selection -->
                    <div *ngIf="!filter.optionSelected">
                        <ng-select [items]="dateOptionsForSingleDate"  
                        [(ngModel)]="filter.optionSelected" bindLabel="display_key" bindValue="key" [clearable]="false" 
                        style="width:100;" placeholder="Select Option" (ngModelChange)="makeDateObj(filter);updateAdvanceFilter.emit()" class="select-Operator key-tab" tabindex=0 style="width:100%;" placement="auto"></ng-select>
                    </div>

                    <!-- custom date selected -->
                    <div *ngIf="filter.optionSelected=='CUSTOM'">
                        <div class="dateInput key-tab" tabindex="0" [ngbPopover]="customDatePopover" #popDate="ngbPopover" placement="auto" container="body" popoverClass="datePickerPopover"
                    [autoClose]="'outside'" (click)="makeDateObj(filter);currentDateKey=filter.key">
                            <span *ngIf="filter.value && filter.value.date">
                                {{{date:filter?.value?.date} | dateCalcuate:"dateTime"}}
                            </span> 
                            <span *ngIf="!filter.value || (filter.value && !filter.value.date)" class="datePlaceHolderText">DD/MM/YYYY</span>
                            <app-dashboard-icon (click)="$event.stopPropagation();filter.optionSelected = null;filter.value = null;updateAdvanceFilter.emit()" class="iconBox-20 cursor-pointer" [size]="14" [name]="'Cross'" [iconJson]="attributesIcons"></app-dashboard-icon>
                            <ng-template #customDatePopover>
                                <date-picker 
                                [enableTimeEditor]="false"
                                [isIncludeFullDayTime]="filter?.operator=='IS_GREATER_THAN'"  
                                [dateRange]="{'from':filter.value}"
                                [focusDate]="{date:filter.value.date}"
                                (onDateSave)="filter.value.date=$event?.date;filter.value.time=$event?.time;popDate.close();updateAdvanceFilter.emit()"
                                (onDateClear)="filter.value.date=null;popDate.close();updateAdvanceFilter.emit()">
                                </date-picker>
                            </ng-template>
                        </div>
                    </div>

                    <!-- Eval date selected -->
                    <div *ngIf="filter.optionSelected && filter.optionSelected!='CUSTOM' && (filter.optionSelected=='EVAL' ||relativeDateTemplates[filter.optionSelected])">
                        <div class="dateInput key-tab" tabindex="0" [ngbPopover]="evalDatePopover" #popEvalDate="ngbPopover" placement="auto" container="body"
                            [autoClose]="'outside'" (click)="makeDateObj(filter);">
                            <span *ngIf="filter.value">ED: {{ filter?.eval_date_string }}</span>
                            <span *ngIf="!filter.value" class="datePlaceHolderText">DD/MM/YYYY</span>
                            <app-dashboard-icon (click)="$event.stopPropagation();filter.value = null;filter.optionSelected=null;updateAdvanceFilter.emit()" class="iconBox-20 cursor-pointer" [size]="14" [name]="'Cross'" [iconJson]="attributesIcons"></app-dashboard-icon>
                            
                            <ng-template #evalDatePopover>
                                <div class="evalDatePop">
                                    <div class="evalDatePopHeader">
                                        Evaluate Date(ED)
                                    </div>
                                    <div>
                                        <app-evaluate-date [dateObj]="filter.evaldateObj" (dateUpdated)="evalDateUpdated(filter,$event);popEvalDate.close()">
    
                                        </app-evaluate-date>
                                    </div>
                                </div>
                            </ng-template>
                        </div>

                    </div>

                      <!-- Eval date selected -->
                      <div *ngIf="automationCall && filter.optionSelected && filter.optionSelected!='CUSTOM' && !relativeDateTemplates[filter.optionSelected]" >
                        <div class="dateInput">
                            <span *ngIf="filter.value">{{ filter?.eval_date_string }}</span>
                            <span *ngIf="!filter.value" class="datePlaceHolderText">DD/MM/YYYY</span>
                            <app-dashboard-icon (click)="$event.stopPropagation();filter.value = null;filter.optionSelected=null;updateAdvanceFilter.emit()" class="iconBox-20 cursor-pointer" [size]="14" [name]="'Cross'" [iconJson]="attributesIcons"></app-dashboard-icon>                            
                        </div>

                    </div>


                </div>

 

                <div *ngIf="(advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='Date'|| advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='DateTime' || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.mirror_column_type=='Date' || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.mirror_column_type=='DateTime')
                && (filter.operator=='RANGE' )">
                    <div *ngIf="!filter.optionSelected">
                        <ng-select [items]="dateOptionsForRangeDate"  
                        [(ngModel)]="filter.optionSelected" bindLabel="display_key" bindValue="key" [clearable]="false" 
                        style="width:100;" placeholder="Select Option"  (ngModelChange)="makeDateObj(filter);updateAdvanceFilter.emit()" class="select-Operator key-tab" tabindex=0  placement="auto"></ng-select>
                    </div>

                    <div *ngIf="filter.optionSelected=='CUSTOM'" class="dateRangeArea">
                        <div class="dateInputRange key-tab" tabindex=0 container="body" [ngbPopover]="customDatePopoverFrom" #fromDatePopup="ngbPopover" popoverClass="datePickerPopover" placement="auto" [autoClose]="'outside'" (click)="currentDateKey=filter.key">
                          <span *ngIf="filter?.value.date.from">{{filter?.value?.date?.from | dateCalcuate:"dateTime"}}</span> 
                          <span *ngIf="!filter.value.date.from" class="datePlaceHolderText">DD/MM/YYYY</span>
                        </div>  
                        <span style="display: flex;justify-content: center;align-items: center;width: 8%;">
                            <img src="../../../assets/img/icons/backArrow.svg" style="transform:scaleX(-1);height: 8px"/>
                        </span>                  
                        <div class="dateInputRange key-tab" tabindex=0 container="body" [ngbPopover]="customDatePopoverTo" #toDatePopup="ngbPopover" popoverClass="datePickerPopover" placement="auto" [autoClose]="'outside'" (click)="currentDateKey=filter.key">
                          <span *ngIf="filter?.value.date.to">{{filter?.value?.date?.to | dateCalcuate:"dateTime"}}</span>
                          <span *ngIf="!filter.value.date.to" class="datePlaceHolderText">DD/MM/YYYY</span>
                        </div>
                        <span style="display: flex;justify-content: center;align-items: center;width: 8%;">
                            <span (click)="$event.stopPropagation();filter.optionSelected = null;filter.value=null;updateAdvanceFilter.emit();" class="removeDateIcon key-tab" tabindex=0>x</span>
                        </span> 
    
                        <ng-template #customDatePopoverFrom>
                            <date-picker [dateRange]="{'from':filter?.value?.date.from}" [dateConfig]="{'maxDate':filter?.value?.date.to}" [focusDate]="{date:filter?.value?.date.from}" [showHeader]="false" [showClearOrSaveBtn]="false" (onDateSelect)="filter.value.date.from = $event;updateAdvanceFilter.emit();fromDatePopup?.close()"></date-picker>
                        </ng-template>
                        <ng-template #customDatePopoverTo>
                            <date-picker [dateRange]="{'to':filter?.value?.date.to}" [dateConfig]="{'minDate':filter?.value?.date.from}" [focusDate]="{date:filter?.value?.date.to}" [showHeader]="false" [showClearOrSaveBtn]="false" (onDateSelect)="filter.value.date.to = $event;updateAdvanceFilter.emit();toDatePopup?.close()"></date-picker>
                        </ng-template>
                    </div>

                    <div *ngIf="filter.optionSelected && filter.optionSelected!='CUSTOM' && (filter.optionSelected=='EVAL' || directFiscaldateTempList.includes(filter.optionSelected))">
                        <div class="dateInput key-tab" tabindex=0 [ngbPopover]="fiscalDatePopover" #popFiscalDate="ngbPopover" placement="auto" container="body"
                            [autoClose]="'outside'" (click)="makeDateObj(filter);">
                            <span *ngIf="filter.value && filter.optionSelected=='EVAL'">FD:{{ filter?.fiscal_date_string }}  F:{{filter.value.eval_json?.filter_cycle?.month }}Months</span>
                            <span *ngIf="filter.value && filter.optionSelected!='EVAL'">{{ filter?.fiscal_date_string }}</span>

                            <span *ngIf="!filter.value" class="datePlaceHolderText">DD/MM/YYYY</span>
                            <app-dashboard-icon (click)="$event.stopPropagation();filter.value = null;filter.optionSelected=null;updateAdvanceFilter.emit();makeDateObj(filter)" [size]="14" [name]="'Cross'" [iconJson]="attributesIcons"></app-dashboard-icon>
                            <ng-template #fiscalDatePopover>
                                <div class="fiscaDatePop">
                                    <div class="fiscalDatePopHeader">
                                        Fiscal date(FD)
                                    </div>
                                    <div>
                                        <app-fiscal-date [dateObj]="filter.fiscaldateObj" (dateUpdated)="fiscalDateUpadted(filter,$event);popFiscalDate.close()">

                                        </app-fiscal-date>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </div>

                    <div *ngIf="filter.optionSelected && filter.optionSelected!='CUSTOM' && (filter.optionSelected!='EVAL' && !directFiscaldateTempList.includes(filter.optionSelected))" class="dateRangeArea">
                        <div class="fiscalTempCalc key-tab" tabindex=0 [ngbPopover]="evalDatePopoverFrom" #popEvalDateFrom="ngbPopover" placement="auto" container="body"
                            [autoClose]="'outside'" (click)="currentDateKey='from'">
                            <span *ngIf="filter.fiscaldateObj?.date?.from">ED: {{ filter?.eval_date_string?.from }}</span>
                            
                            <ng-template #evalDatePopoverFrom>
                                <div class="evalDatePop">
                                    <div class="evalDatePopHeader">
                                        Evaluate Date(ED) : From
                                    </div>
                                    <div>
                                        <app-evaluate-date [dateObj]="filter.fiscaldateObj?.date?.from" (dateUpdated)="fiscalDateCalcUpdate(filter,$event,currentDateKey);popEvalDateFrom.close()">
    
                                        </app-evaluate-date>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                          <span style="display: flex;justify-content: center;align-items: center;width: 8%;">
                              <img src="../../../assets/img/icons/backArrow.svg" style="transform:scaleX(-1);height: 8px"/>
                          </span>                  
                          <div class="fiscalTempCalc" [ngbPopover]="evalDatePopoverto" #popEvalDateTo="ngbPopover" placement="auto" container="body"
                            [autoClose]="'outside'" (click)="currentDateKey='to'">
                            <span *ngIf="filter.fiscaldateObj?.date?.to">ED: {{ filter?.eval_date_string?.to }}</span>
                            
                            <ng-template #evalDatePopoverto>
                                <div class="evalDatePop">
                                    <div class="evalDatePopHeader">
                                        Evaluate Date(ED) : To
                                    </div>
                                    <div>
                                        <app-evaluate-date [dateObj]="filter.fiscaldateObj?.date?.to" (dateUpdated)="fiscalDateCalcUpdate(filter,$event,currentDateKey);popEvalDateTo.close()">
    
                                        </app-evaluate-date>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                          <span style="display: flex;justify-content: center;align-items: center;width: 8%;">
                              <span (click)="$event.stopPropagation();filter.optionSelected = null;filter.value=null;updateAdvanceFilter.emit();" class="removeDateIcon key-tab" tabindex="0">x</span>
                          </span> 
                    </div>
                </div>


                <!-- for Text,Text Field -->
                <div *ngIf="(advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='Text' || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='Textarea' || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='Email' || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='MultipleEmail'
                || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='MultiUrl' || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='Url' || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='IPAddress'
                || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.mirror_column_type=='Text' || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.mirror_column_type=='Textarea' || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.mirror_column_type=='Email' || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.mirror_column_type=='MultipleEmail'
                || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.mirror_column_type=='MultiUrl' || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.mirror_column_type=='Url' || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.mirror_column_type=='IPAddress')
                && (filter.operator=='EQUALS_TO' || filter.operator=='NOT_EQUALS_TO' || filter.operator=='CONTAINS' || filter.operator=='DOES_NOT_CONTAIN' )">
                 <div class="w-100" *ngIf="!automationCall"><input type="text" class="inputBox key-tab" tabindex=0 placeholder="Enter"  [(ngModel)]="filter.value" (blur)="updateAdvanceFilter.emit()" (ngModelChange)="updateAdvanceFilter.emit()"></div>    
                <div class="columnRow" *ngIf="automationCall">
                        <div class="inputArea" *ngIf="!filter?.enableColumns"><input type="text" class="inputBox noBorder key-tab" tabindex=0 placeholder="Enter"  [(ngModel)]="filter.value" (blur)="updateAdvanceFilter.emit()" (ngModelChange)="updateAdvanceFilter.emit()"></div>
                        <div class="inputArea" *ngIf="filter?.enableColumns"><span class="columnsEdit">Expand To View/Edit</span></div>
                        <div class="columnBtn key-tab" tabindex=0 [ngbPopover]="customColumns" #popColumnsForChat="ngbPopover" container="body" [autoClose]="false"><app-dashboard-icon [size]="16" [name]="'Expand'" [iconJson]="lxAttributesIcons"></app-dashboard-icon></div>
                    <ng-template #customColumns>
                        <div style="width:350px;display: flex;align-items: center;justify-content: flex-end;">
                            <span class="closeColumnBtn key-tab" tabindex="0" (click)="popColumnsForChat.close()">
                                Done
                            </span>
                        </div>
                        <div style="width:350px;">
                            <app-content-editable-input-note [item]="actionobj" [htmlContent]="filter.value" [customfieldType]="advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.mirror_column_type ? advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.mirror_column_type : advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type" [filterCall]="true" [dashId]="selectedDashId"  [columnsJson]="filterColumnJson ? filterColumnJson : {}" [columnIndex]="columnIndex ? columnIndex : 0" [showIndex]="false" [isTriggeredNonItemField]="isTriggeredNonItemField" [catchHookReqJson]="catchHookReqJson" [appSampleReqJson]="appSampleReqJson"  [customFieldArr]="triggerItemcustomFields" [triggerType]="triggerType" (onBlur)="filter.value=$event.text;advanceColumnFilter.emit($event);checkForColumnsInadvancefilter();updateAdvanceFilter.emit()"></app-content-editable-input-note>
                        </div>
                    </ng-template>
                </div>

                </div>

                <!-- for Numeric, Currency Field Equal/Not Equal To/Is Greater Than/Is Less Than/Is Greater Than or Equal To/Is Less Than or Equal To -->
                <div *ngIf="(advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='Numeric' || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='CurrencyAmount' || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='Rating'
                || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.mirror_column_type=='Numeric' || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.mirror_column_type=='CurrencyAmount' || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.mirror_column_type=='Rating')
                && (filter.operator=='EQUALS_TO' || filter.operator=='NOT_EQUALS_TO' || filter.operator=='IS_GREATER_THAN' || filter.operator=='IS_LESS_THAN'
                || filter.operator=='IS_GREATER_THAN_OR_EQUALS_TO' || filter.operator=='IS_LESS_THAN_OR_EQUALS_TO')">
                   <div *ngIf="!automationCall || (automationCall && (filter?.value=='customNumber' || isNumber(filter?.value)))"><input type="number" class="inputBox key-tab" tabindex=0 placeholder="Enter Number"  [(ngModel)]="filter.value" (blur)="updateAdvanceFilter.emit()"></div>
                   <div *ngIf="automationCall && filter?.value!='customNumber' && !isNumber(filter?.value)"><ng-select class="select-Operator key-tab" tabindex=0 [items]="advancedFilterData.numberOption" bindLabel="name" bindValue="_id" [(ngModel)]="filter.value" (blur)="updateAdvanceFilter.emit()" style="width: 100%;" placement="auto"></ng-select></div>
                </div>

                <!-- for Numeric, Rating Field Range -->
                <div *ngIf="(advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='Numeric' || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='CurrencyAmount' || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='Rating'
                || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.mirror_column_type=='Numeric' || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.mirror_column_type=='CurrencyAmount' || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.mirror_column_type=='Rating')
                && (filter.operator=='RANGE')">
                    <div style="display: flex;" *ngIf="!automationCall">
                        <input type="number" class="inputBoxRange key-tab" tabindex=0 placeholder="Enter From" [(ngModel)]="filter.value.from" (ngModelChange)="updateAdvanceFilter.emit()">
                        <span style="display: flex;justify-content: center;align-items: center;width: 10%;">
                            <img src="../../../assets/img/icons/backArrow.svg" style="transform:scaleX(-1);height: 8px"/>
                        </span>
                        <input type="number" class="inputBoxRange key-tab" tabindex=0 placeholder="Enter From" 
                         [(ngModel)]="filter.value.to" (ngModelChange)="updateAdvanceFilter.emit()">
                    </div>
                    <div style="display: flex;width:100%;" *ngIf="automationCall">
                        <ng-container *ngIf="filter.value.from!='customNumber' && !isNumber(filter?.value.from)">
                            <ng-select style="width:45%;" [items]="advancedFilterData.numberOption" bindLabel="name" bindValue="_id" [(ngModel)]="filter.value.from" (blur)="updateAdvanceFilter.emit()" class="key-tab" tabindex=0 placement="auto"></ng-select>
                        </ng-container>
                        <ng-container *ngIf="filter.value.from=='customNumber' || isNumber(filter?.value.from)">
                            <input type="number" class="inputBoxRange key-tab" tabindex=0 placeholder="Enter From" [(ngModel)]="filter.value.from" (ngModelChange)="updateAdvanceFilter.emit()">
                        </ng-container>
                        <span style="display: flex;justify-content: center;align-items: center;width: 10%;">
                            <img src="../../../assets/img/icons/backArrow.svg" style="transform:scaleX(-1);height: 8px"/>
                        </span>
                        <ng-container *ngIf="filter.value.to!='customNumber' && !isNumber(filter?.value.to)">
                            <ng-select style="width:45%;" [items]="advancedFilterData.numberOption" bindLabel="name" bindValue="_id" [(ngModel)]="filter.value.to" (blur)="updateAdvanceFilter.emit()" class="key-tab" tabindex=0  placement="auto"></ng-select>
                        </ng-container>
                        <ng-container *ngIf="filter.value.to=='customNumber' || isNumber(filter?.value.to)">
                            <input type="number" class="inputBoxRange key-tab" tabindex=0 placeholder="Enter From" [(ngModel)]="filter.value.to" (ngModelChange)="updateAdvanceFilter.emit()">
                        </ng-container>
                    </div>
                </div>

                <!-- for CurrencyCode Field : all cases -->
                <div *ngIf="(advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='CurrencyCode' || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.mirror_column_type=='CurrencyCode')
                      && (filter.operator=='EQUALS_TO' || filter.operator=='NOT_EQUALS_TO' || filter.operator=='IS_ONE_OF' || filter.operator=='IS_NOT_ONE_OF')">
                        <ng-select [items]="advancedFilterData.currencyArr" [searchFn]="commonUtils.onSearchCodeCurrency" [multiple]="(filter.operator=='EQUALS_TO' || filter.operator=='NOT_EQUALS_TO') ? false : true" bindLabel="currencyCode"
                          bindValue="code" [closeOnSelect]="true" 
                          [clearable]="false" [(ngModel)]="filter.value" (ngModelChange)="updateAdvanceFilter.emit()" 
                          placeholder="{{'placeholders.selectCurrency' | translate}}" class="select-Operator key-tab" tabindex=0  placement="auto">
                          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            {{item?.flag}}
                            {{item?.text}} <br/>
                            <small class="small" *ngIf="item?.flag">{{item.name}}</small>
                            <label *ngIf="!item?.flag">{{item.name}}</label>
                          </ng-template>
                        </ng-select>
                </div>

                     <!-- for Moblie Equal/Not Equal To -->
                <div *ngIf="(advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='MobileNumber' 
                     || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.mirror_column_type=='MobileNumber')
                     && (filter.operator=='EQUALS_TO' || filter.operator=='NOT_EQUALS_TO')">
                     <div *ngIf="!automationCall"><input type="text" class="inputBox key-tab" tabindex=0 placeholder="Enter"  [(ngModel)]="filter.value" (blur)="updateAdvanceFilter.emit()" (ngModelChange)="updateAdvanceFilter.emit()"></div>
                     <div *ngIf="automationCall" class="w-100" style="display:flex;align-items: center;">
                        <ng-container *ngIf="!filter?.custom || filter?.custom!='customMobile'">
                        <ng-select class="select-Operator w-100 key-tab" tabindex=0 [items]="advancedFilterData.mobileNumber" bindLabel="name" bindValue="_id" placeholder="Enter"  [(ngModel)]="filter.custom" (blur)="updateAdvanceFilter.emit()" (ngModelChange)="checkForMobileNumberCustom(filter);updateAdvanceFilter.emit()"  placement="auto"></ng-select>
                        </ng-container>
                        <ng-container *ngIf="filter.custom=='customMobile'">
                            <input type="text" class="inputBox key-tab" tabindex=0 placeholder="Enter"  [(ngModel)]="filter.value" (blur)="updateAdvanceFilter.emit()" (ngModelChange)="updateAdvanceFilter.emit()">
                            <span (click)="$event.stopPropagation();filter.custom = null;filter.value=null;updateAdvanceFilter.emit();" class="removeDateIcon key-tab" tabindex=0>x</span>
                        </ng-container>
                    </div> 
                </div>

                <!-- for MobileCode Field : all cases -->
                <div *ngIf="(advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='MobileCode' || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.mirror_column_type=='MobileCode')
                     && (filter.operator=='EQUALS_TO' || filter.operator=='NOT_EQUALS_TO' || filter.operator=='IS_ONE_OF' || filter.operator=='IS_NOT_ONE_OF')">
                       <ng-select [searchFn]="commonUtils.onSearchCodeCurrency"  [items]="advancedFilterData.mobilecode" [multiple]="(filter.operator=='EQUALS_TO' || filter.operator=='NOT_EQUALS_TO') ? false : true" bindLabel="dial_code" [closeOnSelect]="true" placeholder="{{'placeholders.code' | translate}}"
                       bindValue="code" [ngModelOptions]="{standalone: true}"  [clearable]="false" [(ngModel)]="filter.value" (ngModelChange)="updateAdvanceFilter.emit()" class="select-Operator key-tab" tabindex=0  placement="auto">
                       <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                         {{item.flag}}
                         {{item?.dial_code}} <br/>
                         <small class="smaller">{{item.name}}</small>
                       </ng-template>
                       </ng-select>
               </div>


                <!-- for dropdown,multiselect,checklist,Radiobutton field -->
                <div *ngIf="(advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='Radiobutton' ||  advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='Dropdown'
                || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='Checkbox' || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='MultiSelect'
                || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.mirror_column_type=='Radiobutton' ||  advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.mirror_column_type=='Dropdown'
                || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.mirror_column_type=='Checkbox' || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.mirror_column_type=='MultiSelect')
                && (filter.operator=='EQUALS_TO' || filter.operator=='NOT_EQUALS_TO' || filter.operator=='IS_ONE_OF' || filter.operator=='IS_NOT_ONE_OF')">
                    <ng-container *ngIf="advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='Dropdown' ||  advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='MultiSelect';else multiSelectFields">
                        <ng-select [virtualScroll]="true" [items]="advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.permissible_values" [multiple]="(filter.operator=='EQUALS_TO' || filter.operator=='NOT_EQUALS_TO') ? false : true" bindLabel="k"
                        [closeOnSelect]="true" bindValue="k" [(ngModel)]="filter.value" (ngModelChange)="updateAdvanceFilter.emit()" placeholder="Select" class="key-tab select-Operator" tabindex=0>
                        <ng-template ng-option-tmp let-item="item">
                            <div [style.background-color]="item.c || '#ffffff'" [ngStyle]="{'color': commonUtils.getContrastColor(item?.c)}"class="custom-option">{{ item.v }}</div>
                        </ng-template>
                        <ng-template ng-label-tmp let-item="item">
                            <div [style.background-color]="item?.c || '#ffffff'"[ngStyle]="{'color': commonUtils.getContrastColor(item?.c)}">{{ item?.v }}</div>
                        </ng-template>
                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                            <div class="custom-multi-select-label" style="padding-bottom: 5px;display: flex;">
                                <div *ngFor="let item of items" class="custom-multi-select-item" style="display: flex;padding-bottom: 3px;padding-right: 5px;">
                                    <div [style.background-color]="item.c || '#ffffff'" class="custom-options-for-multi">
                                        <div style="display: flex;">
                                            <div class="text-over-flow" [ngStyle]="{'color': commonUtils.getContrastColor(item?.c)}">{{item.v }}</div>
                                            <div class="clear-icon" (click)="removeItem(item, clear)"><img src="../../../assets/1x/new-cross.svg" /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ng-select>
                    </ng-container>
                    <ng-template #multiSelectFields>
                        <ng-select [virtualScroll]="true"[items]="advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.permissible_values"[multiple]="(filter.operator=='EQUALS_TO' || filter.operator=='NOT_EQUALS_TO') ? false : true"
                            bindLabel="value.k" [closeOnSelect]="true" bindValue="value.k" [(ngModel)]="filter.value" (ngModelChange)="updateAdvanceFilter.emit()" placeholder="Select" class="key-tab">
                            <ng-template ng-option-tmp let-item="item">
                                <div [style.background-color]="item?.value?.c || '#ffffff'" [ngStyle]="{'color': commonUtils.getContrastColor(item?.value?.c)}" class="custom-option">{{ item?.value?.v }}</div>
                            </ng-template>
                            <ng-template ng-label-tmp let-item="item">
                                <div [style.background-color]="item?.value?.c || '#ffffff'" [ngStyle]="{'color': commonUtils.getContrastColor(item?.value?.c)}">{{ item?.value?.v }}</div>
                            </ng-template>
                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                <div class="custom-multi-select-label" style="padding-bottom: 5px;display: flex;">
                                    <div *ngFor="let item of items" class="custom-multi-select-item" style="display: flex;padding-bottom: 3px;padding-right: 5px;">
                                        <div [style.background-color]="item?.value?.c || '#ffffff'" class="custom-options-for-multi">
                                            <div style="display: flex;">
                                                <div class="text-over-flow" [ngStyle]="{'color': commonUtils.getContrastColor(item?.value?.c)}">{{item?.value?.v }}</div>
                                                <div class="clear-icon" (click)="removeItem(item, clear)"><img src="../../../assets/1x/new-cross.svg" /></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-select>
                    </ng-template>
                </div>

                <!-- for Country Field : all cases -->
                <div *ngIf="(advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='Country' || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.mirror_column_type=='Country')
                && (filter.operator=='EQUALS_TO' || filter.operator=='NOT_EQUALS_TO' || filter.operator=='IS_ONE_OF' || filter.operator=='IS_NOT_ONE_OF')">
                    <ng-select  [virtualScroll]="true" [searchFn]="onSearchCountry"  [clearable]="true" [multiple]="(filter.operator=='EQUALS_TO' || filter.operator=='NOT_EQUALS_TO') ? false : true" bindLabel="name"
                    [closeOnSelect]="true" bindValue="name"
                        [(ngModel)]="filter.value" (ngModelChange)="updateAdvanceFilter.emit()"
                        placeholder="{{'placeholders.selectConutry' | translate}}" class="select-Operator w-100 key-tab" tabindex=0  placement="auto">
                        <ng-option *ngFor="let item of advancedFilterData?.countryListOption" [value]="item.name" style="width:16rem" appendTo="body">
                        {{item.flag}} {{item.name}}
                        </ng-option>
                    </ng-select>
                </div>

                <!-- for Board Field : all cases -->
                <div *ngIf="(advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='lead' || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='Board' || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.mirror_column_type=='Board' || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='MultipleBoard' || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.mirror_column_type=='MultipleBoard')
                && (filter.operator=='EQUALS_TO' || filter.operator=='NOT_EQUALS_TO' || filter.operator=='IS_ONE_OF' || filter.operator=='IS_NOT_ONE_OF')">
                    
                    <ng-select  [virtualScroll]="true" 
                    [items]="(advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='lead' ? advancedFilterData.dashBoardLeadList || [] :advancedFilterData?.connectedBoardData[advancedFilterData?.jsonlistForAdvanceFilter[filter?.key]?.connected_board_id] ||[])" 
                    [multiple]="(filter.operator=='EQUALS_TO' || filter.operator=='NOT_EQUALS_TO') ? false : true" 
                    [closeOnSelect]="false" placeholder="Select" [clearSearchOnAdd]="true"
                    bindValue="_id" [(ngModel)]="filter.value" (ngModelChange)="updateAdvanceFilter.emit()"
                    [searchFn]="getSearchMirrorLead" class="select-Operator w-100 key-tab" tabindex=0  placement="auto">
                        <ng-template ng-option-tmp ng-label-tmp let-item="item">
                            <div #infoPopRef="ngbPopover" [ngbPopover]="item.dashboard_id && advancedFilterData?.connectedDashboardJson && info" placement="auto" triggers="hover" container=body>
                                <span *ngIf="item?.seq_id" >#</span>{{item.seq_id}} {{item.title}}
                            </div>
                            <ng-template #info >
                                <ng-container *ngTemplateOutlet="infoTemp;context:{item:{'boardIds':[item?.dashboard_id]}}"></ng-container>
                            </ng-template>
                        </ng-template>

                    </ng-select>
                </div>
                <!-- for Main Board Field : all cases -->
                <div *ngIf="(advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='MainBoard') && (filter.operator=='EQUALS_TO' || filter.operator=='NOT_EQUALS_TO' || filter.operator=='IS_ONE_OF' || filter.operator=='IS_NOT_ONE_OF')">
                    <ng-select  [virtualScroll]="true" 
                    [items]="advancedFilterData.mainBoardList || []" 
                    [multiple]="(filter.operator=='EQUALS_TO' || filter.operator=='NOT_EQUALS_TO') ? false : true" 
                    [closeOnSelect]="false" placeholder="Select" [clearSearchOnAdd]="true"
                    bindValue="dashboard_id" [(ngModel)]="filter.value" (ngModelChange)="updateAdvanceFilter.emit()"
                    [searchFn]="getSearchMirrorLead" class="select-Operator w-100 key-tab" tabindex=0  placement="auto">
                        <ng-template ng-option-tmp ng-label-tmp let-item="item">
                            <span>{{item.dashboard_name}}</span>
                        </ng-template>
                    </ng-select>
                </div>


                 <div *ngIf="(advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='Boolean' || advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.type=='ItemType')
                 && (filter.operator=='EQUALS_TO' || filter.operator=='NOT_EQUALS_TO' || filter.operator=='IS_ONE_OF' || filter.operator=='IS_NOT_ONE_OF')">
                     
                     <ng-select  [virtualScroll]="true" [items]="advancedFilterData?.jsonlistForAdvanceFilter[filter.key]?.key=='item_type' ? itemTypeArr : booleanFieldsArr" [multiple]="(filter.operator=='EQUALS_TO' || filter.operator=='NOT_EQUALS_TO') ? false : true" bindLabel="key" [closeOnSelect]="true"
                     bindValue="value" [(ngModel)]="filter.value" (ngModelChange)="updateAdvanceFilter.emit()" placeholder="Select" class="select-Operator w-100 key-tab" tabindex=0  placement="auto"></ng-select>
                 </div>

                
    
                
            </div>
        </div>
        <span class="filterboxRemoveArea key-tab" tabindex="0" *ngIf="advancedFilterArr.length>1" (click)="removeFilter(i)"><img src="../../../assets/1x/whiteBin.svg"/></span>
    </div>

    
    
    
</div>
<div class="groupOpBtn">
    <span  style="display: flex;column-gap: 10px;">
        <span class="addBtn key-tab" tabindex=0 (click)="addMoreFilter()">+ Add</span>
        <span *ngIf="level<maxlevel" class="addBtn  key-tab" tabindex=0 (click)="addMoreFilterGroup()">+ Filter Group</span>
    </span>
    <span *ngIf="level>0">
        <span class="deleteBtn key-tab" tabindex=0 (click)="deleteThisGroup.emit()">Delete Group</span>
    </span>
</div>
<ng-template #infoTemp let-item="item">
    <div>
        <span><b>Connect boards</b></span>
        <div style="overflow: auto;">
            <ng-container *ngFor="let id of item.boardIds">
                <div *ngIf="advancedFilterData?.connectedDashboardJson[id] && advancedFilterData?.connectedDashboardJson[id]?.DASHBOARD_INFO" style="display: flex;column-gap: 5px;align-items: center;">
                    <span class="iconBox-16 adf-board-icon">
                        <i [class]="advancedFilterData?.connectedDashboardJson[id]?.DASHBOARD_INFO?.icon" class="faicon"></i>
                    </span>
                    <span>{{advancedFilterData?.connectedDashboardJson[id]?.DASHBOARD_INFO?.dashboard_name}}</span>
                </div>
            </ng-container>
        </div>
    </div>
</ng-template>

