import { HttpTransferService } from "./../../services/httpTransfer.service";
import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  TemplateRef,
  ElementRef,
  ViewChild,
  ChangeDetectorRef,
  AfterViewInit,
} from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { ConstantService } from "../../../Enums/Constant.service";
import { CommonUtils } from "../../services/CommonUtils.service";
import * as moment from "moment";
import { DatePipe } from "@angular/common";
import { CustomStorageService } from "../../services/custom-storage.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DashboardUtilsService } from '../../services/dashboard-utils.service';
import { MediaIcons } from "../../../Enums/media-icons";
import { MqttmessagingService } from "../../services/mqttmessaging.service";
import { Subscription } from "rxjs";
import { ActivityIcons, AttributesIcons, AttributesLxIcons, ItemIcons } from "Enums/attributes-icons";
import { MessageService } from "app/message.service";
import { SortingutilsService } from "app/sortingutils.service";

declare var $: any;

@Component({
  selector: "app-timeline",
  templateUrl: "./timeline.component.html",
  styleUrls: ["./timeline.component.css"],
})
export class TimelineComponent implements OnInit, AfterViewInit {
  @Input("dashId") dashId: any;
  @Input() viewType: any='ActivityView';
  @Input("item_id") item_id: any = null;
  @Input()  leadInfo: any;
  @Input() isReadOnly = true;     
  @Input("dashboard_initial") dashboard_initial: any;
  @Input("showDashboardTimeline") showDashboardTimeline: any;
  @Output("imageData") imageData = new EventEmitter<any>();
  @Output() backFromHistory = new EventEmitter<any>();
  @Input() isHeaderQuickFilterRequired: boolean = true;
  @Input() isRightSideFilterRequired: boolean = true;
  @Input() isHeaderRequired: boolean = true;
  @Input() isHeaderFilterRequired: boolean = false;
  @Output() onInitialize= new EventEmitter<any>();
  @Output() openSideFilterModal= new EventEmitter<any>();
  @Output() onItemOpen = new EventEmitter<any>();
  timelineCurrentPage = 0;
  timeLineTotal_Pages: any;
  timelineResponseArray = [];
  totalNumberOfTimelineItems = 20;
  timelineCommentArray = [];
  timelineDataObj: any;
  comment: any;
  @Input("height") height: any;
  userInfoArray = [];
  showImageStatus: boolean = false;
  leadIdCorrespondingToTimeline: any;
  leadTitle: any;
  modalRef: BsModalRef;
  imageUrl: string;
  showmore: boolean = false;
  time: any;
  file: any;
  image: any;
  video: any;
  audio:any;
  groupedObject: any;
  requestPending:boolean=true
  showScrollLoader:boolean=false
  userSearchStatus: boolean = false;
  userInfoArrayCopy: any = [];
  usersPipeSearchFilterResult: any = [];
  isDateActive: boolean = true;
  selectedStartDate: any;
  selectedEndDate: any;
  maxDate:any=new Date()
  selectedUserIds: any = [];
  userSearchInput: any;
  selectedButtonForTimelineQuerry: any;
  showLastUpdatedDate: boolean = false;
  timelineOpenFrom: { FROMDASHBOARD: string; FROM_LEAD_MODAL: string; };
  filterArrForList: string[] = [];
  filterArrForUserId: any = [];
  filterObjectForDate: any = {};
  payload: any = {};
  timelineFilter: any = []
  @ViewChild('widgetsContent') widgetsContent: ElementRef;
  @ViewChild('filterPopover') filterPopover: any;
  selectedTimelineButtonName: string[] = [];
  showMoreCommentsIndexValue: number = null;
  expandAllLeadComments: boolean = false;
  fileTypeConstants: string[];
  dashboardLeadsBySeqId: any[];
  selectedLeadIds: any[] = [];
  selectedUserIdsArrToFilterTimeline: string[] = [];
  selecteLeadIdsForTimelineMsg: string[] = [];
  selectedUserIdsArrToFilterTimelineRight: any[] = [];
  imageUrlForModal: any;
  fileUrlForModal: any;
  dashboardUsers: any={};
  isDashboardUsersLoaded: boolean;
  phaseId: any;
  isDateRangeGreaterThanStartDate: boolean;
  setStartDateInputField: boolean;
  setEndDateInputField: boolean;
  itemViewType: { board_view: string; list_view: string; gant_view: string; activity_view: string; overview: string; chat_view: string; grid_view: string; notes_view: string; drive_view: string; };
  ngbLeadModalRef: any;
  showLeadModal: boolean;
  lock:boolean=false
  mediaIcons = MediaIcons
  itemAttributesObj: {};
  mqttSubscription: Subscription;
  itemTerminology:any={};
  boardTypeFilter:boolean=false;
  attributesIcons = AttributesIcons;
  lxAttributesIcons = AttributesLxIcons;
  checkBaseUrl:boolean=false;
  allowedUrl=['dev1.pronnel.com','dev2.pronnel.com','staging.pronnel.com','localhost:4200']
  itemPreviewData:any=[]
  boardInfo: any={};
  activityTypeBoardList: any=[];
  currentCreateActivityData: any={};
  loaderConfig: any={};
  @Input('selectedActivityBoard') selectedActivityBoard: any;
  selectedHistoryActivity:any;
  @Input() historyInfo:any={}
  activityDataList: any=[];
  upcommingActivityDataList: any=[];
  @Input() activityId: any=null;
  filterJson:any={
    type:['ALL'],
    activityTime:{},
  }
  originalFilterJson:any={}
  pagignationDetails:any={
    pageSize:20,
    currentPageNumber:1,
    totalPageSize:null
  }
  
  chatConfig:any={
    INTERNAL:{
      name:'Comment',
      class:'commentColorView',
      color:'#34A290'
    },
    CHANNELS:{
      name:'Channel',
      class:'channelColorView',
      color:'#EA5A92'
    },
    EXTERNAL_OUTBOUND:{
      name:'Channel',
      class:'channelColorView',
      iconsExist:['WHATSAPP','INSTAGRAM'],
      color:'#EA5A92'
    },
    EXTERNAL_INBOUND:{
      name:'Channel',
      class:'channelColorView',
      iconsExist:['WHATSAPP','INSTAGRAM'],
      color:'#EA5A92'
    },
    EMAIL:{
      name:'Email',
      class:'emailColorView',
      color:'#8B5BEB'
    },
    TASK_ACTIVITY:{
      name:'Task',
      class:'taskColorView',
      color:'#CF9363'
    },
    MEETING_ACTIVITY:{
      icon:'meetingLogsIconsWhite',
      class:'activityColorView',
      color:'#2BA1F0'
    },
    CALL_ACTIVITY:{
      icon:'callLogsIconWhite',
      class:'activityColorView',
      color:'#2BA1F0'
    },
    CUSTOM_ACTIVITY_TYPE:{
      icon:'SettingActivityType',
      class:'activityColorView',
      color:'#2BA1F0'
    },
    MESSAGE_ACTIVITY:{
      icon:'messageActivity',
      class:'activityColorView',
      color:'#2BA1F0'
    },
    ACTIVITY_LOGS:{
      name:'Audit Log',
      class:'auditlogColorView',
      color:'#3269CC'
    }
  }
  activityTypes:any=[{name:"All",key:'ALL'},{name:"Comment",key:'INTERNAL'},{name:"Audit Log",key:'ACTIVITY_LOGS'},{name:'Activity',key:'ACTIVITY'}]
  @Input() selectedActivity:any="ALL"
  expandAll:boolean=true
  collapseData:any={}
  itemIcons = ItemIcons;
  contactBoardId: any=[];
  userList: any=[];
  filterSub: any;
  filterDataObj: any={};
  activityData:any={}
  dashboardJsonChannel: any={};
  currentDate:any={}
  headerOptions:any=[]
  moreHeaderOptions:any=[]
  @ViewChild('activityViewHeader', { static: false }) activityViewHeader: ElementRef;
  isPublicLead: any=false;
  taskBucketInfo:any={}
  connectedBoardInfoList: any=[];
  chatCommentArr: any;
  contactTypeBoardData: any[];
  selectedChannel: any={};
  activityIcons = ActivityIcons;
  chatMesssageGroupArr: any[]=[];
  filterPopupInfo:any ={}
  activityMsgGroup: any[]=[];
  activityEmailGroup: any[]=[];

  constructor(
    private httpTransfer: HttpTransferService,
    private modalService: BsModalService,
    private constantService: ConstantService,
    private commonUtils: CommonUtils,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef,
    private customStorageService: CustomStorageService,
    private ngbModalService: NgbModal,
    private dashboardUtils: DashboardUtilsService,
    public MqttmessagingService: MqttmessagingService,
    private msgservice: MessageService, 
    private sortingUtils:SortingutilsService
    
  ){
    this.image = this.constantService.getImageConstant();
    this.file = this.constantService.getFileConstant();
    this.video = this.constantService.getVideoConstant();
    this.audio = this.constantService.getAudioConstant();
    this.time = this.constantService.getTimeConstant();
    this.timelineOpenFrom = this.constantService.getTimelineOpenFromInfo();
    this.timelineFilter = this.constantService.getTimlineFilter();
    this.fileTypeConstants = this.constantService.getFileTypeConstantArr();
  }

  async ngOnInit() {
    if(this.viewType!='bottomBarView') {
      this.loaderConfig.commentloader=true
    }
    this.dashboardUsers= await this.dashboardUtils.getOrgUsers()
    this.userList=Object.values(this.dashboardUsers)
    this.itemTerminology=this.dashboardUtils.getDashboardTerminoloy(this.dashId)
    if(this.viewType=='ActivityView'){
      this.filterSub = this.msgservice.getFilterDataObject().subscribe(data => {
        this.filterDataObj = data;
        this.loaderConfig.commentloader=true
        let filterDataObj = this.commonUtils.cleanFilterDataObject(this.filterDataObj)
        let saveFilterPayload = this.commonUtils.getFilterJsonForQuery({},filterDataObj)
        delete saveFilterPayload.grouping_details
        this.filterJson['item_filter']=saveFilterPayload
        this.getCommentsForLead(true)
      })
      this.onInitialize.emit(null)  
    }else{
      this.getCommentsForLead(true)
    }
    if(this.viewType!='bottomBarView') {
      this.getActivityBorad();
    }
    this.listenForMqttMessages()
    if(!this.isPublicLead){
      this.commonUtils.isDashboardReadOnly(this.dashId).then((res : any) => { 
        this.isReadOnly = res;     
      });
    }
    if(this.dashId){
      this.contactTypeBoardData = await this.dashboardUtils.contactTypeBoard(this.dashId);
    }
    this.contactTypeBoardData.forEach(e=>{
      this.contactBoardId.push(e.dashboardInfo.dashboard_id)
    })
    let url=document.baseURI.split('/')[2]
    this.checkBaseUrl=this.allowedUrl.includes(url)
  }


  listenForMqttMessages() { 
    this.mqttSubscription = this.MqttmessagingService.getMqttMessage().subscribe(message => {
      if (message) {
          this.updatesFromWss(message)
      }
    });
  }

  updatesFromWss(messagees) {
    let message = JSON.parse(messagees);
    console.log(message)
    const msgAdditionals = message?.additional_attributes
    if(this.dashId==msgAdditionals?.dashboard_id && message[ConstantService.constant.OBJECTTYPEKEY] == 'COMMENT' && (message[ConstantService.constant.ACTIVITYTYPEKEY]=='ADD'  || message[ConstantService.constant.ACTIVITYTYPEKEY]=='UPDATE')){
      let newTimeLine=message?.additional_attributes?.details;
      if(newTimeLine?._id && (!this.leadInfo || newTimeLine?.type=='ACTIVITY_LOGS' && newTimeLine?.activity_logs_obj?.lead_id==this.leadInfo._id || newTimeLine?.type=='ACTIVITY' && newTimeLine?.lead_id?.includes(this.leadInfo?._id))){
        this.currentDate=new Date()
        this.removeActivity(newTimeLine) // remove duplicate sctivity or comment
        let isUpcomingACtivity=newTimeLine?.type=='ACTIVITY' && newTimeLine?.activity_time>this.currentDate?.getTime()
        let obj=this.fillActivitydata(newTimeLine,isUpcomingACtivity)
        let index=this.sortingUtils?.getActivityIndex(this.activityData[obj?.key]?.activityList,newTimeLine,'activity_time',(isUpcomingACtivity ? 'ASC' : 'DSC'))
        this.activityData[obj?.key]?.activityList?.splice(index,0,obj?.valueObj)
        this.cdr.detectChanges();
      }else{
        this.removeActivity({_id:message?.object_type_details?.object_type_id})
      }
    }else if(message[ConstantService.constant.ACTIVITYTYPEKEY]=='DELETE'){
      let newTimeLine=message?.additional_attributes?.details;
      this.removeActivity(newTimeLine)
    }
  }

  removeActivity(removeActivity){
    for(let i=0;i<this.activityDataList?.length;i++){
      let activityIndex=-1
      for(let j=0;j<this.activityDataList[i]?.activityList?.length;j++){
        let activity=this.activityDataList[i]?.activityList[j]
        if(activity?._id==removeActivity?._id)activityIndex=j
      }
      if(activityIndex>-1){
        this.activityDataList[i]?.activityList?.splice(activityIndex,1)
        break
      }
    }
  }

   handleLiveUpdateForActivity(message){
    let newTimeLine=message?.additional_attributes?.details;
    this.timelineDataObj = {};
    this.timelineDataObj["comment"] = newTimeLine?.comment;
    this.timelineDataObj["user_id"] = newTimeLine?.action_user_id;
    this.timelineDataObj["comments"] = [];
    this.timelineDataObj["activity_time"] = new Date(newTimeLine?.activity_time).getTime();
    this.timelineDataObj["image_url"] = this.dashboardUsers[newTimeLine?.action_user_id]?.image_url;
    this.timelineDataObj["name"] = this.dashboardUsers[newTimeLine?.action_user_id]?.name;
    this.timelineDataObj["lead_id"] = newTimeLine?.lead_id;
    this.timelineDataObj["lead_seq_no"] = newTimeLine?.lead_seq_no;
    this.timelineDataObj["object_type_key"]=message?.object_type_details?.object_type_id
    // this.timelineDataObj["_id"]=newTimeLine?._id

    this.getTimeLineParsedCommentsOfUpdatesArray(newTimeLine?.updates, newTimeLine?.comment);
    let index = this.timelineCommentArray.findIndex(m=>m?.object_type_key == message?.object_type_details?.object_type_id)
     if (index === -1) {
      this.timelineCommentArray.push(this.timelineDataObj);
    }
    let currentTime=new Date().getTime();//tcalculate the current time
    for (let key in this.groupedObject[0]) {
      // let element=this.groupedObject[0][key][0]
      this.groupedObject[0][key].forEach((element,i)=>{
        console.log("element",element,this.timelineDataObj)
        console.log("11---->",element?.object_type_key,this.timelineDataObj["object_type_key"])
        if(element?.object_type_key==this.timelineDataObj["object_type_key"] && element.user_id==this.timelineDataObj["user_id"] && element.lead_id==this.timelineDataObj["lead_id"]){
          this.groupedObject[0][key].splice(i,1)//remove the list of object from groupedObject
    }
      })
         this.groupedObject[0][key].unshift(this.timelineDataObj) //push timelineobj to 0th index of groupedObject
    }
    this.cdr.detectChanges();
  }

  ngAfterViewInit() {
    if(this.viewType=='ItemActivityView'){
      var ro = new ResizeObserver(entries => {
        this.calculateOptions()
      });
      ro.observe(this.activityViewHeader?.nativeElement);
    }else{
      this.calculateOptions()
    }
    this.cdr.detectChanges();
  }

  moveElementLeft() {
    this.widgetsContent.nativeElement.scrollLeft -= 250;
  }

  moveElementRight() {
    this.widgetsContent.nativeElement.scrollLeft += 250;
  }

 
  getTimeline(data?, timelineFlag?, dateObj?, userIdArr?, field_name_arr?, leadIdArr?) { 
    console.log(leadIdArr)   
    if(+(dateObj?.date_range?.start_date) > +(dateObj?.date_range?.end_date)) {
      return;
    }
    this.payload = {};
    if(!this.showScrollLoader) this.requestPending=true;
    var orgId = this.customStorageService.getItem("organisationid");
    if (timelineFlag == true) {
      this.payload = data;
    } else {
      var paginationJson = {};
      paginationJson["start_index"] = this.timelineCurrentPage;
      paginationJson["page_size"] = this.totalNumberOfTimelineItems;
      this.payload["pagination_details"] = paginationJson;
    }
    if (dateObj != null) {
      var arr = Object.keys(this.filterObjectForDate)
      if (("start_date" in this.payload)) {
        delete this.payload['start_date']
      } else if (("end_date" in this.payload)) {
        delete this.payload['end_date']
      } else if ("date_range" in this.payload) {
        delete this.payload['date_range']
      }
      this.payload[arr[0]] = this.filterObjectForDate[arr[0]]
    }

    if (userIdArr != null) {
      this.payload['action_user_id'] = userIdArr
    } 
    if (field_name_arr != null) {
      if (field_name_arr.length > 0) {
        this.payload['field_name'] = field_name_arr
      }
    }
    if (leadIdArr) {
      this.payload['item_id'] = this.boardTypeFilter ? [null] : leadIdArr;
    }

    this.httpTransfer.getTimeLineCorrespondingToLead(orgId, this.dashId, this.payload).subscribe(async (res : any) => {
      if (res.status == 200) {
        this.timelineResponseArray = [];
        this.leadIdCorrespondingToTimeline = [];
        let arr = res.result.timeline.map(msg=> {
          if(msg.comment) {
            return msg;
          } else {
            return;
          }
        });
        
        let userArrPayload = this.collectUserIDForCorrespondingPrfileImage(arr);
        var inputjson = {};
        inputjson["user_id"] = [];
        inputjson["user_id"] = userArrPayload;
        this.timeLineTotal_Pages = res.result.pagination_details.total_records;
        for (var i = 0; i < arr.length; i++) {                    
          if(arr[i]?.comment) {
            var obj = {};
            obj["activity_time"] = new Date(arr[i]["activity_time"]).getTime();
            obj["action_user_id"] = arr[i]["action_user_id"];
            obj["comment"] = arr[i]["comment"];
            obj["dashboard_id"] = arr[i]["dashboard_id"];
            obj["lead_id"] = arr[i]["lead_id"];
            obj["updates"] = arr[i]["updates"];
            obj["_id"] = arr[i]["_id"];
            obj["lead_seq_no"] = arr[i]["lead_seq_no"];
            obj["field_name_for_seq"] = arr[i]["updates"][0].field_name;
            this.timelineResponseArray.push(obj);
            if(obj["lead_id"]) this.leadIdCorrespondingToTimeline.push(obj["lead_id"]);
          }
        }
        this.getTimelineParsedComments();
        this.showScrollLoader=false
        this.lock=false;
      }
      this.cdr.detectChanges();
    });
  }

  collectUserIDForCorrespondingPrfileImage(arr?) {
    var userId = [];
    for (var i = 0; i < arr.length; i++) {      
      if(arr?.comment) {
        userId.push(arr[i].action_user_id);
      }
    }
    return userId;
  }

  getTimelineParsedComments() {
    for (let i = 0; i < this.timelineResponseArray.length; i++) {
      this.timelineDataObj = {};
      this.timelineDataObj["comment"] = this.timelineResponseArray[i].comment;
      this.timelineDataObj["user_id"] = this.timelineResponseArray[i].action_user_id;
      this.timelineDataObj["comments"] = [];
      this.timelineDataObj["activity_time"] = new Date(this.timelineResponseArray[i]["activity_time"]).getTime();
      this.timelineDataObj["image_url"] = this.dashboardUsers[this.timelineResponseArray[i].action_user_id]?.image_url;
      this.timelineDataObj["name"] = this.dashboardUsers[this.timelineResponseArray[i].action_user_id]?.name;
      this.timelineDataObj["lead_id"] = this.timelineResponseArray[i].lead_id;
      this.timelineDataObj["lead_seq_no"] = this.timelineResponseArray[i].lead_seq_no;
      this.timelineDataObj["object_type_key"]=this.timelineResponseArray[i]._id
      this.timelineDataObj["_id"]=this.timelineResponseArray[i]._id
      this.timelineDataObj["field_name_for_seq"] = this.timelineResponseArray[i]["updates"][0].field_name;
      console.log('this is update board type activity',this.timelineResponseArray[i].updates)
      this.getTimeLineParsedCommentsOfUpdatesArray(this.timelineResponseArray[i].updates, this.timelineResponseArray[i].comment);
      this.timelineCommentArray.push(this.timelineDataObj);
    }
  }

  getFilterActivity(data){
    this.timelineDataObj = {};
    this.timelineDataObj["comment"] = data.comment;
    this.timelineDataObj["user_id"] = data.action_user_id;
    this.timelineDataObj["comments"] = [];
    this.timelineDataObj["activity_time"] = new Date(data["activity_time"]).getTime();
    // this.timelineDataObj["image_url"] = this.dashboardUsers[data.action_user_id]?.image_url;
    // this.timelineDataObj["name"] = this.dashboardUsers[data.action_user_id]?.name;
    this.timelineDataObj["lead_id"] = data.lead_id;
    this.timelineDataObj["lead_seq_no"] = data.lead_seq_no;
    this.timelineDataObj["_id"]=data._id
    this.timelineDataObj["field_name_for_seq"] = data["updates"][0].field_name;
    this.getTimeLineParsedCommentsOfUpdatesArray(data.updates,data.comment);
    return this.timelineDataObj
  }


  checkForBoardMovedActivity(){
      console.log('this is update board type activity')
     
  }

  getDateFromTime(time) {
    return this.commonUtils.getAgingTimeForHeatMap(time);
  }

  categorizeTheDates() {
    var arr = []
    this.timelineCommentArray.forEach(element => {
      let dayWiseData = this.commonUtils.getAgingTimeForHeatMap(element.activity_time);

      element['label'] = dayWiseData['date']
      arr.push(dayWiseData)
    });

    this.groupedObject = this.groupBy(this.timelineCommentArray, 'label')
    var arr = []
    for (let key in this.groupedObject) {
      console.log("key",this.groupedObject[key])
      var Obj = {}
      Obj[key] = this.groupedObject[key]
      arr.push(Obj)
    }
    this.groupedObject = arr;
    
    this.requestPending=false
  }

  groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  obj = {};
  getTimeLineParsedCommentsOfUpdatesArray(arr, comment?) {
    this.comment = arr?.comment?arr.comment:'';
    arr.forEach((msg) => {
      this.obj = {};
      this.obj['url'] = []
      if(msg.update_values){
        this.obj["comments"] = this.parseAndConvert(msg.comment, msg.update_values);        
      }
      if(msg.operation_type && msg.operation_type=="MOVE_IN"){
        console.log('This are updates',msg)
        this.timelineDataObj['previous_board']=msg.previous_value
      }
      this.timelineDataObj["comments"].push(this.obj);
    })
  }



  parseAndConvert(str, arr) {
    const regexp = /{{#\d+}}/g;
    return str.replace(regexp, (ignore, key) => {
      let half = ignore?.split('{{#')[1];
      let id = half?.split('}}')[0];
      let updateJson = arr[parseInt(id)];
      if (updateJson == null || updateJson.type == null) {
        return '';
      }
      
      if (updateJson.type === 'IMAGE' || updateJson.type == 'SingleImage' || updateJson.type == 'MultipleImage') {
        this.obj['type'] = 'MULTIIMAGE';
        if(updateJson?.media_name){
        this.obj["url"].push(updateJson.value);
        return '';
        }
        else{
          return updateJson.value
        };
      }
      else if (updateJson.type === 'VIDEO' || updateJson.type == 'SingleVideo' || updateJson.type == 'MultipleVideo') {
        this.obj['type'] = 'MULTIVIDEO';
        if(updateJson?.media_name){
          this.obj["url"].push(updateJson.value);
          return '';
          }
          else{
            return updateJson.value
          };
      }
      else if (updateJson.type === 'AUDIO' || updateJson.type == 'SingleAudio' || updateJson.type == 'MultipleAudio') {
        this.obj['type'] = 'MULTIAUDIO';
        if(updateJson?.media_name){
          this.obj["url"].push(updateJson.value);
          return '';
          }
          else{
            return updateJson.value
          };
      }
      else if (updateJson.type === 'FILE' || updateJson.type == 'SingleFile' || updateJson.type == 'MultipleFile') {
        this.obj['type'] = 'MULTIPLEFILE';
        if(updateJson?.media_name){
          this.obj["url"].push(updateJson.value);
          let extension = updateJson.key.split('.')
          this.obj['ext'] = extension[1]
          return '';
          }
          else{
            return updateJson.value
          };
      }
      else if (updateJson.type === 'Mobile' || updateJson.type === "MultipleMobile") {
        this.obj['type'] = 'Simple_Message';
        let finalValue='';
        let data=Array.isArray(updateJson?.value) ? updateJson?.value : [updateJson?.value]
        for(let i=0;i<data?.length;i++){
          let value=data[i];
          if(value?.country_code || value?.mobile_number || value?.full_mobile_number){
            finalValue+=value?.full_mobile_number+(i!=data?.length-1 ? ',' : '');
          }else if(value){
            finalValue+=updateJson?.value
          }
        }
        return finalValue
      }
      else if (updateJson.type === 'Currency' || updateJson.type === 'CURRENCY') {
        this.obj['type'] = 'Simple_Message';
        return updateJson?.value;
      }
      else if (updateJson.type === 'DATE' || updateJson.type === 'Date') {
        this.obj['type'] = 'Simple_Message';
          if(updateJson?.value?.date ||  updateJson.value && typeof updateJson?.value=='number'){
            let finalDate;
            let date;
            if(typeof updateJson?.value=='number'){
              date=this.commonUtils.calculateTimestampSince1900(updateJson.value)
            }else {
              date=!updateJson?.value?.is_time_added ? this.commonUtils.calculateTimestampSince1900(updateJson?.value?.date) : updateJson?.value?.time;
            }
            date =new Date(date)
            if(date && date?.toString() != "Invalid Date"){
              let dateObj=this.commonUtils?.getHumanReadableDateAndTimeFromDate(date,true,false);
              finalDate =dateObj?.date + (updateJson?.value?.is_time_added ? '-'+dateObj?.time : '')
            }
            return finalDate
          }
          else{
            return updateJson?.value
          }
      }
      else if (updateJson.type === 'DATE_TIME') {
        this.obj['type'] = 'Simple_Message';
        return this.datePipe.transform(updateJson.value, 'yyyy/MM/dd');
      }
      else if(updateJson.type === 'MultipleBoard'){
          return updateJson.value
      }
      else {
        this.obj['type'] = 'Simple_Message';
        return updateJson.value;
      }
    });
  }

  getTimelineAccordingToPageNo() {
    let itemId = this.item_id ? [this.item_id] : this.selecteLeadIdsForTimelineMsg;
    let userIdArray = this.item_id ? this.selectedUserIdsArrToFilterTimelineRight : this.selectedUserIdsArrToFilterTimeline;
    var inputJson = {};
    var paginationJson = {};
    paginationJson["start_index"] = this.timelineCurrentPage;
    paginationJson["page_size"] = this.totalNumberOfTimelineItems;
    inputJson["pagination_details"] = paginationJson;
    this.getTimeline(inputJson, true, this.filterObjectForDate,userIdArray, this.filterArrForList, itemId);
  }

  gettingTimeLineOnScroll() {
    if(!this.lock){
      this.lock=true;
    this.timelineCurrentPage = this.timelineCurrentPage + this.totalNumberOfTimelineItems;
    if (this.timelineCurrentPage <= this.timeLineTotal_Pages) {
      this.showScrollLoader=true;
      this.getTimelineAccordingToPageNo();
    }
  }
  }
  openLeadModel(currentLeadId){
    this.itemPreviewData=[{itemId:currentLeadId,boardId:this.dashId}]
    this.cdr.detectChanges();
  }

  onNavigateToEditLeadPage(item) {
    var editUrl = "dashboard/" + this.dashId + "/" + item.seq_id;
    window.open(editUrl, "_blank");
  }

  viewImage(path, template) {
    // this.imageData.emit(path)
    this.imageUrl = "";
    this.imageUrl = path;
    // this.openModal(template);
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  showMoreFromCollapseAll() {
    this.expandAllLeadComments = !this.expandAllLeadComments;
    this.showMoreCommentsIndexValue = null;
  }

  showMore(index) {

    this.timelineCommentArray.forEach((element) => {
      element["showMore"] = false;
    });
    this.timelineCommentArray.forEach((element, i) => {
      if (index == i) {
        element["showMore"] = true;
      }
    });
    this.showmore = true;
  }

  filterUsers(val) {
    // this.userSearchStatus = true;
    this.usersPipeSearchFilterResult = this.userInfoArrayCopy.filter(
      function (tag) {
        return tag.name.toLowerCase().indexOf(val) !== -1 || !val;
      }
    );
    this.usersPipeSearchFilterResult.forEach(element => {
      this.filterArrForUserId.forEach(item => {
        if (element._id == item) {
          element.status = true
        }
      });
    });
  }

  applyFilterForTimelineByList(data: string,key) {
    this.timelineCurrentPage = 0;
    let userIdArray = this.item_id ? this.selectedUserIdsArrToFilterTimelineRight : this.selectedUserIdsArrToFilterTimeline;
    let itemId = this.item_id ? [this.item_id] : this.selecteLeadIdsForTimelineMsg;
    if (this.selectedTimelineButtonName.includes(data)) {
      const delIndex = this.selectedTimelineButtonName.indexOf(data);
      this.selectedTimelineButtonName.splice(delIndex, 1);
      this.filterArrForList.splice(delIndex, 1);
    } else {
      this.selectedTimelineButtonName.push(data);
      this.filterArrForList.push(key);
    }
    this.timelineResponseArray = []
    this.timelineCommentArray = []
    this.getTimeline(null, false, this.filterObjectForDate, userIdArray, this.filterArrForList, itemId)
  }

  applyFilterForTimelineByUser(userIdArr: string[]) {
    let leadIdArr = this.item_id ? [this.item_id] : this.selecteLeadIdsForTimelineMsg;
    this.getTimeline(null, false, this.filterObjectForDate, userIdArr, this.filterArrForList, leadIdArr)
    this.timelineResponseArray = []
    this.timelineCommentArray = []
  }

  applyFilterForTimelineByDate() {
    this.timelineCurrentPage = 0
    let dateObj = {}
    let leadIdArray = this.item_id ? [this.item_id] : this.selecteLeadIdsForTimelineMsg;
    let userIdArray = this.item_id ? this.selectedUserIdsArrToFilterTimelineRight : this.selectedUserIdsArrToFilterTimeline;

    if (this.selectedEndDate === undefined && this.selectedStartDate !== undefined) {
      let date = this.commonUtils.epochToDate(this.selectedStartDate._d);
      let unixSeconds = ((new Date(date)).getTime());
      dateObj['start_date'] = unixSeconds;
      this.filterObjectForDate['date_range'] = dateObj;
      this.setStartDateInputField = true;
      this.timelineResponseArray = []
      this.timelineCommentArray = []
      this.getTimeline(null, false, this.filterObjectForDate, userIdArray, this.filterArrForList, leadIdArray);

    } else if (this.selectedStartDate === undefined && this.selectedEndDate !== undefined) {
      let date = this.commonUtils.epochToDate(this.selectedEndDate._d);
      let unixSeconds = ((new Date(date)).getTime()); 
      unixSeconds = unixSeconds + 86399999;
      dateObj['end_date'] = unixSeconds;
      this.filterObjectForDate['date_range'] = dateObj;
      this.setEndDateInputField = true;    
      this.timelineResponseArray = []
      this.timelineCommentArray = []
      this.getTimeline(null, false, this.filterObjectForDate, userIdArray, this.filterArrForList, leadIdArray)
    } else {
      let date = this.commonUtils.epochToDate(this.selectedStartDate._d);
      let end_date = this.commonUtils.epochToDate(this.selectedEndDate._d);
      let startDate = ((new Date(date)).getTime())
      let enddate = ((new Date(end_date)).getTime() + 86399999)
      dateObj['start_date'] = startDate
      dateObj['end_date'] = enddate
      this.filterObjectForDate['date_range'] = dateObj;
      this.setStartDateInputField = true;
      this.setEndDateInputField = true;
      this.timelineResponseArray = []
      this.timelineCommentArray = []
      this.getTimeline(null, false, this.filterObjectForDate, userIdArray, this.filterArrForList, leadIdArray)
      this.showLastUpdatedDate = true
    }
  }
  getDisplayDate(dateValue){
    return this.datePipe.transform( dateValue ? this.commonUtils.epochToDate(dateValue._d) : new Date(), 'dd-MM-yyyy') 
  }

  selectedButtonStyling(val) {
    this.selectedButtonForTimelineQuerry = val
  }

  onStartDateClick() {
    this.isDateActive = false;
  }
  
  onEndDateClick() {
    this.isDateActive = true;
  }

  clearTimelineFilter() {
    // clear lead ids filter
    this.timelineCurrentPage = 0
    this.selecteLeadIdsForTimelineMsg=[]
    this.selectedUserIdsArrToFilterTimelineRight=[]
    let itemId = this.item_id ? [this.item_id] : this.selecteLeadIdsForTimelineMsg;
    //clear start or end date filter
    this.filterObjectForDate={}
    this.selectedStartDate = undefined;
    this.selectedEndDate = undefined;
    this.setStartDateInputField=false
    this.setEndDateInputField=false
    //clear user ids filter
    this.selectedUserIdsArrToFilterTimeline=[]
    this.timelineResponseArray = []; // clear timeline arr on click clear button
    this.timelineCommentArray = []; // clear timeline comment arr on click clear button
    this.selectedTimelineButtonName = [];
    this.filterArrForList = [];
    var inputJson = {};
    var paginationJson = {};
    paginationJson["start_index"] = this.timelineCurrentPage;
    paginationJson["page_size"] = this.totalNumberOfTimelineItems;
    inputJson["pagination_details"] = paginationJson;
    //this.getTimeline(inputJson, true, null, null, null, itemId);
    this.getTimeline(inputJson, false, this.filterObjectForDate, this.selectedUserIdsArrToFilterTimeline, this.filterArrForList, itemId);
  }

  clearStartDate() {
    let itemId = this.item_id ? [this.item_id] : this.selecteLeadIdsForTimelineMsg;
    delete this.filterObjectForDate?.date_range?.start_date;    
    this.selectedStartDate = undefined;
    if(!this.filterObjectForDate?.date_range?.end_date) {
      delete this.filterObjectForDate.date_range;
    }
    this.setStartDateInputField = false;
    this.isDateRangeGreaterThanStartDate = false;
    this.getTimeline(null, false, this.filterObjectForDate, this.selectedUserIdsArrToFilterTimeline, this.filterArrForList, itemId);
  }

  clearEndDate() {
    let itemId = this.item_id ? [this.item_id] : this.selecteLeadIdsForTimelineMsg;
    delete this.filterObjectForDate?.date_range?.end_date;     
    this.selectedEndDate = undefined;
    if(!this.filterObjectForDate?.date_range?.start_date) {
      delete this.filterObjectForDate.date_range;
    }
    this.setEndDateInputField = false;
    this.isDateRangeGreaterThanStartDate = false;
    this.getTimeline(null, false, this.filterObjectForDate, this.selectedUserIdsArrToFilterTimeline, this.filterArrForList, itemId)
  }

  showMoreComments(index: number) {
    this.showMoreCommentsIndexValue = index;
  }

  showLessComments() {
    this.showMoreCommentsIndexValue = null;
  }

  applyFilterForTimelineBtItemID(leadIdOrName) {
    this.dashboardLeadsBySeqId = null
    this.getDashboardLeads(leadIdOrName.term);
  }

  filterTimelineMessageByLeadId() {
    console.log(this.boardTypeFilter)
    this.timelineCurrentPage = 0
    this.timelineResponseArray = []  // reset arr
    this.timelineCommentArray = []  // reset arr      
    this.getTimeline(null, false, this.filterObjectForDate, this.selectedUserIdsArrToFilterTimeline, this.filterArrForList, this.selecteLeadIdsForTimelineMsg);
    this.dashboardLeadsBySeqId = [];
  }

  getDashboardLeads(leadIdOrName: string) {
    leadIdOrName  = leadIdOrName.trim()
    if (leadIdOrName.length > 0 && leadIdOrName.length < 2) return;
    const searchObj = {};
    searchObj['search_params'] = {"search_text":leadIdOrName};
    
    this.httpTransfer.getLeadQuery(searchObj, this.dashId).subscribe((res : any) => {
      if (res !== undefined && res) {
        this.dashboardLeadsBySeqId = res.result.leadResponse;
        this.cdr.detectChanges();
      }
    })
  }

  openImageInPopup(modalContent, imageUrlForModal) {
    this.imageUrlForModal = imageUrlForModal;
    this.ngbModalService.open(modalContent, { size: 'lg' ,windowClass:"activityImagePreview"})
  }

  openFileModal(modalContent, fileUrl) {
    this.fileUrlForModal = fileUrl;
    this.ngbModalService.open(modalContent, { size: 'lg' })
  }

  onSelectEndDate() {  
    if(this.selectedStartDate?._d?.getTime() > this.selectedEndDate?._d?.getTime()) {
      this.isDateRangeGreaterThanStartDate = true
    } else {
      this.isDateRangeGreaterThanStartDate = false;
    }
  }
  onSelectStartDate() {
    if(this.selectedStartDate?._d?.getTime() > this.selectedEndDate?._d?.getTime()) {
      this.isDateRangeGreaterThanStartDate = true
    } else {
      this.isDateRangeGreaterThanStartDate = false;
    }
  }
  styleObject(): Object {
    if (this.isHeaderRequired) {
      return {
        // 'overflow-y': AutoScroll, 'margin-top': '30px',
        'float': 'left', 'background-color': "white"
      }
    } else {
      return { height: this.height + 'vh' }

    }
  }

  transform(time: number){
    let now = new Date(time);
    let year = now.getFullYear().toString().slice(-2);
    let month = now.getMonth() + 1;
    let day = now.getDate();
    let hours = now.getHours();
    let minutes = now.getMinutes();
    let period = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12;
    return `${year}/${month}/${day} ${hours >= 10 ? hours : '0' + hours}:${minutes >= 10 ? minutes : '0' + minutes} ${period}`;
  }

  updateTimeLine(event,data,i){
    let obj={}
    data.activity_time=event.time
    obj['activity_time']=event.time
    this.httpTransfer.updateTimeLineDate(obj,data.lead_id,this.dashId,data?.activity_logs_obj?.activity_timeline_id).subscribe(res=>{
      this.cdr.detectChanges();
    })
    
  }

  ngOnDestroy() {
    this.mqttSubscription?.unsubscribe();
    this.filterSub?.unsubscribe();
  }

  changeTab(activityType,typeKey){
        this.selectedHistoryActivity=null
    this.selectedActivityBoard=null
    if(this.moreHeaderOptions?.length>0){
      let index=this.moreHeaderOptions?.findIndex(option=>activityType && (activityType==option.key || activityType==option?.dashboard_id))
      if(index>-1){
        let value=this.moreHeaderOptions[index]
        this.moreHeaderOptions?.splice(index,1)
        this.moreHeaderOptions.push(this.headerOptions[this.headerOptions?.length-1])
        this.headerOptions[this.headerOptions?.length-1]=value
      }
    }
    if(this.viewType=='ItemActivityView' && ['INTERNAL','EMAIL','CHANNELS'].includes(activityType)){
      this.selectedActivity=activityType
      delete this.filterJson['activityType']
    }else{
      this.selectedActivity='ALL'
      if( this.filterJson['activityType']!==activityType ){
      this.loaderConfig.commentloader=true
      this.filterJson['activityType']=activityType
      if(this.filterJson['activityType']=='ALL'){
        delete this.filterJson['aditional_filter']
      }else{
        this.filterJson['aditional_filter']={[typeKey]:[activityType]}
      }
      this.getCommentsForLead(true)
    }
 
    }
  }
  clearFilter(){
    this.filterJson.activityTime={}
    delete this.filterJson.created_by
    this.filterJson.type=['ALL']
    this.getCommentsForLead(true)
  }

 async  getComments(){
    let chatUserJson = {}
    this.dashboardUtils.getDashboardChannels([this.dashId]).then(async res=>{
      let channelsList = res[this.dashId]?.CHANNELS || []
      let channelIdArray = channelsList.map(e=>e?._id)
    this.httpTransfer.getChatUserQuery({'dashboard_id' : [this.dashId],'channel_id':channelIdArray}).subscribe(res=>{
    let chatUserDetails = res.result?.chatUsers || []
    if(this.chatCommentArr?.length){
      this.httpTransfer.getCommentCorrespondingLead({type:["MESSAGE_GROUP"],lead_id:[this.leadInfo?._id]}).subscribe(async (res : any) => {
        if(res.status==200){
          let msgGroup = res?.result?.commentsInfo
          res?.result?.commentsInfo.forEach(y=>{
            y['message_group_id'] = msgGroup.filter(p=>p?.chat_user_id == y?.chat_user_id).map(e=>e._id)
            y['comment'] = this.chatCommentArr.filter(q=>q?.message_group_id == y?._id)
            let channel = channelsList.filter(i=>i._id == y?.channel_id)
            y['channelType'] = channel[0]?.type
            let chatUser = chatUserDetails?.filter(m=>m._id==y?.chat_user_id)
            console.log(chatUser)
            y['chatUserName'] = chatUser[0]?.name
            y['profile_pic'] = chatUser[0]?.profile_pic
            y['mobile'] = chatUser[0]?.platform_id
            if(!chatUserJson[y?.chat_user_id]){
              chatUserJson[y?.chat_user_id] = y
            }
            else{
             chatUserJson[y?.chat_user_id].comment = [...chatUserJson[y?.chat_user_id].comment,...y?.comment]
            }
          })
          this.chatMesssageGroupArr = Object.values(chatUserJson)
          delete this.chatMesssageGroupArr[0]?.closed_by
          this.selectedChannel = this.chatMesssageGroupArr[0]
          console.log(this.chatMesssageGroupArr)
        }
        this.cdr?.detectChanges()
      })  
    }
  })
  })
}

loaderCreateACtivityView(board){
  this.loaderConfig.createActivityViewLoader=true
  this.selectedActivityBoard=board;
  this.dashboardUtils.getAndSyncAllDashboardData([board?.dashboard_id],false,['CustomForms']).then(()=>{
    setTimeout(() => {this.loaderConfig.createActivityViewLoader=false},5);
  })
}

changeChannel(option){
  this.selectedChannel=null
  delete option?.closed_by
  setTimeout(() => {  this.selectedChannel = option},1);
}


  //

  async getCommentsForLead(filter=false,scroll=false,viewMore=false){
    let aditionalFilter={'sorting_details':{sort_by:'activity_time',sort_order:"ASC"}}
    let [res1,res2]=[null,null]
    if(filter && !viewMore){
      [res1,res2]=await Promise.all([this.commentsForLead(filter,scroll,true,aditionalFilter),this.commentsForLead(filter,scroll,false)]);
    }else if(viewMore){
      this.loaderConfig.viewMoreLoader=true
      res1=await this.commentsForLead(filter,scroll,true,aditionalFilter)
    }else{
      res1=await this.commentsForLead(filter,scroll,false)
    }
    if(filter){
      this.activityData={upcoming:{activityList:[],title:'Upcoming & Overdue',key:'upcoming'}}
      this.activityDataList=[this.activityData['upcoming']]
    } ;
    [res1,res2]?.forEach(data=>{
      if(data){
        let response=data?.response?.result;
        (response?.commentsInfo || []).forEach(value=>{
          if (this.activityId) {
            if (value?._id == this.activityId) {
              if (value?.activity_time < this.currentDate?.getTime() || data?.upcommingActivity) {
                let obj = this.fillActivitydata(value, data?.upcommingActivity);
                this.activityData[obj?.key]?.activityList?.push(obj?.valueObj);
              }
            }
          } 
          else{
            if(value?.activity_time<this.currentDate?.getTime() || data?.upcommingActivity){
            let obj=this.fillActivitydata(value,data?.upcommingActivity)
            this.activityData[obj?.key]?.activityList?.push(obj?.valueObj)
             if(obj?.valueObj?.type=="MESSAGE_GROUP"){
                this.activityMsgGroup.push(obj?.valueObj)
            } 
            else if(obj?.valueObj?.type=="THREAD"){
              this.activityEmailGroup.push(obj?.valueObj)
          } 
            }
          } 
        })
        if(!data?.upcommingActivity)this.pagignationDetails.totalPageSize= response?.pagination_details?.total_pages
        else{
          let pagedata=response?.pagination_details
          this.activityData['upcoming'].count=pagedata?.total_records;
          this.activityData['upcoming'].enableViewMore=this.activityData['upcoming'].count>this.activityData['upcoming']?.activityList?.length
        }
      }
    })    
    delete this.loaderConfig.filterCommentloader
    delete this.loaderConfig.viewMoreLoader
    setTimeout(()=>{delete this.loaderConfig.commentloader},5)
    setTimeout(() => {delete this.loaderConfig.scrollCommentloader;this.cdr.detectChanges()},10); 
    this.cdr.detectChanges()
    console.log(this.activityEmailGroup)
      await this.getCommentsFilterForChannels()
  }

  async getCommentsFilterForChannels(){
  let messageIds = this.activityMsgGroup.map(item => item._id)
  let threadIds = this.activityEmailGroup.map(item=>item?._id)
  if(messageIds?.length){
    this.httpTransfer
    .getCommentCorrespondingLead({'message_group_id':messageIds})
    .subscribe((res : any) => {
      if(res.status==200){
       this.chatCommentArr = res?.result?.commentsInfo
       this.activityDataList.forEach((section, sectionIndex) => {
         section.activityList.forEach(m=>{
          if(m?.type=="MESSAGE_GROUP"){
          let arr = res?.result?.commentsInfo.filter(x=>x?.message_group_id == m?._id)
          m['comments'] = arr || []
          if(m?.comments?.length){
            m.comments[0]['channel_id'] = m?.channel_id
            m.comments[0]['chat_user_id'] = m?.chat_user_id
            m.comments[0]['lead_id'] = m?.lead_id
            m.comments[0]['message_group_item_id'] = m?.message_group_item_id
            if(m?.closed_by){
              m.comments[0]['closed_by'] = m?.closed_by
            }
          }
          }
         })
        this.getComments()
       })  }
    })
  }
  if(threadIds?.length){
    let result =  await this.httpTransfer.getCommentCorrespondingLead({'thread_id':threadIds}).toPromise()
    let threadCommentArr = result?.result?.commentsInfo || []
       this.activityDataList.forEach((section, sectionIndex) => {
         section.activityList.forEach(m=>{
          if(m?.type=="THREAD"){
          let arr = threadCommentArr?.filter(x=>x?.thread_object_id == m?._id)
          m['comments'] = arr || []
          }
         })
        this.getComments()
       }) 
  }

}

  async commentsForLead(filter=false,scroll=false,upcommingActivity=false,aditionalFilter={}){
    this.currentDate=new Date()
    let pageInfo={}

    if(!upcommingActivity){
      if(scroll){
        this.pagignationDetails.currentPageNumber=this.pagignationDetails.currentPageNumber+1
        if(this.pagignationDetails.totalPageSize<=this.pagignationDetails.currentPageNumber){
          return
        }
        this.loaderConfig.scrollCommentloader=true
      }
      pageInfo={
        page_number : this.pagignationDetails.currentPageNumber,
        page_size : this.pagignationDetails.pageSize
      } 
    }
    else{
      if(scroll){
        if(this.activityData['upcoming']?.count<=this.activityData['upcoming']?.activityList?.length){
          return
        }
        this.loaderConfig.scrollCommentloader=true
      }
      pageInfo={
        start_index:filter ? 0 : this.activityData['upcoming']?.activityList?.length || 0,
        page_size:(filter ? 2 : this.pagignationDetails.pageSize)
      }
    }
    if(filter)this.pagignationDetails.currentPageNumber=1
    var inputJson = {};
    if(this.dashId){
      inputJson['dashboard_id']= [this.dashId]
    }
    if(this.leadInfo?._id)inputJson['lead_id']=[this.leadInfo?._id]
    inputJson['pagination_details']=pageInfo
    inputJson={...inputJson,...aditionalFilter}

    // handle filter
    let dummyFilter = JSON.parse(JSON.stringify(this.filterJson || {}))
    if(this.filterJson.aditional_filter && this.filterJson.aditional_filter!='ALL'){
      dummyFilter.type=['ALL']
      dummyFilter.activityTime={}
    }
    if(this.filterJson.aditional_filter){
      Object.keys(this.filterJson.aditional_filter).forEach(key=>{
        dummyFilter[key]=this.filterJson.aditional_filter[key]
      })
    }
    if(dummyFilter['type']){
      if(dummyFilter['type']?.includes('ALL')){
        delete dummyFilter['type']
      }else{
        let types=dummyFilter['type']
        dummyFilter['type']=[]
        types?.forEach(type => {
          if(['ALL','INTERNAL','CHANNELS','ACTIVITY_LOGS','ACTIVITY','EMAIL']?.includes(type)){
            if(type!='CHANNELS')dummyFilter['type']?.push(type)
            else dummyFilter['type']=[...dummyFilter['type'],...['EXTERNAL_OUTBOUND','EXTERNAL_INBOUND']]
          }
          else{
            if(!dummyFilter?.activity_type_board_id)dummyFilter.activity_type_board_id=[]
            dummyFilter?.activity_type_board_id?.push(type)
          }
        });
        if(!dummyFilter['type']?.length){
          delete dummyFilter['type']
        }
      }
    }
    // filter out empty value in filter json
    Object.keys(dummyFilter || {}).forEach(key=>{
      if(dummyFilter[key] && ['{}','[]','null','undefined','']?.includes(JSON.stringify(dummyFilter[key])) || !dummyFilter[key]){
        delete dummyFilter[key]
      }
    })
    // handle date filter
    if(upcommingActivity){
      if(dummyFilter?.activityTime?.from || dummyFilter?.activityTime?.to){
        dummyFilter.activity_time={from: (dummyFilter?.activityTime?.from && dummyFilter?.activityTime?.from?.time>this.currentDate.getTime() ? dummyFilter?.activityTime?.from?.time : this.currentDate.getTime())} 
        if(dummyFilter?.activityTime?.to)dummyFilter['activity_time'].to=dummyFilter?.activityTime?.to?.time
        if(dummyFilter?.activityTime?.to<=this.currentDate.getTime()){
          return
        }
      }else{
        dummyFilter.activity_time={from: this.currentDate.getTime()} 
      }
    }else{
      if(dummyFilter?.activityTime?.from || dummyFilter?.activityTime?.to){
        dummyFilter['activity_time']={}
        if(dummyFilter?.activityTime?.from)dummyFilter['activity_time'].from=dummyFilter?.activityTime?.from?.time 
        dummyFilter['activity_time'].to=dummyFilter?.activityTime?.to?.time<this.currentDate.getTime() ? dummyFilter?.activityTime?.to?.time : this.currentDate.getTime()
      }else{
        dummyFilter.activity_time={to: this.currentDate.getTime()} 
      }

    }
    delete dummyFilter['activityTime']
    delete dummyFilter['activityType']
    delete dummyFilter.aditional_filter
    inputJson={...dummyFilter,...inputJson}

    let res =await this.httpTransfer.getCommentCorrespondingLead(inputJson).toPromise()
    return {response:res,upcommingActivity:upcommingActivity}
  }

  fillActivitydata(value,upcommingActivity){
    let date=new Date(value?.activity_time)
    let key=upcommingActivity ? 'upcoming' : date.getMonth()+'-'+date.getFullYear()
    if(!this.activityData[key]){
      this.activityData[key]={activityList:[],title:null,key:key}
      if(key==(this.currentDate?.getMonth()+'-'+this.currentDate?.getFullYear()))this.activityData[key].title = 'This month'
      else if(key=='upcoming')this.activityData[key].title = 'UpComing & Overdue'
      else this.activityData[key].title= date?.toLocaleDateString('default',{month:'long'})+(date.getFullYear()!=this.currentDate.getFullYear() ? '('+date.getFullYear()+')' : '')
      this.activityDataList.push(this.activityData[key])
    }
    if(value.type=="ACTIVITY_LOGS" && value?.activity_logs_obj)value['dummy_activity']=this.getFilterActivity(value?.activity_logs_obj)
      return {key:key,valueObj:value}
  }


  async getActivityBorad(){
    this.boardInfo=await this.dashboardUtils?.getAndSyncAllDashboardData([this.dashId])
    if(this.boardInfo[this.dashId]?.DASHBOARD_INFO?.email_enabled){
      this.activityTypes.push({name:'Email',key:'EMAIL'})
    } 
    this.connectedBoardInfoList=[this.boardInfo[this.dashId]?.DASHBOARD_INFO]
    let connectedBoardIds=(this.boardInfo[this.dashId]?.CUSTOM_FORM || [])?.reduce((arr,custom)=>{if(custom?.connected_board_id && custom?.type=='Board')arr?.push(custom?.connected_board_id);return arr},[]) 
    if(connectedBoardIds?.length>0){
      this.boardInfo=await this.dashboardUtils?.getAndSyncAllDashboardData(connectedBoardIds,false,['Info'])
      connectedBoardIds?.forEach(id=>{this.connectedBoardInfoList.push(this.boardInfo[id]?.DASHBOARD_INFO)})
    }
    this.getActivityTypeBoardData(this.boardInfo[this.dashId]?.DASHBOARD_INFO)
    this.dashboardUtils.getDashboardChannels([this.dashId]).then(res=>{
      let channelsList = res[this.dashId]?.CHANNELS || []
      if(channelsList?.length>0){
        this.dashboardJsonChannel=channelsList.reduce((json,channel)=>{json[channel._id]=channel;return json},{})
        this.activityTypes.push({name:'Channels',key:'CHANNELS'})
        if(this.leadInfo?._id){
         // this.getComments()
        }
      }
    })
    this.cdr.detectChanges()
  }

  async getActivityTypeBoardData(currentBoardInfo){
    let boardIds=currentBoardInfo?.connected_activity_types?.map(board=>board?.board_id) || []
    if(boardIds?.length>0){
      let res=await this.dashboardUtils?.getAndSyncAllDashboardData(boardIds,false,['ActivityBoardInfo'],[currentBoardInfo?.work_folder_id])
      this.boardInfo=res;
      this.activityTypeBoardList= Object.keys(res || {})?.reduce((data,boardId)=>{if(boardIds?.includes(boardId) && res[boardId]?.DASHBOARD_INFO){
        let obj=res[boardId]?.DASHBOARD_INFO;
        data?.push({...obj,key:obj?.dashboard_id,name:obj?.dashboard_name}
      )};return data},[])
      this.activityTypes=[...this.activityTypes,...this.activityTypeBoardList]
      // get task board buckets4
      this.getTaskBucket()
      this.calculateOptions()
      this.cdr.detectChanges()
    }
  }

  getTaskBucket(){
    let taskBoardIds=this.activityTypeBoardList?.reduce((data,board)=>{if(board?.board_type=='TASK_ACTIVITY'){data?.push(board?.dashboard_id)};return data},[])
    this.dashboardUtils.getAndSyncAllDashboardData(taskBoardIds,false,['Buckets']).then(res=>{
      this.taskBucketInfo=Object.keys(res || {})?.reduce((data,boardId)=>{
        if(res[boardId]?.BUCKET && res[boardId]?.DASHBOARD_INFO?.board_type=='TASK_ACTIVITY'){
          data[boardId]=res[boardId]?.BUCKET?.reduce((json,bucket)=>{json[bucket?._id]=bucket;return json},{})
        };
        return data},{})
    })
  }

  createActivity(){
    delete this.currentCreateActivityData['isValidated']
    let json={
      "type":"ACTIVITY",
      "activity_lead_obj":this.currentCreateActivityData,
      "activity_type_board_id":this.selectedActivityBoard?.dashboard_id
    }
    this.loaderConfig.createCommentloader=true
    this.httpTransfer.postCommentCorrespondingLead(json, this.dashId, this.leadInfo?._id).subscribe((res : any) => {
     if(res.status==200){
       delete this.loaderConfig.createCommentloader
       if(this.viewType !== "bottomBarView") {
         this.selectedActivityBoard=null;
       }
     }
    },eror=>{
      delete this.loaderConfig.createCommentloader
    })
  }

  updateActivity(json,boardId,itemId,commentId){
    this.httpTransfer.updateCommentCorrespondingLead(json,boardId,itemId,commentId).subscribe((res : any) => {
     }) 
  }

  deleteComment(commentId,leadId,dashboardId,index?){
    this.httpTransfer.deleteCommentCorrespondingLead(commentId,leadId,dashboardId).subscribe(res=>{
      if(res.status==200){

      }
    })
  }

  expendCollapse(){
      this.expandAll=!this.expandAll
      this.activityDataList.forEach(data=>{
        data?.activityList?.forEach(activity=>{
          activity.collapse=!this.expandAll;
        })
      })
      this.cdr.detectChanges()
  }

  calculateOptions(): void {
    if(this.viewType=='ItemActivityView'){
      // Clear the displayed and moreOptions arrays
      this.headerOptions=[]
      this.moreHeaderOptions = [];
      let remainingWidth =this.activityViewHeader?.nativeElement?.offsetWidth-120 // Initialize remaining width with screen width
      for (let option of this.activityTypes) {
        if(option?.key!='ACTIVITY'){
          const optionWidth = ((option?.dashboard_id ? option.dashboard_name?.length : option?.name?.length) * 10); // Assuming each character takes 10px width, adjust as needed
          if (optionWidth <= remainingWidth) {
            this.headerOptions.push(option); // Option fits in the header
            remainingWidth -= optionWidth;
          } else {
            this.moreHeaderOptions.push(option); // Option doesn't fit, move to "More" dropdown
          }
        }
      }
    }else{
      this.headerOptions=this.activityTypes
    }
  }

  changeMarkDone(itemObj){
    let finalCheck=this.taskBucketInfo[itemObj?.dashboard_id][itemObj?.bucket_id]?.is_destination || false
    let destinationBucket=Object.values(this.taskBucketInfo[itemObj?.dashboard_id]).find(bucket=>bucket['is_destination']===!finalCheck)
    if(destinationBucket){
      var leadObj = {destination_bucket_id : destinationBucket['_id']}
      this.httpTransfer.updateLeadCorrespondingToLeadId(leadObj,itemObj?.dashboard_id,itemObj?._id).subscribe((res : any) => {
          if(res.status == 200) {
            itemObj.bucket_id= destinationBucket['_id']
        }
      });
    }

  }

  async openAssociatePannel(data){
    data.associatePannel=true
    data.loadAssociatePannel=true
    if(data?.type=="EXTERNAL_INBOUND" || data?.type=="EXTERNAL_OUTBOUND" || data?.type=="EMAIL"){
      this.activityDataList.forEach((section) => {
        let message = data?.type=="EMAIL" ? section.activityList.filter(m=>m?.type=="THREAD" && m?._id== data?.thread_object_id) : section.activityList.filter(m=>m?.type=="MESSAGE_GROUP" && m?.Id== data?.message_group_id)
         if(message?.length){
          data['lead_id'] = message[0]?.lead_id?.length ? message[0]?.lead_id : data?.lead_id
         }
      })
    }
    if(data?.lead_id?.length>0){
      this.httpTransfer.getLeadQuery({lead_id:data?.lead_id}).subscribe((res : any) => {
        data.associateItemData={}
        if(res?.status==200){
          (res?.result?.leadResponse || [])?.forEach(item=>{
             if(!item?.is_deleted){
              if(!data?.associateItemData[item?.dashboard_id])data.associateItemData[item?.dashboard_id]=[]
                data.associateItemData[item?.dashboard_id]?.push(item)
                delete data.loadAssociatePannel
             }
          })
        }
        let boardIds=Object.keys(data?.associateItemData)
        if(boardIds?.length>0){
          this.dashboardUtils?.getAndSyncAllDashboardData(boardIds,false,['Info'])?.then((res:any)=>{this.boardInfo=res})
        }
      },err=>{delete data.loadAssociatePannel});
    }
  }

  openNewActivityPannel(){
    this.selectedActivityBoard=this.activityTypeBoardList.find(activity=>activity?.dashboard_id==this.filterJson?.activityType)
  }

  updateAssociateItem(data,item,check){
    let leadId=this.leadInfo?._id
    if(!this.leadInfo?._id)leadId=data?.associateItemData[this.dashId][0]?._id
    let associateIds=data?.lead_id
    if(!check?.target?.checked){
      associateIds=data?.lead_id?.filter(id=>id!==item?._id)
    }else{
      if(!data?.lead_id?.includes(item?._id))associateIds?.push(item?._id)
    }
    data.lead_id=associateIds
    if(leadId){
      let commentId = data?.message_group_id ? data?.message_group_id : data?._id
      this.updateActivity({associate_lead_id:associateIds},this.dashId,leadId,commentId)
    }
    
  }

  openConnectAssociateItemPanel(board,data){
    let dataObj={type:board?.dashboard_name,activityObj:data,connectedBoardId:board?.dashboard_id, connectedItemsIds:data?.associateItemData[board?.dashboard_id]?.map(item=>item?._id) || []}
    if(this.viewType=='ItemActivityView'){
      this.openSideFilterModal?.emit(dataObj)
    }else{
      let leadObj=data?.associateItemData[this.dashId]?.length ? data?.associateItemData[this.dashId][0] : null
      if(leadObj){
        this.filterPopupInfo={
          leadObj:leadObj,
          dashId:this.dashId,
          showSubtaskFilter:true,
          callType:dataObj.type,
          connectedBoardId:[dataObj?.connectedBoardId],
          connectedItemsIds:dataObj?.connectedItemsIds,
          activityObj:dataObj?.activityObj
        }
      }
    }
  }


  updateInnerConnected(data,activity,editCustomRef?){
    let json={...data}
    json['activityObj']=activity || {}
    json['type']=data.dashboard_name
    json['activityObj'].updateType='connectedItem'
    json['activityObj'].isCreate=data.isCreate
    this.openSideFilterModal?.emit(json)
  }

 closeConversation(data){
  console.log(data,this.leadInfo)
   this.httpTransfer.updateCommentCorrespondingLead({'close_conversation':true},this.dashId,this.leadInfo?._id,data?.message_group_id).subscribe(res=>{
    if(res?.status==200){}
  }) 
 }

 checkForFilterSelected() {
  if (this.filterJson['type']?.length > 1 && this.filterJson['type'].includes('ALL')) {
    const index = this.filterJson['type'].findIndex(value => value === 'ALL');
    if (index !== -1) {
      this.filterJson['type'].splice(index, 1);
    }
    this.filterJson['type'] = [...this.filterJson['type']];
    const i=this.activityTypes.findIndex(value => value?.key === 'ALL');
    if (i !== -1) {
      this.activityTypes.splice(i, 1);
    }
    this.activityTypes = [...this.activityTypes];
  }
  if(this.filterJson['type']?.length ==0){
    this.activityTypes.push({'key':'ALL','name':'All'})
    this.activityTypes = [...this.activityTypes];
  }
}

onPopoverShown() {
  console.log("filter json",this.filterJson)
  this.originalFilterJson = JSON.parse(JSON.stringify(this.filterJson));
}

onPopoverHidden() {
  this.filterJson = JSON.parse(JSON.stringify(this.originalFilterJson));
}


}
