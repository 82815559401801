import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from "@angular/core";
import { CommonUtils } from "app/services/CommonUtils.service";
import { HttpTransferService } from "app/services/httpTransfer.service";
import { AttributesIcons } from "Enums/attributes-icons";
import { setTime } from "ngx-bootstrap/chronos/utils/date-setters";

@Component({
    selector: 'app-connect-board',
    templateUrl: './connect-board.component.html',
    styleUrls: ['./connect-board.component.scss']
  })
  export class ConnectBoardComponent implements OnInit {
    @Output() boardChanges = new EventEmitter<any>();
    @Output() removeBoard = new EventEmitter<any>();
    @Output() connectBoards = new EventEmitter<any>();
    @Input() selectedDashboardIds: any;
    @Input() showAllBoards:boolean=true;
    @Input() dashId:any;
    @Input() noteCall:boolean=false;
    @Input() bottomCall:boolean=false;
    boardListArr: any[]=[];
    allBoardArr: any;
    selectedBoardList: any[]=[];
    attributesIcons = AttributesIcons;
    showLoader: boolean=false;
    
    constructor(private httpTransfer : HttpTransferService,public commonUtils : CommonUtils){}
  
    async ngOnInit(){
     await this.getDashboard()
    }


   async getDashboard(){
    let res:any = await this.httpTransfer.getDashBoardForAdmin().toPromise()
    let folder:any =  await this.httpTransfer.getDashBoardForAdmin({"status":["ACTIVE"],"type":["WORK_FOLDER"], "access_type": ["INTERNAL", "PRIVATE"],"get_my_boards": true}).toPromise()
    let folderInfo = folder.result.dashboards || [];
    if(res.result.dashboards?.length){
        res.result.dashboards.forEach(board => {
            const matchingWorkFolder = folderInfo?.find(folder => folder.dashboard_id === board.work_folder_id);
            if (matchingWorkFolder) {
              board.workFolderName = matchingWorkFolder.dashboard_name;
            }
          });
    }
    this.allBoardArr = res.result.dashboards || [];
    this.boardListArr = res.result.dashboards;
    this.filterSelectedBoard()
    this.showLoader=true
   }

   onDashboardCheckboxChange(event, dashboard_id: string) {
    if (event.target.checked) {
    this.selectedDashboardIds.push(dashboard_id);
    } else {
    this.selectedDashboardIds = this.selectedDashboardIds.filter(id => id !== dashboard_id);
    }
    if(!this.noteCall){
        this.boardChanges.emit(this.selectedDashboardIds)
        this.filterSelectedBoard()
    }
    }

  filterSelectedBoard(){
    if(this.selectedDashboardIds?.length){
        this.selectedBoardList = this.allBoardArr.filter(b=>this.selectedDashboardIds.includes(b?.dashboard_id))
        this.boardListArr = this.boardListArr.filter(d=>!this.selectedDashboardIds.includes(d?.dashboard_id))
    }
    else{
        this.showAllBoards = !this.bottomCall ? true : this.showAllBoards
    }
    console.log(this.selectedBoardList)
  }

  removeSelectedBoard(dashId: any,index) {
   this.boardListArr.unshift(this.selectedBoardList[index])
   this.selectedBoardList.splice(index,1)
   //this.selectedDashboardIds = this.selectedDashboardIds.filter(d=>d?.dashboard_id !== dashId)
   this.removeBoard.emit(dashId)
   //this.boardChanges.emit(this.selectedDashboardIds)
  }

  connectDashboards() {
    this.boardChanges.emit(this.selectedDashboardIds);
    this.filterSelectedBoard()
    this.showAllBoards=false;
    }
}
