import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonUtils } from 'app/services/CommonUtils.service';

@Component({
  selector: 'date-cell',
  changeDetection:ChangeDetectionStrategy.OnPush,
  templateUrl: './date-cell.component.html',
  styleUrls: ['./date-cell.component.scss']
})
export class DateCellComponent implements OnInit{

  params!: any;
  date: any={};
  editable: boolean = false
  itemInfo:any={}
  itemData:any={}

  constructor(private commonUtils:CommonUtils,private cdr:ChangeDetectorRef){}

   ngOnInit(){
    this.onUpdate()
  }

   onUpdate(params: any={}){
    this.editable=this.params?.editable
    this.itemInfo=this.params?.item || {}
    if(this.params?.field=="start_end_date"){
        this.date=this.params?.value || {}
    }else{
      this.itemData={"dateValidationJson":this.commonUtils.createJsonForDateValidation(this.params?.columnParams?.customField)}
      this.date={customDate:{}}
      if(this.params?.value?.date){
        this.date={customDate:this.params?.value}
      }
      else if(this.params?.columnParams?.customField?.is_end_date){
        this.date={customDate:this.params?.value ?this.params?.value:{'time':this.params?.value}}
      }
      else if(this.params?.value && typeof this.params?.value!='object'){
        this.date={customDate:{'time':this.params?.value}}
      }
      if(this.params?.value)
      this.date.customDate['originalValue']=this.params?.value
      
    }
    this.cdr.detectChanges()
  }

  changeStartEndDates(slectedDateObj){
    if(!slectedDateObj?.start_date)slectedDateObj.start_date = {date: null,time: null,is_time_added: false}
    if(!slectedDateObj?.end_date)slectedDateObj.end_date = {date: null,time: null,is_time_added: false}
    this.params?.gridTableRef?.changedCellValue({value:{...slectedDateObj},params:this.params});
    this.onUpdate();
  }
  onCustomDateChange(dateValue){
    this.params.value=dateValue
    let value=this.params.columnParams?.isCustomField ? dateValue : {[this.params.field]:dateValue}
    this.params?.gridTableRef?.changedCellValue({value:value,customFieldType:this.params.columnParams?.isCustomField || false,params:this.params});
    this.onUpdate()
  }

}
