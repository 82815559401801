  <div class="mx-0 h-100 toprighticons">
    <!----New Button-->
    <ng-container *ngIf="currentDashInfo?.type!=='WORK_FOLDER'">
      <board-creationbtn class="rightHeaderBtn" [headerCall]="true" [workfolderJson]="workfolderJson" [dashSelect]="dashSelect"></board-creationbtn>
    </ng-container>
  <hr class="sepraterLinevertical">
  <!-------Notification-------->
  <div *ngIf="showNotification" class="toprgtico f-s-r  position-relative noticepop" [ngbTooltip]="('helpingText.notifications' | translate)" placement="auto" container="body" tooltipClass="tooltipPopup">
    <div class="text-center position-relative notiIcon"  [ngbPopover]="notificationTemplate"  placement="bottom-right" autoClose="outside" container="body" #popNotifications="ngbPopover" popoverClass="noticepopover">
      <span class=" headerIcon"><app-dashboard-icon style="display: flex;align-items: center;justify-content: center;" [size]="24"  [name]="'Notification'" [iconJson]="attributesIcons" [isSvg]="true" [color]="(profileCall || homeCall)?'#000000':'#fff'"></app-dashboard-icon></span>
      <span *ngIf="totalUnreadNotifications>0" class="badge">
        <!-- {{totalUnreadNotifications<100 ? totalUnreadNotifications : 99+"+"}} -->
      </span>
    </div>
  </div> 
  <!-------Logged In User Details-------->
  <div *ngIf="showProfile && userListData" class="f-s-m avatarNotify" placement="bottom-right"  [ngbPopover]="userLogOutTemplate" popoverClass="logout-popover" #popUserLogin="ngbPopover" container="body">
      <ngx-avatars *ngIf="userListData" [name]="loginUserName" [src]="userListData[loginUserId]?.image_url" textSizeRatio="2.15" size="28" initialsSize="2" ></ngx-avatars>
      <!-- <span [ngStyle]="{'color': profileCall? '#000000' : '#ffffff'}">{{loginUserName}}</span> -->
  </div>
</div>

<!-- User settings start -->
<ng-template #userLogOutTemplate>
  <div class="userLogOutDropDown f-l-r">
    <div class="userName">
      <span *ngIf="userListData" style="display: flex;">
        <ngx-avatars *ngIf="userListData" class="mr-1" [name]="loginUserName" [src]="userListData[loginUserId]?.image_url" textSizeRatio="2.15" size="36" initialsSize="2" ></ngx-avatars>
      </span>  
      <span class="loggedinUserDetail"><span class="f-l-r">{{loginUserName}}</span>
        <span class="f-s-r loggedinUserEmail">{{loggedinUserEmail}}</span>
        
      </span>
      
    </div>
    <div class="d-block border-bottom my-2"></div>
    <div class="scrollorg optionDetailBoxBody">
      <ng-container *ngFor="let org of orgData">
        
        <span class="optionDetailBox orgName" [class]="{'active': selectedOrganization?.organization_id === org.organization_id}" (click)="switchOrg(org.organization_id);popUserLogin.hide()">
         
         <span class="f-l-r orgNameText"> {{ org.organization_name }}</span> <app-dashboard-icon class="iconBox-20" [size]="20" [name]="'checkOutline'" [iconJson]="commonIcons" [isSvg]="true" [color]="'#239D24'" ></app-dashboard-icon></span>
      </ng-container>
    </div>

    <div class="d-block border-bottom my-2"></div>
    <span class="optionDetailBox" [routerLink]="['/profile/user']" (click)="storeLastUrlbeforeProfile();popUserLogin?.hide()"><app-dashboard-icon class="iconBox-20" [size]="20" [name]="'userOutline'" [iconJson]="commonIcons"></app-dashboard-icon><span class="f-l-r">{{'dashHeader.editUserSettings' | translate}}</span></span>
    <span class="optionDetailBox" (click)="logout()"><app-dashboard-icon class="iconBox-20" [size]="20" [name]="'logoutOutline'" [iconJson]="commonIcons"></app-dashboard-icon><span class="f-l-r"> {{'dashHeader.signOut' | translate}}</span></span>
  </div>
</ng-template>
<!-- notification popover -->
<ng-template #notificationTemplate>
    <app-notifications [unreadNotificationCount]="totalUnreadNotifications" (updateNotificationCount)="updateNotificationCount($event)"></app-notifications>
</ng-template>
<!-- -------- no access in org modal------ -->
<ng-template #noAccesInOrgModel let-modal>
  <div class="modal-body text-center py-3">
    <h3>{{'board.sorry' | translate}}</h3> 
    <img src="../../../assets/1x/info.png"><br><br>
    {{'board.youHaveNoAccess' | translate}} <b>{{selectedOrganization?.organization_name}}</b>.<br><br>
    <button type="button" class="btn btn-danger rounded px-4" (click)="redirectToHome();modal.dismiss()" [disabled]="this.orgData.length==0">{{'common.ok' | translate}}</button>
  </div>
</ng-template>
