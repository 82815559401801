<div class="messageBody" *ngIf="!commentMediaArr?.length">
   <div class="messageView" *ngIf="selectedChatUserMsgarr?.length" #scrollToBottom [scrollTop]="!restoreScrollPosition ? scrollToBottom?.scrollHeight : scrollToRply()" (scroll)="onScroll($event)">
    <ng-container *ngFor="let item of selectedChatUserMsgarr;let msgIndex=index">
        <ng-container *ngIf="copyleadInfoForcomment?.unreadCount>0 && item?.create_date>copyleadInfoForcomment?.lastReadTime">
            <div class="commentBox unreadMsg mt-2" [id]="'unreadmsg_'+selectedLeadObj?._id"><span class="m-1">{{copyleadInfoForcomment?.unreadCount+" "+("comment.unreadComments" | translate)}}</span></div>
          </ng-container>

           <!--Receive-->
       <div class="receiveBox" [id]="item._id" *ngIf="item.type=='EXTERNAL_INBOUND' && item.status!='DELETED'">
        <div *ngIf="item?.chatUserName || item?.profile_pic"><ngx-avatars class="mr-1" [name]="item?.chatUserName" [src]="item?.profile_pic" size="24" initialsSize="2" ></ngx-avatars></div>
        <div class="receiveMsgPanel" *ngIf="!item?.message_data?.attachments">
            <div class="replyIconShow" *ngIf="item?.status != 'FAILED'">
                <span aria-hidden="true" class="iconDropDownButton" role="button" [ngbPopover]="option"
                #optionsPopover="ngbPopover" placement="left" container="body" [autoClose]="'outside'" (click)="enableInfo=false">
                <img src="../../../assets/common-use-icon-20dp/down-icon-black.svg">
                    <ng-template #option>
                        <div *ngIf="!enableInfo">
                        <a class="dropdown-item" (click)="getReplyObj(item);optionsPopover.close()" *ngIf="(selectedChatObj?.channelType=='TELEGRAM'|| selectedChatObj?.channelType=='WHATSAPP') && !item?.message_data?.contact?.length && !item?.message_data?.location"> {{'whatsapp.reply' | translate}}</a>
                        <a class="dropdown-item" (click)="enableInfo=true"> {{'whatsapp.info' | translate}}</a>
                        <a class="dropdown-item" *ngIf="item?.message_data?.text" (click)="copytoClip(item?.message_data?.text);optionsPopover.close()"> {{'channel.copy' | translate}}</a>    
                    </div>
                        <div *ngIf="enableInfo" style="width:200px;">
                            <div class="row m-0" class="infoBox" style="border-bottom: 1px solid #D9D9D9;">
                                <div>
                                <img style="width:15px;height:15px" src="../../../assets/1x/readCheck.svg">
                                <span style="padding:5px;">{{'whatsapp.info' | translate}}</span>
                                </div>
                                <div>
                                </div>
                            </div>  
                            <div class="row m-0" class="infoBox">
                                <div>
                                <span style="padding:5px;">Received Time</span>
                                </div>
                                <div>
                                    <span>{{item?.receivedTime}}</span>
                                </div>
                            </div>        
                        </div>
                    </ng-template>
                 </span>
                </div>
<!--             <div class="replyIconShow" (click)="getReplyObj(item)"><span><app-dashboard-icon [size]="22" [name]="'ReplyWhite'" [iconJson]="lxAttributesIcons"></app-dashboard-icon></span></div>
 -->             <!--Send Repy box-->
             <div class="sendReply" *ngIf="item?.reply_comment_id" (click)="scrollToRply(item?.reply_comment_id)">   
                <span class="receiveReplyChild">
                    <div style="display: flex;">
                        <ngx-avatars class="mr-1" [name]="item?.rplyUserName" [src]="item?.rplyUserProfilePic" size="25" initialsSize="2" ></ngx-avatars>
                        <span>{{item?.rplyUserName}}</span>
                    </div>
                    <span *ngIf="item?.replyMsgObj?.message_data?.text" textAssist [optionFields]="{'chatSuggestions':[]}" [innerHTML]="item?.replyMsgObj?.message_data?.text | linky:{stripPrefix: false, truncate: { length: 32, location: 'smart' }}"></span>
                    <div class="mdaBlock mt-2 mb-1 f-l-r-r" *ngIf="item?.replyMsgObj?.message_data?.attachments?.length">
                        <ng-container *ngFor="let media of item?.replyMsgObj?.message_data?.attachments">
                                <span *ngIf="media.file_type =='IMAGE'" class="rplymediaDiv"><img [src]="media?.file_url">
                                    <span>{{media?.caption}}</span>
                                </span>
                                <span *ngIf="media.file_type =='VIDEO'" class="rplymediaDiv"> <video class="videoFieldModal" controls autoplay>
                                    <source src="media?.file_url" type="video/mp4" />
                                  </video>
                                    <span>{{media?.caption}}</span>
                                </span>
                                <span *ngIf="media.file_type =='AUDIO'" class="rplymediaDiv"><audio style="width:auto;" controls><source [src]="media?.file_url" type="audio/mp3"/></audio>
                                    <span>{{media?.caption}}</span>
                                </span>
                                <span *ngIf="media.file_type !='IMAGE' && media.file_type !='VIDEO' && media.file_type !='AUDIO'" class="rplymediaDiv"><app-dashboard-icon  [name]="(mediaIcons.hasOwnProperty(media?.file_path?.split('.').pop())) ? media?.file_path?.split('.').pop() :'txt'" [iconJson]="mediaIcons" [size]="48"></app-dashboard-icon>
                                    <span>{{media?.caption}}</span>
                                </span>                           
                            </ng-container> 
                    </div> 
                </span>
            </div>
            <div class="receiveContent" *ngIf="item?.message_data?.text">
              <div [innerHTML]="item?.message_data?.text"></div>
             </div>
             <div class="receiveContent" *ngIf="item?.message_data?.contact">
                <!--Contact-->
                <div class="w-100 vCard" *ngIf="item?.message_data?.contact?.length>=1">
                   <div><ngx-avatars class="mr-1" [name]="item?.message_data?.contact[0]?.first_name ? item?.message_data?.contact[0]?.first_name : item?.message_data?.contact[0]?.phone_number[0] " [src]="item?.profile_pic" size="24" initialsSize="2" ></ngx-avatars></div>
                   <div>
                      <div class="w-100">{{item?.message_data?.contact[0]?.first_name}} {{item?.message_data?.contact[0]?.last_name}} <span *ngIf="item?.message_data?.contact?.length>1">and {{item?.message_data?.contact?.length-1}} more</span></div>
                      <ng-container *ngIf="item?.message_data?.contact?.length==1">
                      <div class="w-100" *ngFor="let no of item?.message_data?.contact[0]?.phone_number">{{no}}</div>
                    </ng-container>
                    </div>
                </div>
            </div>
            <div class="receiveContent" *ngIf="item?.message_data?.location">
                <app-google-map [latitude]="item?.message_data?.location?.latitude" [longitude]="item?.message_data?.location?.longitude"></app-google-map>
            </div>
            <div class="w-100 viewContact" *ngIf="item?.message_data?.contact?.length">
                <div [ngbPopover]="contact" placement="auto" container="body" [autoClose]="'outside'">View Contact</div>
            </div>
            <ng-template #contact>
                  <ng-container *ngFor="let user of item?.message_data?.contact">
                    <div class="w-100 vCard">
                        <div><ngx-avatars class="mr-1" [name]="user?.first_name ? user?.first_name : user?.phone_number[0]" size="24" initialsSize="2" ></ngx-avatars></div>
                        <div>
                           <div class="w-100">{{user?.first_name}} {{user?.last_name}}</div>
                           <div class="w-100" *ngFor="let no of user?.phone_number">{{no}}</div>
                         </div>
                     </div>
                  </ng-container>
            </ng-template>
            <div class="receiveTime">{{item?.receivedTime}}</div>
        </div>
            <!--For receive Attachemnet-->
            <div class="receiveMsgPanel" *ngIf="item?.message_data?.attachments?.length">
                <div class="replyIconShow" *ngIf="item?.status != 'FAILED'">
                    <span aria-hidden="true" class="iconDropDownButton" role="button" [ngbPopover]="option"
                    #optionsPopover="ngbPopover" placement="left" container="body" [autoClose]="'outside'" (click)="enableInfo=false">
                    <img src="../../../assets/common-use-icon-20dp/down-icon-black.svg">
                    <ng-template #option>
                    <div *ngIf="!enableInfo">
                     <a class="dropdown-item" (click)="getReplyObj(item);optionsPopover.close()" *ngIf="(selectedChatObj?.channelType=='TELEGRAM' || selectedChatObj?.channelType=='WHATSAPP') && !item?.message_data?.contact && !item?.message_data?.location"> {{'whatsapp.reply' | translate}}</a>
                     <a class="dropdown-item" (click)="enableInfo=true"> {{'whatsapp.info' | translate}}</a>
                     <a class="dropdown-item" *ngIf="item?.message_data?.attachments[0]?.caption" (click)="copytoClip(item?.message_data?.attachments[0]?.caption);optionsPopover.close()"> {{'channel.copy' | translate}}</a>    
                    </div>
                    <div *ngIf="enableInfo" style="width:200px;">
                        <div class="row m-0" class="infoBox" style="border-bottom: 1px solid #D9D9D9;">
                            <div>
                            <img style="width:15px;height:15px" src="../../../assets/1x/readCheck.svg">
                            <span style="padding:5px;">{{'whatsapp.info' | translate}}</span>
                            </div>
                            <div>
                            </div>
                         </div>  
                      <div class="row m-0" class="infoBox">
                        <div>
                        <span style="padding:5px;">Received Time</span>
                        </div>
                        <div>
                            <span>{{item?.receivedTime}}</span>
                        </div>
                     </div>        
                    </div>
                    </ng-template>
                     </span></div>
                     <div class="sendReply" *ngIf="item?.reply_comment_id" (click)="scrollToRply(item?.reply_comment_id)">   
                        <span class="receiveReplyChild">
                            <div style="display: flex;">
                                <ngx-avatars class="mr-1" [name]="item?.rplyUserName" [src]="item?.rplyUserProfilePic" size="25" initialsSize="2" ></ngx-avatars>
                                <span>{{item?.rplyUserName}}</span>
                            </div>
                            <span *ngIf="item?.replyMsgObj?.message_data?.text" textAssist [optionFields]="{'chatSuggestions':[]}" [innerHTML]="item?.replyMsgObj?.message_data?.text | linky:{stripPrefix: false, truncate: { length: 32, location: 'smart' }}"></span>
                            <div class="mdaBlock mt-2 mb-1 f-l-r-r" *ngIf="item?.replyMsgObj?.message_data?.attachments?.length">
                                <ng-container *ngFor="let media of item?.replyMsgObj?.message_data?.attachments">
                                        <span *ngIf="media.file_type =='IMAGE'" class="rplymediaDiv"><img [src]="media?.file_url">
                                            <span>{{media?.caption}}</span>
                                        </span>
                                        <span *ngIf="media.file_type =='VIDEO'" class="rplymediaDiv"> <video class="videoFieldModal" controls autoplay>
                                            <source src="media?.file_url" type="video/mp4" />
                                          </video>
                                            <span>{{media?.caption}}</span>
                                        </span>
                                        <span *ngIf="media.file_type =='AUDIO'" class="rplymediaDiv"><audio style="width:auto;" controls><source [src]="media?.file_url" type="audio/mp3"/></audio>
                                            <span>{{media?.caption}}</span>
                                        </span>
                                        <span *ngIf="media.file_type !='IMAGE' && media.file_type !='VIDEO' && media.file_type !='AUDIO'" class="rplymediaDiv"><app-dashboard-icon  [name]="(mediaIcons.hasOwnProperty(media?.file_path?.split('.').pop())) ? media?.file_path?.split('.').pop() :'txt'" [iconJson]="mediaIcons" [size]="48"></app-dashboard-icon>
                                            <span>{{media?.caption}}</span>
                                        </span>                           
                                    </ng-container> 
                            </div> 
                        </span>
                    </div>
<!--                 <div class="replyIconShow" (click)="getReplyObj(item)"><span><app-dashboard-icon [size]="22" [name]="'ReplyWhite'" [iconJson]="lxAttributesIcons"></app-dashboard-icon></span></div>
 -->                <ng-container *ngFor="let media of item?.message_data?.attachments">
                    <div class="mediaDiv" *ngIf="media?.file_type=='IMAGE'"><img  style="object-fit: contain;overflow-clip-margin: content-box;overflow: clip;" [src]="media?.file_url">
                        <span  [innerHTML]="media?.caption"></span>
                        <div class="middle">
                             <button type="button"  class="btn image-options-for-singleimage" (click)="open(previewModal,media,item._id)">
                                <img src="../../../../assets/1x/doc-view.png" />
                              </button> 
                        </div> 
                    </div>
                    <div class="mediaDiv" *ngIf="media?.file_type=='VIDEO'">
                        <video class="videoFieldModal" controls autoplay>
                            <source src="media?.file_url" type="video/mp4" />
                          </video>
                        <span [innerHTML]="media?.caption"></span>
                        <div class="middle">
                            <button type="button"  class="btn image-options-for-singleimage" (click)="open(previewModal,media,item._id)">
                               <img src="../../../../assets/1x/doc-view.png" />
                             </button> 
                       </div> 
                    </div>
                    <div class="mediaDiv" *ngIf="media?.file_type!='VIDEO' && media?.file_type!='IMAGE' && media?.file_type!='AUDIO'">
                        <app-dashboard-icon [name]="(mediaIcons.hasOwnProperty(media?.file_name?.split('.').pop())) ? media?.file_name?.split('.').pop() :'txt'" [iconJson]="mediaIcons" size="95"></app-dashboard-icon>
                        <span [innerHTML]="media?.caption"></span>
                        <div class="middle">
                            <button type="button"  class="btn image-options-for-singleimage" (click)="open(previewModal,media,item._id)">
                               <img src="../../../../assets/1x/doc-view.png" />
                             </button> 
                       </div> 
                    </div>
                    <div class="mediaDiv" *ngIf="media.file_type=='AUDIO'">
                        <audio style="width:auto;" controls><source [src]="media?.file_url" type="audio/mp3"/></audio>
                        <span [innerHTML]="media?.caption"></span>
                    </div>
                    </ng-container>
                <div class="receiveTime">{{item?.receivedTime}}</div>
            </div>
       </div>

       <!--DELETED RECEIVE MSG-->
       <div class="receiveBox" [id]="item._id" *ngIf="item.type=='EXTERNAL_INBOUND' && item.status=='DELETED'">
        <div><ngx-avatars class="mr-1" [name]="item?.chatUserName" [src]="item?.profile_pic" size="24" initialsSize="2" ></ngx-avatars></div>
        <div class="receiveMsgPanel">
            <div class="receiveContent">
              <div><span class="deletedMsg"><app-dashboard-icon  [size]="22" [name]="'Ban'" [iconJson]="lxAttributesIcons"></app-dashboard-icon>This message was deleted</span></div>
             </div>
            <div class="receiveTime">{{item?.deletedTime}}</div>
        </div>
       </div>
            <!--Send-->
        <div class="sendBox" [id]="item._id" *ngIf="item.type=='EXTERNAL_OUTBOUND'">
            <div *ngIf="item?.status == 'FAILED' "><span><app-dashboard-icon  [size]="22" [name]="'Alert'" [iconJson]="lxAttributesIcons"></app-dashboard-icon></span></div>
         <div class="sendMsgPanel" *ngIf="!item?.message_data?.attachments">
            <div class="replyIconShow" *ngIf="item?.status != 'FAILED'"><span aria-hidden="true" class=" iconDropDownButton" role="button" [ngbPopover]="option"
            #optionsPopover="ngbPopover" placement="left" container="body" [autoClose]="'outside'" (click)="enableInfo=false">
            <img src="../../../assets/common-use-icon-20dp/down-icon-black.svg">
            <ng-template #option>
            <div *ngIf="!enableInfo">
             <a class="dropdown-item" (click)="getReplyObj(item);optionsPopover.close()" *ngIf="selectedChatObj?.channelType=='TELEGRAM' || selectedChatObj?.channelType=='WHATSAPP'"> {{'whatsapp.reply' | translate}}</a>
             <a class="dropdown-item" (click)="enableInfo=true" *ngIf="selectedChatObj?.channelType!='TELEGRAM' && selectedChatObj?.channelType!='INSTAGRAM' && selectedChatObj?.channelType!='LINE'"> {{'whatsapp.info' | translate}}</a>
             <a class="dropdown-item" *ngIf="item?.message_data?.text" (click)="copytoClip(item?.message_data?.text);optionsPopover.close()"> {{'channel.copy' | translate}}</a>    
            </div>
            <div *ngIf="enableInfo" style="width:200px;">
                <div class="row m-0" class="infoBox" style="border-bottom: 1px solid #D9D9D9;">
                    <div>
                    <img style="width:15px;height:15px" src="../../../assets/1x/readCheck.svg">
                    <span style="padding:5px;">{{'whatsapp.info' | translate}}</span>
                    </div>
                    <div>
                    </div>
                 </div>  
             <div class="row m-0" class="infoBox">
                <div>
                <img style="width:15px;height:15px" src="../../../assets/1x/readCheck.svg">
                <span style="padding:5px;">{{'whatsapp.read' | translate}}</span>
                </div>
                <div>
                    <span>{{item?.readTime}}</span>
                </div>
             </div> 
             <div class="row m-0" class="infoBox" style="border-bottom: none;">
                <div>
                <img style="width:15px;height:15px" src="../../../assets/1x/delivercheck.svg">
                <span style="padding:5px;">{{'whatsapp.delivered' | translate}}</span>
               </div>
               <div>
                <span>{{item?.deliverTime}}</span>
            </div>

             </div>            
            </div>
            </ng-template>
             </span></div>
<!--             <div class="replyIconShow" (click)="getReplyObj(item)"><span><app-dashboard-icon  [size]="22" [name]="'Reply'" [iconJson]="lxAttributesIcons" [color]="'#1E1F21'"></app-dashboard-icon></span></div>
 -->            <!--Send Repy box-->
            <div class="sendReply" *ngIf="item?.reply_comment_id" (click)="scrollToRply(item?.reply_comment_id)">   
                <span class="sendReplyChild">
                    <div style="display: flex;">
                        <ngx-avatars class="mr-1" [name]="item?.rplyUserName" [src]="item?.rplyUserProfilePic" size="25" initialsSize="2" ></ngx-avatars>
                        <span>{{item?.rplyUserName}}</span>
                    </div>
                    <span *ngIf="item?.replyMsgObj?.message_data?.text" textAssist [optionFields]="{'chatSuggestions':[]}" [innerHTML]="item?.replyMsgObj?.message_data?.text | linky:{stripPrefix: false, truncate: { length: 32, location: 'smart' }}"></span>
                    <div class="mdaBlock mt-2 mb-1 f-l-r-r" *ngIf="item?.replyMsgObj?.message_data?.attachments?.length">
                        <ng-container *ngFor="let media of item?.replyMsgObj?.message_data?.attachments">
                                <span *ngIf="media.file_type =='IMAGE'" class="rplymediaDiv"><img [src]="media?.file_url">
                                    <span>{{media?.caption}}</span>
                                </span>
                                <span *ngIf="media.file_type =='VIDEO'" class="rplymediaDiv">
                                    <video class="videoFieldModal" controls autoplay>
                                        <source src="media?.file_url" type="video/mp4" />
                                      </video>
                                    <span>{{media?.caption}}</span>
                                </span>
                                <span *ngIf="media.file_type =='AUDIO'" class="rplymediaDiv"><audio style="width:auto;" controls><source [src]="media?.file_url" type="audio/mp3"/></audio>
                                    <span>{{media?.caption}}</span>
                                </span>
                                <span *ngIf="media.file_type !='IMAGE' && media.file_type !='VIDEO' && media.file_type !='AUDIO'" class="rplymediaDiv"><app-dashboard-icon  [name]="(mediaIcons.hasOwnProperty(media?.file_path?.split('.').pop())) ? media?.file_path?.split('.').pop() :'txt'" [iconJson]="mediaIcons" [size]="48"></app-dashboard-icon>
                                    <span>{{media?.caption}}</span>
                                </span>                           
                            </ng-container> 
                     </div> 
                </span>
                </div>
            <div class="sendContent" *ngIf="item?.message_data?.text">
               <div [innerHTML]="item?.message_data?.text"></div>
            </div>
            <!--BUTTONS-->
            <div *ngIf="item?.message_data?.buttons?.length" class="botBtnGroup">
                <div class="buttonContainer" *ngFor="let button of item?.message_data?.buttons">
                     <span style="height:calc(100% - 32px)" *ngIf="button?.image"><img class="btnImage" [src]="button?.image?.file_url"></span>
                     <button class="botButton" disabled="true">{{button?.text}}</button>
                </div>
            </div>

            <!--CHECKBOX-->
             <div *ngIf="item?.message_data?.checkboxes?.length" class="botBtnGroup">
                <div class="buttonContainer" *ngFor="let checkbox of item?.message_data?.checkboxes">
                     <span style="height:calc(100% - 32px)" *ngIf="checkbox?.image"><img class="btnImage" [src]="checkbox?.image?.file_url"></span>
                     <span class="botCheckbox"><input type ="checkbox" disabled="true"/>{{checkbox?.text}}</span>
                </div>
            </div> 

            <div class="sendTime">{{item?.sentTime}}
                <span style="width:auto;" *ngIf="item.status=='SENT'"><app-dashboard-icon  [size]="10" [name]="'CheckMarkForSent'" [iconJson]="lxAttributesIcons"></app-dashboard-icon></span>
                <span style="width:auto;" *ngIf="item.status=='DELIVERED'"><app-dashboard-icon [size]="10" [name]="'CheckMarkForDeliver'" [iconJson]="lxAttributesIcons"></app-dashboard-icon></span>
                <span style="width:auto;" *ngIf="item.status=='READ'"><app-dashboard-icon  [size]="10" [name]="'CheckMarkForRead'" [iconJson]="lxAttributesIcons"></app-dashboard-icon></span>
            </div>
         </div>
         <!--For send Attachemnet-->
            <div class="sendMsgPanel" *ngIf="item?.message_data?.attachments?.length">
                <div class="replyIconShow" *ngIf="item?.status != 'FAILED'">
                    <span aria-hidden="true" class="iconDropDownButton" role="button" [ngbPopover]="option"
                    #optionsPopover="ngbPopover" placement="left" container="body" [autoClose]="'outside'" (click)="enableInfo=false">
                    <img src="../../../assets/common-use-icon-20dp/down-icon-black.svg">
                        <ng-template #option>
                        <div *ngIf="!enableInfo">
                        <a class="dropdown-item" (click)="getReplyObj(item);optionsPopover.close()" *ngIf="selectedChatObj?.channelType=='TELEGRAM' || selectedChatObj?.channelType=='WHATSAPP'"> {{'whatsapp.reply' | translate}}</a>
                        <a class="dropdown-item" (click)="enableInfo=true" *ngIf="selectedChatObj?.channelType!='TELEGRAM' && selectedChatObj?.channelType!='INSTAGRAM' && selectedChatObj?.channelType!='LINE'"> {{'whatsapp.info' | translate}}</a>
                        <a class="dropdown-item" *ngIf="item?.message_data?.attachments[0]?.caption" (click)="copytoClip(item?.message_data?.attachments[0]?.caption);optionsPopover.close()"> {{'channel.copy' | translate}}</a>       
                    </div>
                        <div *ngIf="enableInfo" style="width:200px;">
                            <div class="row m-0" class="infoBox" style="border-bottom: 1px solid #D9D9D9;">
                                <div>
                                <img style="width:15px;height:15px" src="../../../assets/1x/readCheck.svg">
                                <span style="padding:5px;">{{'whatsapp.info' | translate}}</span>
                                </div>
                                <div>
                                </div>
                            </div>    
                        <div class="row m-0" class="infoBox">
                            <div>
                            <img style="width:15px;height:15px" src="../../../assets/1x/readCheck.svg">
                            <span style="padding:5px;">{{'whatsapp.read' | translate}}</span>
                            </div>
                            <div>
                                <span>{{item?.readTime}}</span>
                            </div>
                        </div> 
                        <div class="row m-0" class="infoBox" style="border-bottom: none;">
                            <div>
                            <img style="width:15px;height:15px" src="../../../assets/1x/delivercheck.svg">
                            <span style="padding:5px;">{{'whatsapp.delivered' | translate}}</span>
                        </div>
                        <div>
                            <span>{{item?.deliverTime}}</span>
                        </div>
            
                        </div>            
                        </div>
                        </ng-template>
                     </span>
                </div>
                <div class="sendReply" *ngIf="item?.reply_comment_id" (click)="scrollToRply(item?.reply_comment_id)">   
                    <span class="sendReplyChild">
                        <div style="display: flex;">
                            <ngx-avatars class="mr-1" [name]="item?.rplyUserName" [src]="item?.rplyUserProfilePic" size="25" initialsSize="2" ></ngx-avatars>
                            <span>{{item?.rplyUserName}}</span>
                        </div>
                        <span *ngIf="item?.replyMsgObj?.message_data?.text" [innerHTML]="item?.replyMsgObj?.message_data?.text | linky:{stripPrefix: false, truncate: { length: 32, location: 'smart' }}"></span>
                        <div class="mdaBlock mt-2 mb-1 f-l-r-r" *ngIf="item?.replyMsgObj?.message_data?.attachments?.length">
                            <ng-container *ngFor="let media of item?.replyMsgObj?.message_data?.attachments">
                                    <span *ngIf="media.file_type =='IMAGE'" class="rplymediaDiv"><img [src]="media?.file_url">
                                        <span>{{media?.caption}}</span>
                                    </span>
                                    <span *ngIf="media.file_type =='VIDEO'" class="rplymediaDiv">
                                        <video class="videoFieldModal" controls autoplay>
                                            <source src="media?.file_url" type="video/mp4" />
                                          </video>
                                        <span>{{media?.caption}}</span>
                                    </span>
                                    <span *ngIf="media.file_type =='AUDIO'" class="rplymediaDiv"><audio style="width:auto;" controls><source [src]="media?.file_url" type="audio/mp3"/></audio>
                                        <span>{{media?.caption}}</span>
                                    </span>
                                    <span *ngIf="media.file_type !='IMAGE' && media.file_type !='VIDEO' && media.file_type !='AUDIO'" class="rplymediaDiv"><app-dashboard-icon  [name]="(mediaIcons.hasOwnProperty(media?.file_path?.split('.').pop())) ? media?.file_path?.split('.').pop() :'txt'" [iconJson]="mediaIcons" [size]="48"></app-dashboard-icon>
                                        <span>{{media?.caption}}</span>
                                    </span>                           
                                </ng-container> 
                         </div> 
                    </span>
                    </div>
               <ng-container *ngFor="let media of item?.message_data?.attachments">
                        <div class="mediaDiv" *ngIf="media?.file_type=='IMAGE'"><img  style="width:auto;max-height:320px;object-fit: contain;" [src]="media?.file_url ? media?.file_url : media?.url">
                            <span class="fileNameS" [innerHTML]="media?.caption"></span>
                            <div class="middle" *ngIf="!item?.temporary_id">
                                <button  type="button"  class="btn image-options-for-singleimage" (click)="open(previewModal,media,item._id)">
                                <img src="../../../../assets/1x/doc-view.png" />
                                </button> 
                        </div> 
                            <div *ngIf="item?.temporary_id && item?.status!='FAILED'" class="spinner-border text-light tempLoader" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <div class="mediaDiv" *ngIf="media?.file_type=='VIDEO'"><video style="width:100px;height:100px;" [src]="media?.file_url ? media?.file_url : media?.url"></video>
                            <span class="fileNameS" [innerHTML]="media?.caption"></span>
                            <div class="middle"  *ngIf="!item?.temporary_id">
                                <button type="button"  class="btn image-options-for-singleimage" (click)="open(previewModal,media,item._id)">
                                <img src="../../../../assets/1x/doc-view.png" />
                                </button> 
                            </div>
                        <div *ngIf="item?.temporary_id && item?.status!='FAILED'" class="spinner-border text-light tempLoader" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        </div>
                        <div class="mediaDiv" *ngIf="media?.file_type!='VIDEO' && media?.file_type!='IMAGE' && media?.file_type!='AUDIO'">
                            <app-dashboard-icon [name]="(mediaIcons.hasOwnProperty(media?.file_name?.split('.').pop())) ? media?.file_name?.split('.').pop() :'txt'" [iconJson]="mediaIcons" size="95"></app-dashboard-icon>
                            <span class="fileNameS" [innerHTML]="media?.caption"></span>
                            <div class="middle"  *ngIf="!item?.temporary_id">
                                <button type="button"  class="btn image-options-for-singleimage" (click)="open(previewModal,media,item._id)">
                                <img src="../../../../assets/1x/doc-view.png" />
                                </button> 
                        </div> 
                        <div *ngIf="item?.temporary_id && item?.status!='FAILED'" class="spinner-border text-light tempLoader" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        </div>
                        <div class="mediaDiv" *ngIf="media.file_type=='AUDIO'">
                            <audio style="width:auto;" controls><source [src]="media?.file_url ? media?.file_url : media?.url" type="audio/mp3"/></audio>
                            <span class="fileNameS" [innerHTML]="media?.caption"></span>
                        </div>
                        <div *ngIf="item?.temporary_id && item?.status!='FAILED'" class="spinner-border text-light tempLoader" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                </ng-container>
                        <div class="sendTime">{{item?.sentTime}}
                            <span style="width:auto;" *ngIf="item.status=='SENT'"><app-dashboard-icon  [size]="10" [name]="'CheckMarkForSent'" [iconJson]="lxAttributesIcons"></app-dashboard-icon></span>
                            <span style="width:auto;" *ngIf="item.status=='DELIVERED'"><app-dashboard-icon  [size]="10" [name]="'CheckMarkForDeliver'" [iconJson]="lxAttributesIcons"></app-dashboard-icon></span>
                            <span style="width:auto;" *ngIf="item.status=='READ'"><app-dashboard-icon  [size]="10" [name]="'CheckMarkForRead'" [iconJson]="lxAttributesIcons"></app-dashboard-icon></span>
                        </div>
                        </div>
                    <div *ngIf="item?.created_by && orgUsersJson"><ngx-avatars class="mr-1" [name]="orgUsersJson[item?.created_by]?.name || 'Assign'" [src]="orgUsersJson[item?.created_by]?.image_url" size="24" initialsSize="2" triggers="hover" placement="auto" container="body" 
                        [ngbPopover]="groupInfoPopover"></ngx-avatars></div>
                    <ng-template #groupInfoPopover>
                        <ng-container  *ngIf="orgUsersJson[item?.created_by]">
                                <app-user-info-card [user]="orgUsersJson[item?.created_by]"></app-user-info-card>
                        </ng-container>
                     </ng-template>
        </div>
        <ng-container *ngIf="selectedChatUserMsgarr[msgIndex+1]?.message_group_id && item?.message_group_id!==selectedChatUserMsgarr[msgIndex+1]?.message_group_id && msgIndex+1<selectedChatUserMsgarr?.length">
            <div class="commentBox unreadMsg mt-2"><span class="m-1">close Conversation</span></div>
        </ng-container>

        <!--Internal-->
        <div class="commentBox p-2 mt-2" [id]="item._id" *ngIf="item.type == 'INTERNAL'">
         <!--     reply comment -->
           <!--- <div class="receiveReply" *ngIf="item?.reply_comment_id" (click)="scrollToRply(item?.reply_comment_id)">   
              <span class="receiveReplyChild">
                  <div class="leftBox">
                      <ngx-avatars class="mr-1" [name]="item?.reply_comment?.userInfo.name" size="25" initialsSize="2" ></ngx-avatars>
                  <span>{{item?.reply_comment?.userInfo.name}}</span>
                  </div>
                  <span *ngIf="item?.reply_comment?.comment" [innerHTML]="item?.reply_comment?.comment | linky:{stripPrefix: false, truncate: { length: 32, location: 'smart' }}"></span>
                  <span *ngIf="!item?.reply_comment?.comment && !item?.reply_comment?.attachments?.length">This message is present on another item</span>
                  <div class="mdaBlock mt-2 mb-1 f-l-r-r" *ngIf="item?.reply_comment?.attachments?.length">
                      <ng-container *ngFor="let media of item?.reply_comment?.attachments">
                              <span *ngIf="media.file_type =='IMAGE'" class="mediaDiv" style="height:40px;width:40px;"><img style="width:40px;height:40px;object-fit: contain;" [src]="media?.file_url">
                                  <span class="fileNameU" 
                                  style="position:absolute; left: 0; bottom: -22px; max-width: 98px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                                  {{media?.file_name}}</span>
                              </span>
                              <span *ngIf="media.file_type =='VIDEO'" class="mediaDiv" style="height:40px;width:40px;"><video style="width:40px;height:40px;" [src]="media?.file_url"></video>
                                  <span class="fileNameU" 
                                  style="position:absolute; left: 0; bottom: -22px; max-width: 98px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                                  {{media?.file_name}}</span>
                              </span>
                              <span *ngIf="media.file_type =='FILE'|| media.file_type =='DOCUMENT'" class="mediaDiv" style="height:50px;width:50px;"><app-dashboard-icon  [name]="(mediaIcons.hasOwnProperty(media?.file_path?.split('.').pop())) ? media?.file_path?.split('.').pop() :'txt'" [iconJson]="mediaIcons" [size]="48"></app-dashboard-icon>
                                  <span class="fileNameU" 
                                  style="position:absolute; left: 0; bottom: -22px; max-width: 98px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                                  {{media?.file_name}}</span>
                              </span>                           
                          </ng-container> 
                      </div> 
              </span>   
          </div> -->
      
              <div class="comntli w-100">
                <div class="userInfoBox f-l-b">
                  <ngx-avatars class="mr-1" [name]="orgUsersJson[item?.created_by]?.name" [src]="orgUsersJson[item?.created_by]?.image_url" size="25" initialsSize="2" ></ngx-avatars>
                  <span>{{orgUsersJson[item?.created_by]?.name}}</span>
                </div>
                <div class="rightbox">
                  <small class="cmttime">{{item?.createdTime}}</small>
                  <img *ngIf="loggedInUserId === item.created_by && !internalChatbot" class="options" src="assets/1x/vertdots.svg" [ngbPopover]="!isReadOnly && optionPopover"
                  #optionPop="ngbPopover" placement="auto"
                 [autoClose]="'outside'">
                </div>
               <ng-template #optionPopover>
                  <div>
                   <!-- <a class="dropdown-item" (click)="replyComment(item)">Reply</a> -->
                   <a class="dropdown-item" [disabled]="item.create_date<86400000" (click)="deleteComment(item)">Delete</a>
                  </div>
               </ng-template>
              </div>
              <div class="comntlicnt f-l-r-r"  textAssist [optionFields]="{'chatSuggestions':[]}"  [innerHTML]="(item?.commentText | linky:{stripPrefix: false, truncate: { length: 32, location: 'smart' }}) | safehtml">
                {{getMentionColor()}}
              </div>
              <div class="mdaBlock f-l-r-r">
                <ng-container *ngFor="let data of item?.message_data?.attachments;let i=index">
                        <div class="show-image-in-multiple-image show-image" *ngIf="data.file_type=='IMAGE'" 
                        [tooltip]="data?.file_name?data?.file_name : ''" placement="top" container="body" [containerClass]="'tooltipPopup'">
                          <img src="{{data.file_url}}" alt="">
                          <div class="middle">  
                            <span class="image-options-for-singleImage" (click)="open(previewModal,data,item._id)">
                                  <img src="../../../../assets/1x/view-btn.png" class="image-icon-for-eyeIcon-single"/>
                                </span>
                          </div>
                            <span class="fileName" 
                              style="position:absolute; left: 0; bottom: -22px; max-width: 98px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                              {{data?.file_name?.replace('.'+data?.file_path?.split('.')?.pop(),'.') | truncate:6 }}{{data?.file_path?.split('.')?.pop()}}
                            </span>
                            <!-- <span class="fullfileName mt-1">
                              {{data?.file_name}}
                            </span> -->
                        </div>
                        <div class="show-image-in-multiple-image" *ngIf="data.file_type=='VIDEO'" 
                        [tooltip]="data?.file_name?data?.file_name : ''" placement="top" container="body" [containerClass]="'tooltipPopup'">
                            <video controls>
                              <source type="video/mp4" src="{{data?.file_url}}">
                            </video>
                            <div class="middle">  
                                <span class="image-options-for-singleImage" (click)="open(previewModal,data,item._id)">
                                      <img src="../../../../assets/1x/view-btn.png" class="image-icon-for-eyeIcon-single"/>
                                    </span>
                              </div>
                          <span class="fileName" 
                            style="position:absolute; left: 0; bottom: -22px; max-width: 98px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                            {{data?.file_name?.replace('.'+data?.file_path?.split('.')?.pop(),'.') | truncate:6 }}{{data?.file_path?.split('.')?.pop()}}
                            </span>
                            <!-- <span class="fullfileName mt-1">
                                {{data?.file_name}}
                              </span> -->
                        </div>
                        <!-- <span>{{data | json}}</span> -->
                        <div class="" *ngIf="data.file_type!='IMAGE' && data.file_type!='VIDEO' && data.file_type!='AUDIO'" 
                        [tooltip]="data?.file_name?data?.file_name : ''" placement="top" container="body" [containerClass]="'tooltipPopup'">
                          <ng-container >
                            <div class="show-image-in-multiple-image">
                                <span class=""><app-dashboard-icon [name]="(mediaIcons.hasOwnProperty(data?.file_path?.split('.')?.pop())) ? data?.file_path?.split('.')?.pop() :'txt'" [iconJson]="mediaIcons" size="95"></app-dashboard-icon></span>
                                <div class="middle">  
                                    <span class="image-options-for-singleImage" (click)="open(previewModal,data,item._id)">
                                          <img src="../../../../assets/1x/view-btn.png" class="image-icon-for-eyeIcon-single"/>
                                        </span>
                                  </div>
                                <span class="fileName" 
                                style="position:absolute; left: 0; bottom: -22px; max-width: 98px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                                <!-- {{data?.file_name}} -->
                                {{data?.file_name?.replace('.'+data?.file_path?.split('.')?.pop(),'.') | truncate:6 }}{{data?.file_path?.split('.')?.pop()}}
                                </span>
                                <!-- <span class="fullfileName mt-1">
                                    {{data?.file_name}}
                                  </span> -->
                             </div>
                          </ng-container>
                        </div>

                        <div class="show-image-in-multiple-image" style="min-width:100px;" *ngIf="data.file_type=='AUDIO'"
                        [tooltip]="data?.file_name?data?.file_name : ''" placement="top" container="body" [containerClass]="'tooltipPopup'">
                            <audio controls><source src="{{data?.file_url}}" type="audio/mp3"/></audio>
                         <!--    <div class="middle">  
                                <span class="image-options-for-singleImage" (click)="open(previewModal,data,item._id)">
                                      <img src="../../../../assets/1x/view-btn.png" class="image-icon-for-eyeIcon-single"/>
                                    </span>
                              </div> -->
                            <span class="fileName" 
                            style="position:absolute; left: 0; bottom: -22px; max-width: 98px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                            {{data?.file_name?.replace('.'+data?.file_path?.split('.')?.pop(),'.') | truncate:6 }}{{data?.file_path?.split('.')?.pop()}}
                            </span>
                            <!-- <span class="fullfileName mt-1">
                                {{data?.file_name}}
                              </span> -->
                        </div>
                    
                </ng-container>
              </div>
        </div>
    </ng-container>
   </div>
   <div style="height: 0px;position:relative">
        <span style="position: absolute;right: 2%;bottom: 100%;">
            <span class="scrollDropDown" *ngIf="showScrollToBottom" (click)="scrollToBottomOfElement()">
                <i class="fa fa-chevron-down fa-lg iconDropDownButton" aria-hidden="true"></i>
            </span>
        </span>
   </div>
   <div class="w-100 d-flex align-items-center justify-content-center" style="height: 24px;" *ngIf="internalChatbot && chatBotLoader">
       <div class="d-flex align-items-center chatBotLoader" style="gap:8px;"><span>ChatBot is typing..</span><span class="dot-flashing"></span></div>
   </div>
   <div class="messageBottomView" *ngIf="!selectedChatObj?.closed_by">
    <app-content-editable-input-note style="width: 100%; 
    display: flex; flex-direction: column;" [internalChatbot]="internalChatbot" [internalChat]="false" [orgUsersJson]="orgUserArr" [disableEditor]="disableEditor" [showAttachmentIcon]="true" [dashId]="dashId" [commentChat]="true" [commentMediaArr]="commentMediaArr" [selectedChatObj]="selectedChatObj" [currentActiveTab]="currentActiveTab"  [disableAttachColumns]="disableAttachColumns"
    [selectedLeadObj]="selectedLeadObj" [selectedChatUserMsgarr]="selectedChatUserMsgarr" [rplyObj]="rplyObj" (sendChat)="sendChatMsg($event)" (mediaColumns)="mediaColumns($event)" [rplyEnable]="rplyEnable" (rplyDisable)="rplyEnable=false;rplyObj={}" (getTemplateData)="getTemplateMedia($event)"></app-content-editable-input-note>
   </div>
</div>

<!--Media Preview-->
<div class="messageBody" style="background: rgba(234, 241, 253, 0.5019607843) 0% 0% no-repeat padding-box;" *ngIf="commentMediaArr?.length">
    <div class="previewBody">
        <div style="height:100%;"><app-image-editor [dashId]="dashId" [rplyObj]="rplyObj" [isChatView]="true" [imageFiles]="commentMediaArr" [fileType]="'MultipleImage'" (closeModal)="commentMediaArr=[];uploadMediaArr=[];attachmentArr=[]" [currentActiveTab]="currentActiveTab" (editImage)="onUploadFile($event,editImageFiles?.type,editImageFiles?.key,false,editImageFiles?.filePreviouspath)" (sendChatAttachment)="sendWithattachement($event)" [selectedLeadObj]="selectedLeadObj"></app-image-editor></div>
    </div>
</div>

<ng-template #previewModal let-modal>
    <div class="previewBodyModal" style="height: 100%;max-width: 100%;">
      <i class="fa fa-close bg-white previewCross" (click)="modal.dismiss()"></i>
      <app-file-preview [mediaArr]="mediaArr" [index]="previewIndex" [type]="'COMMENT'" [dashId]="dashId" [typeId]="commentId"></app-file-preview>
    </div>
  </ng-template>
