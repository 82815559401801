import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef } from "@angular/core";
import { NgbPopover } from "@ng-bootstrap/ng-bootstrap";
import { CommonUtils } from "app/services/CommonUtils.service";
import { DashboardUtilsService } from "app/services/dashboard-utils.service";
import { array } from "lib0";

@Component({
  selector: 'app-formula-calculation',
  templateUrl: './formula-calculation.component.html',
  styleUrls: ['./formula-calculation.component.scss']
})
export class FormulaCalculationComponent implements OnInit {
  @Output() formulaEmit  : EventEmitter<any> = new EventEmitter();
  @Output() closeEmit  : EventEmitter<any> = new EventEmitter();
  @ViewChild('dynamicFormulaEditor', { read: ViewContainerRef }) dynamicFormulaEditor: ViewContainerRef;
  @ViewChild('myFormulaTemplate', { static: true }) myFormulaTemplate: TemplateRef<any>;
  @ViewChild('popformulaParams') public popformulaParams: NgbPopover;
  @ViewChild('popRemove') public popRemove: NgbPopover;
  @ViewChild('popRemoveCol') public popRemoveCol: NgbPopover;
  @ViewChild('popFunction') public popFunction: NgbPopover;
  @ViewChild('popupParamFunc') public popupParamFunc: NgbPopover;
  @ViewChild('popupParamFuncArr') public popupParamFuncArr: NgbPopover;
  @ViewChild('popParamOption') public popParamOption: NgbPopover;
  @ViewChild('popColumns') public popColumns: NgbPopover;
  @ViewChild('popColumnFunc') public popColumnFunc: NgbPopover;
  @ViewChild('editableDiv') editableDiv: ElementRef;
  @ViewChild('popParameterParam') public popParameterParam: NgbPopover;
  @ViewChild('formulaViewPort') formulaViewPort: ElementRef;
  @ViewChild('inputField') inputField: ElementRef;
  @Input() dashId: any;
  @Input() connected_board_id: any[] = [];
  @Input() selectedFormula:any={}
  @Input() automationCall:boolean=false;
  @Input() isTriggeredNonItemField:boolean=false;
 @Input() item:any={};
 @Input() customFieldArr:any;
 @Input() customfieldType:any;
 @Input() catchHookReqJson:any;
 @Input() catchHookFieldArr: any=[];  
 @Input() templateVariableArr: any[] = [];
 @Input() appSampleReqJson: any;
 @Input() triggerType : any;
 @Input() selectedFunc:any;
 @Input() showFormulPreview : boolean = false;
 @Input() dashboardVariable:boolean=false;
 @Input() dashVariableArr:any;
  formulaObj: any[] = [];
  numberCustomField: any[] = [];
  count: number = 1;
  storedCursorPosition: Range;
  previousIndexID: string;
  isValid:boolean=false;
  copyOfFormula:any;
  @Input() hideValidBar: boolean=false;
  booleanArr:any[]=[{display_key:"True",key:true},{display_key:"False",key:false}]
  dateUnitArr:any[]=[
    {display_key:"Seconds",key:"seconds"},
    {display_key:"Minutes",key:"minutes"},
    {display_key:"Hours",key:"hours"},
    {display_key:"Days",key:"days"},
    {display_key:"Months",key:"months"},
    {display_key:"Years",key:"years"},
  ]
  paramIndex: any;
  cursorAtStart: boolean=false;
  @Input() numberTypeGraph: boolean=false;
    constructor(public commonutils: CommonUtils, public dashboardUtils: DashboardUtilsService) { }

  async ngOnInit() {
    console.log(this.selectedFunc)
    if(this.selectedFunc){
      this.modifyJsonForEditor()
      this.hideValidBar = true;
    }
    if (this.connected_board_id?.length) {
      this.connected_board_id.forEach(async c => {
        let dashboardJson = await this.dashboardUtils.getDashboardCustomForms(c)
        let arrOfCustomField = JSON.parse(JSON.stringify(dashboardJson[c]['CUSTOM_FORM']));
        let numberCustomField = arrOfCustomField.filter(m => m?.type == 'Numeric' || m?.is_numeric === true) || []
        numberCustomField = numberCustomField.map(field => ({ ...field, dashboard_id: c }));
        numberCustomField = numberCustomField.map(field => ({ ...field, dashboard_name: dashboardJson[c]['DASHBOARD_INFO']?.dashboard_name }));
        this.numberCustomField = [...this.numberCustomField, ...numberCustomField]
        if(this.selectedFormula?.expression?.length){
          console.log(this.selectedFormula)
          this.formulaObj = []
          this.modifyJsonForEditor()
        }
      })
    }
    if(this.numberTypeGraph){
      if(this.selectedFormula?.expression?.length){
        console.log(this.selectedFormula)
        this.formulaObj = []
        this.modifyJsonForEditor()
      }
    if(this.customFieldArr?.length){
      this.customFieldArr = this.customFieldArr?.filter(c => c?.type === 'NUMBER').map(item => {
      return {
      ...item,
      display_key: item.name, // Add display_key based on the name field
      key: item._id,           // Add key based on the _id field
      operation_type: item?.name
    };
      });
    }
    }
  
  }



  // Handle arrow key events
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.formulaViewPort?.nativeElement?.contains(event?.target) &&event.target && event.target instanceof HTMLElement && event.target.id !== 'input') {
      event.preventDefault();
    }
  }

  loadDynamicTemplate() {
    this.dynamicFormulaEditor?.clear();
    const view = this.dynamicFormulaEditor.createEmbeddedView(this.myFormulaTemplate);
  }

  insertInput() {
    if (this.previousIndexID?.length) {
      let index = this.formulaObj?.findIndex(f => f?.uniqueid === this.previousIndexID)
      // Add the value at the specified index
      if (this.formulaObj?.length === index + 1) {
        this.formulaObj.push({ 'uniqueid': this.commonutils.getRandomString(10), 'columns': [], 'showInput': true, 'manually': true })
      }
      else {
        this.formulaObj.splice(index + 1, 0, { 'uniqueid': this.commonutils.getRandomString(10), 'columns': [], 'showInput': true, 'manually': true });
      }
    }
    else{
      if(this.cursorAtStart){
        this.formulaObj.unshift({ 'uniqueid': this.commonutils.getRandomString(10), 'columns': [], 'showInput': true, 'manually': true })
      }
      else{
        this.formulaObj.push({ 'uniqueid': this.commonutils.getRandomString(10), 'columns': [], 'showInput': true, 'manually': true })
      }
      this.cursorAtStart = false;
      if(this.formulaObj?.length==1){
        const view = this.dynamicFormulaEditor?.createEmbeddedView(this.myFormulaTemplate, { $implicit: this.formulaObj });
      }
    }
  }

  insertFormula(formulaName) {
    let columnValues = JSON.parse(JSON.stringify(this.numberCustomField))
    let defaultColumn = formulaName=='COUNT_ITEM' ? [] : [columnValues[0]]
    let filterColumnList = formulaName=='COUNT_ITEM' ? [] : columnValues.filter(c=>c?.key !== defaultColumn[0]?.key)
    if (this.previousIndexID?.length) {
      let index = this.formulaObj?.findIndex(f => f?.uniqueid === this.previousIndexID)
      // Add the value at the specified index
      if (this.formulaObj?.length === index + 1) {
        this.formulaObj.push({ 'operation_type': formulaName.toUpperCase(), 'uniqueid': this.commonutils.getRandomString(10), 'columns': defaultColumn ,'columnList':filterColumnList})
      }
      else {
        this.formulaObj.splice(index + 1, 0, { 'operation_type': formulaName.toUpperCase(), 'uniqueid': this.commonutils.getRandomString(10), 'columns': defaultColumn ,'columnList':filterColumnList});
      }
    }
    else {
      if(this.cursorAtStart){
        this.formulaObj.unshift({ 'operation_type': formulaName.toUpperCase(), 'uniqueid': this.commonutils.getRandomString(10), 'columns': defaultColumn ,'columnList':filterColumnList})
      }
      else{
        this.formulaObj.push({ 'operation_type': formulaName.toUpperCase(), 'uniqueid': this.commonutils.getRandomString(10), 'columns': defaultColumn ,'columnList':filterColumnList})
      }
      this.cursorAtStart = false
    }
    this.previousIndexID = ''
    if (this.formulaObj?.length == 1) {
      const view = this.dynamicFormulaEditor?.createEmbeddedView(this.myFormulaTemplate, { $implicit: this.formulaObj });
    }
  }

  insertColumn(index, customObj) {
    this.formulaObj[index]['columns'] = this.formulaObj[index]?.columns || []
    this.formulaObj[index]['columnList'] = this.formulaObj[index]['columnList'].filter(x=>x?.key!==customObj?.key)
    this.formulaObj[index]['columns'].push(customObj)
    this.popformulaParams?.close()
  }

  insertOperator(text) {
    if (this.previousIndexID?.length) {
      let index = this.formulaObj?.findIndex(f => f?.uniqueid === this.previousIndexID)
      if (index + 1 !== this.formulaObj?.length) {
        this.formulaObj.splice(index + 1, 0, { 'operator': text, 'uniqueid': this.commonutils.getRandomString(10) })
      }
      else {
        this.formulaObj.push({ 'operator': text, 'uniqueid': this.commonutils.getRandomString(10) })
      }
    }
    else{
      if(this.cursorAtStart){
        this.formulaObj.unshift({ 'operator': text, 'uniqueid': this.commonutils.getRandomString(10) })
      }
      else{
        this.formulaObj.push({ 'operator': text, 'uniqueid': this.commonutils.getRandomString(10) })
      }
      this.cursorAtStart = false
    }
    if (this.formulaObj?.length == 1) {
      const view = this.dynamicFormulaEditor?.createEmbeddedView(this.myFormulaTemplate, { $implicit: this.formulaObj });
    }
  }

  getCareAtPositionForComment(event) {
    const target = event.target as HTMLElement;
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      this.storedCursorPosition = selection.getRangeAt(0);
      this.previousIndexID = this.storedCursorPosition?.startContainer?.parentElement?.dataset?.id
    }
    if(!this.previousIndexID){
      this.cursorAtStart = this.storedCursorPosition?.startOffset==0 ? true : false
    }
  }


  removefunction(innerIndex,info) {
    console.log(this.formulaObj)
    console.log(info)
    console.log(innerIndex)
    if(info?.index>=0 && info?.arr?.length){
      delete info?.arr[info?.index]?.formulaObj
    }
    else{
      this.formulaObj.splice(innerIndex, 1)
    }
    console.log(this.formulaObj)
    if(!this.formulaObj?.length){
      this.dynamicFormulaEditor?.clear();
    }
    this.popRemove?.close()
  }

  removeSelectedColumn(columnIndex, formulaIndex) {
    this.formulaObj[formulaIndex]['columnList'].push(this.formulaObj[formulaIndex]['columns'][columnIndex])
    this.formulaObj[formulaIndex]['columns'].splice(columnIndex, 1)
    this.popRemoveCol?.close()
  }

  onMouseEnter(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const uniqueId = target.getAttribute('data-id');
    const elements = document.querySelectorAll(`[data-id="${uniqueId}"]`);
    if(!this.showFormulPreview){
      elements.forEach(element => {
        element.classList.add('funcHovered');
      });
    }
  }

  onMouseLeave(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const uniqueId = target.getAttribute('data-id');
    const elements = document.querySelectorAll(`[data-id="${uniqueId}"]`);
    elements.forEach(element => {
      element.classList.remove('funcHovered');
    });
  }

  validateArray() {
    if(this.formulaObj?.length){
      let expression = this.constructExpression()
      this.isValid = this.isValidEquation(expression)  
    }
    else{
      this.isValid = false;
    }
  }

  constructExpression() {
    let expression = "";
    this.formulaObj.forEach((exp, index) => {
      if (exp?.operation_type !== undefined && exp?.operation_type !== null) {
        if (exp.manually) {
          expression += 1+" ";
        }
        else {
          const columnNames = exp?.columns?.map(column => column.display_key);
          expression += 1+" "
        }
      }
      else if (exp?.operator) {
        expression += exp.operator
      }
    });
    console.log(expression)
    return expression;
  }


  isValidEquation(equation) {
    try {
        // Attempt to evaluate the equation
        eval(equation);
        // If evaluation succeeds without errors, return true
        return true;
    } catch (error) {
        // If an error occurs during evaluation, return false
        return false;
    }
  }

  async saveFormula(formulaJson,recurssive=false,mainFormulaKey=true){
    let count = 1;
    let formula = formulaJson ? formulaJson : this.formulaObj
    console.log(this.formulaObj)
      if(this.selectedFormula?.expression){
        this.selectedFormula?.expression.forEach(element=>{
          if (this.selectedFormula && this.selectedFormula[element]) {
            delete this.selectedFormula[element];
        }
        })
        delete this.selectedFormula?.expression
      }
      for (const y of formula){
        let colArr = []

        this.selectedFormula['expression'] = this.selectedFormula?.expression || []
        if (y?.operation_type !== undefined && y?.operation_type !== null && !y?.isFunction && !y?.isColumn) {
          if (y.manually) {
            this.selectedFormula['expression'].push(y?.operation_type)
          }
          else {
            if(!this.automationCall){
              this.selectedFormula['expression'] = this.selectedFormula?.expression || []
              this.selectedFormula['expression'].push("v"+count)
              if(y?.columns?.length){
                y?.columns.forEach(col=>{
                  colArr.push({"dashboard_id": col?.dashboard_id,"data": ["custom_fields."+col?.key]})
                 })
              }
              this.selectedFormula[`v${count}`]= {"operation_type": y?.operation_type,"columns": colArr},
              count++;
            }
            }
        }
        else if (y?.operator) {
          this.selectedFormula['expression'].push(y.operator)
        }
        else if(y?.isFunction){
          let arr = []
          for (const param of y?.funcJson?.parameters || []) {
            if (param?.formulaObj?.length) {
              for (const f of param?.formulaObj) {
                  // Call saveFormula recursively and get the value_eval object
                  let result = await this.saveFormula(param?.formulaObj,true,false)
                  arr.push(result)
              }
              !this.automationCall ? this.formulaEmit.emit(this.selectedFormula) : this.formulaEmit.emit({'formulaDisplayEmit':this.formulaObj,'formulaxpression':this.selectedFormula?.expression})
          }
            if(param?.params?.length){
              let newArray = []
                for(const innerParam of param?.params){
                  const result = await this.processParameter(innerParam);
                  if (result !== undefined) {
                      console.log(result);
                      result['funcJson'] = y?.funcJson
                      newArray.push(result);
                  }
                }
                // Push the new array into the main arr
                arr.push(newArray);
            }
            else{
              let obj = await this.processParameter(param,[],y);
              if(obj){
                arr.push(obj);  
              }
            }
          }  
          let temp = {
            'value_eval':{
              "funcJson" : y?.funcJson,
              "value_from": "EVALUATE",
              "operation_type": "COMPUTEFUNCTION",
              "value_data": {
                  "function_name": y?.funcJson?.name,
                  "args": arr
                  }
              }

          }
          if(!recurssive){
            this.selectedFormula['expression'].push(temp)
          }
          else {
            return temp;
          }
          }
          else if(y?.isColumn){
          let key =`{{${y?.selectedColumnObj?.key}}}`
          let obj ={value_eval:{'selectedColumnObj':y?.selectedColumnObj,'value_data':`{{${y?.selectedColumnObj?.key}}}`,'value_from':"EVALUATE"}}
          if(y?.selectedColumnObj?.algorithm){
            obj['value_eval']['algorithm'] = y?.selectedColumnObj?.algorithm
          }
          this.selectedFormula['expression'].push(obj)
          }
        }
      // this.selectedFormula['expression'].push(expression)
       !this.automationCall ? this.formulaEmit.emit(this.selectedFormula) : this.formulaEmit.emit({'formulaDisplayEmit':this.formulaObj,'formulaxpression':this.selectedFormula?.expression})
       this.closeEmit.emit()
       if(this.automationCall){
        this.hideValidBar = true
       }
       // Return the value_eval object obtained from recursive calls
  }

  modifyJsonForEditor(){
    this.dynamicFormulaEditor?.clear();
    let selectedFormula = !this.automationCall ? this.selectedFormula?.expression : this.selectedFunc
    selectedFormula.forEach(exp=>{
        if (typeof exp === 'string' && exp.includes('v')) {
          let col = this.forColums(this.selectedFormula[exp])
          this.formulaObj.push({'operation_type':this.selectedFormula[exp]?.operation_type,'uniqueid':this.commonutils.getRandomString(10),'columns': col,'columnList': this.numberCustomField.filter(obj => !col.includes(obj.key))})
        }
        else if(typeof exp === 'string'){
          this.formulaObj.push({'operator':exp,'uniqueid':this.commonutils.getRandomString(10)})
        }
        else if(typeof exp === 'number'){
          this.formulaObj.push({'operation_type':exp,'uniqueid':this.commonutils.getRandomString(10),'columns': [],'manually': true,'showInput': false})
        }
        else if(exp?.value_eval && exp?.value_eval?.selectedColumnObj){
          this.formulaObj.push({ 'uniqueid': this.commonutils.getRandomString(10),'operation_type':exp?.value_eval?.selectedColumnObj?.label,'selectedColumnObj':exp?.value_eval?.selectedColumnObj, 'isColumn':true})
        }
        else if(exp?.value_eval && exp?.value_eval?.operation_type=="COMPUTEFUNCTION"){
          this.formulaObj.push({'isFunction':true,'funcJson': exp?.value_eval?.funcJson,'uniqueid':this.commonutils.getRandomString(10),'operation_type': exp?.value_eval?.funcJson?.display_name})
        }
      })
      if(!this.automationCall){
        this.copyOfFormula=this.selectedFormula
      }
      console.log("formulaobject",this.formulaObj,this.selectedFormula,this.copyOfFormula)
      // this.selectedFormula = {}
    setTimeout(()=>{
      this.dynamicFormulaEditor?.clear();
      const view = this.dynamicFormulaEditor?.createEmbeddedView(this.myFormulaTemplate, { $implicit: this.formulaObj});
    },100)
  }


  resetEditorForPreview(){
    console.log(this.selectedFormula?.expression)
    this.modifyJsonForEditor()
  }

  forColums(selectedJson){
    let arr = []
    console.log("arrays list",selectedJson)
    selectedJson?.columns.forEach(c=>{
      console.log(c)
      let key = c?.data[0]?.split('custom_fields.').pop()
      arr.push(this.numberCustomField.filter(y=>y?.key == key)[0])
    })
    console.log("arrays",arr)
    return arr;
  }

  getFunctionsEmit(event,param){
    console.log(event)
    this.popFunction?.close();
    this.popupParamFunc?.close();
    this.popupParamFuncArr?.close()
    this.popParamOption?.close;
    console.log(param)
        if(param){
          delete param?.isColumn
          delete param?.selectedColumnObj
          delete param?.value 
          param['formulaObj'] = [{'isFunction':true,'funcJson':event,'uniqueid':this.commonutils.getRandomString(10),'operation_type': event?.display_name}]
        }
        else{
          this.formulaObj.push({'isFunction':true,'funcJson':event,'uniqueid':this.commonutils.getRandomString(10),'operation_type': event?.display_name})
          if (this.formulaObj?.length == 1) {
            const view = this.dynamicFormulaEditor?.createEmbeddedView(this.myFormulaTemplate, { $implicit: this.formulaObj });
          }
        }
      
  }

  formulaParamInput(param){
    if(param?.isColumn){
      delete param?.value
    }
    delete param?.formulaObj
    delete param?.isColumn
    delete param?.selectedColumnObj
    param.showInput = true;
  }

  insertColumns(event,param){
    console.log(event)
  delete param?.showInput
  delete param?.value
  delete param?.formulaObj
  let algorithm =  event?.algorithm == 'FETCH_FIRST' ? '(First value)' : event?.algorithm == 'FETCH_LAST' ? '(Lastvalue)' : event?.algorithm == 'FETCH_ALL' ? '(All value)' : ''
  event.label = event?.key?.includes('dashboardVariable.')  ? ` ${event.value} ( Workfolder Variable)` : event?.key?.includes('{{variable.') || event?.key?.includes('automationVariable.')  ? ` ${event.value} ( Automation Variable)` : event?.key?.includes('triggeredItem') ? `Triggered Item ${event.value} ${algorithm}` : event?.key?.includes('targetItem') ? `Filtered Item ${event.value} ${algorithm}` : `{{${event.value}}}`
  param.isColumn = true;
  param.selectedColumnObj = event
  param.value = event?.label 
  console.log(this.formulaObj)
  this.popParamOption?.close()
  this.popColumns?.close()

  }

  insertColFun(event){
    this.popColumnFunc.close()
    let algorithm =  event?.algorithm == 'FETCH_FIRST' ? '(First value)' : event?.algorithm == 'FETCH_LAST' ? '(Lastvalue)' : event?.algorithm == 'FETCH_ALL' ? '(All value)' : ''
    if(!this.numberTypeGraph) {
    event.label =event?.key?.includes('dashboardVariable.')  ? ` ${event.value} ( Workfolder Variable)` : event?.key?.includes('{{variable.') || event?.key?.includes('automationVariable.') ? ` ${event.value} ( Automation Variable)` : event?.key?.includes('triggeredItem') ? `Triggered Item ${event.value} ${algorithm}` : event?.key?.includes('targetItem') ? `Filtered Item ${event.value} ${algorithm}` : `{{${event.value}}}`  
   }else{
      event.label = event?.value
     }
  
    if (this.previousIndexID?.length) {
      let index = this.formulaObj?.findIndex(f => f?.uniqueid === this.previousIndexID)
      // Add the value at the specified index
      if (this.formulaObj?.length === index + 1) {
        this.formulaObj.push({ 'uniqueid': this.commonutils.getRandomString(10),'operation_type':event?.label,'selectedColumnObj':event, 'isColumn':true})
      }
      else {
        this.formulaObj.splice(index + 1, 0, { 'uniqueid': this.commonutils.getRandomString(10),'operation_type':event?.label,'selectedColumnObj':event, 'isColumn':true});
      }
    }
    else{
      if(this.cursorAtStart){
        this.formulaObj.unshift({ 'uniqueid': this.commonutils.getRandomString(10), 'operation_type':event?.label,'selectedColumnObj':event, 'isColumn':true})
      }
      else{
        this.formulaObj.push({ 'uniqueid': this.commonutils.getRandomString(10), 'operation_type':event?.label,'selectedColumnObj':event, 'isColumn':true})
      }
      this.cursorAtStart = false;
      if(this.formulaObj?.length==1){
        const view = this.dynamicFormulaEditor?.createEmbeddedView(this.myFormulaTemplate, { $implicit: this.formulaObj });
      }
    }
  }

  async processParameter(param,arr=[],func?) {
    if (param?.isColumn && param?.selectedColumnObj) {
      return {
        value_eval: {
          value_from: "EVALUATE",
          operation_type: (param?.type == 'string' || param?.sub_type == 'string' )? "COMPUTETEXT" : (param?.type == 'number' || param?.sub_type == 'number') ? "COMPUTENUMBER" : param?.type ? param?.type?.toUpperCase() : "COMPUTETEXT",
          value_data: `{{${param?.selectedColumnObj?.key}}}`,
          algorithm: param?.selectedColumnObj?.algorithm ? param?.selectedColumnObj?.algorithm : 'DIRECT_VALUE'
        }
      };
    } else if (param?.funcObj?.length) {
      let tempArr = [];
      let name = "";
      for (const params of param.funcObj) {
        name = params?.name;
        // Use Promise.all to wait for all async calls in the loop
         if(params?.parameters){
        const innerParamsPromises = params?.parameters.map(innerParam => this.processParameter(innerParam));
        const innerParamsResults = await Promise.all(innerParamsPromises);
        tempArr.push(...innerParamsResults);
        }
      }
      return {
        value_eval: {
          value_from: "EVALUATE",
          operation_type: "COMPUTEFUNCTION",
          value_data: { "function_name": name, "args": tempArr }
        }
      };
    } 
    else if(param?.value){
        return { value_eval: { value_from: param?.value?.date ? "OBJECT" : param?.sub_type ? param?.sub_type.toUpperCase() : param?.type ? param?.type?.toUpperCase() : "COMPUTETEXT", value_data: param?.value } };
      }
  }

  addParameForRestType(item,i){
    console.log(item)
    console.log(this.formulaObj)
    const copiedParam = JSON.parse(JSON.stringify(item[i]));
    delete copiedParam?.value
    console.log(this.formulaObj)
    item.push(copiedParam)
  }

  removeParameForRestType(item,i){
    console.log(i)
    item.splice(i,1)
  }
  addfunctionforParam(event,item,i){
     console.log(item)
     item.push({'isFunction':true,'funcJson':event,'uniqueid':this.commonutils.getRandomString(10),'operation_type': event?.display_name})
     console.log(this.formulaObj)
    }

   showInputforArrayTypeParams(param,subType,event?){
    param['params']= param['params'] || []
    if(this.paramIndex>=0){
      delete param['params'][this.paramIndex]?.isColumn
      delete param['params'][this.paramIndex]?.selectedColumnObj
      delete param['params'][this.paramIndex]?.value  
      if(subType=="func"){
        param['params'][this.paramIndex].isFunction = true;  
        param['params'][this.paramIndex].funcJson = event;  
        param['params'][this.paramIndex].uniqueid = this.commonutils.getRandomString(10);  
        param['params'][this.paramIndex].operation_type = event?.display_name;  
      }
      else{
        param['params'][this.paramIndex].showInput = true;
      if(param['params'][this.paramIndex]?.isColumn ||
      param['params'][this.paramIndex]?.selectedColumnObj ||
      param['params'][this.paramIndex].sub_type !== subType){
        param['params'][this.paramIndex].sub_type = subType;  
      }
      }
    }
    else{
      if(subType=="func"){
        param['params'].push({'isFunction':true,'funcJson':event,'uniqueid':this.commonutils.getRandomString(10),'operation_type': event?.display_name})
      }
      else{
        param['params'].push({showInput:true,sub_type : subType})
      }
    }
    this.popupParamFuncArr?.close()
    this.popParameterParam?.close()
    console.log(this.formulaObj)
  } 

  deleteInputforArrayTypeParams(formulaIndex,index){
    this.formulaObj[formulaIndex].funcJson.parameters[index]['params'].splice(this.paramIndex,1)
    this.paramIndex = -1
    this.popParameterParam.close()
  }

  // to focus on input field
  ngAfterViewChecked() {
    if (this.inputField) {
      this.inputField.nativeElement.focus();
    }
  }

}
