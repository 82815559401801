import { Injectable } from "@angular/core";
import { ToasterService } from "./toaster.service";
import { Observable, Subject } from "rxjs";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { tap } from "rxjs/operators";
import { CustomStorageService } from "./custom-storage.service";
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { MessageService } from "app/message.service";

@Injectable({
  providedIn: "root",
})
export class HttpTransferService {
  //hostUrl
  hostUrl = environment.hostUrl;

  //All api urls
  pdfUrl = "/lead/export/pdf";
  excelUrl = "/lead/export";
  leadExcelUrl = "excel"
  exportLeadFormatUrl = "/lead/exportLeadFormat"
  organisationUrl = "organisation";
  orgUrl = "org";
  dashboardUrl = "dashboard";
  bucketUrl = "bucket";
  leadUrl = "lead";
  starred = "starred";
  userUrl = "user";
  userSessionsUrl = "usersessions";
  thirdPartyLoginUrl = "api/user/session/"
  profileimage = "profileimage";
  dashBuckUrl = "dashbuckinfo";
  loginUrl = "login";
  queryUrl = "query";
  multiQueryUrl = "query";
  timelineUrl = "timeline";
  sprintUrl = "sprint";
  adminUrl = "admin";
  notificationUrl = "notification";
  colloboratorsUrl = "colloborators";
  changeBucketUrl = "changebucket";
  tagUrl = "tag";
  customFieldUrl = "customfields";
  changePasswordUrl = "changepassword";
  buckInfoUrl = "buckinfo";
  templateUrl = "template";
  boardUrl = "boards";
  categoryUrl = "category";
  logoutUrl = "logout";
  buckCoresUserUrl = "buckcorresuser";
  forgotPasswordUrl = "forgotPassword";
  resendVerificatioEmailUrl = "resendVerificationEmail";
  userActivityUrl = "user_activity";
  commentUrl = "comment";
  dashCorresUserUrl = "dashcorresuser";
  trashUpdateUrl = "trashupdate";
  leadDeleteUrl = "leaddelete"
  bucketSeqUrl = "buckseq";
  fcmTokenUrl = "fcmtoken";
  historyUrl = "history";
  fileUrl = "file";
  copyUrl = "/copy";
  uploadUrl = "upload";
  refreshTokenMsg = "Auth failed";
  refreshTokenApi = "user/refreshtoken";
  postString = "post";
  getString = "get";
  putString = "put";
  deleteString = "delete";
  patchString = "patch";
  error401 = "401";
  restoreUrl="trash/lead"
  userorginfo = "userorginfo";
  switchOrganisation = "switchorg";
  notificationPreferenceId = "user/notification/preferences/query";
  notificationPreference = "user/notification/preference";
  formulaUrl = "formula"

  filterUrl = 'filter';
  apiUrl = 'api';
  groupList = "user/usergroup/query";
  deleteGroupUrl = "user/usergroup/"
  createGroupUrl = "user/usergroup"
  fetchUserUrl = "user/query"
  addMemberInGroupUrl = "user/usergroup"
  removeMemberInGroupUrl = "user/deleteusergroup/"
  updateGroupUrl = "user/usergroup/"
  mqttUrl: string = "mqtt";
  noteUrl="api/note/query";
  updateNoteUrl="api/note";
  mentions="api/mentions";
  emailUrl="email";
  keygenUrl: string = "keygen";
  requestInterceptMap: any = {};
  chatservertokenUrl: string = "chatservertoken";
  fetchfilefolderquery = 'filesystem/query';
  dashboardFolderQuery = 'dashboardfolder/query';
  dashboardFolderUrl: string = '/dashboardfolder'
  mediaUrl = 'media';
  actionUrl = 'action';
  keyValueUrl: string = "key/value";
  countryCodeUrl: string = "countryCode";
  viewUrl: string = "view";
  colorUrl: string = "color"
  formUrl:string="form";
  captcha:string="captcha";
  formIdUrl:string="formId";
  currency:string="api/countries/info"
  dashboardUserPreferencesUrl:string="/dashboarduserpreferences";
  largefileUrl:string="largefile";
  multipart:string="multipart";
  import:string="startimport";
  column:string="column";
  Mapping:string="mapping";
  mapColumnValues:string="mapcolumnvalues";
  automationrule:string="automationrule";
  indexUrl:string="automationindex";
  organisationIdIndex=-1
  generateQrCode: string = 'generate-2fa-qrcode'
  verifyTwoFactorAuth: string = 'verify-2fa'
  showModal = new Subject<any>();
  channel: string="channel";
  hooks:string="hooks";
  catch:string="catch"
  automationTemplatecategory:string = "automationtemplates/category";
  chatUser="chatuser"
  liveUpdate:string = "liveupdate"
  chatBot:string="chatbot"
  pronnelsupport:string="pronnelsupport"
  function:string="function"
  decisionUrl:string='oauth/dialog/authorize/decision'
  calendar:"calendar"
  event:"event"
  schedule:string="schedule"
  settingsUrl:string="settings"
  listUrl:string="list"
  externalAppUrl:string='user/externalapp'
  createOrUpdateLeadIdStack:any={create:[],update:[]}
  itemModalOpen:any[]=[];
  knowledgebase="knowledgebase"
  aiAssist:any='dashboard/chat/completions'
  aiWfgenerationUrl:string="api/ai/wfgeneration"
  //developerCreate:"/users/:userId/apikey"
  // captcha: string = 'captcha'
  constructor(
    private http: HttpClient,
    private toasterService: ToasterService,
    private router: Router,
    private customStorageService: CustomStorageService,
    private messageService:MessageService
  ) {}

  //All api calls

  getKeyValuesWithoutAuth() {
    let json = {}
    const apiUrl = this.hostUrl + this.apiUrl + "/" +this.keyValueUrl + "/" + this.queryUrl;
    return this.postQuerywithoutAuth(apiUrl,json)
  }  

  getColor(){
    const apiUrl = this.dashboardUrl + "/" +this.colorUrl;
    return this.getQuery(apiUrl);
  } 
  
  createDashboardFolder(inputJson){
    var apiUrl = this.organisationUrl + '/' + this.dashboardFolderUrl
    return this.postQuery(apiUrl, inputJson);
  }

  updateDashboardFolder(folderId:string, properties:any){
    let inputJson = {}
    if(typeof(properties)=='string'){
      inputJson={'name': properties}
    }
    else{
      inputJson=properties;
    }
    var apiUrl = this.organisationUrl + this.dashboardFolderUrl + '/' + folderId
    return this.putQuery(apiUrl, inputJson);
  }


  deleteDashboardFolder(folderId:string){    
    var apiUrl = this.organisationUrl + this.dashboardFolderUrl + '/' + folderId
    return this.deleteQuery(apiUrl);
  }
 
  createOrganisation(data: any) {
    var apiUrl = this.organisationUrl;
    return this.postQuery(apiUrl, data);
  }
  getNote(data: any) {
    var apiUrl = this.noteUrl;
    return this.postQuery(apiUrl, data);
  }
  getAllOrganisation() {
    var apiUrl = this.organisationUrl;
    return this.getQuery(apiUrl);
  }

  getUserSessions() {
    var apiUrl = this.userUrl + "/" + this.userSessionsUrl;
    return this.getQuery(apiUrl);
  }

  updateAnOrganisation(data: any, orgId) {
    var apiUrl = this.organisationUrl + "/" + orgId;
    return this.patchQuery(apiUrl, data);
  }

  getDashBoardForAdmin(inputJson?) {
    var apiUrl = this.organisationUrl + "/" + this.dashboardUrl+"/"+this.queryUrl;
    if(!inputJson){
      inputJson = {"type":["BOARD","BOARD_GRAPH"],"get_my_boards": true,'access_type':["PRIVATE", "INTERNAL"]}

    }
    return this.postQuery(apiUrl, inputJson);
  }

  uploadNoteMedia(noteId,inputJson){
    let apiUrl= this.updateNoteUrl + "/" + noteId +"/" +this. mediaUrl;
    return this.postQuery(apiUrl, inputJson);
  }
  deleteNoteMedia(noteId,mediaId){
    let apiUrl=this.updateNoteUrl + "/" + noteId +"/" +this. mediaUrl+'/'+mediaId;
    return this.deleteQuery(apiUrl)
  }

  getNoteMedia(inputJson){
    let apiUrl= this.updateNoteUrl+"/"+this.mediaUrl+"/"+this.queryUrl;
    return this.postQuery(apiUrl, inputJson);
  }

  getDashboardFolders(inputJson?){
    var apiUrl = this.organisationUrl + "/" + this.dashboardFolderQuery;
    if(!inputJson){
      inputJson = {
        pagination_details: {
          additionalProperties: false,
          properties: {
              page_size: 50,
              page_number: 1
          }
        }
      }
    }
    return this.postQuery(apiUrl, inputJson);
  }

  getTemplateCategories(){
    var apiUrl = this.templateUrl + "/" + this.categoryUrl;
    return this.getQuery(apiUrl);
  }

  createDashBoardCorrespondingToOrganisation(data: any, orgId) {
    var apiUrl = this.organisationUrl + "/" + this.dashboardUrl;
    return this.postQuery(apiUrl, data);
  }

  createDashboardFromTemplate(tempFolderId, inputJson){
    var apiUrl = this.templateUrl + "/" + tempFolderId + "/" + this.dashboardUrl
    return this.postQuery(apiUrl, inputJson);
  }

  updateDashBoardCorrespondingToOrganisation(data: any, orgId, dashId) {
    var apiUrl =
      this.organisationUrl +
      "/" +
      this.dashboardUrl +
      "/" +
      dashId;
    return this.putQuery(apiUrl, data);
  }


  copyBoardOrFolder(data){
    var apiUrl =
      this.organisationUrl +
      "/" +
      this.dashboardUrl +
      this.copyUrl;
    return this.postQuery(apiUrl,data);
  }



  createupdateUseProfile(dataObj) {
      var apiUrl = `user/profile`;
      return this.putQuery(apiUrl, dataObj);
    }

  getUserImage(inputJson) {
    var apiUrl = this.userUrl + "/" + this.profileimage + "/" + this.queryUrl;
    return this.postQuery(apiUrl, inputJson);
  }

  getDashBoardInfoCorrespondingToDashId(dashId) {
    let inputJson = {
      "dashboard_id":[dashId],
      "type": ["BOARD","BOARD_GRAPH","WORK_FOLDER","FOLDER"],
       "access_type":  ["PRIVATE", "INTERNAL"]
     // "type": ["BOARD_PRIVATE", "BOARD_INTERNAL", "BOARD_GRAPH"]
    }
    return this.getDashBoardForAdmin(inputJson)
  }

  getDashBoardInfoCorrespondingToDashIdAndDashType(dashId, dashType) {
    let inputJson = {
      "dashboard_id":[dashId],
      "type": dashType
    }
    var apiUrl = this.organisationUrl + "/" + this.dashboardUrl+"/"+this.queryUrl;
    return this.postQuery(apiUrl, inputJson);
  }
  importFileQuery(dashId,inputJson) {
    var apiUrl = this.dashboardUrl+"/"+dashId+"/"+this.import;
    return this.postQueryForImport(apiUrl, inputJson);
  }
  columnMappingQuery(dashId,inputJson) {
    var apiUrl = this.dashboardUrl+"/"+dashId+"/"+this.column+"/"+this.Mapping;
    return this.postQuery(apiUrl, inputJson);
  }
  mapColumnValusQuery(dashId,inputJson) {
    var apiUrl = this.dashboardUrl+"/"+dashId+"/"+"import"+"/"+this.mapColumnValues;
    return this.postQuery(apiUrl, inputJson);
  }
  importCreateQuery(dashId,inputJson) {
    var apiUrl = this.dashboardUrl+"/"+dashId+"/"+"import"+"/"+"create";
    return this.postQuery(apiUrl, inputJson);
  }
  getBucketsInfoCorrespondingToDashBoard(dashId, buckId) {
    return this.getBucketsCorrespondingToDahBoard(dashId,buckId)
  }

  getBucketsCorrespondingToDahBoard(dashId, bucketId?) {
    var apiUrl = this.dashboardUrl + "/" + this.bucketUrl + '/' + this.queryUrl;
    const inputJson = {
      dashboard_id: this.convertToJsonArray(dashId),
      ...(bucketId && {bucket_id: [bucketId]})
    }
    return this.postQuery(apiUrl, inputJson);
  }

  createBucketCorrespondingToDashBoard(data: any, dashId) {
    var apiUrl = this.dashboardUrl + "/" + dashId + "/" + this.bucketUrl;
    return this.postQuery(apiUrl, data);
  }
  createFormCorrespondingToDashBoard(data: any, dashId) {
    var apiUrl = this.dashboardUrl + "/" + dashId + "/" + this.formUrl;
    return this.postQuery(apiUrl, data);
  }
  getFormCorrespondingToDashBoard(formId) {
    var apiUrl = this.formUrl+ "/" + formId;
    return this.getQuery(apiUrl);
  }
  submitSurveyForm(data,formId) {
    var apiUrl = this.formUrl+ "/" + formId;
    return this.postQuery(apiUrl, data);
  }
  updateSurveyForm(data: any, dashId ,formId) {
    var apiUrl = this.dashboardUrl + "/" + dashId + "/" + this.formUrl+ "/" + formId;;
    return this.patchQuery(apiUrl, data);
  }
  deleteSurveyForm(dashId ,formId) {
    var apiUrl = this.dashboardUrl + "/" + dashId + "/" + this.formUrl+ "/" + formId;;
    return this.deleteQuery(apiUrl);
  }
  getAllFormCorrespondingToDashBoard(data: any) {
    var apiUrl = this.dashboardUrl +  "/" + this.formUrl+"/"+this.queryUrl;
    return this.postQuery(apiUrl, data);
  }

  getSprintCorrespondingtoDashBoard(data: any, dashId) {
    var apiUrl =
      this.dashboardUrl +
      "/" +
      this.sprintUrl +
      "/" +
      this.queryUrl;
      if(data){
        data['dashboard_id'] = this.convertToJsonArray(dashId)
      }
    return this.postQuery(apiUrl, data);
  }

  updateSprintCorrespondingToDashBoard(data: any, dashId, sprintId) {
    var apiUrl =
      this.dashboardUrl + "/" + dashId + "/" + this.sprintUrl + "/" + sprintId;
    return this.patchQuery(apiUrl, data);
  }

  deleteSprintCorrespondingToDashBoard(dashId, sprintId) {
    var apiUrl =
      this.dashboardUrl + "/" + dashId + "/" + this.sprintUrl + "/" + sprintId;
    return this.deleteQuery(apiUrl);
  }

  updateBucketCorrespondingToDashBoard(data: any, dashId, buckId) {
    var apiUrl =
      this.dashboardUrl + "/" + dashId + "/" + this.bucketUrl + "/" + buckId;
    return this.patchQuery(apiUrl, data);
  }

  getCustomFieldsCorrespondingToDashBoard(dashId,leadId?) {
    var apiUrl = this.dashboardUrl + "/" + this.customFieldUrl;
    const payload = {
      dashboard_id: this.convertToJsonArray(dashId)
    }
    if(leadId)payload["lead_id"]=leadId
    return this.postQuery(apiUrl, payload);
  }
  getCaptcha(formId,clientId) {
    var apiUrl = this.captcha+ "/" +this.captcha+ "/?" + this.formIdUrl+ "=" + formId+"&clientId="+clientId;
    return this.getQuery(apiUrl);
  }
  createAndUpdateCustomFieldsCorrespondingToDashBoard(data: any, dashId) {
    var apiUrl = this.dashboardUrl + "/" + dashId + "/" + this.customFieldUrl;
    return this.postQuery(apiUrl, data);
  }

  updateCustomFieldData(data: any, dashId) {
    var apiUrl = this.dashboardUrl + "/" + dashId + "/" + this.customFieldUrl;
    return this.patchQuery(apiUrl, data);
  }

  changeUserPassword(data: any) {
    var apiUrl = this.userUrl + "/" + this.changePasswordUrl;
    return this.postQuery(apiUrl, data);
  }

  fetchingAllDashBoardToUser() {
    var apiUrl = this.userUrl + "/" + this.dashboardUrl;
    return this.getQuery(apiUrl);
  }

  createLeadCorrespondingToDashBoard(data: any, dashId) {
    var apiUrl = this.dashboardUrl + "/" + dashId + "/" + this.leadUrl;
    let postResponse = this.postQuery(apiUrl, data).pipe(tap(res=>{
      this.addLeadtoCreateUpdateStack(res.result?.lead_id,'create')             
    }));
    return postResponse;
  }

  updateLeadCorrespondingToLeadId(data: any, dashId, leadId,confirmation?,showErrorToast=true) {
    var apiUrl 
      if(!confirmation) apiUrl = this.dashboardUrl + "/" + dashId + "/" + this.leadUrl + "/" + leadId;;
      if(confirmation) apiUrl = this.dashboardUrl + "/" + dashId + "/" + this.leadUrl + "/" + leadId + '?' + 'confirmed='+ confirmation;
      let patchResponse = this.patchQuery(apiUrl, data,showErrorToast).pipe(tap(res=>{
        sessionStorage.setItem(`${res.result.lead_id}_leadId`, JSON.stringify(res.result.update_date))
        this.addLeadtoCreateUpdateStack(leadId,'update')   
      }));

      console.log('patchResponse',patchResponse)
    return patchResponse;
  }

  deleteLead(dashId, leadId) {
    var apiUrl =
    this.dashboardUrl + "/" + dashId + "/" + this.leadUrl + "/" + leadId;
    return this.deleteQuery(apiUrl);
  }

  getGeoLocationDetails() {
    const url = "https://ipapi.co/json/"
    return this.getQueryWithoutAuth(url);
  }

  getLeadQuery(data: any, dashId?) {
    if(data?.filter_bucket_id) {
      delete data.filter_bucket_id;
    }
    if(!data?.dashboard_id?.length && dashId){
      data['dashboard_id'] = [dashId]
    }
    var apiUrl =
      this.dashboardUrl +
      "/" +
      this.leadUrl +
      "/" +
      this.queryUrl;
    return this.postQuery(apiUrl, data);
  }
  createNewTag(data: any, dashId) {
    var apiUrl = this.dashboardUrl + "/" + dashId + "/" + this.tagUrl;
    return this.postQuery(apiUrl, data);
  }

  updatingTag(data: any, dashId, tagId) {
    var apiUrl =
      this.dashboardUrl + "/" + dashId + "/" + this.tagUrl + "/" + tagId;
    return this.patchQuery(apiUrl, data);
  }

  getTagCorrespondingToDashId(dashboardIdArray) {
    var apiUrl = this.dashboardUrl + "/" + this.tagUrl+"/"+this.queryUrl;
    let inputJson = {"dashboard_id":this.convertToJsonArray(dashboardIdArray)}
    return this.postQuery(apiUrl,inputJson);
  }

  getTagsForDashboards(dashboardIds: any[]){
    var apiUrl = this.dashboardUrl + "/" + this.tagUrl+"/"+this.queryUrl;
    let inputJson = {"dashboard_id":dashboardIds}
    return this.postQuery(apiUrl,inputJson);
  }

  getGroupList(pageNo = 1, pageSize = 100, searchText?, groupId?, groupType?, inputJson?) {
    var json = {}
    if(!inputJson){ 
      json["group_members"] = true;
      json["group_id"] = groupId;
      if(groupType){
        json["group_type"] = [groupType];
      }
      else{
        json["group_type"] = ["General"];
      }
      if(searchText){
        let search_params = {};
        search_params["search_text"] = searchText;
        search_params["search_columns"] = []
        json["search_params"] = search_params
      }else{
        //json["pagination_details"] =  {page_size: pageSize, page_no: pageNo};
      }
    }
    else{
      json = inputJson
    }
    var apiUrl = this.groupList
    return this.postQuery(apiUrl, json);
  }

  deleteGroup(groupId,removeascolllab?,removeasassigneencollab?) {
    var apiUrl = this.deleteGroupUrl  + groupId +"?removeascolllab=" +removeascolllab +"&removeasassigneencollab="+removeasassigneencollab
    return this.deleteQuery(apiUrl);
  }

  createGroup(data) {
    var apiUrl = this.createGroupUrl
    return this.postQuery(apiUrl, data);
  }

  addMemberInGroup(groupId: string, data: any) {
    var apiUrl = this.addMemberInGroupUrl + "/" + groupId + "/member"
    return this.putQuery(apiUrl, data);
  }
  updateNote(noteId: string, data: any) {
    var apiUrl = this.updateNoteUrl + "/" + noteId 
    return this.putQuery(apiUrl, data);
  }
  createNote(data: any) {
    var apiUrl = this.updateNoteUrl 
    return this.postQuery(apiUrl, data);
  }
  removeMemberFromGroup(groupId: string, userId: string,removeascolllab?,removeasassigneencollab?) {
    var apiUrl = this.removeMemberInGroupUrl + groupId + "/user/" + userId +"?removeascolllab=" +removeascolllab +"&removeasassigneencollab="+removeasassigneencollab
    return this.deleteQuery(apiUrl);
  }

  fetchUser(){
    var apiUrl = this.fetchUserUrl
    return this.getQuery(apiUrl);
  }

  deletingTag(dashId, tagId) {
    var apiUrl =
      this.dashboardUrl + "/" + dashId + "/" + this.tagUrl + "/" + tagId;
    return this.deleteQuery(apiUrl);
  }

  getListOfAllSalesUserCorrespondingToOrganisation(data) {
    var apiUrl = this.userUrl + "/" + this.queryUrl;
    return this.postQuery(apiUrl, data);
  }

  createNewUsers(data: any, orgId) {
    var apiUrl = this.organisationUrl + "/" + orgId + "/" + this.userUrl;
    return this.postQuery(apiUrl, data);
  }

  // updateGroup(groupId, groupName, isGroupAdmin, desc, isPrivate) {
  //   var apiUrl = this.updateGroupUrl + groupId
  //   var param = {}
  //   param["group_name"] = groupName
  //   param["group_admin"] = isGroupAdmin
  //   param["is_private"] = isPrivate
  //   return this.putQuery(apiUrl, param)
  // }

  updateUser(data: any, userId) {
    var apiUrl = this.userUrl + "/" + userId;
    return this.patchQuery(apiUrl, data);
  }

  deletingUser(userId) {
    var apiUrl = this.userUrl + "/" + userId;
    return this.deleteQuery(apiUrl);
  }

  userLogin(data: any) {
    var apiUrl = this.userUrl + "/" + this.loginUrl;
    let response;
    if (data.otp_token != undefined) {
      response = this.loginPostQuery(apiUrl,{otp_token:data.otp_token, temptoken: data.tempToken});
    }
    else {
      response = this.loginPostQuery(apiUrl,{...data,client_id:this.customStorageService.getItem("clientId")});
    }
    localStorage.setItem("clearLocalStorageTime", ""+new Date().getTime());
    sessionStorage.setItem("clearSessionStorageTime", ""+new Date().getTime());
    return response;
  }

  authenticateByThirdParty(Url_code:string,clientId,loginThrough,type){
    var url = this.hostUrl+this.thirdPartyLoginUrl+loginThrough+"?code="+Url_code+"&state=%7Bclient_id="+clientId+",type="+type+"%7D";
    return this.getQueryWithoutAuth(url);
  }

  linkByThirdParty(Url_code:string,clientId,loginThrough,token){
    var url = this.hostUrl+this.thirdPartyLoginUrl+loginThrough+"?code="+Url_code+"&state=%7Bclient_id="+clientId+",token="+token+",type=LINKEDUP"+"%7D";
    return this.getQueryWithoutAuth(url);
  }

  unlinkSocialAccounts(accountId){
    const apiUrl = this.thirdPartyLoginUrl + accountId;
    return this.getQuery(apiUrl);
  }

  userLogOut(data) {
    var apiUrl = this.userUrl + "/" + this.logoutUrl;
    return this.postQuery(apiUrl, data);
  }

  loginInChatServer() {
    let json = {};
    const apiUrl = this.userUrl + "/" + this.apiUrl + "/" + this.chatservertokenUrl;
    return this.postQuery(apiUrl,json);
  }

  getListOfAllAdminCorrespondingToSuperAdmin() {
    var apiUrl = this.userUrl + "/" + this.adminUrl;
    return this.getQuery(apiUrl);
  }

  assigningBucketAndDashBoardToUserByAdmin(userId, dashId, buckId) {
    var apiUrl =
      this.userUrl +
      "/" +
      userId +
      "/" +
      this.dashboardUrl +
      "/" +
      dashId +
      "/" +
      this.bucketUrl +
      "/" +
      buckId;
    return this.postQuery(apiUrl, {});
  }

  removingBucketAndDashBoardFromUserByAdmin(userId, dashId, buckId) {
    var apiUrl =
      this.userUrl +
      "/" +
      userId +
      "/" +
      this.dashboardUrl +
      "/" +
      dashId +
      "/" +
      this.bucketUrl +
      "/" +
      buckId;
    return this.deleteQuery(apiUrl);
  }

  toFollowBucketByUser(dashId, buckId) {
    var apiUrl =
      this.dashboardUrl +
      "/" +
      dashId +
      "/" +
      this.bucketUrl +
      "/" +
      buckId +
      "/" +
      this.notificationUrl;
    return this.postQuery(apiUrl, {});
  }

  toUnFollowBucketByUser(dashId, buckId) {
    var apiUrl =
      this.dashboardUrl +
      "/" +
      dashId +
      "/" +
      this.bucketUrl +
      "/" +
      buckId +
      "/" +
      this.notificationUrl;
    return this.deleteQuery(apiUrl);
  }

  toFollowLeadByUser(dashId, leadId) {
    var apiUrl =
      this.dashboardUrl +
      "/" +
      dashId +
      "/" +
      this.leadUrl +
      "/" +
      leadId +
      "/" +
      this.notificationUrl;
    return this.postQuery(apiUrl, {});
  }

  toUnFollowLeadByUser(dashId, leadId) {
    var apiUrl =
      this.dashboardUrl +
      "/" +
      dashId +
      "/" +
      this.leadUrl +
      "/" +
      leadId +
      "/" +
      this.notificationUrl;
    return this.deleteQuery(apiUrl);
  }

  toColloborateLeadByUser(dashId, leadId, data) {
    var apiUrl =
      this.dashboardUrl +
      "/" +
      dashId +
      "/" +
      this.leadUrl +
      "/" +
      leadId +
      "/" +
      this.colloboratorsUrl;
    return this.postQuery(apiUrl, data);
  }
// api not in use 
  // getListOfAllUsersCorrespondingToBucket(buckId) {
  //   var apiUrl = this.userUrl + "/" + this.buckCoresUserUrl + "/" + buckId;
  //   return this.getQuery(apiUrl);
  // }

  forgotPassword(data) {
    var apiUrl = this.userUrl + "/" + this.forgotPasswordUrl;
    return this.postQuery(apiUrl, data);
  }

  resendVerificationEmail(email) {
    var apiUrl = this.userUrl + "/" + this.resendVerificatioEmailUrl;
    return this.postQuery(apiUrl, email);
  }

  postLastDashBoardActivityOfUser(dashId, userData) {
    var apiUrl = this.dashboardUrl + "/" + dashId + "/" + this.userActivityUrl;
    return this.postQuery(apiUrl, userData);
  }

  fetchUserLastActivityDashBoard(userData) {
    var apiUrl =
      this.dashboardUrl + "/" + this.userActivityUrl + "/" + this.queryUrl;
    return this.postQuery(apiUrl, userData);
  }

  patchLastDashBoardActivityOfUser(dashId, userData) {
    var apiUrl = this.dashboardUrl + "/" + dashId + "/" + this.userActivityUrl;
    return this.patchQuery(apiUrl, userData);
  }

  removeDashBoardActivity(dashId, userData) {
    var apiUrl =
      this.dashboardUrl +
      "/" +
      dashId +
      "/" +
      this.userActivityUrl +
      "/" +
      userData;
    return this.deleteQuery(apiUrl);
  }
  removeNote(noteId, inputJson) {
    var apiUrl = this.updateNoteUrl+"/"+noteId
    return this.deleteQuery(apiUrl);
  }
  removeMentions() {
    var apiUrl = this.mentions+"/"+"remove"
    return this.deleteQuery(apiUrl);
  }
  getNoteKey(noteId, inputJson) {
    var apiUrl = this.updateNoteUrl+"/"+noteId +"/"+"noteKey"
    return this.postQuery(apiUrl,inputJson);
  }
  noteEmail(noteId, inputJson) {
    var apiUrl = this.updateNoteUrl+"/"+noteId +"/"+this.emailUrl
    return this.postQuery(apiUrl,inputJson);
  }
  postQueryForMultipleDashboard(userData) {
    var apiUrl =
      this.dashboardUrl + "/" + this.leadUrl + "/" + this.multiQueryUrl;
    return this.postQuery(apiUrl, userData);
  }

  orglist(userId) {
    let inputJson ={}
    let domain = this.getCurrentUrl(document.baseURI)
    if(domain!=''){
      inputJson['domain'] = [domain]
    }
    var apiUrl = this.userUrl + "/" + userId + "/" + this.userorginfo;
    return this.postQuery(apiUrl, inputJson);
  }

  getParticularOrgData(org_id){
   var apiUrl = this.organisationUrl + "/" + org_id;
   return this.getQuery(apiUrl)
  }

  updateOrgSettings(json,org_id){
    var apiUrl = this.organisationUrl + "/" + org_id;
    return this.patchQuery(apiUrl,json);
  }

  switchOrg(orgId, serverUniqueId) {
    var apiUrl = this.organisationUrl + "/" + this.switchOrganisation;
    var postData = {};
    postData["server_unique_id"] = serverUniqueId;
    postData["organisation_id"] = orgId;
    return this.postQuery(apiUrl, postData);
  }

    createOrg(name, email, password) {
        var apiUrl = this.organisationUrl;
        var postData = {}

        postData["name"] = name
        postData["email"] = email
        postData["password"] =  password
        return this.postQuery(apiUrl, postData);
    }

  getCommentCorrespondingLead(inputjson) {
    var apiUrl =
      this.dashboardUrl +
      "/" +
      this.commentUrl +
      "/" +
      this.queryUrl;
    return this.postQuery(apiUrl, inputjson);
  }

  getCommentCorrespondingChat(inputjson) {
    var apiUrl =
      this.dashboardUrl +
      "/" + this.leadUrl +
      "/" + this.commentUrl +
      "/" +
      this.queryUrl;
    return this.postQuery(apiUrl, inputjson);
  }

  deleteCommentCorrespondingLead(commentId,leadId,dashboardId) {
    let apiUrl = this.dashboardUrl+"/"+dashboardId+"/"+this.leadUrl+"/"+leadId+"/"+this.commentUrl+"/"+commentId
    return this.deleteQuery(apiUrl)
  }

  deleteCommentCorrespondingChat(commentId,leadId) {
    let apiUrl = this.dashboardUrl+"/"+this.leadUrl+"/"+leadId+"/"+this.commentUrl+"/"+commentId
    return this.deleteQuery(apiUrl)
  }

  updateCommentCorrespondingLead(inputjson, dashId, leadId,commentId) {
    var apiUrl = this.dashboardUrl + "/" + dashId + "/" + this.leadUrl + "/" + leadId  + "/" + this.commentUrl +"/"+ commentId;
    return this.patchQuery(apiUrl, inputjson);
  }


  postCommentCorrespondingLead(inputjson, dashId, leadId) {
    var apiUrl = this.dashboardUrl + "/" + dashId + "/" + this.leadUrl + "/" + leadId  + "/" + this.commentUrl;
    return this.postQuery(apiUrl, inputjson);
  }
  postCommentCorrespondingChat(inputjson, leadId) {
    var apiUrl = this.dashboardUrl + "/" + this.leadUrl + "/" + leadId  + "/" + this.commentUrl;
    return this.postQuery(apiUrl, inputjson);
  }
  postMessageForChannel(inputjson) {
    var apiUrl = this.dashboardUrl + "/" + this.commentUrl;
    return this.postQuery(apiUrl, inputjson);
  }

  getUsersCorrespondingToDashBoard(dash, showToolTip=false) {
    var apiUrl =this.dashboardUrl + "/" + this.dashCorresUserUrl;
    let json =typeof dash=='string' ? {dashboard_id:[dash]} : dash
    return this.postQuery(apiUrl,json);
  }

  getUsersAndGroupsCorrespondingToDash(dashId: string) {
    let apiUrl: string = `dashboard/${dashId}/assignee`;
    return this.getQuery(apiUrl);
  }
  createChatUser(inputjson, channelId) {
    var apiUrl =  this.commentUrl + "/" + this.channel + "/" + channelId + "/" + this.chatUser 
    return this.postQuery(apiUrl, inputjson);
  }

  deleteDashboardItem(dashId, leadId, confirmation?,showErrorToast=true) {
    var apiUrl
    if(!confirmation) apiUrl = this.dashboardUrl + "/" + dashId + "/" + this.leadUrl + "/" + leadId;;
     if(confirmation) apiUrl = this.dashboardUrl + "/" + dashId + "/" + this.leadUrl + "/" + leadId + '?' + 'confirmed='+ confirmation;
    return this.deleteQuery(apiUrl,showErrorToast)
  }
  restoreDashboardItem(leadData,dashId, leadId){
    var apiUrl=this.dashboardUrl + "/" + dashId + "/" +this.restoreUrl+ "/" + leadId;
    return this.patchQuery(apiUrl, leadData);
  }

  getIpAddress() {
    let apiUrl =  '/api/location/details';
    return this.getQuery(apiUrl);
  }

  updateBucketForDeletingLead(buckId, leadData) {
    var apiUrl = this.bucketUrl + "/" + buckId;
    return this.patchQuery(apiUrl, leadData);
  }

  getBucketsForDashborads(dashboardIds: any[]){
    var apiUrl = this.dashboardUrl + "/" + this.bucketUrl+"/"+this.queryUrl;
    let inputJson = {"dashboard_id":dashboardIds}
    return this.postQuery(apiUrl,inputJson);
  }

  getCustomFieldsForDasboard(dashboardIds: any[]){
    var apiUrl = this.dashboardUrl + "/" + this.customFieldUrl;
    let inputJson = {"dashboard_id":dashboardIds}
    return this.postQuery(apiUrl,inputJson);
  }

  movingLeadToTrash(trashBuckId, leadIdObj, dashBoardId) {
    var apiUrl = this.dashboardUrl + "/" + dashBoardId + "/" + this.leadUrl + "/" + leadIdObj.lead_id[0] + "/" + this.changeBucketUrl;
    let patchResponse = this.patchQuery(apiUrl, leadIdObj).pipe(tap(res=>sessionStorage.setItem(`${res.result.lead_id}_leadId`, JSON.stringify(res.result.update_date))));
    return patchResponse;
  }

  leadDeletePermantly(leadId, dashBoardId){
    var apiUrl = this.dashboardUrl + "/" + dashBoardId + "/" + this.leadDeleteUrl;
    return this.postQuery(apiUrl,leadId);
  }

  updateUserBucketSeq(dashId, bucketSeqData) {
    var apiUrl = this.dashboardUrl + "/" + dashId + "/" + this.bucketSeqUrl;
    return this.patchQuery(apiUrl, bucketSeqData);
  }

  getFcmTokenApi(userId, inputJson) {
    var apiUrl = this.userUrl + "/" + userId + "/" + this.fcmTokenUrl;
    return this.postQuery(apiUrl, inputJson);
  }

  getNotification(userId, inputJson) {
    var apiUrl =
      this.userUrl +
      "/" +
      userId +
      "/" +
      this.notificationUrl +
      "/" +
      this.historyUrl +
      "/" +
      this.queryUrl;
    return this.postQuery(apiUrl, inputJson);
  }


  updateNotification(userId, inputJson) {
    var apiUrl =
      this.userUrl +
      "/" +
      userId +
      "/" +
      this.notificationUrl +
      "/" +
      this.historyUrl;
    return this.patchQuery(apiUrl, inputJson);
  }

  // fileUpload(orgId, dashId, inputJson) {
  //   var apiUrl =
  //     this.organisationUrl +
  //     "/" +
  //     orgId +
  //     "/" +
  //     this.dashboardUrl +
  //     "/" +
  //     dashId +
  //     "/" +
  //     this.fileUrl +
  //     "/" +
  //     this.uploadUrl;
  //   return this.postQuery(apiUrl, inputJson);
  // }
  fileUploadForOrg(orgId, inputJson) {
    var apiUrl =
      this.organisationUrl +
      "/" +
      orgId +
      "/" +
      this.fileUrl +
      "/" +
      this.uploadUrl;
    return this.postQuery(apiUrl, inputJson);
  }
  formUploadForOrg(Id, inputJson) {
    var apiUrl =
      this.formUrl +
      "/" +
      Id +
      "/" +
      this.uploadUrl;
    return this.postQuery(apiUrl, inputJson);
  }
  userimagefileUpload(orgId, inputJson) {
    var apiUrl =
      this.organisationUrl +
      "/" +
      orgId +
      "/" +
      this.fileUrl +
      "/" +
      this.uploadUrl;
    return this.postQuery(apiUrl, inputJson);
  }

  fileUploadOnAWSServer(url, file) {
    return this.putUploadFile(file, url);
  }

  putUploadFile(file, url) {
    var headers = new HttpHeaders();
    return this.http.put(url, file, {headers,observe: 'response'}).map((response: any) => {
      return { body: response, status: response.status ,header:response.headers};
    });
  }

  twitterSearch(formName: string, user: any) {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http
      .post(this.hostUrl + formName, user, {headers})
      .map((response:any) => {
        return { res: response, status: response.status };
      });
  }
  getQueryWithoutAuth(formName: string,showErrorToast=true) {
    let requestInterceptIndex = this.requestIntercept(formName, this.getString, {});
    return this.http.get(formName).pipe(
      map((response: Response) => {
        return response;
      }),catchError((err) => {
        if (err.status == this.error401) {
          return this.handleError(err,requestInterceptIndex);
        } else {
          return this.handleApiErrorExcept401(err,showErrorToast);
        }
      }));
  }
  getQueryForFileSize(url,showErrorToast=true) {
    const headers = new HttpHeaders({
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
    });
    return this.http
      .get(url, {headers,responseType: 'blob'})
      .map((response: any) => {
        return response
      })
      .catch((err) => {
          return this.handleApiErrorExcept401(err,showErrorToast);
      });
  }
  getQuery(formName: string,showErrorToast=true) {
    var token = this.customStorageService.getItem("token") || '';
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: token,
    });
    let requestInterceptIndex = this.requestIntercept(formName, this.getString, {});
    return this.http.get(this.hostUrl + formName, {headers}).pipe(
      map((response: Response) => {
        return response;
      }),catchError((err) => {
        if (err.status == this.error401) {
          return this.handleError(err,requestInterceptIndex);
        } else {
          return this.handleApiErrorExcept401(err,showErrorToast);
        }
      }));
  }
  postQueryWithFormData(formName: string, user: any,showErrorToast=true) {
    var token = this.customStorageService.getItem("token") || '';
    const headers = new HttpHeaders({
      Authorization: token,
    });
    let requestInterceptIndex = this.requestIntercept(formName, this.postString, user);
    return this.http.post(this.hostUrl + formName, user, {headers,observe: 'response'}).pipe(
      map((response:any) => {
        //   this.toasterService.Success(result.message);
        return { result: response.body, status: response.status };
      }),catchError((err) => {
        if (err.status == this.error401) {
          return this.handleError(err,requestInterceptIndex);
        } else {
          return this.handleApiErrorExcept401(err,showErrorToast);
        }
      }));
  }
  postQuerywithoutAuth(formName: string, user: any,showErrorToast=true) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    let requestInterceptIndex = this.requestIntercept(formName, this.postString, user);
    return this.http
      .post(formName, JSON.stringify(user), {headers,observe: 'response'}).pipe(
      map((response:any) => {
        //   this.toasterService.Success(result.message);
        return { result: response.body, status: response.status };
      }),catchError((err) => {
        if (err.status == this.error401) {
          return this.handleError(err,requestInterceptIndex);
        } else {
          return this.handleApiErrorExcept401(err,showErrorToast);
        }
      }));
  }
 postQuery(formName: string, user: any,showErrorToast=true,contentType?) {
    var token = this.customStorageService.getItem("token") || '';
    let headers= new HttpHeaders()
    .set("Content-Type", contentType ? contentType : "application/json")
    .set("Authorization", token)
    let requestInterceptIndex = this.requestIntercept(formName, this.postString, user);
    return this.http
      .post(this.hostUrl + formName, JSON.stringify(user),{headers,observe: 'response'}).pipe(
      map((response:any) => {
        return { result: response.body, status: response.status };
      })
      ,catchError((err) => {
        if (err.status == this.error401) {
          return this.handleError(err,requestInterceptIndex);
        } else {
          return this.handleApiErrorExcept401(err,showErrorToast);
        }
      }));
  }

  postQueryForImport(formName: string, user: any,showErrorToast=true) {
    var token = this.customStorageService.getItem("token") || '';
    const headers = new HttpHeaders({
      Authorization: token,
    });
    const formData = new FormData()
    formData.append('file', user); 
    let requestInterceptIndex = this.requestIntercept(formName, this.postString, user);
    return this.http
      .post(this.hostUrl + formName, formData, {headers,observe: 'response'})
      .map((response:any) => {
        //   this.toasterService.Success(result.message);
        return { result: response.body, status: response.status };
      })
      .catch((err) => {
        if (err.status == this.error401) {
          return this.handleError(err,requestInterceptIndex);
        } else {
          return this.handleApiErrorExcept401(err,showErrorToast);
        }
      });
  }
  loginPostQuery(formName: string, user: any) {
    let headers= new HttpHeaders()
    .set("Content-Type", "application/json")
    return this.http
      .post(this.hostUrl + formName, JSON.stringify(user), {headers,observe: 'response'}).pipe(
      map((response:any) => {
        return { result: response.body, status: response.status };
      }));
  }

  postExportPdf(dashId,data) {
    var apiUrl = this.dashboardUrl + "/" + dashId + this.pdfUrl;
    return this.postQueryPdf(apiUrl, data);
  }

  postExportExcel(dashId,data) {
    var apiUrl = this.dashboardUrl + "/" + dashId + this.excelUrl;
    return this.postQueryPdf(apiUrl, data);
  }
  exportSampleExcel(dashId){
    var data: any;
    var apiUrl = this.dashboardUrl + "/" + dashId + this.exportLeadFormatUrl;
    return this.postQueryPdf(apiUrl, data);
  }
  getCurrency(){
    const apiUrl = this.currency;
    return this.getQuery(apiUrl);
  } 

  uploadLeadExcel(formData,dashId){
    var apiUrl = this.dashboardUrl + "/" + dashId + "/" + this.leadExcelUrl;
    return this.postQueryWithFormData(apiUrl,formData)
  }
  postQueryPdf(formName: string, user: any,showErrorToast=true) {
    var token = this.customStorageService.getItem("token") || '';
    const headers = new HttpHeaders({ Authorization: token, 'content-type': 'application/json' });
    // const response = new HttpResponse().set("responseType","blob")
    let requestInterceptIndex = this.requestIntercept(formName, this.postString, user);
    return this.http
      .post(this.hostUrl + formName, JSON.stringify(user),{headers,observe: 'response'})
      .map((response: any) => {
        return response;
      })
      .catch((err) => {
        if (err.status == this.error401) {
          return this.handleError(err,requestInterceptIndex);
        } else {
          return this.handleApiErrorExcept401(err,showErrorToast);
        }
      });
  }

  patchQuery(formName: any, user: any,showErrorToast=true) {
    var token = this.customStorageService.getItem("token") || '';
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: token,
    });
    let requestInterceptIndex = this.requestIntercept(formName, this.patchString, user);
    return this.http.patch(this.hostUrl + formName, JSON.stringify(user), {headers,observe: 'response'}).pipe(
      map((response:any) => {
        // this.toasterService.Success(result.message)
        return { result: response.body, status: response.status };
      }),catchError((err) => {
        if (err.status == this.error401) {
          return this.handleError(err,requestInterceptIndex);
        } else {
          return this.handleApiErrorExcept401(err,showErrorToast);
        }
      }));
  }

  uploadLeadFilesQuery(formName: any, user: any) {
    var token = this.customStorageService.getItem("token") || '';
    var headers = new HttpHeaders({ Authorization: token });
    return this.http
      .post(this.hostUrl + formName, user, {headers,observe: 'response'})
      .map((response:any) => {
        this.toasterService.Success(response.body.message);
        return { result: response.body, status: response.status };
      });
  }

  deleteQuery(formName: string,showErrorToast=true) {
    var token = this.customStorageService.getItem("token") || '';
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: token,
    });
    let requestInterceptIndex = this.requestIntercept(formName, this.deleteString, {});
    return this.http
      .delete(this.hostUrl + formName, {headers,observe: 'response'})
      .map((response:any) => {
        return { result: response.body, status: response.status };
      })
      .catch((err) => {
        if (err.status == this.error401) {
          return this.handleError(err,requestInterceptIndex);
        } else {
          return this.handleApiErrorExcept401(err,showErrorToast);
        }
      });
  }

  putQuery(formname: any, user: any,showErrorToast=true) {
    var token = this.customStorageService.getItem("token") || '';
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: token,
    });
    let requestInterceptIndex = this.requestIntercept(formname, this.putString, user);
    return this.http.put(this.hostUrl + formname, JSON.stringify(user), {headers,observe: 'response'}).pipe(
      map((response:any) => {
        return { result: response.body, status: response.status };
      }),catchError((err) => {
        if (err.status == this.error401) {
          return this.handleError(err,requestInterceptIndex);
        } else {
          return this.handleApiErrorExcept401(err,showErrorToast);
        }
      }));
  }

  handleApiErrorExcept401(error: Response | any,showErrorToast=true) {
    console.log(error,'error');
    if(error?.error?.error_code >= 2001 && error?.error?.error_code <= 2500){
      this.messageService.closeModal()
      this.messageService.openModal(error)
      { return Observable.throwError({res: null, status:error.status, message:error?.error?.message});}
    }
    else{ return Observable.throwError({res: (error?.status && showErrorToast ? this.toasterService.Errors(error?.error?.message) : null), status:error.status, message:error?.error?.message});}
  }
  getPhaseCorrespondingToDashboard(dashboardId,inputJson) {
    var apiUrl =
      this.dashboardUrl +
      "/" +
      this.sprintUrl +
      "/" +
      this.queryUrl;
    return this.postQuery(apiUrl, inputJson);
  }
  createPhaseCorrespondingToDashboard(dashboardId, inputJson) {
    var apiUrl = this.dashboardUrl + "/" + dashboardId + "/" + this.sprintUrl;
    return this.postQuery(apiUrl, inputJson);
  }
  deleteBucketCorrespondingToDashboard(dashboardId, bucket_id) {
    var apiUrl =
      this.dashboardUrl +
      "/" +
      dashboardId +
      "/" +
      this.bucketUrl +
      "/" +
      bucket_id;
    return this.deleteQuery(apiUrl);
  }
  updatePhaseCorrespondingToDashboard(dashboardId, inputJson, sprintId) {
    var apiUrl =
      this.dashboardUrl +
      "/" +
      dashboardId +
      "/" +
      this.sprintUrl +
      "/" +
      sprintId;
    return this.patchQuery(apiUrl, inputJson);
  }
  deletePhaseCorrespondingToDashboard(dashboardId, sprintId) {
    var apiUrl =
      this.dashboardUrl +
      "/" +
      dashboardId +
      "/" +
      this.sprintUrl +
      "/" +
      sprintId;
    return this.deleteQuery(apiUrl);
  }
  getTimeLineCorrespondingToLead(orgId, dashboardId, inputJson) {
    var apiUrl =
      this.organisationUrl +
      "/" +
      orgId +
      "/" +
      this.dashboardUrl +
      "/" +
      dashboardId +
      "/" +
      this.timelineUrl +
      "/" +
      this.queryUrl;
    return this.postQuery(apiUrl, inputJson);
  }
  deleteDashboard(dashId, orgId) {
    var apiUrl =`${this.organisationUrl}/${this.dashboardUrl}/${dashId}`
    return this.deleteQuery(apiUrl);
  }
  createUserCorrespondingTodashboard(dashboardId, inputJson) {
    var apiUrl = this.dashboardUrl + "/" + dashboardId + "/" + this.userUrl;
    return this.postQuery(apiUrl, inputJson);
  }
  updateUserCorrespondingTodashboard(dashboardId, userId, inputJson) {
    var apiUrl =
      this.dashboardUrl + "/" + dashboardId + "/" + this.userUrl + "/" + userId;
    return this.putQuery(apiUrl, inputJson);
  }

  removeUserOrGroupFromDashboard(dashId: string, inputJson){
    const apiUrl = `dashboard/${dashId}/unassign`;
    return this.putQuery(apiUrl, inputJson);
  }

  deleteUserCorrespondingTodashboard(dashId: string, userId: string) {
    const apiUrl = `dashboard/${dashId}/user/${userId}`;
    return this.deleteQuery(apiUrl);
  }

  deleteGroupCorrespondingTodashboard(dashId: string, groupId: string) {
    const apiUrl = `dashboard/${dashId}/group/${groupId}`;
    return this.deleteQuery(apiUrl);
  }

  async handleError(error:  HttpErrorResponse | any,requestInterceptIndex,showModal=true) {
    let errMsg: string;
    if (error instanceof HttpErrorResponse) {
      const body = error.error || "";
      const err = body.error || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ""} ${err}`;
      if (error.status === 401) {
        if (error.error.message == this.refreshTokenMsg) {
          if(error.error?.organization_id) {
            this.customStorageService.setItem('organisationid', error.error.organization_id);
          }
          // check user id for login or authentication
         else if(this.customStorageService.getItem('isAuthenticated')=='true'){
          //  let checkAllOrgs= await this.setOrganisationIdForRefreshToken(showModal);
          //  if(checkAllOrgs) return ''
         }          
          var refreshTokenResult = await this.callRefreshToken(this.hostUrl + this.refreshTokenApi).toPromise();
          if (refreshTokenResult["status"] === 200) {
            this.setRefreshTokanOrSwichOrgDataToLocalStorage(refreshTokenResult)
            var prevoiusClonedRequest;
            prevoiusClonedRequest = await this.hitPreviousClonedRequest(requestInterceptIndex).toPromise();
            return prevoiusClonedRequest;
          } else if(refreshTokenResult["status"] === 422) {
            this.logoutUser()
          }
        } 
      } else if (error.status === 403) {
        if(refreshTokenResult.organization_id) {
          this.customStorageService.setItem('organisationid', refreshTokenResult.organization_id);
        }

      }
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    if(error?.error?.message !== this.refreshTokenMsg){
      return Observable.throwError( error?.status ? this.toasterService.Errors(error?.error?.message) : null);
    }
  }

  async setOrganisationIdForRefreshToken(showModal=true){
    let orgData=this.customStorageService.getItem("allOrganisationIds")
    let orgsArr=JSON.parse(orgData);
    // run current org for token
    if(this.organisationIdIndex<0){
      this.organisationIdIndex++;
      return false
    }
    else if(orgData && this.organisationIdIndex<orgsArr.length && orgsArr.length>0){
      this.customStorageService.setItem('organisationid',orgsArr[this.organisationIdIndex]?.organization_id);
      this.organisationIdIndex++
      return false
     
    }else if(this.organisationIdIndex>=orgsArr.length){
      this.organisationIdIndex=0;
      if(showModal)this.showModal.next({not_access_found : true})
      return true
    } 
  }

  getRefreshTokenParams() {
    var refreshparams = {};
    refreshparams["client_id"] = this.customStorageService.getItem("clientId");
    refreshparams["server_unique_id"] = this.customStorageService.getItem("server_unique_id");
    refreshparams["user_id"] = this.customStorageService.getItem("id");
    refreshparams["organization_id"] = this.customStorageService.getItem("organisationid");
    refreshparams["refresh_token"] = this.customStorageService.getItem("refresh_token");
    return refreshparams;
  }

  setRefreshTokanOrSwichOrgDataToLocalStorage(refreshTokenResult:any) {
    var refreshTokenApiresponse;
    refreshTokenApiresponse = JSON.parse(refreshTokenResult["body"]);
    this.customStorageService.setItem("token", refreshTokenApiresponse["token"]);
    var serverId = refreshTokenApiresponse.server_unique_id;
    var userId = refreshTokenApiresponse.user_id;
    var token = refreshTokenApiresponse.token;
    var refreshToken = refreshTokenApiresponse.refresh_token;
    var role = refreshTokenApiresponse.role;
    var name = refreshTokenApiresponse.name;
    var organisationId = refreshTokenApiresponse.organization_id;
    var email = refreshTokenApiresponse.email;
    this.customStorageService.setItem("isAuthenticated", "true");
    this.customStorageService.setItem("token", token);
    this.customStorageService.setItem("refresh_token", refreshToken);
    this.customStorageService.setItem("server_unique_id", serverId);
    this.customStorageService.setItem("role", role);
    this.customStorageService.setItem("organisationid", organisationId);
    this.customStorageService.setItem("name", name);
    this.customStorageService.setItem("id", userId);
    this.customStorageService.setItem("email", email);
    this.customStorageService.setItem("iscustomsearch", "false");
    this.customStorageService.setItem("istwittersearch", "false");
  }

  callRefreshToken(url, headers?) {
    var body = this.getRefreshTokenParams();
    if (!headers) {
      headers = new HttpHeaders();
    }
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    return this.http
      .post(url, body, {headers,observe: 'response'}).pipe(
      map((response:any) => {
        return {
          body: JSON.stringify(response?.body || {}),
          status: response.status,
          response: response,
        };
      }),
      catchError((err) =>{
        if(err["status"] == "422"){
          this.logoutUser()
          return
        }
        return Observable.of(err)
      }));
  }

  hitPreviousClonedRequest(requestInterceptIndex) {
      if (this.requestInterceptMap[requestInterceptIndex]["method"] == this.postString) {
        return this.postQuery(
          this.requestInterceptMap[requestInterceptIndex]["url"],
          this.requestInterceptMap[requestInterceptIndex]["body"]
        );
      } else if (this.requestInterceptMap[requestInterceptIndex]["method"] == this.putString) {
        return this.putQuery(
          this.requestInterceptMap[requestInterceptIndex]["url"],
          this.requestInterceptMap[requestInterceptIndex]["body"]
        );
      } else if (this.requestInterceptMap[requestInterceptIndex]["method"] == this.deleteString) {
        return this.deleteQuery(this.requestInterceptMap[requestInterceptIndex]["url"]);
      } else if (this.requestInterceptMap[requestInterceptIndex]["method"] == this.getString) {
        return this.getQuery(this.requestInterceptMap[requestInterceptIndex]["url"]);
      } else if (this.requestInterceptMap[requestInterceptIndex]["method"] == this.patchString) {
        return this.patchQuery(
          this.requestInterceptMap[requestInterceptIndex]["url"],
          this.requestInterceptMap[requestInterceptIndex]["body"]
        );
      }
    delete this.requestInterceptMap[requestInterceptIndex];
  }

  requestIntercept(url, method, body?) {
    var requestobj = {};
    requestobj["url"] = url;
    requestobj["body"] = body;
    requestobj["method"] = method;
    let length = Object.keys(this.requestInterceptMap).length;
     this.requestInterceptMap[length] = requestobj;
     return length;

  }

  errorHandler(error) {
    return { status: error.status };
  }

  StarTheLead(data: any, dashId, leadId) {
    var apiUrl =
      this.dashboardUrl +
      "/" +
      dashId +
      "/" +
      this.leadUrl +
      "/" +
      leadId +
      "/" +
      this.starred;
    return this.postQuery(apiUrl, data);
  }
  unStarTheLead(dashId, leadId) {
    var apiUrl =
      this.dashboardUrl +
      "/" +
      dashId +
      "/" +
      this.leadUrl +
      "/" +
      leadId +
      "/" +
      this.starred;
    return this.deleteQuery(apiUrl);
  }

  getNotificationPreferenceId() {
    var token = this.customStorageService.getItem("token") || '';
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: token,
    });
    let emptyBody = {};
    return this.http.post(
      this.hostUrl + this.notificationPreferenceId,
      emptyBody,
      { headers }
    );
  }

  updatePhaseStatus(data: any, sprintId, dashId) {
    var token = this.customStorageService.getItem("token") || '';
    const headers = new HttpHeaders({ "Content-Type": "application/json", Authorization: token});
    return this.http.patch(
      `${this.hostUrl}${this.dashboardUrl}/${dashId}/${this.sprintUrl}/${sprintId}`, data, { headers }
    );
  }

  getArchivedDas() {
    return this.getDashBoardForAdmin({"status":["ARCHIVED"],"get_my_boards":true})
  }

  favouriteDash(data: any, dashId) {
    var apiUrl = this.dashboardUrl + this.dashboardUserPreferencesUrl +"/"+ dashId;
   return this.putQuery(apiUrl, data)
  }

  saveFilter(data:any) {
    const apiUrl = `${this.dashboardUrl}/${this.apiUrl}/${this.filterUrl}`;
    return this.postQuery(apiUrl,data);
  }

  editFilter(data:any,filterId) {
    const apiUrl = `${this.dashboardUrl}/${this.apiUrl}/${this.filterUrl}/${filterId}`;
    return this.putQuery(apiUrl,data);
  }

  getQuickFilters(data) {
    const apiUrl = `${this.dashboardUrl}/${this.apiUrl}/${this.filterUrl}/${this.queryUrl}`;
    return this.postQuery(apiUrl,data)
  }

  getQuickFilterObject(data,filterId) {
    const apiUrl = `${this.dashboardUrl}/${this.apiUrl}/${this.filterUrl}/${filterId}/${this.queryUrl}`;
    return this.postQuery(apiUrl,data)
  }

  deleteQuickFilter(filterId) {
    const apiUrl = `${this.dashboardUrl}/${this.apiUrl}/${this.filterUrl}/${filterId}`;
    return this.deleteQuery(apiUrl);
  }

  generateMqttKey(data){
    var apiUrl = this.organisationUrl +"/"+ this.mqttUrl +"/"+ this.keygenUrl;

    let result = this.postQuery(apiUrl,data,false).pipe(tap((res:any)=>this.customStorageService.setItem('dashboard_live_update_key_expiry_time', JSON.stringify(res.result.expiry_time))));

    return result;
  }

  createNewTemplate(inputJson) {
    const apiUrl = this.templateUrl;
    return this.postQuery(apiUrl, inputJson);
  }

  getConnectedBoards(inputJson){
    const apiUrl = this.templateUrl + '/' + this.boardUrl
    return this.postQuery(apiUrl, inputJson);
  }

  updateTemplate(templateId: string, data: any) {
    const apiUrl = `template/${templateId}`;
    return this.putQuery(apiUrl, data);
  }

  deleteTemplate(templateId: string) {
    const apiUrl = `template/${templateId}`;
    return this.deleteQuery(apiUrl);
  }

  addNewUserRoletoOrganization(roleData: any) {
    const apiUrl = 'organisation/api/title';
    return this.postQuery(apiUrl, roleData)
  }

  getAllUserRoleOfOrganization(orgId: any) {
    const apiUrl = 'organisation/api/title/query';
    return this.postQuery(apiUrl, orgId)
  }

  editUesrRoleOfOrganization(data: any, titleId: string) {
    const apiUrl = `organisation/api/title/${titleId}`;
    return this.putQuery(apiUrl, data);
  }

  deleteUserRoleFromOrganization(roleId: string) {
    const apiUrl = `organisation/api/title/${roleId}`;
    return this.deleteQuery(apiUrl)
  }

  deleteUserProfileImage(imageId: string) {
    const apiUrl = `user/profileImage/${imageId}`;
    return this.deleteQuery(apiUrl);
  }

  getAlldashboardCount(inputData) {
    const apiUrl = `${this.dashboardUrl}/${inputData}/${this.actionUrl}`;
    return this.getQuery(apiUrl)
  }

  updateGroup(groupId: string, groupData: any) {
    const apiUrl = `${this.updateGroupUrl}/${groupId}`;
    return this.putQuery(apiUrl, groupData);
  }
  getLinkedAccounts(){
    const apiUrl = this.thirdPartyLoginUrl + this.queryUrl;
    return this.getQuery(apiUrl);
  }

  getDashboardMedia(data:any){
    const apiUrl = `${this.dashboardUrl}/${this.mediaUrl}/${this.queryUrl}`;
    return this.postQuery(apiUrl, data);
  }

  createView(data:any, dashId:string) {
    const apiUrl = this.dashboardUrl + '/' +dashId + '/' + this.viewUrl;
    return this.postQuery(apiUrl, data);
  }

  getDashboardViews(data:any,showErrorToast=true) {
    if(!data?.view_types)data['view_types']=["KANBAN", "GANTT","OVERVIEW", "FILES", "NOTES", "LIST", "GRID", "ACTIVITY","DRIVE","CONNECT","COMMUNICATION","CALENDAR","SPLIT","APP_BOARD_VIEW"]
    const apiUrl = this.dashboardUrl + '/' + this.viewUrl + '/' + this.queryUrl;
    return this.postQuery(apiUrl, data,showErrorToast);
  }
  getViewByViewId(data:any) {
    const apiUrl = this.dashboardUrl + '/' + this.viewUrl + '/' + this.queryUrl;
    return this.postQuery(apiUrl, data);
  }
  updateView(data:any,viewId:string,dashId:string,showErrorToast=true) {
    const apiUrl = this.dashboardUrl + '/' + dashId + '/' + this.viewUrl + '/' + viewId;
    return this.putQuery(apiUrl, data,showErrorToast);
  }
  dashboardViewDelete(viewId:string,dashId:string) {
    const apiUrl = this.dashboardUrl + '/' + dashId + '/' + this.viewUrl + '/' + viewId;
    return this.deleteQuery(apiUrl);
  }

  fileSystemQuery(requestdata) {
    let api_url = this.apiUrl + '/' + this.dashboardUrl + '/' + this.fetchfilefolderquery;
    return this.postQuery(api_url, requestdata)
  }

 
  createFileInFileSystem(dashboard_id, requestdata: any) { 
    let api_url = this.apiUrl + '/' + this.dashboardUrl + '/' + dashboard_id + '/' + this.fileUrl
    return this.postQuery(api_url, requestdata);
  }
  updateFileInFileSystem(dashboard_id, fileid, requestdata: any) {
    let url = this.apiUrl + '/' + this.dashboardUrl + '/' + dashboard_id + '/' + this.fileUrl + '/' + fileid
    return this.putQuery(url, requestdata);
  }
  makeACopyOfFile(requestdata){
    let api_url = this.apiUrl + '/' + this.fileUrl + this.copyUrl
    return this.postQuery(api_url, requestdata);
  }
  
  deleteFileInFileSystem(dashboard_id, fileid) {
    let url = this.apiUrl + '/' + this.dashboardUrl + '/' + dashboard_id + '/' + this.fileUrl + '/' + fileid
    return this.deleteQuery(url);
  }

  convertToJsonArray = function(obj){
    if(obj instanceof Array){
        return obj
    }
    else{
        return [obj];
    }
  }
  getCurrentUrl(url){
    let domain = url.replace(/^(?:https?:\/\/)?/i, "").split('/')
    const pattern = /^developer.*\.pronnel\.com$/;
    if(pattern.test(domain[0])){
          return ''
        }
        else if(!["localhost:4200","app.pronnel.com","staging.pronnel.com","dev1.pronnel.com", 
              "dev2.pronnel.com","staging2.pronnel.com"].includes(domain[0])){
        return domain[0]        
    } 
    else{
      return ''
    }
  }
  largefileUploadForOrg(orgId, inputJson) {
    var apiUrl =
      this.organisationUrl +
      "/" +
      this.largefileUrl +
      "/" +
      this.uploadUrl;
    return this.postQuery(apiUrl, inputJson);
  }
  updateMultipartInfo(uploadId: string, data: any) {
    var apiUrl = this.multipart + "/" + uploadId 
    return this.putQuery(apiUrl, data);
  }
  fetchHtmlOfForm(formId){
   let apiUrl = this.formUrl+"/"+formId+"/html";
   return this.postQuery(apiUrl,{});
  }

  createEmailTemplate(data){
    var apiUrl=this.apiUrl+"/message/template";
    return this.postQuery(apiUrl,data)
  }

  updateEmailTemplate(templateID:string,data){
    var apiUrl=this.apiUrl+"/message/template/"+templateID;
    return this.putQuery(apiUrl,data)
  }

  getEmailTemplate(data){
    var apiUrl=this.apiUrl+"/message/template/query";
    return this.postQuery(apiUrl,data)
  }
  whatsappSendMessage(data){
    var apiUrl = "whatsapp/send"
    return this.postQuery(apiUrl,data)
  }
  whatsappPreApproved(data){
    var apiUrl = "whatsapp/message/preapproved"
    return this.postQuery(apiUrl,data)
  }
  emailSendMessage(data){
    var apiUrl = "api/mail/send"
    return this.postQuery(apiUrl,data)
  }
  getEmailSuggestions(){
    var apiUrl = "api/mail/suggestion"
    return this.getQuery(apiUrl)
  }

  deleteMessageTemplate(templateID:string){
    var apiUrl=this.apiUrl+"/message/template/"+templateID;
    return this.deleteQuery(apiUrl)
  }
  createTimeTracker(dashBoardId,data){
    var apiUrl=this.dashboardUrl+ "/" +dashBoardId+"/tracker"
    return this.putQuery(apiUrl,data)
  }
  queryTimeTracker(data){
    var apiUrl=this.dashboardUrl+"/tracker"
    return this.postQuery(apiUrl,data)
  }
  deleteTimeTracker(id){
    var apiUrl=this.dashboardUrl+"/tracker"+ "/" +id
    return this.deleteQuery(apiUrl)
  }

  updateMultipleDashboard(dashId,data){
    var apiUrl=this.dashboardUrl + "/" + dashId + "/lead" 
    return this.patchQuery(apiUrl,data)
  }
  createAutomationRule(data:any, dashId:string) {
    const apiUrl = this.dashboardUrl + '/' +dashId + '/' + this.automationrule;
    return this.postQuery(apiUrl, data);
  }
  getAutomationRules(data:any, dashId:string) {
    const apiUrl = this.dashboardUrl + '/' + this.automationrule+ "/" + this.queryUrl;
    return this.postQuery(apiUrl, data);
  }

  duplicateAutomationRule(data:any,) {
    const apiUrl = this.dashboardUrl + '/' +'automation'+ "/" + 'duplicate';
    return this.postQuery(apiUrl, data);
  }
   deleteAutomationRule(ruleID:string,dashId:any){
    var apiUrl=this.dashboardUrl + '/' +dashId + '/' + this.automationrule+ "/" +ruleID;
    return this.deleteQuery(apiUrl)
  }  
  publishRule(ruleID:string,dashId:any,data){
    // var apiUrl=this.dashboardUrl + '/' +dashId + '/' + this.automationrule+ "/" +ruleID+'/save';
    var apiUrl=this.dashboardUrl + '/' +dashId + '/' + this.automationrule+ "/" +ruleID;
    return this.putQuery(apiUrl,data)
  }

  updateAutomationRule(ruleID:string,dashId:any,data){
    var apiUrl=this.dashboardUrl + '/' +dashId + '/' + this.automationrule+ "/" +ruleID+'/save';
    return this.postQuery(apiUrl,data)
  }


  saveAutomationBlock(ruleID:string,dashId:any,data){
    var apiUrl=this.dashboardUrl + '/' +dashId + '/' + this.automationrule+ "/" +ruleID+'/automationblock';
    return this.postQuery(apiUrl,data)
  }


  saveAutomationBlockOrBranch(data){
    var apiUrl=this.dashboardUrl + '/' +'automation'+'/'+'savedblock'
    return this.postQuery(apiUrl,data)
  }

  getsavedAutomationBlockOrBranch(data){
    var apiUrl=this.dashboardUrl + '/' +'automation'+'/'+'savedblock'+ '/' + this.queryUrl
    return this.postQuery(apiUrl,data)
  }

  deletesavedAutomationBlockOrBranch(id){
    var apiUrl=this.dashboardUrl + '/' +'automation'+'/'+'savedblock'+ '/' + id
    return this.deleteQuery(apiUrl)
  }

  getBlocks(data){ 
    var apiUrl='dashboard/automationrule/automationblock/query';
    return this.postQuery(apiUrl,data)
  }
  getConditionFields(data){ 
    var apiUrl= this.dashboardUrl + '/' +'automation'+'/'+'conditionfield'+ '/' + this.queryUrl
    return this.postQuery(apiUrl,data)
  }

  getActivityLogs(data){ 
    var apiUrl= this.dashboardUrl + '/' + this.automationrule+'/'+'status'+ '/' + this.queryUrl
    return this.postQuery(apiUrl,data)
  }

  getExecutionDetails(data){ 
    var apiUrl= this.dashboardUrl + '/' + this.automationrule+'/'+'execution'+ '/' + this.queryUrl
    return this.postQuery(apiUrl,data)
  }
  getAuditLogs(data){
    var apiUrl=this.dashboardUrl  + '/' + "auditlogs"  + '/' + this.queryUrl
    return this.postQuery(apiUrl, data);
  }

  locationSearch(data){
    const apiUrl="api/location/search"
    return this.postQuery(apiUrl,data)
  }

  getEmailConnections(data){
    var apiUrl ="api/mail/connections/query"
    return this.postQuery(apiUrl, data)
  }
  connectEmail(data){
    var apiUrl ="api/mail/connect"
    return this.postQuery(apiUrl, data)
  }
  connectImapSmtpEmail(data){
    var apiUrl ="api/mail/imapsmtp/connect"
    return this.postQuery(apiUrl, data)
  }
  updateConnectImapSmtpEmail(data, connectionId){
    var apiUrl ="api/mail/imapsmtp/connect/"+connectionId
    return this.putQuery(apiUrl, data)
  }
  updateEmailConnection(data, connectionId){
    var apiUrl ="api/mail/connection/" + connectionId
    return this.putQuery(apiUrl, data)
  }
  deleteEmailConnection(connectionId){
    var apiUrl ="api/mail/connection/" + connectionId
    return this.deleteQuery(apiUrl)
  }

  createEmailSignature(data){
    var apiUrl ="emailsignature/"
    return this.postQuery(apiUrl, data)
  }
  updateEmailSignature(data, signatureId){
    var apiUrl ="emailsignature/" + signatureId 
    return this.putQuery(apiUrl, data)
  } 
  deleteEmailSignature(signatureId){
    var apiUrl ="emailsignature/" + signatureId
    return this.deleteQuery(apiUrl)
  }
  queryEmailSignatures(data){
    var apiUrl ="emailsignature/query"
    return this.postQuery(apiUrl, data)
  }
  saveUserPreference(data){
    var apiUrl='user/preferences'
    return this.postQuery(apiUrl,data)
  }
  queryUserPreference(){
    var apiUrl='user/preferences'
    return this.getQuery(apiUrl)
  }

  createFormulaForDashboardGraph(json, dashId) {
    let apiUrl = dashId ? this.dashboardUrl + '/' + dashId + '/' + 'formula' : this.dashboardUrl + '/' + 'formula'
    return this.postQuery(apiUrl, json)
  }

  copyGraphAndNumber(json,dashId,formula_id){
    let apiUrl=dashId ? this.dashboardUrl + '/'+dashId+'/'+'formula'+'/'+formula_id+'/'+'copy': this.dashboardUrl +'/'+'formula'+'/'+formula_id+'/'+'copy'
    return this.postQuery(apiUrl, json)
  }

  updateFormulaForDashboardGraph(json, dashId,formulaId) {
    let apiUrl = this.dashboardUrl + '/' + dashId + '/' + 'formula' + '/' + formulaId
    return this.putQuery(apiUrl, json)
  }

  deleteFormulaForDashboardGraph(dashId,formulaId) {
    let apiUrl = dashId ? this.dashboardUrl + '/' + dashId + '/' + 'formula' + '/' + formulaId :
    this.dashboardUrl + '/' + 'formula' + '/' + formulaId
    return this.deleteQuery(apiUrl)
  }

  getDashboardFormulas(dashId,json) {
    let apiUrl = dashId ? this.dashboardUrl + '/' + dashId + '/' + this.formulaUrl + '/' + this.queryUrl :
    this.dashboardUrl + '/' + this.formulaUrl + '/' + this.queryUrl
    return this.postQuery(apiUrl,json)
  }
  getChatCompletions(json?){
    // {
    //   "input_text":"Explain about indian economy.",
    //   "functions":{
    //   "tone":["Professional"],
    //   "style":["Informal"],
    //   "length":["Make Shorter"]
    //   }
    //   }

    // const AI_CHAT_FUNC = {
    //   TONE: ["Professional", "Casual", "Straightforward", "Confident", "Friendly", "Persuasive", "Neutral"],
    //   LENGTH: ["Concise", "Moderate", "Detailed", "Make Shorter", "Make Longer", "Summarize"],
    //   STYLE: ["Formal", "Informal", "Descriptive", "Analytical", "Narrative", "Technical"],
    //   DEPARTMENT: ["Marketing", "Sales", "Customer Success", "Engineering", "HR", "Operations"]
    //   }
    return this.postQuery(this.aiAssist,json)
  }

  getDashboardValueList(dashId,data){
    let apiUrl=dashId ? this.dashboardUrl + '/' + dashId + '/' + this.formulaUrl + '/' + 'eval' : this.dashboardUrl + '/' + this.formulaUrl + '/' + 'eval'
    return this.postQuery(apiUrl,data)
  }

  updateCordinate(dashId,data){
    let apiUrl=dashId ? this.dashboardUrl + '/' + dashId + '/' + this.formulaUrl + '/' + 'attributes' : this.dashboardUrl + '/' + this.formulaUrl + '/' + 'attributes'
    return this.postQuery(apiUrl,data)
  }

  createIndex(dashId,data){
    let apiUrl=this.dashboardUrl + '/' + dashId + '/' + 'automationindex'
    return this.postQuery(apiUrl,data)
  }
  automationTemplatesCategory(){
    let apiUrl=this.dashboardUrl + "/" + this.automationTemplatecategory
    return this.getQuery(apiUrl)
  }

  getAutomationTemplate(data){
    let apiUrl=this.dashboardUrl + '/automation/template/query'
    return this.postQuery(apiUrl,data)
  }

  createAppInstance(data){
    let apiUrl = 'user/externalapp/instance'
    return this.postQuery(apiUrl,data)
  }
  updateAppInstance(data,externalappId,instanceId){
    let apiUrl = 'user/externalapp/'+externalappId+'/instance/'+instanceId
    return this.patchQuery(apiUrl,data)
  }

  deleteAppInstance(externalappId,instanceId){
    let apiUrl = 'user/externalapp/'+externalappId+'/instance/'+instanceId
    return this.deleteQuery(apiUrl)
  }

  getQrCode() {
    let apiUrl =this.userUrl+ '/'+ this.generateQrCode
    console.log(apiUrl)
    return this.getQuery(apiUrl)
  }
  verifyTwoFactorAuthentication(data) {
    let apiUrl = this.userUrl + '/' + this.verifyTwoFactorAuth
    return this.postQuery(apiUrl, data)
  }

 generateApiKey(userId,data){
  let apiUrl=this.userUrl + '/' + 'apikey'
  return this.postQuery(apiUrl,data)
}
getApiKeyQuery(userId){
  let apiUrl=this.userUrl + '/' + 'apikey' +'/' + this.queryUrl
  return this.postQuery(apiUrl,{})
}
deleteApiKey(userId,apikeyId) {
  let apiUrl=this.userUrl + '/' + 'apikey' +'/' + apikeyId
  return this.deleteQuery(apiUrl)
}
getChannelQuery(data){
  let apiUrl=this.dashboardUrl + '/'+ this.channel + '/' + this.queryUrl
  return this.postQuery(apiUrl,data)
}
getChatUserQuery(data){
  let apiUrl=this.commentUrl + '/' + this.chatUser + '/' + this.queryUrl
  return this.postQuery(apiUrl,data)
}
createChannelQuery(dashId,data){
  let apiUrl=this.dashboardUrl + '/' + dashId + '/' + this.channel
  return this.postQuery(apiUrl,data)
}
updateChannelApi(dashId,data: any, channelId) {
  let apiUrl=this.dashboardUrl + '/' + dashId + '/' + this.channel + '/' + channelId
  return this.patchQuery(apiUrl, data);
}
deleteChannelApi(dashId,channelId) {
  let apiUrl=this.dashboardUrl + '/' + dashId + '/' + this.channel + '/' + channelId
  return this.deleteQuery(apiUrl)
}
getCatchHookQuery(data){
  let apiUrl= this.hooks + "/" + this.catch +"/" + this.queryUrl
  return this.postQuery(apiUrl,data)
}

logoutUser(){
  this.customStorageService.clear()
  this.router.navigate(["/login"])
}
  // get token if token not exit and set token value
  async getTokenIfNotExit(){
    if(!this.customStorageService.getItem("token")){
      var refreshTokenResult = await this.callRefreshToken(this.hostUrl + this.refreshTokenApi).toPromise();
      if (refreshTokenResult["status"] === 200) {
        this.setRefreshTokanOrSwichOrgDataToLocalStorage(refreshTokenResult)
      }
    }
  }
  getLoginCaptcha(clientId) {
    let apiUrl = this.captcha + '/' + this.captcha + '/?' + "clientId=" + clientId;
    return this.getQuery(apiUrl)
  }
  readCommentApi(data,dashId,leadId){
      var apiUrl=this.dashboardUrl+'/'+dashId+'/lead/'+ leadId +'/'+ this.commentUrl + '/'+ 'read'
      return this.postQuery(apiUrl,data)
    }



    updateTimeLineDate(data,leadId,dashId,timelineId){
      var apiUrl= this.organisationUrl+ '/' +this.dashboardUrl + '/' +dashId+'/lead/'+ leadId + '/' + 'timeline/'+ timelineId
      return this.patchQuery(apiUrl, data)
    }

    getLiveUpdateQuery(data){
      let apiUrl=this.apiUrl + '/' +  this.liveUpdate + '/' + this.queryUrl
      return this.postQuery(apiUrl,data)
    }

    createNewChatBotQuery(dashId){
      let apiUrl=this.dashboardUrl + '/' +  dashId + '/' + this.chatBot
      return this.getQuery(apiUrl)
    }

    getChatBotLists(dashId){
      let apiUrl=this.dashboardUrl + '/' +  dashId + '/' + this.chatBot + '/' + this.queryUrl
      return this.getQuery(apiUrl)
    }

    deleteChatBot(dashId,chatbotId){
      let apiUrl=this.dashboardUrl + '/' +dashId+'/'+this.chatBot+'/'+chatbotId
      return this.deleteQuery(apiUrl)
    }

    updateChatBot(dashId,chatbotId){
      let apiUrl=this.dashboardUrl + '/' +dashId+'/'+this.chatBot+'/'+chatbotId
      return this.getQuery(apiUrl)
    }

    updateChatBotField(dashId,chatbotId,body){
      let apiUrl=this.dashboardUrl + '/' +dashId+'/'+this.chatBot+'/'+chatbotId
      return this.patchQuery(apiUrl,body)
    }

    copyChatBot(dashId,data){
      let apiUrl=this.dashboardUrl + '/' +  dashId + '/' + this.chatBot + '/' + 'copy'
      return this.postQuery(apiUrl,data)
    }
    
    getFunctions() {
      var apiUrl = this.pronnelsupport + "/" + this.function + "/" + this.queryUrl;
      return this.postQuery(apiUrl,{});
    }

    oauthClientInfo(data){
      var apiUrl='oauth/client/query'
      return this.postQuery(apiUrl,data);
    }
    appOauthToken(data){
      var apiUrl= `${this.externalAppUrl}/sessiontoken`
      return this.postQuery(apiUrl, data)
    }

    authorize(apiUrl){
      return this.getQueryAuth(apiUrl)
    }

    decision(param,data,cookie){
      var apiUrl=this.decisionUrl
      return this.postQueryDecision(apiUrl,data,true,cookie);
    }

    getdecision(str){
      var apiUrl=this.decisionUrl+'?'+str
      return this.getQueryAuth(apiUrl)
    }

    getCalendarEvent(data){
      var apiUrl="calendar/event/query";
      return this.postQuery(apiUrl,data)
    }
    updateCalendarSettings(data,connectionId){
      var apiUrl= "api/mail/connection/"+ connectionId
      return this.putQuery(apiUrl, data);
    }
    createCalendarFilter(data){
      var apiUrl="calendar/filter"
      return this.postQuery(apiUrl,data)
    }
    updateCalendarFilter(data){
      var apiUrl="calendar/filter/reconnect"
      return this.postQuery(apiUrl,data)
    }
    deleteCalendarFilter(filterId){
      let apiUrl="calendar/filter/"+ filterId
      return this.deleteQuery(apiUrl)
    }
    getCalendarFilterBoard(data){
      var apiUrl="calendar/filter/query";
      return this.postQuery(apiUrl,data)
    }
    createCalendarEvent(data){
      var apiUrl="calendar/event";
      return this.postQuery(apiUrl,data)
    }

    updateCalendarEvent(data,eventId){
      var apiUrl="calendar/event/"+eventId;
      return this.patchQuery(apiUrl, data)
    }

    deleteCalendarEvent(eventId){
      var apiUrl="calendar/event/"+eventId;
      return this.deleteQuery(apiUrl)
    }

   getInternalChatQuery(data){
      var apiUrl ="board/chat/query"
      return this.postQuery(apiUrl, data)
    }

    createInternalChat(data){
      var apiUrl = "board/"+"/chat"
      return this.postQuery(apiUrl, data)
    }

    updateInternalChat(data,chatId){
      var apiUrl= "board/"+'/chat/'+ chatId
      return this.patchQuery(apiUrl, data)
    }

    deleteInternalChat(chatId){
      let apiUrl="board/"+'/chat/'+ chatId
      return this.deleteQuery(apiUrl)
    }

    getQueryAuth(formName: string,showErrorToast=true) {
      var token = this.customStorageService.getItem("token") || '';
      const headers = new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: token,
      });
      let requestInterceptIndex = this.requestIntercept(formName, this.getString, {});
      return this.http.get(this.hostUrl + formName, {headers,observe: 'response', withCredentials: true}).pipe(
        map((response: HttpResponse<any>) => {
          return response;
        }),catchError((err) => {
          console.log('error',err)
          if (err.status == this.error401) {
            return this.handleError(err,requestInterceptIndex);
          } else {
            return this.handleApiErrorExcept401(err,showErrorToast);
          }
        }));
    }

    postQueryDecision(apiUrl: string, data: any,showErrorToast=true,cookie?) {
      var token = this.customStorageService.getItem("token") || '';
      let headers= new HttpHeaders()
      .set("Content-Type", 'application/x-www-form-urlencoded')
      .set("Authorization", token)
      .set("Accept",'*/*')
      let urlEncodedData = new HttpParams();
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          urlEncodedData=urlEncodedData.set(key, data[key]);
        }
      }
      let requestInterceptIndex = this.requestIntercept(apiUrl, this.postString, urlEncodedData);
      return this.http
        .post(this.hostUrl + apiUrl, urlEncodedData.toString(),{headers,withCredentials: true,observe: 'response'}).pipe(
        map((response:any) => {
          return { result: response.body, status: response.status };
        })
        ,catchError((err) => {
          console.log('error',err)
          if (err.status == this.error401) {
            return this.handleError(err,requestInterceptIndex);
          } else {
            return this.handleApiErrorExcept401(err,showErrorToast);
          }
        }));
    }


    createExternalApp() {
      const apiUrl = this.externalAppUrl;
      return this.postQuery(apiUrl,{});
    }

    deleteApp(appId:string){    
      var apiUrl =`${this.externalAppUrl}/${appId}`
      return this.deleteQuery(apiUrl);
    }



    getExternalAppList(data) {
      const apiUrl = `${this.externalAppUrl}/query`;
      return this.postQuery(apiUrl,data);
    }

    getAppsCategories(){
    var apiUrl = 'user/externalapp/category/query'
    return this.getQuery(apiUrl);
  }

    createAppConnection(data) {
      const apiUrl = `${this.externalAppUrl}/instance`;
      return this.postQuery(apiUrl,data);
    }

    getAppInstance(data) {
      const apiUrl = `${this.externalAppUrl}/instance/query`;
      return this.postQuery(apiUrl,data);
    }

    getversionOfApp(data) {
      const apiUrl = `${this.externalAppUrl}/version/query`;
      return this.postQuery(apiUrl,data);
    }


    getAppInfo(data) {
      const apiUrl = `${this.externalAppUrl}/info/query`;
      return this.postQuery(apiUrl,data);
    }


    regenerateSecret(data){
      const apiUrl = `${this.externalAppUrl}/secret/regenerate`;
      return this.postQuery(apiUrl,data);
    }

    updateExternalApp(data,appId) {
      const apiUrl = `${this.externalAppUrl}/${appId}`;
      return this.patchQuery(apiUrl,data);
    }

    updateAppInfo(data,appId,versionId) {
      const apiUrl = `${this.externalAppUrl}/${appId}/version/${versionId}/info`;
      return this.patchQuery(apiUrl,data);
    }


    getfeatures(data){
      const apiUrl = `${this.externalAppUrl}/feature/query`;
      return this.postQuery(apiUrl,data)
    }

    createFeature(data,appId,versionId){
      const apiUrl = `${this.externalAppUrl}/${appId}/version/${versionId}/feature`;
      return this.postQuery(apiUrl,data);
    }

    updateFeature(data,appId,versionId, featureId){
      const apiUrl = `${this.externalAppUrl}/${appId}/version/${versionId}/feature/${featureId}`;
      return this.patchQuery(apiUrl,data);
    }

    deleteFeatureInApp(appId,versionId,featureId){
      const apiUrl = `${this.externalAppUrl}/${appId}/version/${versionId}/feature/${featureId}`;
      return this.deleteQuery(apiUrl);
    }

    getAppActions(data){
      const apiUrl = `${this.externalAppUrl}/automation/action/query`;
      return this.postQuery(apiUrl,data)
    }

    createActionInApp(data){
      const apiUrl = `${this.externalAppUrl}/automation/action`;
      return this.postQuery(apiUrl,data)
    }

    updateActionInApp(actionId,data){
      const apiUrl = `${this.externalAppUrl}/automation/action/${actionId}`;
      return this.patchQuery(apiUrl,data)
    }

    deleteActionInApp(actionId:string){    
      var apiUrl =`${this.externalAppUrl}/automation/action/${actionId}`
      return this.deleteQuery(apiUrl);
    }


    getAppTriggers(data){
      const apiUrl = `${this.externalAppUrl}/automation/trigger/query`;
      return this.postQuery(apiUrl,data)
    }

    createTriggerInApp(data){
      const apiUrl = `${this.externalAppUrl}/automation/trigger`;
      return this.postQuery(apiUrl,data)
    }

    updateTriggerInApp(triggerId,data){
      const apiUrl = `${this.externalAppUrl}/automation/trigger/${triggerId}`;
      return this.patchQuery(apiUrl,data)
    }

    deleteTriggerInApp(triggerId:string){    
      var apiUrl =`${this.externalAppUrl}/automation/trigger/${triggerId}`
      return this.deleteQuery(apiUrl);
    }

    getAppTemplate(data){
      const apiUrl = `${this.dashboardUrl}/automation/template/query`;
      return this.postQuery(apiUrl,data)
    }

    createAppTemplate(data){
      const apiUrl = `${this.dashboardUrl}/automation/template`;
      return this.postQuery(apiUrl,data)
    }


    updateAppTemplate(data,templateId:string){
      const apiUrl = `${this.dashboardUrl}/automation/template/${templateId}`;
      return this.patchQuery(apiUrl,data)
    }

    createVersion(actionId:string){
      const apiUrl = `${this.externalAppUrl}/${actionId}/version`;
      return this.postQuery(apiUrl,{})
    }


    deleteVersionInApp(versionId:string,appId){    
      var apiUrl =`${this.externalAppUrl}/${appId}/version/${versionId}`
      return this.deleteQuery(apiUrl);
    }

    getAppCategories(){
      var apiUrl = `${this.externalAppUrl}/category/query`
      return this.getQuery(apiUrl);
    }

    appUrlForFieldData(url: string,body={}) {
      // const headers = new HttpHeaders({ });
      return this.http
        .post(url, body)
        .map((response:any) => {
          return { res: response };
        });
    }


  addLeadtoCreateUpdateStack(id,key){
    if(key && !this.getModalOpenStatus(id)){
      if(!this.createOrUpdateLeadIdStack[key].includes(id)){
        this.createOrUpdateLeadIdStack[key].push(id)
      }
    }
  }

  getCreateOrUpdateStack(key){
    return this.createOrUpdateLeadIdStack[key]
  }


  removeLeadfromCreateUpdateStack(id,key){
    if(key){
      const index = this.createOrUpdateLeadIdStack[key].indexOf(id) 
      if(index>-1) {
        this.createOrUpdateLeadIdStack[key].splice(index, 1);
      }
    }
  }

  clearCreateUpdateStack(){
    this.createOrUpdateLeadIdStack={create:[],update:[]}
  }


  changeItemModalStatus(id){
    if(!this.itemModalOpen.includes(id)){
      this.itemModalOpen.push(id)
    }
  }

  removedLeadFromModalStatus(id){
    const index = this.itemModalOpen.indexOf(id);
    if (index !== -1) {
        this.itemModalOpen.splice(index, 1);
    } 
  }
  
  getModalOpenStatus(id){
    const isPresent = this.itemModalOpen.includes(id);
    return isPresent
  }
    
    htmlToPdfConvert(user){
      this.postQuery
      var formName='api/file/html-to-pdf'
      var token = this.customStorageService.getItem("token") || '';
      let headers= new HttpHeaders()
        .set("Content-Type", "application/json")
        .set("Authorization", token)
      let requestInterceptIndex = this.requestIntercept(formName, this.postString, user);
      return this.http
        .post(this.hostUrl + formName, JSON.stringify(user),{headers,observe: 'response', responseType:'blob'}).pipe(
        map((response:any) => {
          return { result: response.body, status: response.status };
        })
        ,catchError((err) => {
          if (err.status == this.error401) {
            return this.handleError(err,requestInterceptIndex);
          } else {
            return this.handleApiErrorExcept401(err,true);
          }
        }));
    }

    getScheduleLists(data){
      let apiUrl=this.dashboardUrl+ '/' + this.schedule + '/' + this.queryUrl
      return this.postQuery(apiUrl,data)
    }

    updateScheduleQuery(data: any, dashId,scheduleId) {
      var apiUrl =this.dashboardUrl + '/' +dashId+'/'+this.schedule+'/'+scheduleId
      return this.patchQuery(apiUrl, data);
    }

    deleteSchedule(dashId,scheduleId){
      let apiUrl=this.dashboardUrl + '/' +dashId+'/'+this.schedule+'/'+scheduleId
      return this.deleteQuery(apiUrl)
    }

    createSchedule(dashId,data){
      let apiUrl=this.dashboardUrl+ '/' +dashId+'/' + this.schedule 
      return this.postQuery(apiUrl,data)
    }

    appointmentFormSlots(formId,data){
      let apiUrl='form'+ '/' +formId+'/' + 'data/'+'query'
      return this.postQuery(apiUrl,data)
    }


    appSampleResponseUrl(url: string,body={}) {
      // const headers = new HttpHeaders({ });
      return this.http
        .get(url, body)
        .map((response:any) => {
          return { res: response, status: response.status };
        });
    }

    createUserSettings(data) {
      let apiUrl = this.organisationUrl + '/' + this.userUrl + '/' + this.settingsUrl
      return this.patchQuery(apiUrl, data);
    }

    getUserSettings() {
      let apiUrl = this.organisationUrl + '/' + this.userUrl + '/' + this.settingsUrl + '/' + this.queryUrl
      return this.getQuery(apiUrl);
    }

    upgradeSubscription(data) {
      let apiUrl = 'plans/update'
      return this.patchQuery(apiUrl, data);
    }

    buySubscription(data) {
      let apiUrl = 'plans/generate'
      return this.postQuery(apiUrl, data);
    }

    payMentHistory(){
      let apiUrl = 'plans/transactions'
      return this.getQuery(apiUrl);
    }

    cancelSubscription(data){
      let apiUrl = 'plans/cancel'
      return this.postQuery(apiUrl,data);
    }
    createBoardList(dashId,data){
      let apiUrl = this.dashboardUrl+ '/' +dashId+'/' + this.listUrl 
      return this.postQuery(apiUrl,data);
    }
    updateBoardList(dashId, listId, data){
      let apiUrl = this.dashboardUrl+ '/' +dashId+'/' + this.listUrl + '/' + listId;
      return this.patchQuery(apiUrl,data);
    }
    getBoardList(data){
      let apiUrl = this.dashboardUrl+ '/' + this.listUrl + '/' + this.queryUrl;
      return this.postQuery(apiUrl,data);
    }
    deleteBoardList(dashId, listId) {
      let apiUrl = this.dashboardUrl + '/' + dashId +'/' + this.listUrl + '/' + listId;
      return this.deleteQuery(apiUrl);
    }

    createWorkFolderVariable(data:any, dashId:string) {
      const apiUrl = this.dashboardUrl + '/' +dashId + '/' + 'variable';
      return this.postQuery(apiUrl, data);
    }
    getWorkFolderVariables(data:any, dashId:string) {
      const apiUrl = this.dashboardUrl + '/' +dashId + '/' + 'variable'+ "/" + this.queryUrl;
      return this.postQuery(apiUrl, data);
    }
     deleteWorkFolderVariable(variableId:string,dashId:any){
      var apiUrl=this.dashboardUrl + '/' +dashId + '/' + 'variable' + "/" +variableId;
      return this.deleteQuery(apiUrl)
    }  
  
    updateWorkFolderVariable(variableId:string,dashId:any,data){
      var apiUrl=this.dashboardUrl + '/' +dashId + '/' + 'variable' + "/" +variableId;
      return this.patchQuery(apiUrl,data)
    }
    createKnowledgeBase(dashId,data){
      let apiUrl=this.dashboardUrl+ '/' +dashId+'/' + this.knowledgebase 
      return this.postQuery(apiUrl,data)
    }
    createKnowledgeBaseFile(dashId,knowledgebaseId,data){
      let apiUrl=this.dashboardUrl+ '/' +dashId+'/' + this.knowledgebase + '/' + knowledgebaseId + '/' +'file'
      return this.postQuery(apiUrl,data)
    }

    getKnowledgeBase(data){
      let apiUrl=this.dashboardUrl+ '/'+ this.knowledgebase+"/"+this.queryUrl
      return this.postQuery(apiUrl,data);
    }
    getKnowledgeBaseFile(data){
      let apiUrl=this.dashboardUrl+ '/knowledgebase/file/query'
      return this.postQuery(apiUrl,data);
    }

    deleteKnowledgeBaseFile(knowledgeId,fileId:string,dashId:any){
      var apiUrl=this.dashboardUrl + '/' +dashId + '/knowledgebase/'+ knowledgeId +'/file/'+fileId
      return this.deleteQuery(apiUrl)
    }  
    deleteKnowledgeBase(knowledgeId,dashId:any){
      var apiUrl=this.dashboardUrl + '/' +dashId + '/knowledgebase/'+ knowledgeId
      return this.deleteQuery(apiUrl)
    }  
    updateKnowledgeBaseFile(dashId,knowledgebaseId,data,fileId){
      let apiUrl=this.dashboardUrl+ '/' +dashId+'/' + this.knowledgebase + '/' + knowledgebaseId + '/' +'file/'+fileId
      return this.patchQuery(apiUrl,data)
    }

    updateKnowledgeBase(knowledgeId,dashId:any,data){
      var apiUrl=this.dashboardUrl + '/' +dashId + '/knowledgebase/'+ knowledgeId
      return this.patchQuery(apiUrl,data)
    }  

    //start work schedule settings apis
    listOfWorkSchedule(data?){
      let apiUrl='work/schedule/query'
      return this.postQuery(apiUrl,data);
    }
    createNewWorkSchedule(data){
      let apiUrl='work/schedule'
      return this.postQuery(apiUrl,data);
    }
    deleteWorkSchedule(id){
      var apiUrl='work/schedule/'+ id
      return this.deleteQuery(apiUrl)
    }
    updateWorkSchedule(id,data){
      var apiUrl='work/schedule/'+ id
      return this.patchQuery(apiUrl,data)
    }
    updateUserWorkInstance(data){
      var apiUrl='work/instance'
      return this.putQuery(apiUrl,data)
    }
    queryUserWorkInstance(data){
      let apiUrl='work/instance/query'
      return this.postQuery(apiUrl,data);
    }
    getInternalChatbot(data){
      let apiUrl = 'dashboard/internal-chatbot/query';
      return this.postQuery(apiUrl,data);
    }
    getChatForInternalBot(chatbotId){
      let apiUrl = 'dashboard/chatbot/'+ chatbotId +'/initiate'
      return this.getQuery(apiUrl)
    }
    //ends here

    // Knowledge base chat bots apis
    createKnowledgeBaseChatBot(dashId,data){
      let apiUrl=this.dashboardUrl+ '/' +dashId+'/'+this.chatBot
      return this.postQuery(apiUrl,data);
    }
    queryKnowledgeBaseChatBot(data){
      let apiUrl=this.dashboardUrl+ '/' +this.chatBot + '/' + this.queryUrl;
      return this.postQuery(apiUrl,data);
    }

    understandUserRequirement(data) {
      let apiUrl = this.aiWfgenerationUrl + '/requirement';
      return this.postQuery(apiUrl, data);
    }

    crossQuestion(data, threadId) {
      let apiUrl = this.aiWfgenerationUrl + '/' + threadId + '/requirementupdates';
      return this.postQuery(apiUrl, data);
    }

    autoGenerateBoard(data, threadId) {
      let apiUrl = this.aiWfgenerationUrl + '/' + threadId + '/generate';
      return this.postQuery(apiUrl, data);
    }

}
