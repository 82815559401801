import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { CommonUtils } from 'app/services/CommonUtils.service';
import { DashboardUtilsService } from 'app/services/dashboard-utils.service';

@Component({
  selector: 'user-cell',
  changeDetection:ChangeDetectionStrategy.OnPush,
  templateUrl: './user-cell.component.html',
  styleUrls: ['./user-cell.component.scss']
})
export class UserCellComponent implements OnInit  {
  params:any={};
  isCreatedBy: boolean = false
  editable: boolean = false
  orgUserJson:any={}
  itemInfo:any={}
  userIdList:any=[]

  constructor(private dashboardUtils:DashboardUtilsService,private cdr:ChangeDetectorRef){}

  async ngOnInit() {
    this.orgUserJson = await this.dashboardUtils.getOrgUsers()
    this.onUpdate(this.params)
  }

  onUpdate(params: any){
    this.itemInfo=params?.item || {}
    this.editable=params?.editable
    this.userIdList=[]
    if(this.params?.columnParams?.customField?.type == 'MultiUser' && this.params?.value)this.userIdList=this.params?.value || []
    else if(this.params?.value)this.userIdList=[this.params?.value]
    this.cdr.detectChanges() 
    
  }
  changesDetect(){
    setTimeout(() => {
     this.cdr.detectChanges() 
    },2);
  }
  changeAssignee(userId){
    //  let obj={[this.params.field]:userId}
    // this.params?.gridTableRef?.changedCellValue({value:{[this.params.field]:userId},params:this.params});
  }
  addUsers(users){  
      if(!this.userIdList?.includes(users._id)){
        this.params.value=this.params?.columnParams?.customField?.type == 'MultiUser'?  [...this.userIdList,users._id] :  users._id
        this.params?.gridTableRef?.changedCellValue({value:this.params.value,customFieldType:this.params.columnParams?.isCustomField,params:this.params});
        this.onUpdate(this.params)  
      }   
  }
  removeUser(userId){
    this.userIdList=this.userIdList.filter(id=>id!=userId)
    this.params.value =this.userIdList?.length ? this.userIdList : null
    this.params?.gridTableRef?.changedCellValue({value:this.params.value,customFieldType:this.params.columnParams?.isCustomField,params:this.params});
    this.onUpdate(this.params) 
  }
}
