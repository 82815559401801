<div class="cell-box selection-cell cursorPointer" [ngStyle]="{'background-color':selectedValue[0]?.color ? selectedValue[0].color : '#ffffff'}" [placement]="['bottom','bottom-right','bottom-left','auto']" #selectionPopover="ngbPopover" container="body"  popoverClass="gridtableCell" [ngbPopover]="['Checkbox','Radiobutton'].includes(customtype) ? sectionTemplate : dropdownTemplate" [autoClose]="'outside'" (shown)="searchText='';editable && customtype!='MultiSelect' && handeKeyInput('Add')" (hidden)="editable && handeKeyInput()">
  <div class="cell-box-inner">
    <div class="left-box">
      <div class="cell-input-box">
        <span class="text-select" [style.opacity]="selectedValue.length ? 1 : 0.5">
          <ng-container *ngIf="selectedValue.length;else selction">
            <!-- *ngFor="let value of selectedValue;let index=index" -->
            <ng-container ><div class="option-changes"><div class="text-content-overflow" [ngStyle]="{'color': commonUtils.getContrastColor(selectedValue[0]?.color)}">{{selectedValue[0].value}}</div></div></ng-container>
          </ng-container>
          <ng-template #selction>select value</ng-template>
        </span>
      </div>
      <div class="cell-value-box"><ng-container><div class="option-changes"><div class="text-content-overflow" [ngStyle]="{'color': commonUtils.getContrastColor(selectedValue[0]?.color)}">{{selectedValue[0]?.value}}</div></div></ng-container></div>
    </div>
    <!-- <div class="right-box">
      <svg viewBox="0 0 20 20" fill="blue" width="16" height="16" aria-hidden="true" aria-label="Open" class="icon_component icon_component--no-focus-style"><path d="M12.5148 2.49976C12.1212 2.49976 11.8022 2.82125 11.8022 3.21784 11.8022 3.61443 12.1212 3.93593 12.5148 3.93593H14.6466L10.7369 8.15315C10.4682 8.44295 10.4836 8.89735 10.7711 9.16809 11.0587 9.43883 11.5096 9.42339 11.7783 9.1336L15.5746 5.03862V7.28699C15.5746 7.68358 15.8936 8.00507 16.2872 8.00507 16.6807 8.00507 16.9997 7.68358 16.9997 7.28699V3.23812C17.0052 3.03946 16.9293 2.83946 16.7739 2.69313 16.6366 2.56389 16.4621 2.49986 16.288 2.49976L12.5148 2.49976zM9.26311 11.8464C9.53177 11.5566 9.51644 11.1022 9.22888 10.8314 8.94131 10.5607 8.4904 10.5761 8.22174 10.8659L4.42513 14.9612V12.7124C4.42513 12.3158 4.1061 11.9943 3.71256 11.9943 3.31903 11.9943 3 12.3158 3 12.7124V16.7815C3 16.8649 3.01412 16.945 3.04007 17.0195 3.07689 17.1255 3.13902 17.2244 3.22611 17.3064 3.36743 17.4394 3.54818 17.5034 3.72738 17.4996H7.48496C7.8785 17.4996 8.19752 17.1781 8.19752 16.7815 8.19752 16.3849 7.8785 16.0634 7.48496 16.0634H5.35358L9.26311 11.8464z"></path></svg>
    </div>    -->
  </div>
  <ng-container *ngIf="selectedValue.length>1"><div style="width:2px;background-color: #ffffff;height: 39px;"></div></ng-container>
  <ng-container *ngIf="selectedValue.length>1"><div class="moreValues" role="button"><div style="color: #ffffff;padding-top: 5px;"> +{{selectedValue.length-1}}</div></div></ng-container>
</div>
<!-- used checkbox and radio -->
<ng-template #sectionTemplate>
  <div class="selection-option-wrapper" #container>
    <div *ngIf="selectedValue?.length && editable && !params?.columnParams?.customField?.mandatory" class="clear-btn"  (click)="onSetectValue(null);selectionPopover?.close()">
      <span [class.selected-field]="selectedIndex==-1" class="clear-selection">clear selection</span>
    </div>
    <ng-container *ngFor="let data of arrayOfCheckboxOptions;let index=index">
      <div class="option-box cursorPointer" [class.selected-field]="selectedIndex==index" (mouseenter)="selectedIndex=index" [ngStyle]="{'background-color':data?.color ? data.color : '#ffffff'}">
          <div class="selection-box">
            <ng-container *ngIf="customtype == 'Checkbox'else radio"><input type="checkbox" [(ngModel)]="data.checkinput" [checked]="data.checkinput" class="mr-1" (change)="onSetectValue(data.value)" [readonly]="!editable" [disabled]="!editable" /> </ng-container>
            <ng-template #radio><input type="radio" [name]="params?.field" [checked]="data.checkinput" class="mr-1" (change)="onSetectValue(data.value)" [readonly]="!editable" [disabled]="!editable" /> </ng-template>
          </div>
          <div class="value-box" style="width:88%" [ngStyle]="{'color': commonUtils.getContrastColor(data?.color)}">
            {{data.value}}
          </div>
      </div>
    </ng-container>
  </div>
</ng-template>
<!-- used dropdown and multi-select  -->
<ng-template #dropdownTemplate>
  <div class="selection-option-wrapper" style="height:230px">
    <ng-container *ngIf="customtype=='Dropdown'">
        <div class="search-box">
        <input id="search-input" style="border:none; width:90%" class="search" placeholder="search" [(ngModel)]="searchText" (input)="onSearch(searchText)">
        <span class="m-1" style="width:10%"><i class="fa fa-search"></i></span>
      </div>
      <div class="options-container" #container>

        <cdk-virtual-scroll-viewport itemSize="10" style="height: 100%;"> 
          <div *cdkVirtualFor="let data of cloneCheckboxOptions;let index=index" class="cursorPointer">
            <div class="option-box cursorPointer hoverOnValue" style="background-color: #ffffff;border-color: #ffffff;"  [class.selected-field]="selectedIndex==index && false" (mouseenter)="selectedIndex=index" [class.selected-value]="data.checkinput" [class.disabled_selection]="!editable" (click)="onSetectValue(data?.value)">
              <div style="width: 96%;display: flex;align-items: center;border-radius: 6px;" [ngStyle]="{'background-color':data.color ? data.color : '#ffffff','color':commonUtils.getContrastColor(data?.color)}">
                <div class="value-box-drop" style="width: 85%;padding-left:5px">
                  {{data.value}}
                </div>
                <div style="width:10%" *ngIf="data.checkinput && editable && (selectedValue.length<2 && !params?.columnParams?.customField?.mandatory || selectedValue.length>1)">
                  <span class="remove-btn-drop" (click)="$event.stopPropagation();onSetectValue(null)"><i class="fa fa-times" ></i></span>
                </div>
              </div>
            </div>
          </div>
        </cdk-virtual-scroll-viewport>

      </div>
    </ng-container>
    <ng-container *ngIf="customtype=='MultiSelect'">
        <div class="dropdown-box hoverNgSelectP multi-padding" [class.multiselect-dropdown]="customtype=='MultiSelect'">
        <ng-select 
        placeholder="{{'placeholders.select' | translate}}" 
        [virtualScroll]="true" 
        [items]="arrayOfCheckboxOptions" class="custom" 
        tabindex="0" 
        [readonly]="!editable" [multiple]="true" [closeOnSelect]="true"                       
        [clearable]="false"  [(ngModel)]="selectedValue"
        (change)="onDropdownSelect()">
        <ng-template ng-option-tmp let-item="item">
          <div [style.background-color]="item.color || '#ffffff'" style="height: 34px;display: flex;align-items: center;border-radius: 6px;padding: 1px 8px !important;" [ngStyle]="{'color': commonUtils.getContrastColor(item?.color)}" class="custom-option ">
            <div class="overflow-text-in-grid">{{ item.value }}</div>
          </div>
        </ng-template>
        <ng-template ng-multi-label-tmp let-items="item" let-clear="clear">
          <div class="custom-multi-select-label" style="padding-bottom: 5px;display: flex;">
            <div *ngFor="let item of items" class="custom-multi-select-item" style="display: flex;padding-bottom: 3px;padding-right: 5px;">
              <div [style.background-color]="item.color || '#ffffff'" class="custom-options-for-multi">
                <div style="display: flex;">
                  <div class="text-over-flow" style="padding-left: 1px;">{{ item.value }}</div> <div class="clear-icon" (click)="removeItem(item, clear)"><i class="fa fa-times" ></i></div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-select>

      </div>
      <div class="value-wrapper">
        <div class="cell-value-box hoverOnValue">
          <div class="value-wrapper-box selcted-header">
            <span class="valueText"><b>Selected values</b></span>
            <span class="cursorPointer text-danger" *ngIf="selectedValue.length>1 && !params?.columnParams?.customField?.mandatory && editable" (click)="onDropdownSelect(-1)">clear all</span>
          </div>
          <div *ngIf="selectedValue.length==0" class="empty-selection-box">No value selected</div>
          <ng-container *ngFor="let value of selectedValue;let index=index">
            <div class="value-wrapper-box" style="width:95%;margin-left: 5px;margin-bottom: 1px;" [ngStyle]="{'background-color':value?.color ? value.color : '#ffffff'}">
              <span class="valueText" [ngStyle]="{'color': commonUtils.getContrastColor(value?.color)}">{{value?.value}}</span>
              <span class="remove-btn" *ngIf="editable && (selectedValue.length<2 && !params?.columnParams?.customField?.mandatory || selectedValue.length>1)"><i class="fa fa-times" (click)="onDropdownSelect(index)"></i></span>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>