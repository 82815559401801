
<div class="columnHeader" *ngIf="isHeader && !indexEnable && !randomEnable">
   <button class="variableBox" *ngIf="!dashboardVariable " [ngClass]="!isFunctionEnable ? 'selectedFunction' : 'variableBox'" (click)="isFunctionEnable=false;" [disabled]="selectedFunction ? true : false">{{ '\{\{ \}\}'}} Columns</button>
   <button class="variableBox" *ngIf="dashboardVariable " [ngClass]="!isFunctionEnable ? 'selectedFunction' : 'variableBox'" (click)="isFunctionEnable=false;" [disabled]="selectedFunction ? true : false">Variables</button>
   <button class="variableBox" [ngClass]="isFunctionEnable ? 'selectedFunction' : 'variableBox'" (click)="isFunctionEnable=true;" [ngbPopover]="functionPopover" 
   #popFunction="ngbPopover" placement="auto" [autoClose]="'outside'">Functions(fx)</button>
</div> 

<div style="width:300px;min-height:210px; max-height: 300px;overflow-y: auto;overflow-x: hidden;" (click)="$event.stopPropagation()" [ngClass]="{'con' : indexEnable}"
 *ngIf="!selectedFunction && ((!dashboardVariable && !appTemplateCall) || valueTemplateVarArr?.length || dashVariableArr?.length )">
 <ng-container *ngIf="!selectedFunction  && !numberTypeGraph && !dashboardVariable && !appTemplateCall">
    <div *ngIf="!isTriggeredNonItemField  && !indexEnable && !randomEnable && !mirrorEnable  && triggerType!='CATCHHOOK_RESTHOOK'">
        <label>Autopopulate fields from your board</label>
        <div style="display: flex;width: 100%;column-gap: 10px;row-gap:10px;flex-direction: col;flex-wrap: wrap;margin-bottom:5px;margin-top:10px;padding-bottom: 10px;border-bottom: 1px solid #d9d9d9;" *ngIf="((columnsFor!='to' && columnsFor!='cc' && columnsFor!='bcc') || (filterCall && customfieldType=='Text' || customfieldType=='Textarea')) ">

          <ng-container *ngFor="let field of predefinedFieldsArr">
            <span class="predefineBox" (click)="selectedColumnValue('triggeredItem.'+field.key,field.display_key)">{{field.display_key}}</span>
            <span class="predefineBox" *ngIf="assignVariableCall && !['board_id','seq_id','create_date','created_by'].includes(field.key)" (click)="selectedColumnValue('triggeredItemBeforeChange.'+field.key,field.display_key + ' Before Change')">{{field.display_key}} Before Change</span>
            <span class="predefineBox" *ngIf="assignVariableCall && !['board_id','seq_id','create_date','created_by'].includes(field.key)" (click)="selectedColumnValue('triggeredItemAfterChange.'+field.key,field.display_key + ' After Change')">{{field.display_key}} After Change</span>

          </ng-container>

        </div> 
        <div style="display: flex;width: 100%;column-gap: 10px;row-gap:10px;flex-direction: col;flex-wrap: wrap;margin-bottom:5px;margin-top:10px;padding-bottom: 10px;border-bottom: 1px solid #d9d9d9;" *ngIf="(columnsFor=='to' || columnsFor=='cc' || columnsFor=='bcc')">

          <ng-container *ngFor="let field of predefinedFieldsArr">
            <span class="predefineBox" *ngIf="['assigned_to','created_by','updated_by','assigned_by_user','lead_colloborators_users'].includes(field.key)"(click)="selectedColumnValue('triggeredItem.'+field.key,field.display_key)">{{field.display_key}}</span>
            <span class="predefineBox" *ngIf="assignVariableCall && ['assigned_to','created_by','updated_by','assigned_by_user','lead_colloborators_users'].includes(field.key)" (click)="selectedColumnValue('triggeredItemBeforeChange.'+field.key,field.display_key + ' Before Change')">{{field.display_key}} Before Change</span>
            <span class="predefineBox" *ngIf="assignVariableCall && ['assigned_to','created_by','updated_by','assigned_by_user','lead_colloborators_users'].includes(field.key)" (click)="selectedColumnValue('triggeredItemAfterChange.'+field.key,field.display_key + ' After Change')">{{field.display_key}} After Change</span>
          </ng-container>
        </div>           
          <div style="display: flex;width: 100%;column-gap: 10px;row-gap:10px;flex-direction: col;flex-wrap: wrap;margin-top:10px;border-bottom: 1px solid #d9d9d9;padding-bottom: 10px;">
            <label style="width:100%;margin-bottom: 0px;">Custom Fields</label>
            <ng-container *ngFor="let field of customFieldArr">
              <span class="predefineBox" *ngIf="((columnsFor=='message' || columnsFor=='subject' || !columnsFor) && (!['Board','Mirror','Textarea','MultiSelect','MultiUser','SingleImage','MultipleImage','SingleFile','MultipleFile','SingleVideo','MultipleVideo','Checkbox','CheckList','SingleAudio','MultipleAudio','MultiUrl','Button'].includes(field.type))) || ((columnsFor=='to' || columnsFor=='cc' || columnsFor=='bcc') && (field.type=='Email' || field.type=='SingleUser'))" (click)="selectedColumnValue('triggeredItem.custom_fields.'+field.key,field.display_key,field.key,field.type)">{{field?.display_key}}</span>
              <span class="predefineBox" *ngIf="assignVariableCall && ((columnsFor=='message' || columnsFor=='subject' || !columnsFor) && (!['Board','Mirror','Textarea','MultiSelect','MultiUser','SingleImage','MultipleImage','SingleFile','MultipleFile','SingleVideo','MultipleVideo','Checkbox','CheckList','SingleAudio','MultipleAudio','MultiUrl','Button'].includes(field.type))) || ((columnsFor=='to' || columnsFor=='cc' || columnsFor=='bcc') && (field.type=='Email' || field.type=='SingleUser'))" (click)="selectedColumnValue('triggeredItemBeforeChange.custom_fields.'+field.key,field.display_key + ' Before Change',field.key,field.type) ">{{field?.display_key}} Before Change</span>
              <span class="predefineBox" *ngIf="assignVariableCall && ((columnsFor=='message' || columnsFor=='subject' || !columnsFor) && (!['Board','Mirror','Textarea','MultiSelect','MultiUser','SingleImage','MultipleImage','SingleFile','MultipleFile','SingleVideo','MultipleVideo','Checkbox','CheckList','SingleAudio','MultipleAudio','MultiUrl','Button'].includes(field.type))) || ((columnsFor=='to' || columnsFor=='cc' || columnsFor=='bcc') && (field.type=='Email' || field.type=='SingleUser'))" (click)="selectedColumnValue('triggeredItemAfterChange.custom_fields.'+field.key,field.display_key+ ' After Change',field.key,field.type )">{{field?.display_key}} After Change</span>

              </ng-container>
            </div>   
            <div style="display: flex;width: 100%;column-gap: 10px;row-gap:10px;flex-direction: col;flex-wrap: wrap;margin-top:10px;" *ngIf="mirrorCustomFields?.length">
              <label style="width:100%;margin-bottom: 0px;">Mirror Fields</label>
              <ng-container *ngFor="let fields of mirrorCustomFields">
                <span class="predefineBox" *ngIf="((columnsFor=='message' || columnsFor=='subject' || !columnsFor) && (!['Board','Mirror','Textarea','MultiSelect','MultiUser','SingleImage','MultipleImage','SingleFile','MultipleFile','SingleVideo','MultipleVideo','Checkbox','CheckList','SingleAudio','MultipleAudio','MultiUrl','Button'].includes(fields.mirror_column_type) )) || ((columnsFor=='to' || columnsFor=='cc' || columnsFor=='bcc') && (fields.mirror_column_type=='Email' || fields.mirror_column_type=='SingleUser'  || fields.mirror_column_type=='Assignee'))" (click)="$event.stopPropagation();selectedColumnValue('triggeredItem.custom_fields.'+fields.key,fields.display_key,fields.key,fields.type)">{{fields?.display_key}}</span>
                </ng-container>
              </div>  
      </div>
      <div *ngIf="item && !filterCall && item.targetItem && !indexEnable && !randomEnable && !mirrorEnable && triggerType!='CATCHHOOK_RESTHOOK'" style="margin-top:10px;border-top:2px solid black;padding-top:5px;">
        <label>Autopopulate fields from Filtered Item</label>
        <div style="display: flex;width: 100%;column-gap: 10px;row-gap:10px;flex-direction: col;flex-wrap: wrap;margin-bottom:5px;margin-top:10px;padding-bottom: 10px;border-bottom: 1px solid #d9d9d9;" *ngIf="columnsFor!='to' && columnsFor!='cc' && columnsFor!='bcc'">
          <ng-container *ngFor="let field of predefinedFieldsArr">
            <span class="predefineBox" (click)="$event.stopPropagation();selectedColumnValue('targetItem.'+field.key,field.display_key)">{{field.display_key}}</span>
          </ng-container>
        </div>  
        <div style="display: flex;width: 100%;column-gap: 10px;row-gap:10px;flex-direction: col;flex-wrap: wrap;margin-bottom:5px;margin-top:10px;padding-bottom: 10px;border-bottom: 1px solid #d9d9d9;"*ngIf="(columnsFor=='to' || columnsFor=='cc' || columnsFor=='bcc')">
          <ng-container *ngFor="let field of predefinedFieldsArr">
            <span class="predefineBox" *ngIf="['assigned_to','created_by','updated_by','assigned_by_user','lead_colloborators_users'].includes(field.key)"(click)="selectedColumnValue('targetItem.'+field.key,field.display_key)">{{field.display_key}}</span>
          </ng-container>
       
        </div>            
        <div style="display: flex;width: 100%;column-gap: 10px;row-gap:10px;flex-direction: col;flex-wrap: wrap;margin-top:10px;border-bottom: 1px solid #d9d9d9;padding-bottom: 10px;">
          <label style="width:100%;margin-bottom: 0px;">Custom Fields</label>
          <ng-container *ngFor="let data of item?.customFieldForTargetBoard">
            <span class="predefineBox" *ngIf="((columnsFor=='message' || columnsFor=='subject' || !columnsFor) && (!['Board','Mirror','Textarea','MultiSelect','MultiUser','SingleImage','MultipleImage','SingleFile','MultipleFile','SingleVideo','MultipleVideo','Checkbox','CheckList','SingleAudio','MultipleAudio','MultiUrl','Button'].includes(data.type))) || ((columnsFor=='to' || columnsFor=='cc' || columnsFor=='bcc') && (data.type=='Email'))" (click)="selectedColumnValue('targetItem.custom_fields.'+data.key,data.display_key,data.key,data.type)">{{data?.display_key}}</span>
            </ng-container>
          </div> 
          <div style="display: flex;width: 100%;column-gap: 10px;row-gap:10px;flex-direction: col;flex-wrap: wrap;margin-top:10px;border-bottom: 1px solid #d9d9d9;padding-bottom: 10px;">
            <label style="width:100%;margin-bottom: 0px;">Mirror Fields</label>
            <ng-container *ngFor="let fields of mirrorCustomFieldsForTarget">
              <span class="predefineBox" *ngIf="((columnsFor=='message' || columnsFor=='subject' || !columnsFor) && (fields.mirror_column_type!='Textarea' && fields.mirror_column_type!='SingleImage' && fields.mirror_column_type!='MultipleImage' && fields.mirror_column_type!='SingleFile'
              && fields.mirror_column_type!='MultipleFile' && fields.mirror_column_type!='SingleVideo' && fields.mirror_column_type!='MultipleVideo' && fields.mirror_column_type!='SingleAudio' && fields.mirror_column_type!='MultipleAudio' && fields.mirror_column_type!='Button')) || ((columnsFor=='to' || columnsFor=='cc' || columnsFor=='bcc') && (fields.mirror_column_type=='Email' || fields.mirror_column_type=='SingleUser'  || fields.mirror_column_type=='Assignee'))" (click)="$event.stopPropagation();selectedColumnValue('targetItem.custom_fields.'+fields.key,fields.display_key,fields.key,fields.type);" >{{fields?.display_key}}</span>
              </ng-container>
            </div>   
          
      </div> 
      <div *ngIf="(triggerType=='CATCHHOOK_RESTHOOK' || triggerType=='APP_TRIGGER' )&& !mirrorEnable && !indexEnable && !randomEnable">
        <label  *ngIf="triggerType=='CATCHHOOK_RESTHOOK' ">Autopopulate fields from your Catch Hook Url</label>
        <label  *ngIf="triggerType=='APP_TRIGGER'">Autopopulate fields from your App Response</label>
        <div style="display: flex;width: 100%;column-gap: 10px;row-gap:10px;flex-direction: col;flex-wrap: wrap;margin-bottom:5px;margin-top:10px;padding-bottom: 10px;border-bottom: 1px solid #d9d9d9;" *ngIf="((columnsFor!='to' && columnsFor!='cc' && columnsFor!='bcc') || (filterCall && customfieldType=='Text' || customfieldType=='Textarea')) ">
         <ng-container *ngFor="let item of catchHookjsonMap">
          <span class="predefineBox" (click)="selectedColumnValue('triggeredItem.'+item.display_key,item.display_key,null,'catchHook')">{{item?.display_key}}</span>
        <!--   <ng-container *ngFor="let value of item.request_body  | keyvalue ">
            <span class="predefineBox" (click)="selectedColumnValue('triggeredItem.'+value.key,value.key,null,'catchHook')">{{value.key}}</span>
          </ng-container> -->
         </ng-container>
         <ng-container *ngIf="triggerType=='CATCHHOOK_RESTHOOK' &&  catchHookjsonMap?.length">
          <span class="predefineBox" (click)="selectedColumnValue('CATCHHOOK_JSON_BODY_MAPPING','CatchHook Request Body',null,'catchHook')">CatchHook Request Body</span>
          <span class="predefineBox" (click)="selectedColumnValue('CATCHHOOK_HUMAN_READABLE_BODY_MAPPING','CatchHook Request Body- Human Readable',null,'catchHook')">CatchHook Request Body-Human Readable</span>
         </ng-container>
         </div>  
      </div>
     
      <!--Index-->
      <div *ngIf="showIndex && !indexEnable && !randomEnable && !mirrorEnable && columnsFor!='to' && columnsFor!='cc' && columnsFor!='bcc'">
        <div style="display: flex;width: 100%;column-gap: 10px;row-gap:10px;flex-direction: col;flex-wrap: wrap;margin-bottom:5px;margin-top:10px;padding-top: 10px;">
          <span class="predefineBox" (click)="indexEnable=true">Add index</span>
          <span class="predefineBox" (click)="resetCharacterSet();randomEnable=true">Add Random String</span>
  
        </div>  
      </div>
      <div class="randomKeyArea"  (click)="$event.stopPropagation()" *ngIf="indexEnable">
        <div style="display:flex;column-gap: 10px;align-items: flex-start;"> 
          <span class="backButton" (click)="indexEnable=false">
            <img src="../../../assets/1x/angle-left-4d4d4d.svg" style="height: 14px;"/>
          </span>
          <span class="f-xl-m">Add Index </span>
         </div>
  
        <div style="display:flex;align-items: center;justify-content: space-between;margin-top:10px;"><span class="startIndex f-l-r">Start Value</span><span class="startIndexInput"><input type="number" class="keyInputBox" [(ngModel)]="startIndex"/></span></div>
        <div style="display:flex;align-items: center;justify-content: space-between;margin-top:10px;"><span class="startIndex f-l-r">Increment Value</span><span class="startIndexInput"><input type="number" class="keyInputBox" [(ngModel)]="endIndex"/></span></div>
        <div style="display:flex;justify-content: right;align-items: center;margin-top: 10px;margin-right:15px;">
          <button class="btn Sendbtn" style="height:30px;" type="button" (click)="getindexColumns();indexEnable=false;">Save</button>
      </div> 
      </div>
  
      <div class="randomKeyArea" (click)="$event.stopPropagation()" *ngIf="randomEnable">
        <div style="display:flex;column-gap: 10px;align-items: flex-start;"> 
          <span class="backButton" (click)="resetCharacterSet();randomEnable=false">
            <img src="../../../assets/1x/angle-left-4d4d4d.svg" style="height: 14px;"/>
          </span>
          <span class="f-xl-m">Add Random String </span>
         </div>
       
        <div style="display:flex;flex-direction: column;row-gap: 8px;margin-top: 7px;">
          <div style="display:flex;align-items: center;column-gap:10px">
            <span class="includeBtns" (click)="includeCharacters('numeric')">Include Numbers</span>
            <span class="includeBtns" (click)="includeCharacters('alphabet')">Include Alphabets</span>
          </div>
          <div style="display:flex;flex-direction: column;row-gap: 5px;">
            <div style="display:flex;align-items: center;justify-content: space-between;"><span class="startIndex f-l-r">Characters</span><span class="startIndexInput"><input type="text" class="keyInputBox" [(ngModel)]="characterSet" [ngModelOptions]="{standalone: true}" #characterset (blur)="removeDuplicateCharacters()" /></span></div>
            <div style="display:flex;align-items: center;justify-content: space-between;"><span class="startIndex f-l-r">Length</span><span class="startIndexInput"><input type="number" class="keyInputBox" [(ngModel)]="characterlength" [ngModelOptions]="{standalone: true}" #characterlengths [min]="1"/></span></div> 
          </div>
          <div style="display:flex;margin-top: 5px;">
            <span class="text-danger">
              *You can add any character you want. Only unique characters will be picked !
            </span>
          </div>
        </div>
        <div style="display:flex;justify-content: right;align-items: center;column-gap: 10px;">
          <button class="randomBtn resetBtn" style="height:30px;" type="button" (click)="resetCharacterSet();">Reset</button>
          <button class="randomBtn " style="height:30px;"
          [ngClass]="{'saveDisabledBtn':(!characterset.value.trim().length || !characterlengths.value || characterlengths.value<1) , 'saveBtn' : (characterset.value.trim().length && characterlengths.value && characterlengths.value>0)  }"
            type="button" (click)="getRandomColumns();randomEnable=false;" 
          [disabled]=" !characterset.value.trim().length || !characterlengths.value || characterlengths.value<1" >Save</button>
        </div> 
      </div>
      <div class="randomKeyArea"  (click)="$event.stopPropagation()" *ngIf="mirrorEnable">
        <div *ngIf="selectedMirrorObject?.length" style="display:flex;column-gap: 10px;align-items: flex-start;"> 
          <span class="backButton" (click)="mirrorEnable=false">
            <img src="../../../assets/1x/angle-left-4d4d4d.svg" style="height: 14px;"/>
          </span>
          <span class="f-xl-m truncate-text">Select Options for {{selectedMirrorObject[0]?.display_key}} </span>
         </div>

         <div *ngIf="!selectedMirrorObject?.length && selectedCatchhookDisplayKey" style="display:flex;column-gap: 10px;align-items: center;"> 
          <span class="backButton" (click)="mirrorEnable=false">
            <img src="../../../assets/1x/angle-left-4d4d4d.svg" style="height: 14px;"/>
          </span>
          <span class="f-xl-m truncate-text">Select Options for {{selectedCatchhookDisplayKey?.key}} </span>
         </div>
  
  
        <div style="display:flex;align-items: center;margin-top:10px;">
          <input type="radio" id="html" name="fav_language" value="FETCH_FIRST" [(ngModel)]="algorithm">
          <label for="html" class="shifts f-l-r">Fetch first value</label>
        </div>
        <div style="display:flex;align-items: center;margin-top:10px;" *ngIf="showAll">
          <input type="radio" id="html" name="fav_language" value="FETCH_ALL" [(ngModel)]="algorithm">
          <label for="html" class="shifts f-l-r">Fetch all value</label>
        </div>
        <div style="display:flex;align-items: center;margin-top:10px;">
          <input type="radio" id="html" name="fav_language" value="FETCH_LAST" [(ngModel)]="algorithm">
          <label for="html" class="shifts f-l-r">Fetch last value</label><br>
        </div>
        <div style="display:flex;justify-content: right;align-items: center;margin-top: 10px;margin-right:15px;">
          <button class="btn Sendbtn" style="height:30px;" type="button" (click)="setValueForMirrorColumns()">Save</button>
      </div> 
      </div>
    </ng-container>


    <ng-container *ngIf="!selectedFunction && !numberTypeGraph && valueTemplateVarArr?.length && !indexEnable && !randomEnable">
      <div *ngIf="valueTemplateVarArr?.length" style="display: flex;width: 100%;column-gap: 10px;row-gap:10px;flex-direction: col;flex-wrap: wrap;margin-top:10px;border-bottom: 1px solid #d9d9d9;padding-bottom: 10px;">
        <label> Automation Variables </label>
        <div style="display: flex;width: 100%;column-gap: 10px;row-gap:10px;flex-direction: col;flex-wrap: wrap;margin-top:10px;" *ngIf="((columnsFor!='to' && columnsFor!='cc' && columnsFor!='bcc') || (filterCall && customfieldType=='Text' || customfieldType=='Textarea')) ">
         <ng-container *ngFor="let item of valueTemplateVarArr">
          <span class="predefineBox" (click)="selectedColumnValue(item.key,item.display_key)">{{item?.display_key}}</span>
         </ng-container>
         </div>  
      </div>
    </ng-container>

    <!--Dashboard Variable-->
    <ng-container *ngIf="!selectedFunction && !numberTypeGraph && dashVariableArr?.length && !indexEnable && !randomEnable">
      <div style="display: flex;width: 100%;column-gap: 10px;row-gap:10px;flex-direction: col;flex-wrap: wrap;margin-top:10px;padding-bottom: 10px;">
        <ng-container *ngIf="!mirrorEnable">
          <label style="width:100%;margin-bottom: 0px;">Variables</label>
          <ng-container *ngFor="let field of dashVariableArr">
            <span class="predefineBox" *ngIf="!['Textarea','SingleImage','MultipleImage','SingleFile','MultipleFile','SingleVideo','MultipleVideo','SingleAudio','MultipleAudio','Button'].includes(field.field_type)" (click)="selectedColumnValue(field.key,field.name,field.var_key,field.field_type)">{{field?.name}}</span>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="mirrorEnable">
          <div class="randomKeyArea"  (click)="$event.stopPropagation()">
            <div *ngIf="selectedMirrorObject?.length" style="display:flex;column-gap: 10px;align-items: flex-start;"> 
              <span class="backButton" (click)="mirrorEnable=false">
                <img src="../../../assets/1x/angle-left-4d4d4d.svg" style="height: 14px;"/>
              </span>
              <span class="f-xl-m truncate-text">Select Options for {{selectedMirrorObject[0]?.name}} </span>
             </div>
            <div style="display:flex;align-items: center;margin-top:10px;">
              <input type="radio" id="html" name="fav_language" value="FETCH_FIRST" [(ngModel)]="algorithm">
              <label for="html" class="shifts f-l-r">Fetch first value</label>
            </div>
            <div style="display:flex;align-items: center;margin-top:10px;" *ngIf="showAll">
              <input type="radio" id="html" name="fav_language" value="FETCH_ALL" [(ngModel)]="algorithm">
              <label for="html" class="shifts f-l-r">Fetch all value</label>
            </div>
            <div style="display:flex;align-items: center;margin-top:10px;">
              <input type="radio" id="html" name="fav_language" value="FETCH_LAST" [(ngModel)]="algorithm">
              <label for="html" class="shifts f-l-r">Fetch last value</label><br>
            </div>
            <div style="display:flex;justify-content: right;align-items: center;margin-top: 10px;margin-right:15px;">
              <button class="btn Sendbtn" style="height:30px;" type="button" (click)="setValueForMirrorColumns()">Save</button>
          </div> 
          </div>
        </ng-container>
        </div> 
    </ng-container>

    <!--numberTypeGraph-->
    <ng-container *ngIf="numberTypeGraph">
      <div style="display: flex;width: 100%;column-gap: 10px;row-gap:10px;flex-direction: col;flex-wrap: wrap;margin-top:10px;padding-bottom: 10px;">
          <ng-container *ngFor="let field of customFieldArr">
            <span class="predefineBox" (click)="selectedColumnValue(field.key,field.display_key,field.key,field.type)">{{field?.display_key}}</span>
          </ng-container>
        </div> 
    </ng-container>
</div>  


<!--Function-->
<ng-container *ngIf="selectedFunction">
  <div class="functionContainer">
    <app-function-editor style="flex:1;" [dashboardVariable]="dashboardVariable" [dashVariableArr]="dashVariableArr" [isValid]="isValid" [selectedFunction]="selectedFunction" (selectedFunctionsEmit)="selectedFunctionsEmit.emit($event)" (closeEmit)="closeEmit.emit()" [filterCall]="filterCall" [customfieldType]="customfieldType" [showIndex]="showIndex" [item]="item" [dashId]="dashId" [customFieldArr]="customFieldArr" [isTriggeredNonItemField]="isTriggeredNonItemField" [catchHookReqJson]="catchHookReqJson" [appSampleReqJson]="appSampleReqJson" [catchHookFieldArr]="catchHookFieldArr" [triggerType]="triggerType" [columnsJson]="columnsJson"></app-function-editor>
  </div>
</ng-container>

<ng-template #functionPopover>
  <div class="funContainer">
    <app-function-popover (getFunctionsEmit)="getFunctionsEmit($event)"></app-function-popover>
  </div>
</ng-template>