import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit ,} from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { CommonUtils } from 'app/services/CommonUtils.service';
import { DashboardUtilsService } from 'app/services/dashboard-utils.service';
import { HttpTransferService } from 'app/services/httpTransfer.service';

@Component({
  selector: 'url-cell',
  changeDetection:ChangeDetectionStrategy.OnPush,
  templateUrl: './url-cell.component.html',
  styleUrls: ['./url-cell.component.scss']
})
export class UrlCellComponent implements OnInit  {
  params:any={};
  editable: boolean = false
  itemInfo:any={}
  urlValues: any=[];
  newUrl:string=''
  editedUrl:any={index:-1,value:null};

  constructor(private cdr:ChangeDetectorRef,private commonUtils: CommonUtils,private dashboardUtils:DashboardUtilsService,private httpTransfer: HttpTransferService,){
  }

  async ngOnInit() {
    this.onUpdate(this.params)
  }

  onUpdate(params:any){
    this.itemInfo=this.params?.item || {}
    this.editable=this.params?.editable
    this.urlValues=[]
    if(this.params?.columnParams?.customField?.type == 'MultiUrl' && this.params?.value)this.urlValues=this.params?.value || []
    else if(this.params?.value)this.urlValues=[this.params?.value]
    this.cdr.detectChanges()
  }


  trackByFn(index,item){
    return index
  }

  focusInput(id){
    setTimeout(() => {document.getElementById(id)?.focus()},5); 
  }

  onRemoveUrl(i){
    this.urlValues.splice(i,1)
    this.onUpdateUrl()
  }

  onEditUrl(i){
    if(this.editedUrl?.value!=this.urlValues[i]){
      this.urlValues[i]=this.editedUrl?.value
      this.editedUrl={}
      this.urlValues=this.urlValues.filter(url=>url.trim().length!=0)
      this.onUpdateUrl() 
    }
    this.editedUrl={}
    
  }

  addnewUrl(){
    if(this.newUrl?.trim()?.length && this.editable){
      this.urlValues.push(this.newUrl)
      this.newUrl=''
      this.onUpdateUrl();
    }
  }

  onUpdateUrl(){
    let urlData =null
    if(this.urlValues.length>0){
      urlData=this.params?.columnParams?.customField?.type == 'MultiUrl' ? [...this.urlValues] : this.urlValues[0]
    }
    this.params.value=urlData
    this.params?.gridTableRef?.changedCellValue({value:this.params.value,customFieldType:this.params.columnParams?.isCustomField,params:this.params});
    this.onUpdate(this.params) 
  }

}
