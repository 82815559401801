<!--single Email field -->
<ng-container *ngIf="itemValues?.type=='Email'">
  <div class="cell-box">
    <div class="cell-box-inner position-relative w-100" #errorPopover="bs-popover" placement="bottom" container="body" [popover]="errorPopoverTemp" [outsideClick]="true" triggers="manual" [ngClass]="{'red-border': !validateToEmail && (numberdfield?.dirty || numberdfield?.touched),'enable-editor-box': enableEditor || !validateToEmail && (numberdfield?.dirty || numberdfield?.touched)}">
        <div class="left-box">
            <input  (blur)="onValueInput(value); editing = false" (focus)="editing = true" #inputBox (keydown.enter)="$event.preventDefault();onValueInput(value)"  (input)="checkEmptyEmail(value);!validateToEmail && (numberdfield?.dirty || numberdfield?.touched) ? errorPopover?.show() : errorPopover?.hide()"  class="cell-input-box"
            placeholder="{{'placeholders.addYourTextHere' | translate}}" [(ngModel)]="value" name="input_{{ itemInfo.key }}" #numberdfield="ngModel"  [disabled]="!editable" />
            <div class="cell-value-box text-element">{{value}}</div>
        </div>
        <div *ngIf="!enableEditor && editable && !editing" (click)="grid.open()" class="right-box absolute" role="button">
          <span class="btn btn-light text-dark px-1 py-0 rounded">
            <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
          </span>
        </div>  
        <span #grid="ngbPopover" [ngbPopover]="gtidTemplates" popoverClass="modifiedPopover" [autoClose]="'outside'" container="body"></span>
          <ng-template #gtidTemplates>
            <div class="p-1" style="width: 10rem;">
              <div role="button" class="btn btn-light text-dark py-1 px-3 mb-1 w-100 bg-white rounded text-left" (click)="copyToClipboard(value);grid?.close()">
                <i class="fa fa-clone icons mr-3" aria-hidden="true" ></i><span class="f-s-r">Copy</span>
              </div>
              <div *ngIf="editable" role="button" (click)="editable && onEditorEnable();grid?.close()" class="btn btn-light text-dark py-1 px-3 w-100 bg-white rounded text-left">
                <i class="fa fa-pencil icons mr-3"  aria-hidden="true"></i><span class="f-s-r">Edit Email</span>
              </div>
            </div>
          </ng-template>
    </div>
  </div>
</ng-container>

<!--multiple email-->
<ng-container *ngIf="itemValues?.type=='MultipleEmail'">
  <div class="cell-box position-relative">
      <!-- <div class="left-box"> -->
      <div class="text-element" style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;font-size: 14px;">{{itemValues['valueData'][0]}}</div>
    <!-- </div> -->
    <div *ngIf="itemValues['valueData']?.length>1" class="count-indicator"><span class="btn btn-light btn-sm px-1 py-1 rounded text-primary">+{{itemValues['valueData']?.length-1}}</span></div>
    <div *ngIf="!enableEditor && editable" (click)="grid.open();reAssignValues()" class="right-box absolute" role="button">
        <span class="btn btn-light text-dark px-2 py-1 rounded">
          <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
        </span>
      </div>  
      <span #grid="ngbPopover" [ngbPopover]="gtidTemplates" popoverClass="modifiedPopover" [autoClose]="'outside'" container="body"></span>
        <ng-template #gtidTemplates>
          <div class="p-1" style="width: 10rem;">
            <div role="button" class="btn btn-light text-dark py-1 px-3 mb-1 w-100 bg-white rounded text-left" *ngIf= "itemValues['valueData'][0]?.length" (click)="copyToClipboard(itemValues['valueData'][0]);grid?.close()">
              <i class="fa fa-clone icons mr-3" aria-hidden="true" ></i><span class="f-s-r">Copy</span>
            </div>
            <div *ngIf="editable" role="button" (click)="grid?.close();gridS.open();" class="btn btn-light text-dark py-1 px-3 w-100 bg-white rounded text-left">
              <i class="fa fa-pencil icons mr-3"  aria-hidden="true"></i><span class="f-s-r">Manage Emails</span>
            </div>
          </div>
        </ng-template>
  </div>
          <span #gridS="ngbPopover" [ngbPopover]="editable && emailComponent" [autoClose]="false" popoverClass="modifiedPopover" container="body"></span>
          <ng-template #emailComponent>
            <div style="width: 414px;">
              <div style="padding: 5px;">
                <div class="pb-1 mb-2" style="display: flex;justify-content: space-between;height: 35px;border-bottom: 1px solid gray;">
                  <div class="f-xl-m" style="line-height: 24px;display: flex;justify-content: center;align-items: center;">Manage Email</div>
                  <div style="display: flex;justify-content: center;align-items: center;">
                    <div style="padding-right: 4px">
                      <span style="float: right;border-radius: 4px;width: 77px;display: flex;justify-content: center;color: #3269CC;" role="button" (click)="editable && addMoreEmail=true"><span class="iconBox f-l-m" style="color: #3269CC;">Add more</span></span>
                    </div>
                    <div style="line-height: 24px;display: flex;justify-content: center;align-items: center;" (click)="gridS?.close()"><button type="button" class="close" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button></div>
                  </div>
                </div>
                  <app-email-selection [editable]="editable" (emitAddMore)="addMoreEmail=$event" [selectedLeadObj]="params?.item" [addMoreEmail]="addMoreEmail" [fieldType]="'Email'" [item]="itemValues"  [dashId]="params?.item?.dashboard_id" (onValueUpdate)="value=$event;onValueInput()" [currenValue]="itemValues['valueData']" [isGrid]="true" ></app-email-selection>
              </div>
            </div>
          </ng-template>
</ng-container>
<ng-template #errorPopoverTemp>
<div class="text-danger">
  <b>{{'editcustom.enterCorrectEmail' | translate}}</b>
</div>
</ng-template>