export const ChartIcons ={
    "pie": 'assets/1x/chart/pie.svg',
    "bar": 'assets/1x/chart/bar.svg',
    "donut": 'assets/1x/chart/donut.svg',
    "line": 'assets/1x/chart/line.svg',
    "stack_bar": 'assets/1x/chart/stack_bar.svg',
    "stack_line": 'assets/1x/chart/stack_line.svg',
    "multi_bar": 'assets/1x/chart/multi_bar.svg',
    "number":'assets/1x/chart/number.svg',
    "stackArea":"assets/1x/chart/stackArea.svg",
    "huderedPercent":"assets/1x/chart/hunderedPercent.svg",
    "funnel":"assets/1x/chart/funnel.svg",
    "bubble":"assets/1x/chart/bubble.svg",
    "gauge":"assets/1x/chart/gauge.svg",
    "heat_map":"assets/1x/chart/heat_map.svg",
 }
