import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CommonUtils } from 'app/services/CommonUtils.service';
@Component({
  selector: 'bucket-cell',
  changeDetection:ChangeDetectionStrategy.OnPush,
  templateUrl: './bucket-cell.component.html',
  styleUrls: ['./bucket-cell.component.scss']
})
export class BucketCellComponent implements OnInit  {
  @Input() params : any={}; 
  bucketsArr:any=[]
  allowBucketArr:any=[]
  itemInfo:any={}
  selectedBucketInfo:any={}
  
  constructor(private cdr:ChangeDetectorRef,private commonUtils : CommonUtils){}

  ngOnInit(): void {
    this.onUpdate()
  }

  onUpdate(params: any={}){
    this.itemInfo=this.params?.item || {}
    this.setBucketData()
    this.cdr.detectChanges()
  }
  async setBucketData(){
    this.bucketsArr =[...this.params?.globalParams?.boardData[this.itemInfo?.dashboard_id]?.BUCKET || []]
    this.selectedBucketInfo=this.bucketsArr.find(bucket=>bucket._id==this.params.value)
    let allowedBucketsIds = []
    allowedBucketsIds = await this.commonUtils.getAllowedBuckets(this.itemInfo.dashboard_id,this.params.value,this.bucketsArr,this.params.item)
    allowedBucketsIds.push(this.params.value);
    this.allowBucketArr = this.bucketsArr.filter(bucketJson => allowedBucketsIds.includes(bucketJson._id))
  }
  onBucketUpdate(value){
    if(this.params.value==value){return}
    this.params.value=value
    this.params?.gridTableRef?.changedCellValue({value:{'destination_bucket_id':value},params:this.params});
    this.onUpdate()
  }


}
