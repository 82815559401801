<div class="mb-1 mt-1 phaseTopHead">
<span class="togglephase">
  <span class="f-s-m">Multi Select</span>
  <div class="switch toggle">
    <label class="switch toggle" tabindex="0">
        <input type="checkbox" (change)="toggleMultiSelect()" [checked]="isMultiSelectExpanded">
        <span class="slider"></span>            
    </label> 
  </div>
</span>
<span class="clear-text f-s-m" (click)="onSprintSelect(null)">Clear</span>

</div>

<div *ngIf="!phasePopover" class="search searchborder">
  <input class="form-control" autofocus type="search" [(ngModel)]="searchText" class= "f-s-r" placeholder="Search" aria-label="Search">
  <app-dashboard-icon class="iconBox-20 cursor-pointer" [size]="14" [name]="'search'" [iconJson]="commonIcons"></app-dashboard-icon>
  <!-- <app-dashboard-icon class="iconBox-20 cursor-pointer" [size]="14" [name]="'search'" [isSVG]="true" "color ='#CED0D5'" [iconJson]="commonIcons"></app-dashboard-icon> -->
</div>
<div class="sprintselect-cnr scrollarea">
  <ul *ngFor="let data of sprintData | filter:searchText">
    <div>
      <li (click)="onSprintSelect(data.sprint)" [ngClass]="{'singleSelectPhase':selectedSprintIds.includes(data.sprint._id) && !isMultiSelectExpanded}">        
        <span class="d-flex align-items-center gap2">
          <span [ngClass]="{'collapsed': !isMultiSelectExpanded}" >
            <input style="width: 11px;height: 11px; border:1px solid #eaeaea; margin-right: 0.1rem; padding-top: 0.1rem;" [checked]="selectedSprintIds.includes(data.sprint._id)" type="checkbox"></span>
          <span class="f-s-r"> {{data.name}} </span>
          <!-- <span *ngIf="selectedSprintIds.includes(data.sprint._id) && !isMultiSelectExpanded"><app-dashboard-icon class="iconBox-20 cursor-pointer" [size]="14" [name]="'circleCheck'" class="circleCheck" [iconJson]="commonIcons"></app-dashboard-icon> -->
          
        </span>
        
        <span *ngIf="data.current_status=='STARTED'" class="pdot float-right"></span>
        <span *ngIf="data.current_status=='STOPPED'" class="cdot float-right"></span>
        <span class="float-right" *ngIf="data.is_archive" style="margin-top:0px;"> <img class="arcicon"src="../../../assets/img/icons/archive.svg"/></span>
        <!-- <span class="f-xs-l" [ngClass]="{'marginChange': isMultiSelectExpanded}"> -->
        <span class="f-xs-l">
          {{data.planned_start_date}} <span *ngIf="!data.planned_start_date"> {{'form.noStartDate' | translate}}</span> -
          {{data.planned_end_date}}<span *ngIf="!data.planned_end_date"> {{'form.noEndDate' | translate}}</span>
        </span>
      </li>
    </div>
  </ul>  
</div>
<span class="float-right applyBtn commonBtn-28 themeChild mt-2"><span class="commonBtnBox-Child-1"><span class="f-s-r">Apply</span></span></span>
