import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit ,} from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { CommonUtils } from 'app/services/CommonUtils.service';
import { DashboardUtilsService } from 'app/services/dashboard-utils.service';

@Component({
  selector: 'location-cell',
  templateUrl: './location-cell.component.html',
  styleUrls: ['./location-cell.component.scss']
})
export class LocationCellComponent implements OnInit  {
  params:any={};
  editable: boolean = false
  itemInfo:any={}
  value:any;
  locations:any

  constructor(private cdr:ChangeDetectorRef,private commonUtils: CommonUtils,private dashboardUtils:DashboardUtilsService){
  }

  async ngOnInit() {
    this.onUpdate(this.params)
  }

  onUpdate(params: any){
    this.itemInfo=params?.item || {}
    this.editable=params?.editable
    let locations=this.params.value || {}
    this.locations  = locations.description ? locations.description : (locations.latitude && locations.longitude ? locations.latitude + ', ' + locations.longitude : '')
    this.cdr.detectChanges()
  }

  updateLocation(event){
    this.params.value = {place_id:event};
    this.params?.gridTableRef?.changedCellValue({value:this.params.value,customFieldType:true,params:this.params});
    this.onUpdate(this.params) 
  }
}