import { ApplicationRef, Compiler, Component, EventEmitter, Injector, Input, OnInit, Output } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { MessageService } from "app/message.service";
import { HttpTransferChatService } from "app/services/http-transfer-chat.service";
import { HttpTransferService } from "app/services/httpTransfer.service";
import { ToasterService } from "app/services/toaster.service";
import { AttributesIcons, commonIcons } from "Enums/attributes-icons";
@Component({
    selector: 'board-creationbtn',
    templateUrl: './board-creationbtn.component.html',
    styleUrls: ['./board-creationbtn.component.scss'],
  })
  export class BoardCreationBtnComponent implements OnInit {
    commonIcons = commonIcons;
    attributesIcons = AttributesIcons
    @Output() createFolder : EventEmitter<any> = new EventEmitter()
    @Output() selectedAppInstance : EventEmitter<any> = new EventEmitter();
    @Input() workfolderJson:any[]=[]
    @Input() dashSelect :any;
    @Input() headerCall:boolean=false;
    @Input() dashboardFolderId:any;
    @Input() viewType:any;
    @Input() appsDetails:any;
    boardList: any[];
    selectDashboardPopupText:any;
    createNewDashTemplateRef: NgbModalRef;
    selectedBoardForCreaion: any;
    constructor(public modalService : NgbModal,private httpTransfer : HttpTransferService,private toasterService : ToasterService,private messageService:MessageService,
        private compiler: Compiler, private injector: Injector,private app: ApplicationRef
    ){}
    ngOnInit(){
        
    }

    async openNewDashboardModel(modal) {
        if(this.workfolderJson?.length){
          this.boardList = this.workfolderJson.filter(w=>w?.type=="FOLDER" || w?.type=="WORK_FOLDER")
          if(this.selectDashboardPopupText!=='FOLDER'){
            let filterDash = this.workfolderJson.filter(w=>w?.dashboard_id == this.dashSelect)
            this.createNewDashTemplateRef=this.modalService.open(modal, { size: 'lg' ,windowClass : 'createBoardModal'}); 
             if(filterDash?.length && filterDash[0]?.type.includes("BOARD")){
                     this.selectedBoardForCreaion = this.workfolderJson.filter(m=>m?.dashboard_id == filterDash[0]?.folder_id)[0] || {}
             }
             else{
              this.selectedBoardForCreaion = this.workfolderJson.filter(m=>m?.dashboard_id == filterDash[0]?.dashboard_id)[0] || {}
             }
          }
          else{
            this.createNewDashTemplateRef=this.modalService.open(modal, { size: 'lg' ,windowClass : 'createBoardModal'}); 
            this.selectedBoardForCreaion = this.workfolderJson.filter(m=>m?.type == "WORK_FOLDER")[0] || {}
          }
        }
        else{
            if(this.selectDashboardPopupText!=='FOLDER'){
            this.createNewDashTemplateRef=this.modalService.open(modal, { size: 'lg' ,windowClass : 'createBoardModal'}); 
            }
            else{
                this.createNewDashTemplateRef=this.modalService.open(modal, { size: 'lg' ,windowClass : 'createBoardModal'}); 
               // this.selectedBoardForCreaion = this.workfolderJson.filter(m=>m?.type == "WORK_FOLDER")[0] || {}    
            }
        }
    }   

      async openTemplateModal(modalContent){
       this.selectedBoardForCreaion = this.workfolderJson.filter(m=>m?.type == "WORK_FOLDER")[0] || {}         
       this.messageService.setSettingClick(true)
       let modalRef=this.modalService.open(modalContent, { windowClass: 'tempModal',size:'xl' });  
       let newNode = document.createElement('div');
         newNode.id = 'placeholder';
         newNode.classList.add('templateParentDiv');
         document.getElementById('templateHomeComonent').appendChild(newNode);
         const { TemplatesModule } =  await import("app/components/templates/templates.module");
         const moduleFactory = await this.compiler.compileModuleAsync(TemplatesModule);
         const moduleRef = moduleFactory.create(this.injector);
         const componentFactory = moduleRef.instance.getTemplateHomeComponents();
         const ref = componentFactory.create(this.injector, [], newNode);
         this.app.attachView(ref.hostView);
         ref.instance.dashId = this.dashboardFolderId ? this.dashboardFolderId : this.selectedBoardForCreaion?.dashboard_id,
         ref.instance.workfolder=false
         ref.instance.modalRef = modalRef
        }
  }
