<div class="card editCust-bg-color editCust" [class.activity-edit-fields]="leadObj?.item_type=='ACTIVITY_COMMENT' || activityBoardId"  [ngStyle]="{ height: height + 'vh' }" [ngClass]="{'previewCall':previewCall}">
  <div class="customFieldContainer" #container [ngClass]="{'previewCallPadding':previewCall}">
    <ng-container *ngIf="['CONTACT','ORGANIZATION'].includes(dashboardInfo?.board_type) && arrayOfCustomFiled.length>0">
      <div style="width: 100%;min-height: 118px;border-bottom: 1px solid #CED0D5;max-height: 100%;" class="contactTypeBoard">
        <div class="row">
          <div class="col-md-3"></div>
          <div class="col-md-9">
            <div class="d-flex">
              <!-- image here -->
              <div class="show-image text-center show-on-hover-parent mr-3" style="width: fit-content !important;">
                <span *ngIf="!selectedContactProfile?.fileObjArr[0]?.image_download_url" class="card-text"><ngx-avatars class="mr-1" bgColor="#FF5733" [name]="editTitle?editTitle:'P'" class="userImage"  size="100" initialsSize="4" ></ngx-avatars></span>
                <span *ngIf="selectedContactProfile?.fileObjArr[0]?.image_download_url" class="card-text"><img [src]='selectedContactProfile?.fileObjArr[0]?.image_download_url' class="userImage"></span>
                <button *ngIf="!(isPreview  || isItemLocked(selectedContactProfile?.locked) || isReadOnly)" type="button" class="btn absolute-center show-on-hover" [disabled]="isReadOnly" (click)="setSelectedMediaField(selectedContactProfile,customFieldType.IMAGEFIELD);singleImageFile.click(); getMultiImageFile();setSelectedMediaField(selectedContactProfile,customFieldType.MULTIIMAGE);">
                  <span class="upload-icon-contact"><i class="fa fa-upload fa-2x" aria-hidden="true"></i></span>
                </button>
                <input #singleImageFile class="singleImageFile" type="file" name="singleImageFile" (change)="fileChangeEvent($event.target.files)" accept="image/*" single>
              </div>
              <!-- details here -->
              <div [ngClass]="{'edit-fullItem-view':!addItem,'edit-templateItem-view':addItem}">
                <div style="min-height: 30px;gap: 0px;opacity: 0px;width: 100%;" [ngClass]="{'edit-contact-title':!addItem}">
                  <input class="name-text-style p-0 " id="contact-input-field"  [(ngModel)]="editTitle" [readonly]="isReadOnly || isPublicLead" [required]="true"
                    [ngModelOptions]="{standalone: true}" class="emptytextarea hoverBorder key-focus"
                    tabindex="0"
                    (ngModelChange)="(triggeredFromCreateACopy && leadId || !leadId) && addTitleForLeadCreation();updatedCustomFieldObj()"
                    (blur)="!triggeredFromCreateACopy && leadId && !isReadOnly &&!isPublicLead && updateLeadInfo(leadId)" #titlefield="ngModel" placeholder="Enter name"/>
                  <div *ngIf="titlefield.invalid && (titlefield.dirty || titlefield.touched)">
                    <span class="validationText ls-025x" *ngIf="!isTemplateCall">{{'editcustom.titleField' | translate}}  {{'editcustom.Required' | translate}}</span>
                  </div>
                </div>
                <div style="gap: 0.7rem;" class="icon-placement-contact d-flex px-1">
                  <ng-container *ngFor="let item of contactTypeArrayCust; let index=index;trackBy:trackByFnList">
                    <ng-container *ngIf="['contact_user_e2NB6Dm3j_url','contact_user_x2uQrF0e3_url'].includes(item?.key)">
                      <span class="icon-width-css">
                        <a *ngIf="item.urlInput!=''" [href]="item.urlInput" target="_blank"><app-dashboard-icon [size]="30"  [name]="'NEW_FACEBOOK'" [ngClass]="{'grayScale-icon':item?.urlInput==''}" ></app-dashboard-icon></a>
                        <app-dashboard-icon *ngIf="item.urlInput==''" [size]="30"  [name]="'NEW_FACEBOOK'" [ngClass]="{'grayScale-icon':item?.urlInput==''}" ></app-dashboard-icon>
                      </span>
                    </ng-container>
                    <ng-container *ngIf="['contact_user_TJe6L0Er5_url','contact_user_hp77fmHtX_url'].includes(item?.key)">
                      <span class="icon-width-css" style="padding-top: 3px;">
                        <a *ngIf="item.urlInput!=''" [href]="item.urlInput" target="_blank"><app-dashboard-icon [size]="24"  [name]="'LINKDIN'" [ngClass]="{'grayScale-icon':item?.urlInput==''}"></app-dashboard-icon></a>
                        <app-dashboard-icon *ngIf="item.urlInput==''" [size]="24"  [name]="'LINKDIN'" [ngClass]="{'grayScale-icon':item?.urlInput==''}"></app-dashboard-icon>
                      </span>
                    </ng-container>
                    <ng-container *ngIf="['contact_user_9XfYrmFu7_url','contact_user_q8M5zYwj2_url'].includes(item?.key)">
                      <span class="icon-width-css" style="padding-top: 3px;">
                        <a *ngIf="item.urlInput!=''" [href]="item.urlInput" target="_blank"><app-dashboard-icon [size]="24"  [name]="'INSTAGRAM'" [ngClass]="{'grayScale-icon':item?.urlInput==''}"></app-dashboard-icon></a>
                        <app-dashboard-icon *ngIf="item.urlInput==''" [size]="24"  [name]="'INSTAGRAM'" [ngClass]="{'grayScale-icon':item?.urlInput==''}"></app-dashboard-icon>
                      </span>
                    </ng-container>
                    <ng-container *ngIf="['contact_user_K28bcgPZL_url','contact_user_qTX2Jx0j2_url'].includes(item?.key)">
                      <span class="icon-width-css" style="padding-top: 3px;">
                        <a *ngIf="item.urlInput!=''" [href]="item.urlInput" target="_blank"><app-dashboard-icon [size]="24"  [name]="'TWITTER'" [ngClass]="{'grayScale-icon':item?.urlInput==''}"></app-dashboard-icon></a>
                        <app-dashboard-icon *ngIf="item.urlInput==''" [size]="24"  [name]="'TWITTER'" [ngClass]="{'grayScale-icon':item?.urlInput==''}"></app-dashboard-icon>
                      </span>
                    </ng-container>
                  </ng-container>
                </div>
                <div *ngIf="!(isPreview || isReadOnly)" style="position: absolute;bottom: 0;right:0;" class="show-edit-icon-contact" placement="auto" #popChartOptions="ngbPopover" popoverClass="modifiedPopover" [ngbPopover]=" !isReadOnly && contactUrlEdit" container="body" [autoClose]="'outside'" (click)="$event.stopPropagation()"><img  role="button" style="float: right;" role="button" src="../../../../../assets/1x/new-edit-icon.svg">
                  <ng-template #contactUrlEdit>
                    <div style="padding:5px">
                      <div>
                        <div style="padding-bottom: 10px;" class="add-url-text">{{'editcustom.ADD_URL'|translate}}</div>
                        <div>
                          <ng-container *ngFor="let item of contactTypeArrayCust; let index=index;trackBy:trackByFnList">
                            <ng-container *ngIf="['contact_user_e2NB6Dm3j_url','contact_user_x2uQrF0e3_url','contact_user_TJe6L0Er5_url','contact_user_hp77fmHtX_url','contact_user_9XfYrmFu7_url','contact_user_q8M5zYwj2_url','contact_user_K28bcgPZL_url','contact_user_qTX2Jx0j2_url'].includes(item?.key)">
                              <div style="padding-bottom: 5px;">
                                <div class="row">
                                  <div class="col-2">
                                    <app-dashboard-icon *ngIf="['contact_user_e2NB6Dm3j_url','contact_user_x2uQrF0e3_url'].includes(item?.key)" [size]="28"  [name]="'NEW_FACEBOOK'" [ngClass]="{'grayScale-icon':item?.urlInput==''}"></app-dashboard-icon>
                                    <app-dashboard-icon style="padding-top: 3px;" *ngIf="['contact_user_TJe6L0Er5_url','contact_user_hp77fmHtX_url'].includes(item?.key)" [size]="24"  [name]="'LINKDIN'" [ngClass]="{'grayScale-icon':item?.urlInput==''}"></app-dashboard-icon>
                                    <app-dashboard-icon style="padding-top: 3px;" *ngIf="['contact_user_9XfYrmFu7_url','contact_user_q8M5zYwj2_url'].includes(item?.key)" [size]="24"  [name]="'INSTAGRAM'" [ngClass]="{'grayScale-icon':item?.urlInput==''}"></app-dashboard-icon>
                                    <app-dashboard-icon style="padding-top: 3px;" *ngIf="['contact_user_K28bcgPZL_url','contact_user_qTX2Jx0j2_url'].includes(item?.key)" [size]="24"  [name]="'TWITTER'" [ngClass]="{'grayScale-icon':item?.urlInput==''}"></app-dashboard-icon>
                                  </div>
                                  <div class="col-10">
                                    <input class="edit-contact-type-list" placeholder="Enter link" [(ngModel)]="item.urlInput">
                                  </div>
                                </div>
                              </div>
                            </ng-container>
                          </ng-container>
                        </div>
                      </div>
                      <div style="padding-top: 5px;">
                        <div style="float: right;padding-bottom: 10px;display: flex;">
                          <div style="padding-right: 3px;" role="button" (click)="popChartOptions?.close()">
                            <span style="border-radius: 4px;border: 1px solid gray;padding: 5px;">{{'common.cancel'|translate}}</span>
                          </div>
                          <div style="color: #ffffff;" role="button"><span style="border-radius: 4px;border: 1px solid gray;padding: 5px;background-color: #3571de" [ngStyle]="{'background-color':commonUtils?.getCurrentThemeColor()}" (click)="onSaveOfConatct();popChartOptions?.close()">{{'common.save'|translate}}</span></div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngFor="let item of contactTypeArrayCust; let index=index;trackBy:trackByFnList">
        <ng-container *ngIf="['Email','MultipleEmail'].includes(item.type) && isNonHidden(item)">
          <div class="row g-0 editleadcustominputsrow key-tab" >
            <div class="col-md-3 fieldLabelBox f-l-r " role="button">
                <app-dashboard-icon [name]="item.type" [iconJson]="attributesIcons" class="labelimg" [size]="14"></app-dashboard-icon>
                <span class="labelText" appOverflowCheck [ngbTooltip]>{{ item.display_key }}</span><span *ngIf="item.mandatory" style="color: red;">*</span>
              </div>
              <div class="col-md-12 phoneColumn fieldValueBox">
              <app-email-selection [fieldType]="item.type" [item]="item" [selectedLeadObj]="leadObj" [dashId]="dashId" [editable]="!(isPreview || isItemLocked(item.locked) || isReadOnly)"  [currenValue]="item?.emailinput" (onValueUpdate)="emailSelected(arrayOfCustomFiled, item.type, item,$event)"></app-email-selection>
            </div>

          </div>
        </ng-container>
        <ng-container *ngIf="['Mobile','MultipleMobile'].includes(item.type) && isNonHidden(item) && ['contact_phone_f9eoa8dfuf_mmob','company_phone_number_hir9p69xrx_mob'].includes(item?.key)">
          <div class="row g-0 editleadcustominputsrow key-tab">
            <div class="col-md-3 fieldLabelBox f-l-r " role="button">
              <app-dashboard-icon [name]="item.type" [iconJson]="attributesIcons" class="labelimg" [size]="14"></app-dashboard-icon>
              <span class="labelText" appOverflowCheck [ngbTooltip]>{{ item.display_key }}</span><span *ngIf="item.mandatory" style="color: red;">*</span>
            </div>
            <div class="col-md-12 phoneColumn fieldValueBox">
              <div class="rounded inpt p-0 newmobfld">
                <div class="currenty-selection-input" style="align-items: center;">
                  <app-include-country-selection *ngIf="item.type == 'Mobile'" [fieldType]="item.type" [selectedLeadObj]="leadObj" [dashId]="dashId" [editable]="!(isPreview || isItemLocked(item.locked) || isReadOnly)" [countryJson]="currencyArr || []" [currenValue]="item?.mobileinput" (onValueUpdate)="phoneNumberSelected(arrayOfCustomFiled, item.type, item,$event)"></app-include-country-selection>
                  <app-multiple-mobile-handle *ngIf="item.type=='MultipleMobile'" [leadObj]="leadObj" [dashId]="dashId" [editable]="!(isPreview || isItemLocked(item.locked) || isReadOnly)" [countryJson]="currencyArr || []" [currentValue]="item?.mobileinput" (onValueUpdate)="phoneNumberSelected(arrayOfCustomFiled, item.type, item,$event)"></app-multiple-mobile-handle>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
    </ng-container>
  </ng-container>
    <div>
    </div>
    <ng-container *ngTemplateOutlet="nonCustomFields"></ng-container>
    <ng-container *ngIf="arrayOfCustomFiled.length>0" >
      <!-- <ng-template ngFor let-item [ngForOf]="arrayOfCustomFiled" let-index="index" let-isLast="last"> -->
        <ng-container *ngFor="let item of arrayOfCustomFiled; let index=index;trackBy:trackByFnList">
          <ng-container *ngIf="['Email','MultipleEmail'].includes(item.type) && isNonHidden(item) && item?.key!='contact_email_qiybv1ozk4_eml'">
            <div class="row g-0 editleadcustominputsrow key-tab" >
              <div class="col-md-3 fieldLabelBox f-l-r " role="button">
                <app-dashboard-icon [name]="item.type" [iconJson]="attributesIcons" class="labelimg" [size]="14"></app-dashboard-icon>
                <span class="labelText" appOverflowCheck [ngbTooltip]>{{ item.display_key }}</span><span *ngIf="item.mandatory" style="color: red;">*</span>
              </div>
              <div class="col-md-9 phoneColumn fieldValueBox">
                <app-email-selection [fieldType]="item.type" [item]="item" [selectedLeadObj]="leadObj" [dashId]="dashId" [editable]="!(isPreview || isItemLocked(item.locked) || isReadOnly)"  [currenValue]="item?.emailinput" (onValueUpdate)="emailSelected(arrayOfCustomFiled, item.type, item,$event)"></app-email-selection>
              </div>

            </div>
          </ng-container>
          <ng-container *ngIf="['Mobile','MultipleMobile'].includes(item.type) && isNonHidden(item) && !['contact_phone_f9eoa8dfuf_mmob','company_phone_number_hir9p69xrx_mob'].includes(item?.key)">
            <div class="row g-0 editleadcustominputsrow key-tab">
              <div class="col-md-3 fieldLabelBox f-l-r " role="button">
                <app-dashboard-icon [name]="item.type" [iconJson]="attributesIcons" class="labelimg" [size]="14"></app-dashboard-icon>
                <span class="labelText" appOverflowCheck [ngbTooltip]>{{ item.display_key }}</span><span *ngIf="item.mandatory" style="color: red;">*</span>
              </div>
              <div class="col-md-9 phoneColumn fieldValueBox">
                <div class="rounded inpt p-0 newmobfld">
                  <div class="currenty-selection-input" style="align-items: center;">
                    <app-include-country-selection *ngIf="item.type == 'Mobile'" [fieldType]="item.type" [selectedLeadObj]="leadObj" [dashId]="dashId" [editable]="!(isPreview || isItemLocked(item.locked) || isReadOnly)" [countryJson]="currencyArr || []" [currenValue]="item?.mobileinput" (onValueUpdate)="phoneNumberSelected(arrayOfCustomFiled, item.type, item,$event)"></app-include-country-selection>
                    <app-multiple-mobile-handle *ngIf="item.type=='MultipleMobile'" [leadObj]="leadObj" [dashId]="dashId" [editable]="!(isPreview || isItemLocked(item.locked) || isReadOnly)" [countryJson]="currencyArr" [currentValue]="item?.mobileinput" (onValueUpdate)="phoneNumberSelected(arrayOfCustomFiled, item.type, item,$event)"></app-multiple-mobile-handle>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="item.type == textAreaField  && isNonHidden(item)">
            <div class="row g-0 editleadcustominputsrow textAreaFieldBox">
              <div class="row g-0 mx-0 w-100 fieldLabelBox f-l-r "role="button">
                <app-dashboard-icon [name]="item.type" [iconJson]="attributesIcons" class="labelimg" [size]="14"></app-dashboard-icon>
                <span class="labelText" appOverflowCheck [ngbTooltip]>{{ item.display_key }}</span><span *ngIf="item.mandatory" style="color: red;">*</span>
              </div>
              <ng-container *ngIf="isPreview  || isItemLocked(item.locked) || isReadOnly">
                <div class="row g-0 mx-0 w-100 fieldValueBox " textAssist [appendTo]="'body'"  [optionFields]="{'chatSuggestions':[],'aiSuggestions':[]}" [textEditable]="false">
                  <span [innerHtml]="item.textareainput | safehtml"> </span>
                </div>
              </ng-container>
              <!-- summer note start -->
              <ng-container *ngIf="!(isPreview  || isItemLocked(item.locked) || isReadOnly)">
                <div class="col-md-12 px-0" textAssist [appendTo]="'body'"  [optionFields]="triggeredFromCreateACopy ? {'chatSuggestions':[],'aiSuggestions':[],'editorOptions':[]} : {'suggestions':[],'aiSuggestions':[],'editorOptions':[]}" (onOptionSelect)="handledTextAssist($event,item)">
                  <app-content-editable-input-note [columnSelection]="false"  #textareaSummernote
                    (onChange)="item?.mandatory && validationRuntime(item,this.textAreaField)"
                    (onCreate)="item['summernote']=textareaSummernote" [dashId]="dashId"
                    [htmlContent]="item.textareainput" [type]="'summerNote'" [summernoteToolbar]="summerNoteconfig.toolbar"
                    (onOutsideClick)="item.textareainput=$event?.text;onValueInput(arrayOfCustomFiled,item.type, item.key)" >
                  </app-content-editable-input-note>
                </div>
              </ng-container> 
               <!-- summer note end-->
            </div>
          </ng-container>
          <ng-container *ngIf="item.type == textField && isNonHidden(item)">
            <div class="row editleadcustominputsrow key-tab">
              <div class="row g-0 mx-0 w-100 fieldLabelBox f-l-r " role="button">
                <app-dashboard-icon [name]="item.type" [iconJson]="attributesIcons" class="labelimg" [size]="14"></app-dashboard-icon>
                <span class="labelText" appOverflowCheck [ngbTooltip]>{{ item.display_key }}</span><span *ngIf="item.mandatory" style="color: red;">*</span>
              </div>
              <div class="row g-0 mx-0 w-100 fieldValueBox" style="padding: 0px !important;">
                <textarea autosize (keypress)="onKeyUp($event,item)"
                  placeholder="{{'placeholders.addYourTextHere' | translate}}" [required]="item.mandatory" [readonly]="isPreview  || isItemLocked(item.locked) || isReadOnly"
                  (blur)="!isReadOnlyField(item.locked) && onValueInput(arrayOfCustomFiled, textField, item.key)" (input)="validationRuntime(item,item.textinput)"
                  (keydown.tab)="$event.preventDefault()"
                  class="modal_Custom_Lead_Input  ls-025x inpt key-focus" [(ngModel)]="item.textinput" tabindex="0"
                  name="input_{{ item.key }}" #textfield="ngModel" [minRows]="1" placeholder="{{'placeholders.addYourTextHere' | translate}}">
                </textarea>
                <p *ngIf="showMinimumLengthE" style="color:red">{{'editcustom.PLEASE_ADD_MIN_LENGTH'||translate}} {{item.min_length}}</p>
                <div *ngIf="textfield.invalid && (textfield.dirty || textfield.touched)">
                  <span class="validationText ls-025x">{{'itemattributes.textField' | translate}} {{'editcustom.Required' | translate}}</span>
                </div>
              </div>  
          </div>
          </ng-container>
          <ng-container *ngIf="item.type == ipConstant && isNonHidden(item)">
            <div class="row g-0 editleadcustominputsrow" *ngIf="(item.settings !== 'AutoPickWhenCreate' || leadId && itemQueryType != 'TEMPLATE') && (item.settings !== 'AutoPickWhenUpdate' || leadId && itemQueryType != 'TEMPLATE')">
              <div class="col-md-3 fieldLabelBox f-l-r " role="button">
                <app-dashboard-icon [name]="item.type" [iconJson]="attributesIcons" class="labelimg" [size]="14"></app-dashboard-icon>
                <span class="labelText" appOverflowCheck [ngbTooltip]>{{ item.display_key }}</span><span *ngIf="item.mandatory" style="color: red;">*</span>
              </div>
                <div class="col-md-9 ipColumn fieldValueBox">
                    <div class="ipInputBox">
                      <input placeholder="000.0.0.0" [required]="item.mandatory" pattern="^(?!.*\.$)((?!0\d)(1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$" [readonly]="isPreview || isItemLocked(item.locked) || item.settings != 'Manual' || isReadOnly"
                            (blur)="!isReadOnlyField(item.locked) && item.settings == 'Manual' && onValueInput(arrayOfCustomFiled, ipConstant, item.key)" (input)="validationRuntime(item,item.ipInput)" type="string"
                            class="modal_Custom_Lead_Input_ND  ls-025x ipInput key-tab" [(ngModel)]="item.ipInput" tabindex="0"
                            name="input_{{ item.key }}" #ipField="ngModel" />
                      <span class="ip-btn key-tab" tabindex="0" *ngIf="item.settings == 'Manual'" [disabled]="isPreview" (click)="pickIp(item,ipConstant, item.key)">
                        <span class="iconBox"><img src="assets/1x/ip-type.svg" alt="ip"></span>
                        <span> {{'editcustom.pickIP'|translate}}</span>
                      </span>
                    </div>
                    <div *ngIf="ipField.invalid && (ipField.dirty || ipField.touched)">
                      <div *ngIf="ipField?.errors?.required">
                        <span class="validationText ls-025x">{{'itemattributes.ip' | translate}} {{'editcustom.Required' | translate}}. </span>
                      </div>
                      <div *ngIf="ipField?.errors?.pattern">
                        <span class="validationText ls-025x">{{'editcustom.enterCorrectIP' | translate}}</span>
                      </div>
                    </div>
                  </div>
              </div>
            </ng-container>
          <ng-container *ngIf="item.type == numberField && isNonHidden(item)">
            <div class="row g-0 editleadcustominputsrow key-tab">
              <div class="col-md-3 fieldLabelBox f-l-r " role="button">
                <app-dashboard-icon [name]="item.type" [iconJson]="attributesIcons" class="labelimg" [size]="14"></app-dashboard-icon>
                <span class="labelText" appOverflowCheck [ngbTooltip]>{{ item.display_key }}</span><span *ngIf="item.mandatory" style="color: red;">*</span>
              </div>
              <div class="col-md-9 p-0 fieldValueBox" *ngIf="item?.number_display?.display_type!=='DURATION'">
                <div class="numericBox d-inline-flex">
                  <span *ngIf="item.label && item.align=='left'" class="mr-2">{{item.label}}</span>
                  <!-- (input)="validateInput($event,item,index);validationRuntime(item,item.numinput)" -->
                  <input *ngIf="!item?.show_display" id="number_field_{{index}}"  placeholder="{{'placeholders.addYourNumberHere' | translate}}" (input)="onkeyPressForNumber($event,item,index);validateInput($event,$event,item,index);validationRuntime(item,item.numinput)" [required]="item.mandatory" [readonly]="isPreview  || isItemLocked(item.locked) || isReadOnly"
                    (blur)="!isReadOnlyField(item.locked) && onValueInput(arrayOfCustomFiled, numberField, item.key);item.show_display=true"
                    class="  rounded  ls-025x numberInput key-focus" tabindex="0"
                    [(ngModel)]="item.numinput" name="input_{{ item.key }}" #numberdfield="ngModel" [ngStyle]="item.numinput==''?{'min-width': '30px'}:{'width.ch': (''+item.numinput).length+1}" />
                  <span *ngIf="item.label && item.align=='right'">{{item.label}}</span>  
                  <div *ngIf="item?.show_display" (click)="focusInputOfNumber(index,item);" style="min-height: 30px;width: 100%;" class="rounded ls-025x numberInput key-focus">
                    {{item.numinput | numberTransform:item?.number_display?.number_of_decimal_digits:item?.number_display?.display_type}}
                  </div>
                </div>
                <!-- <div *ngIf="(!(item.numinput<0 || item.numinput>0 || item.numinput==0 || item.numinput==null) || numberdfield.invalid) && (numberdfield.dirty || numberdfield.touched)">
                  <span class="validationText ls-025x">{{'itemattributes.number' | translate}} {{'editcustom.Required' | translate}}</span>
                </div>  -->
                <ng-container *ngIf="item['error']?.length>0">
                  <div  style="color:red;">{{item['error']}}</div>
                </ng-container>
              </div>

              <div class="col-md-9 p-0 fieldValueBox"  *ngIf="item?.number_display?.display_type==='DURATION'">
                <div class="numericBox d-inline-flex">
                 <app-number-as-duration [value]="item.numinput" name="input_{{ item.key }}" [readonly]="isPreview  || isItemLocked(item.locked) || isReadOnly" (valueUpdated)="item.numinput=$event;onValueInput(arrayOfCustomFiled, numberField, item.key)"></app-number-as-duration>
                </div> 
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="item.type == dateField && isNonHidden(item) && !item?.start_date_key">
            <div class="row g-0 editleadcustominputsrow key-tab">
              <div class="col-md-3 fieldLabelBox f-l-r " role="button">
                <app-dashboard-icon [name]="item.type" [iconJson]="attributesIcons" class="labelimg" [size]="14"></app-dashboard-icon>
                <span class="labelText" appOverflowCheck [ngbTooltip]>{{ item.display_key }}</span><span *ngIf="item.mandatory" style="color: red;">*</span>
              </div>
              <div class="col-md-9 dateColumn fieldValueBox">
                <ng-container *ngIf="!(isPreview || isItemLocked(item.locked))">
                  <form class="form-inline border-0 rounded inpt p-0">
                    <div class="form-group w-100">
                      <div class="input-group d-inline-flex datePickerBox">
                        <div class="d-inline-flex key-focus" style="column-gap: 5px; align-items: center;" [ngbPopover]="!isReadOnly && datePopover" #popDate="ngbPopover" popoverClass="datePickerPopover" placement="auto" container="body"
                          #popOver="ngbPopover" [autoClose]="'outside'" (click)="$event.stopPropagation()" tabindex="0">
                          <div *ngIf="(!item?.is_end_date && !item.dateinput?.date) || (item?.is_end_date && !item.dateinput?.from)" class="d-inline-flex" style="column-gap: 5px; align-items: center;">
                            <span class="iconBox"><img src="../../../assets/1x/calendar-6d6e6f.svg"></span>
                            <span *ngIf="(!item?.is_end_date && !item.dateinput?.date) || (item?.is_end_date && !item.dateinput?.from)" class="text6d6e6f">DD-MM-YYYY</span>
                          </div>
                          <div *ngIf="item.dateinput" class="d-inline-flex">
                            <span *ngIf="(!item?.is_end_date && item.dateinput?.date)">{{ item?.dateinput | dateCalcuate:"dateTime"}}</span>
                            <span *ngIf="item.dateinput?.from">{{ item?.dateinput?.from?.time | date:'dd MMM yyyy'}}</span>
                            <span *ngIf="item?.dateinput?.to"> - </span>
                            <span *ngIf="item?.dateinput?.to">{{ item?.dateinput?.to?.time | date:'dd MMM yyyy'}}</span>
                          </div>
                        </div>                  
                        <ng-template #datePopover>
                          <!-- [dateRange]="['DAYS', 'MONTH'].includes(item?.dateValidationJson?.date_range?.date_range_type)?item?.dateValidationJson?.dateRange:{}" -->
                          <date-picker 
                          [startEndDatePicker]="item?.is_end_date?true:false"
                          [dateConfig]="item?.dateValidationJson.dateConfig"
                          [enableTimeEditor]="!item?.dateValidationJson.disable_time"
                          [dateRange]="item?.is_end_date?{'from':item.dateinput?.from?item?.dateinput?.from:item?.dateinput,'to':item.dateinput?.to?item.dateinput?.to:item?.dateinput}:{'from':item?.dateinput}"
                          [focusDate]="item.dateinput"
                          (onDateSave)="item.dateinput = $event; onValueInput(arrayOfCustomFiled, dateField, item.key); popDate.close()"
                          (onDateClear)="item.dateinput = null; onValueInput(arrayOfCustomFiled, dateField, item.key);popDate.close()">
                          </date-picker>
                        </ng-template>
                      </div>
                    </div>
                  </form>
                </ng-container>
                <ng-container *ngIf="isPreview  || isItemLocked(item.locked)">
                  <form class="form-inline border-0 rounded inpt">
                    <div class="form-group">
                      <div class="d-inline-flex ml-2 mb-2 mt-2">
                          <div class="d-inline-block">
                            <img src="../../../assets/1x/calender-ico.svg">
                          </div>
                          <div *ngIf="item.dateinput" class="d-inline-flex ml-2">
                            {{ item?.dateinput | dateCalcuate:"dateTime"}}
                          </div>
                        </div>  
                    </div>
                  </form>
                </ng-container>
    
                <ng-template #footerTemplate>
                  <hr class="my-0" />
                  <button class="btn btn-primary btn-sm m-2 float-left" (click)="item.dateinput = today; d.close()">
                    {{'editcustom.today' | translate}}
                  </button>
                  <button class="btn btn-secondary btn-sm m-2 float-right" (click)="d.close()">
                    {{'editcustom.close' | translate}}
                  </button>
                </ng-template>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="item.type == radioField && isNonHidden(item)">
            <div class="row g-0 editleadcustominputsrow key-tab" *ngIf="item?.permissible_values?.length > 0">
                <div class="row g-0 mx-0 w-100" style="justify-content: space-between; align-items: center;">
                  <div class="row g-0 mx-0 fieldLabelBox f-l-r " role="button">
                    <app-dashboard-icon [name]="item.type" [iconJson]="attributesIcons" class="labelimg" [size]="14"></app-dashboard-icon> 
                    <span class="labelText" appOverflowCheck [ngbTooltip]>{{ item.display_key }}</span><span *ngIf="item.mandatory" style="color: red;">*</span>
                  </div>
                </div>
                <div class="row g-0 mx-0 w-100 radioColumn fieldValueBox key-focus" tabindex="0">
                  <div class="optionsRow w-100 rounded p-0">
                    <div class="row g-0 mx-0">
                      <label *ngIf="!item.mandatory && !isReadOnly && !isReadOnlyField(item.locked)" class="text-danger cursorPointer" [hidden]="!item.enableClear && (customFieldDataOfLead[index]?.value==null || customFieldDataOfLead[index]?.value==='')"
                      (click)="selectedCustomId('');
                      onValueInput(arrayOfCustomFiled,radioField,item.key);
                      item.enableClear=false; 
                      customFieldDataOfLead[index].value=null" class="key-updown" tabindex="0">
                          <input type="radio" name="{{ item?.key }}" [value]="item?.value" [checked]="" [hidden]="true" />
                          {{'form.clearMySelection'|translate}}
                      </label>

                      <div class="radioOptionBox"
                        *ngFor="let data of item.permissible_values">
                        <div class="dropDownField" [ngStyle]="{'background-color':data.value.c ? data.value.c : '#ffffff'}">
                          <ng-container *ngIf="!(isPreview  || isItemLocked(item.locked) || isReadOnly)">
                            <input type="radio" style="min-height: 18px;" (blur)="!isReadOnlyField(item.locked) && 
                                onValueInput(
                                  arrayOfCustomFiled,
                                  radioField,
                                  item.key
                                )
                              " name="{{ item.key }}" [value]="item.value" [checked]="data.radioinput" (change)="
                                selectedCustomId(index);
                                selectedPermittedId(data.id);
                                setDataOfRadio(
                                  $event,
                                  arrayOfCustomFiled,
                                  radioField,
                                  item.key
                                );item.enableClear=true
                              " 
                              class="key-updown" tabindex="0"
                              />
                            <label class="optionTexts ls-025x" [ngStyle]="{'color': commonUtils.getContrastColor(data.value.c)}">{{ data.value.k }}</label>
                          </ng-container>
                          <ng-container *ngIf="isPreview  || isItemLocked(item.locked) || isReadOnly">
                            <div class="dropDownField" [ngStyle]="{'background-color':data.value.c ? data.value.c : '#ffffff'}">
                              <input type="radio" name="{{ item.key }}" [value]="item.value" [checked]="data.radioinput" disabled readonly (change)="item.enableClear=true">
                              <label class="optionTexts  ls-025x" [ngStyle]="{'color': commonUtils.getContrastColor(data.value.c)}">{{ data.value.k}}</label>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                      </div>
                  </div>
                </div>
            </div>
          </ng-container>
          <ng-container *ngIf="item.type == checkField && isNonHidden(item)">
            <div class="row g-0 editleadcustominputsrow key-tab" *ngIf="item?.permissible_values?.length > 0">
              <div class="row g-0 mx-0 fieldLabelBox f-l-r " role="button">
                <app-dashboard-icon [name]="item.type" [iconJson]="attributesIcons" class="labelimg" [size]="14"></app-dashboard-icon> 
                <span class="labelText" appOverflowCheck [ngbTooltip]>{{ item.display_key }}</span><span *ngIf="item.mandatory" style="color: red;">*</span>
              </div>
              <div class="row g-0 mx-0 w-100 fieldValueBox checkColumn">
                <div class="optionsRow w-100 p-0 key-focus" tabindex="0">
                  <div class="row g-0 mx-0">
                    <div class="radioOptionBox" *ngFor="let data of item.permissible_values">
                      <div class="dropDownField" [ngStyle]="{'background-color':data.value.c ? data.value.c : '#ffffff'}">
                        <ng-container *ngIf="!(isPreview  || isItemLocked(item.locked) || isReadOnly)">
                          <input type="checkbox" style="min-height: 18px;" [(ngModel)]="data.checkinput" name="data.checkinput_{{ data.id }}" 
                          class="key-updown" tabindex="0"
                          (change)="selectedCustomId(index);selectedPermittedId(data.id);setDataOfCheckBox(arrayOfCustomFiled,checkField,item.key)" />
                          <label class="optionTexts  ls-025x" [ngStyle]="{'color': commonUtils.getContrastColor(data.value.c)}">{{ data.value.k }}</label>
                        </ng-container>
                        <ng-container *ngIf="isPreview  || isItemLocked(item.locked) || isReadOnly">
                          <div class="dropDownField" [ngStyle]="{'background-color':data.value.c ? data.value.c : '#ffffff'}">
                            <input type="checkbox" [disabled]="true" disabled [(ngModel)]="data.checkinput"
                            name="data.checkinput_{{ data.id }}" readonly />
                          <label class="optionTexts  ls-025x" data-toggle="tooltip" data-placement="top" 
                            title="{{ data.value }}" [ngStyle]="{'color': commonUtils.getContrastColor(data.value.c)}">{{ data.value.k }}</label>
                          </div>
                        </ng-container>
                      </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="item.type == dropDownField && isNonHidden(item)">
            <div class="row g-0 editleadcustominputsrow key-tab">
              <div class="col-md-3 fieldLabelBox f-l-r "role="button">
                <app-dashboard-icon [name]="item.type" [iconJson]="attributesIcons" class="labelimg" [size]="14"></app-dashboard-icon>
                <span class="labelText" appOverflowCheck [ngbTooltip]>{{ item.display_key }}</span><span *ngIf="item.mandatory" style="color: red;"></span>
                </div>
              <div class="col-md-9 dropdownColumn fieldValueBox hoverNgSelectP">
                <div *ngIf="item?.permissible_values?.length > 0" 
                    class="w-100 row align-items-stretch">
                    <div class="col dropDownSpacing">
                      <ng-select 
                        placeholder="{{'placeholders.select' | translate}}" 
                        [virtualScroll]="true" 
                        bindLabel='v' 
                        bindValue="k"
                        [items]="item.permissible_values" 
                        class="custom key-focus" 
                        tabindex="0" 
                        [readonly]="isPreview || isItemLocked(item.locked) || isReadOnly" 
                        [multiple]="false" 
                        [closeOnSelect]="true"                       
                        [clearable]="!item.mandatory" 
                        [(ngModel)]="item.p_input" 
                        (change)="selectedItem($event,item);">
                        <ng-template ng-option-tmp let-item="item">
                          <div [style.background-color]="item.c || 'white'" [ngStyle]="{'color': commonUtils.getContrastColor(item?.c)}" class="custom-option">
                            {{ item.v }}
                          </div>
                        </ng-template>
                        <ng-template ng-label-tmp let-item="item">
                          <div [style.background-color]="item.c || 'white'" [ngStyle]="{'color': commonUtils.getContrastColor(item?.c)}" class="custom-options">
                            {{ item.v }}
                          </div>
                        </ng-template>
                      </ng-select>                 
                    </div>
                    
                    <!-- <div class="col-1 p-0 mt-1">
                      <span class="d-inline-block" style="width: 1.5rem; height: 1.5rem;border-radius: 5px;" [ngStyle]="{'background-color': getColorFromObject(item.permissible_values, item.p_input)}"></span>
                    </div> -->
                  
                  </div>
              </div>
            </div>
          </ng-container>
          <!-- single video -->
          <ng-container *ngIf="item.type == customFieldType.VIDEO && isNonHidden(item)">
            <div class="row g-0 editleadcustominputsrow">
              <div class="row g-0 mx-0 d-inline-flex w-100" style="justify-content: space-between; align-items: center;">
                <div class="fieldLabelBox f-l-r col" role="button">
                  <span class="labelimg"><app-dashboard-icon [name]="item.type" [iconJson]="attributesIcons" class="labelimg" [size]="14"></app-dashboard-icon></span>
                  <span class="labelText" appOverflowCheck [ngbTooltip]>{{ item.display_key }}</span><span *ngIf="item.mandatory" style="color: red;">*</span>    
                </div>
                <!-----Loader-->
                <div class="loaderBox pl-0 col" *ngIf='item.isLoaded'>
                  <button class="btn btn-primary" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      {{'editcustom.loading' | translate}}  
                  </button>
                </div>
                <!------Upload and screen reording button-->
                <ng-container *ngIf="!(isPreview  || isItemLocked(item.locked) || isReadOnly)">
                  <div  *ngIf="!item.isLoaded && !item?.fileObjArr?.length>0" class="d-flex justify-content-end text-center col p-0" style="column-gap: 10px;">
                   
                    <div class="mediaUploadButton key-tab" *ngIf="!(recordingStarted && selectedKey==item.key)">
                      <span class="addCreateConnectBtn key-focus" tabindex="0" (click)="singleVideoFile.value='';uploadPopover.isOpen()? uploadPopover.close(): uploadPopover.open(); getImageFile();setSelectedMediaField(item,customFieldType.VIDEO);" class="addCreateConnectBtn">
                        <span class="iconBox"><i class="fa fa-cloud-upload" aria-hidden="true" id="userIcon"></i></span>
                        <span (click)="setSelectedMediaField(item,customFieldType.VIDEO);selectedFileType=customFieldType.VIDEO;selectedKeyLabel=item.key;selectedMediaFieldJson=item">{{'common.upload'|translate}}</span>
                      </span>
                      <app-drive-attachment #uploadPopover 
                        [leadInfo]="leadObj" [dashId]="dashId" [item]="item" [sprintArr]="dashBoardSprintArr" 
                        [orgUsersJson]="orgUsersJson" [singleSelection]="true" [typeOfDoc]="'VIDEO'"  
                        (filesJson)="uploadUrlLink($event);uploadPopover.close()">
                      </app-drive-attachment>
                    </div>
                    <div class="screenRecordingBox key-tab" *ngIf="!item?.isRecordingStopped">
                      <div class="addCreateConnectBtn key-focus" tabindex="0" *ngIf="!recordingStarted || selectedKey != item.key" [ngbPopover]="!isReadOnly && !timerInterval && recordPopover" #popRecord="ngbPopover" placement="auto">
                        <span class="iconBox"><i  class="fa fa-dot-circle-o"  aria-hidden="true"></i></span>
                        <span>{{'editcustom.startRecording' | translate}}</span>
                      </div>
                      <span *ngIf="recordingStarted && selectedKey==item.key" >{{recordingTimeForVideo}}</span>
                      <div class="addCreateConnectBtn key-focus" tabindex="0" *ngIf="recordingStarted && selectedKey==item.key"  (click)="item.isRecordingStopped=true">
                        <span class="iconBox" ><i style="color:rgb(255, 84, 76);" class="fa fa-stop-circle-o" aria-hidden="true"></i></span>
                        <span > {{'editcustom.stopRecording' | translate}}</span>
                      </div>
                      <ng-template #recordPopover>
                        <div (click)="requestMediaPermission(true, customFieldType.VIDEO, item.key)" class="cursorPointer">{{'editcustom.WITH_AUDIO'|translate}}</div>
                        <div (click)="requestMediaPermission(false, customFieldType.VIDEO, item.key)" class="cursorPointer">{{'editCustom.WITHOUT_AUDIO'|translate}}</div>
                      </ng-template>
                    </div>
                    <div  class="fieldValueBox key-tab" #ele *ngIf="item?.isRecordingStopped">
                      <input type="text" placeholder="{{'placeholders.nameRecording' | translate}}" [(ngModel)]="recordingName"  class="key-focus"
                      (keyup.enter)="recordingName && ele?.blur()"  (blur)="recordingName && stopScreenRecording(item)"  style="border-radius: 7px !important; border: 1px solid #85acefef !important;">
                    </div>
                  </div>
                </ng-container>
              </div>
              <input #singleVideoFile type="file" class="singleImageFile" (change)="
                            onUploadFile($event.target.files, customFieldType.VIDEO, item.key)
                          " name="singleImageFile" accept="video/*" single />
              <!----WHen No video Exist-->
              <div class="row mx-0 w-100 mediaColumn fieldValueBox" *ngIf="!item?.fileObjArr?.length>0" id="mainMultiImageDiv" (dragenter)="onDragEnter(item.key)">
                <div id="singleImageSec" (drop)="onDrop($event, customFieldType.VIDEO, item.key)"
                  (dragover)="allowDrop($event)"  class="mediaBox mx-0 row w-100">
                  <ng-container *ngIf="!(isPreview  || isItemLocked(item.locked) || isReadOnly) && item?.fileObjArr?.length==0">
                      <div class="dropZone" (dragleave)="onDragLeave(item.key)" [id]="'drop_'+item.key">
                        <p class="text6d6e6f m-0">{{'editcustom.dropHere' | translate}}</p>
                      </div>
                      <div class="row mx-0 g-0 w-100" style="align-items: center; justify-content: center;">
                        <span class="text6d6e6f">{{'editcustom.DRAG_AND_DROP_VIDEO'|translate}}</span>
                      </div>
                  </ng-container>
                  <ng-container *ngIf="isPreview  || isItemLocked(item.locked) || isReadOnly">
                    <div class="row mx-0 w-100 g-0" style="align-items: center; justify-content: center;">
                      <span class="text6d6e6f">{{'editcustom.DRAG_AND_DROP_VIDEO'|translate}}</span>
                    </div>
                  </ng-container>
                </div>
              </div>
              <!----When Video Exist-->
              <ng-container *ngIf="item?.fileObjArr?.length>0">
                <div class="row mx-0 mediaColumn fieldValueBox" >
                  <ng-container *ngIf="!item.isLoaded">
                    <div class="mx-0 w-100 mediaBox d-flex" >
                      <div class="showImageBox">
                        <div  class="show-image"
                        [tooltip]="item.fileObjArr[0]?.name?.length > 8 ? item.fileObjArr[0]?.name + '.' + ((item.fileObjArr[0]?.type!=='link' && item.fileObjArr[0]?.type!=='one_drive' && item.fileObjArr[0]?.type!=='google_drive')?item?.fileObjArr[0]?.extension || '':'') : ''" placement="top" container="body" containerClass="tooltipPopup">
                          <ng-container  *ngIf="commonUtils.checkSupportVideoExtension(item.fileObjArr[0]?.extension)">
                            <app-dashboard-icon *ngIf="item?.fileObjArr[0]?.type==='google_drive'" style="margin-top: -3px !important;" [name]="'googleDrive'" [iconJson]="attributesIcons" [size]="17" class="labelimg"></app-dashboard-icon>
                            <app-dashboard-icon *ngIf="item?.fileObjArr[0]?.type==='one_drive'" style="margin-top: -3px !important;" [name]="'oneDrive'" [iconJson]="attributesIcons" [size]="17" class="labelimg"></app-dashboard-icon>
                            <video *ngIf="item.fileObjArr[0]?.type!=='link' && item.fileObjArr[0]?.type!=='one_drive' && item.fileObjArr[0]?.type!=='google_drive'" controls id="videoTagForCustomField">
                              <source  [src]="item.fileObjArr[0]?.image_download_url" type="video/mp4" />
                            </video>
                            <span *ngIf="item.fileObjArr[0]?.type==='link' || item.fileObjArr[0]?.type==='one_drive' || item.fileObjArr[0]?.type==='google_drive'">
                              <app-dashboard-icon *ngIf="item.fileObjArr[0]?.type!=='link'" [name]="item.fileObjArr[0]?.extension" [iconJson]="mediaIcons" size="96"></app-dashboard-icon>
                              <app-dashboard-icon *ngIf="item.fileObjArr[0]?.type==='link'" [name]="'url'" [iconJson]="mediaIcons" size="96"></app-dashboard-icon>
                            </span>
                          </ng-container>
                          <ng-container  *ngIf="!commonUtils.checkSupportVideoExtension(item.fileObjArr[0]?.extension)">
                            <app-dashboard-icon [name]="item.fileObjArr[0]?.extension" [iconJson]="mediaIcons" size="98"></app-dashboard-icon>
                          </ng-container>
                          <div class="middle">
                            <span class="btn image-options-for-singleimage" style="height: 32px; width: 100%;" (click)="singleVideoFile.value='';uploadPopover.isOpen()? uploadPopover.close(): uploadPopover.open(); getImageFile();setSelectedMediaField(item,customFieldType.VIDEO);selectedFileType=customFieldType.VIDEO;selectedKeyLabel=item.key;selectedMediaFieldJson=item">
                              <img src="../../../../assets/1x/change-btn.png" class="h-100" />
                            </span>
                            <app-drive-attachment #uploadPopover 
                              [leadInfo]="leadObj" [dashId]="dashId" [item]="item" [sprintArr]="dashBoardSprintArr" 
                              [orgUsersJson]="orgUsersJson" [singleSelection]="true" [typeOfDoc]="'VIDEO'"  
                              (filesJson)="uploadUrlLink($event);uploadPopover.close()">
                            </app-drive-attachment>
                            <br />
                            <span *ngIf="item.fileObjArr[0]?.type!=='link' && item.fileObjArr[0]?.type!=='one_drive' && item.fileObjArr[0]?.type!=='google_drive'" class="btn image-options-for-singleimage"
                              (click)="openModalForPreview(item,0,customFieldType.VIDEO,videoPlayerModalNested)">
                              <img src="../../../../assets/1x/play-btn.png" />
                            </span>
                            <a *ngIf="item.fileObjArr[0]?.type==='link' || item.fileObjArr[0]?.type==='one_drive' || item.fileObjArr[0]?.type==='google_drive'" href="{{item.fileObjArr[0]?.path}}" class="image-options-for-singleImage" target="_blank">
                              <img src="../../../../assets/1x/play-btn.png" class="image-icon-for-eyeIcon-single"/>
                            </a>
                            <span class="image-options-for-delete" (click)="setDeletedata(0, item, item.key);
                                openDeleteModal(deleteModal)" *ngIf="!isReadOnlyField(item.locked)">
                              <img src="../../../../assets/1x/delete-btn.png" class="image-icon-for-deleteIcon" />
                            </span>
                          </div>
                        </div>
                        <span class="d-flex flex-row justify-content-center w-100 fileNameEdit">
                          <span class="fileName ">{{item.fileObjArr[0]?.name}}.{{item.fileObjArr[0]?.extension}}</span>
                          <span *ngIf="!isPreview && !isReadOnlyField(item.locked)" class="ml-2 iconBox" (click)="openRenameModal(renameMediaModal, item.fileObjArr[0], item)">
                            <img src="assets/common-use-icon-20dp/edit-6d6e6f.svg" class="editIcon6d6e6f renameImageIcon" alt="">
                            <img src="assets/common-use-icon-20dp/edit-3571de.svg" class="editIcon3571de renameImageIcon" alt="">
                          </span> 
                        </span>
                        <input #singleVideoFile type="file" class="singleImageFile" (change)="
                            onUploadFile($event.target.files, customFieldType.VIDEO, item.key)
                          " name="singleImageFile" accept="video/*" single />
                      </div>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
          </div>
          </ng-container>
          <!-- multi video  -->
          <ng-container *ngIf="item.type == customFieldType.MULTIVIDEO && isNonHidden(item)">
            <div class="row g-0 editleadcustominputsrow">
              <div class="row g-0 mx-0 d-inline-flex w-100" style="justify-content: space-between; align-items: center;">
                <div class="fieldLabelBox f-l-r col" role="button">
                  <app-dashboard-icon [name]="item.type" [iconJson]="attributesIcons" class="labelimg" [size]="14"></app-dashboard-icon>
                  <span class="labelText" appOverflowCheck [ngbTooltip]>{{ item.display_key }}</span><span *ngIf="item.mandatory" style="color: red;">*</span>
                </div>
                <!-----Loader-->
                <div class="loaderBox pl-0 col" *ngIf='item.isLoaded' id="imageTypeName">
                  <button class="btn btn-primary" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      {{'editcustom.loading' | translate}}  
                  </button>
                </div>
                <!------Upload and screen reording button-->
                <ng-container *ngIf="!(isPreview  || isItemLocked(item.locked) || isReadOnly)">
                  <div  *ngIf="!item.isLoaded" class="d-inline-flex col p-0" style="justify-content: end; align-items: center; column-gap: 10px;">
                   
                    <div class="mediaUploadButton key-tab">
                      <span class="addCreateConnectBtn key-focus" tabindex="0" (click)="multiVideoFile.value='';uploadPopover.isOpen()? uploadPopover.close(): uploadPopover.open(); getMultiImageFile();setSelectedMediaField(item,customFieldType.MULTIVIDEO);">
                        <span class="iconBox"><i class="fa fa-cloud-upload" aria-hidden="true" id="userIcon"></i></span>
                        <span (click)="setSelectedMediaField(item,customFieldType.MULTIVIDEO);selectedFileType=customFieldType.MULTIVIDEO;selectedKeyLabel=item.key;selectedMediaFieldJson=item">{{'common.upload'|translate}}</span>
                      </span>
                      <app-drive-attachment #uploadPopover 
                        [leadInfo]="leadObj" [dashId]="dashId" [item]="item" [sprintArr]="dashBoardSprintArr" 
                        [orgUsersJson]="orgUsersJson" [singleSelection]="false" [typeOfDoc]="'VIDEO'"  
                        (filesJson)="uploadUrlLink($event);uploadPopover.close()">
                      </app-drive-attachment>
                    </div>
                    <div class="screenRecordingBox key-tab" *ngIf="!item?.isRecordingStopped">
                      <div class="addCreateConnectBtn key-focus" tabindex="0" *ngIf="!recordingStarted || selectedKey != item.key" [ngbPopover]="!isReadOnly && !timerInterval && recordPopover" #popRecord="ngbPopover" placement="auto">
                        <span class="iconBox"><i  class="fa fa-dot-circle-o"  aria-hidden="true"></i></span>
                        <span>{{'editcustom.startRecording' | translate}}</span>
                      </div>
                      <span *ngIf="recordingStarted && selectedKey==item.key">{{recordingTimeForVideo}}</span>
                      <div class="addCreateConnectBtn key-focus"  *ngIf="recordingStarted && selectedKey==item.key"  (click)="item.isRecordingStopped=true" tabindex="0">
                        <span class="iconBox" ><i style="color:rgb(255, 84, 76);" class="fa fa-stop-circle-o" aria-hidden="true"></i></span>
                        <span > {{'editcustom.stopRecording' | translate}}</span>
                      </div>
                      <ng-template #recordPopover>
                        <div (click)="requestMediaPermission(true, customFieldType.MULTIVIDEO, item.key)" class="cursorPointer">{{'editcustom.WITH_AUDIO'|translate}}</div>
                        <div (click)="requestMediaPermission(false, customFieldType.MULTIVIDEO, item.key)" class="cursorPointer">{{'editcustom.WITHOUT_AUDIO'|translate}}</div>
                      </ng-template>
                    </div>
                    <div  class="fieldValueBox key-tab" *ngIf="item?.isRecordingStopped">
                      <input type="text" #ele placeholder="{{'placeholders.nameRecording' | translate}}" [(ngModel)]="recordingName"  class="key-focus" tabindex="0"
                      (keyup.enter)="recordingName && ele?.blur()"  (blur)="recordingName && stopScreenRecording(item)"  style="border-radius: 7px !important; border: 1px solid #85acefef !important;">
                    </div>
                  </div>                
                  </ng-container>
              </div>
              <input #multiVideoFile type="file" id="multiImageFile" name="multiImageFile" (change)="
                      onUploadFile($event.target.files, customFieldType.MULTIVIDEO, item.key)
                      " accept="video/*" multiple />
              <div class="row mx-0 w-100 mediaColumn fieldValueBox" id="multiImageSec" (drop)="onDrop($event, customFieldType.MULTIVIDEO, item.key)"
                (dragover)="allowDrop($event)"> 
                <!-----When Video Is not empty-->
                <div *ngIf="!item?.fileObjArr?.length>0" id="singleImageSec" (dragenter)="onDragEnter(item.key)" class="mediaBox mx-0 row w-100">
                  <ng-container *ngIf="!(isPreview  || isItemLocked(item.locked) || isReadOnly)">
                    <div class="dropZone" (dragleave)="onDragLeave(item.key)" [id]="'drop_'+item.key">
                    <p class="text6d6e6f m-0">{{'editcustom.dropHere' | translate}}</p>
                    </div> 
                    <div class="row mx-0 w-100 g-0" style="align-items: center; justify-content: center;">
                      <span class="text6d6e6f">{{'editcustom.DRAG_AND_DROP_VIDEO'|translate}}</span>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="isPreview  || isItemLocked(item.locked) || isReadOnly">
                    <div class="row mx-0 w-100 g-0 " style="align-items: center; justify-content: center;">
                      <span class="text6d6e6f"> {{'editcustom.DRAG_DROP_COPY_PASTE_IMAGE'|translate}}</span>
                    </div>
                  </ng-container>
                </div>
                <!-----When Video Is not empty-->
                <ng-container *ngIf="item?.fileObjArr?.length>0">
                  <div class="mx-0 w-100 mediaBox d-flex" (dragenter)="onDragEnter(item.key)" id="singleImageSec">
                    <ng-container *ngFor="let file of item?.fileObjArr let i = index">
                      <ng-container  *ngIf="isVideoExpanded || i<9">
                        <div class="showImageBox"  id="multipleImagePreview" *ngIf="commonUtils.checkSupportVideoExtension(file?.extension)"
                        [tooltip]="file?.file_name?.length > 5 ? file?.file_name + '.' + ((file?.type!=='link' && file?.type!=='one_drive' && file?.type!=='google_drive')?file?.extension || '' :'') : ''" placement="top" container="body" containerClass="tooltipPopup" >
                              <div class="show-image" id="multiImageDiv">
                                <app-dashboard-icon *ngIf="file?.type==='google_drive'" style="margin-top: -3px !important;" [name]="'googleDrive'" [iconJson]="attributesIcons" [size]="17" class="labelimg"></app-dashboard-icon>
                                <app-dashboard-icon *ngIf="file?.type==='one_drive'" style="margin-top: -3px !important;" [name]="'oneDrive'" [iconJson]="attributesIcons" [size]="17" class="labelimg"></app-dashboard-icon>
                                <video *ngIf="file?.type!=='link' && file?.type!=='one_drive' && file?.type!=='google_drive'" #myVideo id="videoTagForCustomField">
                                  <source [src]="file.image_download_url" type="video/mp4" />
                                </video>
                                <span *ngIf="file?.type==='link' || file?.type==='one_drive' || file?.type==='google_drive'">
                                  <app-dashboard-icon *ngIf="file?.type!=='link'" [name]="file.extension" [iconJson]="mediaIcons" size="100"></app-dashboard-icon>
                                  <app-dashboard-icon *ngIf="file?.type==='link'" [name]="'url'" [iconJson]="mediaIcons" size="100"></app-dashboard-icon>
                                </span>
                                <div class="middle">
                                  <br />
                                  <span *ngIf="file?.type!=='link' && file?.type!=='one_drive' && file?.type!=='google_drive'" class="btn image-options-for-singleimage" (click)="
                                  openModalForPreview(item,i,customFieldType.MULTIVIDEO,videoPlayerModalNested)" >
                                    <img src="../../../../assets/1x/play-btn.png" />
                                </span> 
                                <a *ngIf="file?.type==='link' || file?.type==='one_drive' || file?.type==='google_drive'" [href]="file?.file_path || file?.path" class="image-options-for-singleImage" target="_blank">
                                  <img src="../../../../assets/1x/play-btn.png" class="image-icon-for-eyeIcon-single"/>
                              </a>
                                <a *ngIf="file?.type!=='link' && file?.type!=='one_drive' && file?.type!=='google_drive'" (click)="downloadImg(item?.fileObjArr[i]?.image_download_url,item?.fileObjArr[i]?.name,item?.fileObjArr[i]?.extension)" >
                                  <span class="btn image-options-for-singleimage">
                                    <img src="../../../../assets/1x/doc-whitedownload.svg" class="image-icon-for-deleteIcon"/>
                                  </span>
                                </a>
                                <a *ngIf="file?.type==='link' || file?.type==='one_drive' || file?.type==='google_drive'" [href]="file?.file_path || file?.path" class="image-options-for-singleImage" target="_blank" >
                                  <span class="btn image-options-for-singleimage">
                                    <img src="../../../../assets/1x/doc-whitedownload.svg" class="image-icon-for-deleteIcon"/>
                                  </span>
                                </a>
                                  <span class="btn singleImageCrossIcon" (click)="setDeletedata(i, item, item.key);
                                      openDeleteModal(deleteModal)" *ngIf="!isReadOnlyField(item.locked)">
                                    <img src="../../../../assets/1x/delete-btn.png" />
                                </span>
                                </div>
                              </div>
                                <span class="d-flex flex-row justify-content-between text1e1f21 fileNameEdit">
                                  <span class="fileName w-100"> {{file.name}}.{{file?.extension}}</span>
                                  <span class="iconBox" *ngIf="!isReadOnlyField(item.locked)" (click)="openRenameModal(renameMediaModal, file, item)">
                                    <img *ngIf="!isReadOnlyField(item.locked)"src="assets/common-use-icon-20dp/edit-6d6e6f.svg" class="editIcon6d6e6f renameImageIcon" alt="">
                                    <img *ngIf="!isReadOnlyField(item.locked)"src="assets/common-use-icon-20dp/edit-3571de.svg" class="editIcon3571de renameImageIcon" alt="">
                                  </span> 
                                </span>
                          </div>
                        <div class="showImageBox" id="multipleImagePreview" *ngIf="!commonUtils.checkSupportVideoExtension(file?.extension)"
                        [tooltip]="file?.name?.length > 5 ? file?.name + '.' + ((file?.type!=='link' && file?.type!=='one_drive' && file?.type!=='google_drive')?file?.extension || '':'') : ''" placement="top" container="body" [tooltipClass]="'tooltipPopup'">
                            <div class="show-image" id="multiImageDiv">
                                <app-dashboard-icon *ngIf="file?.type!=='link'" [name]="file?.extension" [iconJson]="mediaIcons" size="96"></app-dashboard-icon>
                                <app-dashboard-icon *ngIf="file?.type==='link'" [name]="'url'" [iconJson]="mediaIcons" size="96"></app-dashboard-icon>
                                <span class="d-flex flex-row justify-content-between text1e1f21 fileNameEdit">
                                  <span class="fileName w-100"> {{file.name}}.{{file?.extension}}</span>
                                  <span *ngIf="!isReadOnlyField(item.locked)" class="iconBox" (click)="openRenameModal(renameMediaModal, file, item)">
                                    <img src="assets/common-use-icon-20dp/edit-6d6e6f.svg" class="editIcon6d6e6f renameImageIcon" alt="">
                                    <img src="assets/common-use-icon-20dp/edit-3571de.svg" class="editIcon3571de renameImageIcon" alt="">
                                  </span>     
                                </span>
                            </div>
                        </div>
                      </ng-container>
                     
                    </ng-container>
                    
                    <ng-container *ngIf="!(isPreview  || isItemLocked(item.locked) || isReadOnly)">
                      <div class="dropBox">
                        <div class="dropZone" (dragleave)="onDragLeave(item.key)" [id]="'drop_'+item.key">
                          <p class="text6d6e6f m-0">
                          {{'editcustom.dropHere' | translate}}</p>
                        </div>
                        <div class="row mx-0 w-100 g-0" style="align-items: center; justify-content: center;">
                          <span class="text6d6e6f">{{'editcustom.DRAG_AND_DROP_VIDEO'|translate}}</span>
                        </div>
                      </div>
                    </ng-container>
                    <div *ngIf="item?.fileObjArr?.length > 9 && !isVideoExpanded" class="moredisimg" (click)="isVideoExpanded = true">
                      <strong>  {{'editcustom.showMore' | translate}}  </strong>
                    </div>
                    <div *ngIf="item?.fileObjArr?.length > 9 && isVideoExpanded" class="moredisimg" (click)="isVideoExpanded = false">
                      <strong>{{'editcustom.showLess' | translate}}</strong>
                    </div>
                </div> 
                </ng-container>
              </div>
            </div>
          </ng-container>
          <!------ single audio  -->
          <ng-container *ngIf="item.type == customFieldType.AUDIO && isNonHidden(item)">
            <div class="row editleadcustominputsrow">
              <div class="row g-0 mx-0 d-inline-flex w-100" style="justify-content: space-between; align-items: center;">
                <div class="fieldLabelBox f-l-r col" role="button">
                  <span class="labelimg"> <app-dashboard-icon [name]="item.type" [iconJson]="attributesIcons" class="labelimg" [size]="14"></app-dashboard-icon></span>
                  <span class="labelText" appOverflowCheck [ngbTooltip]>{{ item.display_key }}</span><span *ngIf="item.mandatory" style="color: red;">*</span>
                </div>
                <!-----Loader-->
                <div class="loaderBox pl-0 col" *ngIf='item.isLoaded' id="imageTypeName">
                  <button class="btn btn-primary" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      {{'editcustom.loading' | translate}}  
                  </button>
                </div>
                <!------Upload and screen reording button-->
                <ng-container *ngIf="!(isPreview  || isItemLocked(item.locked) || isReadOnly)">
                  <div  *ngIf="!item.isLoaded && !item?.fileObjArr?.length>0" class="d-inline-flex col p-0" style="justify-content: end; align-items: center; column-gap: 10px;">
                  
                    <div class="mediaUploadButton key-tab">
                      <span class="addCreateConnectBtn key-focus" tabindex="0" (click)="singleVideoFile.value='';uploadPopover.isOpen()? uploadPopover.close(): uploadPopover.open(); getImageFile();setSelectedMediaField(item,customFieldType.AUDIO);">
                        <span class="iconBox"><i class="fa fa-cloud-upload" aria-hidden="true" id="userIcon"></i></span>
                        <span (click)="setSelectedMediaField(item,customFieldType.AUDIO);selectedFileType=customFieldType.AUDIO;selectedKeyLabel=item.key;selectedMediaFieldJson=item">{{'common.upload'|translate}}</span>
                      </span>
                      <app-drive-attachment #uploadPopover 
                        [leadInfo]="leadObj" [dashId]="dashId" [item]="item" [sprintArr]="dashBoardSprintArr" 
                        [orgUsersJson]="orgUsersJson" [singleSelection]="true" [typeOfDoc]="'AUDIO'"  
                        (filesJson)="uploadUrlLink($event);uploadPopover.close()">
                      </app-drive-attachment>
                    </div>
                    <div class="screenRecordingBox key-tab" *ngIf="!item?.isRecordingStopped">
                      <div *ngIf="!recordingStarted || selectedKey != item.key"  class="addCreateConnectBtn key-focus" tabindex="0" (click)="!timerInterval && openAudioRecorder(customFieldType.AUDIO, item.key)">
                        <span class="iconBox"><i class="fa fa-dot-circle-o"  aria-hidden="true"></i></span>
                        <span>{{'editcustom.startRecording' | translate}}</span>
                      </div>
                      <span *ngIf="recordingStarted && selectedKey==item.key">{{recordingTimeForVideo}}</span>
                      <div *ngIf="recordingStarted && selectedKey==item.key" class="addCreateConnectBtn key-focus" (click)="item.isRecordingStopped=true;"  tabindex="0">
                        <span class="iconBox"><i style="color:rgb(255, 84, 76);" class="fa fa-stop-circle-o" aria-hidden="true"></i></span>
                        <span>{{'editcustom.stopRecording' | translate}}</span>
                      </div>
                    </div>
                    <div class="fieldValueBox key-tab" *ngIf="item?.isRecordingStopped">
                      <input #ele type="text" class="key-focus" tabindex="0" placeholder="{{'placeholders.nameRecording' | translate}}" [(ngModel)]="recordingName" (keydown.enter)="recordingName && ele?.blur()" (blur)="recordingName && stopAudioRecording(item)">
                    </div>
                  </div>
                </ng-container>
              </div>
              <input #singleVideoFile type="file" class="singleImageFile" (change)="
                          onUploadFile($event.target.files, customFieldType.AUDIO, item.key)
                        " name="singleImageFile" accept="audio/*" single />
              <ng-container *ngIf="item?.fileObjArr?.length>0">
                <div class="row mx-0 w-100 fieldValueBox mediaColumn" >
                  <ng-container *ngIf="!item.isLoaded">
                    <div class="mx-0 w-100 mediaBox">
                      <div class="singleAudioBox w-100 d-flex">
                        <div class="singleAudioDiv2">
                          <div class="videohldr" [tooltip]="item.fileObjArr[0]?.name?.length > 5 ? item.fileObjArr[0]?.name + '.' + ((item.fileObjArr[0]?.type!=='link' && item.fileObjArr[0]?.type!=='one_drive' && item.fileObjArr[0]?.type!=='google_drive')?item.fileObjArr[0]?.extension || '':'') : ''" placement="top" container="body" [containerClass]="'tooltipPopup'">
                            <ng-container  *ngIf="commonUtils.checkSupportAudioExtension(item?.fileObjArr[0]?.extension)">
                              <div class="middle w-100 d-inline-flex">
                                <app-dashboard-icon *ngIf="item?.fileObjArr[0]?.type==='google_drive'" style="margin-top: -3px !important;" [name]="'googleDrive'" [iconJson]="attributesIcons" [size]="17" class="labelimg"></app-dashboard-icon>
                                <app-dashboard-icon *ngIf="item?.fileObjArr[0]?.type==='one_drive'" style="margin-top: -3px !important;" [name]="'oneDrive'" [iconJson]="attributesIcons" [size]="17" class="labelimg"></app-dashboard-icon>
                                <span *ngIf="!isReadOnlyField(item.locked)" class="addCreateConnectBtn"
                                (click)="singleVideoFile.value='';uploadPopover.isOpen()? uploadPopover.close(): uploadPopover.open(); getImageFile();setSelectedMediaField(item,customFieldType.AUDIO);selectedFileType=customFieldType.AUDIO;selectedKeyLabel=item.key;selectedMediaFieldJson=item">
                                  <span class="iconBox"><i class="fa fa-cloud-upload" aria-hidden="true" id="userIcon"></i></span>
                                  <span>{{'common.CHANGE'|translate}}</span>
                                </span>
                                <app-drive-attachment #uploadPopover 
                                  [leadInfo]="leadObj" [dashId]="dashId" [item]="item" [sprintArr]="dashBoardSprintArr" 
                                  [orgUsersJson]="orgUsersJson" [singleSelection]="true" [typeOfDoc]="'AUDIO'"  
                                  (filesJson)="uploadUrlLink($event);uploadPopover.close()">
                                </app-drive-attachment>
                                <span class="d-inline-flex" style="align-items: center; justify-content: center; width: 28px; height: 28px;" (click)="setDeletedata(0, item, item.key);
                                    openDeleteModal(deleteModal)" *ngIf="!isReadOnlyField(item.locked)">
                                  <img src="../../../../assets/1x/delete-btn.png" class="blendColor h-100" />
                                </span>
                              </div>
                              <audio controls style="height: 30px; width: 100%;">
                                  <source [src]="item.fileObjArr[0]?.image_download_url" type="audio/mp3" />
                                </audio>
                              </ng-container>
                              <ng-container  *ngIf="!commonUtils.checkSupportAudioExtension(item?.fileObjArr[0]?.extension)">
                                <app-dashboard-icon style="display: flex; justify-content: center;" [name]="item.fileObjArr[0]?.extension" [iconJson]="mediaIcons" size="96"></app-dashboard-icon>
                              </ng-container>
                              <span class="d-flex flex-row justify-content-center text1e1f21 fileNameEdit">
                                <span class="fileName w-auto mr-2"> {{item.fileObjArr[0]?.name}}.{{item.fileObjArr[0]?.extension}}</span>
                                <span *ngIf="!isReadOnlyField(item.locked)" class="iconBox" (click)="openRenameModal(renameMediaModal, item.fileObjArr[0], item)">
                                  <img src="assets/common-use-icon-20dp/edit-6d6e6f.svg" class="editIcon6d6e6f renameImageIcon" alt="">
                                  <img src="assets/common-use-icon-20dp/edit-3571de.svg" class="editIcon3571de renameImageIcon" alt="">
                                </span>     
                              </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            <ng-container *ngIf="!item?.fileObjArr?.length>0">
              <div *ngIf="!commonUtils.checkSupportAudioExtension(item?.fileObjArr[0]?.extension)" class="w-100 mx-0 mediaColumn fieldValueBox" id="mainMultiImageDiv" (dragenter)="onDragEnter(item.key)">
                  <div id="singleImageSec" (drop)="onDrop($event, customFieldType.AUDIO, item.key)"
                    (dragover)="allowDrop($event)"  class="mediaBox m-0">
                    <ng-container *ngIf="!(isPreview  || isItemLocked(item.locked) || isReadOnly) && item?.fileObjArr?.length==0">
                        <div class="dropZone" (dragleave)="onDragLeave(item.key)" [id]="'drop_'+item.key">
                          <p class="text6d6e6f m-0">{{'editcustom.dropHere' | translate}}  </p>
                        </div>
                        <div class="row mx-0 w-100 g-0" style="align-items: center; justify-content: center;">
                          <span class="text6d6e6f"> {{'editcustom.DRAG_AND_DROP_AUDIO'|translate}}</span>
                        </div>  
                    </ng-container>
                    <ng-container *ngIf="isPreview  || isItemLocked(item.locked) || isReadOnly">
                      <div class="row mx-0 w-100 g-0" style="align-items: center; justify-content: center;">
                        <span class="text6d6e6f">{{'editcustom.DRAG_AND_DROP_AUDIO'|translate}}</span>
                      </div>  
                    </ng-container>
                  </div>
              </div>
            </ng-container>
          </div>
          </ng-container>
          <!-- multi audio  -->
          <ng-container *ngIf="item.type == customFieldType.MULTIAUDIO && isNonHidden(item)">
            <div class="row g-0 editleadcustominputsrow">
              <div class="row g-0 mx-0 d-inline-flex w-100" style="justify-content: space-between; align-items: center;">
                <div class="fieldLabelBox f-l-r col" role="button">
                  <span class="labelimg"><app-dashboard-icon [name]="item.type" [iconJson]="attributesIcons" class="labelimg" [size]="14"></app-dashboard-icon></span>
                  <span class="labelText" appOverflowCheck [ngbTooltip]>{{ item.display_key }}</span><span *ngIf="item.mandatory" style="color: red;">*</span>
                </div>
                 <!-----Loader-->
                 <div class="loaderBox pl-0 col" *ngIf='item.isLoaded' id="imageTypeName">
                  <button class="btn btn-primary" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      {{'editcustom.loading' | translate}}  
                  </button>
                </div>
                <!------Upload and screen reording button-->
                <ng-container *ngIf="!isReadOnlyField(item.locked)">
                  <div  *ngIf="!item.isLoaded" class="d-inline-flex col p-0" style="justify-content: end; align-items: center; column-gap: 10px;">
                    <div class="mediaUploadButton key-tab">
                      <span class="addCreateConnectBtn key-focus" tabindex="0" (click)="multiVideoFile.value='';uploadPopover.isOpen()? uploadPopover.close(): uploadPopover.open(); getMultiImageFile();setSelectedMediaField(item,customFieldType.MULTIAUDIO);">
                        <span class="iconBox"><i class="fa fa-cloud-upload" aria-hidden="true" id="userIcon"></i></span>
                        <span (click)="setSelectedMediaField(item,customFieldType.MULTIAUDIO);selectedFileType=customFieldType.MULTIAUDIO;selectedKeyLabel=item.key;selectedMediaFieldJson=item">{{'common.upload'|translate}}</span>
                      </span>
                      <app-drive-attachment #uploadPopover 
                        [leadInfo]="leadObj" [dashId]="dashId" [item]="item" [sprintArr]="dashBoardSprintArr" 
                        [orgUsersJson]="orgUsersJson" [singleSelection]="false" [typeOfDoc]="'AUDIO'"  
                        (filesJson)="uploadUrlLink($event);uploadPopover.close()">
                      </app-drive-attachment>
                    </div>
                    <div class="screenRecordingBox key-tab" *ngIf="!item?.isRecordingStopped">
                      <div *ngIf="!recordingStarted  || selectedKey != item.key" class="addCreateConnectBtn key-focus" tabindex="0" (click)="!timerInterval && openAudioRecorder(customFieldType.MULTIAUDIO, item.key)">
                        <span class="iconBox"><i  class="fa fa-dot-circle-o" aria-hidden="true"></i></span>
                        <span >{{'editcustom.startRecording' | translate}}</span>
                      </div>
                      <span *ngIf="recordingStarted && selectedKey==item.key" >{{recordingTimeForVideo}}</span>
                      <div *ngIf="recordingStarted && selectedKey==item.key" class="addCreateConnectBtn key-focus" tabindex="0" (click)="item.isRecordingStopped=true">
                        <span class="iconBox"><i style="color:rgb(255, 84, 76);"  class="fa fa-stop-circle-o" aria-hidden="true"></i></span>
                        <span>{{'editcustom.stopRecording' | translate}}</span>
                      </div>
                    </div>
                    <div class="fieldValueBox" *ngIf="item?.isRecordingStopped">
                      <input #ele type="text" placeholder="{{'placeholders.nameRecording' | translate}}" [(ngModel)]="recordingName" (keydown.enter)="recordingName && ele?.blur()" (blur)="recordingName && stopAudioRecording(item)">
                    </div>
                  </div>
                </ng-container>
              </div>

              <input #multiVideoFile type="file" id="multiImageFile" name="multiImageFile" (change)="
                    onUploadFile($event.target.files, customFieldType.MULTIAUDIO, item.key)
                  " accept="audio/*" multiple />
              <div class="row mx-0 w-100 fieldValueBox mediaColumn" id="multiImageSec" (drop)="onDrop($event, customFieldType.MULTIAUDIO, item.key)"
                (dragover)="allowDrop($event)">
                <div id="singleImageSec" (dragenter)="onDragEnter(item.key)" class="mediaBox row mx-0 w-100" *ngIf="!item?.fileObjArr?.length>0">
                  <ng-container *ngIf="!(isPreview  || isItemLocked(item.locked) || isReadOnly)">
                    <div class="dropZone" (dragleave)="onDragLeave(item.key)" [id]="'drop_'+item.key">
                      <p class="text-6d6e6f">{{'editcustom.dropHere' | translate}}</p>
                    </div> 
                    <div class="row mx-0 w-100 g-0" style="align-items: center; justify-content: center;">
                      <span class="text6d6e6f">{{'editcustom.DRAG_AND_DROP_AUDIO'|translate}}</span>
                    </div>  

                  </ng-container>
                  <ng-container *ngIf="isPreview  || isItemLocked(item.locked) || isReadOnly">
                    <div class="row mx-0 w-100 g-0" style="align-items: center; justify-content: center;">
                      <span class="text6d6e6f">{{'editcustom.DRAG_AND_DROP_AUDIO'|translate}}</span>
                    </div>  
                  </ng-container>
                </div>
                  <ng-container *ngIf="item?.fileObjArr?.length>0">
                    <div class="mediaBox mx-0 w-100 d-flex">
                      <ng-container *ngFor="let file of item?.fileObjArr | slice:0:6; let i = index">
                        <ng-container *ngIf="i<9 || isvideoExpanded">
                          <div class="showImageBox" id="multipleImagePreview" *ngIf="commonUtils.checkSupportAudioExtension(file?.extension)" 
                          [tooltip]="file?.file_name?.length > 5 ? file.file_name + '.' + ((file?.type!=='link' && file?.type!=='one_drive' && file?.type!=='google_drive')?file?.extension || '' :'') : ''" placement="top" container="body" [containerClass]="'tooltipPopup'">
                          <div class="show-image" style="align-items: center;">
                            <app-dashboard-icon *ngIf="file?.type==='google_drive'" style="margin-top: -85px !important;" [name]="'googleDrive'" [iconJson]="attributesIcons" [size]="17" class="labelimg"></app-dashboard-icon>
                            <app-dashboard-icon *ngIf="file?.type==='one_drive'" style="margin-top: -85px !important;" [name]="'oneDrive'" [iconJson]="attributesIcons" [size]="17" class="labelimg"></app-dashboard-icon>
                            <!-- <app-dashboard-icon  [name]="'audios'" [iconJson]="mediaIcons" [size]="100" class="labelimg"></app-dashboard-icon> -->
                            <span class="d-flex" style="width: 100%; height: 100%; align-items: center; justify-content: center;"><img class="w-100 h-100" src="assets/new-icon-6d6e6f-20dp/audio-6d6e6f.svg" alt="video"></span>
                                  <audio #myVideo id="videoTagForCustomField">
                                    <source [src]="item.fileObjArr[0]?.image_download_url" type="audio/mp3" />
                                  </audio>
                                  <div class="middle">
                                    <br />
                                    <button *ngIf="file?.type!=='link' && file?.type!=='one_drive' && file?.type!=='google_drive'" type="button" class="btn image-options-for-singleimage" (click)="
                                    openModalForPreview(item,i,customFieldType.MULTIAUDIO,videoPlayerModalNested)">
                                      <img src="../../../../assets/1x/play-btn.png" />
                                    </button>
                                    <a *ngIf="file?.type==='link' || file?.type==='one_drive' || file?.type==='google_drive'" href="{{file?.file_path}}" class="image-options-for-singleImage" target="_blank">
                                      <img src="../../../../assets/1x/play-btn.png" class="image-icon-for-eyeIcon-single"/>
                                  </a>
                                    <span class="btn singleImageCrossIcon" (click)="setDeletedata(i, item, item.key);
                                        openDeleteModal(deleteModal)" *ngIf="!isReadOnlyField(item.locked)">
                                      <img src="../../../../assets/1x/delete-btn.png" />
                                  </span>
                                  </div>
                              </div>
                              <span class="d-flex flex-row justify-content-between text1e1f21 fileNameEdit">
                                <span class="fileName w-100"> {{file.file_name}}.{{file?.extension}}</span>
                                <span *ngIf="!isReadOnlyField(item.locked)" class="iconBox" (click)="openRenameModal(renameMediaModal, file, item)">
                                  <img src="assets/common-use-icon-20dp/edit-6d6e6f.svg" class="editIcon6d6e6f renameImageIcon" alt="">
                                  <img src="assets/common-use-icon-20dp/edit-3571de.svg" class="editIcon3571de renameImageIcon" alt="">
                                </span>     
                              </span>
                          </div>
                          <div  class="showImageBox" id="multipleImagePreview" *ngIf="!commonUtils.checkSupportAudioExtension(file?.extension)"
                          [tooltip]="fileObjArr?.file_name?.length > 5 ? file.file_name + '.' + ((file?.type!=='link' && file?.type!=='one_drive' && file?.type!=='google_drive')?file?.extension || '':'') : ''" placement="top" container="body" [containerClass]="'tooltipPopup'">
                                  <div class="show-image" id="multiImageDiv">
                                    <app-dashboard-icon [name]="file?.extension" [iconJson]="mediaIcons" size="96"></app-dashboard-icon>
                                    <div class="middle">
                                      <br />
                                      <button *ngIf="fileObjArr?.type!=='link' && fileObjArr?.type!=='one_drive' && fileObjArr?.type!=='google_drive'" type="button" class="btn image-options-for-singleimage" (click)="
                                      openModalForPreview(item,i,customFieldType.MULTIVIDEO,videoPlayerModalNested)">
                                        <img src="../../../../assets/1x/play-btn.png" />
                                      </button>
                                      <a *ngIf="fileObjArr?.type==='link' || fileObjArr?.type==='one_drive' || fileObjArr?.type==='google_drive'" href="{{fileObjArr?.file_path}}" class="image-options-for-singleImage" target="_blank">
                                        <img src="../../../../assets/1x/play-btn.png" class="image-icon-for-eyeIcon-single"/>
                                    </a>
                                      <span class="btn singleImageCrossIcon" (click)="setDeletedata(i, item, item.key);
                                          openDeleteModal(deleteModal)" *ngIf="!isReadOnlyField(item.locked)">
                                        <img src="../../../../assets/1x/delete-btn.png" />
                                    </span>
                                    </div>
                                  </div>
                                  <span class="d-flex flex-row justify-content-between text1e1f21 fileNameEdit">
                                    <span class="fileName w-100 "> {{file.file_name}}.{{file?.extension}}</span>
                                    <span *ngIf="!isPreview && !isReadOnlyField(item.locked)" class="iconBox" (click)="openRenameModal(renameMediaModal, file, item)">
                                      <img src="assets/common-use-icon-20dp/edit-6d6e6f.svg" class="editIcon6d6e6f renameImageIcon" alt="">
                                      <img src="assets/common-use-icon-20dp/edit-3571de.svg" class="editIcon3571de renameImageIcon" alt="">
                                    </span>     
                                  </span>
                          </div>
                        </ng-container>
                      </ng-container> 
                      <ng-container *ngIf="!(isPreview  || isItemLocked(item.locked) || isReadOnly)">
                        <div class="dropBox">
                          <div class="dropZone" (dragleave)="onDragLeave(item.key)" [id]="'drop_'+item.key">
                            <p class="text6d6e6f m-0">
                            {{'editcustom.dropHere' | translate}}</p>
                          </div>
                          <div class="row mx-0 w-100 g-0" style="align-items: center; justify-content: center;">
                            <span class="text6d6e6f">{{'editcustom.DRAG_DROP_COPY_PASTE_IMAGE'|translate}}</span>
                          </div>
                        </div>
                      </ng-container>      
                      <div *ngIf="item?.fileObjArr?.length > 9 && !isVideoExpanded" class="moredisimg" (click)="isVideoExpanded = true">
                        <strong>  {{'editcustom.showMore' | translate}}  </strong>
                      </div>
                      <div *ngIf="item?.fileObjArr?.length > 9 && isVideoExpanded" class="moredisimg" (click)="isVideoExpanded = false">
                        <strong>{{'editcustom.showLess' | translate}}</strong>
                      </div>
                    </div>
                  </ng-container>
              </div>
            </div>
          </ng-container>
          <!-- single image  -->
          <ng-container *ngIf="item.type == customFieldType.IMAGEFIELD && isNonHidden(item) && !['logo_yAqhpCq5_simg','profile_pic_29N2eLvy_simg'].includes(item?.key)">
            <input #singleImageFile class="singleImageFile" type="file" name="singleImageFile" (change)="
                          onUploadFile($event.target.files, customFieldType.IMAGEFIELD, item.key)
                        " accept="image/*" single />
            <div class="row g-0 editleadcustominputsrow">
                <div class="row g-0 mx-0 d-inline-flex w-100" style="justify-content: space-between; align-items: center;">
                  <div class="fieldLabelBox f-l-r col" role="button">
                    <span class="labelimg"><app-dashboard-icon [name]="item.type" [iconJson]="attributesIcons" class="labelimg" [size]="14"></app-dashboard-icon></span>
                    <span class="labelText" appOverflowCheck [ngbTooltip]>{{ item.display_key }}</span><span *ngIf="item.mandatory" style="color: red;">*</span>
                  </div>
                  <!----Loader-->
                  <div class="loaderBox pl-0 col" *ngIf="item.isLoaded">
                        <button class="btn btn-primary" id="loader" type="button" disabled>
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          {{'editcustom.loading' | translate}}  
                        </button>
                  </div>
                  <!-----upload Button-->
                  <ng-container *ngIf="!(isPreview  || isItemLocked(item.locked) || isReadOnly)">
                    <div  *ngIf="!item.isLoaded && !item?.fileObjArr?.length>0 " class="d-inline-flex col p-0" style="justify-content: end; align-items: center; column-gap: 10px;">
                     
                      <div class="mediaUploadButton key-tab">
                        <span class="addCreateConnectBtn key-focus" tabindex="0" (click)="singleImageFile.value='';uploadPopover.isOpen()? uploadPopover.close(): uploadPopover.open(); getImageFile();setSelectedMediaField(item,customFieldType.IMAGEFIELD);">
                          <span class="iconBox"><i class="fa fa-cloud-upload" aria-hidden="true" id="userIcon"></i></span>
                          <span (click)="setSelectedMediaField(item,customFieldType.IMAGEFIELD);selectedFileType=customFieldType.IMAGEFIELD;selectedKeyLabel=item.key;selectedMediaFieldJson=item">Upload</span>
                        </span>
                        <app-drive-attachment #uploadPopover 
                        [leadInfo]="leadObj" [dashId]="dashId" [item]="item" [sprintArr]="dashBoardSprintArr" 
                        [orgUsersJson]="orgUsersJson" [singleSelection]="true" [typeOfDoc]="'IMAGE'"  
                        (filesJson)="uploadUrlLink($event);uploadPopover.close()">
                        </app-drive-attachment>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <!-----When Image Empty-->
                <div *ngIf="!item?.fileObjArr?.length>0" class="row mx-0 w-100 mediaColumn fieldValueBox" id="mainMultiImageDiv" (dragenter)="onDragEnter(item.key)"
                  (paste)="onPaste($event, customFieldType.IMAGEFIELD, item.key)">
                  <div id="singleImageSec" (drop)="onDrop($event, customFieldType.IMAGEFIELD, item.key)"
                    (dragover)="allowDrop($event)" class="row mx-0 w-100 mediaBox">
                    <ng-container *ngIf="!(isPreview  || isItemLocked(item.locked) || isReadOnly) && item?.fileObjArr?.length==0">
                      <div class="dropZone" (dragleave)="onDragLeave(item.key)" [id]="'drop_'+item.key" >
                            <p class="text6d6e6f m-0">
                              {{'editcustom.dropHere' | translate}}</p>
                    </div>
                     <div class="row mx-0 w-100 g-0" style="align-items: center; justify-content: center;">
                      <span class="text6d6e6f">{{'editcustom.DRAG_DROP_COPY_PASTE_IMAGE'|translate}}</span>
                    </div>
                    </ng-container>
                    <ng-container *ngIf="isPreview  || isItemLocked(item.locked) || isReadOnly">
                      <div class="row mx-0 w-100 g-0" style="align-items: center; justify-content: center;">
                        <span class="text6d6e6f">{{'editcustom.DRAG_DROP_COPY_PASTE_IMAGE'|translate}}</span>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <!----When Image Not empty-->
                <ng-container *ngIf="item?.fileObjArr?.length>0">
                  <div class="row mx-0 w-100 mediaColumn fieldValueBox">
                    <div class="mediaBox mx-0 w-100 d-flex">
                      <div class="m-0 showImageBox">
                        <ng-container *ngIf="!item.isLoaded">
                          <div class="show-image"
                          [tooltip]="item.fileObjArr[0]?.file_name?.length > 5 ? item.fileObjArr[0]?.file_name + '.' + ((item?.fileObjArr[0]?.type!=='link' && item?.fileObjArr[0]?.type!=='one_drive' && item?.fileObjArr[0]?.type!=='google_drive')?item.fileObjArr[0]?.extension || '':'') : ''" placement="top" container="body" [containerClass]="'tooltipPopup'">
                          <!-- (click)="openMergeIemModal(iframeOptions,item?.fileObjArr[0])" -->
                          <app-dashboard-icon *ngIf="item?.fileObjArr[0]?.type==='google_drive'" style="margin-top: -3px !important;" [name]="'googleDrive'" [iconJson]="attributesIcons" [size]="17" class="labelimg"></app-dashboard-icon>
                          <app-dashboard-icon *ngIf="item?.fileObjArr[0]?.type==='one_drive'" style="margin-top: -3px !important;" [name]="'oneDrive'" [iconJson]="attributesIcons" [size]="17" class="labelimg"></app-dashboard-icon>
                          <img *ngIf="item?.fileObjArr[0]?.type!=='link' || item?.fileObjArr[0]?.type!=='google_drive' || item?.fileObjArr[0]?.type!=='one_drive'" class="image" id="singleImage" [src]="item.fileObjArr[0].image_download_url" />
                          <app-dashboard-icon *ngIf="item?.fileObjArr[0]?.type==='link' || item?.fileObjArr[0]?.type==='google_drive' || item?.fileObjArr[0]?.type==='one_drive'" [name]="item?.fileObjArr[0]?.extension" [iconJson]="mediaIcons" size="100">
                          </app-dashboard-icon>
                            <div class="middle">
                              <span *ngIf="!isReadOnly && !isItemLocked(item.locked)" class="btn image-options-for-singleImage" style="width: 90%; height: 32px;"
                                (click)="singleImageFile.value='';uploadPopover.isOpen()? uploadPopover.close(): uploadPopover.open(); getImageFile();setSelectedMediaField(item,customFieldType.IMAGEFIELD);">
                                <img src="../../../../assets/1x/change-btn.png" class="h-100" />
                              </span> 
                              <app-drive-attachment #uploadPopover 
                              [leadInfo]="leadObj" [dashId]="dashId" [item]="item" [sprintArr]="dashBoardSprintArr" 
                              [orgUsersJson]="orgUsersJson" [singleSelection]="true" [typeOfDoc]="'IMAGE'"  
                              (filesJson)="uploadUrlLink($event);uploadPopover.close()">
                              </app-drive-attachment>
                              <br />
                              <a *ngIf="item?.fileObjArr[0]?.type!=='link' && item?.fileObjArr[0]?.type!=='one_drive' && item?.fileObjArr[0]?.type!=='google_drive'" (click)="setDocumentUrl(item,i)">
                                <span class="image-options-for-singleImage" *ngIf="!isReadOnly">
                                  <img src="../../../../assets/1x/doc-whitedownload.svg" class="image-icon-for-download"/>
                                </span>
                              </a>   
                              <a  *ngIf="item?.fileObjArr[0]?.type==='link' || item?.fileObjArr[0]?.type==='one_drive' || item?.fileObjArr[0]?.type==='google_drive'" href="{{item?.fileObjArr[0]?.file_path}}" target="_blank">
                                <span class="image-options-for-singleImage" *ngIf="!isReadOnly">
                                  <img src="../../../../assets/1x/doc-whitedownload.svg" class="image-icon-for-download"/>
                                </span>
                              </a> 
                              <span *ngIf="item?.fileObjArr[0]?.type!=='link' && item?.fileObjArr[0]?.type!=='one_drive' && item?.fileObjArr[0]?.type!=='google_drive'" class="image-options-for-singleImage" (click)="
                              openModalForPreview(item,0,customFieldType.IMAGEFIELD,videoPlayerModalNested)">
                                    <img src="../../../../assets/1x/view-btn.png" class="image-icon-for-eyeIcon-single"/>
                                  </span>
                              <a *ngIf="item?.fileObjArr[0]?.type==='link' || item?.fileObjArr[0]?.type==='one_drive' || item?.fileObjArr[0]?.type==='google_drive'" href="{{item?.fileObjArr[0]?.file_path}}" class="image-options-for-singleImage" target="_blank">
                                    <img src="../../../../assets/1x/view-btn.png" class="image-icon-for-eyeIcon-single"/>
                                </a>
                                <span class="image-options-for-singleImage" (click)="setDeletedata(i, item, item.key);
                                  openDeleteModal(deleteModal)" *ngIf="!isReadOnlyField(item.locked)">
                                <img src="../../../../assets/1x/delete-btn.png" class="image-icon-for-download" />
                              </span>
                            </div>
                          </div>
                        <span class="d-flex flex-row justify-content-between text6d63ef fileNameEdit">
                          <span class="fileName w-100"> {{item.fileObjArr[0].file_name}}.{{item.fileObjArr[0]?.extension}}</span>
                          <span *ngIf="!isPreview && !isReadOnlyField(item.locked)" class="iconBox" (click)="openRenameModal(renameMediaModal, item.fileObjArr[0], item)">
                            <img src="assets/common-use-icon-20dp/edit-6d6e6f.svg" class="editIcon6d6e6f renameImageIcon" alt="">
                            <img src="assets/common-use-icon-20dp/edit-3571de.svg" class="editIcon3571de renameImageIcon" alt="">
                          </span>     
                        </span>
                      </ng-container>
                      </div>
                    </div>
                  </div>
                </ng-container>
            </div>
          </ng-container>
          <!-- multi image -->
          <ng-container *ngIf="item.type == customFieldType.MULTIIMAGE && isNonHidden(item)">
            <div class="row g-0 editleadcustominputsrow">
              <div class="row g-0 mx-0 d-inline-flex w-100" style="justify-content: space-between; align-items: center;">
                <div class="col fieldLabelBox f-l-r " role="button">
                  <span class="labelimg"><app-dashboard-icon [name]="item.type" [iconJson]="attributesIcons" class="labelimg" [size]="14"></app-dashboard-icon></span>
                  <span class="labelText" appOverflowCheck [ngbTooltip]>{{ item.display_key }}</span><span *ngIf="item.mandatory" style="color: red;">*</span>
                </div>
                <!------Loader------>
                <div class="col loaderBox pl-0" id="imageTypeName" *ngIf="item.isLoaded">
                  <button class="btn btn-primary" id="loader" type="button" disabled>
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      {{'editcustom.loading' | translate}}  
                  </button>
                </div>
                <!----Upload Button-->
                <ng-container *ngIf="!(isPreview  || isItemLocked(item.locked) || isReadOnly)">
                  <div  *ngIf="!item.isLoaded" class="col d-inline-flex p-0" style="justify-content: end; align-items: center; column-gap: 10px;">
                   
                    <div class="mediaUploadButton key-tab">
                      <span class="addCreateConnectBtn key-focus" tabindex="0" (click)="multiImageFile.value='';uploadPopover.isOpen()? uploadPopover.close(): uploadPopover.open(); getMultiImageFile();setSelectedMediaField(item,customFieldType.MULTIIMAGE);">
                        <span class="iconBox"><i class="fa fa-cloud-upload" aria-hidden="true" id="userIcon"></i></span>
                        <span (click)="setSelectedMediaField(item,customFieldType.MULTIIMAGE);selectedFileType=customFieldType.MULTIIMAGE;selectedKeyLabel=item.key;selectedMediaFieldJson=item">Upload</span>
                      </span>
                      <app-drive-attachment #uploadPopover 
                        [leadInfo]="leadObj" [dashId]="dashId" [item]="item" [sprintArr]="dashBoardSprintArr" 
                        [orgUsersJson]="orgUsersJson" [singleSelection]="false" [typeOfDoc]="'IMAGE'"  
                        (filesJson)="uploadUrlLink($event);uploadPopover.close()">
                        </app-drive-attachment>
                    </div>
                  </div>
                </ng-container>         
              </div>
              <input #multiImageFile type="file" id="multiImageFile" name="multiImageFile" (change)="
                            onUploadFile($event.target.files, customFieldType.MULTIIMAGE, item.key)
                          " accept="image/*" multiple />
              <!------Main Image Body Box-->
              <div class="row mx-0 w-100 mediaColumn fieldValueBox" *ngIf="!item?.fileObjArr?.length>0" id="multiImageSec" (dragenter)="onDragEnter(item.key)" (paste)="onPaste($event, customFieldType.MULTIIMAGE, item.key)"
                  (drop)="onDrop($event, customFieldType.MULTIIMAGE, item.key)" (dragover)="allowDrop($event)">
                  <div id="singleImageSec" class="row mx-0 w-100 mediaBox">
                      <ng-container *ngIf="!(isPreview  || isItemLocked(item.locked) || isReadOnly)">
                        <div class="dropZone" (dragleave)="onDragLeave(item.key)" [id]="'drop_'+item.key">
                          <p class="text6d6e6f m-0">
                          {{'editcustom.dropHere' | translate}}</p>
                        </div>
                          <div class="row mx-0 w-100 g-0" style="align-items: center; justify-content: center;">
                            <span class="text6d6e6f">{{'editcustom.DRAG_DROP_COPY_PASTE_IMAGE'|translate}}</span>
                          </div>
                      </ng-container>
                      <ng-container *ngIf="isPreview  || isItemLocked(item.locked) || isReadOnly">
                        <div class="row mx-0 w-100 g-0" style="align-items: center; justify-content: center;">
                          <span class="text6d6e6f">{{'editcustom.DRAG_DROP_COPY_PASTE_IMAGE'|translate}}</span>
                        </div>
                      </ng-container>
                  </div>
                </div>
              <div class="row mx-0 w-100 mediaColumn fieldValueBox" *ngIf="item?.fileObjArr?.length>0" (dragenter)="onDragEnter(item.key)" (paste)="onPaste($event, customFieldType.MULTIIMAGE, item.key)"
                (drop)="onDrop($event, customFieldType.MULTIIMAGE, item.key)" (dragover)="allowDrop($event)">
                <div class="mediaBox mx-0 w-100 d-flex" id="multipleImagePreview">
                  <ng-container *ngFor="let file of item?.fileObjArr let i = index">
                    <div class="m-0 showImageBox" *ngIf="isImgExpanded || i<9" 
                    [tooltip]="file?.file_name?.length > 5 ? file?.file_name + '.' + ((file?.type!=='link' && file?.type!=='one_drive' && file?.type!=='google_drive')?file?.extension || '':'') : ''" placement="top" container="body" [containerClass]="'tooltipPopup'">
                      <div class="show-image" id="multiImageDiv">
                        <app-dashboard-icon *ngIf="file?.type==='google_drive'" style="margin-top: -3px !important;" [name]="'googleDrive'" [iconJson]="attributesIcons" [size]="17" class="labelimg"></app-dashboard-icon>
                        <app-dashboard-icon *ngIf="file?.type==='one_drive'" style="margin-top: -3px !important;" [name]="'oneDrive'" [iconJson]="attributesIcons" [size]="17" class="labelimg"></app-dashboard-icon>
                        <!-- <app-dashboard-icon *ngIf="file?.type==='link'" style="margin-top: -3px !important;" [name]="'url'" [iconJson]="attributesIcons" [size]="17" class="labelimg"></app-dashboard-icon> -->
                        <img *ngIf="file?.type!=='link' || file?.type!=='google_drive' || file?.type!=='one_drive'" [src]="file.image_download_url" id="imagePreview" />
                        <app-dashboard-icon *ngIf="file?.type==='google_drive' || file?.type==='one_drive'" [name]="file?.extension" [iconJson]="mediaIcons" size="100">
                        </app-dashboard-icon>
                        <app-dashboard-icon *ngIf="file?.type==='link'" [name]="'url'" [iconJson]="mediaIcons" size="100">
                        </app-dashboard-icon>
                        <div class="middle">
                          <span *ngIf="file?.type!=='link' && file?.type!=='one_drive' && file?.type!=='google_drive'" class="image-options-for-eye" (click)="
                            openModalForPreview(item,i,customFieldType.MULTIIMAGE,videoPlayerModalNested)">
                            <img src="../../../../assets/1x/view-btn.png" class="image-icon-for-eyeIcon" />
                          </span>
                          <a *ngIf="file?.type==='link' || file?.type==='one_drive' || file?.type==='google_drive'" href="{{file?.file_path}}" class="image-options-for-singleImage" target="_blank">
                            <img src="../../../../assets/1x/view-btn.png" class="image-icon-for-eyeIcon-single"/>
                          </a>
                          <a *ngIf="file?.type!=='link' && file?.type!=='one_drive' && file?.type!=='google_drive'" (click)="setDocumentUrl(item,i)">
                            <span class="image-options-for-delete" *ngIf="!isReadOnly" style="padding-right: 9px;">
                              <img src="../../../../assets/1x/doc-whitedownload.svg" class="image-icon-for-deleteIcon"/>
                            </span>
                          </a>
                          <a *ngIf="file?.type==='link' || file?.type==='one_drive' || file?.type==='google_drive'" href="{{file?.file_path}}" target="_blank">
                            <span class="image-options-for-delete" *ngIf="!isReadOnly" style="padding-right: 9px;">
                              <img src="../../../../assets/1x/doc-whitedownload.svg" class="image-icon-for-deleteIcon"/>
                            </span>
                          </a>
                          <span class="image-options-for-delete" (click)="triggerOpenEditImageModal([file],customFieldType.MULTIIMAGE,item.key)" *ngIf="!isReadOnlyField(item.locked)">
                            <img src="../../../../assets/1x/editCircleWhiteIcon.svg" class="image-icon-for-deleteIcon"/>
                          </span>
                          <span class="image-options-for-delete" (click)="setDeletedata(i, item, item.key);
                              openDeleteModal(deleteModal)" *ngIf="!isReadOnlyField(item.locked)">
                            <img src="../../../../assets/1x/delete-btn.png" class="image-icon-for-deleteIcon" />
                          </span>
                        </div>
                      </div>
                      <span class="d-flex flex-row justify-content-between text1e1f21 fileNameEdit">
                        <span class="fileName w-100"> {{file.file_name}}.{{file?.extension}}</span>
                       <span *ngIf="!isPreview && !isReadOnlyField(item.locked)" class="iconBox" (click)="openRenameModal(renameMediaModal, file, item)">
                          <img src="assets/common-use-icon-20dp/edit-6d6e6f.svg" class="editIcon6d6e6f renameImageIcon" alt="">
                          <img src="assets/common-use-icon-20dp/edit-3571de.svg" class="editIcon3571de renameImageIcon" alt="">
                        </span>
                      </span>
                  </div>
                  </ng-container>
                  <ng-container *ngIf="!(isPreview  || isItemLocked(item.locked) || isReadOnly)">
                    <div class="dropBox">
                      <div class="dropZone" (dragleave)="onDragLeave(item.key)" [id]="'drop_'+item.key">
                        <p class="text6d6e6f m-0">
                        {{'editcustom.dropHere' | translate}}</p>
                      </div>
                      <div class="row mx-0 w-100 g-0" style="align-items: center; justify-content: center;">
                        <span class="text6d6e6f">{{'editcustom.DRAG_DROP_COPY_PASTE_IMAGE'|translate}}</span>
                      </div>
                    </div>
                  </ng-container>
                  <div *ngIf="item?.fileObjArr?.length > 9 && !isImgExpanded" class="moredisimg" (click)="isImgExpanded = true">
                    <strong> {{'editcustom.showMore' | translate}} </strong>
                  </div>
                  <div *ngIf="item?.fileObjArr?.length > 9 && isImgExpanded" class="moredisimg" (click)="isImgExpanded = false">
                    <strong> {{'editcustom.showLess' | translate}} </strong>
                  </div>
                </div>
              </div>  
          </div>
          </ng-container>
          <!-----SINGLE FILE UPLOAD-->
          <ng-container *ngIf="item.type == customFieldType.FILEUPLOAD && isNonHidden(item)">
            <div class="row g-0 editleadcustominputsrow">
              <div class="row g-0 mx-0 d-inline-flex w-100" style="justify-content: space-between; align-items: center;">
                <label class="fieldLabelBox f-l-r col" role="button">
                  <span class="labelimg"><app-dashboard-icon [name]="item.type" [iconJson]="attributesIcons" class="labelimg" [size]="14"></app-dashboard-icon></span>
                  <span class="labelText" appOverflowCheck [ngbTooltip]>{{ item.display_key }}</span><span *ngIf="item.mandatory" style="color: red;">*</span>
                </label>
                <!----Loader-->
                <div class="loaderBox pl-0 col" *ngIf="item.isLoaded">
                  <button class="btn btn-primary" id="loader" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    {{'editcustom.loading' | translate}}  
                  </button>
                </div>
                <!-----upload Button-->
                <ng-container *ngIf="!(isPreview  || isItemLocked(item.locked) || isReadOnly)">
                  <div  *ngIf="!item.isLoaded && !item?.fileObjArr?.length>0 " class="d-inline-flex col p-0" style="justify-content: end; align-items: center; column-gap: 10px;">
                    
                    <div class="mediaUploadButton key-tab">
                      <span class="addCreateConnectBtn key-focus" tabindex="0" (click)="singleFile.value='';uploadPopover.isOpen()? uploadPopover.close(): uploadPopover.open(); getImageFile();setSelectedMediaField(item,customFieldType.FILEUPLOAD)">
                        <span class="iconBox"><i class="fa fa-cloud-upload" aria-hidden="true" id="userIcon"></i></span>
                        <span (click)="setSelectedMediaField(item,customFieldType.FILEUPLOAD);selectedFileType=customFieldType.FILEUPLOAD;selectedKeyLabel=item.key;selectedMediaFieldJson=item">{{'common.upload'|translate}}</span>
                      </span>
                      <app-drive-attachment #uploadPopover 
                        [leadInfo]="leadObj" [dashId]="dashId" [item]="item" [sprintArr]="dashBoardSprintArr" 
                        [orgUsersJson]="orgUsersJson" [singleSelection]="true" [typeOfDoc]="'FILE'"  
                        (filesJson)="uploadUrlLink($event);uploadPopover.close()">
                        </app-drive-attachment>
                    </div>
                  </div>
                </ng-container>
              </div>
              <input #singleFile class="singleFile" type="file" name="singleImageFile" (change)="
                      onUploadFile($event.target.files, customFieldType.FILEUPLOAD, item.key)
                    " accept="application/pdf, .xls,.xlsx,.doc,.docx ,application/zip,.rar,.7zip,application/vnd.oasis.opendocument.text" single />
            
                    <!-- single media upload option  -->
              <div class="row w-100 mx-0 mediaColumn fieldValueBox" id="mainMultiImageDiv" (dragenter)="onDragEnter(item.key)" (paste)="onPaste($event, customFieldType.FILEUPLOAD, item.key)" (drop)="onDrop($event, customFieldType.FILEUPLOAD, item.key)"
              (dragover)="allowDrop($event)">
                <div class="mediaBox row mx-0 w-100">
                  <ng-container *ngIf="!item?.fileObjArr?.length>0">
                    <ng-container *ngIf="!(isPreview || isItemLocked(item.locked) || isReadOnly) && item?.fileObjArr?.length==0">
                      <div class="dropZone" (dragleave)="onDragLeave(item.key)" [id]="'drop_'+item.key">
                        <p class="m-0 text6d6e6f">{{'editcustom.dropHere' | translate}}</p>
                      </div>
                      <div class="row mx-0 w-100 g-0" style="align-items: center; justify-content: center;">
                        <span class="text6d6e6f">{{'editcustom.DRAG_DROP_COPY_PASTE_IMAGE'|translate}}</span>
                      </div>  
                    </ng-container>
                    <ng-container *ngIf="isPreview  || isItemLocked(item.locked) || isReadOnly">
                      <div class="row mx-0 w-100 g-0" style="align-items: center; justify-content: center;">
                        <span class="text6d6e6f">{{'editcustom.DRAG_DROP_COPY_PASTE_IMAGE'|translate}}</span>
                      </div>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="item?.fileObjArr?.length>0">
                    <ng-template *ngTemplateOutlet="mediaTemplateRef; context: {$implicit: item}"></ng-template>
                  </ng-container>
              </div>
            </div>
          </div>
          </ng-container>
          <!-- media template -->
          <ng-template #mediaTemplateRef let-item>
            <ng-container  id="multipleImagePreview"
              *ngFor="let file of item?.fileObjArr let i = index">
              <ng-container *ngIf="isFileExpanded || i<9">
                <div class="showImageBox" *ngIf="file.extension"
                [tooltip]="file?.file_name?.length > 5 ? file?.file_name + '.' + ((file?.type!=='link' && file?.type!=='one_drive' && file?.type!=='google_drive')?file?.extension || '':'') : ''" placement="top" container="body" [containerClass]="'tooltipPopup'" >
                  <div id="docDiv" class="show-image m-0">
                      <app-dashboard-icon *ngIf="file?.type==='google_drive'" style="margin-top: -3px !important;" [name]="'googleDrive'" [iconJson]="attributesIcons" [size]="17" class="labelimg"></app-dashboard-icon>
                      <app-dashboard-icon *ngIf="file?.type==='one_drive'" style="margin-top: -3px !important;" [name]="'oneDrive'" [iconJson]="attributesIcons" [size]="17" class="labelimg"></app-dashboard-icon>
                      <app-dashboard-icon *ngIf="file?.type!=='link'" [name]="file.extension" [iconJson]="mediaIcons" size="96"></app-dashboard-icon>
                      <app-dashboard-icon *ngIf="file?.type==='link'" [name]="'url'" [iconJson]="mediaIcons" size="96"></app-dashboard-icon>
                      <div class="middle">
                        <a *ngIf="file?.type!=='link' && file?.type!=='one_drive' && file?.type!=='google_drive'" (click)="setDocumentUrl(item,i)">
                          <span class="btn image-options-for-singleimage">
                            <img src="../../../../assets/1x/doc-download.png" />
                          </span>
                        </a>
                        <a *ngIf="file?.type==='link' || file?.type==='one_drive' || file?.type==='google_drive'" href="{{file?.file_path}}" target="_blank">
                          <span class="btn image-options-for-singleimage">
                            <img src="../../../../assets/1x/doc-download.png" />
                          </span>
                        </a>
                        <span *ngIf="file?.type!=='link' && file?.type!=='one_drive' && file?.type!=='google_drive'" (click)="openModalForPreview(item,i,customFieldType.MULTIFILEUPLOAD,videoPlayerModalNested);
                          setDeletedata(i, item, item.key)" class="btn image-options-for-singleimage">
                          <img src="../../../../assets/1x/doc-view.png" />
                        </span>
                        <a *ngIf="(file?.type==='link' || file?.type==='one_drive' || file?.type==='google_drive') && isLinkValid(file)" href="{{file?.file_path}}" class="image-options-for-singleImage" target="_blank">
                          <img src="../../../../assets/1x/view-btn.png" class="image-icon-for-eyeIcon-single"/>
                        </a>
                        <a  *ngIf="(file?.type==='link' || file?.type==='google_drive') && !isLinkValid(file)" (click)="openMergeIemModal(iframeOptions,file)">
                          <span class="image-options-for-singleImage" *ngIf="!isReadOnly">
                            <img  src="../../../../assets/1x/view-btn.png" class="image-icon-for-download"/>
                          </span>
                        </a> 
                        <span *ngIf="constantService?.getMediaTypes()?.IMAGE?.includes(file.extension) && !(isPreview  || isItemLocked(item.locked) || isReadOnly || publicLeadCustomFieldData)" 
                        (click)="openEditImageModal([file],customFieldType.IMAGEFIELD,item.key)" class="btn image-options-for-singleimage">
                        <img width=35 height="35"  src="../../../assets/1x/editCircle-btn.svg" />
                        </span>
                        <span class="btn singleImageCrossIcon" (click)="setDeletedata(i, item, item.key);openDeleteModal(deleteModal)"
                          *ngIf="!(isPreview  || isItemLocked(item.locked) || isReadOnly || publicLeadCustomFieldData)">
                          <img src="../../../../assets/1x/doc-delete.png" />
                        </span>
                      </div>
                    </div>
                    <span class="d-flex flex-row justify-content-between text1e1f21 fileNameEdit">
                      <span class="fileName w-100"> {{file.file_name}}.{{file?.extension}}</span>
                      <span *ngIf="!isPreview && !isReadOnlyField(item.locked)" class="iconBox" (click)="openRenameModal(renameMediaModal, file, item)">
                          <img src="assets/common-use-icon-20dp/edit-6d6e6f.svg" class="editIcon6d6e6f renameImageIcon" alt="">
                          <img src="assets/common-use-icon-20dp/edit-3571de.svg" class="editIcon3571de renameImageIcon" alt="">
                        </span>     
                    </span> 
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!(isPreview  || isItemLocked(item.locked) || isReadOnly) && item?.fileObjArr?.length>1">
              <div class="dropBox">
                <div class="dropZone" (dragleave)="onDragLeave(item.key)" [id]="'drop_'+item.key">
                  <p class="text6d6e6f m-0">
                  {{'editcustom.dropHere' | translate}}</p>
                </div>
                <div class="row mx-0 w-100 g-0" style="align-items: center; justify-content: center;">
                  <span class="text6d6e6f">{{'editcustom.DRAG_DROP_COPY_PASTE_IMAGE'|translate}}</span>
                </div>
              </div>
            </ng-container>
            <div *ngIf="item?.fileObjArr?.length > 9 && !isFileExpanded" class="moredisimg" (click)="isFileExpanded = true">
              <strong> {{'editcustom.showMore' | translate}} </strong>
            </div>
            <div *ngIf="item?.fileObjArr?.length > 9 && isFileExpanded" class="moredisimg" (click)="isFileExpanded = false">
              <strong>{{'editcustom.showLess' | translate}}</strong>
            </div>
          </ng-template>
          <!-----MULTIPLE FILE UPLOAD-->
          <ng-container *ngIf="item.type == customFieldType.MULTIFILEUPLOAD && isNonHidden(item)">
            <div class="row g-0 editleadcustominputsrow">
              <div class="row g-0 mx-0 d-inline-flex w-100" style="justify-content: space-between; align-items: center;">
                <div class="fieldLabelBox f-l-r col" role="button">
                  <span class="labelimg"><app-dashboard-icon [name]="item.type" [iconJson]="attributesIcons" class="labelimg" [size]="14"></app-dashboard-icon>
                  </span>
                  <span class="labelText" appOverflowCheck [ngbTooltip]>{{ item.display_key }}</span><span *ngIf="item.mandatory" style="color: red;">*</span>
                </div>
                 <!----Loader-->
                 <div class="loaderBox pl-0 col" *ngIf="item.isLoaded" id="imageTypeName">
                  <button class="btn btn-primary" id="loader" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    {{'editcustom.loading' | translate}}  
                  </button>
                </div>
                <!-----upload Button-->
                <ng-container *ngIf="!(isPreview  || isItemLocked(item.locked) || isReadOnly)">
                  <div  *ngIf="!item.isLoaded" class="d-inline-flex col p-0" style="justify-content: end; align-items: center; column-gap: 10px;">
                    <div class="mediaUploadButton key-tab">
                      <span class="addCreateConnectBtn key-focus" tabindex="0" (click)="multiDocumentFile.value='';uploadPopover.isOpen()? uploadPopover.close(): uploadPopover.open(); getMultiImageFile();setSelectedMediaField(item,customFieldType.MULTIFILEUPLOAD);">
                        <span class="iconBox"><i class="fa fa-cloud-upload" aria-hidden="true" id="userIcon"></i></span>
                        <span (click)="setSelectedMediaField(item,customFieldType.MULTIFILEUPLOAD);selectedFileType=customFieldType.MULTIFILEUPLOAD;selectedKeyLabel=item.key;selectedMediaFieldJson=item">{{'common.upload'|translate}}</span>
                      </span>
                      <app-drive-attachment #uploadPopover 
                        [leadInfo]="leadObj" [dashId]="dashId"  [item]="item" [sprintArr]="dashBoardSprintArr" 
                        [orgUsersJson]="orgUsersJson" [singleSelection]="false" [typeOfDoc]="'FILE'"  
                        (filesJson)="uploadUrlLink($event);uploadPopover.close()">
                        </app-drive-attachment>
                    </div>
                  </div>
                </ng-container>
              </div>
              <input #multiDocumentFile type="file" id="multiImageFile" name="multiImageFile"
                    (change)="onUploadFile($event.target.files,customFieldType.MULTIFILEUPLOAD,item.key)"
                    accept="application/pdf, .xls,.xlsx,.doc,.docx,.csv ,application/zip,.rar,.7zip,application/vnd.oasis.opendocument.text"
                    multiple />
              <!-- multi media upload options -->
              <div class="row g-0 mx-0 w-100 mediaColumn fieldValueBox" id="multiImageSec" (dragenter)="onDragEnter(item.key)" (paste)="onPaste($event, customFieldType.MULTIFILEUPLOAD, item.key)" (drop)="onDrop($event, customFieldType.MULTIFILEUPLOAD, item.key)"
                (dragover)="allowDrop($event)">
                <div id="singleImageSec" class="row mx-0 w-100 mediaBox">
                  <ng-container *ngIf="!(isPreview  || isItemLocked(item.locked) || isReadOnly) && !item?.fileObjArr?.length>0">
                    <div class="dropZone" (dragleave)="onDragLeave(item.key)" [id]="'drop_'+item.key">
                      <p class="text6d6e6f m-0">{{'editcustom.dropHere' | translate}}</p>
                    </div>
                    <div class="row g-0 mx-0 w-100" style="align-items: center; justify-content: center;">
                      <span class="text6d6e6f">{{'editcustom.DRAG_DROP_COPY_PASTE_IMAGE'|translate}}</span>
                    </div>  
                  </ng-container>
                  <!-- readOnly and preview media  -->
                  <ng-container *ngIf="isPreview || isItemLocked(item.locked) || isReadOnly">
                    <div class="row mx-0 g-0 w-100" style="align-items: center; justify-content: center;">
                      <span class="text6d6e6f">{{'editcustom.DRAG_DROP_COPY_PASTE_IMAGE'|translate}}</span>
                    </div>  
                  </ng-container>
                  <ng-container *ngIf="item?.fileObjArr?.length>0">
                    <ng-template *ngTemplateOutlet="mediaTemplateRef; context: {$implicit: item}"></ng-template>
                  </ng-container>
                </div>
              </div>
          </div>
          </ng-container>
          <ng-container *ngIf="item.type == customFieldType.SINGLEUSER && isNonHidden(item)">
            <div class="row g-0 editleadcustominputsrow">
              <div class="col-md-3 fieldLabelBox f-l-r " role="button">
                <app-dashboard-icon [name]="item.type" [iconJson]="attributesIcons" [size]="14" class="labelimg"></app-dashboard-icon>
                <span class="labelText" appOverflowCheck [ngbTooltip]>{{ item.display_key }}</span><span *ngIf="item.mandatory" style="color: red;">*</span>
              </div>
              <div class="col-md-9 userColumn fieldValueBox">
                <div *ngIf="item.singleUserInput" class="people">
                  <span class="peopleDetailBox">
                    <ngx-avatars class="d-inline-flex" [name]="orgUsersJson[item.singleUserInput]?.name" [src]="orgUsersJson[item.singleUserInput]?.image_url" size="20" textSizeRatio="2.2" initialsSize="2" ></ngx-avatars>
                    <span class="peopleName">{{orgUsersJson[item.singleUserInput]?.name }}</span>
                  </span>
                  <span (click)="!isReadOnlyField(item.locked) && removeUser(null, customFieldType.SINGLEUSER, item.key)" class="iconBox" [ngClass]="{'disabled': isReadOnlyField(item.locked)}">
                    <img *ngIf="!isPreview" src="assets/1x/close-gray.svg" alt="remove">
                  </span>
                </div>
                <span *ngIf="!item.singleUserInput && !isPreview" [ngClass]="{'disabled': isReadOnlyField(item.locked)}" class="addCreateConnectBtn key-tab"  #popOverAddPeople="ngbPopover" container="body" [autoClose]="'outside'"
                    [ngbPopover]="!isReadOnlyField(item.locked) && addPeopleTemplate" tabindex="0">
                    <span class="iconBox"><img src="assets/1x/add-icon-3571de-10px.svg" alt=""></span>
                    <span class="">Add User</span>
                </span>
                <ng-template #addPeopleTemplate>
                  <div style="width: 270px; min-height: 200px;">
                    <app-user-selected [dashIdSelected]="dashId" (selectedUserList)="setDataOfPeopleType($event, customFieldType.SINGLEUSER, item.key)" [userList]="selectedPeople" (removeSelectedUsers)="removeFromSelectedList($event)"></app-user-selected>
                  </div> 
                </ng-template>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="item.type == customFieldType.CHECKLIST && isNonHidden(item)">
            <div class="row g-0 editleadcustominputsrow key-tab" id="check-list">
              <div class="row g-0 mx-0 w-100 editleadcustominputsrow key-tab" style="flex-direction: column;">
                <label class="fieldLabelBox f-l-r" role="button">
                  <app-dashboard-icon [size]="14"  [name]="item.type" class="labelimg" [size]="14"></app-dashboard-icon>
                  <span class="labelText" appOverflowCheck [ngbTooltip]>{{item.display_key}}</span><span *ngIf="item.mandatory" style="color: red;">*</span>
                </label>
                <app-checklist-setting [dashId]="dashId" [leadId]="leadObj?._id" [item]="item" [isReadOnly]="isReadOnly || isItemLocked(item.locked)" [isPreview]="isPreview" (onUpdateList)=" this.onValueInput(this.arrayOfCustomFiled, this.customFieldType.CHECKLIST, item.key);"></app-checklist-setting>
              </div>
          </div>
          </ng-container>
          <ng-container *ngIf="item.type == customFieldType.BUTTON && isNonHidden(item)">
            <div class="row g-0 editleadcustominputsrow key-tab">
              <div class="col-md-3 fieldLabelBox  f-l-r " role="button">
                <app-dashboard-icon [name]="item.type" [iconJson]="attributesIcons"></app-dashboard-icon>
                <span class="labelText" appOverflowCheck [ngbTooltip]>{{item.display_key}}</span><span *ngIf="item.mandatory" style="color: red;">*</span>
              </div>
              <div class="col-md-8 userColumn" style="row-gap: 10px; padding: 0;">
                <!-- {{item?.buttonInput}} -->
                <button class="btn buttonClickEffect key-focus" tabindex="0"  type="button" (keydown.enter)="$event.preventDefault()" [readonly]="isPreview || isItemLocked(item.locked) || isReadOnly" (click)="!isItemLocked(item.locked) && onValueInput(arrayOfCustomFiled, item.type, item.key)">{{item.display_key }}</button>
              </div>
            </div>
          </ng-container>  
          <ng-container *ngIf="item.type == customFieldType.COUNTRY && isNonHidden(item)">
            <div class="row g-0 editleadcustominputsrow key-tab">
              <div class="col-md-3 fieldLabelBox f-l-r " role="button">
                <app-dashboard-icon [name]="item.type" [iconJson]="attributesIcons" class="labelimg" [size]="14"></app-dashboard-icon> 
                <span class="labelText" appOverflowCheck [ngbTooltip]>{{item.display_key }}</span><span *ngIf="item.mandatory" style="color: red;">*</span>
              </div>
              <div class="col-md-9 countryBox fieldValueBox currenty-selection-input">
                <div class="d-inline-flex">
                  <app-include-country-selection style="width: 100%;" [fieldType]="item.type" [editable]="!(isPreview || isItemLocked(item.locked) || isReadOnly)" [countryJson]="currencyArr || []" [currenValue]="item.country" (onValueUpdate)="item.country=$event;onValueInput(arrayOfCustomFiled, customFieldType, item.key)"></app-include-country-selection>
                </div>
              </div>
          </div>
          </ng-container>
          <ng-container *ngIf="item.type == customFieldType.TIME_TRACKER && isNonHidden(item) && leadId && !isTemplateCall && itemQueryType!='TEMPLATE'">
            <div class="row g-0 editleadcustominputsrow key-tab">
              <div class="col-md-3 fieldLabelBox f-l-r " role="button">
                <app-dashboard-icon [name]="item.type" [iconJson]="attributesIcons" class="labelimg" [size]="14"></app-dashboard-icon> 
                <span class="labelText" appOverflowCheck [ngbTooltip]>{{item.display_key }}</span><span *ngIf="item.mandatory" style="color: red;">*</span>
              </div>
              <div *ngIf="isPublicLead" class="float-right">{{item.time_spend_till_now_formated}}</div>
            <div class="col-md-9 fieldValueBox" *ngIf="!isPublicLead && item">
              <app-time-tracking [itemKey]="item.key" [dashId]="dashId" [activeTrackerList]="activeTrackerList" [leadObj]="leadObj" [timeSpent]="item.TIME_TRACKER?.time_spend_till_now"></app-time-tracking>
            </div>
          </div>
          </ng-container>
          <ng-container *ngIf="item.type == customFieldType.LOCATION && isNonHidden(item)">
            <div class="row g-0 editleadcustominputsrow key-tab">
              <div class="col-md-3 fieldLabelBox f-l-r " role="button">
                <app-dashboard-icon [name]="item.type" [iconJson]="attributesIcons" class="labelimg" [size]="14"></app-dashboard-icon> 
                <span class="labelText" appOverflowCheck [ngbTooltip]>{{item.display_key }}</span><span *ngIf="item.mandatory" style="color: red;">*</span>
              </div>
              <div class="col-9 fieldValueBox">
                <app-location-item-settings style="display: flex; align-items: center; height: 30px;" [locationData]="item" [isLocked]="isReadOnly || isItemLocked(item.locked)" (selectedLocation)="UpdateLocation($event,item)"></app-location-item-settings>
              </div>
          </div>
          </ng-container>
          <ng-container *ngIf="item.type == customFieldType.MULTIUSER && isNonHidden(item)">
            <div class="row g-0 editleadcustominputsrow">
            <div class="col-md-3 fieldLabelBox f-l-r " role="button">
              <app-dashboard-icon [name]="item.type" [iconJson]="attributesIcons" class="labelimg" [size]="14"></app-dashboard-icon>
              <span class="labelText" appOverflowCheck [ngbTooltip]>{{item.display_key }}</span><span *ngIf="item.mandatory" style="color: red;">*</span>
            </div>
            <div class="col-md-9 userColumn fieldValueBox" style="row-gap: 10px;">
              <ng-container *ngFor="let people of item.multiUserInput; let i=index">
                <span class="people">
                  <span class="peopleDetailBox">
                    <ngx-avatars class="d-inline-flex" [name]="orgUsersJson[people]?.name" [src]="orgUsersJson[people]?.image_url" size="20" textSizeRatio="2.2" initialsSize="2" ></ngx-avatars>
                    <span class="peopleName">{{orgUsersJson[people]?.name }}</span>
                  </span>
                  <span (click)="!isReadOnlyField(item.locked) && removeUser(i, customFieldType.MULTIUSER, item.key)" class="iconBox key-tab" tabindex="0" [ngClass]="{'disabled': isReadOnlyField(item.locked)}">
                    <img *ngIf="!isPreview" src="assets/1x/close-gray.svg" alt="remove">
                  </span>
                </span>
              </ng-container>
              <span *ngIf="!isPreview" #popOverAddPeople="ngbPopover" container="body" [autoClose]="'outside'"
                [ngbPopover]="!isReadOnlyField(item.locked) && addPeopleTemplate" class="addCreateConnectBtn key-tab" [ngClass]="{'disabled': isReadOnlyField(item.locked)}" tabindex="0">
                  <span class="iconBox"><img src="assets/1x/add-icon-3571de-10px.svg" alt=""></span>
                  <span class="">{{'userView.adduser'|translate}}</span>
              </span>
              <ng-template #addPeopleTemplate>
                <!-- [removeFromSelected]="removeFromSelected" -->
                <div style="width: 270px; min-height: 200px;">
                    <app-user-selected [dashIdSelected]="dashId" [removeFromSelected]="[item.multiUserInput]" (selectedUserList)="setDataOfPeopleType($event, customFieldType.MULTIUSER, item.key)" [userList]="item.selectedUserList" ></app-user-selected>
                  <div class="select" *ngIf="selectedPeople.length">
                    <div class="row1" *ngFor="let item of selectedPeople">
                      <div class="column1">
                        <ngx-avatars class="mr-1" [name]="item.name" [src]="item.image_url" size="30" initialsSize="2" ></ngx-avatars>
                            </div>
                      <div class="column2">{{item.name | truncate: 15}}
                        <span style="float:right"><img *ngIf="item?.isDashUser" src="../../../assets/1x/boards-folders.svg"></span>
                        <span style="float:right"><img *ngIf="!item?.isDashUser" src="../../../assets/1x/user.svg"></span> 
                      </div>
                    <div class="column3">
                      <img src="../../../assets/1x/cross-icon.svg" (click)="removeFromSelectedList(item)">
                    </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
          </ng-container>
          <ng-container *ngIf="item.type == customFieldType.CURRENCY && isNonHidden(item)">
            <div class="row g-0 editleadcustominputsrow key-tab">
            <div class="col-md-3 fieldLabelBox f-l-r " role="button">
              <app-dashboard-icon [name]="item.type" [iconJson]="attributesIcons" class="labelimg" [size]="14"></app-dashboard-icon> 
              <span class="labelText" appOverflowCheck [ngbTooltip]>{{item.display_key }}</span><span *ngIf="item.mandatory" style="color: red;">*</span>
            </div>
            <div class="col-md-9 fieldValueBox currencyColumn currenty-selection-input">
              <div class="d-inline-flex">
                <app-include-country-selection style="width: 100%;" [fieldType]="item.type" [editable]="!(isPreview || isItemLocked(item.locked) || isReadOnly)" [countryJson]="currencyArr || []" [currenValue]="item?.currencyObj" (onValueUpdate)="item.currencyObj=$event;onValueInput(arrayOfCustomFiled, customFieldType, item.key)"></app-include-country-selection>
              </div>
            </div>
          </div>
          </ng-container>
          <ng-container *ngIf="item.type == customFieldType.URL && isNonHidden(item) && !['contact_user_e2NB6Dm3j_url','contact_user_TJe6L0Er5_url','contact_user_q8M5zYwj2_url','contact_user_K28bcgPZL_url','contact_user_x2uQrF0e3_url','contact_user_hp77fmHtX_url','contact_user_9XfYrmFu7_url','contact_user_qTX2Jx0j2_url'].includes(item?.key)">
            <div class="row g-0 editleadcustominputsrow key-tab">
              <div class="d-flex g-0 mx-0 w-100" style="justify-content: space-between; align-items: center;">
                <div class="fieldLabelBox f-l-r " role="button">
                  <app-dashboard-icon [name]="item.type" [iconJson]="attributesIcons" class="labelimg" [size]="14"></app-dashboard-icon>
                  <span class="labelText" appOverflowCheck [ngbTooltip]>{{ item.display_key }}</span><span *ngIf="item.mandatory" style="color: red;">*</span>
                </div>
              </div>
            <div class="d-flex mx-0 w-100 urlColumn fieldValueBox" [ngClass]="{'disabled': isReadOnlyField(item.locked)}" >
              <input *ngIf="item.isEditUrl || !item.urlInput" placeholder="{{'placeholders.enterYourLinkHere' | translate}}" [required]="item.mandatory" [readonly]="isPreview || isItemLocked(item.locked) || isReadOnly"
              (blur)="!isReadOnlyField(item.locked) && onValueInput(arrayOfCustomFiled, customFieldType.URL, item.key);item.isEditUrl=false" (input)="validationRuntime(item,item.urlInput)" type="text"
              (keyup.enter)="!isReadOnlyField(item.locked) && onValueInput(arrayOfCustomFiled, customFieldType.URL, item.key);item.isEditUrl=false"
              class="modal_Custom_Lead_Input ls-025x inpt key-focus" tabindex="0" [(ngModel)]="item.urlInput" (focus)="item.isEditUrl=true"
              name="urlinput_{{ item.key }}" autofocus
              #url="ngModel" />
              <span *ngIf="!item.isEditUrl && item.urlInput" class="urlTextBox key-focus" tabindex="0" (click)="url?.click()">
                <!-- <div  [innerHTML]="item.urlInput | linky:{stripPrefix: false, truncate: { length: 32, location: 'smart' }}"></div> -->
                <a #url href="{{item.urlInput}}" target="_blank" tabindex="-1">{{item.urlInput}}</a>
                <div  *ngIf="!isReadOnly && !isItemLocked(item.locked)" (click)="$event?.stopPropagation();item.isEditUrl = true" class="iconBox key-tab" tabindex="0" >
                  <img *ngIf="!isPreview"src="assets/common-use-icon-20dp/edit-6d6e6f.svg" class="editIcon6d6e6f"  alt="">
                  <img *ngIf="!isPreview"src="assets/common-use-icon-20dp/edit-3571de.svg" class="editIcon3571de"  alt="">
                </div>
              </span>
              <div *ngIf="isEditUrl && url?.invalid && (url.dirty || url.touched)">
              <span class="validationText ls-025x">{{'editcustom.enterCorrectURL' | translate}}</span>
              </div>
            </div>
          </div>
          </ng-container>
          <ng-container *ngIf="item.type == customFieldType.MULTI_URL && isNonHidden(item)">
            <div class="row g-0 editleadcustominputsrow">
              <div class="d-flex g-0 mx-0 w-100" style="justify-content: space-between; align-items: center;">
                <div class="fieldLabelBox f-l-r " role="button">
                  <app-dashboard-icon [name]="item.type" [iconJson]="attributesIcons" class="labelimg" [size]="14"></app-dashboard-icon>
                  <span class="labelText" appOverflowCheck [ngbTooltip]>{{ item.display_key }}</span><span *ngIf="item.mandatory" style="color: red;">*</span>
                </div>
                  <span *ngIf="item.enableNewButton && !isReadOnly && !isReadOnlyField(item.locked)" class="btn addCreateConnectBtn key-tab" tabindex="0" [readonly]="isPreview || isItemLocked(item.locked) || isReadOnly" (click)="!isReadOnlyField(item.locked) && addNewMultiUrl(item);multiUrlCall(item)">
                    <span class="iconBox"><img src="../../../../../assets/1x/add-icon-3571de-10px.svg"></span>
                    <span>{{'editcustom.new' | translate}}</span>  
                  </span>
                  <span *ngIf="!item.enableNewButton && !isReadOnly && !isReadOnlyField(item.locked)" [disabled] class="disableButton btn">
                    <span class="iconBox"><img src="../../../../../assets/1x/add-icon-6d6e6f-10px.svg"></span>
                    <span>{{'editcustom.new' | translate}}</span>
                    </span>  
              </div>
            <div class="row g-0 mx-0 w-100 fieldValueBox urlCoulmn">
              <div style="border-radius: 7px;border: 1px solid #e1e1e1;width:100%;height: 30px !important;" class="row g-0 mx-0 enableBtn" 
                    *ngFor="let entry of item.multiurlInput; let i = index; trackBy: trackByFn">
                 <div class="d-flex g-0 mx-0 w-100 d-inline-flex jutsify-end urlTextBox multiUrlBox" style="align-items: center;" [ngClass]="{'disabled': isReadOnlyField(item.locked)}">
                        <a *ngIf="!item.enable[i]" href="{{entry}}" target="_blank">{{entry}}</a>
                      <input *ngIf="item.enable[i]" #multiUrl placeholder="{{'placeholders.enterYourLinkHere' | translate}}" [required]="item.mandatory" [readonly]="isPreview || isItemLocked(item.locked) || isReadOnly"
                      (blur)="!isReadOnlyField(item.locked)&& validateMultiUrl(item,arrayOfCustomFiled,i)" (keydown.enter)="multiUrl?.blur()" (input)="validationRuntime(item,item.multiurlInput);" type="text" (ngModelChange)="multiUrlCall(item)"
                      class="modal_Custom_Lead_Input border-0  ls-025x inpt" style="height: 30px !important;background-color: transparent;" [(ngModel)]="item.multiurlInput[i]" #url="ngModel"/>
                <div class=" d-inline-flex" style="column-gap: 10px;">
                  <span class="iconBox" *ngIf="!item.enable[i] && !isReadOnly && !isItemLocked(item.locked)" (click)=" !(isReadOnly || item.locked || isPreview) && item.enable[i]=true;item.adding=false">
                    <img *ngIf="!isPreview"src="assets/common-use-icon-20dp/edit-6d6e6f.svg" class="editIcon6d6e6f" alt="">
                    <img *ngIf="!Preview"src="assets/common-use-icon-20dp/edit-3571de.svg" class="editIcon3571de" alt="">
                  </span>
                  <span *ngIf="!isReadOnlyField(item.locked)" [disabled]="isReadOnly && item.locked && isPreview" role="button" class="iconBox" (click)="removeUrl(item, i)"><i class="fa fa-times" aria-hidden="true"></i>
                  </span>      
                </div>
                 </div>
              </div>
            </div>
          </div>
          </ng-container>
          <ng-container *ngIf="item.type == customFieldType.MULTISELECT && isNonHidden(item)">
            <div class="row g-0 editleadcustominputsrow key-tab">
              <div class="col-md-3 fieldLabelBox f-l-r w-100 " role="button">
                <app-dashboard-icon [name]="item.type" [iconJson]="attributesIcons" class="labelimg" [size]="14"></app-dashboard-icon>
                <span class="labelText" appOverflowCheck [ngbTooltip]>{{ item.display_key }}</span><span *ngIf="item.mandatory" style="color: red;">*</span>
              </div>
              <div class="col-md-9 multiselectColumn fieldValueBox dropDownSpacing hoverNgSelectP">
                <ng-select placeholder="{{'placeholders.select' | translate}}" [virtualScroll]="true" bindLabel='v' bindValue="k"
                  [items]="item.permissible_values" class="custom key-focus w-100" tabindex="0"
                  [readonly]="isPreview || isItemLocked(item.locked) || isReadOnly" [multiple]="true" [closeOnSelect]="true"
                  [clearable]="!item.mandatory" [(ngModel)]="item.multiselectInput" (change)="selectedItem($event,item);">
                  <ng-template ng-option-tmp let-item="item">
                    <div [style.background-color]="item.c || 'white'" [ngStyle]="{'color': commonUtils.getContrastColor(item?.c)}" class="custom-option">
                      {{ item.v }}
                    </div>
                  </ng-template>
                  <ng-template ng-label-tmp let-item="item">
                    <div [style.background-color]="item.c || 'white'" [ngStyle]="{'color': commonUtils.getContrastColor(item?.c)}" class="custom-options-for-multi">
                      {{ item.v }}
                    </div>
                  </ng-template>
                </ng-select>
              
                <!-- <ng-select placeholder="{{'placeholders.select' | translate}}" [virtualScroll]="true"
                              [items]="item.permissible_values" class="custom key-focus" tabindex="0" [readonly]="isPreview || isItemLocked(item.locked) || isReadOnly" [multiple]="true" [closeOnSelect]="true"
                              [(ngModel)]="item.multiselectInput" (change)="onValueInput(arrayOfCustomFiled, emailField, item.key)"></ng-select> -->
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="item.type == customFieldType.PERCENTAGE && isNonHidden(item)">
            <div class="row g-0 editleadcustominputsrow key-tab">
              <div class="col-md-4 fieldLabelBox f-m-r " role="button">
                <app-dashboard-icon [name]="item.type" [iconJson]="attributesIcons" class="labelimg" [size]="14"></app-dashboard-icon>
                <span class="labelText" appOverflowCheck [ngbTooltip]>{{ item.display_key }}</span><span *ngIf="item.mandatory" style="color: red;">*</span>
              </div>
              <div class="col-md-8 percentageColumn">
                <input placeholder="{{'placeholders.addPercentage' | translate}}" [required]="item.mandatory" [readonly]="isPreview  || isItemLocked(item.locked) || isReadOnly"
                (blur)="!isReadOnlyField(item.locked) && onValueInput(arrayOfCustomFiled, customFieldType.PERCENTAGE, item.key)" (input)="validationRuntime(item,item.percentInput)"  type="number"
                class="modal_Custom_Lead_Input_ND border-0 rounded  ls-025x inpt mb-1 key-focus" tabindex="0"
                [(ngModel)]="item.percentInput" name="percentinput_{{ item.key }}" #percentagefield="ngModel" />
                <div *ngIf="
                    percentagefield.invalid &&
                    (percentagefield.dirty || percentagefield.touched)
                  ">
                  <span class="validationText ls-025x">{{'itemattributes.number' | translate}} {{'editcustom.Required' | translate}}</span>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf=" ['Board','MultipleBoard'].includes(item?.type) && isNonHidden(item)">
            <div *ngIf ="!isPublicLead && item?.embedded_view" class="row g-0 editleadcustominputsrow key-tab">
              <div class="d-flex g-0 mx-0 d-inline-flex w-100" style="justify-content: space-between; align-items: center;">
                <div class=" fieldLabelBox f-l-r" role="button">
                  <span class="labelimg"><app-dashboard-icon [name]="'Board'" [iconJson]="attributesIcons" [size]="14" class="labelimg"></app-dashboard-icon></span>
                  <span class="labelText" appOverflowCheck [ngbTooltip]>{{ item.display_key }}</span><span *ngIf="item.mandatory" style="color: red;">*</span>
                </div>
                <div class="d-flex justify-content-end">
                  <span *ngIf="!isPreview && !isReadOnlyField(item.locked)">
                    <span *ngIf="!item?.isConnectedBoardReadonly && (item?.table_view === false || (['CONTACT','ORGANIZATION']?.includes(item?.type_of_board) && (item?.type!='Board' || (item?.type=='Board' && item?.boardInput?.length!=1))))" class="addCreateConnectBtn key-tab" tabindex="0" (click)="openCreateItem(addcustomlead, item);item.searchOpen=false">
                      <span class="iconBox"><img src="../../../../../assets/1x/add-icon-3571de-10px.svg"></span>
                      <span>{{'common.create' | translate}}</span>
                    </span>
                    <span *ngIf="item?.type!='Board' || (item?.type=='Board' && item?.boardInput?.length!=1)" class="addCreateConnectBtn key-tab" [ngStyle]="{'width': item?.table_view !== false ? 'auto' : '25px'}" (click)="showConnectedBoardFilter?.emit({type:' ',item:item,connectedBoardId:item?.connected_board_id,connectedItemsIds:item.boardInput,isCreate:(this.leadId ? false : true)})" tabindex="0">
                      <span class="iconBox"><i class="fa fa-search" style="color:black;"></i></span>
                      <span *ngIf="item?.table_view !== false">{{'editcustom.searchLeads' | translate :{ term: 'Items' | terminology: item?.itemTerminology?.plural : 'Items' } }}</span></span>
                  </span>
                </div>
              </div>        
              <div *ngIf="item?.create_item && connectedBoardQuickAdd" class="subTaskCreationBox">
                <textarea #leadAddText [(ngModel)]="item.creationtext" (keydown.enter)="false" (blur)="createConnectedItem(item)" (keydown.enter)="leadAddText?.blur()"
                (keydown.tab)="$event.preventDefault()"
                [ngModelOptions]="{standalone: true}" autosize class="emptytextarea creatConnectedBoardTextarea"
                [minRows]="3" id="myTextInput">
                </textarea>
              </div>
              <!-- *ngIf="item.boardInput?.length" -->
              <div *ngIf="!['CONTACT','ORGANIZATION']?.includes(item?.type_of_board)" class="row g-0 mx-0 w-100 fieldValueBox position-relative connected-board-grid-in-kanban">
                <ng-container *ngIf="item?.table_view !== false; else itemList">
                                    <app-item-list-grid                 
                  [tabIndex]="tabIndex" 
                  [dashId]="item.connected_board_id" 
                  [showBoardName]="false" 
                  [showHiddenFields]="showHiddenFields.ishidden"
                  [connectedKey]="item.key"
                  [isCurrentBoardReadonly]="isReadOnlyField(item.locked)"
                  [customFieldsOfMainBoard]="arrayOfCustomFiled"
                  (itemRemove)="removeItemFromBoard(item, $event, item?.type ,item.key)" 
                  (itemCreate)="setItemToBoard(item,$event?.item, item?.type ,item.key,$event?.index)" 
                  (itemUpdate)="updateItemToBoard(item,$event,item?.type,item.key)"
                  [currentLead]="leadObj"
                  [fieldInfo]="{fieldName:item?.display_key,fieldType:'Board'}"
                  [boardConnectedItemIds]="item.boardInput"
                  (onItemOpen)="onItemOpen?.emit($event)"
                  (onExpendTable)="onItemOpen?.emit($event)"
                  class="w-100"
                  ></app-item-list-grid>
                </ng-container>
                <ng-template #itemList > 
                  <div class="row g-0 mx-0 w-100 mediaBox">
                      <app-item-list [itemIdList]="item.boardInput" [tabIndex]="tabIndex" [dashId]="item.connected_board_id" 
                          [showBoardName]="false" [itemListforBoard]="true" (itemRemove)="removeItemFromBoard(item, $event, customFieldType.BOARD ,item.key)" 
                          [currentLead]="leadObj" style="width: 100%;" (onItemOpen)="onItemOpen?.emit($event)"></app-item-list>
                  </div>
                </ng-template>
              </div>
              <div *ngIf="['CONTACT','ORGANIZATION'].includes(item?.type_of_board)" style="padding-left: 2px;">
                <app-contact-and-organization-board class="w-100" [dashId]="item.connected_board_id" [connectedKey]="item.key" [currentLead]="leadObj" [boardConnectedItemIds]="item.boardInput" (onItemOpen)="onItemOpen?.emit($event)" (updateConnectBoard)="item.boardInput=$event;onValueInput(arrayOfCustomFiled, item?.type, item?.key)"></app-contact-and-organization-board>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="item.type == customFieldType.MIRROR && checkForField(currentLead,item) && !isPublicLead && connectedBoardInfo[item.connected_board_key]?.table_view === false  && isNonHidden(item)" >
            <div class="row g-0 editleadcustominputsrow key-tab">
              <div class="row g-0 mx-0 w-100" style="justify-content: space-between;">
                <div class="fieldLabelBox f-l-r" role="button">
                  <app-dashboard-icon *ngIf="currentLead.custom_fields[item.key]" class="labelimg" name="{{item.type}}-{{currentLead.custom_fields[item.key].mirror_column_type}}" [iconJson]="attributesIcons" [size]="14"></app-dashboard-icon>
                  <span class="labelText" appOverflowCheck [ngbTooltip]>{{ item.display_key }}</span><span *ngIf="item.mandatory" style="color: red;">*</span>
                </div>
                  <div class="d-inline-flex justify-content-end f-l-r">{{item.connected_board_key}}</div>
                </div>
                <div class="row g-0 mx-0 fieldValueBox" *ngIf="currentLead?.custom_fields[item.key]?.mirror_item_id?.length<1">
                  <span class="text6d6e6f">{{'editcustom.NO_ITEM_CONNECTED'|translate}}</span>
                </div>
                
                    <ng-container *ngIf="currentLead.custom_fields[item.key] && mirrorColumnsJson">
                      <ng-container *ngFor="let mirrorItemId of currentLead.custom_fields[item.key].mirror_item_id">
                        <ng-container *ngIf="mirrorColumnsJson[mirrorItemId] && mirrorColumnsJson[mirrorItemId][currentLead?.custom_fields[item.key]?.mirror_column_key]; else elseTemp">
                          <!--Priority-->
                          <ng-container *ngIf="currentLead.custom_fields[item.key].mirror_column_type === 'Priority'">
                            <div class="col-md-4 editleadcustominputscol">#{{mirrorColumnsJson[mirrorItemId].seq_id}}</div>
                            <div class="col-md-8 editleadcustominputscol option-text priorityMirrorColumn">
                              <span [ngStyle]="{'background-color': priorityJson[mirrorColumnsJson[mirrorItemId].priority].color}" class="priorityBg">
                                {{priorityJson[mirrorColumnsJson[mirrorItemId].priority].label}}
                              </span>
                              {{priorityJson[mirrorColumnsJson[mirrorItemId].priority].name}}
                            </div>
                          </ng-container>
                          
                          <!---Bucket-->
                          <ng-container *ngIf="currentLead.custom_fields[item.key].mirror_column_type === 'Bucket' && mirrorColumnsJson[mirrorItemId]?.bucket_name">
                            <div class="col-md-4 editleadcustominputscol">#{{mirrorColumnsJson[mirrorItemId].seq_id}}</div>
                            <div class="col-md-8 editleadcustominputscol option-text">{{mirrorColumnsJson[mirrorItemId].bucket_name}}
                            </div>
                          </ng-container>

                          <!-----Assignee User----->
                          <ng-container *ngIf="currentLead.custom_fields[item.key].mirror_column_type === 'SingleUser'">
                            <div class="col-md-4 editleadcustominputscol">#{{mirrorColumnsJson[mirrorItemId].seq_id}}</div>
                            <div class="col-md-8 editleadcustominputscol option-text">
                              <span class="assignusrMirror">
                                <ngx-avatars class="mr-1" [name]="orgUsersJson[mirrorColumnsJson[mirrorItemId][currentLead?.custom_fields[item.key]?.mirror_column_key]]?.name ? orgUsersJson[mirrorColumnsJson[mirrorItemId][currentLead?.custom_fields[item.key]?.mirror_column_key]]?.name : 'Assign'" [src]="orgUsersJson[mirrorColumnsJson[mirrorItemId][currentLead?.custom_fields[item.key]?.mirror_column_key]]?.image_url" size="25" initialsSize="2" ></ngx-avatars>
                                <span>{{orgUsersJson[mirrorColumnsJson[mirrorItemId][currentLead?.custom_fields[item.key]?.mirror_column_key]]?.name ? orgUsersJson[mirrorColumnsJson[mirrorItemId][currentLead?.custom_fields[item.key]?.mirror_column_key]]?.name : 'Assign' | truncate: 15}}</span>
                            </span>
                            </div>
                          </ng-container>

                          <!------SPrint-->
                          <ng-container *ngIf="currentLead.custom_fields[item.key].mirror_column_type === 'Sprint' && mirrorColumnsJson[mirrorItemId]?.sprint_name">
                            <div class="col-md-4 py-2  option-text">#{{mirrorColumnsJson[mirrorItemId].seq_id}}</div>
                            <div class="col-md-8 py-2  option-text">{{mirrorColumnsJson[mirrorItemId]?.sprint_name | async}}</div>
                          </ng-container>

                          <!----Tags-->
                          <ng-container *ngIf="currentLead.custom_fields[item.key].mirror_column_type === 'Tag' && mirrorColumnsJson[mirrorItemId]?.tagsName?.length">
                            <div class="col-md-4 py-2 option-text">#{{mirrorColumnsJson[mirrorItemId].seq_id}}</div>
                            <div class="col-md-8 py-2 option-text tagMirror">
                              <span *ngFor="let tag of mirrorColumnsJson[mirrorItemId].tagsName" class="tagbg">{{tag}}</span>
                            </div>
                          </ng-container>

                          <!------Text FIeld-->
                          <ng-container *ngIf="currentLead.custom_fields[item.key].mirror_column_type === 'Text' && mirrorColumnsJson[mirrorItemId]?.title">
                            <div class="col-md-4 py-2 option-text">#{{mirrorColumnsJson[mirrorItemId].seq_id}}</div>
                            <div class="col-md-8 py-2 option-text">{{mirrorColumnsJson[mirrorItemId].title}}</div>
                          </ng-container>

                          <!-----Date Field-->
                          <ng-container *ngIf="currentLead.custom_fields[item.key].mirror_column_type === 'Date' && mirrorColumnsJson[mirrorItemId][currentLead?.custom_fields[item.key]?.mirror_column_key]?.date">
                            <div class="col-md-4 py-2 option-text">#{{mirrorColumnsJson[mirrorItemId].seq_id}}</div>
                            <div class="col-md-8 py-2 option-text">
                              {{mirrorColumnsJson[mirrorItemId][currentLead?.custom_fields[item.key]?.mirror_column_key] | dateCalcuate:"dateTime"}}
                            </div>
                          </ng-container>

                          <!-----Date Time Fields-->
                          <ng-container *ngIf="currentLead.custom_fields[item.key].mirror_column_type === 'DateTime' && mirrorColumnsJson[mirrorItemId][currentLead?.custom_fields[item.key]?.mirror_column_key]">
                            <div class="col-md-4 py-2 option-text">#{{mirrorColumnsJson[mirrorItemId].seq_id}}</div>
                            <div class="col-md-8 py-2 option-text">{{mirrorColumnsJson[mirrorItemId][currentLead?.custom_fields[item.key]?.mirror_column_key] | daysago}}</div>
                          </ng-container>
                        </ng-container>
                        <!-- *ngIf="mirrorColumnsJson[mirrorItemId]?.custom_fields[currentLead?.custom_fields[item.key]?.mirror_column_key]" -->
                        <ng-template #elseTemp>
                          <ng-container *ngIf="commonUtils.checkForFieldInMirror(mirrorItemId,currentLead,item.key,mirrorColumnsJson)">
                          <ng-container *ngIf="currentLead.custom_fields[item.key].mirror_column_type === 'Numeric' ||
                            currentLead.custom_fields[item.key].mirror_column_type === 'Text' ||
                            currentLead.custom_fields[item.key].mirror_column_type === 'Email' ||
                            currentLead.custom_fields[item.key].mirror_column_type === constantService.constant.CUSTOMTYPES.PERCENTAGE ||
                            currentLead.custom_fields[item.key].mirror_column_type === constantService.constant.CUSTOMTYPES.COUNTRY ||
                            currentLead.custom_fields[item.key].mirror_column_type === constantService.constant.CUSTOMTYPES.RADIO ||
                            currentLead.custom_fields[item.key].mirror_column_type === constantService.constant.CUSTOMTYPES.CHECKBOX ||
                            currentLead.custom_fields[item.key].mirror_column_type === constantService.constant.CUSTOMTYPES.MULTISELECT ||
                            currentLead.custom_fields[item.key].mirror_column_type === constantService.constant.CUSTOMTYPES.DROPDOWN ||
                            currentLead.custom_fields[item.key].mirror_column_type === constantService.constant.CUSTOMTYPES.IPADDRESS">
                            <div class="col-md-4 py-2 option-text">#{{mirrorColumnsJson[mirrorItemId].seq_id}}</div>
                            <div class="col-md-8 py-2 option-text">{{mirrorColumnsJson[mirrorItemId].custom_fields[currentLead?.custom_fields[item.key]?.mirror_column_key]}}
                            </div>
                          </ng-container>
                          <ng-container *ngIf="currentLead.custom_fields[item.key].mirror_column_type === constantService.constant.CUSTOMTYPES.LOCATION">
                            <div class="col-md-4 py-2 option-text">#{{mirrorColumnsJson[mirrorItemId].seq_id}}</div>
                            <div class="col-md-8 py-2 option-text">{{mirrorColumnsJson[mirrorItemId].custom_fields[currentLead?.custom_fields[item.key]?.mirror_column_key]?.description}}</div>
                          </ng-container>
                           <!------time tracker-->
                           <ng-container *ngIf="currentLead.custom_fields[item.key].mirror_column_type === 'TimeTracker'">
                            <div class="col-md-4 py-2  option-text">#{{mirrorColumnsJson[mirrorItemId].seq_id}}</div>
                            <div class="col-md-8 py-2  option-text">{{mirrorColumnsJson[mirrorItemId].custom_fields[currentLead.custom_fields[item.key].mirror_column_key].time_spend_till_now_formated}}</div>
                          </ng-container>
                          <!---Mobile Number Field ----->
                          <ng-container *ngIf="currentLead.custom_fields[item.key].mirror_column_type === 'Mobile' && mirrorColumnsJson[mirrorItemId].custom_fields[currentLead?.custom_fields[item.key]?.mirror_column_key]">
                            <div class="col-md-4 py-2 option-text">#{{mirrorColumnsJson[mirrorItemId]?.seq_id}}</div>
                            <div class="col-md-8 py-2 option-text" *ngIf="currencyJson">
                              {{currencyJson[mirrorColumnsJson[mirrorItemId].custom_fields[currentLead?.custom_fields[item.key]?.mirror_column_key]?.country_code]?.dial_code}}-{{mirrorColumnsJson[mirrorItemId]?.custom_fields[currentLead?.custom_fields[item.key]?.mirror_column_key]?.mobile_number}}
                            </div>
                          </ng-container>
                          <div  [innerHTML]="item.urlInput | linky:{stripPrefix: false, truncate: { length: 32, location: 'smart' }}"></div>

                          <!---Url Field ----->
                          <ng-container *ngIf="currentLead.custom_fields[item.key].mirror_column_type === customFieldType.URL">
                            <div class="col-md-4 py-2 option-text">#{{mirrorColumnsJson[mirrorItemId]?.seq_id}}</div>
                            <div class="col-md-8 py-2 option-text">
                            <span [innerHTML]="mirrorColumnsJson[mirrorItemId].custom_fields[currentLead?.custom_fields[item.key]?.mirror_column_key] | linky:{stripPrefix: false, truncate: { length: 32, location: 'smart' }}"></span>
                            </div>
                          </ng-container>
                          <!---Multi Url Field ----->
                          <ng-container *ngIf="currentLead.custom_fields[item.key].mirror_column_type === customFieldType.MULTI_URL">
                            <div class="col-md-4 py-2 option-text">#{{mirrorColumnsJson[mirrorItemId]?.seq_id}}</div>
                            <div class="col-md-8 py-2 option-text">
                              <ng-container *ngFor="let url of mirrorColumnsJson[mirrorItemId].custom_fields[currentLead?.custom_fields[item.key]?.mirror_column_key]">
                                <div [innerHTML]="url | linky:{stripPrefix: false, truncate: { length: 32, location: 'smart' }}"></div>
                              </ng-container>
                            </div>
                          </ng-container>
                          <!-- button -->
                          <ng-container *ngIf="currentLead.custom_fields[item.key].mirror_column_type === customFieldType.BUTTON">
                            <div class="col-md-4 py-2 option-text">#{{mirrorColumnsJson[mirrorItemId]?.seq_id}}</div>
                            <div class="col-md-8 py-2 option-text">
                              <button class="btn buttonClickEffect" [disabled]="true">{{ item.connected_board_column_display_key }}</button>
                            </div>
                          </ng-container>
                          <!-- checklist -->
                          <ng-container *ngIf="currentLead?.custom_fields[item.key].mirror_column_type === 'CheckList'">
                            <div class="col-md-4 option-text">#{{mirrorColumnsJson[mirrorItemId].seq_id}}</div>
                            <div class="col-md-8 option-text d-flex flex-column" style="row-gap: 7px;">
                              <ng-container *ngFor="let checklist of mirrorColumnsJson[mirrorItemId].custom_fields[currentLead?.custom_fields[item.key]?.mirror_column_key]">
                                <div class="option-text">
                                    <img *ngIf="checklist.state=='COMPLETED'" class="checkListIcon" src="assets/1x/pronnel_iconArtboard 29.png" alt="">
                                    <img *ngIf="checklist.state=='NOT_COMPLETED'" class="checkListIcon" src="assets/1x/pronnel_iconArtboard 28.png" alt="">
                                    {{checklist?.summary | truncate: 20}}
                                </div>
                            </ng-container>
                            </div>
                        </ng-container>

                          <!---Single User-->
                          <ng-container *ngIf="currentLead.custom_fields[item.key].mirror_column_type === 'SingleUser' && mirrorColumnsJson[mirrorItemId].custom_fields[currentLead?.custom_fields[item.key]?.mirror_column_key]">
                            <div class="col-md-4 py-2 option-text">#{{mirrorColumnsJson[mirrorItemId].seq_id}}</div>
                            <div class="col-md-8 py-2 option-text">
                              <span class="assignusrMirror">
                                <ngx-avatars class="mr-1" [name]="orgUsersJson[mirrorColumnsJson[mirrorItemId].custom_fields[currentLead?.custom_fields[item.key]?.mirror_column_key]]?.name" [src]="orgUsersJson[mirrorColumnsJson[mirrorItemId].custom_fields[currentLead?.custom_fields[item.key]?.mirror_column_key]]?.image_url" size="25" initialsSize="2" ></ngx-avatars>
                                <span>{{orgUsersJson[mirrorColumnsJson[mirrorItemId].custom_fields[currentLead?.custom_fields[item.key]?.mirror_column_key]]?.name | truncate: 15}}</span>
                            </span>
                            </div>
                          </ng-container>

                          <!-----Multiple User-->
                          <ng-container *ngIf="currentLead.custom_fields[item.key].mirror_column_type === 'MultiUser' && mirrorColumnsJson[mirrorItemId].custom_fields[currentLead?.custom_fields[item.key]?.mirror_column_key]?.length>0">
                            <div class="col-md-4 option-text">#{{mirrorColumnsJson[mirrorItemId].seq_id}}</div>
                            <div class="col-md-8 option-text d-flex flex-column" style="row-gap: 7px;">
                            <ng-container *ngFor="let userId of mirrorColumnsJson[mirrorItemId].custom_fields[currentLead?.custom_fields[item.key]?.mirror_column_key]">
                              <span class="assignusrMirror">
                                <ngx-avatars class="mr-1" [name]="orgUsersJson[userId]?.name" [src]="orgUsersJson[userId]?.image_url" size="25" initialsSize="2" ></ngx-avatars>
                                <span>{{orgUsersJson[userId]?.name | truncate: 15}}</span>
                            </span>
                            </ng-container>
                            </div>
                          </ng-container>

                          <!----Date Field-->
                          <ng-container *ngIf="currentLead.custom_fields[item.key].mirror_column_type === 'Date' && mirrorColumnsJson[mirrorItemId].custom_fields[currentLead?.custom_fields[item.key]?.mirror_column_key]?.date">
                            <div class="col-md-4 py-2 option-text">#{{mirrorColumnsJson[mirrorItemId].seq_id}}</div>
                            <div class="col-md-8 py-2 option-text">
                              {{mirrorColumnsJson[mirrorItemId].custom_fields[currentLead?.custom_fields[item.key]?.mirror_column_key] | dateCalcuate:"dateTime"}}
                            </div>
                          </ng-container>

                          <!----Currency-->
                          <ng-container *ngIf="currentLead.custom_fields[item.key].mirror_column_type == constantService.constant.CUSTOMTYPES.CURRENCY">
                            <div class="col-md-4 py-2 option-text">#{{mirrorColumnsJson[mirrorItemId].seq_id}}</div>
                            <div class="col-md-8 py-2 option-text">
                              <span>{{currencyJson[mirrorColumnsJson[mirrorItemId]?.custom_fields[currentLead?.custom_fields[item.key]?.mirror_column_key]?.currency]?.currencyCode}}: </span>
                                <span>{{mirrorColumnsJson[mirrorItemId]?.custom_fields[currentLead?.custom_fields[item.key]?.mirror_column_key]?.amount || 0}}</span>
                            </div>
                          </ng-container>

                          <!-----Rating-->
                          <ng-container *ngIf="currentLead.custom_fields[item.key].mirror_column_type === 'Rating'">
                            <div class="col-md-4 py-2 option-text">#{{mirrorColumnsJson[mirrorItemId].seq_id}}</div>
                            <div class="col-md-8 py-2 option-text">
                              <ngb-rating [(rate)]="mirrorColumnsJson[mirrorItemId].custom_fields[currentLead?.custom_fields[item.key]?.mirror_column_key]" [max]="5">
                              </ngb-rating>
                            </div>
                          </ng-container>

                          <!----TextArea--->
                        <ng-container *ngIf="currentLead.custom_fields[item.key].mirror_column_type === 'Textarea' && mirrorColumnsJson[mirrorItemId].custom_fields[currentLead?.custom_fields[item.key]?.mirror_column_key]">
                          <div class="col-md-4 py-2 option-text">#{{mirrorColumnsJson[mirrorItemId]?.seq_id}}</div>
                          <div class="col-md-8 py-2 option-text" [innerHTML]="mirrorColumnsJson[mirrorItemId].custom_fields[currentLead?.custom_fields[item.key]?.mirror_column_key] | safehtml"></div>
                        </ng-container>

                        <!------Image Field-->
                          <ng-container
                            *ngIf="currentLead.custom_fields[item.key].mirror_column_type === constantService.constant.CUSTOMTYPES.IMAGEFIELD">
                            <div class="col-md-4 py-2 option-text">#{{mirrorColumnsJson[mirrorItemId].seq_id}}</div>
                            <div class="col-md-8 py-2 option-text" (click)="openModalForPreview(mirrorColumnsJson[mirrorItemId].custom_fields[currentLead?.custom_fields[item.key]?.mirror_column_key],0,customFieldType.IMAGEFIELD,videoPlayerModalNested)">
                              <app-dashboard-icon [name]="currentLead.custom_fields[item.key].mirror_column_type" [iconJson]="MediaSmIcons"></app-dashboard-icon>
                              <span class="ml-2">{{mirrorColumnsJson[mirrorItemId].custom_fields[currentLead?.custom_fields[item.key]?.mirror_column_key].file_name
                                | truncate: 10}}</span>
                                <span>.{{commonUtils.getFileNameExtFromS3Path(mirrorColumnsJson[mirrorItemId].custom_fields[currentLead?.custom_fields[item.key]?.mirror_column_key].file_path).extension}}</span>
                            </div>
                          </ng-container>

                          <!-------Video Field-->
                          <ng-container
                            *ngIf="currentLead.custom_fields[item.key].mirror_column_type === constantService.constant.CUSTOMTYPES.VIDEO">
                            <div class="col-md-4 py-2 option-text">#{{mirrorColumnsJson[mirrorItemId].seq_id}}</div>
                            <div class="col-md-8 py-2 option-text" (click)="openModalForPreview(mirrorColumnsJson[mirrorItemId].custom_fields[currentLead?.custom_fields[item.key]?.mirror_column_key],0,customFieldType.VIDEO,videoPlayerModalNested)">
                              <app-dashboard-icon [name]="currentLead.custom_fields[item.key].mirror_column_type" [iconJson]="MediaSmIcons"></app-dashboard-icon>
                              <span>{{mirrorColumnsJson[mirrorItemId].custom_fields[currentLead?.custom_fields[item.key]?.mirror_column_key].file_name
                                | truncate: 10}}</span>
                                <span>.{{commonUtils.getFileNameExtFromS3Path(mirrorColumnsJson[mirrorItemId].custom_fields[currentLead?.custom_fields[item.key]?.mirror_column_key].file_path).extension}}</span>
                            </div>
                          </ng-container>

                          <!-------AUDIO Field-->
                          <ng-container
                          *ngIf="currentLead.custom_fields[item.key].mirror_column_type === constantService.constant.CUSTOMTYPES.AUDIO">
                          <div class="col-md-4 py-2 option-text">#{{mirrorColumnsJson[mirrorItemId].seq_id}}</div>
                          <div class="col-md-8 py-2 option-text" (click)="openModalForPreview(mirrorColumnsJson[mirrorItemId].custom_fields[currentLead?.custom_fields[item.key]?.mirror_column_key],0,customFieldType.AUDIO,videoPlayerModalNested)">
                            <app-dashboard-icon [name]="currentLead.custom_fields[item.key].mirror_column_type" [iconJson]="MediaSmIcons"></app-dashboard-icon>
                            <span>{{mirrorColumnsJson[mirrorItemId].custom_fields[currentLead?.custom_fields[item.key]?.mirror_column_key].file_name
                              | truncate: 10}}</span>
                              <span>.{{commonUtils.getFileNameExtFromS3Path(mirrorColumnsJson[mirrorItemId].custom_fields[currentLead?.custom_fields[item.key]?.mirror_column_key].file_path).extension}}</span>
                          </div>
                        </ng-container>

                          <!------File Field-->
                          <ng-container
                            *ngIf="currentLead.custom_fields[item.key].mirror_column_type === constantService.constant.CUSTOMTYPES.FILEUPLOAD">
                            <div class="col-md-4 py-2 option-text">#{{mirrorColumnsJson[mirrorItemId].seq_id}}</div>
                            <div class="col-md-8 py-2 option-text d-flex align-items-center" (click)="openModalForPreview(mirrorColumnsJson[mirrorItemId].custom_fields[currentLead?.custom_fields[item.key]?.mirror_column_key],0,customFieldType.FILEUPLOAD,videoPlayerModalNested)">
                              <app-dashboard-icon [name]="currentLead.custom_fields[item.key].mirror_column_type" [iconJson]="MediaSmIcons"></app-dashboard-icon>
                              <span class="ml-2">{{mirrorColumnsJson[mirrorItemId].custom_fields[currentLead?.custom_fields[item.key]?.mirror_column_key].file_name |
                                truncate: 10}}</span>
                                <span>.{{commonUtils.getFileNameExtFromS3Path(mirrorColumnsJson[mirrorItemId].custom_fields[currentLead?.custom_fields[item.key]?.mirror_column_key].file_path).extension}}</span>
                            </div>
                          </ng-container>

                          <!----Multiple Image Field-->
                          <ng-container
                            *ngIf="currentLead.custom_fields[item.key].mirror_column_type === constantService.constant.CUSTOMTYPES.MULTIIMAGE">
                            <div class="col-md-4 py-2 option-text">#{{mirrorColumnsJson[mirrorItemId].seq_id}}</div>
                            <div class="col-md-8 py-2 option-text d-flex flex-column" style="row-gap: 6px;">
                              <ng-container *ngFor="let file of mirrorColumnsJson[mirrorItemId].custom_fields[currentLead?.custom_fields[item.key]?.mirror_column_key]">
                                <span (click)="openModalForPreview(file,0,customFieldType.MULTIIMAGE,videoPlayerModalNested)">
                                  <app-dashboard-icon [name]="currentLead.custom_fields[item.key].mirror_column_type" [iconJson]="MediaSmIcons"></app-dashboard-icon>
                                  <span class="option-text">{{file.file_name | truncate: 10}}</span>
                                  <span>.{{commonUtils.getFileNameExtFromS3Path(file.file_path).extension}}</span>
                                </span>
                            
                              </ng-container>
                            </div>
                          </ng-container>

                          <!-----Multiple Video Field-->
                          <ng-container
                            *ngIf="currentLead.custom_fields[item.key].mirror_column_type === constantService.constant.CUSTOMTYPES.MULTIVIDEO">
                            <div class="col-md-4 py-2 option-text">#{{mirrorColumnsJson[mirrorItemId].seq_id}}</div>
                            <div class="col-md-8 py-2 option-text d-flex flex flex-column" style="row-gap: 6px;">
                              <ng-container *ngFor="let file of mirrorColumnsJson[mirrorItemId].custom_fields[currentLead?.custom_fields[item.key]?.mirror_column_key]">
                                <span (click)="openModalForPreview(file,0,customFieldType.MULTIVIDEO,videoPlayerModalNested)">
                                  <app-dashboard-icon [name]="currentLead.custom_fields[item.key].mirror_column_type" [iconJson]="MediaSmIcons"></app-dashboard-icon>
                                  <span class="option-text ml-2">{{file.file_name | truncate: 10}}</span>
                                  <span>.{{commonUtils.getFileNameExtFromS3Path(file.file_path).extension}}</span>
                                </span>
                              </ng-container>
                            </div>
                          </ng-container>
                          <!-----Multiple AUDIO Field-->
                          <ng-container
                            *ngIf="currentLead.custom_fields[item.key].mirror_column_type === constantService.constant.CUSTOMTYPES.MULTIAUDIO">
                            <div class="col-md-4 py-2 option-text">#{{mirrorColumnsJson[mirrorItemId].seq_id}}</div>
                            <div class="col-md-8 py-2 option-text d-flex flex flex-column" style="row-gap: 6px;">
                              <ng-container *ngFor="let file of mirrorColumnsJson[mirrorItemId].custom_fields[currentLead?.custom_fields[item.key]?.mirror_column_key]">
                                <span (click)="openModalForPreview(file,0,customFieldType.MULTIAUDIO,videoPlayerModalNested)">
                                  <app-dashboard-icon [name]="currentLead.custom_fields[item.key].mirror_column_type" [iconJson]="MediaSmIcons"></app-dashboard-icon>
                                  <span class="option-text ml-2">{{file.file_name | truncate: 10}}</span>
                                  <span>.{{commonUtils.getFileNameExtFromS3Path(file.file_path).extension}}</span>
                                </span>
                              </ng-container>
                            </div>
                          </ng-container>

                          <!------Multiple File Upload-->
                          <ng-container
                            *ngIf="currentLead.custom_fields[item.key].mirror_column_type === constantService.constant.CUSTOMTYPES.MULTIFILEUPLOAD">
                            <div class="col-md-4 py-2 option-text">#{{mirrorColumnsJson[mirrorItemId].seq_id}}</div>
                            <div class="col-md-8 py-2 option-text d-flex flex-column" style="row-gap: 6px;">
                              <ng-container *ngFor="let file of mirrorColumnsJson[mirrorItemId].custom_fields[currentLead?.custom_fields[item.key]?.mirror_column_key]">
                                <span class="d-flex align-items-center" (click)="openModalForPreview(file,0,customFieldType.MULTIFILEUPLOAD,videoPlayerModalNested)">
                                  <app-dashboard-icon [name]="currentLead.custom_fields[item.key].mirror_column_type" [iconJson]="MediaSmIcons"></app-dashboard-icon>
                                  <span class="option-text ml-2">{{file.file_name | truncate: 10}}</span>
                                  <span>.{{commonUtils.getFileNameExtFromS3Path(file.file_path).extension}}</span>
                                </span>
                              </ng-container>
                            </div>
                          </ng-container>
                        </ng-container>
                        </ng-template>
                      </ng-container>
                        <!-- Connected Board -->
                        <ng-container *ngIf="['Board','MultipleBoard'].includes(currentLead.custom_fields[item.key].mirror_column_type)">
                          <div class="col-md-12" *ngIf="item?.table_view !==false; else itemList">
                            <app-item-list-grid 
                            [itemIdList]="currentLead.custom_fields[item.key].mirror_item_id" 
                            [connectedBoardKey]="currentLead.custom_fields[item.key]?.mirror_column_key" 
                            [currentLead]="leadObj"
                            [tabIndex]="tabIndex" 
                            [dashId]="currentLead.custom_fields[item.key].mirror_dashboard_id" 
                            [showBoardName]="false" 
                            [showRemoveIcon]="false" 
                            [boardConnectedItemIds]="currentLead.custom_fields[item.key].mirror_item_id"
                            (onItemOpen)="onItemOpen?.emit($event)"
                            (onExpendTable)="onItemOpen?.emit($event)"
                            [isReadOnly]="true"></app-item-list-grid>
                          </div>
                          <ng-template #itemList>
                            <div class="col-md-12">
                              <app-item-list [itemIdList]="currentLead.custom_fields[item.key].mirror_item_id" [connectedBoardKey]="currentLead.custom_fields[item.key]?.mirror_column_key" [currentLead]="leadObj"
                              [tabIndex]="tabIndex" [itemListforBoard]="true" [dashId]="currentLead.custom_fields[item.key].mirror_dashboard_id" [showBoardName]="false" [showRemoveIcon]="false" [isReadOnly]="true" (onItemOpen)="onItemOpen?.emit($event)"></app-item-list>
                            </div>  
                          </ng-template>
                        </ng-container>
                    </ng-container>
                  <!-- </ng-container> -->
              </div>
          </ng-container>
          <ng-container *ngIf="item.type == customFieldType.RATING && isNonHidden(item)">
            <div class="row g-0 editleadcustominputsrow key-tab">
              <div class="col-md-3 fieldLabelBox f-l-r " role="button">
                <app-dashboard-icon [name]="item.type" [iconJson]="attributesIcons" class="labelimg" [size]="14"></app-dashboard-icon>
                <span class="labelText" appOverflowCheck [ngbTooltip]>{{ item.display_key }}</span><span *ngIf="item.mandatory" style="color: red;">*</span>
              </div>
              <div class="col-md-9 ratiingColumn fieldValueBox">
                <ngb-rating style="font-size: 16px; align-items: center; height: 30px; column-gap: 5px; color: #3571de !important; padding: 0px 7px;" [required]="item.mandatory" [readonly]="isPreview  || isItemLocked(item.locked) || isReadOnly" [(rate)]="item.ratingInput" [max]="5" [disabled]="isReadOnly" 
                [resettable]="true && !item.mandatory && !isReadOnly"
                (rateChange)="!isReadOnly && upateRating(arrayOfCustomFiled, customFieldType.RATING, item.key)">
                </ngb-rating>
              </div>
          </div> 
          </ng-container>
      
      </ng-container>   
  </ng-container>
  </div>
</div>


 
<ng-template #videoPlayerModalNested let-modal>
  <div class="previewBodyModal" style="height: 100%;max-width: 100%;position: relative;">
    <!-- <i class="fa fa-close bg-white previewCross" (click)="modal.dismiss()"></i> -->
    <app-file-preview [mediaArr]="mediaArr" [index]="indexForPreview" [type]="'ITEM'" [dashId]="dashId" [typeId]="leadId" [item]="itemData" (openEditImageModal)="openEditImageModal($event)" [customFieldType]="customFieldType" (closeModal)="modal.dismiss()"></app-file-preview>
  </div>
</ng-template>

<!-- image ediot -->
<ng-template #imageEditorModal let-modal>
  <div class="previewBodyModal" style="height: 100%;max-width: 100%;position: relative;">
    <app-image-editor [imageFiles]="editImageFiles?.fileData" [fileType]="editImageFiles?.type" [itemData]="editImageFiles?.itemData" (closeModal)="modal.dismiss()" (editImage)="onUploadFile($event,editImageFiles?.type,editImageFiles?.key,false,editImageFiles?.filePreviouspath)" ></app-image-editor>
  </div>
</ng-template>

<!-- Delete Confrimation Modal  -->
<ng-template #deleteModal let-modal>
  <div class="modal-body text-center py-3">
    <h3>{{'common.confirmation' | translate}}</h3> 
    <img src="../../../assets/1x/info.png"><br><br>
    {{'common.deleteConfirmation' | translate}}<br><br>
    <button type="button" class="btn btn-danger rounded px-4 mr-3"  (click)="removeFile(toBeDeleteIndex, toBeDeleteItem, toBeDeleteKey); modal.dismiss()">{{'common.confirm' | translate}}</button>
    <button type="button" class="btn rounded cancelbtn px-4" (click)="modal.dismiss()">{{'common.cancel' | translate}}</button>
  </div>
</ng-template>

<ng-container *ngIf="openScreenRecorderPlayer">
  <app-screen-recording leadId={{leadId}} (recordingStarted)="recordingStarted = $event;recordingStatusChanged($event);startTimer()" [audioFlag]="audioFlag"
    (recordedData)="getScreenRecordedData($event,fileType,selectedKey)"></app-screen-recording>
</ng-container>
<ng-container *ngIf="openAudioRecorderPlayer">
  <app-audio-recording leadId={{leadId}} (recordingStarted)="recordingStarted = $event;recordingStatusChanged($event);startTimer()"
    (recordedData)="getScreenRecordedData($event,fileType,selectedKey)"></app-audio-recording>
</ng-container>
<ng-template #addcustomlead let-modal>
  <app-create-lead-modal [dashId]="connectedBoardId" (emitCreatedItemId)="setItemToBoard(connectedBoardItem,{_id:$event},connectedBoardItem?.type ,connectedBoardItem?.key)" (createLeadModalCloseEvent)="modal.dismiss();" style="height: 100%;">
  </app-create-lead-modal>
</ng-template>
<ng-template #mediaPermissionModal let-modal>
  <div class="modal-body text-center py-3">
    <h3>{{'common.alert'|translate}}</h3> 
    <img src="../../../assets/1x/info.png"><br><br>
    {{'editcustom.MICROPHONE_PERMISSION_WARNING'|translate}}
  </div>
</ng-template>
  
  
<!-- RENAME MEDIA -->
<ng-template #renameMediaModal let-modal>
  <div class="renameMediaPopup">  
    <div class="modal-header border-bottom my-1">
        <h5 class="modal-title w-75" id="modal-basic-title"> {{'common.rename' | translate}} </h5>
        <button type="button w-25" class="close mt-3 pr-5" aria-label="Close" (click)= "modalRefRename?.hide(); renameObj=={name:''};">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="py-3 modal-body">
      <label>{{'drive.fileName' | translate}}</label><br>
      <input type="text" class="form-control mb-3" [(ngModel)]="renameObj.name" placeholder="{{'common.rename' | translate}}" maxlength="256" minlength="3" (keydown.enter)="renameObj?.name?.length>=3 && applyRenameMedia()" (keydown)="removeExtraSpaces()">
      <span class="text-danger" *ngIf="renameObj?.name?.length<3"> {{'drive.fileNameMin' | translate}}</span>
    </div>  
    <div class="border-toptext-right p-1 modal-footer">                        
        <button [disabled]="renameObj?.name?.length<3" 
        (click)="renameObj?.name?.length>=3 && applyRenameMedia() " 
        class="renameMediaBtn ml-auto float-right" [ngClass]="{'disabledRename': renameObj?.name?.length<3}">{{'common.save' | translate}}</button>
    </div>
  </div>
</ng-template>


<!-- added non custom field template -->
<ng-template #nonCustomFields>
  <div class="non-custom" *ngIf="addTitile || (!publicLead && !itemAttributesObj?.start_end_date?.hidden) || (!publicLead && !itemAttributesObj?.assigned_to?.hidden) || (!publicLead && !itemAttributesObj?.tag_id?.hidden) || (!publicLead && !itemAttributesObj?.sprint_id?.hidden && dashboardInfo?.sprint_enabled) || !itemAttributesObj?.priority?.hidden || !itemAttributesObj?.bucket_id?.hidden">
    <div *ngIf="(!itemAttributesObj?.assigned_to?.hidden || !itemAttributesObj?.priority?.hidden || !itemAttributesObj?.bucket_id?.hidden)" class="non-custom-wrapper">
      <!-- Start priority -->
      <div *ngIf="!itemAttributesObj?.priority?.hidden" class="option-field key-tab">
        <span class="cardTag add-truncate-property-predefined" appOverflowCheck [ngbTooltip]>
          <span class="tagText" [ngbTooltip]="itemAttributesObj?.priority?.label?.length>15 ? itemAttributesObj?.priority?.label : ''" placement="auto" container="body"  tooltipClass="tooltipPopup">{{itemAttributesObj?.priority?.label | attributesLabel: itemAttributesObj: 'priority': 'editcustom.priority' | translate}}</span>
        </span>
        <span class="bucketPriorityBox" [placement]="['end-top','auto']" (click)="$event.stopPropagation(); getPopOverRef(popOverAssignee)"  #priorityPopoverRef="ngbPopover" [autoClose]="'outside'" [ngbPopover]="!isReadOnly && !isPreview && priorityTemplate" [positionTarget]="priorityTarget" triggers="manual">
          <span  #priorityTarget [ngStyle]="{'background-color': priorityJson[leadObj?.priority]?.color ,'color':priorityJson[leadObj?.priority] ? 'white' : ''}" class="value key-focus priorityValue" (click)="priorityPopoverRef?.open()" tabindex="0">
            <ng-container *ngIf="leadObj?.priority!=null">
              {{priorityJson[leadObj?.priority]?.name}}
            </ng-container>
            <ng-container *ngIf="leadObj?.priority==null">
              {{'editcustom.none' | translate}}
            </ng-container>            
          </span>
        </span>
        <ng-template #priorityTemplate>
          <app-lead-priority (click)="$event.stopPropagation()" [leadInfo]="!triggeredFromCreateACopy && leadId && leadObj ? [leadObj] : null" [dashId]="leadObj?.dashboard_id" (updateLead)="onNonCustomUpdate('priority',$event);priorityPopoverRef?.close()" [bulkSelectionFlag]="false">
          </app-lead-priority>
      </ng-template>
      
      </div>
      <!-- end : priority -->
       <!-- Start bucket -->
      <div *ngIf="!itemAttributesObj?.bucket_id?.hidden" class="option-field key-tab">
        <span class="cardTag add-truncate-property-predefined" appOverflowCheck [ngbTooltip]>
          <span class="tagText" [ngbTooltip]="itemAttributesObj?.bucket_id?.label?.length>15 ? itemAttributesObj?.bucket_id?.label : ''" placement="auto" container="body"  tooltipClass="tooltipPopup">{{itemAttributesObj?.bucket_id?.label | attributesLabel: itemAttributesObj: 'bucket_id': 'editcustom.bucket' | translate}}</span>
        </span>
        <span class="bucketPriorityBox" [placement]="['end-top','auto']" (click)="$event.stopPropagation(); getPopOverRef(popOverAssignee)"  #bucketPopoverTempRef="ngbPopover" [autoClose]="'outside'" [ngbPopover]="!isReadOnly && !isPreview && bucketPopoverTemp" [positionTarget]="bucketTarget" triggers="manual">
          <span #bucketTarget class="value key-focus bucketValue" [ngStyle]="{'background-color': leadObj?.bucket_color ? leadObj?.bucket_color : '','color': leadObj?.bucket_color ? commonUtils.getContrastColor(leadObj?.bucket_color) : ''}" (click)="bucketPopoverTempRef?.open()" tabindex="0">
            <ng-container *ngIf="leadObj?.bucket_id">
              <ng-container *ngIf="leadObj?.bucket_name">
                <span class="cardTag" style="border: none;">
                  <span class="tagText" appOverflowCheck [ngbTooltip]>{{leadObj?.bucket_name}}</span>
                </span>
              </ng-container>
              <ng-container *ngIf="!leadObj?.bucket_name">
                <ng-container *ngFor="let bucket of bucketArray">
                  <ng-container *ngIf="bucket?._id===leadObj?.bucket_id">
                    <span class="cardTag" style="border: none;">
                      <span class="tagText" appOverflowCheck [ngbTooltip]>{{bucket?.name}}</span>
                    </span>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!leadObj?.bucket_id">
              {{'editcustom.none' | translate}}
            </ng-container>            
          </span>
        </span>
        <ng-template #bucketPopoverTemp>
          <app-lead-bucket-change (click)="$event.stopPropagation();"
              [dashId]="leadObj?.dashboard_id"
              [leadInfo]="!triggeredFromCreateACopy && leadId && leadObj ? [leadObj] : null"
              [bucketArray]="bucketArray" [bulkSelectionFlag]="false"
              (updateLead)="onNonCustomUpdate('bucket_id',$event);leadObj['bucket_name']=$event?.bucket_name;bucketPopoverTempRef?.close()">
          </app-lead-bucket-change>
        </ng-template>
      </div>
      <!-- end : bucket -->
      <!-- start : Aissignee -->
      <div *ngIf="!publicLead && !itemAttributesObj?.assigned_to?.hidden" class="option-field key-tab">
        <span class="cardTag add-truncate-property-predefined" appOverflowCheck [ngbTooltip]>
          <span class="tagText" [ngbTooltip]="itemAttributesObj?.assigned_to?.label?.length>15 ? itemAttributesObj?.assigned_to?.label : ''" placement="auto" container="body"  tooltipClass="tooltipPopup">{{ itemAttributesObj?.assigned_to?.label | attributesLabel: itemAttributesObj: 'assigned_to': 'editcustom.assignee' | translate}}</span>
        </span>
        <span class="d-flex" [placement]="['end-top','auto']"  (click)="$event.stopPropagation(); getPopOverRef(popOverAssignee)"  #popOverAssignee="ngbPopover" [autoClose]="'outside'" [ngbPopover]="!isReadOnly && !isPreview && assignUserTemplate" [positionTarget]="assgneeTarget" triggers="manual">
          <span #assgneeTarget class="value key-focus" [ngbPopover]="leadObj?.assigned_to && userInfoCard" popoverClass="userInfoCardPopover" triggers="hover" placement="auto"  container="body" (click)="popOverAssignee?.open()" tabindex="0">
            <ng-container *ngIf="orgUsersJson && leadObj?.assigned_to">
              <ngx-avatars   [name]="orgUsersJson[leadObj?.assigned_to]?.name ? orgUsersJson[leadObj?.assigned_to]?.name : 'Assign'" [src]="orgUsersJson[leadObj?.assigned_to]?.image_url" size="24" initialsSize="2" textSizeRatio="2.2"></ngx-avatars>
              <ng-template #userInfoCard>
                <app-user-info-card [user]="orgUsersJson[leadObj?.assigned_to]"></app-user-info-card>
              </ng-template>
            </ng-container>
            <ng-container *ngIf="!leadObj?.assigned_to">
              <img src="../../assets/common-use-icon-20dp/add-user-6d6e6f.svg">
            </ng-container>            
          </span>
        </span>
        <ng-template #assignUserTemplate>
          <app-lead-assign-user
          [assignedUser]="leadObj?.assigned_to"
          [dashId]="dashId"
          [leadInfo]="!triggeredFromCreateACopy && leadId && leadObj ? [leadObj] : null"
          [bulkSelectionFlag]="false"
          (updateLead)="onNonCustomUpdate('assigned_to',$event);popOverAssignee?.close()"
          ></app-lead-assign-user>
        </ng-template>
      </div>
      <!-- end : Aissignee -->
    </div>
    <div class="customFieldContainer">
      <ng-container *ngIf="addTitile && !['CONTACT','ORGANIZATION'].includes(dashboardInfo?.board_type)">
        <div class="row editleadcustominputsrow key-tab">
            <div class="row g-0 mx-0 fieldLabelBox f-l-r w-100">
              <span class="labelimg"><app-dashboard-icon   [name]="'Text'" [iconJson]="attributesIcons" [size]="14" class="labelimg"></app-dashboard-icon></span>
              <span class="cardTag add-truncate-property-predefined" appOverflowCheck [ngbTooltip]>
                <span class="tagText" style="color: var(--label-color);font-weight: 500;" [ngbTooltip]="itemAttributesObj?.title?.label?.length>15 ? itemAttributesObj?.title?.label : ''" placement="auto" container="body"  tooltipClass="tooltipPopup"> {{ itemAttributesObj?.title?.label | attributesLabel: itemAttributesObj: 'title': 'editcustom.summary' | translate}}</span>
                <span style="color: red;"  *ngIf="!isTemplateCall">*</span>
              </span>
            </div>
            <div  class="row g-0 mx-0  w-100 textFieldAreaBox">
              <textarea [(ngModel)]="editTitle" [readonly]="isReadOnly || isPublicLead" [required]="true" textAssist [optionFields]="triggeredFromCreateACopy ? {'chatSuggestions':[],'aiSuggestions':[]} : {'suggestions':[],'aiSuggestions':[]}" [appendTo]="'body'" [textEditable]="!isReadOnly" (onOptionSelect)="handledTextAssist($event)"
                    [ngModelOptions]="{standalone: true}" autosize class="emptytextarea hoverBorder key-focus"
                    tabindex="0"
                    (ngModelChange)="(triggeredFromCreateACopy && leadId || !leadId) && addTitleForLeadCreation();updatedCustomFieldObj()"
                    (blur)="!triggeredFromCreateACopy && leadId && !isReadOnly &&!isPublicLead && updateLeadInfo(leadId)" #titlefield="ngModel"
                    [minRows]="1" placeholder="{{'placeholders.addYourTextHere' | translate}}">
              </textarea>
              <div *ngIf="titlefield.invalid && (titlefield.dirty || titlefield.touched)">
                <span class="validationText ls-025x" *ngIf="!isTemplateCall">{{'editcustom.titleField' | translate}}  {{'editcustom.Required' | translate}}</span>
              </div>
            </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!publicLead && !itemAttributesObj?.start_end_date?.hidden">
        <div class="row g-0 editleadcustominputsrow key-tab">
          <div class="col-md-3 fieldLabelBox f-l-r ">
            <span  class="labelimg"><app-dashboard-icon [size]="14"  [name]="'StartEndDate'" ></app-dashboard-icon></span>
            <span class="cardTag add-truncate-property-predefined" appOverflowCheck [ngbTooltip]>
              <span class="tagText" style="color: var(--label-color);font-weight: 500;" [ngbTooltip]="itemAttributesObj?.start_end_date?.label?.length>15 ? itemAttributesObj?.start_end_date?.label : ''" placement="auto" container="body"  tooltipClass="tooltipPopup">{{ itemAttributesObj?.start_end_date?.label? itemAttributesObj?.start_end_date?.label : 'Start End Date'}}</span>
            </span>
          </div>
          <div class="col-md-9 dateColumn fieldValueBox">
            <div class="form-inline border-0 rounded inpt p-0">
                <div class="input-group d-flex datePickerBox key-focus gap6" [ngbPopover]="!isReadOnly && !isPreview && datePopover" #datePopoverRef="ngbPopover" placement="auto" container="body" #popOver="ngbPopover" popoverClass="datePickerPopover" [autoClose]="'outside'" (click)="$event.stopPropagation()" container="body" tabindex="0"> 
                    <span class="iconBox-20"><app-dashboard-icon class="appInnerIconBox" [size]="16"  [name]="'StartEndDate'" ></app-dashboard-icon></span>
                    <span class="f-l-r" *ngIf="!leadObj?.start_date?.date && !leadObj?.end_date?.date"> Start End Date</span>
                    <div class="d-flex align-items-center gap6" *ngIf="leadObj?.start_date?.date || leadObj?.end_date?.date">
                      <span class="d-flex align-items-center gap2" *ngIf="leadObj?.start_date?.date">
                        <span class="f-l-r" *ngIf="leadObj?.start_date?.date && !leadObj?.end_date?.date">Starts</span>
                        <span class="f-l-r" *ngIf="leadObj?.start_date?.date">{{leadObj?.start_date?.time ? (leadObj?.start_date | dateCalcuate:"dateTime") : 'start date'}}</span>  
                      </span> 
                      <span class="d-flex f-s-r" *ngIf="leadObj?.start_date?.date && leadObj?.end_date?.date">-</span>
                      <span class="d-flex align-items-center gap2" *ngIf="leadObj?.end_date?.date">
                        <span class="f-l-r" *ngIf="!leadObj?.start_date?.date && leadObj?.end_date?.date">Ends</span>
                        <span class="f-l-r" *ngIf="leadObj?.end_date?.date">{{leadObj?.end_date?.time ? (leadObj?.end_date | dateCalcuate:"dateTime") : 'end date'}}</span>
                      </span>
                    </div>
                </div> 
            </div>
          </div>    
        </div>
        <ng-template #datePopover>
          <app-lead-date-picker (click)="$event.stopPropagation()"
          [leadInfo]="!triggeredFromCreateACopy && leadId && leadObj ? [leadObj] : null"
          [selectedDashId]="dashId"
          [selectedStartDateObj]="leadObj?.start_date"
          [selectedEndDateObj]="leadObj?.end_date"
          (setDateTime)="onNonCustomUpdate('start_end_date',$event)"
          (closeDateModalOnCancel)="datePopoverRef?.close()">
          </app-lead-date-picker>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="!publicLead && !itemAttributesObj?.tag_id?.hidden">
        <div class="row g-0 editleadcustominputsrow key-tab">
          <div class="col-md-3 fieldLabelBox f-l-r ">
            <!-- <span><img src="../../../assets/common-use-icon-20dp/tag-3571de.svg"></span> -->
            <app-dashboard-icon  class="labelimg" [size]="14"  [name]="'Tags'" ></app-dashboard-icon>
            <span class="cardTag add-truncate-property-predefined" appOverflowCheck [ngbTooltip]>
              <span class="tagText" style="color: var(--label-color);font-weight: 500;" [ngbTooltip]="itemAttributesObj?.tag_id?.label?.length>15 ? itemAttributesObj?.tag_id?.label : ''" placement="auto" container="body"  tooltipClass="tooltipPopup">{{ itemAttributesObj?.tag_id?.label | attributesLabel: itemAttributesObj: 'tag_id': 'setting.tags' | translate}}</span>
            </span>
          </div>
          <div class="col-md-9 dateColumn fieldValueBox">
            <div class="form-inline border-0 rounded inpt p-0">
                <div class="input-group d-inline-flex datePickerBox tag-wrapper key-focus" [ngbPopover]="!isReadOnly && !isPreview && tagPopTemplate" #tagPopoverRef="ngbPopover" [placement]="['bottom','bottom-right','right','top','auto']" #popOver="ngbPopover" [autoClose]="'outside'" (click)="$event.stopPropagation()" tabindex="0">
                  <ng-container *ngIf="leadObj?.tag_id?.length>0">
                    <ng-container *ngFor="let tagId of leadObj.tag_id">
                      <span *ngIf="tagsCorrespondingToDash[tagId]" class="cardTag" [ngStyle]="{'background-color': tagsCorrespondingToDash[tagId]?.color ? tagsCorrespondingToDash[tagId].color : '','color': tagsCorrespondingToDash[tagId]?.color ? commonUtils.getContrastColor(tagsCorrespondingToDash[tagId]?.color) : ''}">
                        <span class="tagText" appOverflowCheck [ngbTooltip]>{{tagsCorrespondingToDash[tagId].name}}</span>
                      </span>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="!leadObj?.tag_id?.length">
                    {{'tag.addTag'|translate}}
                  </ng-container>
                </div> 
            </div>
          </div>    
        </div>
        <ng-template #tagPopTemplate>
          <ng-container *ngIf="!showCreateTag">
            <app-lead-tags (click)="$event.stopPropagation()" [dashboardTagsJson]="tagsCorrespondingToDash" [leadInfo]="!triggeredFromCreateACopy && leadId && leadObj ? [leadObj] : null"
            [dashId]="dashId" [bulkSelectionFlag]="false"
            (updateLead)="onNonCustomUpdate('tag_id',$event);popOverAssignee?.close()"
            (showCreateTag)="showCreateTag = $event">
          </app-lead-tags>
          </ng-container>
          <ng-container *ngIf="showCreateTag">
            <app-create-tag (updateDashboardTags)="tagsCorrespondingToDash = $event;" [dashId]="dashId" (showConfirmationModal)="showConfirmationModalFlag = $event" (closeManageTag)="showCreateTag = $event" [confirmationAction]="confirmationAction"></app-create-tag>
          </ng-container>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="!publicLead && !itemAttributesObj?.sprint_id?.hidden && dashboardInfo?.sprint_enabled">
        <div class="row g-0 editleadcustominputsrow key-tab">
          <div class="col-md-3 fieldLabelBox f-l-r ">
            <app-dashboard-icon  class="labelimg" [size]="14"  [name]="'Sprints'" ></app-dashboard-icon>
            <span class="labelText">{{ itemAttributesObj?.sprint_id?.label | attributesLabel: itemAttributesObj: 'phase': 'setting.phase' | translate}}</span>
                      </div>
          <div class="col-md-9 dateColumn fieldValueBox">
            <div class="form-inline border-0 rounded inpt p-0">
                <div class="input-group d-inline-flex datePickerBox key-focus" [ngbPopover]="!isReadOnly && !isPreview && sprintTemplate" #sprintPopoverRef="ngbPopover" placement="auto" container="body" #popOver="ngbPopover" [autoClose]="'outside'" (click)="$event.stopPropagation()" container="body" tabindex="0">
                  <ng-container *ngIf="leadObj?.sprint_id">
                      <ng-container *ngFor="let sprintData of dashBoardSprintArr">
                        <span *ngIf="sprintData._id == leadObj?.sprint_id" class="phaseCard">
                          <span class="f-l-r" appOverflowCheck [ngbTooltip]>{{sprintData.name}}</span>
                        </span>
                      </ng-container>
                  </ng-container>
                  <ng-container *ngIf="!leadObj?.sprint_id">{{'editcustom.ADD_PHASE'|translate}}</ng-container>
                </div> 
            </div>
          </div>    
        </div>
        <ng-template #sprintTemplate>
          <ng-container>
              <app-lead-sprint (click)="$event.stopPropagation();"
                  [leadInfo]="!triggeredFromCreateACopy && leadId && leadObj ? [leadObj] : null" [dashId]="dashId"
                  [bulkSelectionFlag]="false"
                  [dashBoardSprint]="dashBoardSprintArr || []"
                  [selectedPhase]="leadObj?.sprint_id"
                  (updateLead)="onNonCustomUpdate('sprint_id',$event);sprintPopoverRef?.close()">
              </app-lead-sprint>
          </ng-container>
      </ng-template>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #iframeOptions let-modal>
    <iframe [src]="linkToOpenIframe" width="1200" height="800"></iframe>
</ng-template>

