import { Component, EventEmitter, HostListener, Input, OnInit, Output, SimpleChange, ViewChild } from "@angular/core";
import { NgbModal, NgbPopover } from "@ng-bootstrap/ng-bootstrap";
import { CommonUtils } from "app/services/CommonUtils.service";
import { DashboardUtilsService } from "app/services/dashboard-utils.service";
import { HttpTransferService } from "app/services/httpTransfer.service";
import { el, te } from "date-fns/locale";

@Component({
    selector: 'app-channel-template-picker',
    templateUrl: './channel-template-picker.component.html',
    styleUrls: ['./channel-template-picker.component.scss']
  })
  export class ChannelTemplatePickerComponent implements OnInit {
    @Input() templateSearchText:any;
    @Input() showTemplateContent:boolean=false;
    @Input() dashId:any;
    @Input() leadInfo: any;
    @Input() sprintArr:any;
    @Input() orgUsersJson:any;
    @Input() emailTemplates: boolean = false;
    @Input() mobileField:boolean=false;
    @Output() sendTemplateEmit : EventEmitter<any> = new EventEmitter();
    @Output() closeEmit : EventEmitter<any> = new EventEmitter();
    whatsappTemplates: any[]=[];
    searchTemplate: any[]=[];
    customFieldArr: any[]=[];
    currencyJson:any[]=[]
    connecteLeadIdArr: any[]=[];
    mirrorColumnsJson: any = {};
    mediaArr:any[]=[]
    showList: boolean = false;
    isReadOnly:boolean=false;
    selectedIndex: number=0;
    constructor(public httpTransfer : HttpTransferService,public commonUtils : CommonUtils,public dashboardUtils : DashboardUtilsService,private modalService : NgbModal) { }
    loader:boolean=true;
    
  
    async ngOnInit() {
      let dashboardJson = await this.dashboardUtils.getDashboardCustomForms(this.dashId)
      let dashJson = JSON.parse(JSON.stringify(dashboardJson))
      this.customFieldArr = JSON.parse(JSON.stringify(dashJson[this.dashId]['CUSTOM_FORM'])) || {};  
      this.mirrorColumnsJson = await this.commonUtils.getConnectedLeadMirrorJson(this.leadInfo,this.customFieldArr)
      this.currencyJson = await this.dashboardUtils.getCurrencyJson() 
      this.isReadOnly = await this.commonUtils.isDashboardReadOnly(this.dashId);
      console.log(this.leadInfo)
        this.getTemplatesForChannel()
    }

    async ngOnChanges(changes:SimpleChange){
      if(changes['templateSearchText'] && this.templateSearchText) {
      this.whatsappTemplates = this.searchTemplate.filter((data) => data?.message.toString().toLowerCase().includes(this.templateSearchText));
      }
}



     async getTemplatesForChannel(){
      this.loader=true
        let inputJson={
          'dashboard_id' : [this.dashId],
          "template_category": ["BOARD_TEMPLATES", "PERSONAL_TEMPLATES"],
          "type": this.emailTemplates ?  ["EMAIL"] : ["CHANNEL"],
        }
        this.httpTransfer.getEmailTemplate(inputJson).subscribe((res : any) =>{
          this.whatsappTemplates = res?.result?.templates
           this.whatsappTemplates.forEach(async t=>{
            //t['templateMessage'] = await this.getMessageFromTemplate(t)
            if(t?.value_map){
              t['htmlcontentMsg']=t?.message
              Object.keys(t?.value_map).forEach(element=>{
                t['htmlcontentMsg'] = t?.htmlcontentMsg.replaceAll(`{{${t.value_map[element].index}}}`,`<span contenteditable='false' style='width:fit-content;border: 1px solid #CED0D5;border-radius: 7px;background-color:#fff;'><b hidden="true">{{${t.value_map[element].key}}}</b><span class="columsTootltip" style="margin:0 5px;" data-trigger="hover" data-toggle="popover" data-html="true" data-placement="auto" data-container="body" data-content="${t.value_map[element].display_label}">${t.value_map[element].display_key}</span><i class="fa fa-close" style="display:none;" id="removeColumn"></i>${" "}</span>`)
              })
            }
          }) 
          console.log(this.whatsappTemplates)
          this.searchTemplate =  [...this.whatsappTemplates]
          this.showList = true;
          this.loader=false
        })
    }

    filterItem(val) {
        val = val.toLowerCase();
        this.whatsappTemplates = this.searchTemplate.filter(
          (item) => item.template_name.toLowerCase().indexOf(val) !== -1 || !val
        );
    }  


    async getMessageFromTemplate(item) {
        console.log(item)
      if (item.attachments.length) {
          this.mediaArr = this.commonUtils.checkForColumnsMedia(item.attachments);
      }
      if (item?.value_map) {
          console.log(item?.value_map);
          await Promise.all(Object.keys(item?.value_map).map(async (element) => {
              if (item?.value_map[element].customField) {
                  let customObj = this.customFieldArr.filter(q => q?.key == item?.value_map[element]?.key);
                  let value = item?.value_map[element].mirror ? await this.getMirrorFieldsValue(item?.value_map[element]) : await this.commonUtils.checkForCustomFieldValueOnLead(customObj[0], this.leadInfo, item?.value_map[element]?.algorithm);
                  if (['SingleFile','SingleAudio','SingleImage','SingleVideo','MultipleImage','MultipleVideo','MultipleFile','MultipleAudio'].includes(customObj[0]?.type)) {
                      console.log(item.message);
                      console.log(value);
                      let arr = this.commonUtils.trueTypeOf(value) == "ARRAY" ? value : [value];
                      if(item.message.includes(`{{${item?.value_map[element].index}}}`)){
                        this.mediaArr = this.mediaArr.concat(arr)
                      }
                      item.message = item.message.replaceAll(`{{${item?.value_map[element].index}}}`, '');
                  } else {
                    item.message = item.message.replaceAll(`{{${item?.value_map[element].index}}}`, value);
                    if(item?.additional_attributes?.subject){
                        item.additional_attributes.subject = item?.additional_attributes?.subject.replaceAll(`{{${item?.value_map[element].index}}}`, value);
                    }
                  }
              } else {
                  if (item.message.includes(`{{${item?.value_map[element].index}}}`)) {
                      let value = await this.commonUtils.checkforNonCustomValue(item?.value_map[element].key, this.leadInfo)
                      item.message = item.message.replaceAll(`{{${item?.value_map[element].index}}}`, value);
                }
                if(item?.additional_attributes?.subject && item?.additional_attributes?.subject.includes(`{{${item?.value_map[element].index}}}`)){
                    let value = await this.commonUtils.checkforNonCustomValue(item?.value_map[element].key, this.leadInfo)
                    item.additional_attributes.subject = item?.additional_attributes?.subject.replaceAll(`{{${item?.value_map[element].index}}}`, value);
                }
              }
          }));
  
        console.log(item.message)
      }
      if (item?.message.includes('undefined')) {
          item.message = item.message.replaceAll('undefined', '');
      }
      let obj = {
          'message': item.message,
          'attachments': this.mediaArr
      };
      if(!this.emailTemplates){
        this.sendTemplateEmit.emit(obj);
      }
      else{
        item['attachments']= this.mediaArr 
        console.log(item)
        this.sendTemplateEmit.emit(item);
      }
  }
  

    async getMirrorFieldsValue(value_map) {
      let textToInsert = '';
      let mirrorItemIds = this.leadInfo.custom_fields[value_map?.key]?.mirror_item_id;
      if (mirrorItemIds?.length && value_map?.algorithm !== null) {
          let leadObj = [];
          if (['FETCH_FIRST', 'FETCH_LAST'].includes(value_map?.algorithm)) {
              leadObj = value_map?.algorithm === 'FETCH_FIRST' ?
                  [this.mirrorColumnsJson[mirrorItemIds[0]]] :
                  [this.mirrorColumnsJson[mirrorItemIds[mirrorItemIds?.length - 1]]];
          } else {
              mirrorItemIds.forEach(m => {
                  leadObj.push(this.mirrorColumnsJson[m]);
              });
          }
          if (leadObj?.length) {
              const results = await Promise.all(leadObj.map(async leadData => {
                  let dashboardJson = await this.dashboardUtils.getDashboardCustomForms(leadData?.dashboard_id);
                  let customFieldArr = JSON.parse(JSON.stringify(dashboardJson[leadData?.dashboard_id]['CUSTOM_FORM']));
                  let customObj = customFieldArr.filter(m => m.key == value_map?.mirror_column_key);
                  let result = customObj?.length ?
                      await this.commonUtils.checkForCustomFieldValueOnLead(customObj[0], leadData, value_map?.algorithm) :
                      await this.commonUtils.checkforNonCustomValue(value_map?.mirror_column_key, leadData);
                  console.log(result)
                      if (customObj?.length && ['SingleFile', 'SingleAudio', 'SingleImage', 'SingleVideo', 'MultipleImage', 'MultipleVideo', 'MultipleFile', 'MultipleAudio'].includes(customObj[0]?.type) && result) {
                      let arr = this.commonUtils.trueTypeOf(result) == "ARRAY" ? result : [result];
                      console.log(arr)
                      if (arr?.length) {
                          this.mediaArr = this.mediaArr.concat(arr)
                      }
                      result = '';
                  }
                  return result;
              }));
              textToInsert = results.join(" ");
          }
      }
      return textToInsert;
  }

  openChannelTemplateModal(modal){
    this.closeEmit.emit()
    this.modalService.open(modal,{ size: 'lg',windowClass: 'channelTemplateEditorModal'})
  }

  // Handle arrow key events
  @HostListener('window:keydown', ['$event'])
  async handleKeyboardEvent(event: KeyboardEvent) {
    if(this.showTemplateContent){
      if (event.key === 'ArrowDown') {
        this.selectedIndex = Math.min(this.selectedIndex + 1, this.whatsappTemplates.length - 1);
        event.preventDefault();
      } else if (event.key === 'ArrowUp') {
        this.selectedIndex = Math.max(this.selectedIndex - 1, -1);
        event.preventDefault();
      }
      const selectedDiv = document.getElementById(`tempDiv_${this.selectedIndex}`);
      if (selectedDiv) {
        selectedDiv.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
      if(event.key === 'Enter'){
       // this.sendTemplateEmit.emit({message:"neha"});
        await this.getMessageFromTemplate(this.whatsappTemplates[this.selectedIndex]) 
      }
    }
  }

  async tempClick(i){
    await this.getMessageFromTemplate(this.whatsappTemplates[i]) 
  }
  
  
}