import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit ,} from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { CommonUtils } from 'app/services/CommonUtils.service';
import { DashboardUtilsService } from 'app/services/dashboard-utils.service';

@Component({
  selector: 'tracker-cell',
  templateUrl: './tracker-cell.component.html',
  styleUrls: ['./tracker-cell.component.scss']
})
export class TrackerCellComponent implements OnInit  {
  params:any={};
  editable: boolean = false
  itemInfo:any={}
  type: string;
  activeTrackerList:any[]=[]
  value:any

  constructor(private cdr:ChangeDetectorRef,private commonUtils: CommonUtils,private dashboardUtils:DashboardUtilsService){
  }

  async ngOnInit() {
    console.log("params in tracker list",this.itemInfo,this.params)
    this.onUpdate(this.params)
  }


  onUpdate(params:any){
    this.editable=this.params?.editable
    this.itemInfo = this.params?.item || {}
    this.value=this.itemInfo['custom_fields'] ? this.itemInfo['custom_fields'][this.params.field]?.time_spend_till_now || 0 : ''
    this.activeTrackerList = this.params?.globalParams?.activeTrackerList || []
    this.cdr.detectChanges()
  }

}
