import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit ,ViewChild, ViewContainerRef} from '@angular/core';
import { CommonUtils } from 'app/services/CommonUtils.service';
import { DashboardUtilsService } from 'app/services/dashboard-utils.service';
import { HttpTransferService } from 'app/services/httpTransfer.service';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'board-cell',
  templateUrl: './board-cell.component.html',
  styleUrls: ['./board-cell.component.scss']
})
export class BoardCellComponent implements OnInit  {
  params:any={};
  editable: boolean = false
  itemInfo:any={}
  selectedValue: any=[];
  mirrorColumnsJson: any={}

  //popover
  searchText: string;
  searchOpen: boolean = false;
  isBoardSearchOpen: boolean = false;
  connectedLeadIds: any[] = []
  isConnectedBoardReadOnly: boolean = false;
  dashboardName: string
  dashboardId: string = null;
  showTextarea: boolean = false;
  createLeadText:any=''
  dashboardInfo:any= {};
  isConnectedBoardReadonly: boolean;
  countToShow: number=1;

  constructor(private cdr:ChangeDetectorRef,private commonUtils: CommonUtils,private dashboardUtils:DashboardUtilsService,private httpTransfer: HttpTransferService,private modal:NgbModal,){
  }

  async ngOnInit() {
    console.log(this.params)
    this.onUpdate(this.params);
  }


  onUpdate(params: any){
    this.itemInfo=params?.item || {}
    this.connectedLeadIds=this.params?.value || []
    this.mirrorColumnsJson = this.params?.globalParams?.mirrorColumnsJson || {}
    this.setConnectedBoardAndLeads()
    this.onResize(this.params?.columnData)
    this.cdr.detectChanges()
  }

  async setConnectedBoardAndLeads(){       
    this.dashboardId = this.params.columnParams.customField?.connected_board_id
    if(this.dashboardId) {
      let dashboardInfo = await this.dashboardUtils.getDashboardInformation([this.dashboardId])
      this.dashboardInfo=dashboardInfo[this.dashboardId]?.DASHBOARD_INFO || {}
      this.dashboardName = this.dashboardInfo?.dashboard_name;
      this.isConnectedBoardReadonly=await this.commonUtils.isDashboardReadOnly(this.dashboardId)
    } 
    this.cdr.detectChanges()
  }
 

  setItemToBoard(lead) {
    if(!this.connectedLeadIds.includes(lead._id))this.connectedLeadIds.push(lead._id)
    this.params.value =[...this.connectedLeadIds];
    this.params?.gridTableRef?.changedCellValue({value:this.params.value,customFieldType:true,params:this.params});
    this.onUpdate(this.params)
  }

  removeItemFromBoard(lead){
    this.connectedLeadIds=this.connectedLeadIds.filter(id=>id!=lead._id)
    this.params.value =this.connectedLeadIds?.length ? [...this.connectedLeadIds] : null 
    this.params?.gridTableRef?.changedCellValue({value:this.params.value,customFieldType:true,params:this.params});
    this.onUpdate(this.params)
  }



  openItem(item,isConnected=true,connectedInfo=null){
    this.params?.globalParams?.gridViewRef?.openRightItemModal(item,isConnected,connectedInfo)
  }



  onResize(col?){
    let width = col?.width ? col?.width : 300
    this.countToShow=Math.floor((width-30)/130);
    if(this.countToShow<=0)this.countToShow=1
    this.cdr.detectChanges()
  }

  updateTableItem(type,connectedItemIds,leadData,insertIdIndex?){
    if(!connectedItemIds)connectedItemIds=[]
    if(type=='Remove'){
      let index =connectedItemIds.findIndex(el => el === leadData._id);
      if(index >=0)connectedItemIds.splice(index,1);
    }else if(type=='Update')
      connectedItemIds=leadData
    else if(type=='Add'){
      if(insertIdIndex>-1 && insertIdIndex<=connectedItemIds?.length){
        connectedItemIds.splice(insertIdIndex,0,leadData._id)
      } 
      else if(!connectedItemIds?.includes(leadData._id)){
        connectedItemIds.push(leadData._id);
      }
    }
    if(!connectedItemIds?.length)connectedItemIds=null
    this.params.value=connectedItemIds
    this.params?.gridTableRef?.changedCellValue({value:this.params.value,customFieldType:true,params:this.params});
  }

   // open lead item modal 
   openRightItemModal(item,isConnedtedItem?,connectedBoradInfo?){
        
  }

}
