import { Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import { ConstantService } from '../../../../Enums/Constant.service';
import { ChartIcons } from 'Enums/chart-icons';
import { viewsIcons } from 'Enums/attributes-icons';

@Component({
  selector: 'app-add-widget-popup',
  templateUrl: './add-widget-popup.html',
  styleUrls: ['./add-widget-popup.scss']
})
export class AddWidgetPopupComponent implements OnInit {
  @Output() openGraphEmit=new EventEmitter<any>();
  @Input() appsDetails:any;
  @Output() selectedAppInstance=new EventEmitter<any>();
  chartIcons = ChartIcons;
  viewsIcons=viewsIcons;
 

  constructor(public constantService: ConstantService) {

   }

  async ngOnInit() {
   
  }

  openGraphSettings(setting) {
    this.openGraphEmit.emit(setting)
  }

}
