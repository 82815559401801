import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CustomStorageService } from "../app/services/custom-storage.service";

@Injectable({
  providedIn: "root",
})
export class ConstantService {
  constructor(private customStorageService: CustomStorageService,) {}
  public static itemDefaultKeys =["id", "title", "priority", "bucket", "start_end_date", "assigned_to", "tags","create_date","dashboard","update_date","created_by","item_id","summary","last_updated"];
  public searchPlaceholdertext="Search by ID (eg #10) or min. 2 char."
  public priority_colors={
    '0': "#d8d8d8",
    '1': "#53b6ee",
    '2': "#ffaa29",
    '3': "#f50100"
    // '0': "#999999 ",
    // '1': "#efb201",
    // '2': "#ef6129",
    // '3': "#ec423f"
  }
  public static constant = {
    SORT_BY_INDEX : "index",
    SORT_BY_DASHBOARD: "dashboard",
    SORT_BY_create_date: "create_date",
    SORT_BY_LAST_UPDATED: "last_updated",
    SORT_BY_PRIORITY: "priority",
    SORT_BY_USER: "user",
    SORT_BY_RELEVANCE: "",
    SORT_BY_BUCKET : "bucket",
    SORT_BY_CREATEDBY:"created_by",


    DSC: "DSC",
    ASC: "ASC",
    AGINGTIME: "aging_time",
    ENDDATE: "end_date",
    STARTDATE: "start_date",
    CALENDAR: "calendar",
    TIMER: "timer",
    BUCKET: "bucket_id",
    PARENTLEAD:"parent_id",
    BUCKETCONSTANT: "Bucket",
    PRIORITYCONSTANT: "Priority",
    DASHBOARDSORTING: "dashboard",
    DASHBOARDUSER: "DASHBOARD_USER",
    TAGCONSTANT: "Tags",
    TAGCONST:"Tag",
    CREATEDTIME: "create_date",
    LASTUPDATED: "last_updated",
    PRIORITY: "priority",
    RELEVANCE: "relevance",
    USER: "assigned_to",
    IMAGE: "IMAGE",
    VIDEO: "VIDEO",
    FILE: "FILE",
    TIME: "TIME",
    DOCUMENT: "DOCUMENT",
    COMMENTS: "COMMENTS",
    LEAD: "LEAD",
    JPG: "jpg",
    MP4: "mp4",
    PNG: "png",
    SVG: "svg",
    JPEG: "jpeg",
    MPEG: "MPEG",
    MPG: "mpg",
    NOPHASE: "No Phase",
    CUSTOM_FORM: "CUSTOM_FORM",
    AUDIO:"AUDIO",
    SPRINT:"Sprint",
    PRIORITYARRAY: [
      {
        id: 3,
        value: "URGENT",
        checked: false,
        image: "./../assets/img/icons/urgent.png",
        color: '#f50100'
      },
      {
        id: 2,
        value: "HIGH",
        checked: false,
        image: "./../assets/img/icons/high.png",
        color: '#ffaa29'
      },
      {
        id: 1,
        value: "MEDIUM",
        checked: false,
        image: "./../assets/img/icons/medium.png",
        color: '#53b6ee'
      },
      {
        id: 0,
        value: "LOW",
        checked: false,
        image: "./../assets/img/icons/low.png",
        color: '#d8d8d8'
      },
    ],

    fileTypeConstArr: ['IMAGE', 'MULTIIMAGE', 'VIDEO', 'MULTIVIDEO', 'FILE', 'MULTIPLEFILE','AUDIO','MULTIAUDIO'],

    NA: "N/A",
    DATE: "Date",
    TEXT: "Text",
    EMAIL: "Email",
    RADIO: "Radiobutton",
    IMAGEFIELD: "Image",
    TEXTAREA: "Textarea",
    MOBILE: "Mobile",
    CHECKBOX: "Checkbox",
    DROPDOWN: "Dropdown",
    NUMBER: "Numeric",
    LOGO: "logo",
    SPRINTDATA: "sprintData",
    LOCATION: 'Location',
    IPADDRESS: "IPAddress",   //value confirm with back end
    TIMELINETYPE: {},
    DEFAULTCCOLUMNOPTIONS: [
      { id: 0, itemName: "ID", isHide: false, isCustom: false, columnKey:'id',viewColumn:['GRID','LIST','AllDashboard'],viewOptions:['GRID']},
      { id: 1, itemName: "Summary", isHide: false, isCustom: false , columnKey:'summary',viewColumn:['GRID','LIST','AllDashboard'],viewOptions:['GRID']},
      // { id: 2, itemName: "Comments", isHide: false, isCustom: false , columnKey:'comments',viewColumn:['GRID'],viewOptions:['GRID']},
      { id: 2, itemName: "Dashboard", isHide: false, isCustom: false ,columnKey:'dashboard',viewColumn:['AllDashboard'],viewOptions:['AllDashboard'] },
      { id: 3, itemName: "Priority", isHide: false, isCustom: false, columnKey:'priority',viewColumn:['GRID'],viewOptions:['GRID'], groupBy:'priority' },
      { id: 4, itemName: "Bucket", isHide: false, isCustom: false, columnKey:'bucket',key:'bucket_id',viewColumn:['GRID','LIST','AllDashboard','KANBAN'],viewOptions:['GRID','LIST','AllDashboard','KANBAN'], groupBy:'bucket' ,type:'Bucket'},
      { id: 5, itemName: "Start Date - End Date", isHide: false, isCustom: false ,columnKey:'start_end_date',viewColumn:['GRID','LIST','AllDashboard'],viewOptions:['GRID','LIST','AllDashboard'] },
      { id: 6, itemName: "Assigned", isHide: false, isCustom: false , columnKey:'assigned_to',viewColumn:['GRID','LIST','AllDashboard'],viewOptions:['GRID','LIST','AllDashboard'], groupBy:'assigned' },
      { id: 7, itemName: "Tags", isHide: false, isCustom: false, columnKey:'tags',key:'tag_id',viewColumn:['GRID'],viewOptions:['GRID'], groupBy:'tags' },
      { id: 8, itemName: "Last Updated", isHide: false, isCustom: false, columnKey:'last_updated',viewColumn:['GRID','LIST','AllDashboard'],viewOptions:['GRID','LIST','AllDashboard']},
      { id: 9, itemName: "Created By", isHide: false, isCustom: false, columnKey:'created_by',viewColumn:['GRID','LIST','AllDashboard'],viewOptions:['GRID','LIST','AllDashboard']},
      { id: 10, itemName: "Create Date", isHide: false, isCustom: false, columnKey:'create_date',viewColumn:['GRID','LIST','AllDashboard'],viewOptions:['GRID','LIST','AllDashboard']},
      { id: 11, itemName: "Phase", isHide: false, isCustom: false, columnKey:'sprint',key:'sprint_id',viewColumn:['GRID','LIST','AllDashboard'],viewOptions:['GRID','LIST','AllDashboard'], groupBy:'phase'},
    ],
   
    DEFAULT_COLUMN_OPTIONS_GRID: [
      { id: 0, itemName: "ID", isHide: false, isCustom: false, columnKey:'id',viewColumn:['GRID','LIST','AllDashboard','GANTT'],viewOptions:['GRID','GANTT']},
      { id: 1, itemName: "Summary", isHide: false, isCustom: false , columnKey:'title',viewColumn:['GRID','LIST','AllDashboard','GANTT'],viewOptions:['GRID','GANTT']},
      // { id: 2, itemName: "Comments", isHide: false, isCustom: false , columnKey:'comments',viewColumn:['GRID'],viewOptions:['GRID']},
      { id: 2, itemName: "Dashboard", isHide: false, isCustom: false ,columnKey:'dashboard_id',viewColumn:['AllDashboard'],viewOptions:['AllDashboard'] },
      { id: 3, itemName: "Priority", isHide: false, isCustom: false, columnKey:'priority',viewColumn:['GRID','GANTT'],viewOptions:['GRID','GANTT'], groupBy:'priority' },
      { id: 4, itemName: "Bucket", isHide: false, isCustom: false, columnKey:'bucket_id',viewColumn:['GRID','LIST','AllDashboard','KANBAN','GANTT'],viewOptions:['GRID','LIST','AllDashboard','GANTT'], groupBy:'bucket' ,type:'Bucket'},
      { id: 5, itemName: "Start Date - End Date", isHide: false, isCustom: false ,columnKey:'start_end_date',viewColumn:['GRID','LIST','AllDashboard','GANTT'],viewOptions:['GRID','LIST','AllDashboard','GANTT'] },
      { id: 6, itemName: "Assigned", isHide: false, isCustom: false , columnKey:'assigned_to',viewColumn:['GRID','LIST','AllDashboard','GANTT'],viewOptions:['GRID','LIST','AllDashboard','GANTT'], groupBy:'assigned' },
      { id: 7, itemName: "Tags", isHide: false, isCustom: false, columnKey:'tag_id',viewColumn:['GRID','GANTT'],viewOptions:['GRID','GANTT'], groupBy:'tags' },
      { id: 8, itemName: "Last Updated", isHide: false, isCustom: false, columnKey:'last_updated',viewColumn:['GRID','LIST','AllDashboard','GANTT'],viewOptions:['GRID','LIST','AllDashboard','GANTT']},
      { id: 9, itemName: "Created By", isHide: false, isCustom: false, columnKey:'created_by',viewColumn:['GRID','LIST','AllDashboard','GANTT'],viewOptions:['GRID','LIST','AllDashboard','GANTT']},
      { id: 10, itemName: "Create Date", isHide: false, isCustom: false, columnKey:'create_date',viewColumn:['GRID','LIST','AllDashboard','GANTT'],viewOptions:['GRID','LIST','AllDashboard','GANTT']},
      { id: 11, itemName: "Phase", isHide: false, isCustom: false, columnKey:'sprint_id',viewColumn:['GRID','LIST','AllDashboard','GANTT'],viewOptions:['GRID','LIST','AllDashboard','GANTT'], groupBy:'phase'},
   ],
   
    DEFAULT_GROUP_BY_OPTIONS: [
      { itemName: "Priority", isHide: true, isCustom: false, columnKey:'priority', groupByKey:'priority'},
      { itemName: "Bucket", isHide: true, isCustom: false, columnKey:'bucket_id', groupByKey:'bucket',diabledOrder:true},
      { itemName: "Assigned", isHide: true, isCustom: false , columnKey:'assigned_to', groupByKey:'user'},
      { itemName: "Created by", isHide: true, isCustom: false , columnKey:'created_by', groupByKey:'created_by'},
      // { itemName: "Tags", isHide: true, isCustom: false, columnKey:'tags',viewColumn:['GRID'],viewOptions:['GRID'], groupBy:'tag' },
      { itemName: "Phase", isHide: true, isCustom: false, columnKey:'sprint_id', groupByKey:'phase',diabledOrder:true},
    ],
    GROUP_KEY_JSON: { 
      priority: { group_by:'priority', key :"priority",filter_key :"priority", default_value:null}, 
      phase: {group_by:'phase',key : "sprint_id",filter_key :"sprint_id",default_value:'No Phase'}, 
      user:{group_by:'user',key :"assigned_to",filter_key :"assigned_to",default_value:'Without Assignee'}, 
      bucket:{group_by:'bucket',key : "bucket_id",filter_key :"bucket_id",default_value:null}, 
      created_by:{group_by:'created_by',key : "created_by",filter_key :"created_by",default_value:null},
      dashboard_id:{group_by:'dashboard_id',key : "dashboard_id",filter_key :"dashboard_id",default_value:null}, 
      custom_field:{group_by:'custom_field',key : null,filter_key :null,default_value:'Other Leads'},
      none:{group_by:'none',key : null,filter_key :null,default_value:'Default Group'}  
    },
    DEFAULT_FORMULAS_OPTIONS:[{key:"priority",display_key:"Priority",type:"Priority"} ,{key:"assigned_to",display_key:'Assignee',type:'Assignee'},{key:"bucket_id",display_key:'Bucket',type:'Bucket'},{key:"sprint_id",display_key:'Phase',type:'Sprints'},{key:"start_date",display_key:'Start Date',type:'Date'},{key:"end_date",display_key:'End Date',type:'Date'},{key:"tag_id",display_key:'Tags',type:'Tags'}],
    sortByOptions : {
      priority : {display_key:"Priority",sortByKey:"priority",hidden:false,lead_key:"priority"},
      assigned_to:{display_key:"User",sortByKey:"assigned_to",hidden:false,lead_key:"assigned_to"},
      title:{display_key:"Title",sortByKey:"title",hidden:false,lead_key:"title"},
      bucket_id:{display_key:"Bucket",sortByKey:"bucket_id",hidden:false,lead_key:"bucket_name"},
      create_date:{display_key:"Create time",sortByKey:"create_date",hidden:false,lead_key:"create_date"},
      created_by:{display_key:"Created by",sortByKey:"created_by",hidden:false,lead_key:"created_by"},
      last_updated:{display_key:"Last update",sortByKey:"last_updated",hidden:false,lead_key:"update_date"},
      start_date:{display_key: 'Start date',sortByKey: 'start_date',hidden:false,lead_key:'start_date'},
      end_date:{display_key: 'End date', sortByKey: 'end_date',hidden:false,lead_key:'end_date'},
      // aging_time:{display_key: 'Aging', sortByKey: 'aging_time',hidden:false,lead_key:'aging_time'},
      index:{display_key: 'None', sortByKey: 'index',hidden:false,lead_key:'index'},
      relevance:{display_key: 'Relevance', sortByKey: 'relevance',hidden:true,lead_key:'relevance'},
    //  lead_id:{display_key:"Lead",sortByKey:"lead_id",hidden:true,lead_key:"lead_id"},
      comments:{display_key:"Comments",sortByKey:"comments",hidden:false,lead_key:"comment_details"},
    },
    viewList:[{type:'GRID',displayKey:'Grid',icon:'Grid'},{type:'GANTT',displayKey:'Gantt',icon:'Gantt'},{type:'KANBAN',displayKey:'Kanban',icon:'kanban'},{type:'SPLIT',displayKey:'Split',icon:'Split'},{type:'CALENDAR',displayKey:"Calendar",icon:'Calendar'}],
    allowSortByCustomType:['Radiobutton', 'Dropdown','Percentage', 'Numeric', 'Rating','Date','Text','Email', 'Url','Country'],
    CUSTOMTYPES: {
      DATE: "Date",
      TEXT: "Text",
      EMAIL: "Email",
      RADIO: "Radiobutton",
      IMAGEFIELD: "SingleImage",
      MULTIIMAGE: "MultipleImage",
      VIDEO: "SingleVideo",
      MULTIVIDEO: "MultipleVideo",
      USER: "SingleUser",
      FILEUPLOAD: "SingleFile",
      MULTIFILEUPLOAD: "MultipleFile",
      LOCATION: "Location",
      TEXTAREA: "Textarea",
      MOBILE: "Mobile",
      CHECKBOX: "Checkbox",
      DROPDOWN: "Dropdown",
      NUMBER: "Numeric",
      IPADDRESS: "IPAddress",
      MULTIPEOPLE: "MultiPeople",
      SINGLEUSER: "SingleUser",
      MULTIUSER: "MultiUser",
      MAP: "Map",
      CURRENCY: "Currency",
      URL: "Url",
      MULTI_URL:'MultiUrl',
      MULTISELECT: "MultiSelect",
      PERCENTAGE: "Percentage",
      BOARD: "Board",
      MIRROR: "Mirror",
      RATING: "Rating",
      CHECKLIST: "CheckList",
      BUTTON: "Button",
      AUDIO:"SingleAudio",
      MULTIAUDIO:"MultipleAudio",
      COUNTRY:"Country",
      TIME_TRACKER:"TimeTracker",
      MULTIPLEBOARD:"MultipleBoard",
      MULTIPLEEMAIL:"MultipleEmail",
      MULTIPLEMOBILE:"MultipleMobile"
    },
    COLUMNS_NOT_ALLOWED: [
      'Board',
      'Mirror',
      'Checkbox',
      'SingleAudio',
      'MultipleAudio',
      'SingleImage',
      'MultipleImage',
      'SingleUser',
      'MultiUser',
      'MultiPeople',
      'SingleFile',
      'MultipleFile',
      'SingleVideo',
      'MultipleVideo',
      'MultiSelect',
      'CheckList',
      'Button'
    ],
    SETTINGACTION:{
     PROCESS :"Process",
     ITEMATTRIBUTE:"ItemAttribute",
     PHASE:"Phase",
     FORM:"Form",
     MEMBERS:"Members",
     TAG:"Tag",
     ITEMTEMPLATE:"ItemTemplate",
     IMPORT:"Import",
     EMAIL:"Email",
     WHATSAPP:"Whatsapp",
     AUTOMATION:"automation",
     ITEMTERMINOLOGY : "ItemTerminology",
     CHANNELS:"Channels",
     CHATBOT:'Chat bot',
     ACTIVITYTYPE:'Activity Type',
     AVAILABILITY:'Availabilty',
     APPS:'Apps',
     VARIABLES:'Variables',
     QUICK_FILTER:"Quick Filter",
     KNOWLEDGEBASE:"KNOWLEDGEBASE",
     LIST:"Lists"
    },
    THEMEOPTIONS:[
      {color:['#3269CC'],themeClass:"theme-royalBlue",name:"Royal Blue"},
      {color:['#544EA0'],themeClass:"theme-butterflyBush",name:"Butterfly Bush"},
      {color:['#076465'],themeClass:"theme-tealGreen",name:"Teal Green"},
      {color:['#93395F'],themeClass:"theme-twilightLavender",name:"Twilight Lavender"},
      {color:['#A85519'],themeClass:"theme-richGold",name:"Rich Gold"},
      {color:['#1D5E86'],themeClass:"theme-duskBlue",name:"Dusk Blue"},
      {color:['#784094'],themeClass:"theme-warmPurple",name:"Warm Purple"},
      {color:['#67554D'],themeClass:"theme-wengeBrown",name:"Wenge Brown"},
      {color:['#595D66'],themeClass:"theme-midGrey",name:"Mid Grey"},
       {color:['#1B2E82','#26C5C9'],themeClass:"theme-underTheLake",name:"Under The Lake"},
      {color:['#7B79F1','#1590E1'],themeClass:"theme-solidVault",name:"Solid Vault"},
      {color:['#443F4D','#E0E2B7'],themeClass:"theme-sandtoBlue",name:"Sand To Blue"},  
      {color:['#A7076B','#640560'],themeClass:"theme-expresso",name:"Expresso"} 
    ],
    FAICONS: [
      { class: "fa-solid fa-briefcase" },
      { class: "fa-solid fa-scale-balanced" },
      { class: "fa-solid fa-address-card" },
      { class: "fa-solid fa-address-book" },
      { class: "fa-solid fa-square-phone" },
      { class: "fa-solid fa-phone-volume" },
      { class: "fa-solid fa-landmark" },
      { class: "fa-solid fa-landmark" },
      { class: "fa-solid fa-building" },
      { class: "fa-solid fa-city" },
      { class: "fa-solid fa-school" },
      { class: "fa-solid fa-image" },
      { class: "fa-solid fa-cloud" },
      { class: "fa-solid fa-heart" },
      { class: "fa-solid fa-music" },
      { class: "fa-solid fa-file" },
      { class: "fa-solid fa-trash" },
      { class: "fa-solid fa-gear" },
      { class: "fa-solid fa-filter" },
      { class: "fa-solid fa-clipboard" },
      { class: "fa-solid fa-gift" },
      { class: "fa-solid fa-eye" },
      { class: "fa-solid fa-fire" },
      { class: "fa-solid fa-folder-open" },
      { class: "fa-solid fa-square-poll-vertical" },
      { class: "fa-solid fa-palette" },
      { class: "fa-solid fa-cookie" },
      { class: "fa-solid fa-cube" },
      { class: "fa-solid fa-droplet" },
      { class: "fa-solid fa-cubes" },
      { class: "fa-solid fa-rug" },
      { class: "fa-solid fa-tooth" },
      { class: "fa-solid fa-gun" },
      { class: "fa-solid fa-cart-shopping" },
      { class: "fa-solid fa-database" },
      { class: "fa-solid fa-server" },
      { class: "fa-solid fa-video" },
      { class: "fa-solid fa-camera" },
      { class: "fa-solid fa-message" },
      { class: "fa-solid fa-photo-film" },
      { class: "fa-solid fa-desktop" },
      { class: "fa-solid fa-floppy-disk" },
      { class: "fa-solid fa-compact-disc" },
      { class: "fa-solid fa-headset" },
      { class: "fa-solid fa-calendar-days" },
      { class: "fa-solid fa-bug" },
      { class: "fa-solid fa-virus" },
      { class: "fa-solid fa-spider" },
      { class: "fa-solid fa-shield-virus" },
      { class: "fa-solid fa-user-shield" },
      { class: "fa-solid fa-file-shield" },
      { class: "fa-solid fa-building-shield" },
      { class: "fa-solid fa-person-running" },
      { class: "fa-solid fa-microphone-lines" },
      { class: "fa-solid fa-calendar-check" },
      { class: "fa-solid fa-bowling-ball" },
      { class: "fa-solid fa-futbol" },
      { class: "fa-solid fa-volleyball" },
      { class: "fa-solid fa-baseball" },
      { class: "fa-solid fa-basketball" },
      { class: "fa-solid fa-football" },
      { class: "fa-solid fa-table-tennis-paddle-ball" },
      { class: "fa-solid fa-ticket" },
      { class: "fa-solid fa-volume-high" },
      { class: "fa-solid fa-gauge" },
      { class: "fa-solid fa-mountain-sun" },
      { class: "fa-solid fa-bullhorn" },
      { class: "fa-solid fa-user-group" },
      { class: "fa-solid fa-id-card-clip" },
      { class: "fa-solid fa-clipboard-user" },
      { class: "fa-solid fa-clipboard-list" },
      { class: "fa-solid fa-truck-medical" },
      { class: "fa-solid fa-file-medical" },
      { class: "fa-solid fa-house-medical" },
      { class: "fa-solid fa-briefcase-medical" },
      { class: "fa-solid fa-user-doctor" },
      { class: "fa-solid fa-hospital" },
      { class: "fa-solid fa-user-nurse" },
      { class: "fa-solid fa-flask" },
      { class: "fa-solid fa-brain" },
      { class: "fa-solid fa-tablets" },
      { class: "fa-solid fa-weight-scale" },
      { class: "fa-solid fa-user-tie" },
      { class: "fa-solid fa-clipboard-question" },
      { class: "fa-solid fa-chart-pie" },
      { class: "fa-solid fa-chart-simple" },
      { class: "fa-solid fa-ranking-star" },
      { class: "fa-solid fa-file-invoice-dollar" },
      { class: "fa-solid fa-file-invoice" },
      { class: "fa-solid fa-wallet" },
      { class: "fa-solid fa-money-check-dollar" },
      { class: "fa-solid fa-piggy-bank" },
      { class: "fa-solid fa-vault" },
      { class: "fa-solid fa-sack-dollar" },
      { class: "fa-solid fa-money-bill" },
      { class: "fa-solid fa-hand-holding-medical" },
      { class: "fa-solid fa-handshake-angle" },
      { class: "fa-solid fa-warehouse" },
      { class: "fa-solid fa-store" },
      { class: "fa-solid fa-file-contract" },
      { class: "fa-solid fa-hotel" },
      { class: "fa-solid fa-file-signature" },
      { class: "fa-solid fa-key" },
      { class: "fa-solid fa-compass" },
      { class: "fa-solid fa-location-dot" },
      { class: "fa-solid fa-earth-americas" },
      { class: "fa-solid fa-map-location-dot" },
      { class: "fa-solid fa-brush" },
      { class: "fa-solid fa-hammer" },
      { class: "fa-solid fa-wrench" },
      { class: "fa-solid fa-toolbox" },
      { class: "fa-solid fa-paint-roller" },
      { class: "fa-solid fa-shirt" },
      { class: "fa-solid fa-graduation-cap" },
      { class: "fa-solid fa-car" },
      { class: "fa-solid fa-car-side" },
      { class: "fa-solid fa-van-shuttle" },
      { class: "fa-solid fa-train-subway" },
      { class: "fa-solid fa-taxi" },
      { class: "fa-solid fa-bus" },
      { class: "fa-solid fa-truck" },
      { class: "fa-solid fa-ship" },
      { class: "fa-solid fa-sailboat" },
      { class: "fa-solid fa-plane" },
      { class: "fa-solid fa-tractor" },
      { class: "fa-solid fa-helicopter" },
      { class: "fa-solid fa-motorcycle" },
      { class: "fa-solid fa-shield-halved" },
      { class: "fa-solid fa-leaf" },
      { class: "fa-solid fa-tree" },
      { class: "fa-solid fa-gem" },
      { class: "fa-solid fa-folder" }
    ],
    CUSTOMCOLORBYID:[
      "#fac6c6",
      "#fad6c6",
      "#fae5c6",
      "#faf4c6",
      "#effac6",
      "#e0fac6",
      "#d0fac6",
      "#c6facb",
      "#c6fadb",
      "#c6faea",
      "#c6fafa",
      "#c6eafa",
      "#c6dbfa",
      "#c6cbfa",
      "#d0c6fa",
      "#e0c6fa",
      "#efc6fa",
      "#fac6f4",
      "#fac6e5",
      "#fac6d6"
    ],
    KEY_CODE: {
      RIGHT_ARROW: "ArrowRight",
      LEFT_ARROW: "ArrowLeft",
    },
    INPUTTYPE: {
      NUM: "num",
      STR: "str",
    },
    SLIDERTYPE: {
      NEXT: "next",
      PREVIOUS: "previous",
    },
    UPDATECUSTOMTYPE: {
      Lead: "LEAD",
      Comments: "COMMENTS",
      User: "USER",
    },
    UPDATELEADTYPE: {
      TITLE: "title",
      TAGS: "tags",
      SPRINT: "sprint_id",
      ASSIGNED: "assigned_to",
      PRIORITY: "priority",
    },
    CLICKTYPE: {
      FORALLDASHBOARD: "forAllDashboard",
      FORDASHSELECT: "forDashSelect",
    },
    COMMENTS_TYPE: {
      IMAGE: "image",
      VIDEO: "video",
      APPLICATION: "application",
    },
    Attachments_TYPE: {
      ALL: "ALL",
      DOCUMENTS: "DOCUMENTS",
      IMAGES: "IMAGES",
      VIDEO: "VIDEO",
      AUDIO:"AUDIO",
    },
    PHASESTATUSTYPE: {
      STARTED: "STARTED",
      NOT_STARTED: "NOT_STARTED",
      STOPPED: "STOPPED",
    },
    ROLE: {
      ADMIN: "ADMIN", // manage users,change org settings (name)
      SALES: "SALES", // end users 
      ACCESS: "ACCESS", //part of project/dashboard
      SUPER_ADMIN: "SUPER-ADMIN", // create org
      DASHBOARDADMIN: "DASHBOARD_ADMIN", //part of project/dashboard
      READONLY: "READ_ONLY",
      RESTRICTED_ACCESS:"RESTRICTED_ACCESS",
      FOLDER_VIEW_ACCESS:"FOLDER_VIEW_ACCESS",
    },
    SPRINT_STATUS: {
      STARED: "STARTED",
      NOT_STARTED: "NOT_STARTED",
      STOPPED: "STOPPED",
    },
    CHECKLIST_STATE: {
      NOT_COMPLETED: "NOT_COMPLETED",
      COMPLETED: "COMPLETED",
    },
    REGEX: {
      regexForFindingAlphaNumeric: /(?=.*\d)(?=.*[a-zA-Z])/,
    },
    MESSAGE: {
      msg: "Copy Created",
      msgForCorrectFileType: "Please Select Correct File Type!",
      msgForFileLength: "Please Select Only One File !",
    },
    ID: "ID",
    SUMMARY: "Summary",
    BUCKETNAME: "Bucket Name",
    DUEDATE: "Due Date",
    DATEHEADER: "Start Date - End Date",
    ASSIGNED: "Assigned",
    DASHBOARD: "Dashboard",
    END: "End",
    LASTUPDATEDTABLE: "Last Updated",
    LEADUPDATE: "LEADUPDATE",
    PHASE: "Phase",
    CREATEDBY: "Created By",
    CREATEDATE: "Create Date",
    DRAGLEAD: "DRAGLEAD",
    DELETELEAD: "DELETELEAD",
    CREATELEAD: "CREATELEAD",
    REFRESHLEAD: "REFRESHLEAD",
    TABLEUPDATE: "table",
    UNIQUELEADIDCONSTANT: "lead",
    TABLELEADDROPDOWN: "tableLead",
    INDIVIDUALLEADDROPDOWN: "individualLead",
    ADD: "Add",
    Remove: "Remove",
    READ: "read",
    CLICKFROMDASH: "ClickedFromDash",
    CLICKFROMDEFAULT: "ClickedFromDefault",
    BOARDLIST: "BOARD_LIST",

    CLICKUSERCONSTANT: "ClickUserConstant",
    COMPONENTTYPE: {
      BASICINFO: "Basic-Info",
      COMMENTS: "Comments",
      TIMELINE: "Timeline",
      ATTACHMENTS: "Attachments",
    },
    TABLELEADSTART: "leadStart",
    TABLELEADEND: "leadEnd",
    UPDATECOUNTCONSTAT: {
      CHECKLISTCOUNT: "checkListCount",
      COLLOBORATORSCOUNT: "colloboratorsCount",
      DEPENDENCIESCOUNT: "dependenciesCount",
    },
    DASHBOARDNAVIGATIONURL: "dashboard/",
    SUMMERNOTECONFIG: {
      placeholder: "",
      tabsize: 2,
      height: "200px",
      uploadImagePath: "/api/upload",
      toolbar: [
        ["style", ["bold", "italic", "underline", "clear"]],
        ["font", ["strikethrough", "superscript", "subscript"]],
        ["fontsize", ["fontsize"]],
        ["color", ["color"]],
        ["para", ["ul", "ol", "paragraph"]],
        ["height", ["height"]],
      ],
      fontNames: [
        "Helvetica",
        "Arial",
        "Arial Black",
        "Comic Sans MS",
        "Courier New",
        "Roboto",
        "Times",
      ],
    },    
    AUTHMSG: "auth",
    REGISTER: "register",
    ACTIVITYTYPEKEY: "activity_type",
    ADDITIONALATTRIBUTESKEY: "additional_attributes",
    DETAILSKEY: "details",
    OBJECTTYPEKEY: "object_type",
    OBJECTTYPEDETAILSKEY: "object_type_details",
    ADDACTIVITYTYPE: "ADD",
    UPDATEACTIVITYTYPE: "UPDATE",
    LEADOBJECTTYPE: "LEAD",
    COMMENTOBJECTTYPE:"COMMENT",
    TIMELINEOBJECTTYPE: "TIMELINE",
    UPDATEDTIMEKEY: "update_date",
    STRINGVALUETYPE: "STRING",
    NUMBERKEYTYPE: "NUMBER",
    ASSIGNTONAMEKEY: "assigned_to",
    BUCKETNAMEKEY:"bucket_name",
    TITLE_SEARCH: "title",
    INDEX: "index",
    TimelineOpenFrom:{
      FROMDASHBOARD : "fromdashboard",
      FROM_LEAD_MODAL:"fromLeadModal",
    },
    LEADVIEW:{
      TABLEVIEW : "tableview",
      BOARDVIEW : "boardView",
      GANTTVIEW : "ganttView",
      HIDE:"hide",
      SHOW:"show"
    },
    TIMELINEFILTER:[
      {NAME:"Title",
      clicked_status : false,
      key: "title"
    },
      {NAME:"Assigned To",
      clicked_status : false,
      key: "assigned_to",
    },
      {NAME:"Start Date",
      clicked_status : false,
      key: "start_date"
    },
      {NAME:"End Date",
      clicked_status : false,
    key: "end_date"
    },
      {NAME:"Tags",
      clicked_status : false,
      key: "tag_id"
    },
      {NAME:"Priority",
      clicked_status : false,
    key: "priority"
    }
    ],

    ARCHIVE_DAS : 'archiveDas',

    havePermissions: [
      {
        role: "DASHBOARD_ADMIN",
        permissions: ["RENAME_DASHBOARD", "ARCHIVE_DASHBOARD", "EDIT_DASHBOARD", "DELETE_DASHBOARD","MARK_FAVOURITE_DASHBOARD", "REMOVE_FAVOURITE_DASHBOARD", 
        "REMOVE_ARCHIVE_DASHBOARD", "ADD_PHASE", "START_PHASE", "STOP_PHASE", "RENAME_PHASE", "EDIT_PHASE_DATES", "DELETE_PHASE", "ARCHIVE_PHASE", 
        "REMOVE_ARCHIVE_PHASE", "SHOW_PHASE", "HIDE_PHASE"]
      },
      {
        role: "TEMPLATE_ADMIN",
        permissions: ["RENAME_DASHBOARD","EDIT_DASHBOARD", "DELETE_DASHBOARD"]
      },
      {
        role: "ACCESS",
        permissions: ["MARK_FAVOURITE_DASHBOARD", "REMOVE_FAVOURITE_DASHBOARD"]
      },
      {
        role: "READ_ONLY",
        permissions: []
      }
    ],
    itemViewType:{
      board_view:'KANBAN',
      list_view:'LIST',
      gant_view:'GANTT',
      activity_view:'ACTIVITY',
      overview: 'OVERVIEW',
      chat_view: 'COMMUNICATION',
      grid_view: 'GRID',
      notes_view: 'NOTES',
      drive_view: 'DRIVE',
      internal_chat:"INTERNAL_CHAT",
      calendar:"CALENDAR",
      split_view:"SPLIT",
      app:"APP_BOARD_VIEW"
    },
    mediaExtension:{
      IMAGE : ["jpg","jpeg","png","tif","tiff", "bmp", "eps","raw", "cr2", "nef", "orf", "sr2"],
      VIDEO :  ["webm", "mkv", "flv", "vob", "ogv", "ogg", "drc", "gifv", "mng", "avi", "mts", "m2ts", "ts", "mov", "qt", "wmv", "yuv", "rm",
      "rmvb", "viv","asf", "amv", "mp4", "m4p", "m4v", "mpg", "mp2", "mpeg", "mpe", "mpv", "mpg", "mpeg", "m2v", "m4v", "svi", "3gp", "3g2", "mxf", "roq",
      "nsv","flv", "f4v", "f4p", "f4a", "f4b"],
      FILE :  ['pdf','ppt','doc','html','csv'],
      AUDIO : ["3gp", "aa", "aac", "aax", "act", "aiff", "alac", "amr", "ape", "au", "awb", "dss", "dvf", "flac", "gsm", "iklax", "ivs", "m4a",
      "m4b", "m4p", "mmf", "mp3", "mpc", "msv", "nmf", "ogg", "oga", "mogg", "opus", "org", "ra", "rm", "raw", "rf64", "sln", "tta", "voc", "vox", "wav",
      "wma", "wv", "webm", "8svx", "cda"]
    },
    defaultFilters: {
      clearAllFilters:"clearAllFilters",
      myAssigned:"myAssigned",
      myCollaborated:"myCollaborated",
      withOutStartDate:"withOutStartDate",
      withOutDueDate:"withOutDueDate",
      withOutTags:"withOutTags",
      myMentions:"myMentions",
      assignedByMe:"assignedByMe",
      createdByMe:"createdByMe",
      starred:"starred",
      deadlineoverDue:"deadlineoverDue",
      isDeleted:"isDeleted"
    },

    havePermissionObj: {
      rename_dashboard: 'RENAME_DASHBOARD',
      archive_dashboard: 'ARCHIVE_DASHBOARD',
      edit_dashboard: 'EDIT_DASHBOARD',
      delete_dashboard: 'DELETE_DASHBOARD',
      mark_favourite_dashboard: 'MARK_FAVOURITE_DASHBOARD',
      remove_favourite_dashboard: 'REMOVE_FAVOURITE_DASHBOARD',
      remove_archive_dashboard: 'REMOVE_ARCHIVE_DASHBOARD',
      add_phase: 'ADD_PHASE',
      start_phase: 'START_PHASE',
      stop_phase: 'STOP_PHASE',
      rename_phase: 'RENAME_PHASE',
      edit_phase_dates: 'EDIT_PHASE_DATES',
      delete_phase: 'DELETE_PHASE',
      archive_phase: 'ARCHIVE_PHASE',
      remove_archive_phase: 'REMOVE_ARCHIVE_PHASE',
      show_phase: 'SHOW_PHASE',
      hide_phase: 'SHOW_PHASE'
    },

    processPageConst: {
      bucket: 'Bucket',
      processHead: 'Restrict Items'
    },

    notificationType: {
      dashboard: 'DASHBOARD',
      dashboardUser: 'DASHBOARD_USER',
      lead: 'LEAD',
      item:'ITEM',
      date: 'DATE',
      comment: 'COMMENTS',
      assignment: 'ASSIGNMENT',
      leadComment: "LEAD_COMMENT",
      activityType: {
        add: 'ADD',
        update: 'UPDATE',
        delete: 'DELETE',
        board_update:'BOARD_UPDATE'
      }
    },
    config: {
      "FACEBOOK":"facebook",
      "GOOGLE":"google",
      "GITHUB":"github",
      "LINKEDIN":"linkedin",
      "MICROSOFT":"microsoft",
      "ThirdParty_TOKENID":"f9f49e40-a52e-4f64-9caa-7994c903d0"
    },
 
  algorithm:[
    {'display':'Select Randomly','key':"RANDOM"},
    {'display':'Select Based On Workload','key':"LOAD_BALANCED"}
],
  operationOnNumber:[
    {display_key:'+ Add',key:"OPERATOR_PLUS"},
    {display_key:'- Subtract',key:"OPERATOR_MINUS"},
    {display_key:'x Multiply',key:"OPERATOR_MULTIPLY"},
    {display_key:'/ Divide',key:"OPERATOR_DIVIDE"},

  ],
  daysCorrespondingJson:[{name:'Monday',key:1,value:'MONDAY',days:'MON'},{name:'Tuesday',key:2,value:'TUESDAY',days:'TUE'},{name:'Wednesday',key:3,value:'WEDNESDAY',days:'WED'},{name:'Thursday',key:4,value:'THURSDAY',days:'THU'},{name:'Friday',key:5,value:'FRIDAY',days:'FRI'},{name:'Saturday',key:6,value:'SATURDAY',days:'SAT'},{name:'Sunday',key:0,value:'SUNDAY',days:'SUN'}],
  conditionList:[
    {display_key:"Updated By",key:"updated_by"},
    {display_key:"Created By",key:"created_by"},
    {display_key:"Deleted",key:"delete"},
  ],
  andOr:[{'display':'AND','key':'and'},{'display':'OR','key':'or'}],
 
    CurrencyList: [
      {"name":"Afghanistan","flag":"🇦🇫","code":"AF","text":"AF ؋"},
      {"name":"Albania","flag":"🇦🇱","code":"AL","text": "AL Lek"},
      {"name":"Algeria","flag":"🇩🇿","code":"DZ","text":"DZ دج"},
      {"name":"American Samoa","flag":"🇦🇸","code":"AS","text":"AS $"},
      {"name":"Andorra","flag":"🇦🇩","code":"AD","text":"AD €"},
      {"name":"Angola","flag":"🇦🇴","code":"AO","text":"AO Kz"},
      {"name":"Anguilla","flag":"🇦🇮","code":"AI","text":"AI $"},
      {"name":"Antarctica","flag":"🇦🇶","code":"AQ","text":"AQ $"},
      {"name":"Antigua and Barbuda","flag":"🇦🇬","code":"AG","text":"AG $"},
      {"name":"Argentina","flag":"🇦🇷","code":"AR","text":"AR $"},
      {"name":"Armenia","flag":"🇦🇲","code":"AM ֏",},
      {"name":"Aruba","flag":"🇦🇼","code":"AW","text":"AW ƒ"},
      {"name":"Australia","flag":"🇦🇺","code":"AU","text":"AU $"},
      {"name":"Austria","flag":"🇦🇹","code":"AT","text":"AT €"},
      {"name":"Azerbaijan","flag":"🇦🇿","code":"AZ","text":"AZ ман"},
      {"name":"Bahamas","flag":"🇧🇸","code":"BS","text":"BS $"},
      {"name":"Bahrain","flag":"🇧🇭","code":"BH","text":"BH فلس"},
      {"name":"Bangladesh","flag":"🇧🇩","code":"BD","text":"BD  ৳"},
      {"name":"Barbados","flag":"🇧🇧","code":"BB","text":"BB $"},
      {"name":"Belarus","flag":"🇧🇾","code":"BY","text":"BY p."},
      {"name":"Belgium","flag":"🇧🇪","code":"BE","text":"BE €"},
      {"name":"Belize","flag":"🇧🇿","code":"BZ","text":"BZ BZ$"},
      {"name":"Bermuda","flag":"🇧🇲","code":"BM","text":"B $"},
      {"name":"Bhutan","flag":"🇧🇹","code":"BT","text":"BT  Nu."},
      {"name":"Bolivia, Plurinational State of bolivia","flag":"🇧🇴","code":"BO","text":"BO $b"},
      {"name":"Bosnia and Herzegovina","flag":"🇧🇦","code":"BA","text":"BA KM"},
      {"name":"Botswana","flag":"🇧🇼","code":"BW","text":"BW P"},
      {"name":"Bouvet Island","flag":"🇧🇻","code":"BV","text":"BV kr"},
      {"name":"Brazil","flag":"🇧🇷","code":"BR","text":"BR R$"},
      {"name":"British Indian Ocean Territory","flag":"🇮🇴","code":"IO","text":"IO $"},
      {"name":"Brunei Darussalam","flag":"🇧🇳","code":"BN","text":"BN $"},
      {"name":"Bulgaria","flag":"🇧🇬","code":"BG","text":"BG лв."},
      {"name":"Burundi","flag":"🇧🇮","code":"BI","text":"BI ₣"},
      {"name":"Cambodia","flag":"🇰🇭","code":"KH","text":"KH ៛"},
      {"name":"Cameroon","flag":"🇨🇲","code":"CM","text":"CM FCF"},
      {"name":"Canada","flag":"🇨🇦","code":"CA","text":"CA $"},
      {"name":"Cape Verde","flag":"🇨🇻","code":"CV","text":"CV $"},
      {"name":"Cayman Islands","flag":"🇰🇾","code":"KY","text":"KY $"},
      {"name":"Central African Republic","flag":"🇨🇫","code":"CF","item":"CF FCF"},
      {"name":"Chile","flag":"🇨🇱","code":"CL","text":"CL $"},
      {"name":"China","flag":"🇨🇳","code":"CN","text":"CN ¥"},
      {"name":"Christmas Island","flag":"🇨🇽","code":"CX","text":"CX $"},
      {"name":"Cocos (Keeling) Islands","flag":"🇨🇨","code":"CC","text":"CC $"},
      {"name":"Colombia","flag":"🇨🇴","code":"CO","text":"CO $"},
      {"name":"Congo","flag":"🇨🇬","code":"CG","text":"CG ₣"},
      {"name":"Congo, The Democratic Republic of the Congo","flag":"🇨🇩","code":"CD","text":"CD ₣"},
      {"name":"Cook Islands","flag":"🇨🇰","code":"CK","text":"CK $"},
      {"name":"Costa Rica","flag":"🇨🇷","code":"CR","text":"CR ₡"},
      {"name":"Cote d'Ivoire","flag":"🇨🇮","code":"CI","text":"CI"},
      {"name":"Croatia","flag":"🇭🇷","code":"HR","text":"HR kn"},
      {"name":"Cuba","flag":"🇨🇺","code":"CU","text":"CU ₱"},
      {"name":"Cyprus","flag":"🇨🇾","code":"CY","text":"CY €"},
      {"name":"Czech Republic","flag":"🇨🇿","code":"CZ","text":"CZ Kč"},
      {"name":"Denmark","flag":"🇩🇰","code":"DK","text":"DK kr"},
      {"name":"Djibouti","flag":"🇩🇯","code":"DJ","text":"DJ ₣"},
      {"name":"Dominica","flag":"🇩🇲","code":"DM","text":"DM $"},
      {"name":"Dominican Republic","flag":"🇩🇴","code":"DO","text":"DO RD$"},
      {"name":"Ecuador","flag":"🇪🇨","code":"EC","text":"EC $"},
      {"name":"Egypt","flag":"🇪🇬","code":"EG","text":"EG £"},
      {"name":"El Salvador","flag":"🇸🇻","code":"SV","text":"SV $"},
      {"name":"Equatorial Guinea","flag":"🇬🇶","code":"GQ","text":"GQ FCF"},
      {"name":"Eritrea","flag":"🇪🇷","code":"ER","text":"ER Nfk"},
      {"name":"Estonia","flag":"🇪🇪","code":"EE","text":"EE kr"},
      {"name":"Ethiopia","flag":"🇪🇹","code":"ET","text":"ET"},
      {"name":"Falkland Islands (Malvinas)","flag":"🇫🇰","code":"FK","text":"FK £"},
      {"name":"Faroe Islands","flag":"🇫🇴","code":"FO","text":"FQ kr"},
      {"name":"Fiji","flag":"🇫🇯","code":"FJ","text":"FJ $"},
      {"name":"Finland","flag":"🇫🇮","code":"FI","text":"FI €"},
      {"name":"France","flag":"🇫🇷","code":"FR","text":"FR €"},
      {"name":"French Guiana","flag":"🇬🇫","code":"GF","text":"GF €"},
      {"name":"French Polynesia","flag":"🇵🇫","code":"PF","text":"PF ₣"},
      {"name":"French Southern Territories","flag":"🇹🇫","code":"TF","text":"TF €"},
      {"name":"Gabon","flag":"🇬🇦","code":"GA","text":"GA FCF"},
      {"name":"Gambia","flag":"🇬🇲","code":"GM","text":"GM D"},
      {"name":"Georgia","flag":"🇬🇪","code":"GE","text":"GE ლ"},
      {"name":"Germany","flag":"🇩🇪","code":"DE","text":"DE €"},
      {"name":"Ghana","flag":"🇬🇭","code":"GH","text":"GH ¢"},
      {"name":"Gibraltar","flag":"🇬🇮","code":"GI","text":"GI £"},
      {"name":"Greece","flag":"🇬🇷","code":"GR","text":"GR €"},
      {"name":"Greenland","flag":"🇬🇱","code":"GL","text":"GL kr"},
      {"name":"Grenada","flag":"🇬🇩","code":"GD","text":"GD $"},
      {"name":"Guadeloupe","flag":"🇬🇵","code":"GP","text":"GP €"},
      {"name":"Guam","flag":"🇬🇺","code":"GU","text":"GU $"},
      {"name":"Guatemala","flag":"🇬🇹","code":"GT","text":"GT Q"},
      {"name":"Guinea","flag":"🇬🇳","code":"GN","text":"GN ₣"},
      {"name":"Guyana","flag":"🇬🇾","code":"GY","text":"GY $"},
      {"name":"Haiti","flag":"🇭🇹","code":"HT","text":"HT G"},
      {"name":"Heard Island and Mcdonald Islands","flag":"🇭🇲","code":"HM","text":"HM $"},
     {"name":"Honduras","flag":"🇭🇳","code":"HN","text":"HN L"},
     {"name":"Hong Kong","flag":"🇭🇰","code":"HK","text":"HK $"},
     {"name":"Hungary","flag":"🇭🇺","code":"HU","text":"HU Ft"},
     {"name":"Iceland","flag":"🇮🇸","code":"IS","text":"IS kr"},
     {"name":"India","flag":"🇮🇳","code":"IN","text":"IN ₹"},
     {"name":"Indonesia","flag":"🇮🇩","code":"ID","text":"ID Rp"},
     {"name":"Iran, Islamic Republic of Persian Gulf","flag":"🇮🇷","code":"IR","text":"IR ﷼"},
     {"name":"Iraq","flag":"🇮🇶","code":"IQ","text":"IQ 	ع.د"},
     {"name":"Ireland","flag":"🇮🇪","code":"IE","text":"IE €"},
     {"name":"Israel","flag":"🇮🇱","code":"IL","text":"IL ₪"},
     {"name":"Italy","flag":"🇮🇹","code":"IT","text":"IT €"},
     {"name":"Jamaica","flag":"🇯🇲","code":"JM","text":"JM $"},
     {"name":"Japan","flag":"🇯🇵","code":"JP","text":"JP ¥"},
     {"name":"Jordan","flag":"🇯🇴","code":"JO","text":"JO د.ا"},
     {"name":"Kazakhstan","flag":"🇰🇿","code":"KZ","text":"KZ лв"},
     {"name":"Kenya","flag":"🇰🇪","code":"KE","text":"KE Sh"},
     {"name":"Kiribati","flag":"🇰🇮","code":"KI","text":"KI $"},
     {"name":"Kuwait","flag":"🇰🇼","code":"KW","text":"KW د.ك"},
     {"name":"Kyrgyzstan","flag":"🇰🇬","code":"KG","text":"KG лв"},
     {"name":"Laos","flag":"🇱🇦","code":"LA","text":"LA ₭"},
     {"name":"Latvia","flag":"🇱🇻","code":"LV","text":"LV Ls"},
     {"name":"Lebanon","flag":"🇱🇧","code":"LB","text":"LB £"},
     {"name":"Lesotho","flag":"🇱🇸","code":"LS","text":"LS L"},
     {"name":"Liberia","flag":"🇱🇷","code":"LR","text":"LR $"},
     {"name":"Liechtenstein","flag":"🇱🇮","code":"LI","text":"LI CHF"},
     {"name":"Lithuania","flag":"🇱🇹","code":"LT","text":"LT Lt"},
     {"name":"Luxembourg","flag":"🇱🇺","code":"LU","text":"LU €"},
     {"name":"Macao","flag":"🇲🇴","code":"MO","text":"MO MOP"},
     {"name":"Macedonia","flag":"🇲🇰","code":"MK","text":"MK ден"},
     {"name":"Madagascar","flag":"🇲🇬","code":"MG","text":"MG"},
     {"name":"Malawi","flag":"🇲🇼","code":"MW","text":"MW MK"},
     {"name":"Malaysia","flag":"🇲🇾","code":"MY","text":"MY RM"},
     {"name":"Maldives","flag":"🇲🇻","code":"MV","text":"MV Rf"},
     {"name":"Mali","flag":"🇲🇱","code":"ML","text":"ML"},
     {"name":"Malta","flag":"🇲🇹","code":"MT","text":"MT"},
     {"name":"Marshall Islands","flag":"🇲🇭","code":"MH","text":"MH $"},
     {"name":"Martinique","flag":"🇲🇶","code":"MQ","text":"MQ €"},
     {"name":"Mauritania","flag":"🇲🇷","code":"MR","text":"MR UM"},
     {"name":"Mauritius","flag":"🇲🇺","code":"MU","text":"MU ₨"},
     {"name":"Mayotte","flag":"🇾🇹","code":"YT","text":"YT €"},
     {"name":"Mexico","flag":"🇲🇽","code":"MX","text":"MX $"},
     {"name":"Micronesia, Federated States of Micronesia","flag":"🇫🇲","code":"FM","text":"FM $"},
     {"name":"Moldova","flag":"🇲🇩","code":"MD","text":"MD L"},
     {"name":"Monaco","flag":"🇲🇨","code":"MC","text":"MC €"},
     {"name":"Mongolia","flag":"🇲🇳","code":"MN","text":"MN ₮"},
     {"name":"Montserrat","flag":"🇲🇸","code":"MS","text":"MS $"},
     {"name":"Morocco","flag":"🇲🇦","code":"MA","text":"MA 	د.م."},
     {"name":"Mozambique","flag":"🇲🇿","code":"MZ","text":"MZ MT"},
     {"name":"Myanmar","flag":"🇲🇲","code":"MM","text":"MM K"},
     {"name":"Namibia","flag":"🇳🇦","code":"NA","text":"NA $"},
     {"name":"Nauru","flag":"🇳🇷","code":"NR","text":"NR $"},
     {"name":"Nepal","flag":"🇳🇵","code":"NP","text":"NP ₨"},
     {"name":"Netherlands","flag":"🇳🇱","code":"NL","text":"NL €"},
     {"name":"Netherlands Antilles","flag":"","code":"AN","text":"AN ƒ"},
     {"name":"New Caledonia","flag":"🇳🇨","code":"NC","text":"NC ₣"},
     {"name":"New Zealand","flag":"🇳🇿","code":"NZ","text":"NZ $"},
     {"name":"Nicaragua","flag":"🇳🇮","code":"NI","text":"NI C$"},
     {"name":"Niger","flag":"🇳🇪","code":"NE","text":"NE ₣"},
     {"name":"Nigeria","flag":"🇳🇬","code":"NG","text":"NG ₦"},
     {"name":"Niue","flag":"🇳🇺","code":"NU","text":"NU $"},
     {"name":"Norfolk Island","flag":"🇳🇫","code":"NF","text":"NF $"},
     {"name":"Northern Mariana Islands","flag":"🇲🇵","code":"MP","text":"MP $"},
     {"name":"Norway","flag":"🇳🇴","code":"NO","text":"NO kr"},
     {"name":"Oman","flag":"🇴🇲","code":"OM","text":"OM ﷼"},
     {"name":"Pakistan","flag":"🇵🇰","code":"PK","text":"PK ₨"},
     {"name":"Palau","flag":"🇵🇼","code":"PW","text":"PW $"},
     {"name":"Palestinian Territory, Occupied","flag":"🇵🇸","code":"PS","text":"PS ₪"},
     {"name":"Panama","flag":"🇵🇦","code":"PA","text":"PA B/."},
     {"name":"Papua New Guinea","flag":"🇵🇬","code":"PG","text":"PG K"},
     {"name":"Paraguay","flag":"🇵🇾","code":"PY","text":"PY Gs"},
     {"name":"Peru","flag":"🇵🇪","code":"PE","text":"PE S/."},
     {"name":"Philippines","flag":"🇵🇭","code":"PH","text":"PH Php"},
     {"name":"Pitcairn","flag":"🇵🇳","code":"PN","text":"PN $"},
     {"name":"Poland","flag":"🇵🇱","code":"PL","text":"PL zł"},
     {"name":"Portugal","flag":"🇵🇹","code":"PT","text":"PT €"},
     {"name":"Puerto Rico","flag":"🇵🇷","code":"PR","text":"PR $"},
     {"name":"Qatar","flag":"🇶🇦","code":"QA","text":"QA ﷼"},
     {"name":"Romania","flag":"🇷🇴","code":"RO","text":"RO lei"},
     {"name":"Russia","flag":"🇷🇺","code":"RU","text":"RU руб"},
     {"name":"Rwanda","flag":"🇷🇼","code":"RW","text":"RW ₣"},
     {"name":"Reunion","flag":"🇷🇪","code":"RE","text":"RE €"},
     {"name":"Saint Helena, Ascension and Tristan Da Cunha","flag":"🇸🇭","code":"SH","text":"SH £"},
     {"name":"Saint Kitts and Nevis","flag":"🇰🇳","code":"KN","text":"KN $"},
     {"name":"Saint Lucia","flag":"🇱🇨","code":"LC","text":"LC $"},
     {"name":"Saint Pierre and Miquelon","flag":"🇵🇲","code":"PM","text":"PM €"},
     {"name":"Saint Vincent and the Grenadines","flag":"🇻🇨","code":"VC","text":"VC $"},
     {"name":"San Marino","flag":"🇸🇲","code":"SM","text":"SM WS$"},
     {"name":"Sao Tome and Principe","flag":"🇸🇹","code":"ST","text":"ST Db"},
     {"name":"Saudi Arabia","flag":"🇸🇦","code":"SA","text":"SA ﷼"},
     {"name":"Senegal","flag":"🇸🇳","code":"SN","text":"SN"},
     {"name":"Serbia","flag":"🇷🇸","code":"RS","text":"RS Дин"},
     {"name":"Seychelles","flag":"🇸🇨","code":"SC","text":"SC ₨"},
     {"name":"Sierra Leone","flag":"🇸🇱","code":"SL","text":"SL Le"},
     {"name":"Singapore","flag":"🇸🇬","code":"SG","text":"SG $"},
     {"name":"Slovakia","flag":"🇸🇰","code":"SK","text":"SK Sk"},
     {"name":"Slovenia","flag":"🇸🇮","code":"SI","text":"SI €"},
     {"name":"Solomon Islands","flag":"🇸🇧","code":"SB","text":"SB $"},
     {"name":"Somalia","flag":"🇸🇴","code":"SO","text":"SO S"},
     {"name":"South Africa","flag":"🇿🇦","code":"ZA","text":"ZA R"},
     {"name":"South Georgia and the South Sandwich Islands","flag":"🇬🇸","code":"GS","text":"GS £"},
     {"name":"Spain","flag":"🇪🇸","code":"ES","text":"ES €"},
     {"name":"Sri Lanka","flag":"🇱🇰","code":"LK","text":"LK ₨"},
     {"name":"Sudan","flag":"🇸🇩","code":"SD","text":"SD £"},
     {"name":"Suriname","flag":"🇸🇷","code":"SR","text":"SR $"},
     {"name":"Svalbard and Jan Mayen","flag":"🇸🇯","code":"SJ","text":"SJ kr"},
     {"name":"Sweden","flag":"🇸🇪","code":"SE","text":"SE kr"},
     {"name":"Switzerland","flag":"🇨🇭","code":"CH","text":"CH CHF"},
     {"name":"Syrian Arab Republic","flag":"🇸🇾","code":"SY","text":"SY £"},
     {"name":"Taiwan","flag":"🇹🇼","code":"TW","text":"TW NT$"},
     {"name":"Tajikistan","flag":"🇹🇯","code":"TJ","text":"TJ ЅМ"},
     {"name":"Tanzania, United Republic of Tanzania","flag":"🇹🇿","code":"TZ","text":"TZ ฿"},
     {"name":"Thailand","flag":"🇹🇭","code":"TH","text":"TH ฿"},
     {"name":"Togo","flag":"🇹🇬","code":"TG","text":"TG ₣"},
     {"name":"Tokelau","flag":"🇹🇰","code":"TK","text":"TK $"},
     {"name":"Tonga","flag":"🇹🇴","code":"TO","text":"TO T$"},
     {"name":"Trinidad and Tobago","flag":"🇹🇹","code":"TT","text":"TT TT$"},
     {"name":"Tunisia","flag":"🇹🇳","code":"TN","text":"TN د.ت"},
     {"name":"Turkey","flag":"🇹🇷","code":"TR","text":"TR YTL"},
     {"name":"Turkmenistan","flag":"🇹🇲","code":"TM","text":"TM m"},
     {"name":"Turks and Caicos Islands","flag":"🇹🇨","code":"TC","text":"TC $"},
     {"name":"Tuvalu","flag":"🇹🇻","code":"TV","text":"TV $"},
     {"name":"Uganda","flag":"🇺🇬","code":"UG","text":"UG Sh"},
     {"name":"Ukraine","flag":"🇺🇦","code":"UA","text":"UA ₴"},
     {"name":"United Arab Emirates","flag":"🇦🇪","code":"AE","text":"AE"},
     {"name":"United Kingdom","flag":"🇬🇧","code":"GB","text":"GB £"},
     {"name":"United States","flag":"🇺🇸","code":"US","text":"US $"},
     {"name":"Uruguay","flag":"🇺🇾","code":"UY","text":"UY $U"},
     {"name":"Uzbekistan","flag":"🇺🇿","code":"UZ","text":"UZ лв"},
     {"name":"Vanuatu","flag":"🇻🇺","code":"VU","text":"VU Vt"},
     {"name":"Vietnam","flag":"🇻🇳","code":"VN","text":"VN Bs"},
     {"name":"Wallis and Futuna","flag":"🇼🇫","code":"WF","text":"WF ₣"},
     {"name":"Yemen","flag":"🇾🇪","code":"YE","text":"YE ﷼"},
     {"name":"Zambia","flag":"🇿🇲","code":"ZM","text":"ZM ZK"},
     {"name":"Zimbabwe","flag":"🇿🇼","code":"ZW","text":"ZW Z$"}
      
    ],
    AllowedFieldsFromText:[
      {"id":"Text","type":"Text"},
      {"id":"Email","type":"Email"},
      {"id":"Radiobutton","type":"Radio"},
      {"id":"Dropdown","type":"Dropdown"},
      {"id":"Numeric","type":"Number"},
      {"id":"Url","type":"Url"},
      // {"id":"MultiUrl","type":"MultiUrl"}
    ],
    AllowedFieldsFromDropdown:[
      {"id":"Radiobutton","type":"Radio"},
      {"id":"Dropdown","type":"Dropdown"},
    ],
    AllowedFieldsFromSingleFile:[
      {"id":"SingleFile","type":"File"},
      {"id":"SingleImage","type":"Image"},
      {"id":"SingleVideo","type":"Video"},
      {"id":"SingleAudio","type":"Audio"},
    ],
    AllowedFieldsFromMultipleFile:[
      {"id":"MultipleFile","type":"File"},
      {"id":"MultipleImage","type":"Image"},
      {"id":"MultipleVideo","type":"Video"},
      {"id":"MultipleAudio","type":"Audio"},
    ],
    AllowedFieldsFromCheckbox:[
      {"id":"Checkbox","type":"Checkbox"},
      {"id":"MultiSelect","type":"Multiselect"},
    ],
    Priority:[
    {"initials":"L","name":"Low","id":0},
    {"initials":"M","name":"Medium","id":1},
    {"initials":"H","name":"High","id":2},
    {"initials":"U","name":"Urgent","id":3},
    ],
    AUDIT_LOG_BLOCK_STATUS_COLOR:{
      SUCCESS :
      {
        color:"#38a745",
        label:'Success'
      } ,
      PARTIAL_SUCCESS :
      {
        color:"#ffba08",
        label:'Partial Success'
      } ,
      FAILURE : 
      {
        color:"#ea4141",
        label:'Failure'
      } ,
      IN_PROGRESS : 
      {
        color:"#00b4d8",
        label:'In Progress'
      },
      NOT_REACHED :
      {
        color:"#839192",
        label:'Not Reached'
      } ,
      STOPPED :
      {
        color:"#780000",
        label:'Stopped'
      } ,

    },
    AUDIT_LOG_STATUS_COLOR:{
      SUCCESS :
      {
        color:"#50B79A",
        backgroundColor:"#bbede1",
        notificationColor:"#50B79A",
        label:'Success'
      } ,
      FAILURE : 
      {
        color:"#FE2030",
        backgroundColor:"#FE203036",
        notificationColor:"#FE2030",
        label:'Failure'
      } ,
      CONDITION_FAILURE :
      {
        color:"#FE9301",
        backgroundColor:"#FE930136",
        notificationColor:"#FE9301",
        label:'Condition Failed'
      } ,
      IN_PROGRESS : 
      {
        color:"#00b4d8",
        backgroundColor:"#daf1f8",
        notificationColor:"#00b4d8",
        label:'In Progress'
      },
      STOPPED :
      {
        color:"#780000",
        backgroundColor:"#e8ceca",
        notificationColor:"#780000",
        label:'Stopped'
      } ,
      STOPPING :
      {
        color:"#780000",
        backgroundColor:"#e8ceca",
        notificationColor:"#780000",
        label:'Stopping'
      } ,
      ABORTED :
      {
        color:"#780000",
        backgroundColor:"#e8ceca",
        notificationColor:"#780000",
        label:'Aborted'
      } ,

    },
    AUTOMATION_BLOCK_COLOR:{
      TRIGGER :'#3269CC',
      ACTION :'#34A290',
      CONTROL_BLOCK :'#EAB05A',
      WAIT_UNTIL :'#EAB05A',
      ASSIGN_VARIABLE :'#ff006e',
      CONDITION :'#353E4E',
      DEFAULT :'#353E4E',
    },
    
    AUTHORIZATIONTHROUGH3RDPARTY:"AuthorizationThrough3rdParty",
    BASE_URL: window.location.protocol+'//'+window.location.hostname+((window.location.port != '')?(':'+window.location.port):''),
    REDIRECT_URI: window.location.protocol+'//'+window.location.hostname+((window.location.port != '')?(':'+window.location.port):'')+'/login',    
    LINKEDINURL:'https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=',
    LINKEDIN_CLIENT_ID: "77e0tf48cbob2a",  //ToBeChanged
    GOOGLEURL:'https://accounts.google.com/o/oauth2/v2/auth?scope=openid%20profile%20email&access_type=offline&include_granted_scopes=true&response_type=code&state=%7Bclient_id%3D',
    GOOGLE_CLIENT_ID: "486990179517-h0391nml06dtob113tp8dkfp8ken33bt.apps.googleusercontent.com", //ToBeChanged
    // https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fwebmasters%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcontacts.readonly
    // CONNECT_EMAIL_GOOGLE_URL: "https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https%3A%2F%2Fmail.google.com%2F%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.send%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.modify%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.settings.basic&state=pronnelgoogle&response_type=code&client_id=70652112951-8a6uce76giuqvvdvro24390j9ph7c1e7.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fstaging.pronnel.com%2Fprofile%2Fuser",
  /*   CONNECT_EMAIL_GOOGLE_URL: `https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=   
    https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.send%20
    https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.readonly%20
    https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.readonly%20
    https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar%20     
    &state=pronnelgoogle&response_type=code&client_id=`, */
    CONNECT_EMAIL_GOOGLE_CLIENT_ID: "70652112951-8a6uce76giuqvvdvro24390j9ph7c1e7.apps.googleusercontent.com",
    CONNECT_EMAIL_MICROSOFT_URL: "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?response_mode=query&response_type=code&state=pronnelmicrosoft&scope=offline_access%20user.read%20mail.readwrite%20mail.send%20&client_id=",
    CONNECT_EMAIL_MICROSOFT_CLIENT_ID: "2c6ee025-e81e-4b2c-8af5-3127479eed23",
    CONNECT_EMAIL_REDIRECT_URL: "/profile/user",
    CONNECT_EMAIL_ZOHOMAIL_URL: `https://accounts.zoho.com/oauth/v2/auth?access_type=offline&response_type=code&scope=ZohoMail.accounts.READ%20ZohoMail.messages.ALL%20ZohoMail.folders.READ%20ZohoMail.messages.CREATE&prompt=consent&client_id=`,
    CONNECT_EMAIL_ZOHOMAIL_CLIENT_ID: "1000.ZJR0UUOQRAAI8S86NJ2MB5W8RDZFVN",
    CONNECT_GOOGLE_BASE_URL:`https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&state=pronnelgoogle&response_type=code`,
    CONNECT_MICROSOFT_BASE_URL:`https://login.microsoftonline.com/common/oauth2/v2.0/authorize?response_mode=query&response_type=code&state=pronnelmicrosoft`,
    CONNECT_GOOGLE_CALENDAR_SCOPE:`https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20`,
    CONNECT_MICROSOFT_CALENDAR_SCOPE:`offline_access%20user.read%20mail.readwrite%20mail.send%20Calendars.ReadWrite%20`,
    CONNECT_GOOGLE_EMAIL_SCOPE:`https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.send%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.readonly%20`,
    CONNECT_MICROSOFT_EMAIL_SCOPE:`offline_access%20user.read%20mail.readwrite%20mail.send%20`,
  };
  customfieldType : any = {
    MEDIA_TYPES: ['SingleImage', 'MultipleImage', 'SingleVideo', 'MultipleVideo', 'SingleFile', 'MultipleFile','SingleAudio','MultipleAudio'],
    TEXT_TYPES: ['Currency', 'Radiobutton', 'Dropdown', 'SingleUser', 'Text', 'Textarea', 'IPAddress', 'Mobile', 'Email', 'Url'],
    NUMERIC_TYPES: ['Percentage', 'Numeric', 'Rating'],
    MULTI_TYPES: ['MultiUser', 'MultiSelect', 'Checkbox'],
    DATE: ['Date']
  }
  formulasOptionsFields ={
    "TOTAL_ATTACHMENT_SIZE":{key:"TOTAL_ATTACHMENT_SIZE",display_key:"Attachment Size" , fieldOptions:this.customfieldType?.MEDIA_TYPES , value_type:'Size'},
    "SUM":{key:"SUM",display_key:"Sum" , fieldOptions:this.customfieldType?.NUMERIC_TYPES ,value_type:'Number'},
    "AVG":{key:"AVG",display_key:"Average" , fieldOptions:this.customfieldType?.NUMERIC_TYPES ,value_type:'Number'},
    "MIN":{key:"MIN",display_key:"Min" , fieldOptions:this.customfieldType?.NUMERIC_TYPES ,value_type:'Number'},
    "MAX":{key:"MAX",display_key:"Max" , fieldOptions:this.customfieldType?.NUMERIC_TYPES ,value_type:'Number'},
    "COUNT_ALL":{key:"COUNT_ALL",display_key:"Count" , fieldOptions:[] , is_all_Customfield:true , value_type:'Number'},
    "COUNT_UNIQUE":{key:"COUNT_UNIQUE",display_key:"Count Unique", fieldOptions:[...this.customfieldType?.TEXT_TYPES,...this.customfieldType?.NUMERIC_TYPES,...this.customfieldType?.DATE,'Priority','Bucket','Sprints','Assignee'], value_type:'Number'},
    "COUNT_EMPTY":{key:"COUNT_EMPTY",display_key:"Count Empty", fieldOptions:[], is_all_Customfield:true,value_type:'Number'},
    "COUNT_NONEMPTY":{key:"COUNT_NONEMPTY",display_key:"Count Nonempty", fieldOptions:[] ,is_all_Customfield:true ,value_type:'Number'},
    "PERCENT_EMPTY":{key:"PERCENT_EMPTY",display_key:"Percentage Empty", fieldOptions:[] ,is_all_Customfield:true ,value_type:'Percentage'},
    "PERCENT_NONEMPTY":{key:"PERCENT_NONEMPTY",display_key:"Percentage Nonempty", fieldOptions:[] , is_all_Customfield:true,value_type:'Percentage'},
    "NONE":{key:"NONE",display_key:"None",fieldOptions:[] , is_all_Customfield:true,value_type:'Empty'},

  }

  loaderConfig={
    loader_0:'<div class="loader-bounce-jumper"><div>',
    loader_1:`<div class="loader-bounce-squish-shadow"><div class="pl1"><div class="parent"><div class="pl1__a"></div></div><div class="parent"><div class="pl1__b"></div></div><div class="parent"><div class="pl1__c"></div></div><div class="parent"><div class="pl1__d"></div></div></div><div>`,
    loader_2:'<div class="loader-square-shift"></div>',
    loader_3:'<div class="loader-wave-shift"></div>'
  } 

  constant = ConstantService.constant
  getGoogleUrl(){
    //ConstantService.constant.GOOGLE_CLIENT_ID = this.customStorageService.getItem("GOOGLE_CLIENT_ID");
    var googleConfigUrl = ConstantService.constant.GOOGLEURL+ ConstantService.constant.GOOGLE_CLIENT_ID+"%7D&redirect_uri="+ConstantService.constant.REDIRECT_URI+"&client_id="+ ConstantService.constant.GOOGLE_CLIENT_ID;
    return googleConfigUrl;
  }
  getLinkedinUrl(){
    //ConstantService.constant.LINKEDIN_CLIENT_ID = this.customStorageService.getItem("LINKEDIN_CLIENT_ID");
    var linkedinConfigUrl = ConstantService.constant.LINKEDINURL+ConstantService.constant.LINKEDIN_CLIENT_ID+"&scope=r_liteprofile%20r_emailaddress&state=%7Bclient_id="+ ConstantService.constant.LINKEDIN_CLIENT_ID+"%7D&redirect_uri="+ConstantService.constant.REDIRECT_URI
    return linkedinConfigUrl;
  }

  getConnectEmailGoogleUrl(scope='EMAIL',prevAddedType?){
    let redirectUrl = scope=='EMAIL' ? "/profile/email-settings" : "/profile/calendar-settings"
    let scopeUrl = (scope=='EMAIL' && !prevAddedType) ? ConstantService.constant.CONNECT_GOOGLE_EMAIL_SCOPE : (scope=='EMAIL' && prevAddedType=='CALENDAR') ? ConstantService.constant.CONNECT_GOOGLE_EMAIL_SCOPE+ConstantService.constant.CONNECT_GOOGLE_CALENDAR_SCOPE :
    (scope=='CALENDAR' && !prevAddedType) ? ConstantService.constant.CONNECT_GOOGLE_CALENDAR_SCOPE :  ConstantService.constant.CONNECT_GOOGLE_CALENDAR_SCOPE+ConstantService.constant.CONNECT_GOOGLE_EMAIL_SCOPE
    var googleConfigUrl = ConstantService.constant.CONNECT_GOOGLE_BASE_URL+"&scope=" + scopeUrl + "&client_id="+ ConstantService.constant.CONNECT_EMAIL_GOOGLE_CLIENT_ID+"&redirect_uri="+ConstantService.constant.BASE_URL + redirectUrl;
      return googleConfigUrl;
  }

  getConnectEmailMircosoftUrl(scope='EMAIL',prevAddedType?){
    let redirectUrl = scope=='EMAIL' ? "/profile/email-settings" : "/profile/calendar-settings"
    let scopeUrl = (scope=='EMAIL' && !prevAddedType) ? ConstantService.constant.CONNECT_MICROSOFT_EMAIL_SCOPE : (scope=='EMAIL' && prevAddedType=='CALENDAR') ? ConstantService.constant.CONNECT_MICROSOFT_EMAIL_SCOPE+ConstantService.constant.CONNECT_MICROSOFT_CALENDAR_SCOPE :
    (scope=='CALENDAR' && !prevAddedType) ? ConstantService.constant.CONNECT_MICROSOFT_CALENDAR_SCOPE :  ConstantService.constant.CONNECT_MICROSOFT_CALENDAR_SCOPE+ConstantService.constant.CONNECT_MICROSOFT_EMAIL_SCOPE
    var microsoftUrl = ConstantService.constant.CONNECT_MICROSOFT_BASE_URL+"&scope="+scopeUrl+"&client_id="+ ConstantService.constant.CONNECT_EMAIL_MICROSOFT_CLIENT_ID+"&redirect_uri="+ConstantService.constant.BASE_URL + redirectUrl;
    console.log(microsoftUrl)   
    return microsoftUrl;
  }
  getConnectEmailZohoMailUrl(){
    var zohoMailUrl = ConstantService.constant.CONNECT_EMAIL_ZOHOMAIL_URL+ ConstantService.constant.CONNECT_EMAIL_ZOHOMAIL_CLIENT_ID+"&redirect_uri="+ConstantService.constant.BASE_URL +ConstantService.constant.CONNECT_EMAIL_REDIRECT_URL;
    return zohoMailUrl;
  }

  getAuthType(){
    return ConstantService.constant["AUTHORIZATIONTHROUGH3RDPARTY"];
  }

  getSocialMediaConfig() {
    return ConstantService.constant.config;
  }

  getNotificationTypeObj() {
    return ConstantService.constant.notificationType;
  }

  getProcessPageBucketConstant() {
    return ConstantService.constant.processPageConst.bucket;
  }
  getProcessModalHeadConst() {
    return ConstantService.constant.processPageConst.processHead;
  }

  checkUserHavePermission(permission: string, roleArray: any) {
    for(let i=0;i< ConstantService.constant.havePermissions.length;i++){
      if(roleArray?.includes(ConstantService.constant.havePermissions[i].role) && ConstantService.constant.havePermissions[i].permissions.includes(permission)){
        return true;
      }
    }
    return false;
  }

  getHavePermissionObj() {
    return ConstantService.constant.havePermissionObj;
  }

  getArcDasConst() {
    return ConstantService.constant.ARCHIVE_DAS;
  }

  getTableUpdate() {
    return ConstantService.constant["TABLEUPDATE"];
  }

  getLeadUpdateConstant() {
    return ConstantService.constant["LEADUPDATE"];
  }

  getDragLeadConstant() {
    return ConstantService.constant["DRAGLEAD"];
  }

  getDeleteLeadConstant() {
    return ConstantService.constant["DELETELEAD"];
  }

  getCreateLeadConstant() {
    return ConstantService.constant["CREATELEAD"];
  }

  getRefreshLeadConstant() {
    return ConstantService.constant["REFRESHLEAD"];
  }

  getNAValue() {
    return ConstantService.constant["NA"];
  }

  getFieldTypeDate() {
    return ConstantService.constant["DATE"];
  }

  getFieldTypeDropDown() {
    return ConstantService.constant["DROPDOWN"];
  }
  getNoPhaseConstant() {
    return ConstantService.constant["NOPHASE"];
  }

  getFieldTypeCheck() {
    return ConstantService.constant["CHECKBOX"];
  }

  getFieldTypeMobile() {
    return ConstantService.constant["MOBILE"];
  }

  getFieldTypTextArea() {
    return ConstantService.constant["TEXTAREA"];
  }

  getFieldTypeImage() {
    return ConstantService.constant["IMAGEFIELD"];
  }

  getFieldTypeRadio() {
    return ConstantService.constant["RADIO"];
  }

  getFieldTypeEmail() {
    return ConstantService.constant["EMAIL"];
  }

  getFieldTypeNumber() {
    return ConstantService.constant["NUMBER"];
  }

  getFieldTypeText() {
    return ConstantService.constant["TEXT"];
  }

  getPriorityArray() {
    return ConstantService.constant["PRIORITYARRAY"];
  }

  getMpgConstant() {
    return ConstantService.constant["MPG"];
  }

  getMpegConstant() {
    return ConstantService.constant["MPEG"];
  }

  getMP4Constant() {
    return ConstantService.constant["MP4"];
  }

  getPngConstant() {
    return ConstantService.constant["PNG"];
  }

  getJpegConstant() {
    return ConstantService.constant["JPEG"];
  }

  getJpgConstant() {
    return ConstantService.constant["JPG"];
  }

  getLeadConstant() {
    return ConstantService.constant["LEAD"];
  }

  getCommentConstant() {
    return ConstantService.constant["COMMENTS"];
  }

  getDocumentConstant() {
    return ConstantService.constant["DOCUMENT"];
  }

  getVideoConstant() {
    return ConstantService.constant["VIDEO"];
  }

  getAudioConstant() {
    return ConstantService.constant["AUDIO"];
  }

  getImageConstant() {
    return ConstantService.constant["IMAGE"];
  }

  getFileConstant() {
    return ConstantService.constant["FILE"];
  }

  getTimeConstant() {
    return ConstantService.constant["TIME"];
  }

  getDscOrder() {
    return ConstantService.constant["DSC"];
  }

  getAscOrder() {
    return ConstantService.constant["ASC"];
  }

  getSortByAgingTime() {
    return ConstantService.constant["AGINGTIME"];
  }

  getSortByStartDate() {
    return ConstantService.constant["STARTDATE"];
  }

  getSortByEndDate() {
    return ConstantService.constant["ENDDATE"];
  }

  getSortByBucket() {
    return ConstantService.constant["BUCKET"];
  }

  getSortByDashboard() {
    return ConstantService.constant["DASHBOARDSORTING"];
  }
  getSortByCreatedTime() {
    return ConstantService.constant["CREATEDTIME"];
  }

  getSortByLastUpdated() {
    return ConstantService.constant["LASTUPDATED"];
  }

  getSortByPriority() {
    return ConstantService.constant["PRIORITY"];
  }

  getSortByUser() {
    return ConstantService.constant["USER"];
  }

  getSortByRelevance() {
    return ConstantService.constant["RELEVANCE"];
  }

  getRole() {
    return ConstantService.constant["ROLE"];
  }

  getId() {
    return ConstantService.constant["ID"];
  }

  getSummary() {
    return ConstantService.constant["SUMMARY"];
  }

  getBucketName() {
    return ConstantService.constant["BUCKETNAME"];
  }

  getLastUpdatedTable() {
    return ConstantService.constant["LASTUPDATEDTABLE"];
  }

  getDueDate() {
    return ConstantService.constant["DUEDATE"];
  }

  getDateConstant() {
    return ConstantService.constant["DATEHEADER"];
  }

  getAssigned() {
    return ConstantService.constant["ASSIGNED"];
  }
  getDashboardConstant() {
    return ConstantService.constant["DASHBOARD"];
  }
  getBucketConstant() {
    return ConstantService.constant["BUCKETCONSTANT"]
  }

  getEndDate() {
    return ConstantService.constant["END"];
  }

  getPhase() {
    return ConstantService.constant["PHASE"];
  }

  getUniqueLeadIdConstant() {
    return ConstantService.constant["UNIQUELEADIDCONSTANT"];
  }

  getTableLeadDropdown() {
    return ConstantService.constant["TABLELEADDROPDOWN"];
  }

  getIndividualLeadDropdown() {
    return ConstantService.constant["INDIVIDUALLEADDROPDOWN"];
  }

  getCreateBy() {
    return ConstantService.constant["CREATEDBY"];
  }

  getCreateTime() {
    return ConstantService.constant["CREATEDATE"];
  }
  getAddConstant() {
    return ConstantService.constant["ADD"];
  }
  getRemoveConstant() {
    return ConstantService.constant["Remove"];
  }
  getReadConstant() {
    return ConstantService.constant["READ"];
  }
  getDashClickConstant() {
    return ConstantService.constant["CLICKFROMDASH"];
  }
  getDefaultClickonstant() {
    return ConstantService.constant["CLICKFROMDEFAULT"];
  }

  getClickUserConstant() {
    return ConstantService.constant["CLICKUSERCONSTANT"];
  }
  getCustomTypes() {
    return ConstantService.constant["CUSTOMTYPES"];
  }
  getCustomColumnsNotAllowedForTemplates() {
    return ConstantService.constant["COLUMNS_NOT_ALLOWED"];
  }
  getColorById(){
    return ConstantService.constant["CUSTOMCOLORBYID"]
  }
  getGroupKeyJson() {
    return ConstantService.constant["GROUP_KEY_JSON"];
  }
  getDefaultFormulasOPtions() {
    return ConstantService.constant.DEFAULT_FORMULAS_OPTIONS
  }
  getMediaTypes() {
    return ConstantService.constant["mediaExtension"];
  }
  getSettingAction(){
    return ConstantService.constant["SETTINGACTION"];
  }
  getThemeColor(){
    return ConstantService.constant["THEMEOPTIONS"];
  }
  getFaIcons(){
    return ConstantService.constant["FAICONS"]
  }
  getComponetTypes() {
    return ConstantService.constant["COMPONENTTYPE"];
  }
  getStartDateConstant() {
    return ConstantService.constant["TABLELEADSTART"];
  }
  getEndDateConstant() {
    return ConstantService.constant["TABLELEADEND"];
  }
  getAttachmentTypes() {
    return ConstantService.constant["Attachments_TYPE"];
  }
  getCommentsTypes() {
    return ConstantService.constant["COMMENTS_TYPE"];
  }
  getPhaseStatusType() {
    return ConstantService.constant["PHASESTATUSTYPE"];
  }
  getSprintStatusConstant() {
    return ConstantService.constant["SPRINT_STATUS"];
  }
  getChecklistStateConstant() {
    return ConstantService.constant["CHECKLIST_STATE"];
  }
  getUpdateCountConstant() {
    return ConstantService.constant["UPDATECOUNTCONSTAT"];
  }
  getUpdateCustomType() {
    return ConstantService.constant["UPDATECUSTOMTYPE"];
  }
  getDashboardUrl() {
    return ConstantService.constant["DASHBOARDNAVIGATIONURL"];
  }
  getSummerNoteConfig() {
    return ConstantService.constant["SUMMERNOTECONFIG"];
  }
  getInputType() {
    return ConstantService.constant["INPUTTYPE"];
  }
  getRegex() {
    return ConstantService.constant["REGEX"];
  }
  getCopyMessage() {
    return ConstantService.constant["MESSAGE"];
  }
  getDropFileType() {
    return ConstantService.constant["FILETYPE"];
  }
  getLogoConstant() {
    return ConstantService.constant["LOGO"];
  }
  getStartDateS() {
    return ConstantService.constant["STARTDATE"];
  }
  getEndDateS() {
    return ConstantService.constant["ENDDATE"];
  }
  getCalendar() {
    return ConstantService.constant["CALENDAR"];
  }
  getTimer() {
    return ConstantService.constant["TIMER"];
  }
  getKeyCode() {
    return ConstantService.constant["KEY_CODE"];
  }
  getSliderType() {
    return ConstantService.constant["SLIDERTYPE"];
  }
  getSprintData() {
    return ConstantService.constant["SPRINTDATA"];
  }
  getUpdateLeadType() {
    return ConstantService.constant["UPDATELEADTYPE"];
  }
  getClickType() {
    return ConstantService.constant["CLICKTYPE"];
  }
  getAuthMsg() {
    return ConstantService.constant["AUTHMSG"];
  }
  getRegisterMsg() {
    return ConstantService.constant["REGISTER"];
  }
  getActivityTypeKey() {
    return ConstantService.constant["ACTIVITYTYPEKEY"];
  }

  getAdditionalAttributesKey() {
    return ConstantService.constant["ADDITIONALATTRIBUTESKEY"];
  }

  getDetailskEY() {
    return ConstantService.constant["DETAILSKEY"];
  }

  getObjectTypeKey() {
    return ConstantService.constant["OBJECTTYPEKEY"];
  }

  getObjectTypeDetailsKey() {
    return ConstantService.constant["OBJECTTYPEDETAILSKEY"];
  }

  getAddActivityType() {
    return ConstantService.constant["ADDACTIVITYTYPE"];
  }

  getUpdateActivityType() {
    return ConstantService.constant["UPDATEACTIVITYTYPE"];
  }

  getLeadObjectType() {
    return ConstantService.constant["LEADOBJECTTYPE"];
  }

  getCommentObjectType() {
    return ConstantService.constant["COMMENTOBJECTTYPE"];
  }

  getTimelineObjectType() {
    return ConstantService.constant["TIMELINEOBJECTTYPE"];
  }

  getUpdatedTimekey() {
    return ConstantService.constant["UPDATEDTIMEKEY"];
  }

  getStringValueType() {
    return ConstantService.constant["STRINGVALUETYPE"];
  }

  getNumberKeyType() {
    return ConstantService.constant["NUMBERKEYTYPE"];
  }

  getAssignToNameKey() {
    return ConstantService.constant["ASSIGNTONAMEKEY"];
  }

  getBucketNameKey() {
    return ConstantService.constant['BUCKETNAMEKEY'];
  }

  getSortByTitle() {
    return ConstantService.constant["TITLE_SEARCH"];
  }

  getSortByIndexKey() {
    return ConstantService.constant["INDEX"];
  }
  getTimelineOpenFromInfo() {
    return ConstantService.constant["TimelineOpenFrom"];
  }
  getLeadViewStatus() {
    return ConstantService.constant["LEADVIEW"];
  }
  getTimlineFilter(){
    return ConstantService.constant['TIMELINEFILTER'];
  }
  getDefaultilters(){
    return ConstantService.constant['defaultFilters'];
  }
  getItemViewType(){
    return ConstantService.constant['itemViewType']
  }

  getFileTypeConstantArr() {
    return ConstantService.constant.fileTypeConstArr;
  }

  getLocationTypeConstant() {    
    return ConstantService.constant.LOCATION;
  }

  getIPConstant() {    
    return ConstantService.constant.IPADDRESS;
  }
  getDefaultColumnOptions(){
    return  JSON.parse(JSON.stringify(ConstantService.constant['DEFAULTCCOLUMNOPTIONS']));
  } 
  getDefaultColumnOptionsForGrid(){
    return  JSON.parse(JSON.stringify(ConstantService.constant['DEFAULT_COLUMN_OPTIONS_GRID']));
  } 
  
}
