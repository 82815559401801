import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef } from "@angular/core";
import { DashboardUtilsService } from "app/services/dashboard-utils.service";
import { NumericCellComponent } from "../numeric-cell/numeric-cell.component";
import { EmailCellComponent } from "../email-cell/email-cell.component";
import { MobileCellComponent } from "../mobile-cell/mobile-cell.component";
import { LocationCellComponent } from "../location-cell/location-cell.component";
import { TrackerCellComponent } from "../tracker-cell/tracker-cell.component";
import { RatingCellComponent } from "../rating-cell/rating-cell.component";
import { DateCellComponent } from "../date-cell/date-cell.component";
import { UrlCellComponent } from "../url-cell/url-cell.component";
import { ButtonCellComponent } from "../button-cell/button-cell.component";
import { ipaddressCellComponent } from "../ipaddress-cell/ipaddress-cell.component";
import { UserCellComponent } from "../user-cell/user-cell.component";
import { MediaCellComponent } from "../media-cell/media-cell.component";
import { BoardCellComponent } from "../board-cell/board-cell.component";
import { SelectionCellComponent } from "../selection-cell/selection-cell.component";
import { ChecklistCellComponent } from "../checklist-cell/checklist-cell.component";
import { TextfieldCellComponent } from "../textfield-cell/textfield-cell.component";
import { MirrorCellComponent } from "../mirror-cell/mirror-cell.component";
import { PriorityCellComponent } from "../priority-cell/priority-cell.component";
import { BucketCellComponent } from "../bucket-cell/bucket-cell.component";
import { SprintCellComponent } from "../sprint-cell/sprint-cell.component";
import { TagCellComponent } from "../tag-cell copy/tag-cell.component";
import { HttpTransferService } from "app/services/httpTransfer.service";

@Component({
    selector: 'app-multi-type',
    templateUrl: './multi-type-cell.component.html',
    styleUrls: ['./multi-type-cell.component.scss']
  })
  export class MultiTypeComponent implements OnInit  {
    @Output() onItemOpen = new EventEmitter<any>();
    @Input() params:any;
    @Input() dashboardUserRole:any;
    @Input() currencyArr: any[]=[];
    @Input() selectedDashId:any;
    @Input() mirrorColumnsJson:any;
    @ViewChild("cellRender",{ read: ViewContainerRef }) cellRender;
    cellComponentsConfig={
        Numeric:{cellRenderer:NumericCellComponent},
        Percentage:{cellRenderer:NumericCellComponent},
        Email:{cellRenderer:EmailCellComponent},
        Mobile:{cellRenderer:MobileCellComponent},
        MultipleEmail:{cellRenderer:EmailCellComponent},
        MultipleMobile:{cellRenderer:MobileCellComponent},
        Location:{cellRenderer:LocationCellComponent},
        TimeTracker:{cellRenderer:TrackerCellComponent},
        Rating:{cellRenderer:RatingCellComponent},
        Date:{cellRenderer:DateCellComponent},
        DateTime:{cellRenderer:DateCellComponent},
        Url:{cellRenderer:UrlCellComponent},
        MultiUrl:{cellRenderer:UrlCellComponent},
        Button:{cellRenderer:ButtonCellComponent},
        Country:{cellRenderer:MobileCellComponent},
        Currency:{cellRenderer:MobileCellComponent},
        IPAddress:{cellRenderer:ipaddressCellComponent},
        MultiUser:{cellRenderer:UserCellComponent,loaderClass:'user-loader'},
        SingleUser:{cellRenderer:UserCellComponent,loaderClass:'user-loader'},
        SingleVideo:{cellRenderer:MediaCellComponent}, //media
        MultipleVideo:{cellRenderer:MediaCellComponent},//media
        SingleAudio:{cellRenderer:MediaCellComponent},//media
        MultipleAudio:{cellRenderer:MediaCellComponent},//media
        SingleFile:{cellRenderer:MediaCellComponent},//media
        MultipleFile:{cellRenderer:MediaCellComponent},//media
        SingleImage:{cellRenderer:MediaCellComponent},//media
        MultipleImage:{cellRenderer:MediaCellComponent},//media
        Board:{cellRenderer:BoardCellComponent},
        MultipleBoard:{cellRenderer:BoardCellComponent},
        MultiSelect:{cellRenderer:SelectionCellComponent},
        Checkbox:{cellRenderer:SelectionCellComponent},
        CheckList:{cellRenderer:ChecklistCellComponent},
        Radiobutton:{cellRenderer:SelectionCellComponent},
        Dropdown:{cellRenderer:SelectionCellComponent},
        Text:{cellRenderer:TextfieldCellComponent},
        Textarea:{cellRenderer:TextfieldCellComponent},
        Mirror:{cellRenderer:MirrorCellComponent},
        // non custom field
        created_by:{cellRenderer:UserCellComponent,loaderClass:'user-loader'},
        assigned_to:{cellRenderer:UserCellComponent,loaderClass:'user-loader'},
        Priority:{cellRenderer:PriorityCellComponent,loaderClass:'priority-loader'},
        Bucket:{cellRenderer:BucketCellComponent},
        sprint_id:{cellRenderer:SprintCellComponent},
        tag_id:{cellRenderer:TagCellComponent},
        
      }

    constructor(private cdr : ChangeDetectorRef,private dashboardUtils : DashboardUtilsService,private httpTransfer : HttpTransferService){}

    
  async ngOnInit() {
    console.log(this.params)
    this.onUpdate()
  }

  ngDoCheck(){
    /* const changes = this.differ.diff(this.mirrorColumnsJson);
    if (changes) {
      this.onUpdate()
    } */
  }

  onUpdate(params?){
    setTimeout(() => {this.createDynamicComponent();this.cdr.detectChanges()},10);
  }


    async createDynamicComponent(){
        this.cellRender?.clear()
        let type=this.params?.cellType
        let cellRender= this.cellComponentsConfig[type]?.cellRenderer//this.params?.globalParams?.gridViewRef?.cellComponentsConfig[type]?.cellRenderer
        if(cellRender && this.cellRender){
          let nonCustomKey=['title','start_date','end_date','priority','sprint_id','bucket_id','assigned_to','tag_id','create_date','last_updated','created_by']
          let nonEditableFields=['create_date','last_updated','created_by']
          let componentRef=this.cellRender.createComponent(cellRender);
          componentRef.instance["params"]={'value' : this.params?.cellValue ,isMirrorColumn:false,'field':this.params?.cellKey,'columnData':this.params?.customFieldData,'item':this.params?.leadInfo, 'editable':this.params?.editable,'columnParams':{'customField':this.params?.customFieldData},
            'globalParams':{'customFields': this.params?.customFieldData || [],'currencyData':this.currencyArr || {},'dashboardUserRole':this.dashboardUserRole,'mirrorColumnsJson':this.mirrorColumnsJson,'gridViewRef':this},'isBoardView':this.params?.isBoardView,'gridTableRef':this}
        }
        this.cdr.detectChanges()
    }

    changedCellValue(data,boardId,itemId){
        console.log(data)
         let updateItemJson={
            'custom_fields':{[data.params.field] : data.value}
         }
         if(updateItemJson){
            this.httpTransfer.updateLeadCorrespondingToLeadId(updateItemJson,data?.params?.item?.dashboard_id,data?.params?.item?._id).subscribe(result => {
                if(result.status == 200){}
              })
         }
      }

        // open lead item modal 
  openRightItemModal(item,isConnedtedItem?,connectedBoradInfo?){
    let obj = {item:item,subtaskParentObj:connectedBoradInfo};
    this.onItemOpen.emit(obj)
  }
  }