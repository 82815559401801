import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms'; 
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { HttpTransferService } from '../../services/httpTransfer.service';
import { CustomStorageService } from '../../services/custom-storage.service'; 
import { DashboardUtilsService } from '../../services/dashboard-utils.service';
import { ToasterService } from '../../services/toaster.service';
import { CommonUtils } from '../../services/CommonUtils.service';

@Component({
  selector: 'app-dashboard-create',
  templateUrl: './dashboard-create.component.html',
  styleUrls: ['./dashboard-create.component.scss']
})
export class DashboardCreateComponent implements OnInit {
  @Output() createUpdate = new EventEmitter<any>()
  @ViewChild('dashNameInput') dashNameInput!: ElementRef;
  @ViewChild('terminology') terminology : ElementRef;
  @Input() dashboardType: string | null;
  @Input() selectDashboardPopupText: string = null;
  showDash: boolean = false;
  plawnSelsetedDashboardId: string | null = '';
  newDashboardForm: any;
  allDashboardTemplates: any;
  orgIdForGeneralTemplate: any;
  newDashFromTemplateRef: NgbModalRef;
  @Input() createNewDashTemplateRef: NgbModalRef;
  @Input() editDashId: string | null;
  @Input() dashboardFolderId: string | null;
  isViewSelected = true;
  editDashboardForm: FormGroup;
  boardTypes:any;
  boardTypeselected:string="General"
  availableBoards:string[]
  terminologyOptions:any[]=[]
  manualTerminology:any=''
  itemTerminology:any='Items';
  selectedThemeColor: string=null;
  loadEditBoard: boolean=false;



  constructor(
    private httpTransfer: HttpTransferService,
    private modalService: NgbModal,
    private dashboardUtils: DashboardUtilsService,
    private customStorageService: CustomStorageService,
    private toasterService: ToasterService,
    private commonUtils: CommonUtils,

  ) { 
    this.boardTypes={"Contact":"CONTACT","Organisation":"ORGANIZATION","Product":"PRODUCT","General":"GENERAL"}
    this.availableBoards=Object.keys(this.boardTypes)
  }

  ngOnChanges(changes: SimpleChanges){
    this.editDashId = changes.editDashId.currentValue;
    if(this.editDashId) this.getDashboard(); 
  }

  ngOnInit(): void {
    this.newDashboardForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(100), this.noWhitespaceValidator]),
      list: new FormControl(false),
      kanban: new FormControl(true),
      gantt: new FormControl(false),
      grid: new FormControl(false),
      privacy: new FormControl('PRIVATE'),

    });

    this.terminologyOptions=this.commonUtils.terminologyOptions
    this.editDashboardForm = new FormGroup({
      editDashName: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(100), this.noWhitespaceValidator]),
      editDashDescription: new FormControl(''),
      list: new FormControl(false),
      kanban: new FormControl(false),
      gantt: new FormControl(false),
      grid: new FormControl(false),
      privacy: new FormControl('PRIVATE'),
    });
    const theme = this.customStorageService.getItem('theme');
    this.selectedThemeColor = (theme !== "undefined" && theme !== "null" && theme !== null && theme !== undefined) ? theme : 'theme-royalBlue';
    if(this.editDashId) this.getDashboard(); 

 
  }

  ngAfterViewInit() {
    this.dashNameInput?.nativeElement.focus();
  }

  customTerminology(){
    if(this.itemTerminology=='Manual' && this.terminology){
      setTimeout(() => {
        this.terminology?.nativeElement.focus();
      }, 10);
    }
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
}

  getDashboard() {
    this.httpTransfer.getDashBoardInfoCorrespondingToDashId(this.editDashId).subscribe((res : any) => {
      if(res) {
        res = res['result']['dashboards'][0]
        this.editDashboardForm.setValue({
          editDashName: res.dashboard_name,
          editDashDescription: res.description,
          list: res.view_types.includes('LIST'),
          kanban: res.view_types.includes('KANBAN'),
          gantt: res.view_types.includes('GANTT'),
          grid: res.view_types.includes('GRID'),
          privacy: res?.access_type
        });
        this.loadEditBoard = true
        for(let board in this.boardTypes){
          if(res.board_type===this.boardTypes[board]){
            this.boardTypeselected=board
            break
          }
        }
      }
    });
  }

  updateDashboard() {
    const orgId = this.customStorageService.getItem('organisationid');
    this.isViewSelected = true;
    let viewTypes = [];
    if(this.editDashboardForm.value.list) viewTypes.push('LIST');
    if(this.editDashboardForm.value.kanban) viewTypes.push('KANBAN');
    if(this.editDashboardForm.value.gantt) viewTypes.push('GANTT');
    if(this.editDashboardForm.value.grid) viewTypes.push('GRID');
    if(this.dashboardType != 'BOARD_GRAPH' && this.dashboardType != 'BOARD_LIST' && viewTypes.length === 0) {
      this.isViewSelected = false;
      return
    }
    let json  = {
      name: this.editDashboardForm.value.editDashName,
      description: this.editDashboardForm.value.editDashDescription,
      view_types: viewTypes,
      access_type:this.editDashboardForm.value.privacy,
      board_type:this.boardTypes[this.boardTypeselected]
    }
      this.httpTransfer.updateDashBoardCorrespondingToOrganisation(json, orgId, this.editDashId).subscribe((res : any) => {
        if (res !== undefined && res) {
          this.createNewDashTemplateRef.dismiss();
          this.toasterService.Success("Board successfully updated.")
        }
      });
  }

  showHideDasPopup(selectedDasText) {
    this.showDash = false;
    this.selectDashboardPopupText = selectedDasText;
  }

  goToPreviousDashPopup() {
    this.showDash = true;
    this.selectDashboardPopupText = ''
  }

  createDashboard(selectedDasText) {
    this.selectDashboardPopupText = selectedDasText;
  }

  openNewDashFromTemplateModal(modalContent, dashId) {
    this.plawnSelsetedDashboardId = dashId;
    this.newDashFromTemplateRef = this.modalService.open(modalContent, { size: 'lg' });
    this.createNewDashTemplateRef.dismiss();
  }

  goBackToSelectTemplate(selectedDasText) {
    this.selectDashboardPopupText = selectedDasText;
    this.plawnSelsetedDashboardId = null;
  }

  getTerminology(){
    let term=''
    if(this.itemTerminology=='Manual'){
      term=this.manualTerminology
    }
    else{
      term=this.itemTerminology
    }
    return term
  }

  createNewDashboard() {
    this.createUpdate.emit()
    this.isViewSelected = true
    const dashName = this.newDashboardForm.get('name').value;
    let viewTypes = [];
    if(this.newDashboardForm.value.list) viewTypes.push('LIST');
    if(this.newDashboardForm.value.kanban) viewTypes.push('KANBAN');
    if(this.newDashboardForm.value.gantt) viewTypes.push('GANTT');
    if(this.newDashboardForm.value.grid) viewTypes.push('GRID');
    if(viewTypes.length === 0) {
      this.isViewSelected = false;
      return
    }
     const orgId = this.customStorageService.getItem('organisationid');
    const data = {
      name: dashName.trim(),
      view_types: viewTypes,
      access_type:this.newDashboardForm.value.privacy,
      type:this.selectDashboardPopupText,
      dashboard_folder_id: this.dashboardFolderId,
      item_terminology:this.getTerminology(),
      board_type:this.boardTypes[this.boardTypeselected]
    }
    if (data.name !== '') {
      this.httpTransfer.createDashBoardCorrespondingToOrganisation(data, orgId).subscribe((res : any) => {
        if (res !== undefined && res) {
          this.newDashboardForm.reset();
          this.createNewDashTemplateRef.dismiss(); 
          //this.dashboardUtils.createOrUpdatePreviousActivity(res.result?.createdBoard?._id)
        }
      })
    }
  }

  createNewGraphDashboard() {
    this.createUpdate.emit()
    const dashName = this.newDashboardForm.get('name').value;
     const orgId = this.customStorageService.getItem('organisationid');
     let data = {
      'name': dashName.trim(),
      'access_type':this.newDashboardForm.value.privacy,
      'dashboard_folder_id': this.dashboardFolderId 
     }
    if(this.selectDashboardPopupText ==='DOCUMENT'){
      data['type']='DOCUMENT'
    }
    else{
      data['type']='BOARD_GRAPH',
      data['view_types']= ['OVERVIEW']
    }
    if (data.name !== '') {
      this.httpTransfer.createDashBoardCorrespondingToOrganisation(data, orgId).subscribe((res : any) => {
        if (res !== undefined && res) {
          this.newDashboardForm.reset();
          this.createNewDashTemplateRef.dismiss(); 
          //this.dashboardUtils.createOrUpdatePreviousActivity(res.result?.createdBoard?._id,true,res.result?.createdBoard?._id)
        }
      })
    }
  }

  createNewListDashboard(type?) {
    this.createUpdate.emit()
    const dashName = this.newDashboardForm.get('name').value;
    if(dashName?.length>=3){
      const orgId = this.customStorageService.getItem('organisationid');
      const data = {
        name: dashName.trim(),
        type:type,
        access_type:this.newDashboardForm.value.privacy,
        dashboard_folder_id: this.dashboardFolderId
      }
      if (data.name !== '') {
        this.httpTransfer.createDashBoardCorrespondingToOrganisation(data, orgId).subscribe((res : any) => {
          if (res !== undefined && res) {
            this.newDashboardForm.reset();
            this.createNewDashTemplateRef.dismiss(); 
            //this.dashboardUtils.createOrUpdatePreviousActivity(res.result?.createdBoard?._id,true,'BOARD_GRAPH')
          }
        })
      }
      this.createNewDashTemplateRef?.dismiss();
    }
  }

  closeModalAfterCreateDB(event){
    this.newDashboardForm.reset();
    this.createNewDashTemplateRef.dismiss(); 
  }

  capitalizeFirstLetter(value: string) {
    if (value) {
      const capitalizedValue = this.commonUtils?.capitalizeFirstLetter(value)
      this.newDashboardForm.controls['name'].setValue(capitalizedValue, { emitEvent: false });
    }
  }

  createFolderCall(){
    this.httpTransfer.createDashBoardCorrespondingToOrganisation({name:  this.newDashboardForm.get('name').value?.trim(),dashboard_folder_id:this.dashboardFolderId,access_type:"PRIVATE",type:"FOLDER"},null).subscribe((res : any) => { 
      if(res && res.status == 200){
        this.toasterService.Success("Folder Created Successfully.")
      }
    }) 
  }


}
