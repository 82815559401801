<!-- location field -->
<div class="cell-box location-cell" [placement]="['bottom','bottom-right','bottom-left','auto']" container="body" #locationPopover="ngbPopover"  popoverClass="gridtableCell" [ngbPopover]="(editable || !editable && locations?.length) && locationTemplate" [autoClose]="'outside'">
  <div class="cell-box-inner" >
    <div class="left-box">
      <div class="cell-input-box">
        <span class="text-select" [style.opacity]="locations.length ? 1 : 0.5">{{locations.length ? locations : 'Add Location'}}</span>
      </div>
      <div class="cell-value-box">{{locations}}</div>
    </div>
    <div class="right-box" [ngbTooltip]="locations.length && 'Open in map'" placement="top" container="body" [tooltipClass]="'tooltipPopup'" triggers="hover">
      <a *ngIf="locations.length" class="map-link" target="_blank" rel="noopener noreferrer" href="https://maps.google.com/?q={{locations[0]?.value?.latitude}},{{locations[0]?.value?.longitude}}" (click)="$event.stopPropagation();"></a>
      <i class="fa fa-map-marker p-0"></i>
  </div> 
  </div>
</div>

<ng-template #locationTemplate>
  <ng-container>
    <div style="width:350px;height: 65px;" class="location-box">
      <app-location-item-settings class="location-box-inner" [locationData]="params?.value || {}" [gridSelectCall]="true" [isLocked]="!editable" (selectedLocation)="editable && updateLocation($event);locationPopover?.close()"></app-location-item-settings>
    </div>
  </ng-container>
</ng-template>