import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit ,ViewChild} from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { CommonUtils } from 'app/services/CommonUtils.service';
import { DashboardUtilsService } from 'app/services/dashboard-utils.service';
import { HttpTransferService } from 'app/services/httpTransfer.service';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'selection-cell',
  changeDetection:ChangeDetectionStrategy.OnPush,
  templateUrl: './selection-cell.component.html',
  styleUrls: ['./selection-cell.component.scss']
})
export class SelectionCellComponent implements OnInit  {
  @ViewChild('container', { static: false }) container!: ElementRef;
  @ViewChild('selectionPopover') selectionPopover:any;
  params:any={};
  editable: boolean = false
  itemInfo:any={}
  selectedValue: any;
  arrayOfCheckboxOptions:any=[];
  customtype:any;
  cloneCheckboxOptions:any=[]
  searchText:any=''
  selectedIndex=-1

  constructor(private cdr:ChangeDetectorRef,public commonUtils: CommonUtils,private dashboardUtils:DashboardUtilsService,private httpTransfer: HttpTransferService,){
  }

  async ngOnInit() {
    this.onUpdate(this.params)
  }

  onUpdate(params: any){
    console.log("params in drops",params)
    this.itemInfo=this.params?.item || {}
    this.editable=this.params?.editable
    this.selectedValue=[]
    this.customtype=this.params?.columnParams?.customField?.type
    if(['MultiSelect','Checkbox'].includes(this.customtype) && this.params?.value!=null)this.selectedValue=this.params?.value || []
    else if(this.params?.value!=null)this.selectedValue=[this.params?.value]
    let permissibleValues=this.params?.columnParams?.customField?.permissible_values ? [...this.params?.columnParams?.customField?.permissible_values] : []
    this.arrayOfCheckboxOptions=[]
    permissibleValues?.forEach(option=>{
      let value=option?.value?.k!=null ? option.value.k : option.k
      let obj={'checkinput': false,'id':option?.id,'value':value,'color':option?.value?.c?option.value.c:option.c}
      if(this.selectedValue?.includes(value)){obj.checkinput=true;
        // Replace the selected value with the corresponding object from permissibleValues
        let index = this.selectedValue.indexOf(value);
        if (index !== -1) {
            this.selectedValue[index] = obj;
        }
      }
      this.arrayOfCheckboxOptions.push(obj)
    })
    this.cloneCheckboxOptions=this.arrayOfCheckboxOptions;
    this.cdr.detectChanges()
  }

  onSetectValue(value){
    if(!this.editable){
      return
    }
    let currentSelection=null
    if(value!=null && this.customtype == 'Checkbox'){
      currentSelection = this.arrayOfCheckboxOptions.reduce((data,option)=>{if(value==null)option.checkinput=false; return option.checkinput ? [...data,option.value] : data},[])
      this.params.value =  currentSelection?.length ? currentSelection : null ;
    }
    else{
      currentSelection=value
      this.params.value =  currentSelection
    }
    if(this.customtype == 'Radiobutton' || this.customtype == 'Dropdown' || this.customtype=='Checkbox' && value==null){
      this.selectionPopover?.close()
    }
    this.params?.gridTableRef?.changedCellValue({value:this.params.value,customFieldType:true,params:this.params});
    this.onUpdate(this.params)  
  }
  onDropdownSelect(index?){
    if(index!=null){
      if(index>-1)this.selectedValue.splice(index,1)
      else this.selectedValue=[]
    }
    if(this.customtype=='Dropdown' && this.selectedValue.length>1){
      this.selectedValue=[this.selectedValue[this.selectedValue.length-1]]
    }
    let selectedData=this.params?.columnParams?.customField?.type == 'MultiSelect'?[]:null
    if(this.selectedValue.length>0){
      if(this.params?.columnParams?.customField?.type != 'MultiSelect'){
        this.selectedValue[0]
      }
      if(this.params?.columnParams?.customField?.type == 'MultiSelect'){
        this.selectedValue.forEach(ele=>{
          selectedData.push(ele.value)
        })
      }
    }
    this.params.value=selectedData
    this.params?.gridTableRef?.changedCellValue({value:this.params.value,customFieldType:true,params:this.params});
    this.onUpdate(this.params) 
  }
  onSearch(text){
    text=text?.toLowerCase()
    this.cloneCheckboxOptions=this.arrayOfCheckboxOptions?.filter(value=>value?.value?.toLowerCase()?.includes(text))
    this.selectedIndex=0
  }
  handeKeyInput(type?){
    if(type=='Add'){
      this.selectedIndex=0
      setTimeout(() => {document.getElementById('search-input')?.focus(); },1); 
      document.addEventListener('keydown',this.onKeyDown)
    }else{
      document.removeEventListener('keydown',this.onKeyDown)
    }
  }

  onKeyDown = (event: any): void => {
    switch (event.key) {
      case 'ArrowUp':
        if (this.selectedIndex > 0 || this.selectedIndex==0 && this.selectedValue?.length && this.editable && !this.params?.columnParams?.customField?.mandatory && ['Checkbox','Radiobutton']?.includes(this.customtype)) {
          this.selectedIndex--;
          this.commonUtils.ensureVisible(this.container,this.selectedIndex)
        }
        break;
      case 'ArrowDown':
        if (this.selectedIndex < this.cloneCheckboxOptions.length - 1) {
          this.selectedIndex++;
          this.commonUtils.ensureVisible(this.container,this.selectedIndex);
        }
        break;
      case ' ':  
      case 'Enter':
        if(this.editable){
          if(this.selectedIndex==-1){
            this.onSetectValue(null)
          }
          else if (this.selectedIndex>=0 && this.selectedIndex<this.cloneCheckboxOptions.length) {
              if(this.customtype=='Dropdown'){
                if(this.cloneCheckboxOptions[this.selectedIndex].checkinput && (this.selectedValue.length<2 && !this.params?.columnParams?.customField?.mandatory || this.selectedValue.length>1)){
                  this.onSetectValue(null)
                }else{
                  this.onSetectValue(this.cloneCheckboxOptions[this.selectedIndex].value)
                }
              }else{
                this.cloneCheckboxOptions[this.selectedIndex].checkinput=this.cloneCheckboxOptions[this.selectedIndex].checkinput ? false : true
                this.onSetectValue(this.cloneCheckboxOptions[this.selectedIndex].value)
              }
              
          }
        }
        break;
    }
  }

}
